import {
  TextField,
  MenuItem as BaseMenuItem,
  ListItemIcon,
  MenuList as BaseMenuList,
  Box,
} from "@material-ui/core"
import styled from "@material-ui/core/styles/styled"

export const SearchInput = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(0.5, 1),
  display: "flex",

  "& .MuiInputBase-input": {
    fontSize: "0.75rem",
    padding: theme.spacing(1, 1, 0.875),
    background: "#fbfbfb",
  },
}))
SearchInput.defaultProps = {
  size: "small",
  variant: "outlined",
  placeholder: "Search",
  autoFocus: false,
}

export const MenuWithSearch = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0),
}))

export const MenuList = styled(BaseMenuList)(({ theme }) => ({
  maxHeight: theme.spacing(30),
  overflowY: "scroll",
  padding: 0,
}))

export const MenuItem = styled(BaseMenuItem)(({ theme }) => ({
  padding: theme.spacing(1, 1, 1, 2),
  height: theme.spacing(5),
  minWidth: "180px",
  display: "flex",
  justifyContent: "space-between",
}))

export const MenuItemText = styled("div")({
  fontSize: "0.8125rem",
  lineHeight: "1rem",
  letterSpacing: "-0.5px",
})

export const MenuItemIcon = styled(ListItemIcon)({
  padding: 0,
  minWidth: "auto",
})
