import { Alert as MuiAlert } from "@material-ui/lab"
import { PAGE_SPACING, PREVIEW_PAGE_INDICATOR_SIZE, PREVIEW_SIZE, US_LETTER_SIZE } from "./constants"
import styled from "@emotion/styled"
import { AppBar, MenuItem, Select, Toolbar, Typography } from "@material-ui/core"

export const PdfViewport = styled("div")({
  height: "100%",
})

export const ToolbarContainer = styled(AppBar)(({ theme }) => ({
  height: theme.spacing(6),
  marginBottom: theme.spacing(1),
}))

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: "space-between",
  flexBasis: "20%",
  gap: theme.spacing(1),
}))

export const StyledToolbarSpace = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "center",
}))

export const PdfTitleWrapper = styled("div")({
  textAlign: "center",
})

export const PdfTitle = styled(Typography)({
  fontSize: "12px",
  fontWeight: "bold",
})

export const PdfSubTitle = styled(Typography)({
  fontSize: "11px",
  fontWeight: "normal",
})

export const PdfPagesIndicator = styled(Typography)({
  fontSize: "12px",
  fontWeight: "normal",
  minWidth: 60,
})

export const ToolbarOuterContainer = styled("div")(({ theme }) => ({
  height: theme.spacing(7),
}))

export const PdfDocumentContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",

  [theme.breakpoints.up("sm")]: {
    margin: theme.spacing(0, 5),

    "& .pdf-wrapper": {
      margin: theme.spacing(0, -5),
    },
  },
}))

export const PdfContainer = styled("div")(({ theme }) => ({
  height: "100%",
  maxHeight: `calc(100vh - ${theme.spacing(7)}px)`,
  display: "flex",
}))

export const NavigationPanel = styled("div")(({ theme }) => ({
  maxWidth: "300px",
  minWidth: "150px",
  width: "20vw",
  padding: theme.spacing(1, 0),
  display: "flex",
  justifyContent: "center",
  overflowY: "auto",
  borderRight: `1px solid ${theme.palette.divider}`,
}))

export const ThumbnailsContainer = styled("div")({
  width: "100%",
})

export const ThumbnailWrapper = styled("div", { shouldForwardProp: prop => prop !== "loading" })<{
  selected: boolean
  loading: boolean
}>(({ theme, selected, loading }) => ({
  background: selected ? "rgba(0, 0, 0, 0.15)" : "transparent",
  width: PREVIEW_SIZE.WIDTH,
  height: PREVIEW_SIZE.HEIGHT,
  padding: PREVIEW_SIZE.PADDING,
  marginBottom: PREVIEW_SIZE.MARGIN,
  marginLeft: "auto",
  marginRight: "auto",
  boxSizing: "content-box",

  "&:hover": {
    background: selected ? "rgba(0, 0, 0, 0.15)" : "rgba(0, 0, 0, 0.07)",
  },

  "& a": {
    textDecoration: "none",
    display: "block",
  },

  "& canvas": {
    background: theme.palette.common.white,
    boxShadow: theme.shadows[1],

    display: loading ? "none" : "block",
  },

  "& img": {
    background: theme.palette.common.white,
    boxShadow: theme.shadows[1],
  },
}))

function getAnnotationsBackground(colors: string[]): string {
  const colorStopPoint = 100 / colors.length
  const colorStops = colors
    .map((color, idx) => [`${color} ${colorStopPoint * idx}%`, `${color} ${colorStopPoint * (idx + 1)}%`])
    .flat()

  return `linear-gradient(0deg, ${colorStops.join(",")})`
}

export const ThumbnailAnnotatedWrapper = styled("div")<{ colors?: Nullable<string[]> }>(({ colors }) => ({
  "&:after": {
    content: colors && colors.length ? '""' : undefined,
    display: colors && colors.length ? "block" : "none",
    position: "absolute",
    top: 0,
    left: 0,
    width: "8px",
    height: PREVIEW_SIZE.HEIGHT,
    marginTop: PREVIEW_SIZE.PADDING,
    background: colors ? getAnnotationsBackground(colors) : "none",
  },
}))

export const ThumbnailLoading = styled("div")(({ theme }) => ({
  width: PREVIEW_SIZE.WIDTH,
  height: PREVIEW_SIZE.HEIGHT,
  boxShadow: theme.shadows[1],
  padding: theme.spacing(1),
  boxSizing: "border-box",
  background: theme.palette.common.white,
}))

export const ThumbnailViewport = styled("div")<{ deleted?: boolean }>(({ theme, deleted }) => ({
  position: "relative",
  display: "flex",

  "&:before": {
    content: deleted ? '""' : undefined,
    display: deleted ? "block" : "none",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: theme.zIndex.modal,
    background: "rgba(0, 0, 0, 0.05)",
  },

  "&:after": {
    content: deleted ? '"DELETED"' : undefined,
    display: deleted ? "block" : "none",
    position: "absolute",
    color: theme.palette.error.main,
    letterSpacing: "2px",
    fontSize: "14px",
    fontWeight: "bold",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textShadow: "1px 1px 0px #fff",
    zIndex: theme.zIndex.modal,
  },
}))

export const ThumbnailPageIndicator = styled("div")({
  marginBottom: PREVIEW_PAGE_INDICATOR_SIZE.MARGIN,
  textAlign: "center",
  fontSize: "11px",
  lineHeight: `${PREVIEW_PAGE_INDICATOR_SIZE.HEIGHT}px`,
})

export const StyledPage = styled("div")<{ deleted?: boolean }>(({ theme, deleted }) => ({
  boxShadow: theme.shadows[1],
  width: "fit-content",
  margin: "0 auto",
  position: "relative",

  "&:before": {
    content: deleted ? '""' : undefined,
    display: deleted ? "block" : "none",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: theme.zIndex.modal,
    background: "rgba(0, 0, 0, 0.1)",
  },

  "&:after": {
    content: deleted ? '"DELETED"' : undefined,
    display: deleted ? "block" : "none",
    position: "absolute",
    color: theme.palette.error.main,
    letterSpacing: "20px",
    fontSize: "60px",
    top: "30%",
    left: "50%",
    transform: "translateX(-50%)",
    textShadow: "1px 1px 0px #fff",
    zIndex: theme.zIndex.modal,
  },

  "& [data-annotation-id] h1:empty": {
    display: "none",

    "& ~ p": {
      border: "none",
      margin: 0,
    },
  },
}))

export const DefaultPage = styled(StyledPage)<{ scale: number }>(({ theme, scale }) => ({
  width: US_LETTER_SIZE.WIDTH * scale,
  height: US_LETTER_SIZE.HEIGHT * scale,

  padding: theme.spacing(5, 3),
}))

export const Alert = styled(MuiAlert)(({ theme }) => ({
  marginBottom: theme.spacing(5),
}))

export const PageWrapper = styled("div")({
  padding: PAGE_SPACING,
})

export const PagesWrapper = styled("div")(({ theme }) => ({
  overflow: "auto",
  width: "100%",
  maxHeight: "100%",

  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 5),
  },
}))

export const ZoomControlWrapper = styled("div")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
}))

const ZoomSelectClassNames = {
  root: "zoom-control-value",
}
export const ZoomSelect = styled(Select)(({ theme }) => ({
  [`.${ZoomSelectClassNames.root}`]: {
    fontSize: 11,
    textTransform: "uppercase",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}))
ZoomSelect.defaultProps = { classes: ZoomSelectClassNames, variant: "outlined", margin: "dense" }

export const ZoomSelectMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: 12,
  textTransform: "capitalize",
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
}))
