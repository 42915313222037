import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"
import { useForm, Controller } from "react-hook-form"
import { useQueryClient, useQuery, useMutation } from "react-query"
import { makeStyles } from "@material-ui/core/styles"
import { isNil } from "lodash"

import { queryKeys } from "../../react-query/constants"
import { useHandleMessages } from "../../common/messages/useHandleMessages"

import { createUser, updateUser, getFirms } from "../../api"
import useUser from "../../hooks/useUser"
import { useAuth } from "../../hooks/useAuth"

import { getRoleOptionsForRole } from "../../app/constants"
import { INTERNAL_ROLES, OSF } from "../../common/models/roles"
import { ConfirmDialog } from "../../common"

const useStyles = makeStyles(theme => ({
  formFields: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(4),
  },
  spanAcross: {
    gridColumn: "1 / 3",
  },
  actionButton: {
    gridColumn: "1 / 2",
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: "flex",
    gridColumn: "1 / 3",
    "& > :last-child": {
      marginLeft: theme.spacing(2),
    },
  },
}))

export function UserAccountForm({ formData = {}, callback = null }) {
  const { showMessage } = useHandleMessages()
  const [errors, setErrors] = useState(null)
  const [showWarning, setShowWarning] = useState(false)

  const classes = useStyles()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { logout } = useAuth()

  const { user } = useUser()
  const isEditingSelf = user.id === formData?.pk
  const emailChangeCopy = isEditingSelf
    ? "Are you sure you want to change your email? You will be logged out and will need to log back in with the new email."
    : "Are you sure you want to change the email for this user? They will be required to log in with the new email address."

  const { data: firms } = useQuery(queryKeys.firms, getFirms, { enabled: user.isInternal })
  const firmsMap = {}
  if (firms) {
    for (const f of firms) {
      firmsMap[f.pk] = f
    }
  }
  // 'Name' field to used on the selector
  const firmName = formData?.firm_id ? "firm_id" : "firm.pk"

  const { control, handleSubmit, watch, getValues } = useForm({
    defaultValues: formData,
  })
  const roleOptions = getRoleOptionsForRole(user.role, getValues("role"))

  const handleErrors = error => {
    try {
      if (error?.response?.status === 400) {
        let errorObject = isNil(error?.message) ? null : JSON.parse(error?.message)
        if ("error" in errorObject) {
          showMessage({
            type: "error",
            message: `Unable to update the user. Please check the form for errors. ${error?.message}`,
          })
        }

        return setErrors && setErrors(errorObject)
      }
      if (error?.response?.status === 500) {
        // in dev env full HTML message will be too large to display on a snackbar
        showMessage({
          type: "error",
          message: "Something went wrong. Please contact Eng.",
        })
        return
      }
    } catch {
      showMessage({
        type: "error",
        message: "Something went wrong. Please contact Eng.",
      })
    }
  }

  const saveUserAccountMutation = useMutation(createUser, {
    onMutate: () => {
      setErrors(null)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.users)
      navigate(`/settings/accounts`)
    },
    onError: handleErrors,
  })

  const handleFormSubmit = e => {
    e.preventDefault()
    hasChangedEmail ? setShowWarning(true) : submitForm()
  }

  const updateUserAccountMutation = useMutation(updateUser, {
    onMutate: () => {
      setErrors(null)
    },
    onSuccess: () => {
      if (isEditingSelf && hasChangedEmail) {
        showMessage(
          {
            type: "success",
            message: "Email updated successfully. Logging you out... ",
          },
          1000
        )

        setTimeout(async () => {
          await logout()
        }, 1000)
      }

      queryClient.invalidateQueries(queryKeys.userAccount)
      callback && callback()
    },
    onError: handleErrors,
  })

  const submitForm = handleSubmit(async data => {
    if (data?.firm?.pk) {
      data.firm_id = data.firm.pk
    }

    const pk = data.pk
    if (pk) {
      updateUserAccountMutation.mutate({ data: data, userId: pk })
    } else {
      saveUserAccountMutation.mutate({ data: data })
    }
  })

  const roleWatch = watch("role", INTERNAL_ROLES.LEGALOPS_MANAGER)
  const emailWatch = watch("email", formData?.email)
  const hasChangedEmail = formData.email && emailWatch !== formData?.email

  return (
    <Box>
      <form className={classes.formFields} onSubmit={handleFormSubmit}>
        <Controller
          name="first_name"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                required
                variant="outlined"
                label="First Name"
                error={errors?.first_name}
                helperText={errors?.first_name}
                {...field}
              />
            )
          }}
        />
        <Controller
          name="last_name"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                required
                variant="outlined"
                label="Last Name"
                error={errors?.last_name}
                helperText={errors?.last_name}
                {...field}
              />
            )
          }}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                required
                variant="outlined"
                label="Email"
                error={errors?.email}
                helperText={errors?.email}
                {...field}
              />
            )
          }}
        />
        {!isEditingSelf && !!roleOptions.length && (
          <Controller
            name="role"
            control={control}
            required
            render={({ field }) => (
              <FormControl
                variant="outlined"
                disabled={
                  !user.isRole(INTERNAL_ROLES.LEGALOPS_ADMIN) && roleWatch === INTERNAL_ROLES.LEGALOPS_ADMIN
                }
              >
                <InputLabel id="role-label">Role</InputLabel>
                <Select labelId="role-label" label="Role" {...field} required data-test="role-selector">
                  {roleOptions.map(({ key, display }) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {display}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            )}
          />
        )}
        {firms &&
          user.isInternal &&
          roleWatch !== undefined &&
          roleWatch !== OSF &&
          !Object.values(INTERNAL_ROLES).includes(roleWatch) && (
            <Controller
              required
              name={firmName}
              control={control}
              render={({ field }) => (
                <FormControl variant="outlined">
                  <InputLabel id="firm-label">Firm</InputLabel>
                  <Select labelId="firm-label" id="firm-selector" {...field} required>
                    {firms.map(f => (
                      <MenuItem key={f.pk} value={f.pk}>
                        {f.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          )}
        {typeof formData?.pk === "undefined" && (
          <Controller
            name="password"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  required
                  className={classes.spanAcross}
                  variant="outlined"
                  label="Password"
                  {...field}
                />
              )
            }}
          />
        )}

        <Box className={classes.actions}>
          <Button type="submit" variant="contained" color="primary" data-test="save-user">
            Save User
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => (callback ? callback() : navigate("../"))}
            data-test="cancel-button"
          >
            Cancel
          </Button>
        </Box>
      </form>
      <ConfirmDialog
        open={showWarning}
        onClose={() => setShowWarning(false)}
        onConfirm={() => {
          setShowWarning(false)
          submitForm()
        }}
        title={"User email was changed"}
        body={emailChangeCopy}
      />
    </Box>
  )
}
