import { KeyboardEvent } from "react"
import isHotkey from "is-hotkey"
import { cloneDeep } from "lodash"
import { Node } from "slate"
import { Editor } from "./Editor"
import { CustomEditor, EditorRoot } from "./CustomEditor"
import { LIST_HOTKEYS, STYLE_HOTKEYS, TEXT_HOTKEYS, COMMON_HOTKEYS } from "./hotkeys"

type ReplacementType = string | number
type Replacements = Record<string, ReplacementType>

export function replaceMatches(original: EditorRoot, replacements: Replacements): EditorRoot {
  const nodes = cloneDeep(original)
  const replacementItems = Object.entries(replacements)

  for (const node of nodes) {
    replaceWith(node, replacementItems)
  }

  return nodes
}

function replaceWith(node: Node, replacements: [string, ReplacementType][]) {
  if ("children" in node) {
    for (const child of node.children) {
      replaceWith(child, replacements)
    }
  }

  if ("text" in node) {
    let text = node.text.replaceAll("\\[", "[").replaceAll("\\]", "]")

    for (const [key, value] of replacements) {
      text = text.replaceAll(key, `${value}`)
    }

    node.text = text
  }
}

export const handleKeyDown =
  (editor: CustomEditor) =>
  (event: KeyboardEvent): void => {
    for (const hotkey in STYLE_HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault()
        event.stopPropagation()

        const textStyle = STYLE_HOTKEYS[hotkey as keyof typeof STYLE_HOTKEYS]
        return Editor.toggleTextStyle(editor, textStyle)
      }
    }

    for (const hotkey in TEXT_HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        return TEXT_HOTKEYS[hotkey](editor, event)
      }
    }

    if (Editor.isInList(editor)) {
      for (const hotkey in LIST_HOTKEYS) {
        if (isHotkey(hotkey, event)) {
          return LIST_HOTKEYS[hotkey](editor, event)
        }
      }
    }

    for (const hotkey in COMMON_HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        return COMMON_HOTKEYS[hotkey](editor, event)
      }
    }
  }

export function matchVariables(text: string): Nullable<RegExpMatchArray> {
  return text.match(/\[\[(.|\n)+?\]\]/gim)
}

export function createVariableTextNode(variableText: string): Nullable<string> {
  const normalizedText = variableText.replaceAll(/\n/g, "")

  if (!matchVariables(variableText)) return null

  const variableName = normalizedText.slice(2, -2).trim()

  return variableName ? `<span data-variable-name="${variableName}">[[${variableName}]]</span>` : null
}
