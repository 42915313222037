import React, { useState } from "react"
import { Box, Button, Container, Paper, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { Link as RouterLink, useParams } from "react-router-dom"
import { getFavoriteCase } from "../api"
import { StateSelect } from "../common"
import CaseResult from "../common/case-result/CaseResult"
import { queryKeys } from "../react-query/constants"

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(2),
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  fabArea: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fab: {
    marginLeft: "10px",
  },
  emptyCases: {
    padding: theme.spacing(4),
  },
}))

const NoSavedCases = function ({ caseId }) {
  const classes = useStyles()

  return (
    <Paper className={classes.emptyCases} data-test="empty-favorite-cases">
      <Typography gutterBottom variant="h5">
        No cases saved under this case name.
      </Typography>
      <Typography gutterBottom component="p">
        Find and add cases through <RouterLink to={`/demands/${caseId}/search`}>Search</RouterLink>!
      </Typography>
    </Paper>
  )
}

export function FavoriteCases() {
  const { id: caseId } = useParams()
  const [errors, _] = useState(null)
  const { control, getValues, reset } = useForm({
    defaultValues: { state: "" },
  })
  const [state, setState] = useState()

  const { isLoading, data: documents, error } = useQuery([queryKeys.favoriteCases, state], getFavoriteCase)

  const handleChange = () => {
    const stateValue = getValues("state")
    if (!stateValue) return

    setState(stateValue)
  }

  if (error) return "An error has occurred: " + error.message
  return (
    <Box>
      <Box display={"flex"} alignItems="center">
        <form onBlur={handleChange}>
          <StateSelect control={control} selectName="state" errors={errors?.state} />
        </form>
        <Box ml={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              reset()
              setState(null)
            }}
            data-test="clear-state-button"
          >
            Clear
          </Button>
        </Box>
      </Box>

      <Container maxWidth="xl">
        {!isLoading && !!documents?.results?.length ? (
          documents?.results?.map(document => {
            document._is_favorite = true
            document.favorite_document =
              documents?.favorite_documents?.find(favorite_document => {
                return favorite_document.elasticsearch_id === document.id
              }) || null

            return (
              <CaseResult
                data={document}
                key={document.id}
                caseId={caseId}
                givenNickname={document.nickname}
              />
            )
          })
        ) : (
          <NoSavedCases caseId={caseId} />
        )}
      </Container>
    </Box>
  )
}
