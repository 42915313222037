import React from "react"
import { Draggable } from "react-beautiful-dnd"
import { Box } from "@material-ui/core"
import { DragIndicator } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import UploadedFile, { UploadedFileProps } from "./UploadedFile"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  providerDragIndicator: {
    fontSize: "1.9rem",
    marginRight: theme.spacing(4),
  },
  disabled: {
    color: theme.palette.action.disabled,
  },
}))

interface DraggableFileProps extends UploadedFileProps {
  index: number
}

const DraggableFile: React.FC<DraggableFileProps> = ({ index, ...uploadedFileProps }) => {
  const classes = useStyles()
  return (
    <Draggable key={uploadedFileProps.id} draggableId={String(uploadedFileProps.id)} index={index}>
      {draggableProvided => (
        <UploadedFile
          {...uploadedFileProps}
          draggableProps={draggableProvided.draggableProps}
          // @ts-expect-error: I couldn't figure out the definition between react-beautiful-dnd refs and react's forwardRef typing
          ref={draggableProvided.innerRef}
          dragHandle={
            <Box display="flex" {...draggableProvided.dragHandleProps}>
              <DragIndicator
                className={clsx(
                  classes.providerDragIndicator,
                  uploadedFileProps.disabled && classes.disabled
                )}
              />
            </Box>
          }
        />
      )}
    </Draggable>
  )
}

export default DraggableFile
