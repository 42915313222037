import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  nestedListItem: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    border: `1px solid theme.palette.divder`,
    background: theme.palette.background.default,
  },
}))

export function NestedListItem({ renderContent, children, ...props }) {
  const classes = useStyles()

  return (
    <div className={classes.nestedListItem}>
      <div>{renderContent(props)}</div>
      <div>{children}</div>
    </div>
  )
}
