import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Attributes as AttributesType } from "common/types/attributes"
import { AttributeFiltersData, SectionWithAttributes } from "./types"
import { isEqual } from "lodash"
import { VerticalCenterBox } from "common/FlexBox"
import { AttributesFilter } from "./AttributesFilter"
import { SectionFilter } from "./SectionFilter"
import { FilterGroupDivider } from "./styled"

type SectionAndAttributesFilterProps = {
  attributes: AttributesType
  onChange?: (values: SectionWithAttributes) => void
  error?: boolean
  errorSection?: boolean
  dividers?: boolean
  showSection?: boolean
  beforeAttributeChange?: () => void
} & {
  [K in keyof SectionWithAttributes as `initial${Capitalize<K>}`]?: SectionWithAttributes[K]
}

export function SectionAndAttributesFilter({
  attributes,
  initialSection,
  initialAttributeValues,
  onChange,
  error,
  errorSection,
  beforeAttributeChange,
  showSection = true,
  dividers = false,
}: SectionAndAttributesFilterProps): JSX.Element {
  const initialValues = useMemo<SectionWithAttributes>(
    () => ({
      section: initialSection ?? null,
      attributeValues: initialAttributeValues ?? {},
    }),
    [initialSection, initialAttributeValues]
  )
  const [values, setValues] = useState<SectionWithAttributes>(initialValues)

  const setNextValues = useCallback(
    (changes: Partial<SectionWithAttributes>) => {
      setValues(currentValues => {
        const nextValues = { ...currentValues, ...changes }

        return isEqual(currentValues, nextValues) ? currentValues : nextValues
      })
    },
    [setValues]
  )

  useEffect(() => {
    setNextValues(initialValues)
  }, [initialValues, setNextValues])

  useEffect(() => {
    onChange?.(values)
  }, [onChange, values])

  const handleAttributeValuesChange = useCallback(
    (attributeValues: AttributeFiltersData) => {
      beforeAttributeChange && beforeAttributeChange()
      setNextValues({ attributeValues })
    },
    [setNextValues, beforeAttributeChange]
  )

  const handleSectionChange = useCallback(
    (section: SectionWithAttributes["section"]) => {
      beforeAttributeChange && beforeAttributeChange()
      setNextValues({ section })
    },
    [setNextValues, beforeAttributeChange]
  )

  const handleReset = useCallback(() => {
    setNextValues({ section: null })
  }, [setNextValues])

  return (
    <VerticalCenterBox>
      <AttributesFilter
        error={error}
        attributes={attributes}
        values={values.attributeValues}
        onChange={handleAttributeValuesChange}
        onReset={handleReset}
      >
        {showSection && (
          <SectionFilter
            error={errorSection || error}
            value={values.section}
            onChange={handleSectionChange}
          />
        )}
        {dividers && <FilterGroupDivider />}
      </AttributesFilter>
    </VerticalCenterBox>
  )
}
