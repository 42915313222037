import React, { useEffect } from "react"
import { Typography } from "@material-ui/core"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router"
import { queryKeys } from "../react-query/constants"
import { createCase } from "../api"

export function NewDemand() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { mutateAsync: handleCreateCase } = useMutation(createCase, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.cases)
    },
  })

  useEffect(() => {
    const createCase = async () => {
      const data = await handleCreateCase()
      navigate(`/demands/${data.pk}`, { replace: true })
    }
    createCase()
  }, [handleCreateCase, navigate])

  return (
    <>
      {handleCreateCase.isLoading ? (
        <Typography>Creating New Demand... </Typography>
      ) : (
        <>
          {handleCreateCase.isError ? (
            <Typography>An error has occured. Please reload this page to try again.</Typography>
          ) : null}
        </>
      )}
    </>
  )
}
