import React, { useCallback } from "react"
import { KeysFor } from "common/types/helper"
import { MenuItemText, MenuItem } from "./styled"

interface LeafMenuItemProps<T extends BaseObject> {
  item: T
  label: KeysFor<T, string>
  displayLabel?: KeysFor<T, Nullable<string> | undefined>
  onClick: (item: T) => void
  onHover?: (item: Nullable<T>) => void
}

export function LeafMenuItem<T extends BaseObject>({
  item,
  label,
  displayLabel,
  onClick,
  onHover,
}: LeafMenuItemProps<T>): JSX.Element {
  const displayValue = displayLabel && item[displayLabel]
  const value = displayValue || item[label]

  const handleMouseOver = useCallback(() => {
    onHover && onHover(item)
  }, [item, onHover])

  const handleMouseOut = useCallback(() => {
    onHover && onHover(null)
  }, [onHover])

  const handleClick = useCallback(() => {
    onClick(item)
  }, [item, onClick])

  return (
    <MenuItem onClick={handleClick} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} button>
      <MenuItemText>{value}</MenuItemText>
    </MenuItem>
  )
}
