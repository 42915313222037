import { Box, Typography } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"
import BaseFileDropzone from "common/form-components/files/FileDropzone"

export const RevisionContainer = styled(Box)(({ theme }) => ({
  maxWidth: "73rem",
  padding: theme.spacing(4, 8, 0, 8),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(7, 0, 0, 0),
  },
}))

export const FormContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  maxWidth: "60rem",
}))

export const FormButtons = styled(Box)(({ theme }) => ({
  "& > button:not(:last-child)": {
    marginRight: theme.spacing(3),
  },
}))

export const RevisionHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  marginBottom: theme.spacing(3),
}))

export const RevisionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1.5rem",
  lineHeight: "2.125rem",
  letterSpacing: "-0.5px",
  marginBottom: theme.spacing(2),

  "&:only-child": {
    marginBottom: 0,
  },
}))

RevisionTitle.defaultProps = {
  variant: "h1",
}

export const RevisionSubtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1.25rem",
  lineHeight: "2.125rem",
  letterSpacing: "-0.4px",
  marginBottom: theme.spacing(4),
}))

RevisionSubtitle.defaultProps = {
  variant: "h2",
}

export const FormLine = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
}))

export const FormLineHalfWidth = styled(FormLine)({
  "& > .MuiFormControl-root": {
    width: "50%",
  },
})

export const Info = styled(Typography)({
  fontSize: "0.815rem",
})

export const Caption = styled(Typography)(({ theme }) => ({
  fontSize: "0.815rem",
  fontWeight: 600,
  marginBottom: theme.spacing(1),
}))

export const FileDropzone = styled(BaseFileDropzone)(({ theme }) => ({
  margin: theme.spacing(3, 0, 1),
}))

export const DetailsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  paddingTop: theme.spacing(4),
  borderTop: `1px solid ${theme.palette.divider}`,
}))

export const Details = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 3, 6),
  border: `1px solid ${theme.palette.divider}`,
  maxWidth: "56rem",
}))

export const DetailsRow = styled(Box)(({ theme }) => ({
  fontSize: "0.825rem",
  lineHeight: "1.125rem",
  marginBottom: theme.spacing(2),

  "& button": {
    fontSize: "inherit",
  },

  "&:last-child": {
    marginBottom: 0,
  },
}))

export const DetailsRowItems = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
})

export const RevisionDate = styled(Typography)({
  fontWeight: 600,
  fontSize: "1rem",
})

export const RevisionDescription = styled("div")({
  whiteSpace: "pre-wrap",
})

export const RevisionFiles = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 5,
}))

export const RevisionListContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(-2),
}))

export const RevisionListitem = styled(Box)(({ theme }) => ({
  "&:not(:first-of-type)": {
    marginTop: theme.spacing(4),
  },
}))
