import { apm as elasticApm } from "@elastic/apm-rum"
import { noop } from "lodash"
import { MessageReply, OnErrorCallback, Publish } from "./types"
import { useMessageBroker } from "./useMessageBroker"
import { useCallback, useRef } from "react"

interface UsePublishProps {
  onError?: OnErrorCallback
}

export function usePublish({ onError = noop }: UsePublishProps = {}): Publish {
  const [publish] = useMessageBroker()
  const onErrorRef = useRef(onError)
  onErrorRef.current = onError

  const safePublish = useCallback<Publish>(
    (topic, payload) => {
      return publish(topic as any, payload).catch<MessageReply>(reason => {
        onErrorRef.current(reason)
        // Log messaging error to be able to discover issues
        elasticApm.captureError(`Message Broker error (publish): ${reason}`)

        return {
          success: false,
          error: reason,
        }
      })
    },
    [publish]
  )

  return safePublish
}
