import React from "react"
import { RenderElementProps } from "slate-react"
import { ListItemElement } from "../CustomEditor"

interface ListItemProps extends RenderElementProps {
  element: ListItemElement
}

export const ListItem: React.FC<ListItemProps> = ({ attributes, children }) => {
  return <li {...attributes}>{children}</li>
}
