import React from "react"
import { Chip } from "@material-ui/core"

import { STANDARD, BASIC_PLUS } from "./../constants"

export type DemandType = typeof STANDARD | typeof BASIC_PLUS

interface RequestTypeBadgeProps {
  type: DemandType
}

export const RequestTypeBadge: React.FC<RequestTypeBadgeProps> = ({ type }): JSX.Element => {
  switch (type) {
    case STANDARD:
      return <Chip label="STANDARD" variant="outlined" color="primary" />
    case BASIC_PLUS:
      return <Chip label="BASIC +" variant="outlined" color="primary" />
    default:
      return <></>
  }
}
