import React from "react"
import { Theme, styled } from "@material-ui/core/styles"
import TextButton from "common/buttons/TextButton"
import { omit } from "lodash"
import { BaseActionButton } from "../Tabs/styled"

interface MessageWrapperProps {
  error?: boolean
}

export const StyledTitle = styled("div")(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "bold",
  marginBottom: theme.spacing(3),
  textTransform: "capitalize",
}))

export const StyledLibraryFormWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(6),
}))

export const StyledLibraryForm = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(4, 10, 4, 4),
  marginTop: theme.spacing(3),
}))

export const StyledLibraryAddButton = styled(TextButton)(({ theme }) => ({
  color: theme.palette.blue.main,
}))

export const StyledLibraryTableFormButtonsWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(4),
  textAlign: "right",
}))

export const StyledMessageWrapper = styled(props => (
  <div {...omit<MessageWrapperProps, keyof MessageWrapperProps>(props, "error")} />
))<Theme, MessageWrapperProps>(({ theme, error }) => ({
  marginTop: error ? theme.spacing(3) : undefined,
  position: "relative",

  "& > div": !error
    ? {
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        top: theme.spacing(2),
      }
    : {},
}))

export const StyledCancelButton = styled(BaseActionButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
}))

export const StyledAddButton = styled(BaseActionButton)({})
StyledAddButton.defaultProps = {
  color: "primary",
}
