import React, { useCallback, useContext, useMemo } from "react"
import { CREATE_VARIABLE_FORM_STATE_MESSAGES } from "common/template-form/constants"
import { TransitionGroup } from "react-transition-group"
import { Collapse } from "@material-ui/core"
import { Loading } from "common/loading"
import { useLibraryVariableForm } from "common/template-form/useLibraryVariableForm"
import { FormFooter } from "./FormFooter"
import { createEndCreatingTemplateAction, createStartCreatingTemplateAction } from "../State/templatesActions"
import { LibraryTabStateContext } from "../State/constants"
import {
  StyledTitle,
  StyledLibraryFormWrapper,
  StyledLibraryForm,
  StyledLibraryAddButton,
  StyledMessageWrapper,
} from "./styled"

export function NewVariable(): JSX.Element {
  const { state, dispatch } = useContext(LibraryTabStateContext)
  const disabled = !!(state.creatingData || state.editingEntityId)
  const initialTemplateData = state.creatingData || {}

  const onFinish = useCallback(() => dispatch(createEndCreatingTemplateAction()), [dispatch])

  const { message, editForm, isLoading, handleSubmit, handleCancel, clearForm, errorForm } =
    useLibraryVariableForm({
      onFinish,
      initialData: initialTemplateData,
      formStatusMessageMap: CREATE_VARIABLE_FORM_STATE_MESSAGES,
    })

  const handleAddNewTemplate = useCallback(() => {
    clearForm()
    dispatch(createStartCreatingTemplateAction())
  }, [clearForm, dispatch])

  const footer = useMemo(
    () => <FormFooter onCancel={handleCancel} onAction={handleSubmit} />,
    [handleSubmit, handleCancel]
  )
  const addNewTemplateForm = useMemo(() => editForm(footer), [editForm, footer])

  return (
    <>
      {message && <StyledMessageWrapper error={errorForm}>{message}</StyledMessageWrapper>}
      <StyledLibraryFormWrapper>
        <TransitionGroup>
          {!!state.creatingData && (
            <Collapse appear>
              <StyledLibraryForm>
                <StyledTitle>Add new variable</StyledTitle>
                {addNewTemplateForm}
              </StyledLibraryForm>
            </Collapse>
          )}
          <>
            <StyledLibraryAddButton onClick={handleAddNewTemplate} disabled={disabled}>
              + Add New Variable
            </StyledLibraryAddButton>
          </>
        </TransitionGroup>
      </StyledLibraryFormWrapper>
      <Loading show={isLoading} label="Creating variable..." />
    </>
  )
}
