import React, { useMemo } from "react"
import { Box, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { CustomDamageSection } from "./Firm"
import { CustomDamageSectionType, DamageType } from "./enums"
import { useForm } from "react-hook-form"
import { InputField, RadioButtons, SelectInput } from "../../common/form-components"
import { RichTextField } from "../../common/form-components/rich-text"
import { CUSTOM_DAMAGE_SECTION_VARIABLES } from "./constants"
import { useQuery } from "react-query"
import { queryKeys } from "react-query/constants"
import { variablesService } from "api/services/variables"

const useStyles = makeStyles(theme => ({
  formFields: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(4),
  },
  fullWidth: {
    gridColumn: "1 / 3",
  },
  actions: {
    gridColumn: "1 / 3",
    "& button": {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    margin: theme.spacing(2, 0),
    justifyContent: "flex-end",
  },
}))

type TemplateSectionFormProps = {
  section: CustomDamageSection
  onChange: (section: CustomDamageSection) => void
  sectionTypes: ValueOptions<CustomDamageSectionType>
}

const DamageTypeOptions: ValueOptions<DamageType> = [
  { key: DamageType.ECONOMIC, display: "Economic" },
  { key: DamageType.NON_ECOMONIC, display: "Non-Economic" },
]

export function TemplateSectionForm({
  section,
  onChange,
  sectionTypes,
}: TemplateSectionFormProps): JSX.Element {
  const classes = useStyles()
  const { control, handleSubmit, getValues, watch } = useForm({
    defaultValues: section,
    mode: "onBlur",
  })

  const { data: caseVariables } = useQuery(
    queryKeys.defaultVariables,
    variablesService.getDefaultCaseVariables,
    {
      keepPreviousData: true,
    }
  )

  const isValid = (): boolean => {
    const title = getValues("title")
    return title !== null && title !== ""
  }

  const handleFormSubmit = handleSubmit(() => onChange(getValues()))

  const sectionType = watch("section_type")
  const sectionVariables = useMemo(() => CUSTOM_DAMAGE_SECTION_VARIABLES[sectionType] ?? [], [sectionType])
  const variables = useMemo(() => {
    if (!caseVariables) return sectionVariables
    return [...caseVariables, ...sectionVariables]
  }, [caseVariables, sectionVariables])

  return (
    <form noValidate className={classes.formFields} onSubmit={handleFormSubmit}>
      <InputField
        autoFocus
        control={control}
        name="title"
        label="Title"
        variant="outlined"
        error={!isValid()}
        helperText={!isValid() && "Title is required"}
        className={classes.fullWidth}
      />
      <RadioButtons
        aria-label="damage_type"
        control={control}
        name="damage_type"
        options={DamageTypeOptions}
      />
      <SelectInput
        control={control}
        name="section_type"
        options={sectionTypes}
        rules={undefined}
        label="Section Type"
      />
      <RichTextField
        control={control}
        label="Template"
        name="text_json"
        markdownName="text"
        helperText={
          sectionType === CustomDamageSectionType.FUTURE_EXPENSES
            ? "Use [[future_procedures]] as a placeholder if you need to overwrite text per demand."
            : ""
        }
        withVariables
        variables={variables}
      />
      <RichTextField
        control={control}
        label="Post-Section Template"
        name="posttext_json"
        markdownName="posttext"
        withVariables
        variables={variables}
      />
      <Box className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleFormSubmit}>
          Done
        </Button>
      </Box>
    </form>
  )
}
