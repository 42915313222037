import { Theme } from "@material-ui/core"

export type ButtonColor = "blue" | "grey" | "secondary"

const getTextColorFromTheme = ({ theme, textColor }: { theme: Theme; textColor?: ButtonColor }): string => {
  switch (textColor) {
    case "grey":
      return theme.palette.grey[700]
    case "secondary":
      return theme.palette.secondary.main
    case "blue":
    default:
      return theme.palette.blue.main
  }
}

export default getTextColorFromTheme
