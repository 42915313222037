import React, { useCallback, useMemo } from "react"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import { useMutation, useQuery } from "react-query"
import { providerTemplateService } from "api/services/provider-template"
import { Autocomplete } from "@material-ui/lab"
import { TextField } from "@material-ui/core"
import { queryClient } from "react-query/queryClient"
import { queryKeys } from "react-query/constants"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { caseService } from "api/services/case"
import { Provider } from "demand/Providers/types"
import { ProviderTemplateServiceDeserializer } from "api/services/provider-template/serializers"
import { ProviderTemplateDefinition } from "common/types/providerTemplates"

interface ProviderTemplateSelectorProps {
  disabled: boolean
  provider: Provider
  caseId: PrimaryKey
}

export function ProviderTemplateSelector({
  disabled,
  provider,
  caseId,
}: ProviderTemplateSelectorProps): Nullable<JSX.Element> {
  const { isFeatureEnabled } = useFeatures()
  const isProviderTemplatesFeatureEnabled = isFeatureEnabled(FEATURES.PROVIDER_TEMPLATES)
  const { showMessage } = useHandleMessages()
  const { isFetching, data } = useQuery([queryKeys.providerTemplates, 1, 999999], () =>
    providerTemplateService.getProviderTemplatesList({ page: 1, pageSize: 999999, orderBy: "template_name" })
  )
  const renderInput = useCallback(
    params => <TextField {...params} label="Provider template type" variant="outlined" />,
    []
  )

  const getOptionLabel = useCallback(option => option.template_name || option.templateName, [])

  const getOptionSelected = useCallback(
    option => {
      if (!provider.templated_sections?.[0]?.template) {
        return false
      }

      return option.id === provider.templated_sections[0].template?.pk
    },
    [provider.templated_sections]
  )

  const { mutate: selectTemplate } = useMutation(caseService.selectCaseProviderTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.providers, caseId])
      queryClient.invalidateQueries([queryKeys.steps, caseId])
    },
    onError: () => {
      showMessage({ type: "error", message: "There was an error while trying to update provider." })
    },
  })

  const { mutate: createTemplate } = useMutation(caseService.createCaseProviderTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.providers, caseId])
      queryClient.invalidateQueries([queryKeys.steps, caseId])
    },
    onError: () => {
      showMessage({ type: "error", message: "There was an error while trying to update provider." })
    },
  })

  const handleChange = useCallback(
    (_, selectedValue) => {
      if (!selectedValue) return
      if (!provider.templated_sections?.[0]) {
        createTemplate({
          caseId,
          providerId: provider.pk,
          data: { templateId: selectedValue.id },
        })
        return
      }

      if (selectedValue.id === provider.templated_sections[0].template?.pk) return

      selectTemplate({
        caseId,
        providerId: provider.pk,
        templateSectionId: provider.templated_sections[0].pk,
        data: { templateId: selectedValue.id },
      })
    },
    [caseId, provider.pk, selectTemplate, provider.templated_sections, createTemplate]
  )

  const value = useMemo(() => {
    if (provider?.templated_sections?.[0]?.template) {
      return ProviderTemplateServiceDeserializer.definitionFromJSON(provider.templated_sections[0].template)
    }

    return null
  }, [provider])

  return isProviderTemplatesFeatureEnabled ? (
    <Autocomplete<ProviderTemplateDefinition | null>
      value={value}
      disabled={disabled}
      loading={isFetching}
      options={data?.items || []}
      blurOnSelect
      getOptionSelected={getOptionSelected}
      onChange={handleChange}
      getOptionLabel={getOptionLabel}
      style={{ width: 300 }}
      renderInput={renderInput}
    />
  ) : null
}
