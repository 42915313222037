import { Box } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"
import { css } from "@emotion/react"
import { APP_CONTENT_ID, APP_HEADER_ID, APP_FOOTER_ID, APP_ID } from "app/constants"

export const disableHeaderFooterStyles = css({
  [`#${APP_ID}`]: {
    paddingTop: 0,
  },
  [`#${APP_HEADER_ID}`]: {
    display: "none",
  },
  [`#${APP_FOOTER_ID}`]: {
    display: "none",
  },
  [`#${APP_CONTENT_ID}`]: {
    marginTop: 0,
    height: "100%",
  },
})

export const ActionButton = styled(Box)(({ theme }) => ({
  color: theme.palette.info.main,
  margin: theme.spacing(0, 0.5),
  "&:hover": {
    cursor: "pointer",
  },
}))

export const MainTitle = styled(Box)(({ theme }) => ({
  fontSize: "22px",
  lineHeight: 1.55,
  fontWeight: "bold",
  marginBottom: theme.spacing(1),
}))

export const Subtitle = styled(Box)(({ theme }) => ({
  fontWeight: 200,
  fontSize: "18px",
  marginBottom: theme.spacing(2),
  letterSpacing: "-0.36px",
}))
