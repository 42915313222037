import { AlertProps } from "@material-ui/lab"
import { INTAKE_STATUSES } from "requests/constants"

export interface RequestStatusMessage {
  severity?: AlertProps["severity"]
  title?: string
  message: string
}

export const IN_PROGRESS_MESSAGE: RequestStatusMessage = {
  title: "We are working on your request.",
  message: "There is no action needed on your part currently.",
}

export const IN_PROGRESS_REVISION_MESSAGE: RequestStatusMessage = {
  title: "Your revision request is In Progress.",
  message: "We are currently working on your request. There is no action needed on your part currently.",
}

export const REQUEST_STATUS_MESSAGES: Partial<Record<INTAKE_STATUSES, RequestStatusMessage>> = {
  [INTAKE_STATUSES.requested]: {
    title: "We have received your request.",
    message: "Someone on our team will be working on it shortly.",
  },
  [INTAKE_STATUSES.inProgress]: IN_PROGRESS_MESSAGE,
  [INTAKE_STATUSES.rework]: {
    title: "Your rework request has been received.",
    message: "A legal operations associate will contact you shortly.",
  },
  [INTAKE_STATUSES.completed]: {
    title: "Your demand package is ready.",
    message: 'If you would like to request a revision, please click the tab at the top "Revision".',
  },
  [INTAKE_STATUSES.ready_for_tasker]: IN_PROGRESS_MESSAGE,
  [INTAKE_STATUSES.tasker_assigned]: IN_PROGRESS_MESSAGE,
  [INTAKE_STATUSES.tasker_completed]: IN_PROGRESS_MESSAGE,
}

export const REQUEST_WITH_REVISION_STATUS_MESSAGES: Partial<Record<INTAKE_STATUSES, RequestStatusMessage>> = {
  [INTAKE_STATUSES.requested]: {
    title: "We have received your revision request.",
    message: "Someone on our team will be working on it shortly.",
  },
  [INTAKE_STATUSES.inProgress]: IN_PROGRESS_REVISION_MESSAGE,
  [INTAKE_STATUSES.completed]: {
    title: "Your demand package is ready.",
    message: 'If you would like to request a revision, please click the tab at the top "Revision".',
  },
  [INTAKE_STATUSES.ready_for_tasker]: IN_PROGRESS_REVISION_MESSAGE,
  [INTAKE_STATUSES.tasker_assigned]: IN_PROGRESS_REVISION_MESSAGE,
  [INTAKE_STATUSES.tasker_completed]: IN_PROGRESS_REVISION_MESSAGE,
}

export const REQUEST_STATUS_REWORK_MESSAGES: Partial<Record<INTAKE_STATUSES, RequestStatusMessage>> = {
  ...REQUEST_STATUS_MESSAGES,
  [INTAKE_STATUSES.completed]: {
    title: "Your demand package is ready.",
    message: 'If you would like to request a rework, please click the "Request Rework" button.',
  },
}

export const REQUEST_STATUS_MESSAGES_COMMON: Partial<Record<INTAKE_STATUSES, RequestStatusMessage>> = {
  [INTAKE_STATUSES.completed]: {
    severity: "success",
    message: "Request has been completed.",
  },
  [INTAKE_STATUSES.cancelled]: {
    severity: "error",
    title: "This request has been cancelled.",
    message: "Please create a new request to reinitiate.",
  },
  [INTAKE_STATUSES.redelivered]: {
    severity: "success",
    message: "Request has been redelivered.",
  },
}
