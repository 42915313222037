import React, { useEffect, useState, useCallback } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { Box, IconButton, InputAdornment, TextField } from "@material-ui/core"
import { Card, CardActionArea, CardMedia, CardHeader, CardActions } from "@material-ui/core"
import { Tooltip } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import { DeleteOutlined as DeleteIcon } from "@material-ui/icons"
import { useHandleMessages } from "./messages/useHandleMessages"
import LinkButton from "./buttons/LinkButton"
import TextButton from "./buttons/TextButton"
import splitFileName from "./form-components/files/splitFileName"
import { MAX_FILE_NAME_LENGTH } from "../demand/constants"
import { updateExhibit } from "../api"
import { validateFile } from "../demand/validation"

const useStyles = makeStyles(theme => ({
  cardHeader: {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    padding: theme.spacing(1, 1, 0, 1),
    "& > .MuiCardHeader-content": {
      overflow: "hidden",
    },
    "& span": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  },
  listCard: {
    width: "300px",
    margin: theme.spacing(1, 1, 1, 0),
  },
  list: {
    padding: theme.spacing(1, 0),
    display: "flex",
    overflow: "auto",
  },
  cardActions: {
    justifyContent: "end",
  },
}))

const ImageExhibit = ({
  caseId,
  exhibitId,
  imageName,
  imageUrl,
  onMediaClick,
  onDelete,
  onSuccessfulSave,
}) => {
  const classes = useStyles()
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)
  const { showMessage } = useHandleMessages()

  const [name, setName] = useState(imageName)
  const [splitName, extension] = splitFileName(name)

  const handleNameChange = event => {
    setName(`${event.target.value}${extension}`)
  }

  const handleMouseDown = useCallback(() => {
    if (document.activeElement?.blur) {
      document.activeElement.blur()
    }
  }, [])

  const handleReset = () => {
    setEditing(false)
    setSaving(false)
    setError(null)
    setName(imageName)
  }

  const handleSave = async () => {
    const imageValidation = validateFile({ name, type: "anything" })
    if (imageValidation) {
      setError(imageValidation)
      return
    }

    setSaving(true)
    setError(null)

    try {
      await updateExhibit({
        caseId,
        exhibitId,
        data: {
          case_id: caseId,
          pk: exhibitId,
          name,
        },
      })
    } catch (error) {
      // no possible validation errors since name is validated by front end
      showMessage({
        type: "error",
        message: "Error updating image. Please try again shortly and if your problem persists contact a dev.",
      })
      setSaving(false)
      return
    }

    showMessage({
      type: "success",
      message: "Successfully updated image.",
    })
    setSaving(false)
    setEditing(false)
    onSuccessfulSave()
  }

  return (
    <Card className={classes.listCard} variant="outlined">
      <CardActionArea>
        <CardMedia
          component="img"
          height="100px"
          className={classes.listImage}
          image={imageUrl}
          alt={name}
          onClick={onMediaClick}
        />
      </CardActionArea>
      {editing ? (
        <Box pt={1} px={1}>
          <TextField
            label="Image Name"
            fullWidth
            value={splitName}
            variant="outlined"
            margin="dense"
            onChange={handleNameChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">{extension}</InputAdornment>,
            }}
            disabled={saving}
            // slightly less than the max to account for file ext getting added automatically
            inputProps={{ maxLength: MAX_FILE_NAME_LENGTH - extension.length }}
            error={!!error}
            helperText={error && error.name}
          />
        </Box>
      ) : (
        <CardHeader
          className={classes.cardHeader}
          title={
            <Tooltip title={name}>
              <span>{name}</span>
            </Tooltip>
          }
          titleTypographyProps={{ variant: "h6" }}
        />
      )}

      <CardActions className={classes.cardActions}>
        {editing ? (
          <>
            <TextButton textColor="blue" onClick={handleSave}>
              Save
            </TextButton>
            <TextButton textColor="grey" onClick={handleReset}>
              Cancel
            </TextButton>
          </>
        ) : (
          <>
            <LinkButton onClick={() => setEditing(true)}>Edit</LinkButton>
            <IconButton onMouseDown={handleMouseDown} onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </CardActions>
    </Card>
  )
}

/**
 * A gallery for images. `images` should be a list of objects that have a name
 * property. `downloadImage(image) should give the image data.
 */
export default function DnDExhibitList({
  id,
  caseId,
  exhibits,
  onDelete,
  onDownload,
  onReorder,
  onMediaClick,
  onSuccessfulSave,
}) {
  const [imageBlobs, setImageBlobs] = useState([])
  const classes = useStyles()
  const { showMessage } = useHandleMessages()

  useEffect(() => {
    if (!exhibits) {
      return
    }

    const images = exhibits
      .filter(e => !!e.image)
      .map(image => {
        return { ...image, url: image.image }
      })
    setImageBlobs(images)

    return () => images.forEach(image => URL.revokeObjectURL(image.url))
  }, [exhibits, caseId, onDownload, showMessage])

  const onDragEndInner = ({ source: src, destination: dst }) => {
    if (!src || !dst) {
      return
    }
    if (src.index == dst.index) {
      return
    }
    const [movedItem] = exhibits.splice(src.index, 1)
    exhibits.splice(dst.index, 0, movedItem)
    onReorder(exhibits)
  }

  return (
    <DragDropContext onDragEnd={onDragEndInner}>
      <Droppable droppableId={id} direction="horizontal">
        {provided => (
          <div ref={provided.innerRef} className={classes.list} {...provided.droppableProps}>
            {imageBlobs.map((image, index) => (
              <Draggable key={image.pk} draggableId={String(image.pk)} index={index}>
                {provided => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <ImageExhibit
                      caseId={caseId}
                      exhibitId={image.pk}
                      imageName={image.name}
                      imageUrl={image.url}
                      onMediaClick={() => onMediaClick(image)}
                      onDelete={() => onDelete(image.pk)}
                      onSuccessfulSave={() => onSuccessfulSave()}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
