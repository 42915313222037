import React from "react"
import { Box, IconButton } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import { makeStyles } from "@material-ui/core/styles"

import { InputField, RadioButtons } from "../../common/form-components"
import { useRequestContext } from "../context"

import { INDIVIDUAL, ENTITY, DEFENDANT_TYPES } from "./../constants"

const useStyles = makeStyles(theme => ({
  defendantContainer: {
    display: "grid",
    margin: theme.spacing(2, 0, 0, 0),
    gap: theme.spacing(4),
    border: `solid ${theme.spacing(0.125)}px #D4D4D4`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  defendantIndividual: {
    gridTemplateColumns: "1fr 3fr 3fr 2fr 1fr",
    gap: theme.spacing(4),
  },
  defendantEntity: {
    gridTemplateColumns: "1fr 8fr 1fr",
  },
  verticalAlign: {
    margin: theme.spacing("auto", 0),
  },
  deleteDefendant: {
    marginLeft: "auto",
  },
}))

const DefendantDetails = ({ index, type }) => {
  const { control, errors } = useRequestContext()
  const classes = useStyles()

  switch (type) {
    case INDIVIDUAL:
      return (
        <>
          <InputField
            control={control}
            className={classes.verticalAlign}
            name={`defendants.${index}.first_name`}
            type="text"
            label="Defendant's first name"
            data-test="defendant-first-name"
            variant="outlined"
            errors={errors}
            rules={{
              required: "This is required",
              maxLength: { value: 50, message: "Ensure this field has no more than 50 characters." },
            }}
          />

          <InputField
            control={control}
            name={`defendants.${index}.last_name`}
            className={classes.verticalAlign}
            data-test="defendant-last-name"
            type="text"
            label="Defendant's last name"
            variant="outlined"
            errors={errors}
            rules={{
              required: "This is required",
              maxLength: { value: 50, message: "Ensure this field has no more than 50 characters." },
            }}
          />

          <RadioButtons
            control={control}
            name={`defendants.${index}.gender`}
            options={[
              { key: "m", display: "Male" },
              { key: "f", display: "Female" },
              { key: "u", display: "Unspecified" },
              { key: "o", display: "Other" },
            ]}
            row={true}
          />
        </>
      )
    case ENTITY:
      return (
        <>
          <InputField
            control={control}
            className={classes.verticalAlign}
            name={`defendants.${index}.name`}
            type="text"
            label="Defendant's name"
            variant="outlined"
            errors={errors}
            rules={{ required: "This is required" }}
          />
        </>
      )
    default:
      return <></>
  }
}

export const DefendantForm = ({ index, remove }) => {
  const { control, watch } = useRequestContext()
  const classes = useStyles()
  const type = watch(`defendants.${index}.type`)

  let containerClass = classes.defendantIndividual
  switch (type) {
    case INDIVIDUAL:
      containerClass = classes.defendantIndividual
      break
    case ENTITY:
      containerClass = classes.defendantEntity
      break
  }

  return (
    <Box className={`${classes.defendantContainer} ${containerClass}`} data-test="defendant-form">
      <RadioButtons
        control={control}
        className={classes.verticalAlign}
        name={`defendants.${index}.type`}
        options={DEFENDANT_TYPES}
      />

      <DefendantDetails index={index} type={type} />

      <IconButton
        className={`${classes.deleteDefendant} ${classes.verticalAlign}`}
        disabled={index === 0}
        onClick={() => {
          remove(index)
        }}
        data-test="delete-defendant-button"
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  )
}
