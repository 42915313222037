import { Box } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"

export const FileAttributes = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  "& > .MuiFormControl-root": {
    width: "15rem",
  },

  "& > :not(:first-child)": {
    marginLeft: theme.spacing(2),
  },
}))

export const FileNameLabel = styled("span")({
  fontWeight: "bold",
})

export const FileWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  "&:not(:first-child)": {
    marginTop: theme.spacing(2),
  },
}))

export const FilesContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 4),
}))
