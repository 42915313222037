import React from "react"
import { HandleMessagesContext } from "./HandleMessagesContext"
import { Message } from "./Message"

interface HandleMessagesProviderProps {
  handleMessage(message: Message, timeout: number): void
}

export const HandleMessagesProvider: React.FC<HandleMessagesProviderProps> = React.memo(
  function HandleMessagesProvider({ handleMessage, children }) {
    return (
      <HandleMessagesContext.Provider value={{ handleMessage }}>{children}</HandleMessagesContext.Provider>
    )
  }
)
