import { useMemo, useRef } from "react"
import { debounce, DebouncedFunc } from "lodash"

type Callback = (...args: any[]) => void

const DEBOUNCED_TIME = 800

export function useDebouncedCallback<T extends Callback>(callback: T): DebouncedFunc<T> {
  const ref = useRef<T>(callback)

  ref.current = callback

  return useMemo(() => debounce<T>(((...args) => ref.current(...args)) as T, DEBOUNCED_TIME), [ref])
}
