import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { Box, Typography, Button } from "@material-ui/core"
import { useQuery } from "react-query"

import { queryKeys } from "../../react-query/constants"
import { getAttorney } from "../../api"
import AttorneyForm from "./AttorneyForm"

export function Attorney() {
  const { attorneyId, id: firmId } = useParams()
  const [isEditing, setIsEditing] = useState(false)
  const { isLoading, data: attorney } = useQuery([queryKeys.attorney, firmId, attorneyId, true], getAttorney)

  return (
    <Box>
      {!isLoading && !isEditing && (
        <>
          <Typography variant="h4" component="h1">{`${attorney.first_name || ""} ${
            attorney.last_name || ""
          }`}</Typography>
          <Button variant="contained" color="primary" onClick={() => setIsEditing(true)}>
            Edit Attorney
          </Button>
        </>
      )}
      {isEditing && (
        <AttorneyForm data={{ ...attorney, firm_id: firmId }} callback={() => setIsEditing(false)} />
      )}
    </Box>
  )
}
