import { Table, TableBody, TableHead, TableRow } from "@material-ui/core"
import styled from "@emotion/styled"
import React from "react"
import { displayPageRange } from "../rangeUtils"
import {
  AnnotationTableCell,
  AnnotationTableHeader,
  AnnotationTableRow,
  LastAnnotationTableCell,
} from "../styled"
import { CaseId } from "../types"
import { RelevantDeletion } from "./getRelevantDeletions"
import { upperFirst } from "lodash"
import DeletionMoreButtonComponent from "./DeletionMoreButton"

const UnstyledUL = styled("ul")(({ theme }) => ({
  listStyle: "none",
  margin: theme.spacing(0.5, 0),
  padding: 0,
}))

const DeletionMoreButton = styled(DeletionMoreButtonComponent)({
  visibility: "hidden",
})

const DeletionTableRow = styled(AnnotationTableRow)(() => ({
  [`&:hover ${DeletionMoreButton}`]: {
    visibility: "visible",
  },
}))

interface DeletionTableProps {
  relevantDeletions: RelevantDeletion[]
  caseId: CaseId
  exhibitId: number
  duplicateId?: Nullable<number>
  onPreview: (deletion: RelevantDeletion) => unknown
}

const DeletionTable: React.FC<DeletionTableProps> = ({
  relevantDeletions,
  caseId,
  exhibitId,
  duplicateId,
  onPreview,
}) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <AnnotationTableHeader align="left" colSpan={3}>
            Deleted Pages
          </AnnotationTableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {relevantDeletions.map((deletion, index) => (
          <DeletionTableRow key={index} onClick={() => onPreview(deletion)}>
            <AnnotationTableCell width="150px">
              Pages:&nbsp;{displayPageRange(deletion.start_page, deletion.end_page)}
            </AnnotationTableCell>
            <AnnotationTableCell>
              {deletion.excerpts.length > 0 ? (
                <UnstyledUL>
                  {deletion.excerpts.map((excerpt, index) => (
                    <li key={index}>{`${excerpt.provider_name} - ${upperFirst(
                      excerpt.relation_type
                    )} (p.${displayPageRange(excerpt.start_page, excerpt.end_page)})`}</li>
                  ))}
                </UnstyledUL>
              ) : (
                "No annotations"
              )}
            </AnnotationTableCell>
            <LastAnnotationTableCell align="right" width="30px">
              <DeletionMoreButton
                caseId={caseId}
                exhibitId={exhibitId}
                duplicateId={duplicateId}
                actionId={deletion.pk}
              />
            </LastAnnotationTableCell>
          </DeletionTableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default DeletionTable
