import React from "react"
import { Navigate, useLocation } from "react-router-dom"
import { Loading } from "common/loading"
import useUser from "../hooks/useUser"

// A wrapper for <Route> that redirects to the login
// screen if user is not yet authenticated.
export default function RequireAuth({ restrictAccess = [], children, loading }) {
  const location = useLocation()
  const { user, isLoading, isInitialized, error } = useUser()

  if (isLoading || !isInitialized || loading) return <Loading />

  if (user.isAuthorized && !isLoading && !error) {
    const requiresAccess = restrictAccess?.length
    const userRole = user.role
    const hasAccess = restrictAccess?.includes(userRole)

    return requiresAccess && !hasAccess ? <Navigate to="/access-denied" replace /> : children
  }

  return (
    <Navigate to={`/login?redirectTo=${location.pathname}`} replace state={{ path: location.pathname }} />
  )
}
