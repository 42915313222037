import React, { useCallback } from "react"
import { FieldValues, useWatch } from "react-hook-form"
import { RichTextField, RichTextFieldProps } from "common/form-components/rich-text"
import { RichTextEditor, RichTextEditorProps } from "common/form-components/rich-text/RichTextEditor"
import { EditorFeatureProps, PropsByFeature } from "common/form-components/rich-text/features/types"
import { EDITOR_FEATURES } from "common/form-components/rich-text/features/constants"
import { FeatureProps } from "common/types/features"
import { Variable } from "common/types/variables"
import { useCaseVariables } from "demand/Variables"
import { RichTextInput, RichTextInputProps } from "common/form-components/rich-text/RichTextInput"
import { CASE_SECTIONS } from "common/types/sections"
import { CaseTemplatedSection } from "api/services/case/types"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import { Button } from "@material-ui/core"
import { DEFAULT_VALUE } from "common/form-components/rich-text/defaultValue"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { TemplatePreview } from "./TemplatePreview"
import { QueryKey, useIsFetching, useMutation, useQueryClient } from "react-query"
import { caseService } from "api/services/case"
import { Loading } from "common"
import { isEqual } from "lodash"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { StyledNoTemplate } from "./styled"

interface CaseVariablesProps {
  variables: Variable[]
}

type EditorFeaturePropsWithoutVariables = Omit<
  EditorFeatureProps,
  keyof FeatureProps<Pick<PropsByFeature, EDITOR_FEATURES.VARIABLES>>
>

type CaseEditorFieldProps<T extends FieldValues> = RichTextFieldProps<T> &
  EditorFeaturePropsWithoutVariables &
  CaseVariablesProps

type CaseEditorProps = RichTextEditorProps & EditorFeaturePropsWithoutVariables & CaseVariablesProps

export type CaseEditorInputProps = RichTextInputProps &
  EditorFeaturePropsWithoutVariables &
  CaseVariablesProps

type CaseInvalidationProps = {
  invalidateQueryKeys?: QueryKey[]
}

type CaseTemplatedSections = {
  sections: Partial<Record<CASE_SECTIONS, CaseTemplatedSection>>
}

export function CaseEditorField<T extends FieldValues & CaseTemplatedSections>({
  variables: customVariables = [],
  section,
  invalidateQueryKeys = [],
  ...props
}: CaseEditorFieldProps<T> & { section?: CASE_SECTIONS } & CaseInvalidationProps): JSX.Element {
  const { variables } = useCaseVariables(customVariables)
  const { isFeatureEnabled } = useFeatures()
  const watch = useWatch({ control: props.control })
  const { showMessage } = useHandleMessages()
  const isSectionsFeatureEnabled = isFeatureEnabled(FEATURES.CASE_ATTRIBUTES)

  const queryClient = useQueryClient()
  const { mutate: overrideCaseSection, isLoading } = useMutation(caseService.overrideCaseSection, {
    onSuccess: () => {
      for (const queryKey of invalidateQueryKeys) {
        queryClient.invalidateQueries(queryKey)
      }
    },
    onError: () => {
      showMessage({
        type: "error",
        message: "There was an error while trying to update content.",
      })
    },
  })

  const fetchingQueriesCount = useIsFetching()
  const isFetching =
    isLoading ||
    !!(fetchingQueriesCount && invalidateQueryKeys.some(queryKey => queryClient.isFetching({ queryKey })))

  const handleContentResolve = useCallback(() => {
    if (!section || !watch.sections?.[section]) return

    const templatedSection = watch.sections[section] as CaseTemplatedSection

    overrideCaseSection({ caseId: templatedSection.caseId, data: templatedSection })
  }, [watch, section, overrideCaseSection])

  if (isSectionsFeatureEnabled && section && watch.sections?.[section]) {
    const templatedSection = watch.sections[section]
    const isReviewNeeded = templatedSection?.userActionRequired ?? false
    const template = templatedSection?.template
    const isInReview = isReviewNeeded && template

    return (
      <>
        <Loading show={isFetching} label="Loading..." />
        <RichTextField
          {...props}
          withVariables
          variables={variables}
          name={`sections.${section}.content` as CaseEditorFieldProps<T>["name"]}
          markdownName={undefined}
          error={isReviewNeeded}
          actions={
            isReviewNeeded ? (
              <Button variant="contained" disableElevation size="small" onClick={handleContentResolve}>
                Resolve
              </Button>
            ) : null
          }
        />
        {isInReview &&
          (isEqual(template, DEFAULT_VALUE) ? (
            <StyledNoTemplate>No template is returned</StyledNoTemplate>
          ) : (
            <TemplatePreview template={template as EditorRoot} variables={variables} />
          ))}
      </>
    )
  }

  return <RichTextField {...props} withVariables variables={variables} />
}

export function CaseEditor({ variables: customVariables = [], ...props }: CaseEditorProps): JSX.Element {
  const { variables } = useCaseVariables(customVariables)

  return <RichTextEditor {...props} withVariables variables={variables} />
}

export function CaseEditorInput({
  variables: customVariables = [],
  ...props
}: CaseEditorInputProps): JSX.Element {
  const { variables } = useCaseVariables(customVariables)

  return <RichTextInput {...props} withVariables variables={variables} />
}
