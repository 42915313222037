import React from "react"
import { Box, CircularProgress, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useIsMutating } from "react-query"
import { formatTimeSinceNow } from "../utils"
import { useRequestContext } from "./context"

const useStyles = makeStyles(theme => ({
  progressWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& > p": {
      marginLeft: theme.spacing(1),
    },
  },
}))

export function SavingIndicator() {
  const isMutating = useIsMutating() > 0
  const classes = useStyles()
  const { getValues } = useRequestContext()
  const lastModified = getValues("updated_at")

  return (
    <>
      {isMutating && (
        <Box className={classes.progressWrapper}>
          <CircularProgress size={20} color="secondary" />
          <span>&nbsp;</span>
          <Typography variant="caption" component="p">
            Saving...
          </Typography>
        </Box>
      )}
      {!isMutating && !lastModified && (
        <Typography variant="caption">Input will be saved when going to the next page</Typography>
      )}
      {!isMutating && lastModified && (
        <Typography variant="caption">Last saved: {formatTimeSinceNow(lastModified)}</Typography>
      )}
    </>
  )
}
