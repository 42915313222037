import React, { useEffect } from "react"
import { useQuery } from "react-query"
import { queryKeys } from "react-query/constants"
import { attributesService } from "api/services/attributes"
import { caseService } from "api/services/case"
import { useDemandSteps } from "demand/steps"
import { FORM_SECTION_ROUTES } from "demand/steps/constants"
import { CASE_ATTRIBUTES_STEP } from "demand/steps/types"
import { Templates } from "./Templates"
import { fetchRequest, getFirmExtended, getIntakeAsset } from "api"
import { ASSET_KEYS } from "requests/constants"

interface TemplatesProviderProps {
  lastVisited: React.MutableRefObject<string>
}

export function TemplatesProvider({ lastVisited }: TemplatesProviderProps): JSX.Element {
  const { caseId } = useDemandSteps()
  const { data: attributes } = useQuery(queryKeys.attributes, async () =>
    attributesService.getAvailableAttributes(null)
  )
  const { data: caseInfo } = useQuery(
    [queryKeys.case, caseId, "-serialized"],
    () => caseService.getCase({ caseId: caseId as PrimaryKey }),
    { enabled: caseId !== null }
  )

  const { data: requestInfo } = useQuery([queryKeys.request, caseInfo?.questionnaireId], fetchRequest, {
    enabled: caseInfo?.questionnaireId !== null,
  })

  const { data: caseTypeAsset } = useQuery([queryKeys.intakeAsset, ASSET_KEYS.caseTypes], () => {
    const results = getIntakeAsset({ queryKey: [null, ASSET_KEYS.caseTypes] })
    return results
  })

  const hasCorrespondingRequest = caseInfo && caseInfo.questionnaireId !== null
  const hasFirm = caseInfo && caseInfo.firm !== null && caseInfo.firm.pk !== null

  const { data: firmInfo } = useQuery([queryKeys.firm, caseInfo?.firm?.pk], getFirmExtended, {
    enabled: hasFirm,
  })

  useEffect(() => {
    lastVisited.current = FORM_SECTION_ROUTES[CASE_ATTRIBUTES_STEP]
  }, [lastVisited])

  if (
    !attributes ||
    !caseInfo ||
    !caseTypeAsset ||
    !caseTypeAsset?.results ||
    (hasFirm && !firmInfo) ||
    (hasCorrespondingRequest && !requestInfo)
  ) {
    return <></>
  }

  return (
    <Templates
      attributes={attributes}
      caseInfo={caseInfo}
      firmInfo={firmInfo}
      requestInfo={requestInfo}
      caseTypeAsset={caseTypeAsset?.results}
    />
  )
}
