import React from "react"
import { Box, IconButton, Paper, Typography } from "@material-ui/core"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFileOutlined"
import { makeStyles } from "@material-ui/core/styles"

import clsx from "clsx"

import { RequestView } from "../requests/ViewRequest/Request"

import { FLOATING_PANEL_WIDTH } from "./constants"
import { GoToRequestButton } from "common/buttons/GoToRequestButton"

const useStyles = makeStyles(theme => ({
  floating: {
    zIndex: 100,
    position: "fixed",
    right: 0,
    top: 160,
    backgroundColor: "white",
    transition: "height 0.3s, width 0.3s",
  },
  floatingBox: {
    height: "inherit",
    maxHeight: "75vh",
    width: FLOATING_PANEL_WIDTH,
    overflow: "overlay",
  },
  floatingButton: {
    margin: "auto",
    display: "flex",
    width: "80px",
    height: "50px",
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#EBEBEB",
      cursor: "pointer",
    },
  },
  buttonSpacing: {
    display: "flex",
    justifyContent: "space-between",
    "& *": {
      margin: theme.spacing("auto", 1),
    },
  },
  icon: {
    color: "white",
    backgroundColor: "black",
    borderRadius: "50%",
    padding: "2px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0.5rem 1rem",
    maxHeight: "10%",
  },
  headerLeft: {
    display: "flex",
    flexDirection: "row",
    "& *": {
      margin: theme.spacing("auto", 1, "auto", 0),
    },
  },
  title: {
    fontWeight: 600,
    fontSize: "1.25rem",
  },
  requestContianer: {
    height: "100%",
    overflow: "scroll",
  },
}))

interface WindowProps {
  request: any
  open: boolean
  toggleOpened: () => void
  expandedSections?: []
  highlightedFields?: []
}

interface ButtonProps {
  onClick: () => void
}

const FloatingButton = ({ onClick }: ButtonProps) => {
  const classes = useStyles()
  return (
    <Box className={classes.buttonSpacing} onClick={onClick} data-test="request-panel-button">
      <InsertDriveFileIcon className={classes.icon} />
      <ChevronLeftIcon />
    </Box>
  )
}

const RequestPanel = ({
  request,
  open,
  toggleOpened,
  expandedSections = [],
  highlightedFields = [],
}: WindowProps): JSX.Element => {
  const classes = useStyles()

  if (!request?.pk) {
    return <></>
  }

  return (
    <Paper
      className={clsx(classes.floating, open && classes.floatingBox, !open && classes.floatingButton)}
      elevation={3}
      data-test="request-panel-container"
    >
      {open ? (
        <Box data-test="request-panel">
          <Box className={classes.header}>
            <Box className={classes.headerLeft}>
              <InsertDriveFileIcon className={classes.icon} />
              <Typography className={classes.title}>Intake Request</Typography>
            </Box>
            <Box>
              <IconButton onClick={toggleOpened} data-test="close-request-panel-button">
                <ChevronRightIcon />
              </IconButton>
            </Box>
          </Box>
          <Box className={classes.header}>
            <GoToRequestButton />
          </Box>
          <Box className={classes.requestContianer}>
            {request && (
              <RequestView
                request={request}
                isPanel={true}
                expandedGroups={expandedSections}
                highlightedFields={highlightedFields}
              />
            )}
          </Box>
        </Box>
      ) : (
        <FloatingButton onClick={toggleOpened} />
      )}
    </Paper>
  )
}

export { RequestPanel as default }
