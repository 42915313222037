import { BaseServiceDeserializer } from "api/BaseDeserializer"
import { AttributeFiltersData } from "common/attributes-filter"
import { PaginatedList } from "common/models/pagination"
import { CASE_SECTIONS } from "common/types/sections"
import { NewSectionTemplate, SectionTemplateDefinition } from "common/types/templates"
import {
  MatchingTemplates,
  SectionTemplateAttributeDto,
  SectionTemplateDto,
  SectionTemplateItemAttributeDto,
  SectionTemplateItemDto,
} from "./types"

export class SectionTemplateServiceDeserializer {
  static definitionFromJSON(template: SectionTemplateItemDto): SectionTemplateDefinition {
    return {
      id: template.pk,
      section: template.section,
      content: template.content,
      updatedAt: BaseServiceDeserializer.fromDateString(template.created_at),
      attributes: SectionTemplateServiceDeserializer.attributesDisplayFromListJSON(template.attributes),
    }
  }

  static fromListJSON(templates: SectionTemplateItemDto[]): SectionTemplateDefinition[] {
    return templates.map(SectionTemplateServiceDeserializer.definitionFromJSON)
  }

  static fromPaginatedListJSON(
    templates: PaginatedList<SectionTemplateItemDto>
  ): PaginatedList<SectionTemplateDefinition> {
    return new PaginatedList(
      SectionTemplateServiceDeserializer.fromListJSON(templates.items),
      templates.count,
      templates.pageSize,
      templates.page
    )
  }

  static attributeDisplayFromJSON(
    attributeValue: SectionTemplateItemAttributeDto
  ): SectionTemplateDefinition["attributes"][number] {
    return {
      id: attributeValue.attribute_id,
      valueId: attributeValue.value_id,
      displayValue: attributeValue.value,
    }
  }

  static attributesDisplayFromListJSON(
    attributes: SectionTemplateItemAttributeDto[]
  ): SectionTemplateDefinition["attributes"] {
    return attributes.map(SectionTemplateServiceDeserializer.attributeDisplayFromJSON)
  }

  static templatesBySectionFromJSON(
    templates: Partial<Record<CASE_SECTIONS, Nullable<SectionTemplateItemDto>>>
  ): MatchingTemplates {
    return Object.fromEntries(
      Object.entries(templates)
        .filter((entry): entry is [CASE_SECTIONS, SectionTemplateItemDto] => entry[1] !== null)
        .map(([section, template]) => [
          section,
          SectionTemplateServiceDeserializer.definitionFromJSON(template),
        ])
    )
  }
}

export class SectionTemplateServiceSerializer {
  static toAttributeValuesJSON(values: AttributeFiltersData): number[] {
    return Object.values(values).filter((value): value is SectionTemplateAttributeDto["pk"] => value !== null)
  }

  static toDefinitionJSON(template: NewSectionTemplate): SectionTemplateDto {
    const attributeValues = SectionTemplateServiceSerializer.toAttributeValuesJSON(template.attributes)

    return {
      section: template.section,
      attribute_ids: attributeValues,
      content: template.content,
    }
  }
}
