import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react"
import { ClickAwayListener, Paper, Popper, PopperProps } from "@material-ui/core"
import { useMemo } from "react"
import { groupBy } from "lodash"
import { KeysFor } from "common/types/helper"
import { GroupMenuItem } from "./GroupMenuItem"
import { LeafMenuItem } from "./LeafMenuItem"
import { SearchInput, MenuList, MenuWithSearch } from "./styled"
import { filterItems } from "./utils"

interface MultiLevelMenuProps<T extends BaseObject> {
  anchorRef: React.RefObject<Element>
  open: boolean
  group: Nullable<KeysFor<T, string> | KeysFor<T, string>[]>
  label: KeysFor<T, string>
  displayLabel?: KeysFor<T, Nullable<string> | undefined>
  items: T[]
  onSelect: (value: T) => void
  onHover?: (value: Nullable<T>) => void
  onClose?: () => void
  placement?: PopperProps["placement"]
  zIndexAdjuster?: number
}

// React.forwardRef type does not support of returning Generic components
// So we need to cast to "custom" type that is compatible with React.forwardRef and supports generics
export const MultiLevelMenu = forwardRef(MultiLevelMenuComponent) as unknown as <T extends BaseObject>(
  props: MultiLevelMenuProps<T> & { ref?: React.ForwardedRef<Element> }
) => ReturnType<typeof MultiLevelMenuComponent>

function MultiLevelMenuComponent<T extends BaseObject>(
  {
    anchorRef,
    open,
    group = null,
    items,
    onSelect,
    onClose,
    onHover,
    label,
    displayLabel,
    placement,
    zIndexAdjuster = 0,
  }: MultiLevelMenuProps<T>,
  ref: React.ForwardedRef<Element>
): JSX.Element {
  // Open/close states
  const [nestedMenuOpened, setNestedMenuOpened] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState<Nullable<string>>(null)

  // Search state
  const [searchText, setSearchText] = useState("")

  // Refs
  const nestedAnchorRef = useRef<Nullable<Element>>(null)
  const nestedMenuRef = useRef<Nullable<Element>>(null)
  const inputRef = useRef<Nullable<HTMLInputElement>>(null)

  // Group settings
  const currentGroup = Array.isArray(group) ? group[0] : group
  const nestedGroup = Array.isArray(group) && group.length > 1 ? group.slice(0, -1) : null

  // Getting items for current and next level of menu
  const groupedItems = useMemo(
    () => (currentGroup ? groupBy(items, currentGroup) : {}),
    [items, currentGroup]
  )
  const groupItems = useMemo(
    () => filterItems(Object.keys(groupedItems), searchText),
    [groupedItems, searchText]
  )
  const nestedItems = useMemo(
    () => (selectedGroup && groupedItems[selectedGroup]) || [],
    [groupedItems, selectedGroup]
  )
  const leafItems = useMemo(() => {
    if (currentGroup) return []

    return Array.from(
      new Set([
        ...filterItems(items, searchText, label),
        ...(displayLabel ? filterItems(items, searchText, displayLabel) : []),
      ])
    )
  }, [searchText, items, label, displayLabel, currentGroup])

  // Handlers
  const closeNestedMenu = useCallback(() => {
    setNestedMenuOpened(false)
    setSelectedGroup(null)
  }, [])

  const handleGroupClick = useCallback(
    (group: string) => {
      if (selectedGroup === group) return closeNestedMenu()

      setNestedMenuOpened(true)
      setSelectedGroup(group)
    },
    [selectedGroup, closeNestedMenu]
  )

  const handleClose = useCallback(() => {
    closeNestedMenu()
    onClose && onClose()
  }, [onClose, closeNestedMenu])

  const handleClickAway = useCallback(
    (e: React.MouseEvent<Document, MouseEvent>) => {
      const nestedRoot = nestedMenuRef.current
      const target = (e.target as Nullable<Element>) || e.currentTarget

      // If clicked inside nested menu - don't close current menu
      if (nestedMenuOpened && nestedRoot && target && nestedRoot.contains(target)) {
        return
      }

      // If click on or inside anchor element - don't close current menu
      if (anchorRef.current && (anchorRef.current === target || anchorRef.current.contains(target))) {
        return
      }

      handleClose()
    },
    [anchorRef, nestedMenuRef, nestedMenuOpened, handleClose]
  )

  const handleSearchChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    setSearchText(e.target.value.toLowerCase())
  }, [])

  const handleSearchKeydown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
  }, [])

  // Effects
  useEffect(() => {
    // Close nested menu if current is also closed
    if (!open) {
      closeNestedMenu()
      setSearchText("")
    }
  }, [open, closeNestedMenu])

  useEffect(() => {
    // Set focus to search input when
    // - current menu opens
    // - nested menu closes
    if (open && !nestedMenuOpened && inputRef.current) {
      inputRef.current.focus()
    }
  }, [open, inputRef, nestedMenuOpened])

  const getAnchorElementRect: PopperProps["anchorEl"] = useCallback(
    () => ({
      clientWidth: anchorRef.current?.clientWidth || 0,
      clientHeight: anchorRef.current?.clientHeight || 0,
      getBoundingClientRect: () =>
        anchorRef.current ? anchorRef.current.getBoundingClientRect() : ({} as DOMRect),
      referenceNode: anchorRef.current ?? undefined,
    }),
    [anchorRef]
  )

  return (
    <>
      {currentGroup && (
        <MultiLevelMenu
          zIndexAdjuster={zIndexAdjuster + 1}
          key="nested-menu"
          open={nestedMenuOpened}
          group={nestedGroup}
          label={label}
          displayLabel={displayLabel}
          items={nestedItems}
          placement="right-start"
          onSelect={onSelect}
          onHover={onHover}
          onClose={closeNestedMenu}
          ref={nestedMenuRef}
          anchorRef={nestedAnchorRef}
        />
      )}
      <Popper
        key="current-menu"
        open={open}
        role={undefined}
        anchorEl={getAnchorElementRect}
        transition
        disablePortal
        placement={placement || "bottom-start"}
        style={{ zIndex: 1000 + zIndexAdjuster }}
      >
        <Paper ref={ref} square elevation={5}>
          <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
            <MenuWithSearch>
              <SearchInput
                inputRef={inputRef}
                onChange={handleSearchChanged}
                onKeyDown={handleSearchKeydown}
              />
              <MenuList autoFocusItem={open}>
                {currentGroup
                  ? groupItems.map(item => (
                      <GroupMenuItem
                        key={item}
                        item={item}
                        onClick={handleGroupClick}
                        selected={item === selectedGroup}
                        ref={nestedAnchorRef}
                      />
                    ))
                  : leafItems.map(item => {
                      return (
                        <LeafMenuItem
                          key={item[label] as string}
                          item={item}
                          label={label}
                          displayLabel={displayLabel}
                          onClick={onSelect}
                          onHover={onHover}
                        />
                      )
                    })}
              </MenuList>
            </MenuWithSearch>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  )
}
