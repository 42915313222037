import { workbox } from "./workbox"

export function initMessageBroker(): void {
  workbox
    .register()
    .then(() => {
      workbox.update()
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.error("Service Worker registration failed:", err)
    })
}
