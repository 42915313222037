import React, { useEffect, useState, useCallback } from "react"
import NumberFormat from "react-number-format"
import {
  Box,
  Divider,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles"
import { HelpOutline } from "@material-ui/icons"
import { useMutation, useQuery } from "react-query"
import { useOutletContext } from "react-router-dom"
import { DateField, SelectInput } from "common/form-components"

import {
  calculateHouseholdLoss,
  deleteExhibit,
  getHousehold,
  getSectionMissingExhibits,
  updateHousehold,
  uploadExhibit,
  fetchPlaintiffInfoForCase,
} from "../api"
import { queryKeys } from "../react-query/constants"
import { useFormContext } from "./context"
import {
  formSectionsToRoutes,
  HOUSEHOLD_SERVICE_FILE_OPTIONS,
  IMPAIRMENT_TIME_UNIT_MAP,
  STEP_STATUSES,
} from "./constants"

import { ExhibitList } from "../common"
import { FileUploader } from "./FileUploader"
import { Controller, useForm } from "react-hook-form"
import { getChangedFields, getCommonMutateOptions } from "../utils"
import useAutosave from "../hooks/useAutosave"
import size from "lodash/size"
import MissingLossOfHouseholdAlert from "./Alerts/MissingLossOfHouseholdAlert"
import { debounce, isEmpty } from "lodash"

import RelevantDatesTable from "./RelevantDatesTable"
import MissingDocumentSection from "./MissingDocumentSection"
import { SECTIONS } from "../missing-docs/constants"
import useUser from "../hooks/useUser"
import { isNotNotSetupRole } from "../common/permission"
import { getDifferenceInYears, isDateEarlier } from "./utils"
import { useHandleMessages } from "../common/messages/useHandleMessages"

const INITIAL_FORM_STATE = {
  start_of_household_impairment_date: "",
  end_of_household_impairment_date: "",
  household_impairment_rate: 0,
  future_household_impaired_days: 0,
  future_household_impaired_years: 0,
  future_household_impairment_rate: 0,
  household_impairment_timeframe: "years",
}

const useStyles = makeStyles(theme => ({
  formFields: {
    marginTop: theme.spacing(4),
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: theme.spacing(4),
  },
  actions: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    margin: theme.spacing(2, 0),
    justifyContent: "flex-end",
  },
  fullWidth: {
    gridColumn: "1 / 4",
  },
  helpIcon: {
    fontSize: "1rem",
    verticalAlign: "bottom",
  },
  dateTableBox: {
    margin: theme.spacing(4, 2),
  },
  tableHeading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  calculationMessage: {
    margin: theme.spacing(2),
    textAlign: "center",
  },
  summaryFooterRow: {
    backgroundColor: "#757575",
    color: "white !important",
    "& > *": {
      color: "white",
      fontWeight: 700,
    },
  },
  ageAlert: {
    marginTop: theme.spacing(1),
  },
}))

function HouseholdLossesCalculation({ startDate, endDate }) {
  const classes = useStyles()
  const { caseId } = useFormContext()
  const [isDirty, setIsDirty] = useState(false)
  const { error, data } = useQuery(
    [queryKeys.calculateHousehold, caseId, "results"],
    () => {
      setIsDirty(true)
      return calculateHouseholdLoss(caseId)
    },
    {
      enabled: !!startDate && !!endDate,
      meta: {
        disableLoader: true,
      },
      onSuccess: () => {
        setIsDirty(false)
      },
    }
  )

  if (isDirty) return <Box align="center">Loading calculation...</Box>
  if (error) {
    return (
      <div className={classes.calculationMessage}>
        <Typography>There was an error getting the calculation.</Typography>
      </div>
    )
  }
  if (!data?.results || isEmpty(data?.results?.start_date))
    return (
      <div className={classes.calculationMessage}>
        <Typography>Fill out form to see loss calculation.</Typography>
      </div>
    )

  const dateFormat = new Intl.DateTimeFormat("en-US")
  const sum = (x, y) => x + y

  return (
    <TableContainer>
      <Divider />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell>Start</TableCell>
            <TableCell>End</TableCell>
            <TableCell align="right">Hourly Rate</TableCell>
            <TableCell align="right">Hours/day</TableCell>
            <TableCell align="right">% Impaired</TableCell>
            <TableCell align="right">Net Loss</TableCell>
            <TableCell align="right">Time Adj. Net Loss</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(data?.results.year).map(k => (
            <TableRow key={k}>
              <TableCell>
                <NumberFormat value={data.results.year[k]} displayType="text" />
              </TableCell>
              <TableCell>{dateFormat.format(Date.parse(data.results.start_date[k]))}</TableCell>
              <TableCell>{dateFormat.format(Date.parse(data.results.end_date[k]))}</TableCell>
              <TableCell align="right">
                <NumberFormat
                  value={data.results.hourly_rate[k]}
                  displayType="text"
                  decimalScale="2"
                  fixedDecimalScale="true"
                />
              </TableCell>
              <TableCell align="right">
                <NumberFormat value={data.results.hours_per_day[k]} displayType="text" decimalScale="2" />
              </TableCell>
              <TableCell align="right">
                <NumberFormat
                  value={data.results.percent_impaired[k] * 100}
                  suffix="%"
                  displayType="text"
                  decimalScale="0"
                />
              </TableCell>
              <TableCell align="right">
                <NumberFormat
                  value={data.results.net_loss[k]}
                  prefix="$"
                  displayType="text"
                  decimalScale="2"
                  thousandSeparator=","
                  fixedDecimalScale="true"
                />
              </TableCell>
              <TableCell align="right">
                <NumberFormat
                  value={data.results.time_adj_net_loss[k]}
                  prefix="$"
                  displayType="text"
                  decimalScale="2"
                  thousandSeparator=","
                  fixedDecimalScale="true"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {!isEmpty(data?.results?.start_date) && !isEmpty(data?.results?.end_date) && (
          <TableFooter>
            <TableRow className={classes.summaryFooterRow}>
              <TableCell>
                <Typography>Total</Typography>
              </TableCell>
              <TableCell>{dateFormat.format(Date.parse(data.results.start_date["0"]))}</TableCell>
              <TableCell>
                {dateFormat.format(
                  Date.parse(data.results.end_date[Object.keys(data.results.end_date).length - 1])
                )}
              </TableCell>
              <TableCell align="right" />
              <TableCell align="right" />
              <TableCell align="right" />
              <TableCell align="right">
                <NumberFormat
                  value={
                    !isEmpty(data?.results?.net_loss) ? Object.values(data?.results?.net_loss).reduce(sum) : 0
                  }
                  prefix="$"
                  displayType="text"
                  decimalScale="2"
                  thousandSeparator=","
                  fixedDecimalScale="true"
                />
              </TableCell>
              <TableCell align="right">
                <NumberFormat
                  value={
                    !isEmpty(data?.results?.time_adj_net_loss)
                      ? Object.values(data?.results?.time_adj_net_loss).reduce(sum)
                      : 0
                  }
                  prefix="$"
                  displayType="text"
                  decimalScale="2"
                  thousandSeparator=","
                  fixedDecimalScale="true"
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}

export function HouseholdServices({ lastVisited }) {
  const classes = useStyles()
  const [saveRef] = useOutletContext()
  const { control, handleSubmit, reset, formState, watch, getValues, setValue } = useForm({
    defaultValues: INITIAL_FORM_STATE,
  })
  const { caseId, queryClient, setSavedSuccessfully, showErrorMessage, handleUpdateStepStatus, request } =
    useFormContext()
  const [errors, setErrors] = useState(null)
  const [exhibits, setExhibits] = useState(null)
  const [fileToUpload, setFileToUpload] = useState(null)

  const startDate = watch("start_of_household_impairment_date")
  const endDate = watch("end_of_household_impairment_date")
  const householdImpairmentTimeframe = watch("household_impairment_timeframe")

  const futureImpairmentError =
    householdImpairmentTimeframe === "years"
      ? errors?.future_household_impaired_years
      : errors?.future_household_impaired_days
  const futureImpairmentTooltipTitle =
    householdImpairmentTimeframe === "years" ? `Decimal values are accepted for years, i.e. "10.17"` : ""
  if (householdImpairmentTimeframe === "years") {
    setValue("future_household_impaired_days", 0, { shouldDirty: true })
  } else {
    setValue("future_household_impaired_years", 0, { shouldDirty: true })
  }

  const getFutureImpairmentLabel = timeframe => {
    const impairmentTimeLabel = `Subsequent Impaired ${IMPAIRMENT_TIME_UNIT_MAP.get(timeframe).display}`
    if (timeframe === "years") {
      return (
        <Box display={"flex"}>
          <Box>{impairmentTimeLabel}</Box>
          <Box ml={1} mt={-0.5}>
            <HelpOutline />
          </Box>
        </Box>
      )
    } else {
      return impairmentTimeLabel
    }
  }

  const handleStartDate = useCallback(
    newStartDate => {
      setValue("start_of_household_impairment_date", newStartDate, { shouldDirty: true })
    },
    [setValue]
  )

  const handleEndDate = useCallback(
    newEndDate => setValue("end_of_household_impairment_date", newEndDate, { shouldDirty: true }),
    [setValue]
  )

  const { showMessage } = useHandleMessages()

  const { data: plaintiffInfo } = useQuery([queryKeys.plaintiff, caseId], async () => {
    const [info] = await fetchPlaintiffInfoForCase(caseId)
    return info
  })

  const startOfImpairmentDate = getValues("start_of_household_impairment_date")
  const isStartOfImpairmentDateOutOfRange =
    Boolean(plaintiffInfo) &&
    Boolean(startOfImpairmentDate) &&
    isDateEarlier(startOfImpairmentDate, plaintiffInfo.date_of_birth)
  const hasZeroHouseholdPeriods =
    Boolean(plaintiffInfo) &&
    Boolean(startOfImpairmentDate) &&
    !isStartOfImpairmentDateOutOfRange &&
    getDifferenceInYears(startOfImpairmentDate, plaintiffInfo.date_of_birth) < 15

  useQuery([queryKeys.household, caseId], async () => {
    const data = await getHousehold(caseId)
    if (data) {
      data.future_household_impaired_days = data.future_household_impaired_days ?? 0
      data.future_household_impaired_years = data.future_household_impaired_years ?? 0

      reset(data)
      // TOOD: Fix this - response recieves id instead of PK
      setExhibits(
        data?.exhibits.map(exhibit => {
          return { ...exhibit, pk: exhibit.id }
        })
      )
    }
  })
  const { user } = useUser()

  const { data: missingExhibits } = useQuery(
    [queryKeys.missingExhibits, caseId],
    async () => {
      return await getSectionMissingExhibits({ caseId: caseId, section: SECTIONS.HOUSEHOLD_LOSS })
    },
    {
      enabled: isNotNotSetupRole(user.role),
    }
  )

  const mutation = useMutation(
    updateHousehold,
    getCommonMutateOptions({
      setErrors,
      reset,
      setSavedSuccessfully,
      showErrorMessage,
      onSuccessExtra: () => {
        handleUpdateStepStatus({ status: STEP_STATUSES.completed })
        queryClient.invalidateQueries(queryKeys.calculateHousehold, caseId, "results")
      },
    })
  )

  // succeed/fail silently
  const autosaveMutation = useMutation(updateHousehold)

  const deleteFileMutation = useMutation(deleteExhibit, {
    onSuccess: () => {
      showMessage({
        type: "success",
        message: "Deleted file",
      })
      queryClient.invalidateQueries(queryKeys.household)
    },
    onError: () => {
      showMessage({
        type: "error",
        message:
          "Error occurred deleting file. Please try again shortly or contact a dev if your problem persists.",
      })
    },
  })
  const uploadFileMutation = useMutation(uploadExhibit, {
    onSuccess: () => {
      setFileToUpload(null)
      queryClient.invalidateQueries(queryKeys.household)
    },
    onError: () => {
      showMessage({
        type: "error",
        message:
          "Error occurred uploading file. Please try again shortly or contact a dev if your problem persists.",
      })
    },
  })
  const handleFileChange = async (file, remove = false) => {
    if (remove) {
      deleteFileMutation.mutate({ exhibitId: file.id, caseId })
    } else {
      let fileData = new FormData()
      const { file: fileObject, documentType, name } = file
      fileData.append("file", fileObject)
      fileData.append("name", name)
      fileData.append("type", documentType)
      fileData.append("section", "household_loss")

      uploadFileMutation.mutate({ data: fileData, caseId })
    }
  }

  const handleOnChange = handleSubmit(async data => {
    if (fileToUpload) {
      await handleFileChange(fileToUpload)
    }

    const changesFields = getChangedFields(data, formState)

    if (size(changesFields) > 0) {
      mutation.mutate({ caseId, data })
    }
  })

  const handleOnChangeDebounce = debounce(handleOnChange, 300)

  useEffect(() => {
    saveRef.current = handleOnChange
  }, [handleOnChange, saveRef])

  useAutosave({
    shouldAutosave: formState.isDirty && getValues("pk"),
    save: () => {
      autosaveMutation.mutate({ caseId, data: { ...getValues(), case_id: caseId } })
    },
  })

  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.household_loss
  }, [lastVisited])

  return (
    <>
      <MissingLossOfHouseholdAlert caseId={caseId} />
      <RelevantDatesTable />
      <Divider />
      {isStartOfImpairmentDateOutOfRange && (
        <Alert className={classes.ageAlert} severity="error" data-test="hh-plaintiff-age-error">
          Initial impairment date could not be before Plaintiff&apos;s birth date.
        </Alert>
      )}
      {hasZeroHouseholdPeriods && (
        <Alert className={classes.ageAlert} severity="warning" data-test="hh-plaintiff-age-warning">
          Plaintiff age is less than 15 for several periods. Hours/day will show 0 for those periods.
        </Alert>
      )}
      <form className={classes.formFields} noValidate onChange={handleOnChangeDebounce}>
        <DateField
          error={errors?.start_of_household_impairment_date}
          helperText={errors?.start_of_household_impairment_date}
          label="Initial Impairment Date"
          initialValue={startDate}
          onChange={handleStartDate}
          onClose={handleOnChange}
          fieldProps={{
            name: "start_of_household_impairment_date",
          }}
        />
        <DateField
          error={errors?.end_of_household_impairment_date}
          helperText={errors?.end_of_household_impairment_date}
          label="End of Initial Impairment Date"
          initialValue={endDate}
          onChange={handleEndDate}
          onClose={handleOnChange}
          fieldProps={{
            name: "end_of_household_impairment_date",
          }}
        />

        <Box>
          <Typography id="slider-label" gutterBottom>
            Past impairment Rate
          </Typography>

          <Controller
            name="household_impairment_rate"
            control={control}
            render={({ field }) => (
              <Slider
                aria-labelledby="slider-label"
                valueLabelDisplay="auto"
                {...field}
                onChange={(_, value) => {
                  setValue("household_impairment_rate", value, {
                    shouldDirty: true,
                  })
                }}
                onChangeCommitted={handleOnChangeDebounce}
              />
            )}
          />
        </Box>
        <SelectInput
          name="household_impairment_timeframe"
          control={control}
          label="Household Impairment Timeframe"
          options={Array.from(IMPAIRMENT_TIME_UNIT_MAP.values())}
        />
        <Controller
          name={`future_household_impaired_${householdImpairmentTimeframe}`}
          control={control}
          render={({ field }) => (
            <Tooltip placement="top" title={futureImpairmentTooltipTitle}>
              <TextField
                type="number"
                label={getFutureImpairmentLabel(householdImpairmentTimeframe)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: 0,
                }}
                error={futureImpairmentError}
                helperText={futureImpairmentError}
                {...field}
              />
            </Tooltip>
          )}
        />
        <Box>
          <Typography id="future-slider-label" gutterBottom>
            Future impairment Rate
          </Typography>
          <Controller
            name="future_household_impairment_rate"
            control={control}
            render={({ field }) => (
              <Slider
                aria-labelledby="future-slider-label"
                valueLabelDisplay="auto"
                {...field}
                onChange={(_, value) => {
                  setValue("future_household_impairment_rate", value, {
                    shouldDirty: true,
                  })
                }}
                onChangeCommitted={handleOnChangeDebounce}
              />
            )}
          />
        </Box>
        {!!exhibits?.length && (
          <Box className={classes.fullWidth}>
            <ExhibitList
              caseId={caseId}
              exhibits={exhibits}
              fileTypeMap={HOUSEHOLD_SERVICE_FILE_OPTIONS}
              onDelete={exhibit => handleFileChange(exhibit, true)}
              onSuccessfulSave={() => queryClient.invalidateQueries(queryKeys.household)}
            />
          </Box>
        )}
      </form>
      <Box className={classes.fullWidth}>
        <FileUploader
          fileTypes={HOUSEHOLD_SERVICE_FILE_OPTIONS}
          fileToUpload={fileToUpload}
          setFileToUpload={setFileToUpload}
          handleFileChange={handleFileChange}
        />
      </Box>
      {request?.pk && (
        <Box className={classes.fullWidth} mt={2}>
          <MissingDocumentSection
            missingDocs={missingExhibits}
            title="Missing Documents List"
            section={SECTIONS.HOUSEHOLD_LOSS}
          />
        </Box>
      )}
      <HouseholdLossesCalculation startDate={startDate} endDate={endDate} />
    </>
  )
}
