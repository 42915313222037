import React, { useState, useCallback } from "react"
import {
  Box,
  CircularProgress,
  Fab,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  FormControl,
  TextField,
} from "@material-ui/core"

import { Add as AddIcon, Delete as DeleteIcon, Warning, VerifiedUser } from "@material-ui/icons"
import { Link } from "react-router-dom"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { makeStyles } from "@material-ui/core/styles"

import { getUsers, deleteUser } from "../../api"
import { queryKeys } from "../../react-query/constants"

import useUser from "../../hooks/useUser"

import { StyledPageSizeWrapper } from "../Library/Tabs/styled"
import { GenericError } from "../../common"
import ConfirmDialog from "../../common/ConfirmDialog"
import AlertSnackBar from "../../common/AlertSnackBar"
import { useSearchState } from "../../hooks/useSearchState"
import { Pagination } from "common/pagination"
import { PageSizeSelect } from "common/pagination/PageSizeSelect"
import { EXTERNAL_ROLES, INTERNAL_ROLES, USER_ROLES } from "../../common/models/roles"
import { DEFAULT_PAGE_SIZE } from "common/models/pagination"

const useStyles = makeStyles(theme => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  deleteIcon: {
    margin: "auto",
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(10),
  },
  alignRight: {
    display: "flex",
    justifyContent: "right",
  },
  alignLeft: {
    display: "flex",
    justifyContent: "left",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
}))

const TableFilters = ({ handleSearchQueryChange, searchQuery }) => {
  const classes = useStyles()

  return (
    <Box className={classes.alignRight}>
      <FormControl className={classes.searchBar}>
        <TextField
          label={"Search"}
          value={searchQuery}
          onChange={handleSearchQueryChange}
          variant="outlined"
          placeholder="Search by user name"
          data-test="search-demand"
        />
      </FormControl>
    </Box>
  )
}

const PaginationHelper = ({ handlePageChange, currentPage, data, pageSize, handlePageSizeChange }) => {
  const classes = useStyles()

  return (
    <Box className={classes.actionContainer}>
      <Pagination
        onChange={handlePageChange}
        page={currentPage}
        pageCount={Math.ceil(data.count / pageSize)}
      />
      {/* Dropdown menu for selecting the page size */}
      <StyledPageSizeWrapper className={classes.alignRight}>
        <PageSizeSelect pageSize={pageSize} onChange={handlePageSizeChange} />
      </StyledPageSizeWrapper>
    </Box>
  )
}

export function UserTable({ users, invalidateUsers }) {
  const { user: authedUser } = useUser()
  const classes = useStyles()
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [currentUserId, setCurrentUserId] = useState(null)
  const [showSnackBar, setShowSnackBar] = useState(false)

  const deleteUserMutation = useMutation(deleteUser, {
    onSuccess: () => {
      invalidateUsers()
    },
  })

  const userCanDeleteThisUser = userRole => {
    switch (authedUser.role) {
      case INTERNAL_ROLES.LEGALOPS_ADMIN:
        return true
      case EXTERNAL_ROLES.FIRM_ADMIN:
        return true
      case EXTERNAL_ROLES.OFFICE_ADMIN:
        return ![EXTERNAL_ROLES.FIRM_ADMIN, EXTERNAL_ROLES.OFFICE_ADMIN].includes(userRole)
      default:
        return false
    }
  }

  const canDelete = [
    INTERNAL_ROLES.LEGALOPS_ADMIN,
    EXTERNAL_ROLES.FIRM_ADMIN,
    EXTERNAL_ROLES.OFFICE_ADMIN,
  ].includes(authedUser.role)

  const showAddUser = () => {
    if (![INTERNAL_ROLES.LEGALOPS, EXTERNAL_ROLES.SUPPORT].includes(authedUser.role)) {
      return true
    } else {
      return false
    }
  }

  const handleDeleteConfirmation = () => {
    deleteUserMutation.mutate({
      userId: currentUserId,
    })

    handleCloseDeleteDialog()
    setShowSnackBar(true)
  }

  const handleCloseDeleteDialog = () => {
    setShowDeleteDialog(false)
    setCurrentUserId(null)
  }

  return (
    <>
      {!deleteUserMutation.isIdle && (
        <AlertSnackBar
          onClose={() => {
            setShowSnackBar(false)
          }}
          open={showSnackBar}
          severity={deleteUserMutation.isSuccess ? "success" : "error"}
          message={
            deleteUserMutation.isSuccess
              ? `User has been deleted`
              : `Error deleteing user. Please contact your system administrator.`
          }
        />
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell data-test="name-cell">Name</TableCell>
            {authedUser.isInternal && <TableCell data-test="firm-cell">Firm</TableCell>}
            <TableCell data-test="email-cell">Email</TableCell>
            <TableCell data-test="role-cell">Role</TableCell>
            {authedUser.isInternal && <TableCell data-test="stytch-status-cell">Can Login?</TableCell>}
            {canDelete && <TableCell data-test="delete-cell"></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(user => {
            const isAuthedUser = authedUser.id === user.pk
            const userCanDeleteThisUserPermission = userCanDeleteThisUser(user.role)
            return (
              <TableRow key={user.pk} data-test={`user-row-${user.pk}`}>
                <TableCell data-test={`user-name-cell`}>
                  <Link to={`/settings/accounts/${user.pk}`}>
                    {user.first_name} {user.last_name}
                  </Link>
                </TableCell>
                {authedUser.isInternal && <TableCell>{user?.firm?.name}</TableCell>}
                <TableCell>
                  <Link to={`/settings/accounts/${user.pk}`}>{user.email}</Link>
                </TableCell>
                <TableCell>{USER_ROLES[user.role].display}</TableCell>
                {authedUser.isInternal && (
                  <TableCell>
                    {user.stytch_user_id ? (
                      <VerifiedUser color="success" fontSize="small" />
                    ) : (
                      <Warning color="secondary" fontSize="small" />
                    )}
                  </TableCell>
                )}
                {userCanDeleteThisUserPermission && !isAuthedUser ? (
                  <TableCell data-test="user-delete-cell">
                    <IconButton
                      onClick={() => {
                        setShowDeleteDialog(true)
                        setCurrentUserId(user.pk)
                      }}
                    >
                      <DeleteIcon className={classes.deleteIcon} />
                    </IconButton>
                  </TableCell>
                ) : (
                  <TableCell></TableCell>
                )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {showAddUser() && (
        <Link to={"/settings/accounts/new"}>
          <Fab aria-label="add" color="secondary" className={classes.fab} data-test="create-new-user-fab">
            <AddIcon />
          </Fab>
        </Link>
      )}
      <ConfirmDialog
        open={showDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirmation}
        title={"Are you sure?"}
        body={
          "Are you sure you want to delete this user? Once confirmed, this user will no longer be able to login to EvenUp Law."
        }
      />
    </>
  )
}

export function UserAccounts() {
  const classes = useStyles()

  const queryClient = useQueryClient()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [searchQuery, setSearchQuery] = useSearchState("query", "", "string")
  const { isLoading, isFetching, data, error } = useQuery({
    queryKey: [
      queryKeys.firms,
      {
        currentPage,
        pageSize,
        searchQuery,
      },
    ],
    queryFn: () => getUsers({ page: currentPage, pageSize: pageSize, searchQuery: searchQuery }),
    meta: { disableLoader: true },
  })
  const invalidateUsers = () => {
    queryClient.invalidateQueries(queryKeys.users)
  }
  const handlePageChange = page => {
    setCurrentPage(page)
  }
  const handleSearchQueryChange = e => {
    handlePageChange(1)
    // don't replace history when value is empty
    // this creates a distinct entry when clearing input
    // so you can use back button to return to input before clearing
    setSearchQuery(e.target.value, { replace: e.target.value ? true : false })
  }
  const handlePageSizeChange = useCallback(pageSize => {
    setCurrentPage(1)
    setPageSize(pageSize)
  }, [])

  if (error) return <GenericError />

  return (
    <Box>
      <Typography variant="h4" component="h1">
        User Accounts
      </Typography>
      {/* Box which contains search bar and can add filters to it */}
      {<TableFilters handleSearchQueryChange={handleSearchQueryChange} searchQuery={searchQuery} />}
      {!isLoading && !isFetching && <UserTable users={data.results} invalidateUsers={invalidateUsers} />}
      {(isFetching || isLoading) && (
        <div className={classes.alignCenter}>{<CircularProgress color="secondary" />}</div>
      )}
      {/* Page bar to move between pages */}
      {!isLoading && data.count > 0 && (
        <PaginationHelper
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          data={data}
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
        />
      )}
    </Box>
  )
}
