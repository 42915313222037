import React from "react"
import { Box, Paper, Popover } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"

const PopoverArrow = styled(Paper)(() => ({
  position: "relative",
  mt: "10px",
  "&::before": {
    backgroundColor: "white",
    content: '""',
    display: "block",
    position: "absolute",
    width: 12,
    height: 12,
    top: -6,
    transform: "rotate(45deg)",
    left: "calc(50% - 6px)",
  },
}))

const PopoverContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
}))

interface PointedPopoverProps {
  anchorEl: Nullable<EventTarget & HTMLElement>
  onClose: () => void
  children: React.ReactNode
}
const PointedPopover = ({ anchorEl, onClose, children }: PointedPopoverProps): JSX.Element => {
  const open = Boolean(anchorEl)
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          borderRadius: 0,
        },
      }}
    >
      <PopoverArrow />
      <PopoverContentContainer>{children}</PopoverContentContainer>
    </Popover>
  )
}

export { PointedPopover as default }
