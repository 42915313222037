import React from "react"
import { Box, Slider } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useFormContext } from "./FormContext"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles(theme => ({
  boost: {
    width: "25%",
  },
}))

export default function BoostControls() {
  const classes = useStyles()
  const { queryState, queryActions, handleQueryUpdate } = useFormContext()
  const marks = [
    {
      value: 10,
      label: "Boost Case Facts",
    },
    {
      value: 0,
      label: "Equal",
    },
    {
      value: -10,
      label: "Boost Injuries",
    },
  ]

  const handleBoost = (event, value) => {
    handleQueryUpdate(queryActions.SET_BOOST, value)
  }

  return (
    <Box className={classes.boost}>
      <Slider
        track={false}
        aria-labelledby="injuries-facts-boost-slider"
        step={1}
        defaultValue={queryState.injury_to_facts_boosts}
        marks={marks}
        min={-10}
        max={10}
        onChangeCommitted={handleBoost}
      />
    </Box>
  )
}
