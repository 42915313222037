import React, { useState } from "react"
import { Box, Button, Chip, Collapse, FormControlLabel, Icon, Switch, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Link, useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { VerifiedUser, Warning } from "@material-ui/icons"

import { queryKeys } from "../../react-query/constants"
import { getUser } from "../../api"

import { UserAccountForm } from ".."
import { USER_ROLES } from "../../common/models/roles"
import useUser from "../../hooks/useUser"

const useStyles = makeStyles(theme => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  user: {
    padding: theme.spacing(2, 0),
    width: theme.spacing(60),
  },
  actions: {
    display: "grid",
    gridGap: theme.spacing(2),
    gridTemplateColumns: "1fr 1fr",
    marginTop: theme.spacing(2),
  },
  iconWithText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& > span": {
      marginRight: theme.spacing(1),
      display: "inline-flex",
    },
  },
}))

const StytchInfo = ({ user }) => {
  const [showId, setShowId] = useState(false)
  const classes = useStyles()
  return (
    <>
      {user.stytch_user_id ? (
        <Box>
          <Box className={classes.iconWithText}>
            <Icon>
              <VerifiedUser />
            </Icon>
            <Typography variant="body2" component="p">
              User has a linked Stytch account!
            </Typography>
          </Box>
          <FormControlLabel
            control={<Switch checked={showId} onChange={() => setShowId(!showId)} />}
            label="Show Stytch ID"
          />
          <Collapse in={showId}>
            <Typography variant="body2" component="p">
              {user.stytch_user_id}
            </Typography>
          </Collapse>
        </Box>
      ) : (
        <Box className={classes.iconWithText}>
          <Icon color="secondary">
            <Warning />
          </Icon>
          <Typography variant="body2" component="p" color="secondary">
            User is not currently linked to Stytch!
          </Typography>
        </Box>
      )}
    </>
  )
}

const UserCard = ({ user, handleEdit }) => {
  const classes = useStyles()
  const { user: loggedInUser } = useUser()
  return (
    <Box className={classes.user}>
      <Box className={classes.header}>
        <Box>
          <Typography variant="h5" component="h2" data-test="user-name">
            {user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : "(No Name)"}
          </Typography>
          <Typography variant="body1" data-test="user-email">
            {user?.email}
          </Typography>
        </Box>
        <Chip label={USER_ROLES[user.role].display} data-test="user-role" />
      </Box>
      {loggedInUser.isInternal && <StytchInfo user={user} />}
      <Box className={classes.actions}>
        <Button color="primary" variant="contained" onClick={handleEdit} data-test="edit-user-button">
          Edit User
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          component={Link}
          to={`change-password`}
          data-test="change-user-password-button"
        >
          Change Password
        </Button>
      </Box>
    </Box>
  )
}

export function UserAccount() {
  const { id: userId } = useParams()
  const [isEditing, setIsEditing] = useState(false)
  const { isLoading, data: user } = useQuery([queryKeys.userAccount, userId], getUser)

  if (isEditing) {
    return (
      <Box>
        <UserAccountForm formData={user} callback={() => setIsEditing(false)} />
      </Box>
    )
  }

  return <Box>{!isLoading && <UserCard user={user} handleEdit={() => setIsEditing(true)} />}</Box>
}
