import { BaseServiceDeserializer } from "api/BaseDeserializer"
import { PaginatedList } from "common/models/pagination"
import { ProviderTemplateDefinition, NewProviderTemplate } from "common/types/providerTemplates"
import { ProviderTemplateDto, ProviderTemplateItemDto } from "./types"

export class ProviderTemplateServiceDeserializer {
  static definitionFromJSON(provider: ProviderTemplateItemDto): ProviderTemplateDefinition {
    return {
      id: provider.pk,
      content: provider.content,
      templateName: provider.template_name,
      generatorKey: provider.generator_key,
      updatedAt: BaseServiceDeserializer.fromDateString(provider.created_at),
    }
  }

  static fromListJSON(templates: ProviderTemplateItemDto[]): ProviderTemplateDefinition[] {
    return templates.map(ProviderTemplateServiceDeserializer.definitionFromJSON)
  }

  static fromPaginatedListJSON(
    templates: PaginatedList<ProviderTemplateItemDto>
  ): PaginatedList<ProviderTemplateDefinition> {
    return new PaginatedList(
      ProviderTemplateServiceDeserializer.fromListJSON(templates.items),
      templates.count,
      templates.pageSize,
      templates.page
    )
  }
}

export class ProviderTemplateServiceSerializer {
  static toDefinitionJSON(provider: NewProviderTemplate): ProviderTemplateDto {
    return {
      content: provider.content,
      template_name: provider.templateName,
      generator_key: provider.generatorKey,
    }
  }
}
