import { PaginatedOptions } from "api/PaginatedList"
import { handleEmptyResponse } from "api/utils"
import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"
import { isUndefined } from "lodash"
import { apiService } from "../ApiService"
import { ApiServiceType } from "../types"
import { ProviderTemplateServiceDeserializer, ProviderTemplateServiceSerializer } from "./serializers"
import { ProviderTemplateDto } from "./types"

interface ProviderServiceOptions {
  templateId: BaseEntity["pk"]
}

export type ProviderServiceEntityOptionsArg<Exists extends boolean = false> = {
  data: ProviderTemplateDto
} & (Exists extends true ? { options: ProviderServiceOptions } : { options?: never })

enum TEMPLATE_API_PATHS {
  BASE = "library/provider-template",
}

export class ProviderTemplateService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(options?: ProviderServiceOptions, path?: TEMPLATE_API_PATHS): string {
    const pathParts = ["", TEMPLATE_API_PATHS.BASE, options?.templateId, path]

    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getProviderTemplatesList = withResponseSerializer(
    ProviderTemplateServiceDeserializer.fromPaginatedListJSON,
    (options: PaginatedOptions) => {
      return this.apiService.getPaginatedList(this.getPath(), null, options)
    }
  )

  createProviderTemplate = withRequestSerializer(
    ProviderTemplateServiceSerializer.toDefinitionJSON,
    withResponseSerializer(
      ProviderTemplateServiceDeserializer.definitionFromJSON,
      async ({ data }: ProviderServiceEntityOptionsArg) => {
        return await handleEmptyResponse(this.apiService.create(data, this.getPath()))
      }
    )
  )

  updateProviderTemplate = withRequestSerializer(
    ProviderTemplateServiceSerializer.toDefinitionJSON,
    withResponseSerializer(
      ProviderTemplateServiceDeserializer.definitionFromJSON,
      async ({ data, options }: ProviderServiceEntityOptionsArg<true>) => {
        return await handleEmptyResponse(this.apiService.replace(data, this.getPath(options)))
      }
    )
  )
}

export const providerTemplateService = new ProviderTemplateService(apiService)
