import React, { useState } from "react"

import { Box, IconButton, Tooltip as MuiTooltip } from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"
import { withStyles, makeStyles, styled } from "@material-ui/core/styles"
import { useMutation } from "react-query"

import clsx from "clsx"

// Internal Components
import StatusLabel from "../../../common/status/StatusLabel"
import ActionDrawer from "../../ActionDrawer"
import AssigneePicker from "../../AssigneePicker"
import AssigneeAvatar from "../../AssigneeAvatar"
import Tooltip from "../../../common/Tooltip"
import { CircleBadge } from "../../../common/CircleBadge"

// Icons
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFileOutlined"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import DownloadIcon from "@material-ui/icons/GetApp"

// Helpers
import { downloadAllFiles } from "../../utils"
import { useRequestContext } from "../../context"
import { formatTimeSinceNow, formatDate } from "../../../utils"
import { queryKeys } from "../../../react-query/constants"
import { updateRequestInternalAssignees, downloadRequestDemandPackageFiles } from "../../../api"
import {
  canUserAssignRequest,
  canUserSeeFirmCell,
  canUserViewAssigneesCell,
  canDemandPackageBeDownloaded,
  canUserSeeDemandPackageDownload,
  canUserSeeSubmitter,
} from "../../permissions/requestAction"
import getStatusToProperties from "../../RequestStatus/requestStatusProperties"
import useUser from "../../../hooks/useUser"

import { INTAKE_STATUSES } from "../../constants"
import { INTERNAL_ROLES_VALUES } from "../../../common/models/roles"
import { CommentOutlined } from "@material-ui/icons"
import { memoize } from "lodash"
import { TagLabels, TAG_LABEL_TYPE } from "common/TagLabel"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { roundDown } from "common/math"

const useStyles = makeStyles(theme => ({
  hideHyperlinks: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  linkToRequest: {
    display: "flex",
    width: "100%",
  },
  plaintiffName: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    alignItems: "center",
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
  tooltipArrow: {
    color: theme.palette.common.black,
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  statusContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",

    margin: "-2px 0",

    "& > *": {
      whiteSpace: "nowrap",
      margin: "2px 0",
    },

    "& > :not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },
}))

const PlaintiffNameCell = ({ record }) => {
  const pk = record.pk
  const classes = useStyles()
  const plaintiffs = record.plaintiffs
  const primaryPlaintiff = plaintiffs[0]

  return (
    <RouterLink
      className={clsx(classes.hideHyperlinks, classes.linkToRequest, classes.plaintiffName)}
      to={`${pk}`}
    >
      <Box>{`${primaryPlaintiff.first_name} ${primaryPlaintiff.last_name}`}</Box>
      {plaintiffs.length > 1 && (
        <Box ml={1}>
          <Tooltip
            arrow
            classes={{ arrow: classes.tooltipArrow, tooltip: classes.tooltip }}
            title={plaintiffs.map((plaintiff, index) => {
              if (index === 0) {
                return
              }

              return (
                <Box key={plaintiff.pk}>
                  {plaintiff.first_name} {plaintiff.last_name}
                </Box>
              )
            })}
          >
            <CircleBadge label={`+${plaintiffs.length - 1}`} />
          </Tooltip>
        </Box>
      )}
    </RouterLink>
  )
}

const SubmitterCell = ({ record }) => {
  if (!record?.submitter) {
    return ""
  }

  return `${record.submitter?.first_name} ${record.submitter?.last_name}`
}

const FirmCell = ({ record }) => {
  return `${record.firm.name}`
}

const Assignees = ({ record }) => {
  const { user } = useRequestContext()
  const classes = useStyles()
  const assignees = user.isInternal ? record.internal_assignees : record.external_assignees

  return (
    <div className={classes.avatarContainer}>
      {assignees?.map(assignee => {
        return (
          <AssigneeAvatar
            firstName={assignee?.first_name}
            lastName={assignee?.last_name}
            key={`assignee-avatar-${assignee.pk}`}
          />
        )
      })}
    </div>
  )
}

const StatusCell = ({ record }) => {
  const { user } = useUser()
  const classes = useStyles()
  let status = getStatusToProperties(record.intake_status, user.role)

  let subtext = ""
  if (record.intake_status === INTAKE_STATUSES.missingDocuments && record.days_missing_docs > 0) {
    subtext = `${record.days_missing_docs} days since reported`
  }

  const tags = []
  if (record.revised) {
    tags.push({ type: TAG_LABEL_TYPE.INFO, label: "Revision" })
  }

  if (record.annotation_status && user.isInternal) {
    if (record.annotation_status == "pre_annotating" || record.annotation_status == "annotating") {
      tags.push({ type: TAG_LABEL_TYPE.ANNOTATION_IN_PROGRESS, label: "Annotation in Progress" })
    } else if (record.annotation_status == "complete") {
      tags.push({ type: TAG_LABEL_TYPE.ANNOTATION_COMPLETE, label: "Annotation Completed" })
    } else if (record.annotation_status == "auto_complete") {
      tags.push({ type: TAG_LABEL_TYPE.ANNOTATION_COMPLETE, label: "Automatic Annotations Completed" })
    }
  }

  return (
    <div className={classes.statusContainer}>
      <StatusLabel
        status={record.intake_status}
        text={status.text}
        color={status.color}
        filled={status.filled}
        subtext={subtext}
      />
      <TagLabels tags={tags} />
    </div>
  )
}

const DateRequestedCell = ({ record }) => {
  return formatDate(record?.date_requested)
}

const LastModifiedCell = ({ record }) => {
  return formatTimeSinceNow(record?.updated_at)
}

const DownloadDemandPackageCell = ({ record }) => {
  const { showMessage } = useHandleMessages()
  const downloadAllFilesMutation = useMutation(
    async () => {
      await downloadAllFiles(downloadRequestDemandPackageFiles, `demand-package`, record.pk)
    },
    {
      onError: () => {
        showMessage({
          type: "error",
          message:
            "Something went wrong downloading all files. Try downloading individual files and report an issue if your problem persists.",
        })
      },
    }
  )

  const demandPackageCanBeDownloaded = canDemandPackageBeDownloaded(record)
  const tooltipText = demandPackageCanBeDownloaded
    ? "Download Demand Package"
    : "This demand package is not ready to be downloaded"

  return (
    <Tooltip
      disabled={!demandPackageCanBeDownloaded}
      title={tooltipText}
      onClick={() => {
        downloadAllFilesMutation.mutateAsync()
      }}
    >
      <IconButton>
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  )
}

const ActionButtonDisplay = ({ record }) => {
  const { user, queryClient } = useRequestContext()
  const assignees = user.isInternal ? record.internal_assignees : record.external_assignees

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [drawerAssignees, setDrawerAssignees] = useState(assignees)

  const updateAssigneesMutation = useMutation(updateRequestInternalAssignees, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.requests)
    },
  })

  const userRole = user.role
  const canUserAssignRequestPermission = !!canUserAssignRequest(userRole)

  const handleDrawerSave = e => {
    e.preventDefault()
    e.stopPropagation()

    canUserAssignRequestPermission &&
      updateAssigneesMutation.mutate({
        requestId: record.pk,
        data: drawerAssignees.map(assignee => {
          return assignee.pk
        }),
      })
  }

  if (!record) {
    return <></>
  }

  return (
    <>
      <IconButton
        onClick={e => {
          e.stopPropagation()
          setDrawerOpen(true)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <ActionDrawer
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false)
          setDrawerAssignees(assignees)
        }}
        onSave={handleDrawerSave}
        title={`Request Actions`}
      >
        {canUserAssignRequestPermission && (
          <AssigneePicker
            value={drawerAssignees}
            onChange={({ newValues }) => {
              setDrawerAssignees(newValues)
            }}
            isInternal={user.isInternal}
          />
        )}
      </ActionDrawer>
    </>
  )
}

const CreditCellWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing(1),
}))

const CommentTooltip = withStyles({
  arrow: {
    color: "#000",
  },
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px",
  },
})(MuiTooltip)

const CreditCell = ({ record }) => {
  return (
    <CreditCellWrapper>
      <Box>{roundDown(record.credit_amount, { decimalPlaces: 2 })}</Box>
      <Box display="flex" minWidth="20px">
        {record.credit_comments && (
          <CommentTooltip title={record.credit_comments} placement="bottom" arrow>
            <CommentOutlined fontSize="small" />
          </CommentTooltip>
        )}
      </Box>
    </CreditCellWrapper>
  )
}

export const getColumns = memoize(
  ({ user, classes }) =>
    [
      {
        id: "icon",
        text: "",
        style: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          width: "1rem",
        },
        disablePadding: true,
        cellComponent: InsertDriveFileIcon,
      },
      {
        id: "plaintiff_name",
        text: "Plaintiff Name",
        align: "left",
        className: classes.plaintiffColumn,
        cellComponent: PlaintiffNameCell,
      },
      {
        id: "firm",
        text: "Firm",
        align: "left",
        restrictView: canUserSeeFirmCell,
        cellComponent: FirmCell,
      },
      {
        id: "submitter",
        text: "Requester",
        align: "left",
        cellComponent: SubmitterCell,
        restrictView: canUserSeeSubmitter,
      },
      {
        id: "assignees",
        text: "Assignees",
        align: "left",
        restrictView: canUserViewAssigneesCell,
        cellComponent: Assignees,
      },
      {
        id: "intake_status",
        text: "Status",
        align: "left",
        sortable: true,
        cellComponent: StatusCell,
      },
      user.isRole(INTERNAL_ROLES_VALUES)
        ? {
            id: "credits",
            text: "Credits",
            cellComponent: CreditCell,
          }
        : null,
      {
        id: "date_requested",
        text: "Date Requested",
        align: "right",
        sortable: true,
        cellComponent: DateRequestedCell,
      },
      {
        id: "updated_at",
        text: "Last Modified",
        align: "right",
        sortable: true,
        cellComponent: LastModifiedCell,
      },
      {
        id: "download-demand-package",
        text: "Demand Package",
        align: "center",
        sortable: false,
        cellComponent: DownloadDemandPackageCell,
        restrictView: canUserSeeDemandPackageDownload,
      },
      {
        id: "actions",
        text: "Actions",
        align: "right",
        sortable: false,
        cellComponent: ActionButtonDisplay,
        restrictView: canUserAssignRequest,
      },
    ].filter(col => col) // remove any nulls of omitted columns
)
