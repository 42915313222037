import React from "react"
import ReactDOM from "react-dom"
import { ThemeProvider } from "@emotion/react"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles"
import { QueryClientProvider } from "react-query"
import { elasticApm } from "infrastructure/apm"

import { queryClient } from "./react-query/queryClient"

import App from "./app/App"
import theme from "./theme"

elasticApm.init()

// Can also use with React Concurrent Mode
// ReactDOM.createRoot(document.getElementById('root')).render(<App />);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <MuiThemeProvider theme={theme}>
      {/* Basic styling. */}
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </MuiThemeProvider>
  </ThemeProvider>,
  document.querySelector("#root")
)
