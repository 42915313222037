import React from "react"
import { StyledLibraryTableFormButtonsWrapper, StyledCancelButton, StyledAddButton } from "./styled"

interface FormFooterProps {
  onCancel: () => void
  onAction: () => void
}

export const FormFooter = ({ onCancel, onAction }: FormFooterProps): JSX.Element => (
  <StyledLibraryTableFormButtonsWrapper>
    <StyledCancelButton variant="contained" onClick={onCancel}>
      Cancel
    </StyledCancelButton>
    <StyledAddButton variant="contained" color="primary" onClick={onAction}>
      Add
    </StyledAddButton>
  </StyledLibraryTableFormButtonsWrapper>
)
