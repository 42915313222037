import React from "react"
import {
  Badge,
  Button,
  Box,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ButtonGroup,
} from "@material-ui/core"
import { ExpandMore, Delete as IconDelete } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import { compact, pick, startCase } from "lodash"
import { useFormContext } from "./FormContext"

const useStyles = makeStyles(theme => ({
  injuryFacetContainer: {
    margin: theme.spacing(1, 0),
  },
  injuryText: {
    "& > span": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  count: {
    margin: theme.spacing(0, 1),
  },
  actions: {
    "& > button": {
      fontSize: theme.typography.pxToRem(10),
    },
  },
}))

const InjurySelections = ({ selections, onHandleDelete }) => {
  const classes = useStyles()
  const getInjuryText = selection => {
    if (selection.injury_types) {
      return startCase(selection.injury_types).toLowerCase()
    }
    const nonLevelData = pick(selection, ["category", "problem"])
    return compact(Object.values(nonLevelData)).join(" // ")
  }
  const getSecondaryText = selection => {
    if (selection.injury_types) {
      return "injury name"
    }
    const levelsData = pick(selection, ["level_1", "level_2", "level_3", "level_4"])
    return (
      compact(Object.values(levelsData))
        .map(levelData => startCase(levelData).toLowerCase())
        .join(" > ") || "(no further levels)"
    )
  }

  return (
    <List dense>
      {selections.map((selection, index) => (
        <ListItem key={index}>
          <ListItemText
            className={classes.injuryText}
            primary={getInjuryText(selection)}
            secondary={getSecondaryText(selection)}
            primaryTypographyProps={{ variant: "body2" }}
            secondaryTypographyProps={{ variant: "caption" }}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="remove" onClick={() => onHandleDelete(index)} size="small">
              <IconDelete />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
      <ListItem />
    </List>
  )
}

export default function InjuryOntologyTrigger({ openModal }) {
  const classes = useStyles()
  const { queryState, queryActions, handleQueryUpdate } = useFormContext()
  const selectedInjuries = queryState.query["injury_tags"] || []
  const selectedInjuryCount = selectedInjuries.length
  const removeFacet = facetIndex => {
    handleQueryUpdate(queryActions.REMOVE_FACET_BY_INDEX, { name: "injury_tags", value: facetIndex })
  }

  const removeAllInjuries = () => {
    handleQueryUpdate(queryActions.REMOVE_ALL_IN_FACET, "injury_tags")
  }

  return (
    <Box className={classes.injuryFacetContainer}>
      <Button
        variant="contained"
        size="small"
        onClick={openModal}
        startIcon={
          selectedInjuryCount ? (
            <Badge className={classes.count} badgeContent={selectedInjuryCount} color="secondary"></Badge>
          ) : null
        }
        endIcon={selectedInjuryCount ? <ExpandMore /> : null}
      >
        Set Injuries
      </Button>
      <Collapse in={selectedInjuryCount > 0}>
        <InjurySelections selections={selectedInjuries} onHandleDelete={removeFacet} />
        <ButtonGroup className={classes.actions} variant="outlined" size="small" disableElevation>
          <Button onClick={openModal}>Add More</Button>
          <Button color="secondary" onClick={removeAllInjuries}>
            Remove All
          </Button>
        </ButtonGroup>
      </Collapse>
    </Box>
  )
}
