import React, { useRef } from "react"

import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"

const AutoCompleteFilter = ({
  value,
  onChange,
  options,
  label = "Autocomplete",
  className = null,
  id = null,
}) => {
  const labelRef = useRef()

  return (
    <Autocomplete
      className={className}
      onChange={(e, newValue) => {
        e.target.value = newValue?.key ?? 0
        onChange(e)
      }}
      getOptionSelected={(option, value) => !!option && !!value && option.key === value.key}
      getOptionLabel={option => `${option?.display}` || ""}
      renderOption={option => <span data-test="filter-value">{option?.display || ""}</span>}
      options={options}
      value={value}
      disableClearable={value?.key === 0}
      renderInput={params => {
        return (
          <TextField
            {...params}
            ref={labelRef}
            label={label}
            variant="outlined"
            fullWidth={false}
            InputProps={{
              ...params.InputProps,
              className: className,
              endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
              fullWidth: false,
            }}
            data-test={id}
          />
        )
      }}
    />
  )
}

export { AutoCompleteFilter as default }
