import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
  hiddenTabContent: {
    height: 0,
    overflow: "hidden",
    visibility: "hidden",
  },
}))

interface TabPanelProps {
  active: boolean
}

export const TabPanel: React.FC<TabPanelProps> = ({ active, children }) => {
  const classes = useStyles()

  return <Box className={clsx(!active && classes.hiddenTabContent)}>{children}</Box>
}
