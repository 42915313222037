import React, { useRef, useState } from "react"
import { Navigate, Outlet, Route } from "react-router-dom"
import { ApmRouter as Routes } from "infrastructure/apm/ApmRouter"
import Home from "../common/Home"
import RequireAuth from "../common/RequireAuth"
import RestrictAuth from "../common/RestrictAuth"
import AccessDenied from "../common/AccessDenied"
import { CaseList } from "../case"
import { Case } from "../case/Case"
import { StartCase } from "../case/StartCase"
import { Search } from "../search"
import {
  AnnotationList,
  AnnotationRequest,
  AnnotationStatsRequest,
  NewAnnotationRequest,
} from "../annotation"
import Login from "../Login"
import {
  Attorney,
  ChangeEmail,
  Firm,
  FirmEdit,
  FirmList,
  NewAttorney,
  NewFirm,
  NewUserAccount,
  Profile,
  ResetPassword,
  Settings,
  SETTINGS_ROUTE_PREFIX,
  UserAccount,
  UserAccounts,
} from "../settings"
import {
  CarrierForm,
  CaseFacts,
  DamagesSections,
  Demand,
  DemandFlow,
  FavoriteCases,
  FutureExpenses,
  HouseholdServices,
  IncomeLoss,
  LawFirm,
  NewDemand,
  NonEconomic,
  PlaintiffInfo,
  ProviderList,
  RelevantCases,
  Review,
} from "../demand"
import Files from "../demand/Files/Files"
import MissingDocuments from "../demand/MissingDocuments"
import { formSectionsToRoutes } from "../demand/constants"
import { Confirmation, RequestsList, RequestForm, ViewRequest } from "../requests"
import { EXTERNAL_ROLES, EXTERNAL_ROLES_VALUES, INTERNAL_ROLES, OSF } from "../common/models/roles"
import ExhibitPage from "../demand/ExhibitPage"
import LoginWithStytch from "../PasswordlessLogin"
import Authenticate from "../Authenticate"
import { Helmet } from "react-helmet"
import { REQUEST_VIEW_TABS } from "../requests/ViewRequest/enums"
import { REQUEST_VIEW_URL_PREFIX } from "../requests/ViewRequest/constants"
import { FEATURES, isFeatureEnabled } from "hooks/useFeatures"
import { Library } from "settings/Library"
import DemandHome from "demand/DemandHome"
import { FORM_SECTION_ROUTES } from "demand/steps/constants"
import { CASE_ATTRIBUTES_STEP } from "demand/steps/types"
import { Templates } from "demand/Templates"
import { ExhibitPreviewPage } from "exhibit-preview"
import { PdfPreviewPage } from "pdf-preview"
import { RestrictedCredits } from "settings/Credits/RestrictedCredits"

const DemandLastVisited = ({ lastVisited, defaultPath }) => (
  <Navigate to={lastVisited.current || defaultPath} replace />
)

export default function AppRoutes() {
  const lastVisited = useRef(null)
  const [lastSearchParams, setLastSearchParams] = useState(null)

  return (
    <Routes>
      <Route
        path="demands"
        element={
          <RequireAuth
            restrictAccess={[
              INTERNAL_ROLES.LEGALOPS,
              INTERNAL_ROLES.LEGALOPS_MANAGER,
              INTERNAL_ROLES.LEGALOPS_ADMIN,
              OSF,
            ]}
          >
            <Outlet />
          </RequireAuth>
        }
      >
        <Route
          index
          element={
            <>
              <Helmet>
                <title>Demands List - EvenUp</title>
              </Helmet>
              <CaseList setLastSearchParams={setLastSearchParams} />
            </>
          }
        />
        <Route path="new" element={<NewDemand />} />
        <Route path=":id" element={<DemandFlow />}>
          <Route index element={<DemandHome />} />
          <Route path="files" element={<Files />} />
          <Route path="form" element={<Demand />}>
            {/* default to plaintiff section if no section is provided */}
            <Route
              index
              element={
                <DemandLastVisited lastVisited={lastVisited} defaultPath={formSectionsToRoutes.plaintiff} />
              }
            />
            <Route
              path={formSectionsToRoutes.plaintiff}
              element={<PlaintiffInfo lastVisited={lastVisited} />}
            />
            <Route path={formSectionsToRoutes.carrier} element={<CarrierForm lastVisited={lastVisited} />} />
            <Route path={formSectionsToRoutes.facts} element={<CaseFacts lastVisited={lastVisited} />} />
            <Route
              path={formSectionsToRoutes.providers}
              element={<ProviderList lastVisited={lastVisited} />}
            />
            <Route
              path={formSectionsToRoutes.future_expenses}
              element={<FutureExpenses lastVisited={lastVisited} />}
            />
            <Route
              path={formSectionsToRoutes.non_economic}
              element={<NonEconomic lastVisited={lastVisited} />}
            />
            <Route
              path={formSectionsToRoutes.income_loss}
              element={<IncomeLoss lastVisited={lastVisited} />}
            />
            <Route
              path={formSectionsToRoutes.household_loss}
              element={<HouseholdServices lastVisited={lastVisited} />}
            />
            <Route path={formSectionsToRoutes.law_firm} element={<LawFirm lastVisited={lastVisited} />} />
            <Route
              path={formSectionsToRoutes.damages_sections}
              element={<DamagesSections lastVisited={lastVisited} />}
            />
            <Route
              path={FORM_SECTION_ROUTES[CASE_ATTRIBUTES_STEP]}
              element={<Templates lastVisited={lastVisited} />}
            />

            <Route
              path={formSectionsToRoutes.missing_docs}
              element={
                <RequireAuth
                  restrictAccess={[
                    INTERNAL_ROLES.LEGALOPS,
                    INTERNAL_ROLES.LEGALOPS_MANAGER,
                    INTERNAL_ROLES.LEGALOPS_ADMIN,
                  ]}
                >
                  <MissingDocuments lastVisited={lastVisited} />
                </RequireAuth>
              }
            />
            <Route path="exhibit" element={<ExhibitPage />} />
          </Route>
          <Route
            path="search"
            element={
              <>
                <Helmet>
                  <title>Search - EvenUp</title>
                </Helmet>
                <Search lastSearchParams={lastSearchParams} setLastSearchParams={setLastSearchParams} />
              </>
            }
          />
          <Route path="relevant" element={<RelevantCases />} />
          <Route path="favorite" element={<FavoriteCases />} />
          <Route path="review" element={<Review />} />
        </Route>
      </Route>
      <Route
        path="/"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      <Route
        path="/case/new"
        element={
          <RequireAuth
            restrictAccess={[
              INTERNAL_ROLES.LEGALOPS,
              INTERNAL_ROLES.LEGALOPS_MANAGER,
              INTERNAL_ROLES.LEGALOPS_ADMIN,
            ]}
          >
            <StartCase />
          </RequireAuth>
        }
      />
      <Route
        path="/case/:id"
        element={
          <RequireAuth
            restrictAccess={[
              INTERNAL_ROLES.LEGALOPS,
              INTERNAL_ROLES.LEGALOPS_MANAGER,
              INTERNAL_ROLES.LEGALOPS_ADMIN,
            ]}
          >
            <Case />
          </RequireAuth>
        }
      />
      {/* SEARCH */}
      <Route
        path="search"
        element={
          <RequireAuth
            restrictAccess={[
              INTERNAL_ROLES.LEGALOPS,
              INTERNAL_ROLES.LEGALOPS_MANAGER,
              INTERNAL_ROLES.LEGALOPS_ADMIN,
            ]}
          >
            <Outlet />
          </RequireAuth>
        }
      >
        <Route
          index
          element={<Search lastSearchParams={lastSearchParams} setLastSearchParams={setLastSearchParams} />}
        />
        <Route
          path=":id"
          element={<Search lastSearchParams={lastSearchParams} setLastSearchParams={setLastSearchParams} />}
        />
      </Route>
      {/* SETTINGS */}
      <Route
        path="settings"
        element={
          <>
            <Helmet>
              <title>Settings - EvenUp</title>
            </Helmet>
            <RequireAuth>{<Settings />}</RequireAuth>
          </>
        }
      >
        <Route index element={<Profile />} />
        <Route
          path={SETTINGS_ROUTE_PREFIX.FIRMS}
          element={
            <RequireAuth
              restrictAccess={[
                INTERNAL_ROLES.LEGALOPS,
                INTERNAL_ROLES.LEGALOPS_MANAGER,
                INTERNAL_ROLES.LEGALOPS_ADMIN,
              ]}
            >
              <Outlet />
            </RequireAuth>
          }
        >
          <Route index element={<FirmList />} />
          <Route
            path="new"
            element={
              <RequireAuth restrictAccess={[INTERNAL_ROLES.LEGALOPS_ADMIN]}>
                <NewFirm />
              </RequireAuth>
            }
          />
          <Route path=":id/edit" element={<FirmEdit />} />
          <Route path=":id" element={<Firm />}>
            <Route path="attorneys/:attorneyId" element={<Attorney />} />
            <Route path="attorneys/new-attorney" element={<NewAttorney />} />
          </Route>
        </Route>
        <Route path={SETTINGS_ROUTE_PREFIX.PROFILE} element={<Outlet />}>
          <Route index element={<Profile />} />
          <Route path="change-password" element={<ResetPassword />} />
          <Route path="change-email" element={<ChangeEmail />} />
        </Route>
        <Route
          path={SETTINGS_ROUTE_PREFIX.ACCOUNTS}
          element={
            <RequireAuth
              restrictAccess={[
                INTERNAL_ROLES.LEGALOPS_ADMIN,
                INTERNAL_ROLES.LEGALOPS_MANAGER,
                EXTERNAL_ROLES.FIRM_ADMIN,
                EXTERNAL_ROLES.OFFICE_ADMIN,
              ]}
            >
              <Outlet />
            </RequireAuth>
          }
        >
          <Route index element={<UserAccounts />} />
          <Route path="new" element={<NewUserAccount />} />
          <Route path=":id" element={<Outlet />}>
            <Route index element={<UserAccount />} />
            <Route path="change-password" element={<ResetPassword />} />
          </Route>
        </Route>
        <Route
          path={SETTINGS_ROUTE_PREFIX.CREDITS}
          element={
            <RequireAuth restrictAccess={EXTERNAL_ROLES_VALUES}>
              <RestrictedCredits restricted={<Navigate to="/access-denied" replace />} />
            </RequireAuth>
          }
        />
        <Route
          path={SETTINGS_ROUTE_PREFIX.LIBRARY}
          element={
            <RequireAuth
              restrictAccess={[
                INTERNAL_ROLES.LEGALOPS_ADMIN,
                INTERNAL_ROLES.LEGALOPS_MANAGER,
                INTERNAL_ROLES.LEGALOPS,
              ]}
            >
              {isFeatureEnabled(FEATURES.TEMPLATES) ? <Library /> : <Navigate to="/settings" replace />}
            </RequireAuth>
          }
        />
      </Route>
      {/* CLIENT / INTAKE ROUTES */}
      <Route path="requests" element={<RequireAuth>{<Outlet />}</RequireAuth>}>
        <Route
          index
          element={
            <>
              <Helmet>
                <title>Requests - EvenUp</title>
              </Helmet>
              <RequestsList />
            </>
          }
        />
        <Route
          path="new"
          element={
            <RequireAuth>
              <RequestForm />
            </RequireAuth>
          }
        />
        <Route path=":id" element={<Outlet />}>
          <Route index element={<ViewRequest />} />
          <Route
            path="edit"
            element={
              <RequireAuth>
                <RequestForm />
              </RequireAuth>
            }
          />
          <Route path="success" element={<Confirmation />} />
          <Route
            path={REQUEST_VIEW_URL_PREFIX.MISSING_DOCS}
            element={<ViewRequest tab={REQUEST_VIEW_TABS.MISSING_DOCS} />}
          />
          <Route
            path={REQUEST_VIEW_URL_PREFIX.REVISION}
            element={<ViewRequest tab={REQUEST_VIEW_TABS.REVISION} />}
          />
        </Route>
      </Route>
      {/* Annotation */}
      <Route
        path="/annotation"
        element={
          <RequireAuth
            restrictAccess={[
              INTERNAL_ROLES.LEGALOPS,
              INTERNAL_ROLES.LEGALOPS_MANAGER,
              INTERNAL_ROLES.LEGALOPS_ADMIN,
            ]}
          >
            <AnnotationList />
          </RequireAuth>
        }
      />
      <Route
        path="/annotation/:id"
        element={
          <RequireAuth
            restrictAccess={[
              INTERNAL_ROLES.LEGALOPS,
              INTERNAL_ROLES.LEGALOPS_MANAGER,
              INTERNAL_ROLES.LEGALOPS_ADMIN,
            ]}
          >
            <AnnotationRequest />
          </RequireAuth>
        }
      />
      <Route
        path="/annotation/new"
        element={
          <RequireAuth
            restrictAccess={[
              INTERNAL_ROLES.LEGALOPS,
              INTERNAL_ROLES.LEGALOPS_MANAGER,
              INTERNAL_ROLES.LEGALOPS_ADMIN,
            ]}
          >
            <NewAnnotationRequest />
          </RequireAuth>
        }
      />
      <Route
        path="/annotation/stats"
        element={
          <RequireAuth
            restrictAccess={[
              INTERNAL_ROLES.LEGALOPS,
              INTERNAL_ROLES.LEGALOPS_MANAGER,
              INTERNAL_ROLES.LEGALOPS_ADMIN,
            ]}
          >
            <AnnotationStatsRequest />
          </RequireAuth>
        }
      />
      <Route
        path="exhibit-preview/:caseId/exhibit/:exhibitId"
        element={
          <RequireAuth
            restrictAccess={[
              INTERNAL_ROLES.LEGALOPS,
              INTERNAL_ROLES.LEGALOPS_MANAGER,
              INTERNAL_ROLES.LEGALOPS_ADMIN,
              OSF,
            ]}
          >
            <ExhibitPreviewPage />
          </RequireAuth>
        }
      />
      <Route
        path="pdf-preview"
        element={
          <RequireAuth
            restrictAccess={[
              INTERNAL_ROLES.LEGALOPS,
              INTERNAL_ROLES.LEGALOPS_MANAGER,
              INTERNAL_ROLES.LEGALOPS_ADMIN,
              OSF,
            ]}
          >
            <PdfPreviewPage />
          </RequireAuth>
        }
      />
      <Route
        path="exhibit-preview/:caseId/exhibit/:exhibitId/partition/:partitionId"
        element={
          <RequireAuth
            restrictAccess={[
              INTERNAL_ROLES.LEGALOPS,
              INTERNAL_ROLES.LEGALOPS_MANAGER,
              INTERNAL_ROLES.LEGALOPS_ADMIN,
              OSF,
            ]}
          >
            <ExhibitPreviewPage />
          </RequireAuth>
        }
      />
      {/* LOGIN */}
      <Route path="/access-denied" element={<AccessDenied />} replace />
      <Route
        path="/login-password"
        element={
          <RestrictAuth>
            <Login />
          </RestrictAuth>
        }
      />
      <Route
        path="/login"
        element={
          <RestrictAuth>
            <LoginWithStytch />
          </RestrictAuth>
        }
      />
      <Route path="/authenticate" element={<Authenticate />} />
      <Route path="*" element={<AccessDenied />} replace />
    </Routes>
  )
}
