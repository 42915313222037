import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useIsFetching } from "react-query"

import "./properties.css"

export type Optional<T> = {
  [K in keyof T]?: T[K]
}

interface RadialProgressOptions {
  showDot: boolean
  size: string
  blankColor: string
  fillColor: string
  overflowColor: string
  thick?: boolean
}

type RadialProgressProps = Optional<RadialProgressOptions> & {
  used: number
  total: number
  showTotals?: boolean
}

type RadialProgressStyleProps = Required<RadialProgressOptions> & {
  value: number
  size: string
  isOverflow: boolean
}

const useStyles = ({
  value,
  size,
  isOverflow,
  blankColor,
  fillColor,
  overflowColor,
  showDot,
  thick,
}: RadialProgressStyleProps) =>
  makeStyles(() => ({
    "@keyframes growProgressBar": {
      "0%": {
        "--pgPercentage": 0,
        "--baseColor": blankColor,
        "--fillColor": fillColor,
      },
      "100%": {
        "--pgPercentage": value,
        "--baseColor": blankColor,
        "--fillColor": fillColor,
      },
    },

    "@keyframes growProgressBarOverflow": {
      "0%": {
        "--pgPercentage": 0,
        "--baseColor": blankColor,
        "--fillColor": fillColor,
      },
      "50%": {
        "--pgPercentage": 100,
        "--baseColor": blankColor,
        "--fillColor": fillColor,
      },
      "50.1%": {
        "--pgPercentage": 0,
        "--baseColor": fillColor,
        "--fillColor": overflowColor,
      },
      "100%": {
        "--pgPercentage": value,
        "--baseColor": fillColor,
        "--fillColor": overflowColor,
      },
    },

    radialProgress: {
      animation: isOverflow ? "$growProgressBarOverflow 2s 1 forwards" : "$growProgressBar 1.5s 1 forwards",
      animationTimingFunction: isOverflow ? "linear" : "ease-out",
      animationFillMode: "forward",
      background: `
        radial-gradient(closest-side, white ${thick ? "60" : "80"}%, transparent 0 99.9%, white 0),
        conic-gradient(var(--fillColor) calc(var(--pgPercentage) * 1%), var(--baseColor) 0)`,
      border: `solid calc(${size} / 25) #EFEFEF`,
      borderRadius: "50%",
      display: "grid",
      fontSize: `calc(${size} / 5)`,
      height: size,
      position: "relative",
      placeItems: "center",
      width: size,
      "&::after": {
        animation: isOverflow ? "$growProgressBarOverflow 2s 1 forwards" : "$growProgressBar 1.5s 1 forwards",
        animationTimingFunction: isOverflow ? "linear" : "ease-out",
        position: "absolute",
        background: "radial-gradient(var(--fillColor) 0 50%, transparent 50% 100%)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "0px 0",
        backgroundSize: showDot ? `calc(${size} / 5) calc(${size} / 5)` : 0,
        content: '""',
        height: `calc(${size} / 2 + 5%)`,
        width: `calc(0.1 * ${size} + 10%)`,
        left: value / 10 <= 5 ? `calc(50% - ${(value / 10) % 5}%)` : `calc(50% + ${(value / 10) % 5}%)`,
        top:
          value < 50 || value > 79
            ? `calc(-6% - ${(value / 10) % 5}%)`
            : `calc(-6% - ${(value / 10) % 5}% - 5%)`,

        transform: "translateX(-50%) rotate(calc(var(--pgPercentage) / 100 * 360deg))",
        transformOrigin: "50% 100%",
        zIndex: 1,
      },
    },
    ratioTest: {
      textAlign: "center",
      lineBreak: "anywhere",
      fontSize: `calc(${size} / 8)`,
    },
  }))

export const RadialProgress = ({
  used,
  total,
  size = "15rem",
  showTotals = true,
  showDot = true,
  blankColor = "#b5b5b6",
  fillColor = "#3cd5ad",
  overflowColor = "#25ac88",
  thick = false,
}: RadialProgressProps): JSX.Element => {
  const isFetching = !!useIsFetching()
  const percentage = (used / total) * 100
  const isOverflow = used > total
  const value = percentage % 100 === 0 && used !== 0 ? 100 : percentage % 100

  const classes = useStyles({
    value: isFetching ? 0 : value,
    isOverflow,
    size,
    blankColor,
    fillColor,
    overflowColor,
    showDot,
    thick,
  })()

  return (
    <Box className={classes.radialProgress}>
      {showTotals && (
        <Box className={classes.ratioTest} color="black" fontWeight={900}>
          {used}/{total}
        </Box>
      )}
    </Box>
  )
}
