import React, { useCallback, useContext, useMemo } from "react"
import { Box, FormControlLabel, Tooltip, Switch } from "@material-ui/core"
import { HelpOutline } from "@material-ui/icons"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { UPDATE_INCLUDE_TABLE, UPDATE_ICD_CODES, UPDATE_CPT_CODES, Action } from "demand/Providers/reducer"
import { CaseIcdCode, CaseCptCode, Provider, IcdCodeFields } from "demand/Providers/types"

import { IcdCodesInput } from "./IcdCodesInput"
import { CptCodesInput } from "./CptCodesInput"
import { SectionHeading } from "../styled"
import { useProviderVariables } from "../../useProviderVariables"
import { useCaseVariables } from "demand/Variables"
import { ProviderFormEditor } from "../ProviderFormEditor"

import { useFeatures, FEATURES } from "hooks/useFeatures"
import IcdCodes from "../../Annotation/IcdCodes/IcdCodes"
import { PartitionEntry } from "../../Annotation/types"
import InjuryDetails from "../../Annotation/InjuryDetails/InjuryDetails"
import SectionContainer from "../../SectionContainer"
import { LeftNavContext } from "demand/context"

interface Props {
  disabled: boolean
  provider: Provider
  caseId: PrimaryKey
  dispatch: React.Dispatch<Action>
  annotationDateUpdated: Nullable<string>
  onAnnotationClick: (entry: PartitionEntry) => void
}

interface StyleProps {
  leftNavTabIsExpanded: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  helpIcon: {
    fontSize: "1rem",
    verticalAlign: "text-bottom",
  },
  codeInputs: {
    display: "flex",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    flexDirection: props => (props.leftNavTabIsExpanded ? "row" : "column"),
  },
  inputLabelText: {
    fontSize: "13px",
    fontWeight: 600,
  },
  wordBreakAll: {
    wordBreak: "break-word",
  },
}))

export const InjuryDetailsSection = ({
  provider,
  disabled,
  dispatch,
  caseId,
  annotationDateUpdated = "",
  onAnnotationClick,
}: Props): JSX.Element => {
  const { leftNavTabIsExpanded } = useContext(LeftNavContext)
  const classes = useStyles({ leftNavTabIsExpanded })
  const { isFeatureEnabled } = useFeatures()
  const isProviderAutofillEnabled = isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL)
  const hasPartitionProvider = isProviderAutofillEnabled && !!provider.partition_provider

  const icdCodes = provider.icd_codes ?? []
  const cptCodes = provider.cpt_codes ?? []

  const handleChangeIncludeTable = useCallback(
    (_, checked: boolean) => {
      dispatch({
        type: UPDATE_INCLUDE_TABLE,
        payload: { providerId: provider.pk, includeTable: checked },
      })
    },
    [provider, dispatch]
  )

  const handleChangeIcdCodes = useCallback(
    (value: CaseIcdCode[]) => {
      dispatch({
        type: UPDATE_ICD_CODES,
        payload: { providerId: provider.pk, icdCodes: value },
      })
    },
    [provider, dispatch]
  )

  const handleChangeCptCodes = useCallback(
    (value: CaseCptCode[]) => {
      dispatch({
        type: UPDATE_CPT_CODES,
        payload: { providerId: provider.pk, cptCodes: value },
      })
    },
    [provider, dispatch]
  )

  const label = useMemo(
    () => (
      <>
        Include provider details table{" "}
        <Tooltip placement="top" arrow title="Adds a table with all provider details in the generated demand">
          <HelpOutline className={classes.helpIcon} />
        </Tooltip>
      </>
    ),
    [classes.helpIcon]
  )
  const providerVariables = useProviderVariables(provider)
  const { variables } = useCaseVariables(providerVariables)

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <SectionHeading>Injury Details from Provider</SectionHeading>
        <Box justifyContent="right">
          <FormControlLabel
            control={<Switch />}
            label={label}
            checked={provider.include_table}
            onChange={handleChangeIncludeTable}
            disabled={disabled}
          />
        </Box>
      </Box>
      <SectionContainer>
        <Box width="100%">
          <Box className={classes.codeInputs}>
            <IcdCodesInput icdCodes={icdCodes} onChange={handleChangeIcdCodes} disabled={disabled} />
            <CptCodesInput cptCodes={cptCodes} onChange={handleChangeCptCodes} disabled={disabled} />
          </Box>
        </Box>
        {hasPartitionProvider && (
          <Box>
            <IcdCodes
              caseId={caseId}
              providerId={provider.pk}
              currentIcdCodes={icdCodes as IcdCodeFields[]}
              onEntryClick={onAnnotationClick}
              dateUpdated={annotationDateUpdated ?? ""}
            />
          </Box>
        )}
      </SectionContainer>

      <SectionContainer>
        <Box className={classes.wordBreakAll} width="100%" flexGrow={1}>
          <ProviderFormEditor
            disabled={disabled}
            caseId={caseId}
            variables={variables}
            dispatch={dispatch}
            provider={provider}
          />
        </Box>
        {hasPartitionProvider && (
          <Box mt={leftNavTabIsExpanded ? 2 : 3}>
            <InjuryDetails caseId={caseId} providerId={provider.pk} onEntryClick={onAnnotationClick} />
          </Box>
        )}
      </SectionContainer>
      <Box mb={3} />
    </>
  )
}
