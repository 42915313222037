import React, { useEffect, useCallback } from "react"
import { Box, Typography, CircularProgress, Link, Divider } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useStytch } from "@stytch/react"
import { useHandleMessages } from "./common/messages/useHandleMessages"
import { useAuth } from "./hooks/useAuth"

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "grid",
    placeItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  contact: {
    marginTop: theme.spacing(1),
  },
  dividerWrapper: {
    padding: theme.spacing(1, 0),
  },
  error: {
    fontFamily: "monospace",
    fontSize: "0.8rem",
    marginTop: theme.spacing(1),
  },
}))

const ErrorMessage = ({ message }) => {
  const classes = useStyles()

  return (
    <Box className={classes.alertContainer}>
      <Typography variant="h6" component="h1">
        Unable to log in!
      </Typography>
      <Typography variant="subtitle1" component="p">
        Verify that the correct email address was used and you have clicked on the most recent login email.
      </Typography>
      <Typography variant="subtitle2" className={classes.contact} component="p">
        Contact{" "}
        <Link href="mailto:support@evenuplaw.com" rel="noopener noreferrer" target="_top">
          support@evenuplaw.com
        </Link>{" "}
        if you continue to have issues.
      </Typography>
      {message && (
        <Box className={classes.errorContainer}>
          <Box className={classes.dividerWrapper}>
            <Divider />
          </Box>
          <Typography variant="body2">
            Provide the following error message when contacting support:{" "}
            <Box className={classes.error}>{message}</Box>
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default function Authenticate() {
  const classes = useStyles()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const auth = useAuth()
  const token = params.get("token")
  const oauth = params.get("oauth")
  const path = params.get("path")
  const stytchClient = useStytch()
  const { showMessage } = useHandleMessages()

  const redirectUponLogin = useCallback(() => {
    let redirectPath = path || "/"

    // avoid user from being sent back to change password after changing password
    if (redirectPath === "/settings/profile/change-password") {
      redirectPath = "/"
    }

    if (redirectPath === "/settings/profile/change-email") {
      redirectPath = "/"
    }

    return navigate(redirectPath)
  }, [navigate, path])

  useEffect(() => {
    const loginUser = async ({ token, oauth }) => {
      try {
        await auth.loginWithStytch({ token, oauth: !!oauth })
        redirectUponLogin()
      } catch (error) {
        showMessage({
          type: "error",
          message: <ErrorMessage message={error?.message} />,
        })

        return navigate("/login")
      }
    }

    loginUser({ token, oauth })
  }, [stytchClient, token, navigate, auth, oauth, showMessage, redirectUponLogin])

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <Typography>Logging you in...</Typography>
        <CircularProgress />
      </Box>
    </Box>
  )
}
