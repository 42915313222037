import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { queryKeys } from "react-query/constants"
import { isEqual } from "lodash"
import { getAttributeValues } from "common/attributes-filter/utils"
import { attributesService } from "api/services/attributes"
import { LibraryFiltersContext as LibraryFiltersContextType, LibraryFilterValues } from "./types"
import { LibraryFiltersContext } from "./context"
import { useSearchParams } from "react-router-dom"
import { getNewParams } from "../utils"
import { useInitialAttributeValuesFromQuery, useInitialSectionFromQuery } from "./useFilterHooks"

interface LibraryFiltersProviderProps {
  children: React.ReactNode
}

export function LibraryFiltersProvider({ children }: LibraryFiltersProviderProps): JSX.Element {
  const [filterValues, setFilterValues] = useState<LibraryFilterValues>({
    section: null,
    attributeValues: {},
  })
  const queryClient = useQueryClient()
  const [params, setParams] = useSearchParams()
  const initialAttributeValuesFromQuery = useInitialAttributeValuesFromQuery()
  const initialSectionFromQuery = useInitialSectionFromQuery()
  const initialParams = useRef({ initialSectionFromQuery, initialAttributeValuesFromQuery })

  const handleFilterValuesChange = useCallback(
    (nextValues: LibraryFilterValues) => {
      setFilterValues(currentValues => {
        const areAttributesChanged = !isEqual(currentValues.attributeValues, nextValues.attributeValues)
        const isSectionChanged = currentValues.section !== nextValues.section

        const newParams = getNewParams(params, nextValues, isSectionChanged, areAttributesChanged)

        if (newParams) {
          setParams(newParams)
        }

        if (!areAttributesChanged && !isSectionChanged) {
          return currentValues
        }

        const values = { ...currentValues, section: nextValues.section }

        if (areAttributesChanged) {
          values.attributeValues = nextValues.attributeValues
        }

        return values
      })
    },
    [params, setParams]
  )

  const { data: attributes } = useQuery(queryKeys.attributes, async () => {
    const nextAttributes = await attributesService.getAvailableAttributes(null)

    handleFilterValuesChange({
      ...filterValues,
      section: initialParams.current.initialSectionFromQuery,
      attributeValues: getAttributeValues(
        nextAttributes,
        initialParams.current.initialAttributeValuesFromQuery
      ),
    })

    return nextAttributes
  })

  const contextValue = useMemo<LibraryFiltersContextType>(
    () => ({
      attributes: attributes ?? null,
      filterValues: filterValues,
      setFilterValues: handleFilterValuesChange,
    }),
    [attributes, filterValues, handleFilterValuesChange]
  )

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(queryKeys.attributes)
    }
  }, [queryClient])

  return <LibraryFiltersContext.Provider value={contextValue}>{children}</LibraryFiltersContext.Provider>
}
