import { PlaintiffDto } from "./ViewRequest/types"

export const STANDARD = "standard"
export const BASIC_PLUS = "basic+"

export const DEMAND_TYPE_RADIO_OPTIONS = [
  { key: STANDARD, display: "Standard" },
  { key: BASIC_PLUS, display: "Basic+" },
]

export const ASSET_KEYS = {
  caseTypes: "case-types",
  familyStatus: "family-status",
  dependentStatus: "dependent-status",
  educationLevel: "education-level",
  recreation: "recreation",
  homeActivities: "home-activities",
  selfCare: "self-care",
  entertainment: "entertainment",
  socialActivities: "social-activities",
}

export const ASSET_TYPES = Object.values(ASSET_KEYS)

export const INJURY_IMPACTS_KEYS = {
  recreation: {
    key: "injury_impacts_recreation",
    display: "Recreation",
  },
  entertainment: {
    key: "injury_impacts_entertainment",
    display: "Entertainment",
  },
  homeActivities: {
    key: "injury_impacts_home_activities",
    display: "Home Activities",
  },
  socialActivities: {
    key: "injury_impacts_social",
    display: "Social Activities",
  },
  selfCare: {
    key: "injury_impacts_self_care",
    display: "Self Care",
  },
  other: {
    key: "injury_impacts_other",
    display: "Other",
  },
}

export const FAMILY_STATUS_KEYS = {
  familyStatus: {
    key: "family_status",
    display: "Status",
  },
  dependentStatus: {
    key: "dependent_status",
    display: "Dependents",
  },
}

export const EMPTY_PLAINTIFF: PlaintiffDto = {
  first_name: "",
  last_name: "",
  role: null,
  pronoun: null,
  is_minor: false,
}

export const INITIAL_STATE_REQUIRED = {
  plaintiff_first_name: "",
  plaintiff_last_name: "",
  carrier_name: "",
  additional_information: "",
  firm_id: "",
  defendants: [],
  policy_type: "individual",
  policy_coverage_type: "bodily_injury",
  type: STANDARD,
  plaintiffs: [EMPTY_PLAINTIFF],
}

export const INITIAL_STATE_FULL = {
  ...INITIAL_STATE_REQUIRED,
  case_type: "",
  date_of_incident: "",
  adjuster_first_name: "",
  adjuster_last_name: "",
  policy_number: "",
  policy_limit: "",
  case_facts: "",
  claim_number: "",
  ongoing_complaints: "",
  files: [],
  future_treatments: "",
  family_status: "",
  dependent_status: "",
  dependent_status_other: "",
  household_start_of_impairment: "",
  household_end_of_impairment: "",
  household_percent_of_chores: 0,
  education_level: "",
  injury_impacts_recreation: [],
  injury_impacts_recreation_other: null,
  injury_impacts_home_activities: [],
  injury_impacts_home_activities_other: null,
  injury_impacts_self_care: [],
  injury_impacts_self_care_other: null,
  injury_impacts_entertainment: [],
  injury_impacts_entertainment_other: null,
  injury_impacts_social: [],
  injury_impacts_social_other: null,
  injury_impacts_recreation_impact_percentage_3: 50,
  intake_status_last_modified: "",
  type: STANDARD,
  plaintiffs: [],
}

export enum INTAKE_STATUSES {
  notRequested = "1",
  requested = "2",
  inProgress = "3",
  cancelled = "4",
  completed = "5",
  rework = "6",
  redelivered = "7",
  ready_for_tasker = "8",
  tasker_assigned = "9",
  tasker_completed = "10",
  missingDocuments = "11",
  documentsUploaded = "12",
}

export const UNEDITABLE_STATUSES: INTAKE_STATUSES[] = [
  INTAKE_STATUSES.inProgress,
  INTAKE_STATUSES.cancelled,
  INTAKE_STATUSES.completed,
  INTAKE_STATUSES.rework,
  INTAKE_STATUSES.redelivered,
  INTAKE_STATUSES.ready_for_tasker,
  INTAKE_STATUSES.tasker_assigned,
  INTAKE_STATUSES.tasker_completed,
  INTAKE_STATUSES.documentsUploaded,
]

export const DOWNLOADABLE_DEMAND_PACKAGE_STATUSES: INTAKE_STATUSES[] = [
  INTAKE_STATUSES.completed,
  INTAKE_STATUSES.rework,
  INTAKE_STATUSES.redelivered,
]

export const DEMAND_PACKAGE = "Demand Package"
export const DEMAND_LETTER = "Demand Letter"
export const EXHIBIT = "Exhibit"
export const OTHER = "Other"
export const DEMAND_PACKAGE_FILE_TYPES = {
  demand_package: DEMAND_PACKAGE,
  demand_letter: DEMAND_LETTER,
  exhibit: EXHIBIT,
  other: OTHER,
}

export const INDIVIDUAL = "individual"
export const COMMERCIAL = "commercial"
export const POLICY_TYPE_KEYS = {
  individual: { key: INDIVIDUAL, display: "Individual" },
  commercial: { key: COMMERCIAL, display: "Commercial" },
}

export enum POLICY_COVERAGE_TYPE_KEYS {
  bodily_injury = "bodily_injury",
  underinsured_motorist = "underinsured_motorist",
  uninsured_motorist = "uninsured_motorist",
  self_insured = "self_insured",
  commercial_general_liability = "commercial_general_liability",
  other = "other",
}

export const INDIVIDUAL_POLICY_COVERAGE_TYPE_KEYS = {
  [POLICY_COVERAGE_TYPE_KEYS.bodily_injury]: {
    key: POLICY_COVERAGE_TYPE_KEYS.bodily_injury,
    display: "BI Policy",
  },
  [POLICY_COVERAGE_TYPE_KEYS.underinsured_motorist]: {
    key: POLICY_COVERAGE_TYPE_KEYS.underinsured_motorist,
    display: "Underinsured Motorist (UIM)",
  },
  [POLICY_COVERAGE_TYPE_KEYS.uninsured_motorist]: {
    key: POLICY_COVERAGE_TYPE_KEYS.uninsured_motorist,
    display: "Uninsured Motorist (UM)",
  },
  [POLICY_COVERAGE_TYPE_KEYS.self_insured]: {
    key: POLICY_COVERAGE_TYPE_KEYS.self_insured,
    display: "Self-Insured",
  },
  [POLICY_COVERAGE_TYPE_KEYS.other]: { key: POLICY_COVERAGE_TYPE_KEYS.other, display: "Other" },
}
export const COMMERCIAL_POLICY_COVERAGE_TYPE_KEYS = {
  [POLICY_COVERAGE_TYPE_KEYS.bodily_injury]: {
    key: POLICY_COVERAGE_TYPE_KEYS.bodily_injury,
    display: "BI Policy",
  },
  [POLICY_COVERAGE_TYPE_KEYS.underinsured_motorist]: {
    key: POLICY_COVERAGE_TYPE_KEYS.underinsured_motorist,
    display: "Underinsured Motorist (UIM)",
  },
  [POLICY_COVERAGE_TYPE_KEYS.uninsured_motorist]: {
    key: POLICY_COVERAGE_TYPE_KEYS.uninsured_motorist,
    display: "Uninsured Motorist (UM)",
  },
  [POLICY_COVERAGE_TYPE_KEYS.self_insured]: {
    key: POLICY_COVERAGE_TYPE_KEYS.self_insured,
    display: "Self-Insured",
  },
  [POLICY_COVERAGE_TYPE_KEYS.commercial_general_liability]: {
    key: POLICY_COVERAGE_TYPE_KEYS.commercial_general_liability,
    display: "Commercial General Liability (CGL)",
  },
  [POLICY_COVERAGE_TYPE_KEYS.other]: { key: POLICY_COVERAGE_TYPE_KEYS.other, display: "Other" },
}
export const NO_DEFENDANT_COVERAGE_TYPES_INDIVIDUAL = [
  "underinsured_motorist",
  "uninsured_motorist",
  "self_insured",
  "other",
]
export const NO_DEFENDANT_COVERAGE_TYPES_COMMERCIAL = [
  "underinsured_motorist",
  "uninsured_motorist",
  "self_insured",
  "commercial_general_liability",
  "other",
]

export const ENTITY = "entity"
export const DEFENDANT_TYPE_KEYS = {
  individual: { key: INDIVIDUAL, display: "Individual" },
  entity: { key: ENTITY, display: "Entity" },
}

export const POLICY_TYPES = Object.values(POLICY_TYPE_KEYS)
export const INDIVIDUAL_POLICY_COVERAGE_TYPES = Object.values(INDIVIDUAL_POLICY_COVERAGE_TYPE_KEYS)
export const COMMERCIAL_POLICY_COVERAGE_TYPES = Object.values(COMMERCIAL_POLICY_COVERAGE_TYPE_KEYS)
export const DEFENDANT_TYPES = Object.values(DEFENDANT_TYPE_KEYS)
export const POLICY_COVERAGE_TYPE_MAP = new Map([
  [INDIVIDUAL, INDIVIDUAL_POLICY_COVERAGE_TYPES],
  [COMMERCIAL, COMMERCIAL_POLICY_COVERAGE_TYPES],
  ["other", [{ key: "other", display: "Other" }]],
])

export const BASIC_INFORMATION = "basicInfo"
export const CARRIER_INFORMATION = "carrierInfo"
export const CASE_FACTS = "caseFacts"
export const PLAINTIFF_INFORMATION = "plaintiffInfo"
export const FILES = "files"
export const INJURY_IMPACTS = "injuryImpacts"
export const ALL = "all"

export const DEMAND_TYPE = "demandType"
export const PLAINTIFF_NAME = "plaintiffName"
export const CARRIER_NAME = "carrierName"
export const FIRM_NAME = "firmName"
export const SUBMITTER = "submitter"
export const ASSIGNED_ATTORNEY = "assignedAttorney"
export const DEFENDANTS = "defendants"
export const ADDITIONAL_INFO = "additionalInfo"

export const ADJUSTER = "adjuster"
export const RECIPIENT_EMAIL = "recipientEmail"
export const POLICY_NUMBER = "policyNumber"
export const POLICY_TYPE = "policyType"
export const POLICY_COVERAGE_TYPE = "policyCoverageType"
export const POLICY_LIMIT = "policyLimit"
export const CLAIM_NUMBER = "claimNumber"
export const RECIPIENT_ADDRESS = "recipientAddress"

export const DATE_OF_INCIDENT = "dateOfIncident"
export const CASE_TYPE = "caseType"
export const ONGOING_COMPLAINTS = "ongoingComplaints"
export const FUTURE_TREATMENTS = "futureTreatments"

export const EDUCATION_LEVEL = "educationLevel"
export const FAMILY_STATUS = "familyStatus"
export const DEPENDANT_STATUS = "dependantStatus"
export const HOUSEHOLD_IMPACT = "householdImpact"
export const HOUSEHOLD_IMPACT_PERCENTAGE = "householdImpactPercentage"

export const UPLOADED = "uploaded"
export const MISSING_DOCS = "missing_docs"

export const PRONOUN_SELECT_OPTIONS = [
  {
    key: "she",
    display: "She",
  },
  {
    key: "he",
    display: "He",
  },
  {
    key: "they",
    display: "They",
  },
]

export const PLAINTIFF_ROLE_SELECT_OPTIONS = [
  {
    key: "driver",
    display: "Driver",
  },
  {
    key: "passenger",
    display: "Passenger",
  },
  {
    key: "pedestrian",
    display: "Pedestrian",
  },
  {
    key: "other",
    display: "Other",
  },
]
