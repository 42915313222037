import React from "react"
import { RenderElementProps } from "slate-react"
import { ParagraphElement } from "../CustomEditor"

interface ParagraphProps extends RenderElementProps {
  element: ParagraphElement
}

export const Paragraph: React.FC<ParagraphProps> = ({ attributes, children }) => {
  return <p {...attributes}>{children}</p>
}
