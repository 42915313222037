import { useEffect, useRef, MutableRefObject } from "react"

const THIRTY_SECONDS_MS = 30_000

interface AutosaveOptions {
  intervalInMs: number
  save: () => void
  shouldAutosave: boolean
}

export default function useAutosave({
  intervalInMs = THIRTY_SECONDS_MS,
  save,
  shouldAutosave,
}: AutosaveOptions): void {
  const intervalId: MutableRefObject<NodeJS.Timer | null> = useRef(null)
  const autosaveRef = useRef<boolean>(shouldAutosave)
  autosaveRef.current = shouldAutosave
  const saveRef = useRef<AutosaveOptions["save"]>(save)
  saveRef.current = save

  useEffect(() => {
    if (intervalId.current === null) {
      intervalId.current = setInterval(() => {
        if (autosaveRef.current) {
          saveRef.current()
        }
      }, intervalInMs)
    }

    return () => {
      if (intervalId.current !== null) {
        clearInterval(intervalId.current)
        intervalId.current = null
      }
    }
  }, [autosaveRef, intervalInMs, saveRef])
}
