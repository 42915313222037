import React, { useState } from "react"
import { IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons"
import { VerticalCenterBox } from "../../common/FlexBox"
import { CustomDamageSection } from "./Firm"
import { CustomDamageSectionType } from "./enums"
import { TemplateSectionForm } from "./TemplateSectionForm"

const useStyles = makeStyles(theme => ({
  invalid: {
    border: "1px solid red",
    borderRadius: theme.shape.borderRadius,
    padding: "5px",
  },
  valid: {
    padding: "5px",
  },
  actionButton: {
    padding: theme.spacing(1),
  },
  card: {
    "& .section-controls": {
      transition: "50ms opacity ease",
      opacity: 0.4,
    },
    "&:hover .section-controls": {
      opacity: 1,
    },
  },
}))

type TemplateSectionProps = {
  section: CustomDamageSection
  onChange: (section: CustomDamageSection) => void
  sectionTypes: ValueOptions<CustomDamageSectionType>
  onDelete: () => void
  compact?: boolean
}

export function TemplateSection({
  section,
  onDelete,
  onChange,
  sectionTypes,
  compact,
}: TemplateSectionProps): JSX.Element {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(!compact && section.title == null)

  const validate = (data: CustomDamageSection): boolean => {
    const valid = data.title !== null && data.title !== ""
    return valid
  }

  const [isValid, setIsValid] = useState(validate(section))

  const handleSectionChange = (data: CustomDamageSection) => {
    const isValid = validate(data)

    if (isValid) {
      setIsValid(true)
      setIsEditing(false)
      onChange(data)
    } else {
      setIsValid(false)
    }
  }

  return (
    <div className={isValid ? classes.valid : classes.invalid}>
      {isEditing ? (
        <TemplateSectionForm section={section} sectionTypes={sectionTypes} onChange={handleSectionChange} />
      ) : (
        <>
          <VerticalCenterBox sx={{ justifyContent: "space-between" }} className={classes.card}>
            {section.title}
            <div className="section-controls">
              <IconButton className={classes.actionButton} onClick={() => setIsEditing(true)}>
                <EditIcon />
              </IconButton>
              <IconButton className={classes.actionButton} onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </div>
          </VerticalCenterBox>
        </>
      )}
    </div>
  )
}
