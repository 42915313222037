import React from "react"
import { Outlet, Link, useNavigate } from "react-router-dom"
import { Box, List, ListItem, ListItemText } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import useUser from "../hooks/useUser"
import useFirm from "../hooks/useFirm"

import { ALL_ROLES, EXTERNAL_ROLES, INTERNAL_ROLES } from "../common/models/roles"
import { NavLink, SETTINGS_ROUTE_PREFIX } from "./types"
import { FirmDto } from "./Firm/Firm"
import { getNavLinkOption } from "./utils"
import { FEATURES, isFeatureEnabled } from "hooks/useFeatures"

const useStyles = makeStyles(theme => ({
  settingsContainer: {
    display: "grid",
    gridTemplateColumns: "10% 90%",
    marginTop: theme.spacing(2),
  },
  settingsList: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  settingsMain: {
    padding: theme.spacing(2),
  },
}))

function BackButton(): JSX.Element {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <ListItem button onClick={handleBack}>
      <ListItemText primary="Go Back" />
    </ListItem>
  )
}

function getNavOptions(role: ALL_ROLES, firm: Nullable<FirmDto>): NavLink[] {
  const defaultNavOptions: NavLink[] = [getNavLinkOption(SETTINGS_ROUTE_PREFIX.PROFILE)]
  let navOptions: NavLink[] = []

  switch (role) {
    case INTERNAL_ROLES.LEGALOPS_ADMIN:
    case INTERNAL_ROLES.LEGALOPS_MANAGER:
      navOptions = [
        ...defaultNavOptions,
        getNavLinkOption(SETTINGS_ROUTE_PREFIX.FIRMS),
        getNavLinkOption(SETTINGS_ROUTE_PREFIX.ACCOUNTS),
      ]
      isFeatureEnabled(FEATURES.TEMPLATES) && navOptions.push(getNavLinkOption(SETTINGS_ROUTE_PREFIX.LIBRARY))
      break
    case INTERNAL_ROLES.LEGALOPS:
      navOptions = [...defaultNavOptions, getNavLinkOption(SETTINGS_ROUTE_PREFIX.FIRMS)]
      isFeatureEnabled(FEATURES.TEMPLATES) && navOptions.push(getNavLinkOption(SETTINGS_ROUTE_PREFIX.LIBRARY))
      break
    case EXTERNAL_ROLES.FIRM_ADMIN:
      navOptions = [
        ...defaultNavOptions,
        getNavLinkOption(SETTINGS_ROUTE_PREFIX.ACCOUNTS, true),
        getNavLinkOption(SETTINGS_ROUTE_PREFIX.CREDITS),
      ]
      break
    default:
      navOptions = defaultNavOptions
      break
  }

  const canFirmSeeCredits = firm?.can_client_view_credits ?? false
  if (!canFirmSeeCredits) {
    navOptions = navOptions.filter(option => {
      return option.link !== SETTINGS_ROUTE_PREFIX.CREDITS
    })
  }

  return navOptions
}

export function Settings(): JSX.Element {
  const classes = useStyles()
  const { user } = useUser()
  const firmId = user.firmId ?? 0
  const { firm } = useFirm(firmId)
  const navOptions = getNavOptions(user.role, firm)

  return (
    <Box className={classes.settingsContainer}>
      <List component="nav" className={classes.settingsList}>
        <BackButton />
        {navOptions.map(({ label, link }) => (
          <ListItem button key={label} component={Link} to={link}>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
      <Box className={classes.settingsMain}>
        <Outlet />
      </Box>
    </Box>
  )
}
