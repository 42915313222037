import { updatePartition } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { noop } from "lodash"
import { UseMutateFunction, useMutation, useQueryClient } from "react-query"
import { queryKeys } from "react-query/constants"
import { mapUpdatePartition, updateAnnotatedExhibits } from "../cacheUtils"
import { AnnotatedExhibit, CaseId, Duplicate, Split, WritablePartition } from "../types"

interface UseUpdatePartitionMutationProps {
  caseId: CaseId
  exhibitId: number
  partitionId: number
  errorMsg?: string
  onSuccess?: (data: Split | Duplicate) => unknown
}

interface UseUpdatePartitionMutationReturn {
  update: UseMutateFunction<
    Split | Duplicate,
    unknown,
    {
      exhibitId: number
      partitionId: number
      data: Partial<WritablePartition>
    },
    unknown
  >
  isUpdating: boolean
}

export default function useUpdatePartitionMutation({
  caseId,
  exhibitId,
  partitionId,
  errorMsg,
  onSuccess = noop,
}: UseUpdatePartitionMutationProps): UseUpdatePartitionMutationReturn {
  const queryClient = useQueryClient()
  const { showMessage } = useHandleMessages()
  const { mutate: update, isLoading: isUpdating } = useMutation({
    mutationFn: updatePartition,
    onSuccess: (data: Split | Duplicate) => {
      queryClient.setQueryData<AnnotatedExhibit[]>([queryKeys.annotated_exhibits, caseId], oldData => {
        return updateAnnotatedExhibits({
          oldData,
          exhibitId,
          update: exhibit => {
            const split = exhibit.splits.find(split => split.pk === partitionId)

            if (split) {
              return {
                splits: mapUpdatePartition({ partitions: exhibit.splits, partitionId, update: data }),
              } as Partial<AnnotatedExhibit>
            }
            // must be a duplicate if could not find partition id in splits
            return {
              duplicates: mapUpdatePartition({ partitions: exhibit.duplicates, partitionId, update: data }),
            } as Partial<AnnotatedExhibit>
          },
        })
      })
      onSuccess(data)
    },
    onError: () => {
      showMessage({
        type: "error",
        message:
          errorMsg ??
          "Could not update partition. Try again shortly and file an issue if the problem persists.",
      })
    },
  })

  return {
    update,
    isUpdating,
  }
}
