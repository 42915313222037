import React, { FormEvent, useEffect, useRef, useState } from "react"
import { Control, useForm } from "react-hook-form"
import { Box, Button, CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import { FirmDetailFields, FirmDetailDataFields } from "./FirmDetailFields"
import { FirmTemplateDataFields, FirmTemplateFields } from "./FirmTemplateFields"
import { FirmHeader, FirmHeaderDataFields } from "./FirmHeader"

import { Tab, Tabs } from "../../common/tabs"
import { FirmData } from "./Firm"

const useStyles = makeStyles(theme => ({
  submit: {
    "& .MuiCircularProgress-root": {
      marginRight: theme.spacing(1),
    },
  },
  formFields: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr);",
    gap: theme.spacing(4),
  },
  disabled: {
    opacity: 0.2,
  },
  actions: {
    "& > button": {
      marginLeft: theme.spacing(2),
    },
  },
}))

type FirmFormSyncState = Partial<Record<keyof FirmData, boolean>>

interface FirmFormProps {
  firmData: FirmData
  onCreate: (data: FirmData, options?: Record<string, unknown>) => Promise<FirmData>
  onUpdate: (data: Partial<FirmData>, options?: Record<string, unknown>) => Promise<FirmData>
  onClose: (data: FirmData, options?: Record<string, unknown>) => Promise<void>
  onLogoUpdate: (file: File, options?: Record<string, unknown>) => Promise<FirmData>
  onTemplateUpdate: (file: File, options?: Record<string, unknown>) => Promise<FirmData>
  onTemplateDelete: () => Promise<void>
  onTemplateDownload: (fileName: string) => Promise<void>
  onCsrUpdate: (enable: boolean) => Promise<{ results: Pick<FirmData, "has_collateral_source_rule"> }>
}

export const FirmForm: React.FC<FirmFormProps> = ({
  firmData,
  onCreate,
  onUpdate,
  onClose,
  onLogoUpdate,
  onTemplateUpdate,
  onTemplateDelete,
  onTemplateDownload,
  onCsrUpdate,
}) => {
  const classes = useStyles()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields, isValid, isSubmitting },
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: firmData,
    mode: "onChange",
  })
  const [syncState, setSyncState] = useState<FirmFormSyncState>({})
  const isNewFirm = !getValues("pk")
  const isNewFirmCreating = isNewFirm && isSubmitting
  const submitRef = useRef<Promise<void>>(Promise.resolve())

  const handleLogoChange = async (file: File) => {
    setSyncState({ ...syncState, logo: true })
    reset(await onLogoUpdate(file), { keepValues: true })
    setSyncState({ ...syncState, logo: false })
  }

  const handleTemplateDelete = async () => {
    setSyncState({ ...syncState, templateData: true })
    await onTemplateDelete()
    reset({ template: null, templateData: null }, { keepValues: true })
    setValue("template", null, { shouldDirty: false, shouldTouch: false, shouldValidate: false })
    setValue("templateData", null, { shouldDirty: false, shouldTouch: false, shouldValidate: false })
    setSyncState({ ...syncState, templateData: false })
  }

  const handleTemplateDownload = async (fileName: string) => {
    await onTemplateDownload(fileName)
  }

  const handleTemplateChange = async (file: Nullable<File>) => {
    setSyncState({ ...syncState, templateData: true })

    if (file) {
      const updatedData = await onTemplateUpdate(file, { reset, setValue })
      reset({ ...updatedData, templateData: null }, { keepValues: false })
    }

    setSyncState({ ...syncState, templateData: false })
  }

  const handleCsrChange = async (enable: boolean) => {
    const response = await onCsrUpdate(enable)
    reset({ ...response.results }, { keepValues: true })
  }

  const handleFirmCreate = async (data: FirmData) => {
    const updatedData = await onCreate(data)

    reset(updatedData, { keepValues: true })
    setValue("pk", updatedData.pk)
  }

  const handleFirmUpdate = async (data: Partial<FirmData>) => {
    const sectionFields: Array<keyof FirmData> = ["sections", "deletedSections"]
    const updatedData = await onUpdate(data)

    reset(updatedData, { keepValues: true })

    if (sectionFields.some(field => dirtyFields[field])) {
      setValue("sections", updatedData.sections)
      setValue("deletedSections", [])
    }
  }

  const handleFormSave = handleSubmit(async data => {
    if (!isValid || isNewFirmCreating) {
      return
    }

    if (isNewFirm) {
      return await handleFirmCreate(data as FirmData)
    }

    if (dirtyFields.templateData) {
      await handleTemplateChange(data.templateData || null)
    }

    if (dirtyFields.has_collateral_source_rule) {
      await handleCsrChange(data.has_collateral_source_rule)
    }

    const fileFields: (keyof FirmData)[] = ["logo", "template", "templateData"]
    const fieldsToOmit: (keyof FirmData)[] = [...fileFields, "has_collateral_source_rule"]
    const dirtyFieldsKeys = Object.keys(dirtyFields) as (keyof FirmData)[]

    const fieldsToUpdate = dirtyFieldsKeys.filter(field => !fieldsToOmit.includes(field))

    if (!fieldsToUpdate.length) {
      return
    }

    const dataToUpdate = fieldsToUpdate.reduce<Partial<FirmData>>(
      (nextData, field) => ({
        ...nextData,
        [field]: data[field],
      }),
      {}
    )

    await handleFirmUpdate(dataToUpdate)
  })

  const handleFormSubmit = async (e?: FormEvent) => {
    submitRef.current = submitRef.current.then(() => handleFormSave(e))
    await submitRef.current
  }
  const handleFormSubmitRef = useRef<(e: FormEvent) => Promise<void>>(handleFormSubmit)

  useEffect(() => {
    handleFormSubmitRef.current = handleFormSubmit
  })

  const handleSave = async (e: FormEvent) => {
    await handleFormSubmitRef.current(e)
  }

  const handleSaveAndClose = async (e: FormEvent) => {
    await handleFormSubmitRef.current(e)

    onClose(getValues())
  }

  return (
    <form noValidate onBlur={!isNewFirm ? handleFormSubmit : undefined} onSubmit={handleFormSubmit}>
      <FirmHeader
        firmId={getValues("pk") || null}
        control={control as Control<FirmHeaderDataFields>}
        errors={errors}
        onLogoChange={handleLogoChange}
        editable
        syncState={syncState}
        getValues={getValues}
        renderButtons={() => (
          <>
            {isNewFirm && !isSubmitting && (
              <Button type="submit" variant="contained" color="primary" data-test="create-new-firm-button">
                Create new firm
              </Button>
            )}
            {isNewFirm && isSubmitting && (
              <Button
                className={classes.submit}
                variant="contained"
                color="primary"
                disabled
                data-test="create-new-firm-button-loading"
              >
                <CircularProgress size={14} color="secondary" />
                Creating new firm
              </Button>
            )}
          </>
        )}
      />
      <Tabs className={clsx(isNewFirm && classes.disabled)}>
        <Tab title="Contact Details" className={classes.formFields}>
          <FirmDetailFields
            control={control as Control<FirmDetailDataFields>}
            disabled={isNewFirm}
            watch={watch}
          />
        </Tab>
        <Tab title="Firm Template" className={classes.formFields}>
          <FirmTemplateFields
            control={control as Control<FirmTemplateDataFields>}
            disabled={isNewFirm}
            onUpdate={handleFormSubmit}
            templateName={getValues("templateData")?.name || getValues("template")?.name}
            syncState={syncState}
            onTemplateDelete={handleTemplateDelete}
            onTemplateDownload={handleTemplateDownload}
          />
        </Tab>
      </Tabs>
      <Box className={classes.actions}>
        <Button onClick={handleSave} variant="outlined" color="secondary">
          Save
        </Button>
        <Button onClick={handleSaveAndClose} variant="outlined" color="primary">
          {isSubmitting ? "Saving..." : "Save & Exit"}
        </Button>
      </Box>
    </form>
  )
}
