import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useSensors, useSensor, DndContext, DragOverlay, MouseSensor, closestCorners } from "@dnd-kit/core"
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers"
import { defaultRenderItem, NestedList } from "./NestedList"
import { SortableNestedListItem } from "./SortableNestedListItem"
import { SortableEmptyPlaceholder } from "./SortableEmptyPlaceholder"
import { ITEM_REF } from "./constants"
import {
  getFlatDataProjection,
  getDataFromProjection,
  normalizeFromProjectionWithRef,
} from "./utils/projection"
import { isDragging } from "./utils/drag"
import { handleDrop } from "./utils/drop"

const useStyles = makeStyles(() => ({
  dragOverlay: {
    opacity: 0.9,
    cursor: "grab",
  },
}))

class CustomMouseSensor extends MouseSensor {
  attach() {
    super.attach()
    this.windowListeners.add("canceldragging", this.handleCancel)
  }
}

function defaultRenderEmptyPlaceholder() {
  return <div>Drop items here</div>
}

function defaultCanDrag() {
  return true
}

export function SortableNestedList({
  data,
  uniqueKey = "id",
  onUpdate,
  renderItemContent,
  renderItem = defaultRenderItem,
  renderEmptyPlaceholder = defaultRenderEmptyPlaceholder,
  canDrag: checkIdCanDrag = defaultCanDrag,
  canDrop: checkIfCanDrop,
  canDropAsChild,
  getBackgroundColor = () => "#FFF",
}) {
  const classes = useStyles()
  const [draggingItemId, setDraggingItemId] = useState(null)
  const projectedData = getFlatDataProjection(data, uniqueKey)
  const renderData = normalizeFromProjectionWithRef(projectedData)

  useEffect(() => {
    if (draggingItemId && !projectedData[draggingItemId]) {
      window.dispatchEvent(new CustomEvent("canceldragging"))
    }
  }, [projectedData, draggingItemId])

  function renderSortableItem(renderContent, props) {
    const canDropToItem = draggingItemId !== null && !isDragging(projectedData, draggingItemId, props.id)
    const canDrop =
      canDropToItem &&
      (!checkIfCanDrop || checkIfCanDrop(projectedData[props.id], draggingItemId, projectedData))
    const canDrag = checkIdCanDrag(projectedData[props.id], projectedData)

    return (
      <SortableNestedListItem
        key={props.id}
        id={props.id}
        canDrop={canDrop}
        canDrag={canDrag}
        renderContent={renderContent}
        backgroundColor={getBackgroundColor(props.ITEM_REF)}
        {...props}
      />
    )
  }

  function renderSortableEmptyPlaceholder(props) {
    const canDropToItem = draggingItemId !== null && !isDragging(projectedData, draggingItemId, props.id)
    const canDrop = canDropToItem && (!canDropAsChild || canDropAsChild(props, draggingItemId, projectedData))
    const emptyPlaceholderContent = renderEmptyPlaceholder(props)

    if (!emptyPlaceholderContent) {
      return null
    }

    return (
      <SortableEmptyPlaceholder key={`placeholder-${props.id}`} {...props} canDrop={canDrop}>
        {emptyPlaceholderContent}
      </SortableEmptyPlaceholder>
    )
  }

  const sensors = useSensors(useSensor(CustomMouseSensor))
  const handleDragStart = event => setDraggingItemId(event.active.id)
  const handleDragEnd = event => {
    setDraggingItemId(null)

    const targetItemData = event.over.data.current
    const currentItemId = event.active.id
    const updatedData = handleDrop(projectedData, currentItemId, targetItemData.id, targetItemData.type)

    onUpdate(getDataFromProjection(updatedData, uniqueKey))
  }

  return (
    <DndContext
      modifiers={[restrictToFirstScrollableAncestor]}
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      collisionDetection={closestCorners}
    >
      <NestedList
        data={renderData}
        renderItemContent={renderItemContent}
        renderItem={renderSortableItem}
        renderEmptyPlaceholder={renderSortableEmptyPlaceholder}
      />
      <DragOverlay className={classes.dragOverlay}>
        {draggingItemId &&
          projectedData[draggingItemId] &&
          renderItem(renderItemContent, {
            item: { ...projectedData[draggingItemId][ITEM_REF], children: [] },
            compact: true,
          })}
      </DragOverlay>
    </DndContext>
  )
}
