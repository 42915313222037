import React from "react"

export enum FORM_STATE_STATUS {
  IDLE = "idle",
  DATA_ERROR = "data_error",
  LACK_OF_DATA = "lack_of_data",
  API_ERROR = "api_error",
  SUCCESS = "success",
}

const BASIC_FORM_MESSAGES: Record<FORM_STATE_STATUS, Nullable<JSX.Element>> = {
  [FORM_STATE_STATUS.IDLE]: null,
  [FORM_STATE_STATUS.LACK_OF_DATA]: null,
  [FORM_STATE_STATUS.DATA_ERROR]: (
    <>
      <b>Template already exists.</b> Please input a different attribute.
    </>
  ),
  [FORM_STATE_STATUS.API_ERROR]: (
    <>
      <b>Something went wrong.</b> Please try again and if your issue persists contact our support team
    </>
  ),
  [FORM_STATE_STATUS.SUCCESS]: null,
}

export const CREATE_TEMPLATE_FORM_STATE_MESSAGES: Record<FORM_STATE_STATUS, Nullable<JSX.Element>> = {
  ...BASIC_FORM_MESSAGES,
  [FORM_STATE_STATUS.SUCCESS]: (
    <>
      <b>Success!</b> New template has been added below.
    </>
  ),
}

export const CREATE_VARIABLE_FORM_STATE_MESSAGES: Record<FORM_STATE_STATUS, Nullable<JSX.Element>> = {
  ...BASIC_FORM_MESSAGES,
  [FORM_STATE_STATUS.DATA_ERROR]: (
    <>
      <b>A variable already exists with the given attributes and group.</b> Please input a different attribute
      or a different group.
    </>
  ),
}

export const UPDATE_TEMPLATE_FORM_STATE_MESSAGES: Record<FORM_STATE_STATUS, Nullable<JSX.Element>> = {
  ...BASIC_FORM_MESSAGES,
}

export const UPDATE_VARIABLE_FORM_STATE_MESSAGES: Record<FORM_STATE_STATUS, Nullable<JSX.Element>> = {
  ...BASIC_FORM_MESSAGES,
  [FORM_STATE_STATUS.DATA_ERROR]: (
    <>
      <b>A variable already exists with the given attributes and group.</b> Please input a different attribute
      or a different group.
    </>
  ),
}
