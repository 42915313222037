import React, { useEffect, useState } from "react"
import { useFormContext } from "./FormContext"
import { Box, Button, Collapse, RadioGroup, FormControlLabel, Radio } from "@material-ui/core"
import { ExpandMore, ExpandLess } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  group: {
    margin: theme.spacing(1, 0),
  },
  radio: {
    fontSize: theme.typography.pxToRem(14),
  },
}))

export default function SurgeriesControl() {
  const classes = useStyles()
  const { queryState, queryActions, handleQueryUpdate } = useFormContext()
  const facetKey = "treatments"
  const [surgeriesVisibility, setSurgeriesVisibility] = useState("none")
  const [showSurgeriesControl, setShowSurgeriesControl] = useState(false)

  useEffect(() => {
    const currentValue = queryState.query[facetKey]
    if (!currentValue) {
      setSurgeriesVisibility("none")
    } else {
      currentValue && currentValue === "exclude_surgery" && setSurgeriesVisibility("exclude")
      currentValue && currentValue === "only_surgery" && setSurgeriesVisibility("only")
      setShowSurgeriesControl(true)
    }
  }, [queryState])

  const handleChange = event => {
    const { value } = event.target
    if (value === "none") {
      handleQueryUpdate(queryActions.RESET_RANGE_FACET, facetKey)
    } else {
      handleQueryUpdate(queryActions.APPLY_RANGE_FACET, {
        name: facetKey,
        value: value === "exclude" ? "exclude_surgery" : "only_surgery",
      })
    }
  }

  return (
    <Box key={facetKey}>
      <Button
        style={{ fontSize: ".75rem" }}
        size="small"
        variant="contained"
        onClick={() => setShowSurgeriesControl(!showSurgeriesControl)}
        endIcon={showSurgeriesControl ? <ExpandLess /> : <ExpandMore />}
      >
        Surgeries visibility
      </Button>
      <Collapse in={showSurgeriesControl}>
        <RadioGroup value={surgeriesVisibility} onChange={handleChange} className={classes.group}>
          <FormControlLabel
            classes={{ label: classes.radio }}
            value="none"
            control={<Radio />}
            label="Default"
          />
          <FormControlLabel
            classes={{ label: classes.radio }}
            value="only"
            control={<Radio />}
            label="Only show cases with surgeries"
          />
          <FormControlLabel
            classes={{ label: classes.radio }}
            value="exclude"
            control={<Radio />}
            label="Exclude cases with surgeries"
          />
        </RadioGroup>
      </Collapse>
    </Box>
  )
}
