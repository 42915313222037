import React from "react"
import { Box, Button, DialogActions, DialogContent, TextField, Tooltip, Typography } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"
import { HelpOutline, Lock, LockOpen } from "@material-ui/icons"

import { Controller, useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { createContract, deleteContract, getFirmsFirstContract, updateContract } from "../../../api"

import { useHandleMessages } from "../../../common/messages/useHandleMessages"
import useUser from "hooks/useUser"

import { Contract } from "../../../common/interfaces"
import { CurrencyField, DateField, InputField, SwitchInput } from "common/form-components"
import { InputWithHeader } from "../../../common/form-components/InputWithHeader"
import { MainTitle } from "../../../app/styled"
import { TitleAndValue } from "./ContractCard"
import TextButton from "common/buttons/TextButton"

import { formatDate, toUSDateString } from "../../../utils"
import { queryKeys } from "../../../react-query/constants"
import { canRoleManageContracts } from "../permissions"
import { Alert } from "@material-ui/lab"
import {
  ContractDatesObject,
  findContractOverlap,
  getEndDate,
  parseContractDate,
} from "common/contracts/utils"

const Container = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3, 4),
  display: "flex",
  flexDirection: "column",
  minWidth: "800px",
  [theme.breakpoints.down("sm")]: {
    minWidth: "600px",
  },
  [theme.breakpoints.down("xs")]: {
    minWidth: "400px",
  },
}))

const Row = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  display: "flex",
}))

const ButtonContainer = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2, 2, 2, 4),
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  display: "flex",
  justifyContent: "space-between",
}))

const RightSideButtons = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3),
}))

const SwitchLabel = styled(Box)(({ theme }) => ({
  margin: theme.spacing("auto", 0, "auto", 0.5),
  fontStyle: "italic",
}))

interface Props {
  contract: Contract | null
  firmId?: number
  onSubmitCallback: () => void
  onClose: () => void
  contracts: Contract[]
}

const overlapErrorMessage = (overlaps: ContractDatesObject[]) =>
  `Overlaps with other contract dates: ${overlaps
    .map(
      overlap =>
        `(${toUSDateString(overlap.start_date)} - ${toUSDateString(
          overlap.cancellation_date || overlap.end_date
        )})`
    )
    .join(", ")}`

export const ContractForm = ({
  contract,
  firmId,
  onSubmitCallback,
  onClose,
  contracts,
}: Props): JSX.Element => {
  const INITIAL_FORM_STATE = {
    monthly_price: contract?.monthly_price ?? 2750.0,
    start_date: contract?.start_date ?? "",
    term_length: contract?.term_length ?? 12,
    auto_renewal: contract?.auto_renewal ?? true,
    monthly_credits: contract?.monthly_credits ?? 5,
    medical_pages_cap: contract?.medical_pages_cap ?? 250,
    medical_pages_overage_fee: contract?.medical_pages_overage_fee ?? 150.0,
    medical_pages_overage: contract?.medical_pages_overage ?? 250,
    verdict_fee: contract?.verdict_fee ?? 50.0,
    firm_id: firmId,
    price_per_credit: contract?.price_per_credit ?? null,
    note: contract?.note ?? "",
  }

  const { user } = useUser()

  const { data: firstContract } = useQuery<Contract>(
    [queryKeys.firmContracts, firmId],
    async () => {
      return await getFirmsFirstContract(firmId)
    },
    {
      enabled: canRoleManageContracts(user.role) && !!firmId,
    }
  )

  const isFirstContract = firstContract?.pk === contract?.pk

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: INITIAL_FORM_STATE,
  })
  const queryClient = useQueryClient()
  const { showMessage } = useHandleMessages()

  const createContractMutation = useMutation(createContract, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.firm)
      queryClient.invalidateQueries(queryKeys.firmContracts)
      showMessage({
        type: "success",
        message: "The contract was successfully created",
      })
      onSubmitCallback()
    },
    onError: () => {
      showMessage({
        type: "error",
        message: "There was an error creating the contract",
      })
    },
  })
  const updateContractMutation = useMutation(updateContract, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.firm)
      queryClient.invalidateQueries(queryKeys.firmContracts)
      showMessage({
        type: "success",
        message: "The contract was successfully updated",
      })
      onSubmitCallback()
    },
    onError: () => {
      showMessage({
        type: "error",
        message: "There was an error updating the contract",
      })
    },
  })
  const deleteContractMutation = useMutation(deleteContract, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.firmContracts)
      showMessage({
        type: "success",
        message: "Contract deleted.",
      })
      onSubmitCallback()
    },
    onError: () => {
      showMessage({
        type: "error",
        message: "Could not delete contract. Try again shortly or contact a dev if your problem persists.",
      })
    },
  })

  const handleSaveContract = handleSubmit(async data => {
    const overlaps = findContractOverlap({
      idToIgnore: contract?.pk,
      startDate: parseContractDate(data.start_date),
      endDate: getEndDate(data.start_date, data.term_length),
      contracts,
    })

    if (overlaps.length > 0) {
      setError("start_date", {
        message: overlapErrorMessage(overlaps),
      })
      return
    }

    if (contract?.pk) {
      updateContractMutation.mutate({ data: data, contractId: contract.pk })
    } else {
      createContractMutation.mutate({ data: data })
    }
  })

  const startDate = watch("start_date")
  const termLength = watch("term_length")
  const monthlyPrice = watch("monthly_price")
  const monthlyCredits = watch("monthly_credits")

  const automaticPricePerCredit = Number(monthlyPrice ?? 0) / (Number(monthlyCredits) || 1)
  const overridePricePerCredit = watch("price_per_credit")

  return (
    <>
      <Container data-test="contract-form">
        <Box mb={1}>
          <MainTitle mb={0}>{contract ? "Edit Contract" : "Create Contract"}</MainTitle>
          {contract?.cancellation_date && (
            <Alert severity="info">
              Scheduled for cancellation: {formatDate(contract?.cancellation_date, "MM/dd/yyyy", true)}
            </Alert>
          )}
        </Box>
        <Box display="flex" flexDirection="column">
          <Row>
            <InputWithHeader header="Monthly Price">
              <CurrencyField
                control={control}
                errors={errors}
                size={"small"}
                variant="outlined"
                name="monthly_price"
                data-test="monthly-price"
              />
            </InputWithHeader>
          </Row>
          <Row>
            <InputWithHeader header="Start Date">
              <Controller
                name={"start_date"}
                control={control}
                rules={{
                  required: "You must provide a valid start date",
                }}
                render={({ field }) => {
                  return (
                    <Box maxWidth="231px">
                      <DateField
                        initialValue={getValues("start_date") ?? null}
                        {...field}
                        error={!!errors?.start_date}
                        helperText={errors?.start_date?.message}
                        fieldProps={{
                          size: "small",
                        }}
                        dataTest="start-date"
                      />
                    </Box>
                  )
                }}
              />
              {!!firstContract?.start_date && !isFirstContract && (
                <Box mt="5%" data-test="term-ends-date">
                  <TitleAndValue
                    title="First Contract Start Date"
                    value={formatDate(firstContract.start_date, "MM/dd/yyyy", true)}
                  ></TitleAndValue>
                </Box>
              )}
            </InputWithHeader>

            <InputWithHeader header="Term Length (Months)">
              <InputField
                control={control}
                errors={errors}
                size={"small"}
                variant="outlined"
                name="term_length"
                type="number"
              />
            </InputWithHeader>

            {!!startDate && termLength !== undefined && (
              <Box mt="5%" data-test="term-ends-date">
                <TitleAndValue
                  title="Term Ends"
                  value={formatDate(getEndDate(startDate, termLength), "MM/dd/yyyy", true)}
                ></TitleAndValue>
              </Box>
            )}
          </Row>
          <Row>
            <SwitchInput control={control} errors={errors} name="auto_renewal" label={"Auto Renewal"} />
            <SwitchLabel>{watch("auto_renewal") ? "On" : "Off"}</SwitchLabel>
          </Row>
          <Row>
            <InputWithHeader header="Credits Per Month">
              <InputField
                control={control}
                errors={errors}
                size={"small"}
                variant="outlined"
                name="monthly_credits"
                type="number"
                data-test="monthly-credits"
              />
            </InputWithHeader>
            <Box mt="5%" data-test="total-credits">
              <TitleAndValue
                title="Total Credits Per Term"
                value={termLength * monthlyCredits}
              ></TitleAndValue>
            </Box>
          </Row>
          <Row>
            <InputWithHeader
              header={
                <>
                  Price Per Credit{" "}
                  <Tooltip
                    title="Price per credit is calculated with: Monthly Price / Monthly Credits. In most cases it can be automatically calculated, however if you wish to give a customer extra credits without affecting the value of a credit in the credit calculator you can manually override it."
                    arrow
                  >
                    <HelpOutline fontSize="small" />
                  </Tooltip>
                </>
              }
            >
              <Controller
                name="price_per_credit"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      size={"small"}
                      variant="outlined"
                      data-test="price-per-credit"
                      {...field}
                      disabled={overridePricePerCredit === null}
                      value={overridePricePerCredit ?? automaticPricePerCredit}
                    />
                  )
                }}
              />
            </InputWithHeader>
            <Box alignSelf="flex-end">
              <Button
                data-test="price-per-credit-override"
                onClick={() =>
                  overridePricePerCredit === null
                    ? setValue("price_per_credit", automaticPricePerCredit)
                    : setValue("price_per_credit", null)
                }
                startIcon={overridePricePerCredit === null ? <LockOpen /> : <Lock />}
              >
                {overridePricePerCredit === null ? "Override" : "Automatic"}
              </Button>
            </Box>
          </Row>
          <Row>
            <InputWithHeader header="Medical Record Page Limit">
              <InputField
                control={control}
                errors={errors}
                size={"small"}
                variant="outlined"
                name="medical_pages_cap"
                type="number"
              />
            </InputWithHeader>
          </Row>
          <Row>
            <InputWithHeader header="Overage Pages">
              <InputField
                control={control}
                errors={errors}
                size={"small"}
                variant="outlined"
                name="medical_pages_overage"
                type="number"
              />
            </InputWithHeader>
            <InputWithHeader header="Overage Fees">
              <CurrencyField
                control={control}
                errors={errors}
                size={"small"}
                name="medical_pages_overage_fee"
              />
            </InputWithHeader>
          </Row>
          <Row>
            <InputWithHeader header="Verdict Fees">
              <CurrencyField control={control} errors={errors} size={"small"} name="verdict_fee" />
            </InputWithHeader>
          </Row>
          <Row>
            <InputWithHeader fullWidth header="Notes">
              <InputField
                control={control}
                errors={errors}
                size={"small"}
                variant="outlined"
                name="note"
                type="text"
                multiline
                fullWidth
              />
            </InputWithHeader>
          </Row>
        </Box>
      </Container>
      <ButtonContainer>
        <Box display="flex" alignItems="center">
          {!!contract && (
            <TextButton
              textColor="secondary"
              size="large"
              disabled={contract?.active || deleteContractMutation.isLoading}
              onClick={() => {
                if (confirm("Are you sure you want to delete this contract?")) {
                  deleteContractMutation.mutate(contract.pk)
                }
              }}
              data-test="delete-contract-button"
            >
              {deleteContractMutation.isLoading ? "Deleting Contract" : "Delete Contract"}
            </TextButton>
          )}
          <Box>
            {contract?.active && <Typography variant="caption">* Cannot delete active contracts</Typography>}
          </Box>
        </Box>
        <RightSideButtons>
          <Button
            disableElevation
            size="large"
            variant="contained"
            onClick={onClose}
            data-test="cancel-button"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveContract}
            data-test="save-contract-button"
            size="large"
            disableElevation
          >
            {contract ? "Update" : "Create"}
          </Button>
        </RightSideButtons>
      </ButtonContainer>
    </>
  )
}
