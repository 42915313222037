import React from "react"
import TextButton from "common/buttons/TextButton"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Divider, Tooltip } from "@material-ui/core"
import { HelpOutline } from "@material-ui/icons"
import { useFormContext } from "demand/context"

import { decodeExhibitValue, getBillExhibitValue } from "./utils"
import { ADD_BILL, EXHIBIT_CHANGE } from "demand/Providers/reducer"
import { SectionHeading } from "../styled"
import { ExhibitSelect } from "./ExhibitSelect"
import { BillRow } from "./BillRow"

import { useFeatures, FEATURES } from "hooks/useFeatures"
import Bills from "../../Annotation/Bills/Bills"
import SectionContainer from "../../SectionContainer"

const useStyles = makeStyles(theme => ({
  helpIcon: {
    fontSize: "1rem",
    verticalAlign: "text-bottom",
  },
  billsGrid: {
    display: "grid",
    gridTemplateColumns: ({ hasCollateralSourceRule }) =>
      hasCollateralSourceRule ? "2fr 1fr 1fr auto" : "2fr 1fr 1fr 1fr auto",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  billsSelect: {
    alignSelf: "flex-start",
  },
  inputLabelText: {
    fontSize: "13px",
    fontWeight: 600,
  },
}))

export const BillsSection = ({
  validationErrors,
  dispatch,
  disabled,
  provider,
  hasCollateralSourceRule,
  onAnnotationClick,
  annotationDateUpdated = "",
}) => {
  const classes = useStyles({ hasCollateralSourceRule })
  const { caseId } = useFormContext()
  const { isFeatureEnabled } = useFeatures()
  const isProviderAutofillEnabled = isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL)
  const hasPartitionProvider = isProviderAutofillEnabled && !!provider.partition_provider

  const handleExhibitChange = (bill, index) => event => {
    const [type, id] = decodeExhibitValue(event.target.value)

    dispatch({
      type: EXHIBIT_CHANGE,
      payload: { bill, index, type, id, providerId: provider.pk },
    })
  }

  const handleBillAdd = () => {
    dispatch({
      type: ADD_BILL,
      payload: { caseId, providerId: provider.pk },
    })
  }

  return (
    <>
      <SectionContainer>
        <Box>
          <SectionHeading mb={4}>Bills</SectionHeading>
          {Boolean(provider.bills?.length) && (
            <Box className={classes.billsGrid}>
              <Box className={classes.inputLabelText}>Bill description</Box>
              <Box className={classes.inputLabelText}>Amount billed</Box>
              {!hasCollateralSourceRule && <Box className={classes.inputLabelText}>Adjustment amount</Box>}
              <Box className={classes.inputLabelText}>
                Related file{" "}
                <Tooltip
                  placement="top"
                  arrow
                  title="Upload documents in the section above in order to select from this dropdown."
                >
                  <HelpOutline className={classes.helpIcon} />
                </Tooltip>
              </Box>
              <Box className={classes.inputLabelText} />
              {provider.bills.map((bill, index) => {
                const billId = bill.pk ?? bill.formId
                return (
                  <BillRow
                    key={billId}
                    bill={bill}
                    validationErrors={validationErrors["bills"]?.[index] || {}}
                    disabled={disabled}
                    dispatch={dispatch}
                    providerId={provider.pk}
                    hasCollateralSourceRule={hasCollateralSourceRule}
                    exhibitSection={
                      <ExhibitSelect
                        value={getBillExhibitValue(bill)}
                        provider={provider}
                        onChange={handleExhibitChange(bill, index)}
                        disabled={
                          disabled || (!provider?.filesToUpload?.length && !provider?.exhibits?.length)
                        }
                        error={!!validationErrors["bills"]?.[index]?.exhibit_id}
                        helperText={validationErrors["bills"]?.[index]?.exhibit_id}
                      />
                    }
                  />
                )
              })}
            </Box>
          )}
          <TextButton onClick={handleBillAdd} disabled={disabled} data-test="add-new-bill">
            + Add New Bill
          </TextButton>
        </Box>
        {hasPartitionProvider && (
          <Box>
            <Bills
              caseId={caseId}
              providerId={provider.pk}
              currentBills={provider.bills ?? []}
              onEntryClick={onAnnotationClick}
              dateUpdated={annotationDateUpdated}
            />
          </Box>
        )}
      </SectionContainer>
      <Box my={6}>
        <Divider />
      </Box>
    </>
  )
}
