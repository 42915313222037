import React, { useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { IconButton } from "@material-ui/core"
import { Card, CardActionArea, CardMedia, CardHeader, CardActions } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { DeleteOutlined as DeleteIcon } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  cardHeader: {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "& > .MuiCardHeader-content": {
      overflow: "hidden",
    },
    "& span": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  },
  listCard: {
    width: "300px",
    margin: theme.spacing(1),
  },
  cardContent: {
    display: "flex",
    overflow: "hidden",
    justifyContent: "space-between",
  },
  list: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
  },
}))

/**
 * A gallery for images. `images` should be a list of objects that have a name
 * property. `downloadImage(image) should give the image data.
 */
export function DnDImageGallery({ id, caseId, images, onFileDelete, downloadImage, reorder }) {
  const [imageBlobs, setImageBlobs] = useState([])
  const [loading, setIsLoading] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    async function fetchImageBlobs() {
      setIsLoading(true)
      const imageBlobPromises = images.map(async image => await downloadImage(image))
      const imageBlobData = await Promise.all(imageBlobPromises)
      const imagesWithObjectURLs = images.map((image, index) => {
        return { ...image, objectUrl: URL.createObjectURL(imageBlobData[index]) }
      })
      setImageBlobs(imagesWithObjectURLs)
      setIsLoading(false)
    }

    fetchImageBlobs()
    return () => images.forEach(image => URL.revokeObjectURL(image.objectUrl))
  }, [images, caseId, downloadImage])

  const onDragEndInner = ({ source: src, destination: dst }) => {
    if (!src || !dst) {
      return
    }
    const [movedItem] = images.splice(src.index, 1)
    images.splice(dst.index, 0, movedItem)
    reorder(images)
  }
  return (
    <>
      {!loading && (
        <DragDropContext onDragEnd={onDragEndInner}>
          <Droppable droppableId={id} direction="horizontal">
            {provided => (
              <div ref={provided.innerRef} className={classes.list} {...provided.droppableProps}>
                {imageBlobs.map((image, index) => (
                  <Draggable key={image.pk} draggableId={String(image.pk)} index={index}>
                    {provided => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Card className={classes.listCard} variant="outlined">
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="100px"
                              className={classes.listImage}
                              image={image.objectUrl}
                              alt={image.name}
                            />
                          </CardActionArea>
                          <div className={classes.cardContent}>
                            <CardHeader
                              className={classes.cardHeader}
                              title={image.name}
                              titleTypographyProps={{ variant: "h6" }}
                              subheader={index + 1}
                            />
                            <CardActions>
                              <IconButton onClick={() => onFileDelete(image.pk)}>
                                <DeleteIcon />
                              </IconButton>
                            </CardActions>
                          </div>
                        </Card>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  )
}

export default DnDImageGallery
