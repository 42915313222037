import React, { useCallback, useMemo } from "react"
import { Box, IconButton } from "@material-ui/core"
import { HighlightOffSharp as RemoveIcon } from "@material-ui/icons"
import { styled } from "@material-ui/core/styles"
import {
  Control,
  Controller,
  ErrorOption,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"

import { useMutation } from "react-query"
import { updateRequest } from "api"

import TextButton from "common/buttons/TextButton"
import { CheckboxInput, InputField, SelectInput } from "common/form-components"
import { useRequestContext } from "requests/context"
import { PRONOUN_SELECT_OPTIONS, PLAINTIFF_ROLE_SELECT_OPTIONS, EMPTY_PLAINTIFF } from "requests/constants"
import { ordinalSuffixOf } from "../../utils"

import { PlaintiffDto } from "../ViewRequest/types"

const FormRow = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "4fr 4fr 3fr 3fr 3fr 1fr",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(1),
}))

const CenteredIconButton = styled(IconButton)({
  placeSelf: "center",
})

interface PlaintiffsFormProps {
  allowMultiple?: boolean
}

interface ContextValues {
  control: Control
  errors: ErrorOption[]
  getValues: UseFormGetValues<any>
  requestId: Nullable<number>
  setValue: UseFormSetValue<any>
  watch: UseFormWatch<any>
}

const isPlaintiffFilled = (plaintiff: PlaintiffDto) => {
  return plaintiff.first_name && plaintiff.last_name
}

export const PlaintiffsForm: React.FC<PlaintiffsFormProps> = (): JSX.Element => {
  const { watch, setValue, requestId } = useRequestContext() as ContextValues
  const watchedPlaintiffs = watch("plaintiffs")
  const plaintiffs = useMemo(() => watchedPlaintiffs ?? [], [watchedPlaintiffs]) as PlaintiffDto[]

  const canAddNewPlaintiff = plaintiffs.every(isPlaintiffFilled)

  const addEmptyPlaintiff = useCallback(() => {
    const newPlaintiffs: PlaintiffDto[] = [...plaintiffs, EMPTY_PLAINTIFF]
    setValue("plaintiffs", newPlaintiffs, { shouldDirty: true })
  }, [plaintiffs, setValue])

  const removePlaintiffByIndex = (index: number): void => {
    plaintiffs.splice(index, 1)
    setValue("plaintiffs", plaintiffs)
    updatePlaintiffs(plaintiffs)
  }

  const updatePlaintiffs = (plaintiffs: PlaintiffDto[]) => {
    updateRequestMutation.mutate({
      requestId,
      data: {
        plaintiffs: plaintiffs,
      },
    })
  }

  const updateRequestMutation = useMutation(updateRequest)

  return (
    <Box>
      <Box mb={1}>
        {plaintiffs.map((_: PlaintiffDto, index: number) => {
          return (
            <PlaintiffForm
              index={index}
              key={index}
              remove={removePlaintiffByIndex}
              updatePlaintiffs={updatePlaintiffs}
            />
          )
        })}
      </Box>
      <AddPlaintiffButton disabled={!canAddNewPlaintiff} onClick={addEmptyPlaintiff} />
    </Box>
  )
}

const AddPlaintiffButton = ({ disabled, onClick }: { disabled: boolean; onClick: () => void }) => {
  return (
    <TextButton disabled={disabled} onClick={onClick}>
      + Add Plaintiff
    </TextButton>
  )
}

interface FormProps {
  index: number
  remove: (index: number) => void
  updatePlaintiffs: (plaintiffs: PlaintiffDto[]) => void
}

const PRIMARY = "Primary"

const PlaintiffForm: React.FC<FormProps> = ({ index, remove, updatePlaintiffs }): JSX.Element => {
  const { control, errors, getValues, setValue } = useRequestContext() as ContextValues

  const plaintiffOrderLabel: string = index === 0 ? PRIMARY : ordinalSuffixOf(index + 1)

  const handleOnIsMinorChange = (e: { currentTarget: any }) => {
    const currentTarget = e.currentTarget
    const { name, checked } = currentTarget

    setValue(name, checked)
    updatePlaintiffs(getValues("plaintiffs"))
  }

  const handleRemovePlaintiff = (index: number) => {
    remove(index)
  }

  return (
    <FormRow>
      <InputField
        control={control}
        name={`plaintiffs.${index}.first_name`}
        data-test={`plaintiff-first-name-${index}`}
        type="text"
        label={`${plaintiffOrderLabel} Plaintiff First Name`}
        variant="outlined"
        rules={{
          required: "This is required",
          maxLength: { value: 50, message: "Ensure this field has no more than 50 characters." },
        }}
        showCharacterCounter={true}
        errors={errors}
      />
      <InputField
        control={control}
        name={`plaintiffs.${index}.last_name`}
        data-test={`plaintiff-last-name-${index}`}
        type="text"
        label={`${plaintiffOrderLabel} Plaintiff Last Name`}
        variant="outlined"
        errors={errors}
        rules={{
          required: "This is required",
          maxLength: { value: 50, message: "Ensure this field has no more than 50 characters." },
        }}
        showCharacterCounter={true}
      />
      <SelectInput
        control={control}
        name={`plaintiffs.${index}.role`}
        label="Role"
        options={PLAINTIFF_ROLE_SELECT_OPTIONS}
      />
      <SelectInput
        control={control}
        name={`plaintiffs.${index}.pronoun`}
        label="Pronoun"
        options={PRONOUN_SELECT_OPTIONS}
      />
      <CheckboxInput
        control={control}
        name={`plaintiffs.${index}.is_minor`}
        label="This plaintiff is a minor"
        onChange={handleOnIsMinorChange}
      />
      {index !== 0 && (
        <Controller
          name={`plaintiffs.${index}.pk`}
          control={control}
          render={() => {
            return (
              <CenteredIconButton
                onClick={() => {
                  handleRemovePlaintiff(index)
                }}
              >
                <RemoveIcon />
              </CenteredIconButton>
            )
          }}
        />
      )}
    </FormRow>
  )
}
