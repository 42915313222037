import { variablesService } from "api/services/variables"
import { Variable } from "common/types/variables"
import React, { useContext, useEffect, useMemo, useRef } from "react"
import { useIsFetching, useIsMutating, useQuery, useQueryClient } from "react-query"
import { queryKeys } from "react-query/constants"
import { CaseVariablesContext, CaseVariablesContextType } from "./context"

interface CaseVariablesProviderProps {
  caseId: PrimaryKey
  children: React.ReactChildren
}

export function CaseVariablesProvider({ caseId, children }: CaseVariablesProviderProps): JSX.Element {
  const { variables: defaultVariables } = useContext(CaseVariablesContext)
  const queryClient = useQueryClient()
  const isFetching = useIsFetching({ queryKey: queryKeys.steps })
  const isMutating = useIsMutating({ mutationKey: queryKeys.steps })
  const hasActiveRequests = isFetching || isMutating
  const previousCaseVariables = useRef<Variable[]>()

  const { data: caseVariables } = useQuery(
    [queryKeys.caseVariables, caseId],
    () => variablesService.getCaseVariables({ caseId }),
    {
      meta: { disableLoader: true },
    }
  )

  const value: CaseVariablesContextType = useMemo(
    () => ({
      variables: caseVariables ?? defaultVariables,
    }),
    [defaultVariables, caseVariables]
  )

  useEffect(() => {
    if (previousCaseVariables.current && !hasActiveRequests) {
      queryClient.invalidateQueries(queryKeys.caseVariables)
    }

    if (!previousCaseVariables.current && caseVariables && !hasActiveRequests) {
      previousCaseVariables.current = caseVariables
    }
  }, [queryClient, previousCaseVariables, caseVariables, hasActiveRequests])

  return <CaseVariablesContext.Provider value={value}>{children}</CaseVariablesContext.Provider>
}
