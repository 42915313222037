import React from "react"
import { Chip } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"

import { useFeatures, FEATURES } from "hooks/useFeatures"

interface FileTagProps {
  label: string
}

const BoxChip = styled(Chip)(({ theme }) => ({
  borderRadius: "5px",
  fontStyle: "italic",
  fontSize: "10px",
  lineHeight: 1.4,
  letterSpacing: "-0.31px",
  color: theme.palette.common.black,
  border: `0.7px solid ${theme.palette.common.black}`,
}))

const FileTag = ({ label }: FileTagProps): JSX.Element => {
  const { isFeatureEnabled } = useFeatures()
  const isProviderAutofillEnabled = isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL)

  if (!label || !isProviderAutofillEnabled) {
    return <></>
  }

  return <BoxChip label={label} variant="outlined" size="small" />
}

export { FileTag as default }
