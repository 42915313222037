import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Typography, IconButton } from "@material-ui/core"
import { Search as SearchIcon, Visibility as VisibilityIcon, Delete as DeleteIcon } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"

import { useMutation, useQueryClient } from "react-query"
import { queryKeys } from "../../react-query/constants"
import { deleteCase } from "../../api"

import { formatDate } from "../../utils"

const NOT_APPLICABLE = "N/A"

const useStyles = makeStyles(theme => ({
  hideHyperlinks: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    "&:hover": {
      fontWeight: 500,
    },
  },
}))

const CaseNameCell = ({ record }) => {
  const classes = useStyles()
  return (
    <RouterLink className={classes.hideHyperlinks} to={"/demands/" + record.pk}>
      {record.name}
    </RouterLink>
  )
}

const FirmCell = ({ record }) => {
  return record?.firm?.name || NOT_APPLICABLE
}

const CreatedByCell = ({ record }) => {
  return record?.creator ? `${record.creator?.first_name} ${record.creator?.last_name}` : NOT_APPLICABLE
}

const ActionsCell = ({ record }) => {
  const queryClient = useQueryClient()
  const { mutate: handleDelete } = useMutation(deleteCase, {
    onSuccess: () => queryClient.invalidateQueries(queryKeys.cases),
  })

  return (
    <>
      <IconButton component={RouterLink} to={"/demands/" + record.pk}>
        <VisibilityIcon color="primary" />
      </IconButton>
      <IconButton component={RouterLink} to={`/demands/${record.pk}/search`}>
        <SearchIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          if (confirm("Delete the case?")) {
            handleDelete(record.pk)
          }
        }}
      >
        <DeleteIcon />
      </IconButton>
    </>
  )
}

export const columns = [
  {
    id: "case_name",
    text: "Case Name",
    align: "left",
    cellComponent: CaseNameCell,
  },
  {
    id: "firm",
    text: "Firm",
    align: "left",
    cellComponent: FirmCell,
  },
  {
    id: "created_by",
    text: "Created By",
    align: "left",
    cellComponent: CreatedByCell,
  },
  {
    id: "state",
    text: "State",
    align: "left",
    cellComponent: ({ record }) => {
      return record.state ?? NOT_APPLICABLE
    },
  },
  {
    id: "county",
    text: "County",
    align: "left",
    cellComponent: ({ record }) => {
      return record.county ?? NOT_APPLICABLE
    },
  },
  {
    id: "created_at",
    text: "Date Created",
    sortable: true,
    cellComponent: ({ record }) => {
      return <Typography noWrap={true}>{formatDate(record.created_at) ?? NOT_APPLICABLE}</Typography>
    },
  },
  {
    id: "actions",
    text: "Actions",
    align: "right",
    cellComponent: ActionsCell,
  },
]
