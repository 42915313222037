import { BASIC_PLUS, STANDARD } from "requests/constants"

enum STEP_NAME {
  CLIENT_INFORMATION = "Client information",
  UPLOAD_DOCUMENTS = "Upload documents",
  CASE_DETAILS = "Case details (optional)",
  ADDITIONAL_INFORMATION = "Additional information (optional)",
  REVIEW_AND_SUBMIT = "Review & Submit",
}

export const STEPS_BY_DEMAND_TYPE = {
  [STANDARD]: [
    STEP_NAME.CLIENT_INFORMATION,
    STEP_NAME.UPLOAD_DOCUMENTS,
    STEP_NAME.CASE_DETAILS,
    STEP_NAME.ADDITIONAL_INFORMATION,
    STEP_NAME.REVIEW_AND_SUBMIT,
  ],
  [BASIC_PLUS]: [
    STEP_NAME.CLIENT_INFORMATION,
    STEP_NAME.UPLOAD_DOCUMENTS,
    STEP_NAME.CASE_DETAILS,
    STEP_NAME.REVIEW_AND_SUBMIT,
  ],
}
