import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Checkbox, IconButton, Menu, MenuItem, Select, Typography } from "@material-ui/core"
import { Delete as DeleteIcon } from "@material-ui/icons"
import { Alert } from "@material-ui/lab"

import Dropzone from "react-dropzone"
import { CsvIcon, DocIcon, JpgIcon, Mp4Icon, PdfIcon, PngIcon, ZipIcon, XlsIcon } from "./assets"
import { FILE_TYPES } from "./constants"

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
  },
  fullWidth: {
    gridColumn: "1 / 3",
  },
  dropzone: {
    textAlign: "center",
    padding: theme.spacing(4),
    border: `4px dashed ${theme.palette.divider}`,
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    cursor: "pointer",
    marginBottom: theme.spacing(3),
  },
  selectedFileWrapper: {
    textAlign: "center",
  },
  filesToUpload: {
    color: "#000",
    fontWeight: "bold",
  },
  bulkActions: {
    display: "flex",
  },
  fileToUpload: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "10% 2fr 1fr 1fr",
  },
  filesToUploadName: {
    lineHeight: "48px",
    textAlign: "left",
  },
  bulkHeaders: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "10% 2fr 1fr 1fr",
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(3),
  },
  iconList: {
    marginBottom: theme.spacing(3),
  },
  icon: {
    width: "60px",
    height: "60px",
    padding: theme.spacing(1),
  },
  helperText: {
    fontWeight: theme.typography.fontWeightLight,
  },
  errorMessage: {
    marginBottom: theme.spacing(2),
  },
  moreText: {
    marginTop: theme.spacing(2),
  },
}))

const SELECTED_FILE_PREFIX = "bulk-item-"
const selectedFileKey = index => `${SELECTED_FILE_PREFIX}${index}`

export default function DragDropFileUploader({
  files = [],
  fileOptions,
  acceptedTypes,
  defaultType = "other",
  onChange,
  onAdd,
  onSave,
  uploadError = false,
}) {
  const classes = useStyles()

  const [selectedFiles, setSelectedFiles] = useState({})
  const [uploadingFiles, setUploadingFiles] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  const allChecked = files.every((file, index) => selectedFiles[selectedFileKey(index)])
  const showCsv = acceptedTypes.includes(FILE_TYPES.CSV)
  const showDoc = acceptedTypes.includes(FILE_TYPES.WORD)
  const showJpg = acceptedTypes.includes(FILE_TYPES.IMAGE) || acceptedTypes.includes("images/jpeg")
  const showMp4 = acceptedTypes.includes(FILE_TYPES.VIDEO) || acceptedTypes.includes("video/mp4")
  const showPdf = acceptedTypes.includes(FILE_TYPES.PDF)
  const showPng = acceptedTypes.includes(FILE_TYPES.IMAGE) || acceptedTypes.includes("images/png")
  const showZip = acceptedTypes.includes(FILE_TYPES.ZIP)
  const showXls = acceptedTypes.includes(FILE_TYPES.SPREADSHEET)

  const open = Boolean(anchorEl)

  const defaultSelectedFiles = files => {
    const selected = {}
    for (const [i] of files.entries()) {
      selected[selectedFileKey(i)] = false
    }
    setSelectedFiles(selected)
  }

  const handleDrop = droppedFiles => {
    if (droppedFiles.length > 0) {
      droppedFiles = droppedFiles.map(file => {
        return { file, fileType: defaultType }
      })
      defaultSelectedFiles(droppedFiles)

      onAdd(droppedFiles)
    }
  }

  const handleSingleTypeChange = (event, i) => {
    const newFiles = [...files]
    newFiles[i].fileType = event.target.value
    onChange(newFiles)
  }

  const handleSingleCheckedChange = event => {
    setSelectedFiles({ ...selectedFiles, [event.target.name]: event.target.checked })
  }

  const handleSingleRemoveFile = (event, deleteIndex) => {
    const updatedFiles = []
    const updatedSelected = {}
    let index = 0
    for (const [i, v] of files.entries()) {
      if (i != deleteIndex) {
        updatedFiles.push(v)
        updatedSelected[selectedFileKey(index)] = selectedFiles[selectedFileKey(i)]
        index++
      }
    }
    onChange(updatedFiles)
    setSelectedFiles(updatedSelected)
  }

  const handleBulkCheckAll = event => {
    // converts files array into obj with indexes as keys
    // e.g, if we have 2 files --> { bulk-item-0: event.target.checked, bulk-item-1: event.target.checked }
    setSelectedFiles(
      Object.fromEntries(files.map((file, index) => [selectedFileKey(index), event.target.checked]))
    )
  }

  const handleApplyTag = event => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }

  const handleApplyTagClose = (event, fileType) => {
    setAnchorEl(null)
    if (fileType) {
      onChange(
        files.map((file, index) => {
          if (selectedFiles[selectedFileKey(index)]) {
            return { ...file, fileType }
          }
          return file
        })
      )
    }
    event.stopPropagation()
  }

  const handleBulkRemove = event => {
    const newList = files.filter((element, index) => {
      return !selectedFiles[selectedFileKey(index)]
    })
    onChange(newList)
    defaultSelectedFiles(newList)
    event.stopPropagation()
  }

  const selectedCount = () => {
    return Object.values(selectedFiles).reduce(function (previous, selected) {
      return previous + (selected === true ? 1 : 0)
    }, 0)
  }
  const handleUploadFiles = async () => {
    setSelectedFiles({})
    setUploadingFiles(true)
    try {
      await onSave()
    } finally {
      setUploadingFiles(false)
    }
  }

  return (
    <div className={classes.fullWidth} data-test="drag-and-drop-file-uploader">
      <Dropzone onDrop={handleDrop} multiple={true} accept={acceptedTypes}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: classes.dropzone })}>
              <input {...getInputProps()} />
              {files.length > 0 && (
                <div className={classes.bulkHeaders}>
                  <div>
                    <Checkbox
                      checked={allChecked}
                      onChange={handleBulkCheckAll}
                      onClick={e => e.stopPropagation()}
                    />
                  </div>
                  <div />
                  <div hidden={selectedCount() == 0}>
                    <Button
                      variant="outlined"
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleApplyTag}
                    >
                      Apply tag to {selectedCount()} files
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {Object.keys(fileOptions).map(key => (
                        <MenuItem
                          key={key}
                          value={key}
                          onClick={event => handleApplyTagClose(event, key)}
                          onBlur={event => handleApplyTagClose(event, undefined)}
                        >
                          {fileOptions[key]}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                  <div hidden={selectedCount() == 0}>
                    <Button id="basic-button" onClick={handleBulkRemove} variant="outlined">
                      Remove {selectedCount()} files
                    </Button>
                  </div>
                </div>
              )}
              <div className={classes.filesToUpload}>
                {files.length > 0 ? (
                  <>
                    {files.map(({ file, fileType }, index) => (
                      <div
                        key={`${file.name}-${index}`}
                        className={classes.fileToUpload}
                        data-test="file-to-upload"
                        onClick={e => e.stopPropagation()}
                      >
                        <div>
                          <Checkbox
                            name={selectedFileKey(index)}
                            checked={selectedFiles[selectedFileKey(index)] || false}
                            onChange={handleSingleCheckedChange}
                            className={classes.filesToUploadCheck}
                          />
                        </div>
                        <div className={classes.filesToUploadName}>{file.name}</div>
                        <Select
                          data-test="file-type-select"
                          labelId="file-type-selector"
                          defaultValue={"other"}
                          value={
                            // Select only option when there is only one available
                            Object.keys(fileOptions).length === 1 ? Object.keys(fileOptions)[0] : fileType
                          }
                          onChange={event => handleSingleTypeChange(event, index)}
                          onClick={e => e.stopPropagation()}
                          className={classes.filesToUploadType}
                        >
                          {Object.keys(fileOptions).map(key => (
                            <MenuItem key={key} value={key}>
                              {fileOptions[key]}
                            </MenuItem>
                          ))}
                        </Select>
                        <IconButton
                          aria-label="delete"
                          onClick={event => handleSingleRemoveFile(event, index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    ))}
                    <Typography variant="h6" component="p" className={classes.moreText}>
                      Drop or click to add more files.
                    </Typography>
                  </>
                ) : (
                  <>
                    <div className={classes.iconList}>
                      {showCsv && <CsvIcon className={classes.icon} />}
                      {showDoc && <DocIcon className={classes.icon} />}
                      {showXls && <XlsIcon className={classes.icon} />}
                      {showJpg && <JpgIcon className={classes.icon} />}
                      {showMp4 && <Mp4Icon className={classes.icon} />}
                      {showPdf && <PdfIcon className={classes.icon} />}
                      {showPng && <PngIcon className={classes.icon} />}
                      {showZip && <ZipIcon className={classes.icon} />}
                    </div>
                    <Typography variant="h6" component="p">
                      Drop files or Click here to browse files.
                    </Typography>
                  </>
                )}
              </div>
            </div>
            {uploadError && (
              <div className={classes.errorMessage}>
                <Alert variant="outlined" severity="error">
                  There was an error uploading your files. Make sure no files are corrupt (i.e. you can open
                  them properly) and all files are one of the accepted types: {acceptedTypes}
                </Alert>
              </div>
            )}
            <aside className={classes.selectedFileWrapper}>
              <Button
                variant="contained"
                color="secondary"
                disabled={files.length === 0 || uploadingFiles}
                onClick={handleUploadFiles}
                data-test="save-button-for-upload"
              >
                Save
              </Button>
            </aside>
          </section>
        )}
      </Dropzone>
    </div>
  )
}
