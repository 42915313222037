import React, { useEffect, useState, useRef } from "react"
import { IconButton, ImageList, ImageListItem, ImageListItemBar, ListSubheader } from "@material-ui/core"
import { DeleteOutlined as DeleteIcon } from "@material-ui/icons"

export function ImageGallery({ caseId, images, onFileDelete, downloadImage }) {
  const [imageBlobs, setImageBlobs] = useState([])
  const [loading, setIsLoading] = useState(false)
  // create a callbackRef for downloadImage since it's a function
  const downloadImageRef = useRef(downloadImage)
  downloadImage.current = downloadImage

  useEffect(() => {
    async function fetchImageBlobs() {
      setIsLoading(true)
      const imageBlobPromises = images.map(async image => await downloadImageRef.current(image))
      const imageBlobData = await Promise.all(imageBlobPromises)
      const imagesWithObjectURLs = images.map((image, index) => {
        return { ...image, objectUrl: URL.createObjectURL(imageBlobData[index]) }
      })
      setImageBlobs(imagesWithObjectURLs)
      setIsLoading(false)
    }

    fetchImageBlobs()
    return () => images.forEach(image => URL.revokeObjectURL(image.objectUrl))
  }, [images, caseId])

  return (
    <>
      {!loading && (
        <ImageList rowHeight={180}>
          <ImageListItem key="Subheader" cols={2} style={{ height: "auto" }}>
            <ListSubheader component="div">Supporting images</ListSubheader>
          </ImageListItem>
          {imageBlobs.map(image => (
            <ImageListItem key={image.pk}>
              <img src={image.objectUrl} alt={image.name} />
              <ImageListItemBar
                title={image.name}
                actionIcon={
                  <IconButton onClick={() => onFileDelete(image.pk)}>
                    <DeleteIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </>
  )
}

export default ImageGallery
