import React from "react"
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import { styled, Theme } from "@material-ui/core/styles"
import LinkButton from "common/buttons/LinkButton"
import { TransitionGroup } from "react-transition-group"
import { omit } from "lodash"

interface DropdownValueProps {
  error?: boolean
}

export const DropdownValue = styled((props: DropdownValueProps) => (
  <span {...omit<DropdownValueProps, keyof DropdownValueProps>(props, "error")} />
))<Theme, DropdownValueProps>(({ theme, error }) => ({
  fontWeight: 600,
  textTransform: "capitalize",
  fontSize: "13px",
  color: error ? theme.palette.error.main : theme.palette.grey["A400"],
}))

export const DropdownLabel = styled(InputLabel)(({ theme }) => ({
  background: theme.palette.common.white,
  textTransform: "capitalize",
  // There is scale of 0.75 applied
  maxWidth: "125%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}))
DropdownLabel.defaultProps = {
  variant: "outlined",
  shrink: true,
}

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1, 1, 1, 0),
  minWidth: "150px",
  fontSize: "13px",

  "&:last-of-type": {
    marginRight: theme.spacing(2),
  },

  "&:last-child": {
    marginRight: 0,
  },
}))
StyledFormControl.defaultProps = {
  variant: "outlined",
  size: "small",
  margin: "none",
}

export const ResetButton = styled(LinkButton)(({ theme }) => ({
  fontSize: "13px",
  alignSelf: "start",
  height: theme.spacing(6),
}))

export const StyledMenuItem = styled(MenuItem)(() => ({
  textTransform: "capitalize",
  fontSize: "13px",
  letterSpacing: "-0.5px",
}))

export const FiltersBox = styled(TransitionGroup)({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "row",
  alignContent: "middle",
})

export const FilterGroupDivider = styled("div")(({ theme }) => ({
  width: "1px",
  height: theme.spacing(5),
  background: theme.palette.grey["A200"],
  alignSelf: "center",
  marginRight: theme.spacing(1),
}))

export const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-selectMenu": {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))
