import React, { useState } from "react"
import { Box, Collapse, IconButton } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from "@material-ui/icons"

import { BaseMissingExhibit } from "./interfaces"

import { buildPropertiesObject, getColourByImportance } from "./utils"
import { MissingDocContainer, Status } from "./styled"

const ListContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxWidth: "66%",
})

const Header = styled(Box)({
  display: "flex",
  flexDirection: "row",
  margin: "auto 0",
})

const Title = styled(Box)({
  fontWeight: 700,
  margin: "auto 0",
})

const Importance = styled(Box)((props: { color: string }) => ({
  color: props.color,
  display: "inline",
  marginLeft: "4px",
}))

const DescriptionContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  height: "inherit",
  transition: "height 1.35s ease-in-out",
  fontSize: "0.9rem",
  lineHeight: "1rem",
}))

interface Props {
  missingDoc: BaseMissingExhibit
}

const PendingMissingDocumentItem = ({ missingDoc }: Props): JSX.Element => {
  const name = missingDoc.name
  const importanceLevel = missingDoc.importance
  const description = missingDoc.description

  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const statusProperties = buildPropertiesObject("#FFFFFF", "#00B1FF", "Pending")
  const showExpandIcon = !!description

  return (
    <MissingDocContainer>
      <Header>
        <Status
          backgroundColor={statusProperties.backgroundColor}
          fontColor={statusProperties.fontColor}
          label={statusProperties.display}
        />
        <ListContainer>
          <Title>
            {name} &#124; Importance:{" "}
            <Importance color={getColourByImportance(importanceLevel)}>
              {importanceLevel.toUpperCase()}
            </Importance>
          </Title>
        </ListContainer>
        <Box display={"flex"} ml={"auto"}>
          {showExpandIcon && (
            <IconButton
              onClick={() => {
                setIsExpanded(!isExpanded)
              }}
            >
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </Box>
      </Header>

      {showExpandIcon && (
        <Collapse in={isExpanded}>
          <DescriptionContainer>{description}</DescriptionContainer>
        </Collapse>
      )}
    </MissingDocContainer>
  )
}

export { PendingMissingDocumentItem as default }
