import { Box, CircularProgress } from "@material-ui/core"
import React from "react"

export function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {`${Math.floor(props.value)}%`}
      </Box>
    </Box>
  )
}
