import React from "react"
import { DetailsContainer, RevisionSubtitle } from "./styled"
import { RevisionRequestEventDetails, RevisionRequestEventDetailsProps } from "./RevisionRequestEventDetails"

export const ActiveRevisionRequestEventDetails = (props: RevisionRequestEventDetailsProps): JSX.Element => {
  return (
    <DetailsContainer>
      <RevisionSubtitle>Documents &amp; Comments Sent</RevisionSubtitle>
      <RevisionRequestEventDetails {...props} />
    </DetailsContainer>
  )
}
