import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core"
import { makeStyles, styled } from "@material-ui/core/styles"

import { FieldValues, useForm } from "react-hook-form"
import { useHandleMessages } from "../../common/messages/useHandleMessages"

import { useMutation, useQueryClient } from "react-query"
import { calculateCredits, updateRequest } from "../../api"
import { queryKeys } from "../../react-query/constants"

import TextButton from "../../common/buttons/TextButton"
import LinkButton from "../../common/buttons/LinkButton"
import { CheckboxInput, CheckboxInputProps, InputField, InputFieldProps } from "../../common/form-components"

import { DetailedContract } from "../../common/interfaces"

import { amountInDollars } from "../../utils"
import { Lock, LockOpen } from "@material-ui/icons"
import { MinorTitleContent } from "./styled"
import { CreditBreakdown } from "./types"
import { Alert } from "@material-ui/lab"

const useStyles = makeStyles(theme => ({
  creditAmountInput: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  creditCommentsContainer: {
    width: "100%",
    maxWidth: "670px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  overridableInput: {
    marginRight: theme.spacing(1),
  },
}))

const Container = styled(Paper)(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  padding: theme.spacing(2, 2),
  marginBottom: theme.spacing(2),
}))

const HeaderRow = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "2fr 5fr 1fr",
  "& > *": {
    margin: theme.spacing("auto", 0),
    "&:last-child": {
      textAlign: "right",
      justifyContent: "right",
    },
  },
}))

const EditRow = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    display: "flex",
    justifyContent: "space-between",
  },
}))

const Key = styled(Box)(({ theme }) => ({
  fontWeight: "bold",
  margin: theme.spacing("auto", 1, "auto"),
}))

const Row = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginRight: theme.spacing(1),
  width: "100%",
}))

const MinorTitle = styled(Box)({
  fontSize: "18px",
  fontWeight: 500,
})

const OverridableInputGrid = styled(Box)(({ theme }) => ({
  display: "inline-grid",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  gridTemplateColumns: "1fr 1fr",
  columnGap: theme.spacing(12),
  rowGap: theme.spacing(4),
  paddingLeft: theme.spacing(1.5),
}))

type OverrideableInputFieldProps<T extends FieldValues> = InputFieldProps<T> & {
  overridden: boolean
  onToggle: () => unknown
  autoValue: string
}

function OverrideableInputField<T extends FieldValues>({
  overridden,
  onToggle,
  autoValue,
  disabled,
  ...inputFieldProps
}: OverrideableInputFieldProps<T>): JSX.Element {
  const classes = useStyles()
  return (
    <Box>
      {overridden ? (
        <InputField className={classes.overridableInput} disabled={disabled} {...inputFieldProps} />
      ) : (
        <TextField
          className={classes.overridableInput}
          label={inputFieldProps.label}
          variant="outlined"
          size="small"
          disabled
          value={autoValue}
        />
      )}

      <Button disabled={disabled} startIcon={overridden ? <Lock /> : <LockOpen />} onClick={onToggle}>
        {overridden ? "Automatic" : "Override"}
      </Button>
    </Box>
  )
}

interface OverrideableCheckboxProps<TFields extends FieldValues> extends CheckboxInputProps<TFields> {
  overridden: boolean
  onToggle: () => unknown
  autoValue: boolean
  disabled: boolean
  label: string
}

function OverrideableCheckbox<TFields extends FieldValues>({
  overridden,
  onToggle,
  autoValue,
  disabled,
  label,
  ...checkboxInputProps
}: OverrideableCheckboxProps<TFields>): JSX.Element {
  const classes = useStyles()
  return (
    <Box>
      {overridden ? (
        <CheckboxInput
          className={classes.overridableInput}
          disabled={disabled}
          label={label}
          {...checkboxInputProps}
        />
      ) : (
        <FormControlLabel
          className={classes.overridableInput}
          disabled
          control={<Checkbox checked={autoValue} />}
          label={
            <Typography variant="caption" display="block">
              {label}
            </Typography>
          }
        />
      )}

      <Button disabled={disabled} startIcon={overridden ? <Lock /> : <LockOpen />} onClick={onToggle}>
        {overridden ? "Automatic" : "Override"}
      </Button>
    </Box>
  )
}

interface CalculatorFieldProps {
  overageFee: number
  overageCredits: number
}
const CalculatorField: React.FC<CalculatorFieldProps> = ({ overageFee, overageCredits, children }) => (
  <Box>
    <Box mb={1}>{children}</Box>

    <Box pl={1} display="flex">
      <Typography variant="body2">
        <strong>Fee Total:</strong> {amountInDollars(overageFee)} (+{overageCredits} credits)
      </Typography>
    </Box>
  </Box>
)

const getValue = (priority: unknown, fallback: unknown): unknown => {
  if (priority !== null && priority !== undefined && priority !== "") return priority

  return fallback
}

interface CreditManagementFormProps {
  requestId: number
  contract: DetailedContract
  autoCreditAmount: Nullable<number>
  manualCreditAmount: Nullable<number>
  creditComments: Nullable<string>
  editable: boolean
  filesPageCount: number
  plaintiffCount: number
  verdictSearch: boolean
  revisionFilesPageCount: number
  manualFilesPageCount: Nullable<number>
  manualPlaintiffCount: Nullable<number>
  manualVerdictSearch: Nullable<boolean>
  manualRevisionFilesPageCount: Nullable<number>
}

const CreditManagementForm: React.FC<CreditManagementFormProps> = ({
  requestId,
  contract,
  autoCreditAmount,
  manualCreditAmount,
  creditComments,
  editable,
  filesPageCount,
  plaintiffCount,
  verdictSearch,
  revisionFilesPageCount,
  manualFilesPageCount,
  manualPlaintiffCount,
  manualVerdictSearch,
  manualRevisionFilesPageCount,
}): JSX.Element => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const [isEditing, setIsEditing] = useState(false)
  const [creditBreakdown, setCreditBreakdown] = useState<Partial<CreditBreakdown>>({})

  const { showMessage } = useHandleMessages()

  const updateRequestMutation = useMutation(updateRequest, {
    onSuccess: () => {
      // Reloads the request with new changes
      // queryClient.setQueryData([queryKeys.request, requestId], data)

      // TODO: The setQueryData does not appear to be working right now, but this invalidation leads to a super long loada
      queryClient.invalidateQueries(queryKeys.request)

      setIsEditing(false)
      showMessage({
        type: "success",
        message: "The request's credits have been updated",
      })
    },
    onError: () => {
      showMessage({
        type: "error",
        message: "There was an error updating the request's credits",
      })
    },
  })

  const INITIAL_FORM_STATE = {
    manual_credit_amount: manualCreditAmount !== null ? String(manualCreditAmount) : manualCreditAmount,
    credit_comments: creditComments ?? "",
    manual_files_page_count:
      manualFilesPageCount !== null ? String(manualFilesPageCount) : manualFilesPageCount,
    manual_plaintiff_count:
      manualPlaintiffCount !== null ? String(manualPlaintiffCount) : manualPlaintiffCount,
    manual_verdict_search: manualVerdictSearch,
    manual_revision_files_page_count:
      manualRevisionFilesPageCount !== null
        ? String(manualRevisionFilesPageCount)
        : manualRevisionFilesPageCount,
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
  } = useForm<{
    manual_credit_amount: Nullable<string>
    credit_comments: string
    manual_files_page_count: Nullable<string>
    manual_plaintiff_count: Nullable<string>
    manual_verdict_search: Nullable<boolean>
    manual_revision_files_page_count: Nullable<string>
  }>({
    defaultValues: INITIAL_FORM_STATE,
  })

  const handleReset = () => {
    reset()
  }

  const handleOnSave = handleSubmit(async data => {
    updateRequestMutation.mutate({ requestId, data })
  })

  const handleTogglePageCount = () => {
    if (getValues("manual_files_page_count") === null) {
      setValue("manual_files_page_count", "")
      return
    }
    setValue("manual_files_page_count", null)
  }

  const handleTogglePlaintiffCount = () => {
    if (getValues("manual_plaintiff_count") === null) {
      setValue("manual_plaintiff_count", "")
      return
    }
    setValue("manual_plaintiff_count", null)
  }

  const handleToggleVerdictSearch = () => {
    if (getValues("manual_verdict_search") === null) {
      setValue("manual_verdict_search", verdictSearch)
      return
    }
    setValue("manual_verdict_search", null)
  }

  const handleToggleRevisionPageCount = () => {
    if (getValues("manual_revision_files_page_count") === null) {
      setValue("manual_revision_files_page_count", "")
      return
    }
    setValue("manual_revision_files_page_count", null)
  }

  const handleToggleManualCreditAmount = () => {
    if (getValues("manual_credit_amount") === null) {
      setValue("manual_credit_amount", "")
      return
    }
    setValue("manual_credit_amount", null)
  }

  const watchedManualCreditAmount = watch("manual_credit_amount")
  const isAutoCreditAmount = watchedManualCreditAmount === null
  const watchedManualFilesPageCount = watch("manual_files_page_count")
  const watchedManualPlaintiffCount = watch("manual_plaintiff_count")
  const watchedManualVerdictSearch = watch("manual_verdict_search")
  const watchedManualRevisionFilesPageCount = watch("manual_revision_files_page_count")
  // use to re-fetch credits when page count switches back to auto from manual
  const isAutoPageCount = watchedManualFilesPageCount === null
  const isAutoPlaintiffCount = watchedManualPlaintiffCount === null
  const isAutoVerdictSearch = watchedManualVerdictSearch === null
  const isAutoRevisionPageCount = watchedManualRevisionFilesPageCount === null

  // fetch initial credits using automatic values
  useEffect(() => {
    calculateCredits({
      requestId,
      data: {
        page_count: filesPageCount,
        number_of_plaintiffs: plaintiffCount,
        verdict_search: verdictSearch,
        revision_page_count: revisionFilesPageCount,
      },
    }).then(
      data => setCreditBreakdown(data),
      () =>
        showMessage({
          type: "error",
          message:
            "Error while calculating credits. Please try again shortly and file a bug if your issue continues.",
        })
    )
    // we only want to run the initial fetch once, hence empty dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // fetch credits each time a manual value changes
  useEffect(() => {
    calculateCredits({
      requestId,
      data: {
        page_count: getValue(watchedManualFilesPageCount, filesPageCount),
        number_of_plaintiffs: getValue(watchedManualPlaintiffCount, plaintiffCount),
        verdict_search: getValue(watchedManualVerdictSearch, verdictSearch),
        revision_page_count: getValue(watchedManualRevisionFilesPageCount, revisionFilesPageCount),
      },
    }).then(
      data => {
        setCreditBreakdown(data)
      },
      () =>
        showMessage({
          type: "error",
          message:
            "Error while calculating credits. Please try again shortly and file a bug if your issue continues.",
        })
    )
  }, [
    watchedManualFilesPageCount,
    watchedManualPlaintiffCount,
    watchedManualVerdictSearch,
    watchedManualRevisionFilesPageCount,
    filesPageCount,
    plaintiffCount,
    verdictSearch,
    revisionFilesPageCount,
    showMessage,
    requestId,
  ])

  return (
    <Container data-test="request-credit-container">
      {isEditing ? (
        <Box>
          <EditRow>
            <Box className={classes.creditAmountInput}>
              <OverrideableInputField
                type="number"
                label="Credit Amount"
                control={control}
                name="manual_credit_amount"
                variant="outlined"
                size="small"
                errors={errors}
                autoFocus={getValues("manual_credit_amount") === ""}
                helperText={`Auto credits: ${autoCreditAmount}`}
                overridden={watchedManualCreditAmount !== null}
                autoValue={String(autoCreditAmount)}
                onToggle={handleToggleManualCreditAmount}
              />
            </Box>
            <Box className={classes.creditCommentsContainer}>
              <InputField
                label="Credit Comments"
                control={control}
                name="credit_comments"
                variant="outlined"
                size="small"
                errors={errors}
                multiline={true}
                fullWidth
              />
            </Box>
            <Box>
              <TextButton onClick={handleOnSave} data-test="save-credits-button">
                Save
              </TextButton>
              <TextButton
                textColor="grey"
                onClick={() => {
                  handleReset()
                  setIsEditing(false)
                }}
              >
                Cancel
              </TextButton>
            </Box>
          </EditRow>
        </Box>
      ) : (
        <HeaderRow>
          <Row data-test="credit-amount">
            <Key>Credit Amount:</Key>
            <Box>{manualCreditAmount ?? autoCreditAmount}</Box>
          </Row>
          <Row data-test="credit-comment">
            <Key>Explanation:</Key>
            <Box>{creditComments ? creditComments : "None"}</Box>
          </Row>

          {editable && (
            <LinkButton onClick={() => setIsEditing(true)} data-test="edit-request-credit-button">
              Edit
            </LinkButton>
          )}
        </HeaderRow>
      )}

      <Collapse in={isEditing}>
        <Box>
          <Divider />
          <Box mt={2} pl={1}>
            <Typography variant="h6">
              <strong>Credit Calculator</strong>
            </Typography>
            {!isAutoCreditAmount && (
              <Box display="inline-flex">
                <Alert icon={false} color="info">
                  Set credit amount to automatic to override individual fields.
                </Alert>
              </Box>
            )}
          </Box>
          <OverridableInputGrid>
            <CalculatorField
              overageFee={creditBreakdown.files_overage_fee ?? 0}
              overageCredits={creditBreakdown.files_overage_credits ?? 0}
            >
              <OverrideableInputField
                type="number"
                label="Page Count"
                control={control}
                name="manual_files_page_count"
                variant="outlined"
                size="small"
                errors={errors}
                autoFocus
                helperText={`Auto count: ${filesPageCount}`}
                overridden={!isAutoPageCount}
                autoValue={String(filesPageCount)}
                onToggle={handleTogglePageCount}
                disabled={!isAutoCreditAmount}
              />
            </CalculatorField>
            <CalculatorField
              overageFee={creditBreakdown.plaintiff_overage_fee ?? 0}
              overageCredits={creditBreakdown.plaintiff_overage_credits ?? 0}
            >
              <OverrideableInputField
                type="number"
                label="Number of Plaintiffs"
                control={control}
                name="manual_plaintiff_count"
                variant="outlined"
                size="small"
                errors={errors}
                autoFocus
                helperText={`Auto count: ${plaintiffCount}`}
                overridden={!isAutoPlaintiffCount}
                autoValue={String(plaintiffCount)}
                onToggle={handleTogglePlaintiffCount}
                disabled={!isAutoCreditAmount}
              />
            </CalculatorField>
            <CalculatorField
              overageFee={creditBreakdown.verdict_overage_fee ?? 0}
              overageCredits={creditBreakdown.verdict_overage_credits ?? 0}
            >
              <OverrideableCheckbox
                label="Include Verdicts"
                control={control}
                name="manual_verdict_search"
                errors={errors}
                overridden={!isAutoVerdictSearch}
                autoValue={verdictSearch}
                onToggle={handleToggleVerdictSearch}
                disabled={!isAutoCreditAmount}
              />
            </CalculatorField>
            <CalculatorField
              overageFee={creditBreakdown.revision_files_overage_fee ?? 0}
              overageCredits={creditBreakdown.revision_files_overage_credits ?? 0}
            >
              <OverrideableInputField
                type="number"
                label="Revision Page Count"
                control={control}
                name="manual_revision_files_page_count"
                variant="outlined"
                size="small"
                errors={errors}
                autoFocus
                helperText={`Auto count: ${revisionFilesPageCount}`}
                overridden={!isAutoRevisionPageCount}
                autoValue={String(revisionFilesPageCount)}
                onToggle={handleToggleRevisionPageCount}
                disabled={!isAutoCreditAmount}
              />
            </CalculatorField>
          </OverridableInputGrid>
          <Divider />
          <Box mt={2} pl={1}>
            <Typography variant="h6">
              <strong>Contract Details</strong>
            </Typography>
          </Box>
          <Box mt={1} display="grid" gridTemplateColumns={"2fr 8fr"} pl={1.5}>
            <Box>
              <Box mb={2}>
                <MinorTitle>{amountInDollars(contract.cost_per_credit)}</MinorTitle>
                <MinorTitleContent>Price Per Case</MinorTitleContent>
              </Box>
              <Box mb={2}>
                <MinorTitle>
                  {contract.monthly_credits - contract.current_month_credits_used} +{" "}
                  {contract.rollover_credits} Rollover
                </MinorTitle>
                <MinorTitleContent>Current Available Credits</MinorTitleContent>
              </Box>
            </Box>
            <Box lineHeight={2}>
              <Row>
                <Key>Medical Record Page Limit: </Key>
                <Box>{contract.medical_pages_cap}</Box>
              </Row>
              <Row>
                <Key>Overage Pages: </Key>
                <Box>{contract.medical_pages_overage}</Box>
              </Row>
              <Row>
                <Key>Overage Fees: </Key>
                <Box>{amountInDollars(contract.medical_pages_overage_fee)}</Box>
              </Row>
              <Row>
                <Key>Verdict Fees: </Key>
                <Box>{amountInDollars(contract.verdict_fee)}</Box>
              </Row>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Container>
  )
}

export { CreditManagementForm as default }
