import styled from "@emotion/styled"
import { Box, IconButton, Paper, TableCell, TableRow, Typography } from "@material-ui/core"
import LinkButton from "common/buttons/LinkButton"
import ExcerptMoreButtonComponent from "./ExcerptMoreButton"

export const ExcerptMoreButton = styled(ExcerptMoreButtonComponent)({
  visibility: "hidden",
})

export const AnnotationFileIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
}))

export const Text = styled(Typography)(() => ({
  fontSize: "12px",
}))

export const BoldText = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "bold",
}))

export const EditBoxTitle = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: "bold",
}))

export const EditPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3, 5, 2, 5),
  border: "1px solid black",
  width: "600px",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "-20px",
}))

export const RangesWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}))

export const AnnotationTableHeader = styled(TableCell)(({ theme }) => ({
  textTransform: "uppercase",
  fontWeight: "bold",
  fontSize: "12px",
  paddingTop: 0,
  paddingBottom: 0,
  "&:first-of-type": {
    paddingLeft: theme.spacing(1),
  },
  "&:last-child": {
    paddingRight: 0,
  },
}))

export const AnnotationTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: "12px",
  paddingTop: 0,
  paddingBottom: 0,
  "&:first-of-type": {
    paddingLeft: theme.spacing(1),
  },
}))

export const LastAnnotationTableCell = styled(AnnotationTableCell)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  "&:last-child": {
    paddingRight: 0,
  },
}))

export const AnnotationTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.grey[200],
  },

  "&:hover": {
    backgroundColor: theme.palette.blue.light,
    cursor: "pointer",
  },
}))

export const EmptyAnnotationTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.grey[200],
  },
}))

export const TableLinkButton = styled(LinkButton)(() => ({
  fontSize: "14px",
}))
