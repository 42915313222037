import { Variable } from "common/types/variables"
import { FEATURES, isFeatureEnabled } from "hooks/useFeatures"
import { get } from "lodash"
import { VARIABLE_CATEGORIES, VARIABLE_CATEGORY_MAP } from "./constants"
import { VariablesDataDto, VariablesDto } from "./types"

export class VariablesServiceDeserializer {
  static fromCategoryJSON(variables: VariablesDto): Variable[] {
    const variablesGroups = Object.keys(variables)
    const showLibraryVariables = isFeatureEnabled(FEATURES.TEMPLATE_VARIABLES_TAB)

    return variablesGroups.reduce<Variable[]>((result, groupName) => {
      const group = get(variables, groupName) as Record<string, VariablesDataDto>

      if (!showLibraryVariables && groupName === VARIABLE_CATEGORIES.LIBRARY_VARIABLES) return result

      if (group) {
        Object.entries(group).forEach(([name, { value, label }]) => {
          const category = get(VARIABLE_CATEGORY_MAP, groupName) as string

          if (category) {
            result.push({ category, type: "text", name, value, label })
          }
        })
      }

      return result
    }, [])
  }
}
