import React from "react"
import { TableRow, TableCell, Typography } from "@material-ui/core"
import StatusLabel from "../common/status/StatusLabel"
import { StatusStyle } from "./annotationStatusProperties"
import { KiliAsset } from "./api"
import { formatTimeSinceNow } from "utils"

interface AnnotationKiliStatusEntryInterface {
  asset: KiliAsset
  asset_status: StatusStyle
}

export default function AnnotationKiliStatusEntry({
  asset,
  asset_status,
}: AnnotationKiliStatusEntryInterface): JSX.Element {
  return (
    <>
      <TableRow>
        <TableCell>
          <Typography display="inline">{asset.externalId}</Typography>
        </TableCell>
        <TableCell>
          <StatusLabel
            status={asset.status}
            color={asset_status.color}
            text={asset_status.text}
            filled={asset_status.filled}
            showDot={true}
          />
        </TableCell>
        <TableCell>{asset.updatedAt ? formatTimeSinceNow(asset.updatedAt) : ""}</TableCell>
      </TableRow>
    </>
  )
}
