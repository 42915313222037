import React from "react"
import { Button } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"

export const AddButton = styled(Button)(({ theme }) => ({
  color: theme.palette.info.main,
  fontWeight: 700,
  "&:hover": {
    cursor: "pointer",
  },
}))

interface Props {
  disabled: boolean
  onClick: () => void
}

const AddMissingDocumentButton = ({ disabled, onClick }: Props): JSX.Element => {
  return (
    <AddButton disabled={disabled} onClick={onClick} data-test="add-missing-document">
      + ADD MISSING DOCUMENT
    </AddButton>
  )
}

export { AddMissingDocumentButton as default }
