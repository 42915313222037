import { RevisionRequestEventList } from "common/models/revision-request-event"
import { User } from "common/models/user"
import { canUserCreateRevisionRequest, canUserViewRevisions } from "requests/permissions/requestAction"
import { RequestViewDto } from "./types"

export function canUserViewRevisionsTab(
  request: RequestViewDto | undefined,
  isLoading: boolean,
  user: User,
  events: RevisionRequestEventList | undefined,
  areRevisionsLoading: boolean
): boolean {
  // User should have permission at least to view revisions
  if (!canUserViewRevisions(user.role)) return false

  // Initial loading - prevent from redirects until we have all data
  if (isLoading && !request) return true

  // In case we have no request there is nothing to show
  if (!request) return false

  // User can create revision in current state
  if (canUserCreateRevisionRequest(user.role, request)) return true

  // User can not create revision (above) and request has no revisions
  if (request.revised) return true

  // Request has revisions but events are not loaded
  if (areRevisionsLoading) return true

  // There is active (not completed or cancelled) revision event
  return events != undefined && events.hasActiveEvent
}
