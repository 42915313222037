import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Box } from "@material-ui/core"

import { useQuery } from "react-query"
import { queryKeys } from "react-query/constants"
import { getAnnotatedInjuryDetails } from "api"

import { DateToPassages } from "./types"
import { Entry, PartitionEntry } from "../types"

import { ContainerContent, InjuryDetailContainer, InjuryDetailsContainer } from "./styled"

import { useFeatures, FEATURES } from "hooks/useFeatures"

import InjuryDetailRecord from "./InjuryDetailRecord"
import LoadingState from "./LoadingState"
import ErrorState from "../ErrorState"
import LinkButton from "common/buttons/LinkButton"
import { LeftNavContext } from "demand/context"

type OpenMap = {
  [date: string]: boolean
}

interface InjuryDetailsProps {
  caseId: number
  providerId: number
  onEntryClick: (entry: PartitionEntry) => void
}

const InjuryDetails = ({ caseId, providerId, onEntryClick }: InjuryDetailsProps): JSX.Element => {
  const { leftNavTabIsExpanded } = useContext(LeftNavContext)
  const { isFeatureEnabled } = useFeatures()
  const isProviderAutofillEnabled = isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL)
  const [injuryDetails, setInjuryDetails] = useState<Nullable<DateToPassages>>(null)
  const [openMap, setOpenMap] = useState<OpenMap>({})

  const handleEntryClick = useCallback(
    (entry: Entry) => {
      onEntryClick({
        exhibitId: entry.exhibit_id,
        partitionId: entry.partition_id,
        page: entry.page,
      })
    },
    [onEntryClick]
  )

  const handleToggleOpen = useCallback(
    (date: string) => {
      const currentValue = openMap[date]
      setOpenMap({ ...openMap, [date]: !currentValue })
    },
    [openMap]
  )

  const { isFetching, isError } = useQuery<DateToPassages>(
    [queryKeys.annotatedInjuryDetails, caseId, providerId],
    async () => {
      return await getAnnotatedInjuryDetails(caseId, providerId)
    },
    {
      meta: {
        disableLoader: true,
      },
      onSuccess: response => {
        setInjuryDetails(response)
      },
      enabled: isProviderAutofillEnabled,
    }
  )

  const datesOfService = useMemo(() => {
    if (!injuryDetails) return []
    return Object.keys(injuryDetails)
  }, [injuryDetails])

  const setOpenMapToDefault = useCallback(() => {
    if (!injuryDetails) return

    const map: OpenMap = {}
    const dateList = datesOfService ?? []
    dateList.forEach((date: string, index) => {
      if (index === 0 || index === dateList.length - 1) {
        map[date] = true
      } else {
        map[date] = false
      }
    })

    setOpenMap(map)
  }, [injuryDetails, datesOfService])

  const handleExpandAll = useCallback(() => {
    if (!injuryDetails) return

    const map: OpenMap = {}
    datesOfService.forEach((date: string) => {
      map[date] = true
    })

    setOpenMap(map)
  }, [injuryDetails, datesOfService])

  useEffect(() => {
    setOpenMapToDefault()
  }, [injuryDetails, setOpenMapToDefault])

  const EXPAND_LIMIT = 2
  const openCount = datesOfService.filter(date => {
    return openMap[date]
  }).length
  const showExpand = openCount <= EXPAND_LIMIT

  if (!isProviderAutofillEnabled) return <></>

  if (isFetching) {
    return <LoadingState />
  }

  if (isError || injuryDetails === null) {
    return (
      <InjuryDetailsContainer isLeftNavOpen={leftNavTabIsExpanded}>
        <InjuryDetailContainer m="auto">
          <ErrorState message="There was an error retrieving annotated injury details" />
        </InjuryDetailContainer>
      </InjuryDetailsContainer>
    )
  }

  if (!datesOfService.length) {
    return <Box fontSize="14px">&#8212;</Box>
  }

  return (
    <InjuryDetailsContainer isLeftNavOpen={leftNavTabIsExpanded}>
      <Box display="flex" justifyContent="space-between">
        <Box fontSize="13px" fontWeight="600">
          Annotation Extracts
        </Box>
        {datesOfService.length > EXPAND_LIMIT && (
          <LinkButton
            onClick={() => {
              if (showExpand) {
                handleExpandAll()
              } else {
                setOpenMapToDefault()
              }
            }}
          >
            {showExpand ? "Expand All" : "Collapse All"}
          </LinkButton>
        )}
      </Box>
      <ContainerContent>
        {datesOfService.map((date: string) => {
          return (
            <InjuryDetailRecord
              key={date}
              date={date}
              injuryDetails={injuryDetails[date]}
              onEntryClick={handleEntryClick}
              open={openMap[date]}
              onToggleOpen={handleToggleOpen}
              annotationExtractUpdated={false} // TODO: Implement this with updates endpoint
            />
          )
        })}
      </ContainerContent>
    </InjuryDetailsContainer>
  )
}

export { InjuryDetails as default }
