import React, { useState, useEffect } from "react"
import { Controller } from "react-hook-form"
import { Slider, FormLabel, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
  sliderContainer: {
    display: "flex",
    flexDirection: "column",
  },
}))

export function SliderInput({
  name,
  control,
  label,
  onChange,
  className = null,
  defaultValue = 0,
  ...inputProps
}) {
  const classes = useStyles()
  const [value, setValue] = useState(defaultValue)
  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  const updateValue = () => {
    onChange && onChange(name, value)
  }

  useEffect(() => {
    // apply the change if provided value changes
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Box className={clsx(classes.sliderContainer, className && className)}>
            {label && <FormLabel>{label}</FormLabel>}
            <Slider
              {...inputProps}
              {...field}
              value={value}
              onChange={handleChange}
              onChangeCommitted={updateValue}
            />
          </Box>
        )
      }}
    />
  )
}
