import React, { useEffect, useState } from "react"
import { Collapse } from "@material-ui/core"
import { FutureExpenseEditor } from "./FutureExpenseEditor"
import { FutureExpenseView } from "./FutureExpenseView"

export function FutureExpense({ futureExpense, initialEdit, saveCallback, index }) {
  const [isEditing, setIsEditing] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleOnExpandedClick = () => {
    setIsExpanded(!isExpanded)
  }

  useEffect(() => {
    if (initialEdit == true) {
      handleEdit()
    }
  }, [initialEdit])

  return (
    <>
      <Collapse in={isEditing}>
        <FutureExpenseEditor
          key={futureExpense.pk}
          onSaveCallback={() => {
            saveCallback && saveCallback()
            setIsEditing(false)
            setIsExpanded(false)
          }}
          data={futureExpense}
          index={index}
        />
      </Collapse>

      <Collapse in={!isEditing}>
        <FutureExpenseView
          futureExpense={futureExpense}
          handleEdit={handleEdit}
          index={index}
          isExpanded={isExpanded}
          onExpandedClick={handleOnExpandedClick}
        />
      </Collapse>
    </>
  )
}
