import React, { useCallback, useRef } from "react"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import { MenuItemText, MenuItem, MenuItemIcon } from "./styled"

interface GroupMenuItemProps {
  item: string
  selected: boolean
  onClick: (item: string) => void
}

export const GroupMenuItem = React.forwardRef<Nullable<Element>, GroupMenuItemProps>(
  function GroupMenuItemCompoment({ item, selected, onClick }, ref) {
    const itemRef = useRef<HTMLLIElement>(null)

    const toggleRefForSelectedState = useCallback(() => {
      if (!ref || typeof ref === "function") {
        return
      }

      if (selected && itemRef.current === ref.current) {
        ref.current = null
      }

      if (!selected) {
        ref.current = itemRef.current
      }
    }, [ref, itemRef, selected])

    const handleClick = useCallback(() => {
      toggleRefForSelectedState()
      onClick(item)
    }, [onClick, item, toggleRefForSelectedState])

    return (
      <MenuItem key={item} onClick={handleClick} selected={selected} ref={itemRef} button>
        <MenuItemText>{item}</MenuItemText>
        <MenuItemIcon>
          <KeyboardArrowRightIcon />
        </MenuItemIcon>
      </MenuItem>
    )
  }
)
