import { Editor, Node } from "slate"
import { CustomEditor, SoftLineBreak } from "../CustomEditor"
import { INLINE_ELEMENTS } from "../elements"

export function isSoftLineBreak(editor: CustomEditor, node: Node): node is SoftLineBreak {
  return Editor.isInline(editor, node) && node.type === INLINE_ELEMENTS.SOFT_LINE_BREAK
}

export function isSoftLineBreakNode(node: Node): node is SoftLineBreak {
  return "type" in node && node.type === INLINE_ELEMENTS.SOFT_LINE_BREAK
}
