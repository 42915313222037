import React, { useEffect } from "react"
import { Box, Typography } from "@material-ui/core"
import { useNavigate, useSearchParams } from "react-router-dom"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { StytchLogin } from "@stytch/react"
import { OAuthProviders, OneTapPositions, Products, StyleConfig, StytchLoginConfig } from "@stytch/vanilla-js"

import { LS_LOGGED_IN_KEY } from "common/constants"

const useStyles = makeStyles(theme => ({
  loginWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1rem",
    gap: theme.spacing(3),
    margin: theme.spacing(4, 0),
  },
  copyContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  copyMain: {
    fontWeight: "bold",
  },
  copySub: {
    fontStyle: "italic",
  },
}))

const LoginWithStytch = (): JSX.Element => {
  const [params] = useSearchParams()
  const path = params.get("redirectTo") || "/"
  const navigate = useNavigate()
  const classes = useStyles()
  const domain = window.location.origin
  const theme = useTheme()

  const stytchProps: StytchLoginConfig = {
    products: [Products.emailMagicLinks, Products.oauth],
    emailMagicLinksOptions: {
      loginRedirectURL: `${domain}/authenticate?path=${path}`,
      signupRedirectURL: `${domain}/authenticate?path=${path}`,
    },
    oauthOptions: {
      loginRedirectURL: `${domain}/authenticate?oauth=true&path=${path}`,
      signupRedirectURL: `${domain}/authenticate?oauth=true&path=${path}`,
      providers: [
        {
          type: OAuthProviders.Google,
          one_tap: true,
          position: OneTapPositions.embedded,
        },
        {
          type: OAuthProviders.Microsoft,
        },
      ],
    },
  }

  const overrideStyles: StyleConfig = {
    fontFamily: '"Inter", "Manrope", "Helvetica New", Helvetica, sans-serif',
    width: "321px",
    primaryColor: theme.palette.secondary.main,
    primaryTextColor: theme.palette.primary.dark,
    hideHeaderText: true,
  }

  useEffect(() => {
    function checkIsLoggedIn(event: StorageEvent) {
      if (event.key === LS_LOGGED_IN_KEY && event.newValue) {
        navigate(path)
      }
    }

    window.addEventListener("storage", checkIsLoggedIn)

    return () => {
      window.removeEventListener("storage", checkIsLoggedIn)
    }
  }, [navigate, path])

  return (
    <Box className={classes.loginWrapper}>
      <Box className={classes.loginContainer}>
        <Typography variant="h4" component="h1">
          Log in to EvenUp
        </Typography>
        <Box className={classes.copyContainer}>
          <Typography variant="body1" component="p" className={classes.copyMain}>
            Enter your email address below to receive a secured link in your inbox or use your Google or
            Microsoft 365 account to log in.
          </Typography>
          <Typography variant="body1" component="p" className={classes.copySub}>
            Make sure to use the email address you normally use to log in to the EvenUp Law Portal.
          </Typography>
        </Box>
        <StytchLogin config={stytchProps} styles={overrideStyles} />
      </Box>
    </Box>
  )
}

export default LoginWithStytch
