import { INTAKE_STATUSES } from "../constants"
import { ALL_ROLES, EXTERNAL_ROLES_VALUES, OSF } from "../../common/models/roles"
import { canUserSeeInternalStatus } from "../permissions/requestAction"

export interface IntakeStatusOption {
  text: string
  color: string
  filled: boolean
  disabled?: boolean
}

export type IntakeStatusOptions = Record<INTAKE_STATUSES, IntakeStatusOption>

export const statusToPropertiesAll: IntakeStatusOptions = {
  [INTAKE_STATUSES.ready_for_tasker]: {
    text: "Ready for tasker",
    color: "#aa497e",
    filled: false,
  },
  [INTAKE_STATUSES.tasker_assigned]: {
    text: "Tasker assigned",
    color: "#aa9349",
    filled: false,
  },
  [INTAKE_STATUSES.tasker_completed]: {
    text: "Tasker completed",
    color: "#75aa49",
    filled: false,
  },
  [INTAKE_STATUSES.notRequested]: {
    text: "Not Requested",
    color: "#7D7D7D",
    filled: true,
  },
  [INTAKE_STATUSES.requested]: {
    text: "Requested",
    color: "#FEA568",
    filled: false,
  },
  [INTAKE_STATUSES.inProgress]: {
    text: "In Progress",
    color: "#216AFC",
    filled: true,
  },
  [INTAKE_STATUSES.cancelled]: {
    text: "Cancelled",
    color: "#9844A9",
    filled: true,
  },
  [INTAKE_STATUSES.completed]: {
    text: "Completed",
    color: "#47B971",
    filled: true,
  },
  [INTAKE_STATUSES.rework]: {
    text: "Rework",
    color: "#E44E48",
    filled: true,
  },
  [INTAKE_STATUSES.redelivered]: {
    text: "Redelivered",
    color: "#49AA6C",
    filled: false,
  },
  [INTAKE_STATUSES.missingDocuments]: {
    text: "Missing Documents",
    color: "#D50000",
    filled: true,
  },
  [INTAKE_STATUSES.documentsUploaded]: {
    text: "Documents Uploaded",
    color: "#000000",
    filled: true,
  },
}

function getOptionsForStatuses(statuses: INTAKE_STATUSES[]): Partial<IntakeStatusOptions> {
  return statuses.reduce<Partial<IntakeStatusOptions>>((result, status) => {
    result[status] = statusToPropertiesAll[status]
    return result
  }, {})
}

export const taskerStatuses = [INTAKE_STATUSES.tasker_assigned, INTAKE_STATUSES.tasker_completed]

export const deprecatedStatuses = [INTAKE_STATUSES.rework, INTAKE_STATUSES.redelivered]

export const disabledByRevisionStatuses = [
  INTAKE_STATUSES.missingDocuments,
  INTAKE_STATUSES.documentsUploaded,
]

export const internalIntakeStatuses = [
  INTAKE_STATUSES.ready_for_tasker,
  INTAKE_STATUSES.tasker_assigned,
  INTAKE_STATUSES.tasker_completed,
]

export const externalIntakeStatuses = Object.values(INTAKE_STATUSES).filter(
  status => !internalIntakeStatuses.includes(status)
)

export const statusToPropertiesInternalOSFOnly = getOptionsForStatuses(internalIntakeStatuses)

export const statusToPropertiesExternal = getOptionsForStatuses(externalIntakeStatuses)

export const statusInternalOSFOnly = internalIntakeStatuses

export interface IntakeStatusOptionValue extends IntakeStatusOption {
  intake_status: INTAKE_STATUSES
}

const getStatusToProperties = (status: INTAKE_STATUSES, role: ALL_ROLES): IntakeStatusOptionValue => {
  if (EXTERNAL_ROLES_VALUES.includes(role) && statusInternalOSFOnly.includes(status)) {
    status = INTAKE_STATUSES.inProgress
  }

  return { ...statusToPropertiesAll[status], intake_status: status }
}

export function getDisplayStatusToPropertiesByRole(userRole: ALL_ROLES): Partial<IntakeStatusOptions> {
  if (userRole === OSF) {
    return statusToPropertiesInternalOSFOnly
  }

  if (canUserSeeInternalStatus(userRole)) {
    return statusToPropertiesAll
  }

  return statusToPropertiesExternal
}

export { getStatusToProperties as default }
