import React, { useCallback, useState } from "react"
import { CaseAlertBox, CaseAlertTitle } from "./styled"

interface CaseAlertProps {
  className?: string
  title: string
  message: string
  canHide?: boolean
}

export function CaseAlert({ className, title, message, canHide }: CaseAlertProps): Nullable<JSX.Element> {
  const [opened, setOpened] = useState(true)

  const handleClose = useCallback(() => {
    setOpened(false)
  }, [setOpened])

  if (!opened) {
    return null
  }

  return (
    <CaseAlertBox
      severity="error"
      icon={false}
      className={className}
      onClose={canHide ? handleClose : undefined}
    >
      <CaseAlertTitle>{title}</CaseAlertTitle>
      {message}
    </CaseAlertBox>
  )
}
