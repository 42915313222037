import { Box } from "@material-ui/core"
import { InputField } from "common/form-components"
import { InputWithHeader } from "common/form-components/InputWithHeader"
import React from "react"
import { Control, FieldError, UseFormHandleSubmit } from "react-hook-form"

const DEFAULT_VALUES = { cancellation_date: "", cancellation_note: "" }

interface ContractsCancelFormProps {
  id?: string
  onReviewCancellation: (data: typeof DEFAULT_VALUES) => unknown
  // our lint rules ban use of object, but it seems like react-hook-form is using object type
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<typeof DEFAULT_VALUES, object>
  handleSubmit: UseFormHandleSubmit<typeof DEFAULT_VALUES>
  errors: { cancellation_date?: FieldError }
}

const ContractsCancelForm: React.FC<ContractsCancelFormProps> = ({
  id,
  onReviewCancellation,
  control,
  handleSubmit,
  errors,
}) => {
  return (
    <form id={id} onSubmit={handleSubmit(onReviewCancellation)}>
      <Box color="red" fontWeight={600} mb={3}>
        Cancel all contracts for this firm.
      </Box>
      <Box mb={1}>
        <InputWithHeader header="Cancellation Date">
          <InputField
            control={control}
            size={"small"}
            variant="outlined"
            name="cancellation_date"
            type="date"
            fullWidth
            rules={{ required: true }}
            helperText="Required. The final day of service e.g, if last day is Sept 20th, 2022 the customer will be able to use the service until end of day Sept 20th, 2022"
            errors={errors}
          />
        </InputWithHeader>
      </Box>
      <Box>
        <InputWithHeader header="Notes">
          <InputField
            control={control}
            size={"small"}
            variant="outlined"
            name="cancellation_note"
            type="text"
            helperText="Optional. Any notes explaining why the contract was cancelled"
            fullWidth
            multiline
            minRows={3}
          />
        </InputWithHeader>
      </Box>
    </form>
  )
}

export default ContractsCancelForm
