import React, { createRef, PureComponent } from "react"
import { RenderElementProps, RenderLeafProps } from "slate-react"
import { Leaf } from "./render/Leaf"
import { Element } from "./Element"
import { RichTextEditableProps, RichTextEditorEditable } from "./RichTextEditableEditor"
import { RichTextEditorReadonly, RichTextReadonlyProps } from "./RichTextReadonlyEditor"
import { CustomEditor, EditorRoot } from "./CustomEditor"
import { Editor } from "./Editor"
import { initialNormalize } from "./normalize/initial"
import { EditorFeatureProps } from "./features/types"

const renderElement = (props: RenderElementProps) => <Element {...props} />
const renderLeaf = (props: RenderLeafProps) => <Leaf {...props} />

type RichTextComponentProps<T> = Omit<T, "renderLeaf" | "renderElement">

export type RichTextEditorProps = EitherProps<
  RichTextComponentProps<RichTextReadonlyProps>,
  RichTextComponentProps<RichTextEditableProps>
>

type RichTextEditorWithFeaturesProps = RichTextEditorProps & EditorFeatureProps

export class RichTextEditor extends PureComponent<RichTextEditorWithFeaturesProps> {
  editorRef = createRef<CustomEditor>()
  initialValue: RichTextEditorProps["value"] = null

  constructor(props: RichTextEditorProps) {
    super(props)

    this.normalizeInitialState()
  }

  componentDidMount(): void {
    this.initialValue = null
  }

  componentDidCatch(): void {
    if (this.editorRef.current) {
      const editor = this.editorRef.current

      this.props.readonly
        ? Editor.resetReadonlyState(editor, editor.children)
        : Editor.resetEditableState(editor, editor.children)
    }

    this.normalizeInitialState()
    this.forceUpdate()
  }

  normalizeInitialState(): void {
    // We need to normalize raw data upon component creation
    // The reason is - editor can not normalize data with built-in mechanism until rendered
    // And in case data is corrupted it will fail on even first render
    // NOTE: we don't need to normalize data manually on updates
    // because in case of updates editor will normalize data with it's built-in mechanism BEFORE the render
    if (this.props.value) {
      const isFrozen = Object.isFrozen(this.props.value)
      const value: EditorRoot = isFrozen ? JSON.parse(JSON.stringify(this.props.value)) : this.props.value

      this.initialValue = initialNormalize(value)
    }
  }

  get value(): RichTextEditorProps["value"] {
    return this.initialValue || this.props.value
  }

  render(): JSX.Element {
    const { value } = this

    this.initialValue = null

    if (this.props.readonly) {
      return (
        <RichTextEditorReadonly
          {...this.props}
          value={value}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          ref={this.editorRef}
        />
      )
    }

    return (
      <RichTextEditorEditable
        {...this.props}
        value={value}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        ref={this.editorRef}
      />
    )
  }
}
