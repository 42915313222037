import { LibraryVariableRowData, SectionTemplateTableRowData } from "common/models/library"

export enum ActionType {
  EXPAND_TEMPLATE,
  COLLAPSE_TEMPLATE,
  START_EDIT_TEMPLATE,
  END_EDIT_TEMPLATE,
  START_CREATING_TEMPLATE,
  END_CREATING_TEMPLATE,
}

export interface ExpandTemplateAction {
  type: ActionType.EXPAND_TEMPLATE
  payload: { templateId: PrimaryKey }
}
export const createExpandTemplateAction = (
  payload: ExpandTemplateAction["payload"]
): ExpandTemplateAction => ({ type: ActionType.EXPAND_TEMPLATE, payload })

export interface CollapseTemplateAction {
  type: ActionType.COLLAPSE_TEMPLATE
  payload: { templateId: PrimaryKey }
}
export const createCollapseTemplateAction = (
  payload: CollapseTemplateAction["payload"]
): CollapseTemplateAction => ({ type: ActionType.COLLAPSE_TEMPLATE, payload })

export interface StartEditTemplateAction {
  type: ActionType.START_EDIT_TEMPLATE
  payload: { templateId: PrimaryKey }
}
export const createStartEditTemplateAction = (
  payload: StartEditTemplateAction["payload"]
): StartEditTemplateAction => ({ type: ActionType.START_EDIT_TEMPLATE, payload })

export interface FinishEditTemplateAction {
  type: ActionType.END_EDIT_TEMPLATE
  payload?: never
}
export const createFinishEditTemplateAction = (): FinishEditTemplateAction => ({
  type: ActionType.END_EDIT_TEMPLATE,
})

export interface StartCreatingTemplateAction {
  type: ActionType.START_CREATING_TEMPLATE
  payload?: SectionTemplateTableRowData | LibraryVariableRowData
}
export const createStartCreatingTemplateAction = (
  payload?: StartCreatingTemplateAction["payload"]
): StartCreatingTemplateAction => ({ type: ActionType.START_CREATING_TEMPLATE, payload })

export interface EndCreatingTemplateAction {
  type: ActionType.END_CREATING_TEMPLATE
  payload?: never
}
export const createEndCreatingTemplateAction = (): EndCreatingTemplateAction => ({
  type: ActionType.END_CREATING_TEMPLATE,
})

export type TemplateAction =
  | ExpandTemplateAction
  | CollapseTemplateAction
  | StartEditTemplateAction
  | FinishEditTemplateAction
  | StartCreatingTemplateAction
  | EndCreatingTemplateAction
