import React, { useEffect, useCallback } from "react"
import { Box, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useFieldArray } from "react-hook-form"
import { useRequestContext } from "../context"

import {
  INDIVIDUAL,
  NO_DEFENDANT_COVERAGE_TYPES_COMMERCIAL,
  NO_DEFENDANT_COVERAGE_TYPES_INDIVIDUAL,
} from "../constants"

import { DefendantForm } from "./DefendantForm"
import { PolicyInformation } from "./PolicyInformation"

const useStyles = makeStyles(theme => ({
  addDefendantButton: {
    marginTop: theme.spacing(1),
    maxWidth: theme.spacing(25),
  },
  defendantInfoContainer: {
    display: "grid",
    margin: theme.spacing(1, 0),
    gridColumnStart: 1,
    gridColumnEnd: "3",
  },
  defendantsContainer: {
    gridGap: theme.spacing(2),
  },
}))

const noDefendantCoverageTypes = new Set([
  ...NO_DEFENDANT_COVERAGE_TYPES_INDIVIDUAL,
  ...NO_DEFENDANT_COVERAGE_TYPES_COMMERCIAL,
])
const canAddDefendant = coverageType => {
  return !noDefendantCoverageTypes.has(coverageType)
}

export const PolicyAndDefendants = () => {
  const { control, watch } = useRequestContext()
  const {
    fields: defendants = [],
    append,
    remove,
  } = useFieldArray({
    control: control,
    name: "defendants",
  })

  const classes = useStyles()
  const currentCoverageType = watch("policy_coverage_type")

  const addEmptyDefendant = useCallback(() => {
    append({
      type: INDIVIDUAL,
      first_name: "",
      last_name: "",
      gender: "",
    })
  }, [append])

  useEffect(() => {
    if (defendants.length === 0 && canAddDefendant(currentCoverageType)) {
      addEmptyDefendant()
    }
  }, [currentCoverageType, defendants, addEmptyDefendant])

  useEffect(() => {
    if (!canAddDefendant(currentCoverageType)) {
      remove()
    }
  }, [currentCoverageType, remove])

  return (
    <Box className={classes.defendantInfoContainer}>
      <PolicyInformation />

      {canAddDefendant(currentCoverageType) && (
        <>
          <Button
            className={classes.addDefendantButton}
            variant="outlined"
            color={"primary"}
            onClick={addEmptyDefendant}
            data-test="add-defendant-button"
          >
            Add Defendant
          </Button>

          <Box className={classes.defendantsContainer}>
            {defendants.map(({ id }, index) => {
              return <DefendantForm key={id} index={index} remove={remove} />
            })}
          </Box>
        </>
      )}
    </Box>
  )
}
