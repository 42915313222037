import React, { useState, useCallback } from "react"
import { Box, Button, TextField, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { DateField } from "common/form-components"

import { queryKeys } from "../react-query/constants"
import { createCase } from "../api"

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0),
  },
}))

export function StartCase({ onSubmit = undefined }) {
  const classes = useStyles()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [caseInfo, setCaseInfo] = useState({})

  const { mutate: createNewCase } = useMutation(createCase, {
    onSuccess: data => {
      onSubmit ? onSubmit(data.pk) : navigate(`/search/${data.pk}`)
      queryClient.invalidateQueries(queryKeys.cases)
    },
  })

  const handleInputChange = event => {
    const { name, value } = event.target
    setCaseInfo({
      ...caseInfo,
      [name]: value,
    })
  }

  const handleCreate = () => {
    createNewCase(caseInfo)
  }

  const handleDateChange = useCallback(
    value => {
      setCaseInfo({
        ...caseInfo,
        ["date_of_incident"]: value,
      })
    },
    [setCaseInfo, caseInfo]
  )

  return (
    <Box>
      <Typography variant="h3" component="h1">
        New Case
      </Typography>
      <Box>
        <div className={classes.paper}>
          <form className={classes.form} autoComplete="off">
            <DateField
              label="Date of Incident"
              placeholder="When did the incident that opened this case occur?"
              initialValue={caseInfo.date_of_incident}
              onChange={handleDateChange}
              fieldProps={{
                id: "date_of_incident",
                name: "date_of_incident",
                margin: "normal",
                autoFocus: true,
                fullWidth: true,
              }}
            />
            <TextField
              id="state"
              label="State"
              placeholder="What state is this case taking place in?"
              variant="outlined"
              name="state"
              value={caseInfo.state}
              onChange={handleInputChange}
              margin="normal"
              autoFocus
              fullWidth
            />
            <TextField
              id="county"
              label="County"
              placeholder="What county is this case taking place in?"
              variant="outlined"
              name="county"
              value={caseInfo.county}
              onChange={handleInputChange}
              margin="normal"
              autoFocus
              fullWidth
            />
            <Button variant="contained" color="primary" onClick={handleCreate} className={classes.submit}>
              Create
            </Button>
          </form>
        </div>
      </Box>
    </Box>
  )
}
