import { getChunkedFile } from "common/file-resolver/resolver"
import { FileMetadata } from "common/form-components/files/types"
import { includesFile } from "common/form-components/files/utils"
import { FileToUpload } from "./upload-flow/types"
import { FileUploaderData } from "./uploader-state/types"
import { FailedUploadData } from "./file-state/types"

export function getDuplicatesFromState(currentState: FileUploaderData, files: File[]): FileMetadata[] {
  const recentlyAssignedFiles = Object.values(currentState)
    .filter(({ state }) => state.current === "ASSIGNED")
    .map(({ metadata }) => metadata)
  const duplicatedFiles = files.filter(file => includesFile(recentlyAssignedFiles, file))

  return duplicatedFiles
}

export function hasDuplicates(currentState: FileUploaderData, files: File[]): boolean {
  return getDuplicatesFromState(currentState, files).length > 0
}

type ChunkedFileWithMetadata = {
  data: FileToUpload
  metadata: FileMetadata
}

export function getChunkedFiles(files: File[]): ChunkedFileWithMetadata[] {
  return files.map(file => {
    const chunkedFile = getChunkedFile(file)

    return {
      data: {
        chunkCount: chunkedFile.chunkCount,
        chunks: chunkedFile.chunks,
        contentType: chunkedFile.file.type,
        size: chunkedFile.file.size,
      },
      metadata: chunkedFile.file,
    }
  })
}

export function getUploadIdFromState(state: FileUploaderData, id: Nullable<string>): Nullable<string> {
  if (id === null || !state[id]?.state) return null

  const { data } = state[id].state

  return "uploadId" in data ? data.uploadId : null
}

export function getUploadFailureReason(
  state: FileUploaderData,
  id: Nullable<string>
): Nullable<FailedUploadData> {
  if (id === null || !state[id]?.state) return null

  const { data } = state[id].state

  return "reason" in data ? data : null
}
