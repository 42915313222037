import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Box, Button, TextField, Typography } from "@material-ui/core"
import { Create as CreateIcon } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import { createAnnotationRequest } from "./api"
import { useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { queryKeys } from "../react-query/constants"
import { FileUploader } from "./FileUploader"
import { FILE_TYPES } from "common/constants"
import { Loading } from "common/loading"

const useStyle = makeStyles(theme => ({
  formContainer: {
    margin: theme.spacing(10),
  },
  formField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  submitBox: {
    display: "flex",
    marginTop: theme.spacing(2),
    flexDirection: "row-reverse",
  },
}))

interface FormValues {
  name: string
}

export default function NewAnnotationRequest(): JSX.Element {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { control, handleSubmit } = useForm<FormValues>()
  const classes = useStyle()
  const [files, setFiles] = useState<File[]>([])
  const [uploading, setUploading] = useState<boolean>(false)

  const upload = async (data: FormValues) => {
    setUploading(true)
    createAnnotationRequest({
      name: data.name,
      files,
    })
      .then(
        () => queryClient.invalidateQueries(queryKeys.annotationRequests),
        () => {
          setUploading(false)
        }
      )
      .then(() => {
        navigate("/annotation")
      })
  }

  return (
    <Box className={classes.formContainer}>
      <Loading show={uploading} label="Uploading files..." />
      <Typography variant="h4">Create New Annotation Request</Typography>
      <form onSubmit={handleSubmit(upload)}>
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <>
              <TextField
                className={classes.formField}
                label="Name"
                variant="outlined"
                InputLabelProps={{ required: true, shrink: true }}
                InputProps={{
                  required: true,
                }}
                {...field}
              />
            </>
          )}
        />

        <FileUploader accept={FILE_TYPES.PDF} files={files} setFiles={setFiles} />
        <Box className={classes.submitBox}>
          <Button variant="outlined" type="submit" color="primary" startIcon={<CreateIcon />}>
            Create Request
          </Button>
        </Box>
      </form>
    </Box>
  )
}
