import React, { useCallback, useState } from "react"
import { Box } from "@material-ui/core"

import clsx from "clsx"

import { Entry, PartitionEntry } from "../types"
import { WrapCell } from "./styled"
import { useStyles } from "../styled"
import MultipleEntriesItem from "../common/MultipleEntriesItem"
import EntriesPopover from "./EntriesPopover"
import { toUSDateString } from "utils"

interface CellProps {
  date: Nullable<string>
  entries: Entry[]
  onEntryClick: (entry: PartitionEntry) => void
}

const Cell = ({ date, entries, onEntryClick }: CellProps): JSX.Element => {
  const classes = useStyles()
  const numberOfEntries = entries.length
  const [anchorEl, setAnchorEl] = useState<Nullable<EventTarget & HTMLElement>>(null)

  const handleOpenPopover = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget)
  }, [])

  const handleClosePopover = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <WrapCell
      className={clsx(numberOfEntries === 1 && classes.entry)}
      onClick={() => {
        if (numberOfEntries !== 1) return
        const entry = entries[0]
        onEntryClick({
          exhibitId: entry.exhibit_id,
          partitionId: entry.partition_id,
          page: entry.page,
        })
      }}
    >
      <Box>{date ? toUSDateString(date) : "———"}</Box>

      {numberOfEntries > 1 && <Box>&#x3a;&nbsp;</Box>}
      {numberOfEntries > 1 && (
        <MultipleEntriesItem
          anchorEl={anchorEl}
          onOpen={handleOpenPopover}
          onClose={handleClosePopover}
          count={numberOfEntries}
          renderPopover={entries => {
            return (
              <EntriesPopover
                date={date}
                entries={entries}
                onEntryClick={(entry: Entry) => {
                  onEntryClick({
                    exhibitId: entry.exhibit_id,
                    partitionId: entry.partition_id,
                    page: entry.page,
                  })
                }}
              />
            )
          }}
          data={entries}
        />
      )}
    </WrapCell>
  )
}

export { Cell as default }
