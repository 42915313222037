import React from "react"
import { Box, Divider } from "@material-ui/core"
import { useDropzone } from "react-dropzone"
import { useDrop } from "react-dnd"

import FileDropper from "./FileDropper"
import { FILE_TYPE } from "../../constants"
import { FileToUploadType } from "./interfaces"
import { ACCEPT_ALL_TYPE, ACCEPT_TYPE } from "./constants"
import clsx from "clsx"
import { Alert, AlertTitle } from "@material-ui/lab"
import { fileTypeRestrictionText } from "./utils"

export interface FileDropzoneProps {
  onDrop: (files: FileToUploadType[] | File[]) => void
  children?: React.ReactNode
  acceptedFileTypes?: ACCEPT_TYPE
  disabled?: boolean
  className?: string
}

const FileDropzone = ({
  onDrop,
  children,
  acceptedFileTypes = ACCEPT_ALL_TYPE,
  disabled = false,
  className,
}: FileDropzoneProps): JSX.Element => {
  const { getRootProps, getInputProps, isDragActive, isFocused, fileRejections } = useDropzone({
    onDrop,
    disabled,
    accept: acceptedFileTypes === ACCEPT_ALL_TYPE ? undefined : acceptedFileTypes,
  })

  const { ref: dropzoneRef } = getRootProps()
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: FILE_TYPE,
      drop: () => ({
        onDrop: (fileId: number, fileName: string, url: string): void => {
          onDrop([
            {
              questionnaireFileId: fileId,
              downloadUrl: url,
              file: null,
              name: fileName,
            },
          ])
        },
      }),
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    []
  )

  const rootProps = getRootProps()

  return (
    <Box>
      <div
        {...rootProps}
        className={clsx(rootProps.className, className)}
        ref={el => {
          dropzoneRef.current = el
          drop(el)
        }}
      >
        <input {...getInputProps()} accept={acceptedFileTypes} data-test="file-dropper" />
        <FileDropper
          isDragActive={isDragActive || (isOver && canDrop)}
          isFocused={isFocused}
          disabled={disabled}
          fileTypes={acceptedFileTypes}
        >
          {!!children && (
            <>
              <Divider />
              {children}
            </>
          )}
        </FileDropper>
      </div>
      {!!fileRejections.length && (
        <Box mt={2}>
          <Alert severity="warning">
            <AlertTitle>
              {fileTypeRestrictionText(acceptedFileTypes)}. These files will be ignored:
            </AlertTitle>
            <ul>
              {fileRejections.map(({ file }) => (
                <li key={file.name}>{file.name}</li>
              ))}
            </ul>
          </Alert>
        </Box>
      )}
    </Box>
  )
}

export default FileDropzone
