import React, { useEffect, useState } from "react"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import { Attributes as AttributesType } from "common/types/attributes"
import {
  AttributeFiltersData,
  SectionAndAttributesFilter,
  SectionWithAttributes,
} from "common/attributes-filter"
import { CASE_SECTIONS } from "common/types/sections"
import { SectionTemplateFormData as SectionTemplateFormDataType } from "./types"
import { StyledEditorWrapper } from "./styled"
import { Variable } from "common/types/variables"

interface SectionTemplateFormProps {
  attributes: AttributesType
  onChange: (data: SectionTemplateFormDataType) => void
  initialSection?: CASE_SECTIONS
  initialAttributeValues?: AttributeFiltersData
  initialContent?: EditorRoot
  variables?: Variable[]
  error?: boolean
  errorSection?: boolean
}

const INITIAL_SECTION_WITH_ATTIRIBUTES: SectionWithAttributes = { section: null, attributeValues: {} }
const INITIAL_CONTENT: EditorRoot = []
const INITIAL_VARIABLES: Variable[] = []

export function SectionTemplateFormData({
  attributes,
  onChange,
  error,
  errorSection,
  initialContent,
  initialSection,
  initialAttributeValues,
  variables,
}: SectionTemplateFormProps): JSX.Element {
  const [sectionWithAttributes, setSectionWithAttributes] = useState(INITIAL_SECTION_WITH_ATTIRIBUTES)
  const [content, setContent] = useState(initialContent || INITIAL_CONTENT)

  useEffect(() => {
    onChange({ sectionWithAttributes, content })
  }, [onChange, sectionWithAttributes, content])

  return (
    <>
      <SectionAndAttributesFilter
        initialSection={initialSection}
        initialAttributeValues={initialAttributeValues}
        attributes={attributes}
        onChange={setSectionWithAttributes}
        error={error}
        errorSection={errorSection}
      />
      <StyledEditorWrapper>
        <RichTextEditor
          value={content}
          onChange={setContent}
          keepValue={false}
          withVariables
          variables={variables || INITIAL_VARIABLES}
        />
      </StyledEditorWrapper>
    </>
  )
}
