import { findParent } from "./projection"

export function removeFromParent(data, itemId) {
  const parent = findParent(data, itemId)
  parent.children = parent.children.filter(id => id !== itemId)

  return parent
}

export function appendTo(data, parentId, itemId) {
  const parent = data[parentId]
  parent.children = [...parent.children, itemId]

  return parent
}

export function appendBefore(data, previousItemId, itemId) {
  const parent = findParent(data, previousItemId)
  const index = parent.children.findIndex(id => id === previousItemId)
  const childrenBefore = parent.children.slice(0, index)
  const childrenAfter = parent.children.slice(index)

  parent.children = [...childrenBefore, itemId, ...childrenAfter]

  return parent
}

export function appendAfter(data, previousItemId, itemId) {
  const parent = findParent(data, previousItemId)
  const index = parent.children.findIndex(id => id === previousItemId)
  const childrenBefore = parent.children.slice(0, index + 1)
  const childrenAfter = parent.children.slice(index + 1)

  parent.children = [...childrenBefore, itemId, ...childrenAfter]

  return parent
}
