import React from "react"
import { Box, Typography } from "@material-ui/core"

import AttorneyForm from "./AttorneyForm"

export function NewAttorney() {
  return (
    <Box>
      <Typography variant="h4" component="h1">
        Create New Attorney
      </Typography>
      <AttorneyForm />
    </Box>
  )
}
