import React, { useCallback, useMemo } from "react"
import { StyledFormControl } from "./styled"
import { NONE_VALUE } from "./utils"
import { FormHelperText, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"

interface AttributeSelectProps<TValue> {
  value: TValue | typeof NONE_VALUE
  label: string
  options: ValueOptions<TValue | typeof NONE_VALUE>
  onChange: (value: TValue | typeof NONE_VALUE) => void
  required?: boolean
  error?: boolean
  helperText?: string
  dataTest?: string
}

export function AttributeSelect<TValue extends string | number>({
  value,
  options,
  label,
  onChange,
  error,
  helperText,
  dataTest,
  ...rest
}: AttributeSelectProps<TValue>): JSX.Element {
  const handleChange = useCallback((_, e) => onChange(e?.key), [onChange])

  const currentValue = useMemo(
    () => options.find(({ key }) => (value ?? NONE_VALUE) === key),
    [options, value]
  )

  const renderInput = useCallback(
    params => {
      return <TextField {...params} variant="outlined" label={label} error={error} />
    },
    [label, error]
  )
  const renderOption = useCallback(data => {
    return <span data-test={data.display}>{data.display}</span>
  }, [])
  const getOptionLabel = useCallback((option: { display: string }) => option.display, [])

  return (
    <StyledFormControl {...rest}>
      <Autocomplete
        size="small"
        blurOnSelect
        clearOnBlur
        data-test={dataTest}
        style={{ minWidth: 210 }}
        value={currentValue}
        options={options}
        renderInput={renderInput}
        renderOption={renderOption}
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </StyledFormControl>
  )
}
