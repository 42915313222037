import React from "react"
import { RenderElementProps } from "slate-react"
import { ListElement } from "../CustomEditor"

interface UnorderedListProps extends RenderElementProps {
  element: ListElement
}

export const UnorderedList: React.FC<UnorderedListProps> = ({ attributes, children }) => {
  return <ul {...attributes}>{children}</ul>
}
