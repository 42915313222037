import React from "react"
import { Snackbar } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const AlertSnackBar = ({
  message,
  open,
  onClose,
  severity = "success",
  autoHideDuration = 4000,
  verticalPosition = "top",
  horizontalPosition = "center",
}) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: verticalPosition, horizontal: horizontalPosition }}
      autoHideDuration={autoHideDuration}
      open={open}
      onClose={onClose}
    >
      <Alert severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export { AlertSnackBar as default }
