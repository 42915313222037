import React, { useCallback } from "react"
import { BrowserRouter } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { StytchProvider } from "@stytch/react"
import { StytchUIClient } from "@stytch/vanilla-js"
import { ProvideAuth } from "hooks/useAuth"
import { MessageBroker } from "message-broker"
import { useSubscribe } from "message-broker/useSubscribe"
import { MESSAGE_TOPIC } from "message-broker/topics"

import Main from "./Main"
import Header from "./Header"
import Footer from "./Footer"
import {
  APP_ID,
  HEADER_NAV_HEIGHT_SPACE_MULTIPLIER,
  DEFAULT_PREVIEW_WIDTH,
  DEFAULT_WINDOW_CONFIGURATIONS,
  MESSAGE_TOPIC_ROUTES,
} from "./constants"

const useStyles = makeStyles(theme => ({
  app: {
    position: "relative",
    height: "100vh",
    paddingTop: theme.spacing(HEADER_NAV_HEIGHT_SPACE_MULTIPLIER),
  },
}))

const PUBLIC_TOKEN = process.env.REACT_APP_STYTCH_PUBLIC_TOKEN
const stytch = new StytchUIClient(PUBLIC_TOKEN)

function handleOpenRoute(event) {
  const topic = event.data.payload
  const route = MESSAGE_TOPIC_ROUTES[topic]

  if (route) {
    const width = Math.max(DEFAULT_PREVIEW_WIDTH, Math.round(window.screen.availWidth / 3))
    const height = window.screen.availHeight
    const left = window.screen.availWidth - width

    window.open(
      route,
      "_blank",
      `${DEFAULT_WINDOW_CONFIGURATIONS},width=${width}},height=${height},top=0,left=${left}`
    )
  }
}

const App = React.memo(function App() {
  const classes = useStyles()

  const onSubscribeError = useCallback(reason => {
    // eslint-disable-next-line no-console
    console.error(`Unable to subscribe to message broker: ${reason}.`)
  }, [])

  useSubscribe(MESSAGE_TOPIC.OPEN_LISTENER, handleOpenRoute, onSubscribeError)

  return (
    <BrowserRouter>
      <StytchProvider stytch={stytch}>
        <ProvideAuth>
          <MessageBroker>
            <div className={classes.app} id={APP_ID}>
              <Header />
              <Main />
              <Footer />
            </div>
          </MessageBroker>
        </ProvideAuth>
      </StytchProvider>
    </BrowserRouter>
  )
})

export default App
