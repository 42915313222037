import { handleEmptyResponse } from "api/utils"
import { apiService } from "../ApiService"
import { ApiServiceType } from "../types"
import { isUndefined } from "lodash"

export enum EXHIBIT_API_PATHS {
  DOWNLOAD = "download",
  DOWNLOAD_ANNOTATED = "download-annotated",
  DOWNLOAD_PRE_ANNOTATED = "download-pre-annotated",
  DOWNLOAD_OCR = "download-ocr",
}

interface ExhibitServiceOptions {
  caseId: PrimaryKey
  exhibitId: PrimaryKey
}

class ExhibitService {
  constructor(private readonly apiService: ApiServiceType) {}

  getPath(options: ExhibitServiceOptions, path?: EXHIBIT_API_PATHS): string {
    const basePath = `/case/${options.caseId}/exhibit/${options.exhibitId}`
    return [basePath, path, ""].filter(path => !isUndefined(path)).join("/")
  }

  downloadExhibit = (options: ExhibitServiceOptions & { type: EXHIBIT_API_PATHS }) => {
    return handleEmptyResponse(this.apiService.getRaw(null, this.getPath(options, options.type)))
  }
}

export const exhibitService = new ExhibitService(apiService)
