import React from "react"
import { Loading } from "common"
import useFirm from "hooks/useFirm"
import useUser from "hooks/useUser"
import { Credits } from "./Credits"

interface RestrictedCreditsProps {
  restricted: JSX.Element
}

export function RestrictedCredits({ restricted }: RestrictedCreditsProps): JSX.Element {
  const { user } = useUser()
  const { firm, isLoading, hasValidFirmId } = useFirm(user.firmId ?? 0)

  if (!hasValidFirmId || isLoading) return <Loading />

  if (!firm.can_client_view_credits) {
    return restricted
  }

  return <Credits />
}
