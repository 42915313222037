import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import { Box, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import {
  CheckCircle as CompletedIcon,
  InsertDriveFile as FileIcon,
  NotInterested as SkippedIcon,
} from "@material-ui/icons"
import { Link, useParams } from "react-router-dom"
import { useQuery } from "react-query"

import { getMissingExhibits } from "../api"
import { queryKeys } from "../react-query/constants"
import useUser from "../hooks/useUser"
import { INTERNAL_ROLES_VALUES } from "../common/models/roles"
import { isNotOSFAndNotSetupRole } from "../common/permission"
import { useFormContext as useDemandFormContext } from "./context"
import { useDemandSteps } from "./steps"
import { FORM_SECTION_ROUTES } from "./steps/constants"
import { CASE_STEP, DEMAND_SECTION } from "./steps/enums"

const useStyles = makeStyles(theme => ({
  formSections: {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  listItem: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(2),
    },
  },
  listItemIcon: {
    minWidth: theme.spacing(3),
  },
  listItemText: {
    margin: 0,

    "&:first-child": {
      paddingLeft: theme.spacing(3),
    },

    "&, & > span": {
      fontSize: "14px",
    },
  },
  active: {
    "&, &:hover": {
      backgroundColor: theme.palette.grey.A100,
    },
  },
  icon: {
    fontSize: theme.spacing(2),
  },
  section: {
    textTransform: "uppercase",
    margin: theme.spacing(2, 0),

    "&:hover": {
      cursor: "pointer",
      fontWeight: "bold",
    },
  },
  infoIcon: {
    color: theme.palette.common.white,
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: "50%",
    textAlign: "center",
    margin: "auto 0",
    lineHeight: `${theme.spacing(2)}px`,
    fontSize: "12px",
  },
  notification: {
    backgroundColor: theme.palette.info.main,
  },
  warning: {
    backgroundColor: "#FEA568",
    fontWeight: "bold",
  },
  alert: {
    backgroundColor: theme.palette.error.main,
    fontWeight: "bold",
  },
  floatRight: {
    marginLeft: "auto",
  },
  fileIcon: {
    fontSize: "15px",
    height: "1.25rem",
  },
}))

export default function FormSections() {
  const classes = useStyles()
  const { user } = useUser()
  const { id: caseId } = useParams()
  const { request } = useDemandFormContext()
  const demandSteps = useDemandSteps()
  const hasCompletedPlaintiff = Boolean(
    demandSteps.items.find(({ step }) => step === CASE_STEP.PLAINTIFF)?.isCompleted
  )

  const { data: missingExhibits } = useQuery(
    [queryKeys.missingExhibits],
    async () => {
      return await getMissingExhibits({ caseId: caseId, onlyUnresolved: false })
    },
    {
      enabled: isNotOSFAndNotSetupRole(user.role) && !!request?.pk,
    }
  )

  const missingExhibitMap = {}
  let total = 0
  if (missingExhibits) {
    missingExhibits.forEach(file => {
      if (file.instructions == "upload") {
        missingExhibitMap[`section-${file.section}`] = "Uploaded"
      } else if (file.is_resolved == false && missingExhibitMap[`section-${file.section}`] != "Uploaded") {
        missingExhibitMap[`section-${file.section}`] = "Missing"
      }
      total++
    })
  }

  return (
    <List>
      {demandSteps.items.map(({ step, isCompleted, isSkipped, title, disabled, isReviewNeeded }) => (
        <ListItem
          className={clsx(classes.listItem, demandSteps.section === step && classes.active)}
          button
          key={step}
          component={Link}
          to={FORM_SECTION_ROUTES[step]}
          disabled={disabled}
          data-test={`section-${step}`}
        >
          {isCompleted || isSkipped ? (
            <>
              <ListItemIcon className={classes.listItemIcon}>
                {isCompleted && <CompletedIcon className={classes.icon} />}
                {isSkipped && <SkippedIcon className={classes.icon} />}
              </ListItemIcon>
              <ListItemText className={classes.listItemText} primary={title} />
            </>
          ) : (
            <ListItemText className={classes.listItemText} inset primary={title} />
          )}
          {missingExhibitMap[`section-${step}`] == "Missing" && (
            <ListItemIcon className={classes.listItemIcon}>
              <Box className={clsx(classes.infoIcon, classes.warning, classes.floatRight)}>!</Box>
            </ListItemIcon>
          )}
          {missingExhibitMap[`section-${step}`] == "Uploaded" && (
            <ListItemIcon className={classes.listItemIcon}>
              <Box className={clsx(classes.infoIcon, classes.notification, classes.floatRight)}>
                <FileIcon className={classes.fileIcon} />
              </Box>
            </ListItemIcon>
          )}
          {isReviewNeeded && (
            <ListItemIcon className={classes.listItemIcon}>
              <Box className={clsx(classes.infoIcon, classes.alert, classes.floatRight)}>!</Box>
            </ListItemIcon>
          )}
        </ListItem>
      ))}
      {INTERNAL_ROLES_VALUES.includes(user.role) && request?.pk && (
        <>
          <ListItem
            className={clsx(
              classes.listItem,
              demandSteps.section === DEMAND_SECTION.MISSING_DOCUMENTS && classes.active
            )}
            button
            component={Link}
            to={FORM_SECTION_ROUTES[DEMAND_SECTION.MISSING_DOCUMENTS]}
            disabled={!hasCompletedPlaintiff}
          >
            <ListItemText className={classes.listItemText} inset primary={"Missing Documents"} />
            <ListItemIcon className={classes.listItemIcon}>
              <Box
                className={clsx(classes.infoIcon, classes.notification, classes.floatRight)}
                data-test="missing-document-counter"
              >
                {missingExhibits && total}
              </Box>
            </ListItemIcon>
          </ListItem>
        </>
      )}
      <ListItem
        className={clsx(classes.listItem, demandSteps.section === DEMAND_SECTION.EXHIBITS && classes.active)}
        button
        component={Link}
        to="exhibit"
        data-test="arrange-exhibits"
        disabled={!hasCompletedPlaintiff}
      >
        <ListItemText className={classes.listItemText} inset primary="Arrange Exhibits" />
      </ListItem>
    </List>
  )
}
