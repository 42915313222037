import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Alert, DefaultPage } from "./styled"
import { Hidden } from "@material-ui/core"

interface PdfErrorPageProps {
  message?: string
  scale: number
}

export function PdfErrorPage({
  message = "Failed to load pdf page...",
  scale = 1,
}: PdfErrorPageProps): JSX.Element {
  return (
    <DefaultPage scale={scale}>
      <Alert severity="error">{message}</Alert>
      <Skeleton variant="rect" height={118} animation={false} />
      <Skeleton animation={false} />
      <Skeleton width="60%" animation={false} />
      <Hidden xsDown>
        <Skeleton height={118} animation={false} />
        <Skeleton animation={false} />
        <Skeleton width="60%" animation={false} />
      </Hidden>
    </DefaultPage>
  )
}
