import { Action, ConstEnum, DataUpdatersMap, State, StateTransitionMap, StateReducer } from "./types"

export function stateReducer<TStates extends ConstEnum<unknown>, TActions extends ConstEnum<unknown>>(
  state: keyof TStates,
  action: Action<keyof TActions, unknown>,
  stateTransitions: StateTransitionMap<TStates, TActions>
): keyof TStates | undefined {
  return stateTransitions[state][action.type]
}

export function dataReducer<
  TData extends BaseObject,
  TActionTypes extends ConstEnum<unknown>,
  TActions extends Action<keyof TActionTypes, BaseObject>,
  TUpdaters extends DataUpdatersMap<TData, TActionTypes, TActions>
>(data: TData, action: TActions, updaters: TUpdaters): TData {
  const { type, payload } = action
  const updater = updaters[type]

  if (!updater) return data

  const nextData = updater(data, payload)

  if (!nextData) return data

  return nextData
}

export function createReducer<
  TStates extends ConstEnum<unknown>,
  TData extends BaseObject,
  TActionTypes extends ConstEnum<unknown>,
  TActions extends Action<keyof TActionTypes, BaseObject>
>(
  stateTransitions: StateTransitionMap<TStates, TActionTypes>,
  dataUpdaters: DataUpdatersMap<TData, TActionTypes, TActions>,
  failureTransition: keyof TActionTypes
): StateReducer<TStates, TActionTypes, State<TStates, TData>, TActions> {
  return function (state, action) {
    const { current, data } = state

    try {
      const nextState = stateReducer(current, action, stateTransitions)

      if (!nextState) return state

      const nextData = dataReducer<TData, TActionTypes, TActions, typeof dataUpdaters>(
        data,
        action,
        dataUpdaters
      )

      return {
        current: nextState,
        data: nextData,
      }
    } catch (e) {
      const failureState = stateReducer(
        current,
        { type: failureTransition, payload: { reason: e } },
        stateTransitions
      )

      if (!failureState) return state

      const failureData = dataReducer<TData, TActionTypes, TActions, typeof dataUpdaters>(
        data,
        { type: failureTransition, payload: { reason: e } } as TActions,
        dataUpdaters
      )

      return {
        current: failureState,
        data: failureData,
      }
    }
  }
}
