import React from "react"
import { useQuery } from "react-query"

import AutoCompleteFilter from "./AutoCompleteFilter"

import { getInternalUsers } from "../../../api"
import useUser from "../../../hooks/useUser"
import { queryKeys } from "../../../react-query/constants"
import { FILTER_OPTION_ALL } from "../../../app/constants"

const UserFilter = ({ onChange, value, label = "User", className = "", showCurrentUserInLabel = true }) => {
  const { user } = useUser()
  const { isLoading, data, error } = useQuery([queryKeys.internalUsers], getInternalUsers)
  const SELF_STRING = "(Me)"

  if (isLoading) {
    return <></>
  }

  if (error) {
    return <></>
  }

  const options = [
    ...data?.data?.map(field => {
      const isSelf = user?.id === field.pk
      const display = showCurrentUserInLabel
        ? `${field.first_name} ${field.last_name}${isSelf ? ` ${SELF_STRING}` : ""}`
        : `${field.first_name} ${field.last_name}`
      return {
        key: field.pk,
        display: display,
      }
    }),
  ]

  // Sort to add self string to top of filter
  if (showCurrentUserInLabel) {
    options.sort((a, b) => {
      return a.display.indexOf(SELF_STRING) !== -1 ? -1 : b.display.indexOf(SELF_STRING) !== -1 ? 1 : 0
    })
  }
  options.unshift(FILTER_OPTION_ALL)

  const valueOption = options.find(option => option.key === value)

  return (
    <AutoCompleteFilter
      value={valueOption}
      onChange={onChange}
      options={options}
      label={label}
      className={className}
      id="user-filter"
    />
  )
}

export { UserFilter as default }
