import React, { useState } from "react"
import { useMutation } from "react-query"

import {
  createNewFirm,
  updateFirm,
  updateFirmFile,
  deleteFirmTemplate,
  updateCollateralSourceRule,
  downloadFirmTemplate,
} from "../../api"
import { FirmForm } from "./FirmForm"
import { INITIAL_STATE } from "./constants"
import { FirmData } from "./Firm"

interface FirmFormControllerProps {
  data?: Partial<FirmData>
  onCreate?: (firm: FirmData) => void
  onUpdate?: (firm: FirmData) => void
  onClose?: (firm: FirmData) => void
}

export const FirmFormController: React.FC<FirmFormControllerProps> = ({
  data: initialData = {},
  onCreate,
  onUpdate,
  onClose,
}) => {
  const [data, setData] = useState({ ...INITIAL_STATE, ...initialData })

  const createMutation = useMutation(createNewFirm, {
    onSuccess: updatedData => {
      setData(updatedData)
      onCreate && onCreate(updatedData)
    },
  })
  const updateMutation = useMutation(updateFirm, {
    onSuccess: updatedData => {
      setData(updatedData)
      onUpdate && onUpdate(updatedData)
    },
  })
  const updateFileMutation = useMutation(updateFirmFile)
  const deleteTemplateMutation = useMutation(deleteFirmTemplate)
  const downloadTemplateMuiation = useMutation(downloadFirmTemplate)
  const updateCsrMutation = useMutation(updateCollateralSourceRule)

  const updateFileMutationHandler = async (payload: { firmId: number; data: FormData }) => {
    const response = await updateFileMutation.mutateAsync(payload)
    const updatedData = await response.json()

    setData(updatedData)

    return updatedData
  }

  const handleLogoUpdate = async (file: File) => {
    if (!data.pk) {
      return data
    }

    const formData = new FormData()
    formData.append("logo", file)

    return await updateFileMutationHandler({ firmId: data.pk, data: formData })
  }

  const handleTemplateUpdate = async (file: File) => {
    if (!data.pk) {
      return data
    }

    const formData = new FormData()
    formData.append("template", file)

    return await updateFileMutationHandler({ firmId: data.pk, data: formData })
  }

  const handleTemplateDelete = async () => {
    return await deleteTemplateMutation.mutateAsync({ firmId: data.pk })
  }

  const handleTemplateDownload = async (fileName: string) => {
    return await downloadTemplateMuiation.mutateAsync({ firmId: data.pk, fileName: fileName })
  }

  const handleCsrUpdate = async (enable: boolean) => {
    return await updateCsrMutation.mutateAsync({ firmId: data.pk, enable })
  }

  const handleFirmCreate = async (firmData: FirmData) => {
    return await createMutation.mutateAsync({ data: { ...firmData, template: undefined } })
  }

  const handleFirmUpdate = async (firmData: Partial<FirmData>) => {
    return await updateMutation.mutateAsync({ firmId: data.pk, data: firmData })
  }

  const handleFirmFormClose = async (firmData: FirmData) => {
    onClose && onClose(firmData)
  }

  return (
    <FirmForm
      firmData={data}
      onCreate={handleFirmCreate}
      onUpdate={handleFirmUpdate}
      onClose={handleFirmFormClose}
      onLogoUpdate={handleLogoUpdate}
      onTemplateUpdate={handleTemplateUpdate}
      onTemplateDelete={handleTemplateDelete}
      onTemplateDownload={handleTemplateDownload}
      onCsrUpdate={handleCsrUpdate}
    />
  )
}
