import { SETTINGS_ROUTE_PREFIX } from "./types"

export const SETTINGS_LINK_LABELS: Record<SETTINGS_ROUTE_PREFIX, string> = {
  [SETTINGS_ROUTE_PREFIX.PROFILE]: "Profile",
  [SETTINGS_ROUTE_PREFIX.FIRMS]: "Firms List",
  [SETTINGS_ROUTE_PREFIX.ACCOUNTS]: "Users Accounts",
  [SETTINGS_ROUTE_PREFIX.CREDITS]: "Credits",
  [SETTINGS_ROUTE_PREFIX.LIBRARY]: "Library",
}

export const SETTINGS_LINK_ALT_LABELS: Partial<Record<SETTINGS_ROUTE_PREFIX, string>> = {
  [SETTINGS_ROUTE_PREFIX.ACCOUNTS]: "Manage Users",
}
