import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { CloudUpload, Delete as DeleteIcon } from "@material-ui/icons"
import React, { useRef } from "react"
import { FILE_OPTIONS, MAX_FILE_NAME_LENGTH } from "./constants"

const useStyles = makeStyles(theme => ({
  fileToUpload: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
  },
  fileExtension: {
    position: "relative",
    top: "2em",
    marginLeft: "5px",
  },
  addFileButton: {
    marginRight: theme.spacing(1),
  },
}))

const strippedFileName = name => {
  if (name) {
    return name.split(".").shift()
  }
  return ""
}

const fileExtension = fileToUpload => {
  if (fileToUpload?.file) {
    const parts = fileToUpload.file.name.split(".")
    if (parts.length > 1) {
      return "." + parts.pop()
    }
  }
  return ""
}

export function FileUploader({ handleFileChange, fileToUpload, setFileToUpload, fileTypes = FILE_OPTIONS }) {
  const classes = useStyles()
  const fileRef = useRef()

  const setFile = event => {
    if (event.currentTarget.files.length) {
      setFileToUpload({ file: event.currentTarget.files[0], name: event.currentTarget.files[0].name })
    } else {
      setFileToUpload(null)
    }
  }

  const setFileName = event => {
    setFileToUpload({ ...fileToUpload, name: event.target.value + fileExtension(fileToUpload) })
  }

  const setFileType = event => {
    setFileToUpload({ ...fileToUpload, documentType: event.target.value })
  }

  const handleAddFile = () => {
    handleFileChange(fileToUpload)
    setFileToUpload(null)
  }

  const handleClearFile = () => {
    setFileToUpload(null)
    fileRef.current.value = ""
  }

  const extension = fileExtension(fileToUpload)
  return (
    <Box className={classes.fileUploader}>
      <input type="file" ref={fileRef} onChange={setFile} style={{ display: "none" }} />
      <Button
        onClick={() => fileRef.current.click()}
        variant="outlined"
        color="primary"
        startIcon={<CloudUpload />}
      >
        Upload File
      </Button>
      {fileToUpload && (
        <Box className={classes.fileToUpload} marginTop={1}>
          <Typography variant="body1" component="p" className={classes.fileName}>
            {fileToUpload.file.name}
          </Typography>
          <Box>
            <TextField
              label="File name"
              name="filename"
              variant="outlined"
              onChange={setFileName}
              // slightly less than the max to account for file ext getting added automatically
              inputProps={{ maxLength: MAX_FILE_NAME_LENGTH - extension.length }}
              InputLabelProps={{ shrink: true }}
              value={strippedFileName(fileToUpload?.name || fileToUpload?.file.name)}
            />
            <span className={classes.fileExtension}>{extension}</span>
          </Box>
          <FormControl className={classes.fileType}>
            <Select
              labelId="file-type-selector"
              value={fileToUpload.documentType || ""}
              onChange={setFileType}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Object.keys(fileTypes).map(key => (
                <MenuItem key={key} value={key}>
                  {fileTypes[key]}
                </MenuItem>
              ))}
            </Select>
            {!fileToUpload?.documentType && <FormHelperText>Please select the file type</FormHelperText>}
          </FormControl>
          <Box>
            <Button
              className={classes.addFileButton}
              variant="outlined"
              onClick={handleAddFile}
              disabled={!fileToUpload?.documentType}
            >
              Add File
            </Button>
            <IconButton onClick={handleClearFile}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  )
}
