import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { initMessageBroker } from "./init"
import { workbox } from "./workbox"

export const MessageBroker = React.memo(function MessageBroker({ children }) {
  const location = useLocation()

  useEffect(() => {
    initMessageBroker()
  }, [])

  useEffect(() => {
    workbox.update()
  }, [location.pathname])

  return <>{children}</>
})
