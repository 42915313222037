import React from "react"
import { Control, FieldErrors, useController } from "react-hook-form"
import { makeStyles } from "@material-ui/core/styles"
import { InputField } from "../../common/form-components"
import Placeholders from "./Placeholders"
import TemplateSections from "./TemplateSections"
import { FileInput } from "../../common/form-components/files/FileInput"
import { CustomDamageSection, FirmData } from "./Firm"

const useStyles = makeStyles(() => ({
  fullWidth: {
    gridColumn: "1 / 4",
  },
  large: {
    gridColumn: "1 / 3",
  },
}))

export type FirmTemplateDataFields = Partial<FirmData> &
  Pick<
    FirmData,
    "sections" | "deletedSections" | "template" | "templateData" | "header_text" | "header_text_2"
  >

interface FirmTemplateFieldsProps {
  control: Control<FirmTemplateDataFields>
  disabled: boolean
  errors?: FieldErrors<FirmTemplateDataFields>
  onUpdate: () => void
  onTemplateDelete: () => void
  onTemplateDownload: (fileName: string) => void
  templateName?: string
  syncState: Partial<Record<keyof FirmTemplateDataFields, boolean>>
}

export const FirmTemplateFields: React.FC<FirmTemplateFieldsProps> = ({
  control,
  disabled,
  onUpdate,
  syncState,
  templateName,
  onTemplateDelete,
  onTemplateDownload,
}) => {
  const classes = useStyles()
  const { field: sectionsField } = useController({
    control,
    name: "sections",
  })
  const { field: deletedSectionsField } = useController({
    control,
    name: "deletedSections",
  })

  const updateSections = (value: CustomDamageSection[]) => {
    sectionsField.onChange({ target: { value } })
    onUpdate()
  }
  const deleteSection = (value: number) => {
    deletedSectionsField.onChange({ target: { value: [...(deletedSectionsField.value || []), value] } })
  }

  return (
    <>
      <FileInput
        control={control}
        name="templateData"
        label={templateName}
        buttonLabel="Select Template"
        accept=".docx"
        syncState={syncState}
        className={classes.large}
        onDelete={onTemplateDelete}
        onFileDownload={onTemplateDownload}
      />
      <Placeholders />
      <InputField
        control={control}
        name="header_text"
        type="text"
        label="First Page Header Text"
        variant="outlined"
        className={classes.fullWidth}
        disabled={disabled}
      />
      <InputField
        control={control}
        name="header_text_2"
        type="text"
        label="Remaining Pages Headers Text"
        variant="outlined"
        className={classes.fullWidth}
        disabled={disabled}
      />
      {!disabled && (
        <TemplateSections
          sections={sectionsField.value}
          onUpdate={updateSections}
          onDeleteSection={deleteSection}
        />
      )}
    </>
  )
}
