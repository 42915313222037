import { Editor, NodeEntry, Path, Range } from "slate"
import { CustomEditor, ParagraphElement } from "../CustomEditor"
import { LEAF_BLOCK_ELEMENTS } from "../elements"

export function getEditorRange(editor: CustomEditor): Range | undefined {
  // editor.children can sometimes be undefined, even though TypeScript says otherwise
  if (!editor.children || editor.children.length === 0) {
    return undefined
  }

  return {
    anchor: Editor.start(editor, [0]),
    focus: Editor.end(editor, [editor.children.length - 1]),
  }
}

export function getNodeRange(editor: CustomEditor, path: Path): Range | undefined {
  if (!Editor.hasPath(editor, path)) {
    return undefined
  }

  return {
    anchor: Editor.start(editor, path),
    focus: Editor.end(editor, path),
  }
}

export function getNodeStartRange(editor: CustomEditor, path: Path): Range | undefined {
  if (!Editor.hasPath(editor, path)) {
    return
  }

  return {
    anchor: {
      path,
      offset: 0,
    },
    focus: {
      path,
      offset: 0,
    },
  }
}

export function getClosestParagraph(
  editor: CustomEditor,
  path: Path
): NodeEntry<ParagraphElement> | undefined {
  if (!Editor.hasPath(editor, path)) {
    return
  }

  return Editor.above(editor, {
    at: path,
    match: node => Editor.isBlock(editor, node) && node.type === LEAF_BLOCK_ELEMENTS.PARAGRAPH,
    mode: "lowest",
  })
}
