export const queryKeys = {
  user: "user",
  trial: "trial",
  session: "session",
  searchResults: "searchResults",
  cases: "cases",
  case: "case",
  caseWithAnnotation: "caseWithAnnotation",
  favoriteCases: "favoriteCases",
  documents: "documents",
  formSections: "formSections",
  steps: "steps",
  plaintiff: "plaintiff",
  incomeLoss: "incomeLoss",
  incomeLossCalulation: "incomeLossCalculation",
  carrier: "carrier",
  providers: "providers",
  icdCodes: "icd",
  cptCodes: "cpt",
  attorneys: "attorneys",
  template: "template",
  firms: "firms",
  firm: "firm",
  attorney: "attorney",
  firmLogo: "firmLogo",
  household: "household",
  calculateHousehold: "calcHousehold",
  householdRelevantDates: "householdRelevantDate",
  nonEconomic: "nonEconomic",
  calculateNonEconomic: "calculateNonEconomic",
  facts: "facts",
  property: "property",
  validateDemand: "validateDemand",
  usStates: "usStates",
  eduLevel: "eduLevel",
  sectionTypes: "sectionTypes",
  damagesSections: "damagesSections",
  requests: "requests",
  isInSquad: "isInSquad",
  request: "request",
  requestFiles: "requestFiles",
  calculateCredits: "calculateCredits",
  intakeAsset: "intakeAsset",
  userAccount: "userAccount",
  internalUsers: "internalUsers",
  externalUsers: "externalUsers",
  futureExpense: "futureExpense",
  annotationRequests: "annotationRequests",
  caseSummaries: "caseSummaries",
  userSettings: "userSettings",
  medicalAssociations: "medicalAssociations",
  exhibits: "exhibits",
  annotated_exhibits: "annotated_exhibits",
  partition_providers: "partition_providers",
  nonEconomicValidation: "nonEconomicValidation",
  householdValidation: "householdValidation",
  incomeLossColumns: "incomeLossColumns",
  caseVariables: "caseVariables",
  // Demand generation
  generateDemand: "generateDemand",
  allGeneratedDemands: "allGeneratedDemands",
  missingExhibits: "missingExhibits",
  missingExhibitEvents: "missingExhibitEvents",
  providersMinimal: "providersMinimal",
  firmContracts: "firmContracts",
  firmContract: "firmContract",
  requestRevision: "requestRevision",
  requestRevisionEvents: "requestRevisionEvents",
  providerUpdates: "providerUpdates",
  // Library
  attributes: "attributes",
  sectionTemplates: "sectionTemplates",
  providerTemplates: "providerTemplates",
  libraryVariables: "libraryVariables",
  libraryVariableGroups: "libraryVariableGroups",
  caseTemplates: "caseTemplates",
  defaultVariables: "defaultVariables",
  // Annotated Providers
  annotatedAppointments: "annotatedAppointments",
  annotatedBills: "annotatedBills",
  annotatedIcdCodes: "annotatedIcdCodes",
  annotatedInjuryDetails: "annotatedInjuryDetails",
  // LLM
  generatorKeys: "generatorKeys",
  providerSummary: "providerSummary",
}
