import React from "react"
import { Link } from "react-router-dom"
import { Box, Typography, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    placeItems: "center",
    padding: theme.spacing(2),
  },
  copy: {
    textAlign: "center",
    "& > p": {
      margin: theme.spacing(2, 0),
    },
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(4),
  },
}))

export function Confirmation() {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.copy}>
        <Typography variant="h4" component="h1" data-test="thank-you-for-submitting">
          Thank you for submitting your request.
        </Typography>
        <Typography variant="body1" component="p">
          We are working it and will notify you if any further action is needed on your part.
        </Typography>
        <Typography variant="body2" component="p">
          You can review the submitted information or the status of all your requests using the buttons below.
        </Typography>
        <Box className={classes.actions}>
          <Button to="../.." component={Link} variant="text" color="secondary">
            Back to list of requests
          </Button>
          <Button to="../" component={Link} variant="text">
            View request details
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
