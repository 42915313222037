import { useQuery, useQueryClient } from "react-query"
import { queryKeys as defaultQueryKeys } from "../react-query/constants"
import { getFirmExtended } from "../api"
import { ExtendedFirmDto } from "settings/Firm/Firm"

type FirmLoadSuccessReturn = {
  firm: ExtendedFirmDto
  hasValidFirmId: true
  isLoading: false
}

type InvalidFirmReturn = {
  firm: null
  hasValidFirmId: false
  isLoading: false
}

type FirmLoadingReturn = {
  firm: null
  hasValidFirmId: true
  isLoading: true
}

type FirmReturn = FirmLoadSuccessReturn | InvalidFirmReturn | FirmLoadingReturn

type UseFirmHookReturn = FirmReturn & {
  updateCache: (firm: Partial<ExtendedFirmDto>) => void
}

function getFirm(firmId: number): Promise<ExtendedFirmDto> {
  return getFirmExtended({ queryKey: [null, firmId] })
}

export default function useFirm(firmId: number, queryKey: string = defaultQueryKeys.firm): UseFirmHookReturn {
  const queryClient = useQueryClient()
  const hasValidFirmId = !!firmId

  const { data: firm, isLoading } = useQuery<ExtendedFirmDto>([queryKey, firmId], () => getFirm(firmId), {
    retry: false,
    enabled: hasValidFirmId,
  })

  const updateCache = (newFirm: Partial<ExtendedFirmDto>) => {
    queryClient.setQueryData([queryKey, firmId], (oldFirm: Partial<ExtendedFirmDto> | undefined) => {
      if (oldFirm === undefined) return newFirm

      return {
        ...oldFirm,
        ...newFirm,
      }
    })
  }

  const isInvalidFirmId = !hasValidFirmId || (!isLoading && !firm)

  if (isInvalidFirmId) {
    return {
      firm: null,
      isLoading: false,
      hasValidFirmId: false,
      updateCache,
    }
  }

  if (isLoading || !firm) {
    return {
      firm: null,
      isLoading: true,
      hasValidFirmId: true,
      updateCache,
    }
  }

  return {
    firm,
    isLoading,
    hasValidFirmId,
    updateCache,
  }
}
