import React, {
  ClipboardEventHandler,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"
import { createEditor, Descendant } from "slate"
import { withHistory } from "slate-history"
import { withReact, Slate, Editable, RenderLeafProps, RenderElementProps } from "slate-react"
import { styled, Theme } from "@material-ui/core/styles"
import { DEFAULT_VALUE } from "./defaultValue"
import { Editor } from "./Editor"
import { RichTextToolbar } from "./render/toolbar"
import { CustomEditor, EditorRoot } from "./CustomEditor"
import { isInList } from "../rich-text/commands/lists/queries"
import { deserializeFromHtml, deserializeFromPlainText } from "./serializer/html/deserializer"
import { handleKeyDown } from "./utils"
import { EditorFeatureProps, EditorFeatureRendererProps } from "./features/types"
import { getFeaturesRenderer } from "./features/renderers"
import { getFeaturesWrapper } from "./features/wrappers"

const StyledEditor = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  "& p": {
    "&:first-child": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
}))

const StyledToolbarWrapper = styled("div")(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const EditorWrapper: React.FC<{ error?: boolean; className?: string }> = ({ className, children }) => {
  return <div className={className}>{children}</div>
}

const StyledWrapper = styled(EditorWrapper)<Theme, { error?: boolean }>(({ theme, error }) => ({
  border: `1px solid ${error ? theme.palette.error.main : theme.palette.grey["400"]}`,
  borderRadius: theme.spacing(0.5),
}))

export interface RichTextEditableProps {
  value?: Nullable<EditorRoot>
  onChange: (nextValue: EditorRoot) => void
  onBlur?: React.FocusEventHandler
  renderElement: (props: RenderElementProps) => JSX.Element
  renderLeaf: (props: RenderLeafProps) => JSX.Element
  keepValue: boolean
  dataTest?: string
  name?: string
  error?: boolean
}

export const RichTextEditorEditable = React.forwardRef<
  CustomEditor,
  RichTextEditableProps & EditorFeatureProps
>(function RichTextEditorEditable(
  {
    value = DEFAULT_VALUE,
    onChange,
    onBlur,
    renderElement,
    renderLeaf,
    keepValue = false,
    dataTest,
    error,
    ...featureProps
  },
  ref
): JSX.Element {
  const editorValue = useMemo<EditorRoot>(() => {
    if (!value || value.length === 0) return DEFAULT_VALUE
    return value
  }, [value])
  const featureWrapper = useRef(getFeaturesWrapper(featureProps))
  const [editor] = useState<CustomEditor>(() =>
    withHistory(featureWrapper.current(withReact(createEditor())))
  )
  const featuresRenderer = useRef(getFeaturesRenderer(featureProps))
  const rendererProps = { ...featureProps, editor } as EditorFeatureRendererProps

  useImperativeHandle(ref, () => editor, [editor])

  useEffect(() => {
    if (!keepValue) return

    if (editor.children !== editorValue) {
      Editor.resetEditableState(editor, editorValue)
    }
  }, [keepValue, editor, editorValue])

  useEffect(() => {
    Editor.normalize(editor, { force: true })
  }, [editor])

  const handleOnChange = useCallback(
    (nextEditorValue: Descendant[]) => {
      if (editor.isSideEffectRunning) {
        return
      }

      if (nextEditorValue !== editor.children) {
        return
      }

      if (nextEditorValue !== editorValue && onChange) {
        onChange(nextEditorValue as EditorRoot)
      }
    },
    [editor, onChange, editorValue]
  )

  const handlePaste: ClipboardEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (event.clipboardData.types.length === 1 && event.clipboardData.types.includes("text/plain")) {
        event.preventDefault()

        const nodes = deserializeFromPlainText(event.clipboardData)

        Editor.pasteFragment(editor, nodes)

        return
      }

      const cursorInList =
        isInList(editor, editor.selection?.anchor.path) || isInList(editor, editor.selection?.focus.path)

      if (event.clipboardData.types.includes("application/x-slate-fragment") && !cursorInList) return false

      if (event.clipboardData.types.includes("text/html")) {
        event.preventDefault()

        const nodes = deserializeFromHtml(event.clipboardData)

        Editor.pasteFragment(editor, nodes)

        return false
      }
    },
    [editor]
  )

  return (
    <StyledWrapper error={error}>
      <Slate editor={editor} value={editorValue} onChange={handleOnChange}>
        <StyledToolbarWrapper>
          <RichTextToolbar />
        </StyledToolbarWrapper>
        <StyledEditor>
          {featuresRenderer.current({
            ...rendererProps,
            children: (
              <Editable
                data-test={dataTest}
                onBlur={onBlur}
                onPaste={handlePaste}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                spellCheck
                onKeyDown={handleKeyDown(editor)}
              />
            ),
          })}
        </StyledEditor>
      </Slate>
    </StyledWrapper>
  )
})
