import { Range } from "slate"
import { Editor, Node } from "slate"
import { CustomEditor, CustomText } from "../CustomEditor"

export function isTextNode(node: Node | undefined): node is CustomText {
  return Boolean(node && "text" in node)
}

export function isText(editor: CustomEditor, node: Node | undefined): node is CustomText {
  if (!node) return false

  return (
    !Editor.isBlock(editor, node) &&
    !Editor.isInline(editor, node) &&
    !Editor.isVoid(editor, node) &&
    isTextNode(node)
  )
}

export function getSelectedText(editor: CustomEditor): string {
  if (!editor.selection || Range.isCollapsed(editor.selection)) return ""

  const fragment = Editor.fragment(editor, editor.selection)

  const text = fragment.reduce((acc, node) => acc + Node.string(node), "")

  return text
}
