import React, { useEffect, useState } from "react"
import { Card, CardHeader, Button, CardActions, Typography, Collapse, CardContent } from "@material-ui/core"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import {
  downloadOriginalFile,
  downloadFileCSV,
  downloadAutoAnnotation,
  downloadKiliAnnotation,
  getStatus,
  AnnotationFile,
} from "./api"
import { AttachFile as FileIcon } from "@material-ui/icons"
import annotationStatusProperties, {
  LOADING_STATUS,
  PENDING_STATUS,
  StatusStyle,
} from "./annotationStatusProperties"
import StatusLabel from "../common/status/StatusLabel"
import AnnotationFileStatusTable from "./AnnotationFileStatusTable"
import { formatTimeSinceNow, getAPIServerURL } from "utils"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    status: {
      display: "inline-block",
      verticalAlign: "middle",
    },
    actionArea: {
      display: "flex",
      flexDirection: "row-reverse",
    },
    file_id: {
      color: theme.palette.grey["A100"],
    },
  })
)

interface AnnotationRequestViewInterface {
  file: AnnotationFile
  requestId: number
}
export default function AnnotationRequestView(arv: AnnotationRequestViewInterface): JSX.Element {
  const classes = useStyles()
  const [statusStyle, setStatusStyle] = useState<StatusStyle>(annotationStatusProperties.loading)
  const [status, setStatus] = useState<string>(LOADING_STATUS)
  const [expanded, setExpanded] = useState<boolean>(false)
  const file = arv.file
  const requestId = arv.requestId
  useEffect(() => {
    function updateStatusFromGCS() {
      if (file.status_file) {
        getStatus(requestId, file.pk).then(value => {
          setStatusStyle(annotationStatusProperties[value.status])
          setStatus(value.status)
        })
      } else {
        if (file.status) {
          let style = annotationStatusProperties[file.status?.json?.status]
          if (style === undefined) {
            style = annotationStatusProperties[PENDING_STATUS]
          }
          setStatusStyle(style)
          setStatus(file.status.json.status)
        }
      }
    }
    // TODO: use ReactQuery to dynamically update this without refreshing page
    updateStatusFromGCS()
  }, [file.pk, file.status_file, file.status, requestId])

  const expandCard = () => {
    if (Object.keys(file?.status?.json?.kili_assets ?? {}).length > 0) {
      setExpanded(!expanded)
    } else {
      setExpanded(false)
    }
  }
  let displayOpenMessage
  if (Object.keys(file?.status?.json?.kili_assets ?? {}).length > 0) {
    displayOpenMessage = <Typography>Click to expand</Typography>
  } else {
    displayOpenMessage = <Typography></Typography>
  }
  const lastUpdatedStr = file?.status?.last_updated
  return (
    <Card key={file.pk} title={file.filename}>
      <CardHeader
        title={
          <>
            <FileIcon />
            <Button onClick={async () => await downloadOriginalFile(file.display_name, requestId, file.pk)}>
              {file.display_name}
            </Button>
            <div className={classes.status}>
              <StatusLabel
                status={status}
                color={statusStyle.color}
                text={statusStyle.text}
                filled={statusStyle.filled}
                showDot={true}
              />
            </div>
            {!!lastUpdatedStr && (
              <div>
                <Typography>Last updated {formatTimeSinceNow(lastUpdatedStr)}.</Typography>
              </div>
            )}
            <Typography className={classes.file_id}>{file.file_id}</Typography>
            {displayOpenMessage}
          </>
        }
        onClick={expandCard}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <AnnotationFileStatusTable file={file} />
        </CardContent>
      </Collapse>
      <CardActions className={classes.actionArea}>
        <Button
          onClick={() => downloadFileCSV(file.result_csv_file, requestId, file.pk)}
          disabled={!file.result_csv_file}
        >
          CSV
        </Button>
        <Button
          onClick={() => downloadAutoAnnotation(file.result_file, requestId, file.pk)}
          disabled={!file.result_file}
        >
          Automated Annotations
        </Button>
        <Button
          onClick={() => downloadKiliAnnotation(file.kili_result_file, requestId, file.pk)}
          disabled={!file.kili_result_file}
        >
          Annotations
        </Button>
        <Button
          disabled={!file?.json_annotations}
          href={`${getAPIServerURL()}/annotation/${requestId}/file/${file.pk}/download-summary/`}
        >
          Single Doc Summary
        </Button>
      </CardActions>
    </Card>
  )
}
