import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { SECTIONS } from "./enums"
import { ExhibitSection } from "./Exhibit"
import { Button } from "@material-ui/core"
import { AddCircle as AddCircleIcon } from "@material-ui/icons"
import { addNewGroup } from "./utils"

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    padding: theme.spacing(1),
  },
}))

interface ExhibitsListSectionProps {
  item: ExhibitSection
  onChange: (item: ExhibitSection) => void
}

export const ExhibitsListSection: React.FC<ExhibitsListSectionProps> = ({ item, onChange }) => {
  const classes = useStyles()

  const handleAddGroup = () => {
    const group = addNewGroup(item.section as SECTIONS)
    const updatedItem = {
      ...item,
      children: [group, ...item.children],
    }

    onChange(updatedItem)
  }

  return (
    <>
      <span className={classes.sectionTitle} data-test="exhibit-section-title">
        {item.section}
      </span>
      <Button variant="outlined" startIcon={<AddCircleIcon />} size="small" onClick={handleAddGroup}>
        Combine Exhibits
      </Button>
    </>
  )
}
