import React from "react"
import { styled } from "@material-ui/core/styles"
import { Box, Typography } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import FileDropzone from "common/form-components/files/FileDropzone"
import FileToUpload from "common/form-components/files/FileToUpload"
import TextButton from "common/buttons/TextButton"
import DraggableFile from "common/form-components/files/DraggableFile"
import { reorderImmutable } from "utils"
import { AUTO_IMPORT, EXHIBIT, PARTITIONED_EXHIBIT } from "demand/Providers/constants"
import { MEDICAL_FILE_OPTIONS } from "demand/constants"
import { useFeatures, FEATURES } from "hooks/useFeatures"

const FilesToUpload = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  marginTop: theme.spacing(5),
}))

const Exhibits = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(5),
}))

export const FilesSection = ({
  providerId,
  disabled,
  onDrop,
  filesToUpload,
  filesToUploadValidationErrors,
  onFilesToUploadChange,
  onFileCancel,
  onFileUpload,
  exhibits,
  exhibitsValidationErrors,
  onExhibitReorder,
  onExhibitDelete,
  onExhibitDownload,
  onExhibitsChange,
  onExhibitCancel,
  onExhibitSave,
  onExhibitEdit,
  onPartitionDownload,
  onPartitionDelete,
  onPartitionChange,
  onPartitionCancel,
  onPartitionSave,
  onPartitionEdit,
  openPDFViewerWindow,
}) => {
  const { isFeatureEnabled } = useFeatures()
  const isProviderAutofillEnabled = isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL)

  const handleFileFieldChange = (name, index) => value => {
    const newFilesToUpload = [...filesToUpload]
    const newFile = { ...newFilesToUpload[index], [name]: value }

    newFilesToUpload[index] = newFile

    onFilesToUploadChange(newFilesToUpload)
  }

  const handleFileCancel = formId => () => {
    onFileCancel(formId)
  }

  const handleFileUpload = (file, index) => () => {
    onFileUpload(file, index)
  }

  const handleExhibitDelete = exhibit => () => {
    onExhibitDelete(exhibit)
  }

  const handleExhibitDownload = exhibit => async () => {
    return await onExhibitDownload(exhibit)
  }

  const handleExhibitFieldChange = (name, index) => value => {
    const newExhibits = [...exhibits]
    const newExhibit = { ...newExhibits[index], [name]: value }

    newExhibits[index] = newExhibit

    onExhibitsChange(newExhibits)
  }

  const handlePartitionFieldChange = (name, index) => value => {
    const newPartitions = [...exhibits]
    const newPartition = { ...newPartitions[index], [name]: value }

    newPartitions[index] = newPartition

    onPartitionChange(newPartitions)
  }

  const handleExhibitCancel = exhibitPk => () => {
    onExhibitCancel(exhibitPk)
  }

  const handleExhibitSave = (exhibit, index) => () => {
    onExhibitSave(exhibit, index)
  }

  const handleExhibitEdit = exhibitPk => () => {
    onExhibitEdit(exhibitPk)
  }

  const handlePartitionDownload = partitionedExhibit => async () => {
    return await onPartitionDownload(partitionedExhibit)
  }

  const handlePartitionDelete = partitionedExhibit => async () => {
    return await onPartitionDelete(partitionedExhibit)
  }

  const handlePartitionCancel = partitionPk => () => {
    onPartitionCancel(partitionPk)
  }

  const handlePartitionSave = (partition, index) => () => {
    onPartitionSave(partition, index)
  }

  const handlePartitionEdit = partitionPk => () => {
    onPartitionEdit(partitionPk)
  }

  const getActionsByType = exhibitType => {
    if (exhibitType === PARTITIONED_EXHIBIT) {
      return {
        onDelete: handlePartitionDelete,
        onDownload: handlePartitionDownload,
        onFileNameChange: handlePartitionFieldChange,
        onFileTypeChange: handlePartitionFieldChange,
        onCancel: handlePartitionCancel,
        onSave: handlePartitionSave,
        onEdit: handlePartitionEdit,
      }
    }

    return {
      onDelete: handleExhibitDelete,
      onDownload: handleExhibitDownload,
      onFileNameChange: handleExhibitFieldChange,
      onFileTypeChange: handleExhibitFieldChange,
      onCancel: handleExhibitCancel,
      onSave: handleExhibitSave,
      onEdit: handleExhibitEdit,
    }
  }

  return (
    <>
      <FileDropzone onDrop={onDrop} disabled={disabled} />

      {Boolean(filesToUpload?.length) && (
        <FilesToUpload>
          {filesToUpload.map((file, index) => (
            <Box key={file.formId}>
              <FileToUpload
                originalFileName={file.file.name}
                fileName={file.name}
                onFileNameChange={handleFileFieldChange("name", index)}
                fileNameError={filesToUploadValidationErrors?.[index]?.name}
                fileType={file.type}
                onFileTypeChange={handleFileFieldChange("type", index)}
                fileTypeError={filesToUploadValidationErrors?.[index]?.type}
                onCancel={handleFileCancel(file.formId)}
                disabled={disabled || file.uploading}
                button={
                  <TextButton
                    onClick={handleFileUpload(file, index)}
                    disabled={disabled || file.uploading}
                    data-test="upload-file"
                  >
                    {file.uploading ? "Uploading" : "Upload"}
                  </TextButton>
                }
              />
              {Boolean(file.error) && (
                <Box mt={0.5} pr={2.5}>
                  <Alert severity="error">
                    Error uploading &quot;{file.name}&quot;. Please ensure the file is not corrupt and try
                    again.
                  </Alert>
                </Box>
              )}
            </Box>
          ))}
          <Box pr={4} textAlign="right">
            <Typography variant="caption">Un-uploaded files will be uploaded on save</Typography>
          </Box>
        </FilesToUpload>
      )}

      {Boolean(exhibits?.length) && (
        <DragDropContext
          onDragEnd={({ destination, source }) => {
            // dropped outside the list or moved to same position
            if (!destination || destination.index === source.index) return

            const newExhibits = reorderImmutable(exhibits, source.index, destination.index)
            onExhibitReorder(newExhibits)
          }}
        >
          <Droppable droppableId={`provider-exhibits-edit-${providerId}`}>
            {droppableProvided => (
              <Exhibits {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                {exhibits.map((exhibit, index) => {
                  const isPartitionedExhibit =
                    isProviderAutofillEnabled && exhibit.exhibitType === PARTITIONED_EXHIBIT
                  const {
                    onDelete,
                    onDownload,
                    onFileNameChange,
                    onFileTypeChange,
                    onCancel,
                    onSave,
                    onEdit,
                  } = getActionsByType(exhibit.exhibitType)

                  return (
                    <DraggableFile
                      key={exhibit.pk}
                      id={String(exhibit.pk)}
                      index={index}
                      fileName={exhibit.name}
                      fileType={exhibit.type}
                      editing={exhibit.editing}
                      disabled={disabled || exhibit.saving}
                      onDelete={onDelete(exhibit)}
                      onDownload={onDownload(exhibit)}
                      onFileNameChange={onFileNameChange("name", index)}
                      onFileTypeChange={onFileTypeChange("type", index)}
                      onCancel={onCancel(exhibit.pk)}
                      onSave={onSave(exhibit, index)}
                      onEdit={onEdit(exhibit.pk)}
                      validationError={exhibitsValidationErrors?.[index]}
                      editable={true}
                      fileTypeMap={MEDICAL_FILE_OPTIONS}
                      tagLabel={!isPartitionedExhibit ? "" : AUTO_IMPORT}
                      highlightOnHover={isProviderAutofillEnabled}
                      exhibitType={exhibit.exhibitType}
                      onClick={(exhibitId, exhibitType) => {
                        if (!isProviderAutofillEnabled) {
                          return
                        }

                        if (exhibitType === EXHIBIT) {
                          return openPDFViewerWindow({ exhibitId })
                        }
                        if (exhibitType === PARTITIONED_EXHIBIT) {
                          return openPDFViewerWindow({
                            exhibitId: exhibit?.exhibit_id,
                            partitionId: exhibit.pk,
                            page: exhibit?.start_page,
                          })
                        }
                      }}
                    />
                  )
                })}
                {droppableProvided.placeholder}
              </Exhibits>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  )
}
