import React, { forwardRef } from "react"
import { Button, ButtonProps } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import getTextColorFromTheme, { ButtonColor } from "./getTextColorFromTheme"

const useStyles = makeStyles(theme => ({
  addButton: {
    color: ({ textColor }: { textColor: ButtonColor }) => {
      return getTextColorFromTheme({ theme, textColor })
    },
    letterSpacing: "0.3px",
    fontWeight: "bold",
  },
}))

export interface TextButtonProps extends ButtonProps, TestableComponentProps {
  textColor?: ButtonColor
}

const TextButton: React.FC<TextButtonProps> = forwardRef(function TextButton(
  { children, className, textColor, ...buttonProps },
  ref
) {
  const classes = useStyles({ textColor: textColor ?? "blue" })
  return (
    <Button className={clsx(classes.addButton, className)} {...buttonProps} ref={ref}>
      {children}
    </Button>
  )
})

export default TextButton
