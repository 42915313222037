import React from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core"

const ConfirmDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  body = "",
  cancelText = "Cancel",
  confirmText = "Confirm",
}) => {
  return (
    <Dialog open={open} onClose={onClose} data-test="confirm-dialog">
      <DialogTitle>{title}</DialogTitle>
      {body && (
        <DialogContent>
          <DialogContentText data-test="confirm-dialog-body">{body}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={onClose} data-test="cancel-dialog-button">
          {cancelText}
        </Button>
        <Button color="secondary" onClick={onConfirm} data-test="confirm-dialog-button">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { ConfirmDialog as default }
