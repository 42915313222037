import React, { useCallback } from "react"
import { DEFAULT_PAGE_SIZE } from "common/models/pagination"
import { SelectProps } from "@material-ui/core"
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons"
import { StyledDropdownValue, StyledFormControl, StyledMenuItem, StyledSelect } from "./styled"

interface PageSizeSelectProps {
  pageSize?: number
  onChange: (pageSize: number) => void
}

const PageSizeOptions: ValueOptions<number> = [
  { key: DEFAULT_PAGE_SIZE, display: `${DEFAULT_PAGE_SIZE}` },
  { key: 25, display: "25" },
  { key: 50, display: "50" },
  { key: 100, display: "100" },
]

export function PageSizeSelect({ pageSize = DEFAULT_PAGE_SIZE, onChange }: PageSizeSelectProps): JSX.Element {
  const handleChange = useCallback<Required<SelectProps>["onChange"]>(
    event => {
      onChange((event.target.value as number) || DEFAULT_PAGE_SIZE)
    },
    [onChange]
  )

  const renderValue = useCallback(
    (value: unknown) => <StyledDropdownValue>{value} per page</StyledDropdownValue>,
    []
  )

  return (
    <StyledFormControl variant="outlined" size="small" margin="none">
      <StyledSelect
        value={pageSize || DEFAULT_PAGE_SIZE}
        onChange={handleChange}
        IconComponent={ExpandMoreIcon}
        renderValue={renderValue}
      >
        {PageSizeOptions.map(({ key, display }) => (
          <StyledMenuItem key={key} value={key} aria-label={display}>
            {display}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  )
}
