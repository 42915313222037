import React, { useState } from "react"

import { Exhibit } from "./Providers/types"
import { downloadExhibit, updateExhibit } from "../api"
import { useHandleMessages } from "../common/messages/useHandleMessages"
import UploadedFile from "../common/form-components/files/UploadedFile"
import { FileValidationError, validateFile } from "./validation"
import splitFileName from "common/form-components/files/splitFileName"
import { MESSAGE_TOPIC } from "message-broker/topics"
import { usePublish } from "message-broker/usePublish"
import { EXHIBIT_API_PATHS, exhibitService } from "api/services/exhibit"
import { getUrl } from "apiHelper"

interface Props {
  caseId: number
  exhibit: Exhibit
  onDeleteExhibit: () => void
  onSuccessfulSave: () => void
  fileTypeMap: Record<string, string>
}

const UploadedExhibit = ({
  exhibit,
  caseId,
  onDeleteExhibit,
  onSuccessfulSave,
  fileTypeMap,
}: Props): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [exhibitName, setExhibitName] = useState(exhibit.name)
  const [exhibitType, setExhibitType] = useState(exhibit.type)
  const [error, setError] = useState<FileValidationError>(null)
  const { showMessage } = useHandleMessages()

  const handleDownloadFile = async (fileName: string) => {
    try {
      await downloadExhibit({ caseId, exhibitId: exhibit.pk, fileName, downloadFile: true })
    } catch (err) {
      showMessage({ type: "error", message: "Error downloading exhibit." })
    }
  }

  const [, extension] = splitFileName(exhibitName ?? "")
  const isPdf = extension.toLowerCase() === ".pdf"

  const publish = usePublish({
    onError: reason =>
      showMessage({
        type: "error",
        message: `There was an error while sending message: ${reason}`,
      }),
  })

  const handlePreviewFile = () => {
    const url = getUrl(exhibitService.getPath({ caseId, exhibitId: exhibit.pk }, EXHIBIT_API_PATHS.DOWNLOAD))
    const reply = publish(MESSAGE_TOPIC.RENDER_PDF, {
      fileName: exhibitName,
      url,
      withCredentials: true,
    })

    reply.catch(reason =>
      showMessage({
        type: "error",
        message: `Unable to preview exhibit: ${reason}`,
      })
    )
  }

  const handleReset = () => {
    setExhibitName(exhibit.name)
    setExhibitType(exhibit.type)
    setError(null)
    setIsEditing(false)
  }

  const handleExhibitSave = async () => {
    // reset any previous errors
    setError(null)

    // fileToUpload and exhibit share the same name + type requirements
    const validationError = validateFile({ name: exhibitName, type: exhibitType })

    if (validationError) {
      setError(validationError)
      return
    }

    setIsSaving(true)

    try {
      await updateExhibit({
        caseId,
        exhibitId: exhibit.pk,
        data: {
          case_id: caseId,
          pk: exhibit.pk,
          name: exhibitName,
          type: exhibitType,
        },
      })
    } catch (error) {
      // no possible validation errors since exhibit name is validated by front end and type is a select with no blank options
      showMessage({
        type: "error",
        message:
          "Error updating exhibit. Please try again shortly and if your problem persists contact a dev.",
      })
      setIsSaving(false)
      return
    }

    showMessage({
      type: "success",
      message: "Successfully updated exhibit.",
    })
    setIsSaving(false)
    setIsEditing(false)
    onSuccessfulSave()
  }

  return (
    <UploadedFile
      id={exhibit.pk}
      editing={isEditing}
      fileName={exhibitName}
      fileType={exhibitType}
      fileTypeMap={fileTypeMap}
      onDelete={onDeleteExhibit}
      onDownload={handleDownloadFile}
      onClick={isPdf ? handlePreviewFile : undefined}
      disabled={isSaving}
      onFileNameChange={newName => setExhibitName(newName)}
      onFileTypeChange={newType => setExhibitType(newType)}
      onSave={handleExhibitSave}
      onEdit={() => setIsEditing(true)}
      onCancel={handleReset}
      validationError={error}
      highlightOnHover={isPdf}
      editable
    />
  )
}

export { UploadedExhibit as default }
