import React, { useMemo, useReducer, useRef, useState, useCallback } from "react"
import { useQuery } from "react-query"
import { queryKeys } from "react-query/constants"
import { Loading } from "common"
import { Pagination } from "common/pagination"
import { PageSizeSelect } from "common/pagination/PageSizeSelect"
import { DEFAULT_PAGE_SIZE } from "common/models/pagination"
import { SectionTemplatesTableViewModel } from "common/models/library"
import { useSectionTemplateForm } from "common/template-form/useSectionTemplateForm"
import { sectionTemplateService } from "api/services/section-template"
import { UPDATE_TEMPLATE_FORM_STATE_MESSAGES } from "common/template-form/constants"
import useUser from "hooks/useUser"
import { canEditLibrary } from "settings/permissions"
import { templatesTabReducer } from "../State/templatesReducer"
import { INITIAL_TEMPLATES_TAB_STATE, LibraryTabStateContext } from "../State/constants"
import { NewSectionTemplate } from "../Forms/NewSectionTemplate"
import { LibraryTable } from "../LibraryTable"
import { useAttributes, LibraryFilters, useFilterValues } from "../Filters"
import { StyledFiltersWrapper, StyledPageSizeWrapper, StyledSeparator } from "./styled"

export function LibraryTemplates(): Nullable<JSX.Element> {
  const [state, dispatch] = useReducer(templatesTabReducer, INITIAL_TEMPLATES_TAB_STATE)
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])
  const attributes = useAttributes()
  const { section, attributeValues } = useFilterValues()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)
  const { user } = useUser()
  const canEdit = canEditLibrary(user.role)

  const templatesRef = useRef<Awaited<ReturnType<typeof sectionTemplateService.getTemplatesList>>>()
  const { isFetching } = useQuery(
    [queryKeys.sectionTemplates, currentPage, pageSize, section, attributeValues],
    () => sectionTemplateService.getTemplatesList({ page: currentPage, pageSize, section, attributeValues }),
    {
      meta: {
        disableLoader: true,
      },
      enabled: Boolean(attributes),
      onSuccess: nextTemplates => {
        templatesRef.current = nextTemplates
      },
    }
  )

  const resetCurrentPage = () => {
    setCurrentPage(1)
  }

  const handleChangeFilters = useCallback(() => {
    resetCurrentPage()
  }, [])

  const handleChangePageSize = useCallback((pageSize: number) => {
    resetCurrentPage()
    setPageSize(pageSize)
  }, [])

  if (!templatesRef.current && !attributes) return null

  if (!attributes) return <Loading show label="Loading attributes" />

  if (!templatesRef.current) {
    return (
      <LibraryTabStateContext.Provider value={contextValue}>
        <StyledFiltersWrapper>
          <LibraryFilters />
        </StyledFiltersWrapper>
        <Loading show label="Loading templates..." />
      </LibraryTabStateContext.Provider>
    )
  }

  const templatesTableData = new SectionTemplatesTableViewModel(templatesRef.current.items, attributes)

  return (
    <LibraryTabStateContext.Provider value={contextValue}>
      <StyledFiltersWrapper>
        <LibraryFilters onFilterChange={handleChangeFilters} />
        <StyledPageSizeWrapper>
          <PageSizeSelect pageSize={pageSize} onChange={handleChangePageSize} />
        </StyledPageSizeWrapper>
      </StyledFiltersWrapper>
      {canEdit ? <NewSectionTemplate /> : <StyledSeparator />}
      <LibraryTable
        tableData={templatesTableData}
        loading={isFetching}
        getFormData={useSectionTemplateForm}
        entityName="templates"
        formStatusMessageMap={UPDATE_TEMPLATE_FORM_STATE_MESSAGES}
      />
      {templatesRef.current.count > 0 && (
        <Pagination
          pageCount={templatesRef.current.pageCount}
          page={templatesRef.current.page}
          count={templatesRef.current.count}
          onChange={setCurrentPage}
        />
      )}
    </LibraryTabStateContext.Provider>
  )
}
