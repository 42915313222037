import React, { useCallback } from "react"
import { Box, IconButton, TextField, Button } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"

import { SECTIONS } from "missing-docs/constants"
import MissingDocumentSection from "demand/MissingDocumentSection"
import * as actionTypes from "demand/Providers/reducer"
import { validateFile } from "demand/validation"
import { UPDATE_PROVIDER_NAME } from "demand/Providers/reducer"

import { BillsSection } from "./BillsSection/BillsSection"
import { InjuryDetailsSection } from "./InjuryDetailsSection/InjuryDetailsSection"
import { AppointmentSection } from "./AppointmentSection"
import { FilesSection } from "./FilesSection"
import { updateImmutable } from "../utils"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { useFormContext } from "demand/context"
import { noop } from "lodash"
import { ProviderTemplateSelector } from "./ProviderTemplateSelector"
import { StyledInputWrapper } from "./styled"
import { EXHIBIT, PARTITIONED_EXHIBIT, UPDATE_TYPES } from "demand/Providers/constants"
import { useFeatures, FEATURES } from "hooks/useFeatures"
import { formatDate } from "utils"

const useStyles = makeStyles(theme => ({
  providerOpenTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 3, 0, 6),
    fontSize: "18px",
    fontWeight: "bold",
    letterSpacing: "-.36px",
    color: theme.palette.getContrastText(theme.palette.blue.main),
    backgroundColor: theme.palette.blue.main,
  },
  providerNameInput: {
    maxWidth: "540px",
    flexGrow: 1,
  },
  rightButtonGroup: {
    display: "flex",
    gap: theme.spacing(2),
  },
}))

export const ProviderForm = ({
  provider,
  validationErrors,
  onChange,
  onDelete,
  onCancel,
  onSave,
  hasCollateralSourceRule,
  missingExhibits,
  onAppendExhibit,
  onExhibitReorder,
  onExhibitDelete,
  onExhibitDownload,
  onPartitionDownload,
  onPartitionDelete,
  uploadExhibit,
  dispatch,
  disabled,
  updateExhibitMutation,
  updatePartitionMutation,
  openPDFViewerWindow,
  updates,
}) => {
  const { caseId } = useFormContext()
  const { showMessage } = useHandleMessages()
  const classes = useStyles()
  const { isFeatureEnabled } = useFeatures()
  const isProviderAutofillEnabled = isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL)

  const handleChangeName = event => {
    dispatch({
      type: UPDATE_PROVIDER_NAME,
      payload: { providerId: provider.pk, name: event.target.value },
    })
  }

  const handleFilesToUploadChange = newFiles => {
    dispatch({
      type: actionTypes.UPDATE_FILES_TO_UPLOAD,
      payload: { id: provider.pk, filesToUpload: newFiles },
    })
  }

  const handleDrop = files => {
    dispatch({ type: actionTypes.ADD_FILES_TO_UPLOAD, payload: { id: provider.pk, files } })
  }

  const handleFileCancel = fileFormId => {
    if (
      provider.bills?.some(bill => bill.file_to_upload_id === fileFormId) &&
      !confirm(
        "One or more bills have this file attached. If you delete it you will need to choose a new file for those bills. Would you like to delete this file?"
      )
    ) {
      return
    }

    dispatch({
      type: actionTypes.DELETE_FILE_TO_UPLOAD,
      payload: { id: provider.pk, fileFormId },
    })
  }

  const handleFileUpload = async (file, index) => {
    const fileValidation = validateFile(file)

    if (fileValidation) {
      dispatch({
        type: actionTypes.SET_FILE_VALIDATION,
        payload: { validationError: fileValidation, index, providerPk: provider.pk },
      })

      return
    }

    dispatch({
      type: actionTypes.FILE_UPLOAD_START,
      payload: { formId: file.formId, providerPk: provider.pk },
    })

    let exhibit = null

    try {
      exhibit = await uploadExhibit({ file, providerId: provider.pk })
    } catch (error) {
      dispatch({
        type: actionTypes.FILE_UPLOAD_ERROR,
        payload: { formId: file.formId, providerPk: provider.pk, error },
      })

      showMessage({ type: "error", message: `Error uploading file ${file.name}` })

      return
    }

    dispatch({
      type: actionTypes.FILE_UPLOAD_SUCCESS,
      payload: { caseId, formId: file.formId, providerPk: provider.pk, exhibit },
    })
  }

  const handleExhibitsChange = newExhibits => {
    dispatch({
      type: actionTypes.UPDATE_EXHIBITS,
      payload: { caseId, providerPk: provider.pk, exhibits: newExhibits },
    })
  }

  const handleExhibitSave = async (exhibit, index) => {
    // fileToUpload and exhibit share the same name + type requirements
    const validationError = validateFile(exhibit)

    if (validationError) {
      dispatch({
        type: actionTypes.EXHIBIT_SAVE_ERROR,
        payload: { validationError, index, providerPk: provider.pk },
      })
      return
    }

    dispatch({
      type: actionTypes.EXHIBIT_SAVE_START,
      payload: { providerPk: provider.pk, exhibitPk: exhibit.pk },
    })

    let savedExhibit

    try {
      savedExhibit = await updateExhibitMutation.mutateAsync({
        caseId,
        exhibitId: exhibit.pk,
        data: {
          case_id: caseId,
          pk: exhibit.pk,
          name: exhibit.name,
          type: exhibit.type,
        },
      })
    } catch (error) {
      // no possible validation errors since exhibit name is validated by front end and type is a select with no blank options
      dispatch({
        type: actionTypes.EXHIBIT_SAVE_ERROR,
        payload: { validationError: null, index, providerPk: provider.pk },
      })

      showMessage({
        type: "error",
        message:
          "Error updating exhibit. Please try again shortly and if your problem persists contact a dev.",
      })

      return
    }

    dispatch({
      type: actionTypes.EXHIBIT_SAVE_SUCCESS,
      payload: {
        caseId,
        providerPk: provider.pk,
        exhibitPk: exhibit.pk,
        exhibit: savedExhibit,
        exhibitType: PARTITIONED_EXHIBIT,
      },
    })

    const newExhibits = [...provider.exhibits]

    newExhibits[index] = savedExhibit

    onChange(updateImmutable(provider, { exhibits: newExhibits }))
    showMessage({
      type: "success",
      message: "Exhibit Saved Successfully",
    })
  }

  const handleExhibitEditing = exhibitPk => {
    dispatch({
      type: actionTypes.SET_EXHIBIT_EDITING,
      payload: {
        providerPk: provider.pk,
        exhibitPk,
        editing: true,
        exhibitType: isProviderAutofillEnabled ? EXHIBIT : undefined,
        isProviderAutofillEnabled: isProviderAutofillEnabled,
      },
    })
  }

  const handlePartitionsChange = newExhibits => {
    dispatch({
      type: actionTypes.UPDATE_EXHIBITS,
      payload: { caseId, providerPk: provider.pk, exhibits: newExhibits },
    })
  }

  const handlePartitionSave = async (partition, index) => {
    // fileToUpload and exhibit share the same name + type requirements
    const validationError = validateFile(partition)

    if (validationError) {
      dispatch({
        type: actionTypes.EXHIBIT_SAVE_ERROR,
        payload: { validationError, index, providerPk: provider.pk },
      })
      return
    }

    dispatch({
      type: actionTypes.EXHIBIT_SAVE_START,
      payload: { providerPk: provider.pk, exhibitPk: partition.pk, exhibitType: PARTITIONED_EXHIBIT },
    })

    let savedPartition

    try {
      savedPartition = await updatePartitionMutation.mutateAsync({
        exhibitId: partition.exhibit_id,
        partitionId: partition.pk,
        data: {
          pk: partition.pk,
          name: partition.name,
          type: partition.type,
        },
      })
    } catch (error) {
      // no possible validation errors since exhibit name is validated by front end and type is a select with no blank options
      dispatch({
        type: actionTypes.EXHIBIT_SAVE_ERROR,
        payload: { validationError: null, index, providerPk: provider.pk },
      })

      showMessage({
        type: "error",
        message:
          "Error updating partition. Please try again shortly and if your problem persists contact a dev.",
      })

      return
    }

    savedPartition.exhibitType = PARTITIONED_EXHIBIT

    dispatch({
      type: actionTypes.EXHIBIT_SAVE_SUCCESS,
      payload: {
        caseId,
        providerPk: provider.pk,
        exhibitPk: partition.pk,
        exhibit: savedPartition,
        exhibitType: isProviderAutofillEnabled ? EXHIBIT : undefined,
      },
    })

    const newExhibits = [...provider.exhibits]

    newExhibits[index] = savedPartition

    onChange(updateImmutable(provider, { exhibits: newExhibits }))
    showMessage({
      type: "success",
      message: "Exhibit Saved Successfully",
    })
  }

  const handlePartitionEditing = partitionId => {
    dispatch({
      type: actionTypes.SET_EXHIBIT_EDITING,
      payload: {
        providerPk: provider.pk,
        exhibitPk: partitionId,
        editing: true,
        exhibitType: isProviderAutofillEnabled ? PARTITIONED_EXHIBIT : undefined,
        isProviderAutofillEnabled: isProviderAutofillEnabled,
      },
    })
  }

  const handleApprove = useCallback(
    data => onAppendExhibit(data, provider.pk),
    [provider.pk, onAppendExhibit]
  )

  const handleProviderDelete = provider => () => onDelete(provider)

  const getDateUpdatedByRecordType = useCallback(
    recordType => {
      if (!updates) {
        return null
      }

      const icdCodeUpdatedDate =
        updates.find(update => {
          return update.record_type === recordType
        })?.created_at ?? null

      if (!icdCodeUpdatedDate) {
        return null
      }

      return formatDate(new Date(icdCodeUpdatedDate), "MM/dd/yyyy") ?? null
    },
    [updates]
  )

  return (
    <Box data-test="provider-form">
      <Box className={classes.providerOpenTitle}>
        <Box>{`Editing: ${provider.name ?? "Provider"}`}</Box>
        <IconButton disabled={disabled} color="inherit" onClick={onCancel}>
          <Close />
        </IconButton>
      </Box>
      <Box p={6}>
        <StyledInputWrapper>
          <TextField
            label="Provider Name"
            value={provider.name ?? ""}
            data-test="provider-name"
            onChange={handleChangeName}
            variant="outlined"
            inputProps={{ name: "name" }}
            className={classes.providerNameInput}
            disabled={disabled}
            error={Boolean(validationErrors["name"])}
            helperText={validationErrors["name"]}
          />
          <ProviderTemplateSelector disabled={disabled} provider={provider} caseId={caseId} />
        </StyledInputWrapper>
        <FilesSection
          providerId={provider.pk}
          disabled={disabled}
          filesToUpload={provider.filesToUpload ?? []}
          filesToUploadValidationErrors={validationErrors.filesToUpload}
          exhibits={provider.exhibits ?? []}
          exhibitsValidationErrors={validationErrors.exhibits}
          onFilesToUploadChange={handleFilesToUploadChange}
          onDrop={handleDrop}
          onFileCancel={handleFileCancel}
          onFileUpload={handleFileUpload}
          onExhibitReorder={onExhibitReorder}
          onExhibitDelete={onExhibitDelete}
          onExhibitDownload={onExhibitDownload}
          onExhibitsChange={handleExhibitsChange}
          onExhibitCancel={noop}
          onExhibitSave={handleExhibitSave}
          onExhibitEdit={handleExhibitEditing}
          onPartitionDownload={onPartitionDownload}
          onPartitionDelete={onPartitionDelete}
          onPartitionChange={handlePartitionsChange}
          onPartitionCancel={noop}
          onPartitionEdit={handlePartitionEditing}
          onPartitionSave={handlePartitionSave}
          openPDFViewerWindow={openPDFViewerWindow}
        />
        <Box mt={2}>
          <MissingDocumentSection
            missingDocs={missingExhibits}
            section={SECTIONS.PROVIDERS}
            title="Missing Documents List"
            providerId={provider.pk}
            canEditExistingProvider={false}
            canSelectProvider={Boolean(provider.pk)}
            onApproveCallback={handleApprove}
          />
        </Box>

        <AppointmentSection
          dispatch={dispatch}
          disabled={disabled}
          provider={provider}
          validationErrors={validationErrors}
          annotationDateUpdated={getDateUpdatedByRecordType(UPDATE_TYPES.APPOINTMENTS)}
          onAnnotationClick={openPDFViewerWindow}
        />
        <BillsSection
          dispatch={dispatch}
          validationErrors={validationErrors}
          provider={provider}
          hasCollateralSourceRule={hasCollateralSourceRule}
          disabled={disabled}
          annotationDateUpdated={getDateUpdatedByRecordType(UPDATE_TYPES.BILLS)}
          onAnnotationClick={openPDFViewerWindow}
        />
        <InjuryDetailsSection
          caseId={caseId}
          disabled={disabled}
          provider={provider}
          dispatch={dispatch}
          annotationDateUpdated={getDateUpdatedByRecordType(UPDATE_TYPES.APPOINTMENTS)}
          onAnnotationClick={openPDFViewerWindow}
        />

        <Box display="flex" justifyContent="space-between">
          <Box>
            <Button
              variant="contained"
              color="secondary"
              data-test="delete-provider"
              disableElevation
              disabled={disabled}
              onClick={handleProviderDelete(provider)}
            >
              {provider.deleting ? "Deleting" : "Delete Provider"}
            </Button>
          </Box>
          <Box className={classes.rightButtonGroup}>
            <Button
              variant="contained"
              color="default"
              disableElevation
              disabled={disabled}
              onClick={onCancel}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              data-test="save-provider"
              disableElevation
              disabled={disabled}
              onClick={onSave}
            >
              {provider.saving ? "Saving" : "Save"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
