import { union } from "lodash"
import { DEFAULT_AWARD_AMOUNT_RANGES, DEFAULT_DECISION_TYPES } from "./constants"

export const INITIAL_STATE = {
  free_text_query: "",
  query: {
    total_settlement_amount: JSON.stringify(DEFAULT_AWARD_AMOUNT_RANGES),
    case_decision_type: DEFAULT_DECISION_TYPES,
  },
  facets: ["state", "county", "high_level_categories", "case_decision_type"],
  sort: {},
  injury_to_facts_boosts: 0,
  page: 0,
}

export const RANGE_FACETS = {
  NUM_FACETS: ["total_settlement_amount"],
  STR_FACETS: ["case_decision_date"],
}

export const ONTOLOGY_FACET = "injury_tags"

export const actions = {
  SET_INITIAL_STATE_PARAMS: "SET_INITIAL_STATE_PARAMS",
  UPDATE_QUERY_TEXT: "UPDATE_QUERY_TEXT",
  APPLY_FACET: "APPLY_FACET",
  APPLY_RANGE_FACET: "APPLY_RANGE_FACET",
  RESET_RANGE_FACET: "RESET_RANGE_FACET",
  REMOVE_FACET: "REMOVE_FACET",
  REMOVE_FACET_BY_INDEX: "REMOVE_FACET_BY_INDEX",
  REMOVE_ALL_IN_FACET: "REMOVE_ALL_IN_FACET",
  SET_PAGE: "SET_PAGE",
  SET_SORT: "SET_SORT",
  SET_BOOST: "SET_BOOST",
}

export const QueryReducer = (state, { action, payload }) => {
  switch (action) {
    case actions.SET_INITIAL_STATE_PARAMS:
      state.free_text_query = payload.free_text_query
      state.query = { ...state.query, ...payload.query }
      return

    case actions.UPDATE_QUERY_TEXT:
      state.free_text_query = payload
      state.page = INITIAL_STATE.page
      state.boost = INITIAL_STATE.injury_to_facts_boosts
      return

    case actions.APPLY_FACET:
      state.query[payload.name]
        ? (state.query[payload.name] = union(state.query[payload.name], [payload.value]))
        : (state.query[payload.name] = [payload.value])

      state.page = INITIAL_STATE.page
      return

    case actions.REMOVE_FACET: {
      const facetIndex = state.query[payload.name].findIndex(facet => facet === payload.value)

      if (facetIndex >= 0) {
        state.query[payload.name].splice(facetIndex, 1)
      }

      if (!state.query[payload.name].length) {
        delete state.query[payload.name]
      }

      state.page = INITIAL_STATE.page
      return
    }
    case actions.REMOVE_FACET_BY_INDEX: {
      const facetIndex = payload.value
      if (facetIndex >= 0) {
        state.query[payload.name].splice(facetIndex, 1)
      }

      if (!state.query[payload.name].length) {
        delete state.query[payload.name]
      }

      state.page = INITIAL_STATE.page
      return
    }
    case actions.REMOVE_ALL_IN_FACET: {
      delete state.query[payload]

      state.page = INITIAL_STATE.page
      return
    }
    case actions.APPLY_RANGE_FACET:
      state.query[payload.name] = payload.value
      state.page = INITIAL_STATE.page
      return

    case actions.RESET_RANGE_FACET:
      delete state.query[payload]

      state.page = INITIAL_STATE.page
      return

    case actions.SET_PAGE:
      state.page = payload
      return

    case actions.SET_SORT:
      state.sort = payload
      state.page = INITIAL_STATE.page
      return
    case actions.SET_BOOST:
      state.injury_to_facts_boosts = payload
      state.page = INITIAL_STATE.page
      return
  }
}
