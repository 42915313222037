import { handleEmptyResponse } from "api/utils"
import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"
import { RawModel, toCamelCase, toSnakeCase } from "common/helpers/object"
import { isUndefined } from "lodash"
import { apiService } from "./ApiService"
import { ApiServiceType } from "./types"

interface FileServiceOptions {
  uploadId?: string
}

type FileServiceArgs<T> = T extends null
  ? FileServiceOptions
  : FileServiceOptions & {
      data: T
    }

interface FileUploadData {
  chunk_count: number
  content_type: string
  size: number
}

interface FileUploadDto extends FileUploadData {
  id: string
  path: string
}

type FileUpload = RawModel<FileUploadDto>

interface ChunkUpload {
  file: Blob
  offset: number
  upload: string
}

class FileUploadDeserializer {
  static fromJSON(fileUpload: FileUploadDto): FileUpload {
    return toCamelCase(fileUpload)
  }
}

class FileUploadSerializer {
  static newToJSON(fileUpload: RawModel<FileUploadData>): FileUploadData {
    return toSnakeCase(fileUpload)
  }

  static chunkToFormData(chunk: ChunkUpload): FormData {
    const formData = new FormData()

    formData.append("file", chunk.file)
    formData.append("offset", String(chunk.offset))
    formData.append("upload", chunk.upload)

    return formData
  }
}

enum FILE_API_PATHS {
  BASE = "upload/upload",
  CHUNK = "uploadchunk",
}

class FileService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(options: FileServiceOptions, path?: FILE_API_PATHS): string {
    const pathParts = ["", FILE_API_PATHS.BASE, options.uploadId, path]
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  createFileUpload = withRequestSerializer(
    FileUploadSerializer.newToJSON,
    withResponseSerializer(
      FileUploadDeserializer.fromJSON,
      ({ data, ...options }: FileServiceArgs<FileUploadData>) => {
        const path = this.getPath(options)
        return handleEmptyResponse(this.apiService.create(data, path))
      }
    )
  )

  uploadChunk = withRequestSerializer(
    FileUploadSerializer.chunkToFormData,
    ({ data, ...options }: Required<FileServiceArgs<FormData>>) => {
      const path = this.getPath(options, FILE_API_PATHS.CHUNK)
      return this.apiService.submitForm(data, path)
    }
  )
}

export const fileService = new FileService(apiService)
