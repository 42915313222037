import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { getAuthHeaders } from "apiHelper"
import { Document, pdfjs } from "react-pdf"
import { Drawer, Hidden, IconButton } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api"
import { PdfLoadingPage } from "./PdfLoadingPage"
import { PagesWrapper, PageWrapper, PdfContainer, PdfDocumentContainer, PdfViewport } from "./styled"
import { PdfErrorPage } from "./PdfErrorPage"
import { PdfThumbnails } from "./Thumbnails"
import { PdfDocument } from "./PdfDocument"
import { PdfNavigationPanel } from "./PdfNavigationPanel"
import { PdfToolbar } from "./PdfToolbar"
import { US_LETTER_SIZE } from "./constants"
import { getPagesFromRanges } from "./utils"

import "react-pdf/dist/esm/Page/TextLayer.css"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

interface PdfDocumentSource {
  url: string
}

type PdfPagesRange = [number, number]

interface PdfDocumentSourceAuth {
  httpHeaders: Record<string, string>
  withCredentials: boolean
}

export interface PdfRendererProps {
  filename: string
  url: string
  withCredentials?: boolean
  currentFileName?: string
  page: number
  pageNumberAdjustment?: number
  deletedPages?: PdfPagesRange[]

  leftControls?: React.ReactElement
  rightControls?: React.ReactElement
}

export function PdfRenderer({
  filename,
  url,
  withCredentials = false,
  currentFileName,
  page = 1,
  pageNumberAdjustment = 0,
  leftControls,
  rightControls,
  deletedPages: deletedPageRanges = [],
}: PdfRendererProps): JSX.Element {
  const [currentPage, setCurrentPage] = useState(page)
  const [pdfDocument, setPdfDocument] = useState<Nullable<PDFDocumentProxy>>(null)
  const [pagesCount, setPagesCount] = useState(0)
  const [viewportWidth, setViewportWidth] = useState<Nullable<number>>(null)
  const pdfSource = useMemo<PdfDocumentSource | (PdfDocumentSource & PdfDocumentSourceAuth)>(() => {
    if (!withCredentials) {
      return { url }
    }

    return {
      url,
      httpHeaders: getAuthHeaders(),
      withCredentials: true,
    }
  }, [url, withCredentials])
  const viewportRef = useRef<HTMLDivElement>(null)
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(([entry]) => {
        if (!entry) return

        setViewportWidth(Math.floor(entry.contentRect.width * 0.9))
      }),
    []
  )
  const deletedPages = useMemo<Set<number>>(() => getPagesFromRanges(deletedPageRanges), [deletedPageRanges])

  const onDocumentLoadSuccess = useCallback((pdfDocument: PDFDocumentProxy) => {
    setPdfDocument(pdfDocument)
    setPagesCount(pdfDocument.numPages)
  }, [])

  const [mobileNav, setMobileNav] = useState(false)
  const handleNavigationToggle = useCallback(() => {
    setMobileNav(currentValue => !currentValue)
  }, [])

  const [scale, setScale] = useState<Nullable<number>>(null)
  const estimatedScale = viewportWidth ? Math.min(viewportWidth / US_LETTER_SIZE.WIDTH, 1) : 1
  const placeholderScale = scale === null ? estimatedScale : scale

  useEffect(() => {
    setCurrentPage(page)
  }, [page, url])

  useEffect(() => {
    if (!viewportRef.current) return

    const target = viewportRef.current
    resizeObserver.observe(target)

    return () => resizeObserver.unobserve(target)
  }, [resizeObserver])

  return (
    <PdfViewport>
      <PdfToolbar
        title={filename}
        subtitle={currentFileName ? `Current filename: ${currentFileName}` : undefined}
        pages={pagesCount || 1}
        page={currentPage}
        pageNumberAdjustment={pageNumberAdjustment}
        estimatedScale={estimatedScale}
        onScaleChange={setScale}
        leftControls={
          <>
            <Hidden mdUp>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                size="small"
                onClick={handleNavigationToggle}
              >
                <MenuIcon fontSize="small" />
              </IconButton>
            </Hidden>
            {leftControls}
          </>
        }
        rightControls={<>{rightControls}</>}
      />
      <PdfContainer data-test="pdf-preview-wrapper">
        <Hidden smDown>
          <PdfNavigationPanel>
            {pdfDocument && (
              <PdfThumbnails
                onPageSelect={setCurrentPage}
                document={pdfDocument}
                currentPage={currentPage}
                pageNumberAdjustment={pageNumberAdjustment}
                deletedPages={deletedPages}
              />
            )}
          </PdfNavigationPanel>
        </Hidden>
        <Hidden mdUp>
          <Drawer open={mobileNav} variant="temporary" onClick={handleNavigationToggle}>
            <PdfNavigationPanel>
              {pdfDocument && (
                <PdfThumbnails
                  onPageSelect={setCurrentPage}
                  document={pdfDocument}
                  currentPage={currentPage}
                  pageNumberAdjustment={pageNumberAdjustment}
                  deletedPages={deletedPages}
                />
              )}
            </PdfNavigationPanel>
          </Drawer>
        </Hidden>
        <PdfDocumentContainer ref={viewportRef}>
          {viewportWidth && (
            <Document
              file={pdfSource}
              className="pdf-wrapper"
              onLoadSuccess={onDocumentLoadSuccess}
              loading={
                <PagesWrapper>
                  <PageWrapper>
                    <PdfLoadingPage scale={placeholderScale} />
                  </PageWrapper>
                </PagesWrapper>
              }
              error={
                <PagesWrapper>
                  <PageWrapper>
                    <PdfErrorPage message="Failed to load pdf document..." scale={placeholderScale} />
                  </PageWrapper>
                </PagesWrapper>
              }
            >
              {pagesCount && (
                <PdfDocument
                  pagesCount={pagesCount}
                  currentPage={Math.min(currentPage, pagesCount)}
                  onPageChange={setCurrentPage}
                  viewportWidth={viewportWidth}
                  deletedPages={deletedPages}
                  scale={scale ?? undefined}
                />
              )}
            </Document>
          )}
        </PdfDocumentContainer>
      </PdfContainer>
    </PdfViewport>
  )
}
