export enum FONTS {
  ARIAL = "arial",
  CALIBRI = "calibri",
  CENTURY_GOTHIC = "century_gothic",
  CONSTANTIA = "constantia",
  GARAMOND = "garamond",
  TIMES_NEW_ROMAN = "times_new_roman",
}

export enum ALIGNMENT {
  LEFT = "Left",
  JUSTIFIED = "Justified",
  CENTER = "Center",
  RIGHT = "Right",
}

export enum CustomDamageSectionType {
  INCOME = "income",
  HOUSEHOLD = "household",
  PER_DIEM = "per_diem",
  MEDICAL = "medical",
  REL_CASE = "rel_case",
  FUTURE_EXPENSES = "future_expenses",
  CUSTOM = "custom",
}

export enum DamageType {
  ECONOMIC = "economic",
  NON_ECOMONIC = "non-economic",
}

export enum EXHIBIT_SORTING_VALUES {
  PER_PROVIDER_RECORDS_AND_BILLS = "1",
  PER_PROVIDER_BILLS_AND_RECORDS = "2",
  ALL_RECORDS_THEN_BILLS = "3",
  ALL_BILLS_THEN_RECORDS = "4",
}

export enum EXHIBIT_GROUPING_VALUES {
  INDIVIDUAL_FILES = "1",
  PER_PROVIDER_AND_FILETYPE = "4",
  PER_PROVIDER = "2",
  ONE_FILE = "3",
}
