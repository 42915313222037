import React, { useCallback } from "react"
import { Box, Divider, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { SECTIONS } from "missing-docs/constants"
import { amountInDollars, reorderImmutable, dateDisplay, formatDate } from "utils"
import DraggableFile from "common/form-components/files/DraggableFile"
import { deserializeFromMarkdown } from "common/form-components/rich-text/serializer/markdown/deserializer"
import { useFeatures, FEATURES } from "hooks/useFeatures"
import { AUTO_IMPORT, EXHIBIT, PARTITIONED_EXHIBIT, UPDATE_TYPES } from "demand/Providers/constants"
import { SectionHeading } from "./styled"
import MissingDocumentSection from "demand/MissingDocumentSection"
import { MEDICAL_FILE_OPTIONS } from "demand/constants"

import { getFilteredCodes } from "./utils"
import {
  Wrapper,
  UploadedFiles,
  NoItems,
  AppointmentsGrid,
  BillRow,
  Label,
  Italic,
  CodeList,
  CodeChip,
} from "./styled"
import { useProviderVariables } from "./useProviderVariables"
import { CaseEditor } from "demand/components/CaseEditor"
import Appointments from "./Annotation/Appointments/Appointments"
import Bills from "./Annotation/Bills/Bills"
import IcdCodes from "./Annotation/IcdCodes/IcdCodes"
import InjuryDetails from "./Annotation/InjuryDetails/InjuryDetails"
import SectionContainer from "./SectionContainer"

const useStyles = makeStyles(theme => ({
  billsGrid: {
    display: "grid",
    gridTemplateColumns: ({ hasCollateralSourceRule }) =>
      hasCollateralSourceRule ? "2fr 1fr auto" : "2fr 1fr 1fr auto",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  wordBreakAll: {
    flexGrow: 1,
    wordBreak: "break-word",
  },
}))

const ProviderDetails = ({
  provider,
  onExhibitReorder,
  onExhibitDelete,
  onExhibitDownload,
  onPartitionDelete,
  onPartitionDownload,
  hasCollateralSourceRule,
  missingExhibits,
  onAppendExhibit,
  caseId,
  updates,
  openPDFViewerWindow,
}) => {
  const classes = useStyles(hasCollateralSourceRule)
  const { isFeatureEnabled } = useFeatures()
  const isProviderAutofillEnabled = isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL)

  const handleExhibitDelete = exhibit => () => {
    onExhibitDelete(exhibit)
  }
  const handleExhibitDownload = exhibit => () => {
    return onExhibitDownload(exhibit)
  }
  const handlePartitionDelete = partition => {
    return onPartitionDelete(partition)
  }
  const handlePartitionDownload = partition => () => {
    return onPartitionDownload(partition)
  }

  const getDateUpdatedByRecordType = useCallback(
    recordType => {
      if (!updates) {
        return
      }

      const icdCodeUpdatedDate =
        updates.find(update => {
          return update.record_type === recordType
        })?.created_at ?? null

      if (!icdCodeUpdatedDate) {
        return
      }

      return formatDate(new Date(icdCodeUpdatedDate), "MM/dd/yyyy")
    },
    [updates]
  )

  const icdCodes = getFilteredCodes(provider?.icd_codes)
  const cptCodes = getFilteredCodes(provider?.cpt_codes)
  const variables = useProviderVariables(provider)

  const hasPartitionProvider = isProviderAutofillEnabled && !!provider.partition_provider
  const hasFiles = !!provider?.exhibits?.length

  return (
    <Wrapper>
      {hasFiles && (
        <DragDropContext
          onDragEnd={({ destination, source }) => {
            // dropped outside the list or moved to same position
            if (!destination || destination.index === source.index) return

            const newExhibits = reorderImmutable(provider.exhibits, source.index, destination.index)
            onExhibitReorder(newExhibits)
          }}
        >
          <Droppable droppableId={`provider-exhibits-view-${provider.pk}`}>
            {droppableProvided => (
              <UploadedFiles {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                {provider.exhibits.map((exhibit, index) => {
                  const isPartitionedExhibit =
                    isProviderAutofillEnabled && exhibit.exhibitType === PARTITIONED_EXHIBIT
                  return (
                    <DraggableFile
                      exhibit={exhibit}
                      index={index}
                      key={exhibit.pk}
                      id={exhibit.pk}
                      fileName={exhibit.name}
                      fileType={exhibit.type}
                      onDelete={
                        !isPartitionedExhibit
                          ? handleExhibitDelete(exhibit)
                          : () => {
                              handlePartitionDelete(exhibit)
                            }
                      }
                      onDownload={
                        !isPartitionedExhibit
                          ? handleExhibitDownload(exhibit)
                          : handlePartitionDownload(exhibit)
                      }
                      fileTypeMap={MEDICAL_FILE_OPTIONS}
                      editable={false}
                      tagLabel={isPartitionedExhibit ? AUTO_IMPORT : ""}
                      onClick={(exhibitId, exhibitType) => {
                        if (!isProviderAutofillEnabled) {
                          return
                        }

                        if (exhibitType === EXHIBIT) {
                          return openPDFViewerWindow({ exhibitId })
                        }
                        if (exhibitType === PARTITIONED_EXHIBIT) {
                          return openPDFViewerWindow({
                            exhibitId: exhibit?.exhibit_id,
                            partitionId: exhibit.pk,
                            page: exhibit?.start_page,
                          })
                        }
                      }}
                      highlightOnHover={isProviderAutofillEnabled && !!exhibit.exhibitType}
                      exhibitType={exhibit.exhibitType}
                    />
                  )
                })}
              </UploadedFiles>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {!provider?.exhibits?.length && (
        <Box mb={6}>
          <NoItems variant="outlined">No attached files. Edit provider to add some.</NoItems>
        </Box>
      )}
      <Box mb={2}>
        <MissingDocumentSection
          missingDocs={missingExhibits}
          section={SECTIONS.PROVIDERS}
          title="Missing Documents List"
          providerId={provider.pk}
          canEditExistingProvider={false}
          onApproveCallback={data => {
            onAppendExhibit(data, provider.pk)
          }}
        />
      </Box>
      <Divider />
      <SectionContainer>
        <Box>
          <SectionHeading mb={2}>Appointments</SectionHeading>
          <AppointmentsGrid>
            <Label>Date of first appointment</Label>
            <Label>Date of last appointment</Label>
            <Label>No. of visits</Label>
            <Box>{provider.is_ongoing_appointment ? <Italic>Treatment is ongoing</Italic> : ""}</Box>
            <Box>{dateDisplay(provider.first_contact, "N/A")}</Box>
            <Box>{dateDisplay(provider.last_contact, "N/A")}</Box>
            <Box>{provider.visit_count ?? "N/A"}</Box>
            <Box />
          </AppointmentsGrid>
        </Box>
        {hasPartitionProvider && (
          <Box>
            <Appointments
              providerId={provider.pk}
              caseId={caseId}
              currentFirstAppointment={provider.first_contact}
              currentLastAppointment={provider.last_contact}
              currentNumberOfVisits={provider.visit_count}
              dateUpdated={getDateUpdatedByRecordType(UPDATE_TYPES.APPOINTMENTS)}
              onEntryClick={openPDFViewerWindow}
            />
          </Box>
        )}
      </SectionContainer>
      <SectionContainer>
        <Box>
          <SectionHeading mb={2}>Bills</SectionHeading>
          {!!provider?.bills && (
            <Box className={classes.billsGrid}>
              <Label>Bill description</Label>
              <Label>Billed</Label>
              {!hasCollateralSourceRule && <Label>Adjusted</Label>}
              <Label>File</Label>
              {provider.bills.map(bill => (
                <BillRow key={bill.pk}>
                  <Box>{bill.description}</Box>
                  <Box>{amountInDollars(bill.billed_amount ?? 0)}</Box>
                  {!hasCollateralSourceRule && <Box>{amountInDollars(bill.adjusted_amount ?? 0)}</Box>}
                  <Box>{bill?.exhibit?.name || (isProviderAutofillEnabled && bill?.partition?.name)}</Box>
                </BillRow>
              ))}
            </Box>
          )}
          {!provider?.bills?.length && (
            <Box mt={1}>
              <NoItems variant="outlined">No bills. Edit provider to add some.</NoItems>
            </Box>
          )}
        </Box>
        {hasPartitionProvider && (
          <Box>
            <Bills
              providerId={provider.pk}
              caseId={caseId}
              currentBills={provider?.bills ?? []}
              dateUpdated={getDateUpdatedByRecordType(UPDATE_TYPES.BILLS)}
              onEntryClick={openPDFViewerWindow}
            />
          </Box>
        )}
      </SectionContainer>
      <Divider />
      <SectionContainer>
        <Box>
          <SectionHeading mb={2}>Injury Details from Provider</SectionHeading>
          <Box mb={3}>
            <Label mb={1}>ICD code/injuries</Label>
            {!!icdCodes.length && (
              <CodeList>
                {icdCodes.map(icd_code => (
                  <Tooltip placement="top" arrow title={icd_code.description} key={icd_code.pk}>
                    <CodeChip label={`${icd_code.code} - ${icd_code.description}`} />
                  </Tooltip>
                ))}
              </CodeList>
            )}
            {!icdCodes.length && (
              <NoItems variant="outlined">No ICD codes added. Edit provider to add some.</NoItems>
            )}
          </Box>
          <Box mb={5}>
            <Label mb={1}>CPT code/treatments</Label>
            {!!cptCodes.length && (
              <CodeList>
                {cptCodes.map(cpt_code => (
                  <Tooltip placement="top" arrow title={cpt_code.description} key={cpt_code.pk}>
                    <CodeChip label={`${cpt_code.code} - ${cpt_code.description}`} />
                  </Tooltip>
                ))}
              </CodeList>
            )}
            {!cptCodes.length && (
              <NoItems variant="outlined">No CPT codes added. Edit provider to add some.</NoItems>
            )}
          </Box>
        </Box>
        {hasPartitionProvider && (
          <Box>
            <IcdCodes
              caseId={caseId}
              providerId={provider.pk}
              currentIcdCodes={icdCodes}
              dateUpdated={getDateUpdatedByRecordType(UPDATE_TYPES.ICD_CODES)}
              onEntryClick={openPDFViewerWindow}
            />
          </Box>
        )}
      </SectionContainer>
      <SectionContainer>
        <Box className={classes.wordBreakAll}>
          <Label mb={2}>Injury details</Label>
          <CaseEditor
            readonly
            value={provider.details_json || deserializeFromMarkdown(provider.details) || null}
            variables={variables}
          />
        </Box>
        {hasPartitionProvider && (
          <Box>
            <InjuryDetails caseId={caseId} providerId={provider.pk} onEntryClick={openPDFViewerWindow} />
          </Box>
        )}
      </SectionContainer>
    </Wrapper>
  )
}

export default ProviderDetails
