import { States } from "app/constants"
import { Attributes as AttributesType, Attribute, AttributeValue } from "common/types/attributes"
import { COMMERCIAL, INDIVIDUAL, POLICY_COVERAGE_TYPE_KEYS } from "requests/constants"
import { RequestViewDto } from "requests/ViewRequest/types"
import { v4 } from "uuid"
import {
  CASE_TYPE_ATTRIBUTE,
  COVERAGE_ATTRIBUTE,
  JURISDICTION_ATTRIBUTE,
  POLICY_TYPE_ATTRIBUTE,
} from "./constants"
import { AttributeFiltersData, AttributeFilterValue } from "./types"

const StateNamesByTitle = Object.entries(States).reduce<Record<string, string>>((result, stateEntry) => {
  const [stateName, stateTitle] = stateEntry

  result[stateTitle] = stateName.replaceAll("_", " ").toLowerCase()

  return result
}, {})

export function getAttributeValues(
  attributes: AttributesType,
  currentValues: AttributeFiltersData = {}
): AttributeFiltersData {
  const nextValues: AttributeFiltersData = {}
  const items = attributes.getAttributesList(currentValues)

  for (const item of items) {
    nextValues[item.id] = item.values.some(({ id }) => id === currentValues[item.id])
      ? currentValues[item.id]
      : null
  }

  return nextValues
}

export const NONE_VALUE = `none_value_${v4()}` as const

type AttributeValueOptionKey = NonNullable<AttributeFilterValue> | typeof NONE_VALUE

interface AttributeValueOptionsCache {
  items: Map<AttributeValueOptionKey, AttributeValue["value"]>
  options: ValueOptions<AttributeValueOptionKey>
}

export const NONE_VALUE_OPTION: ValueOption<typeof NONE_VALUE> = {
  key: NONE_VALUE,
  display: "None",
}

export function getAttributeValueOptions(attribute: Attribute): AttributeValueOptionsCache {
  const optionsCache: AttributeValueOptionsCache = {
    items: new Map([[NONE_VALUE_OPTION.key, attribute.title]]),
    options: [NONE_VALUE_OPTION],
  }

  for (const { id, value } of attribute.values) {
    optionsCache.items.set(id, value)
    optionsCache.options.push({
      key: id,
      display: value,
    })
  }

  return optionsCache
}

export function getJurisdictionByState(attributes: AttributesType, state?: States): AttributeFiltersData {
  if (!state) return {}

  const attributeList = attributes.getAttributesList({})
  const stateName = StateNamesByTitle[state]
  const jurisdictionAttribute = attributeList.find(attribute => attribute.title === JURISDICTION_ATTRIBUTE)

  if (stateName && jurisdictionAttribute) {
    const attributeValue = jurisdictionAttribute.values.find(value => value.value.toLowerCase() === stateName)

    if (attributeValue) return { [jurisdictionAttribute.id]: attributeValue.id }
  }

  return {}
}

function matchCaseTypeAssetToAttribute(asset: string) {
  switch (asset) {
    case "Motor Vehicle Accident":
      return "MVA"

    case "Premise Liability":
      return "Premise Liability"

    case "Medical Malpractice":
      return "Medical Malpractice"

    case "Dog Bite":
      return "Animal Bite"

    case "Intentional Tort":
      return "Intentional Torts"

    default:
      return null
  }
}

function matchPolicyTypeToAttribute(type: POLICY_COVERAGE_TYPE_KEYS) {
  switch (type) {
    case POLICY_COVERAGE_TYPE_KEYS.bodily_injury:
      return "Bodily Injury"

    case POLICY_COVERAGE_TYPE_KEYS.underinsured_motorist:
      return "Underinsured Motorist (UIM)"

    case POLICY_COVERAGE_TYPE_KEYS.uninsured_motorist:
      return "Uninsured Motorist (UM)"

    case POLICY_COVERAGE_TYPE_KEYS.self_insured:
      return "Self-Insured"

    default:
      return null
  }
}

function matchPolicyCoverageTypeToAttribute(type: typeof INDIVIDUAL | typeof COMMERCIAL) {
  switch (type) {
    case INDIVIDUAL:
      return "Individual"

    case COMMERCIAL:
      return "Commercial"

    default:
      return null
  }
}

export function getCaseTypeByRequestInfo(
  attributes: AttributesType,
  caseTypeAsset: { key: string; display: string }[],
  requestInfo?: RequestViewDto
): AttributeFiltersData {
  if (!requestInfo) return {}

  if (requestInfo.case_type) {
    const attributeList = attributes.getAttributesList({})
    const caseTypeAttribute = attributeList.find(attribute => attribute.title === CASE_TYPE_ATTRIBUTE)
    const caseAsset = caseTypeAsset.find(asset => asset.key === requestInfo.case_type)
    const atributeValue = caseAsset && matchCaseTypeAssetToAttribute(caseAsset.display)
    const value =
      !!(atributeValue && caseTypeAttribute) &&
      caseTypeAttribute.values.find(caseAttributeValue => caseAttributeValue.value === atributeValue)

    if (value && caseTypeAttribute) {
      return { [caseTypeAttribute.id]: value.id }
    }
  }

  return {}
}

export function getPolicyType(
  attributes: AttributesType,
  requestInfo?: RequestViewDto
): AttributeFiltersData {
  if (!requestInfo) return {}

  if (requestInfo.policy_coverage_type) {
    const attributeList = attributes.getAttributesList({})
    const policyCoverageAttribute = attributeList.find(attribute => attribute.title === POLICY_TYPE_ATTRIBUTE)
    const atributeValue =
      policyCoverageAttribute && matchPolicyTypeToAttribute(requestInfo.policy_coverage_type)
    const value =
      !!(policyCoverageAttribute && atributeValue) &&
      policyCoverageAttribute.values.find(caseAttributeValue => caseAttributeValue.value === atributeValue)

    if (value && policyCoverageAttribute) {
      if (requestInfo.policy_type) {
        const attributeListWithPolicyType = attributes.getAttributesList({
          [policyCoverageAttribute.id]: value.id,
        })

        const policyTypeAttribute = attributeListWithPolicyType.find(
          attribute => attribute.title === COVERAGE_ATTRIBUTE
        )
        const atributeValue =
          policyTypeAttribute && matchPolicyCoverageTypeToAttribute(requestInfo.policy_type)

        const coverageAttributeValue =
          !!(policyTypeAttribute && atributeValue) &&
          policyTypeAttribute.values.find(caseAttributeValue => caseAttributeValue.value === atributeValue)

        if (coverageAttributeValue && policyTypeAttribute) {
          return {
            [policyCoverageAttribute.id]: value.id,
            [policyTypeAttribute.id]: coverageAttributeValue.id,
          }
        }
      }

      return { [policyCoverageAttribute.id]: value.id }
    }
  }

  return {}
}
