import { Editor } from "slate"
import { normalizeList } from "../commands/lists/normalize"
import { CustomEditor } from "../CustomEditor"

export function withLists<T extends CustomEditor>(editor: T): T {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const normalized = normalizeList(editor, entry)
    const [node, path] = entry

    if (!normalized) {
      return normalizeNode(entry)
    }

    if (!Editor.isBlock(editor, node)) {
      return
    }

    if (!Editor.hasPath(editor, path)) return

    const nextNodeEntry = Editor.node(editor, path)

    if (nextNodeEntry) {
      const [nextNode, nextPath] = nextNodeEntry

      if (Editor.isBlock(editor, nextNode)) {
        const lastIndex = nextNode.children.length - 1
        return [...nextNode.children].reverse().forEach((child, i) => {
          normalizeNode([child, [...nextPath, lastIndex - i]])
        })
      }
    }
  }

  return editor
}
