import { IconButton, Menu, MenuItem } from "@material-ui/core"
import { MoreHoriz } from "@material-ui/icons"
import { removeAction } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import React, { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { queryKeys } from "react-query/constants"
import { updateAnnotatedExhibits } from "../cacheUtils"
import { AnnotatedExhibit, CaseId } from "../types"

const removeActionInDuplicate =
  ({ duplicateId, actionId }: { duplicateId: number; actionId: number }) =>
  (exhibit: AnnotatedExhibit): Partial<AnnotatedExhibit> => {
    const newDuplicates = exhibit.duplicates.map(dup => {
      if (dup.pk !== duplicateId) {
        return dup
      }
      return { ...dup, actions: dup.actions?.filter(action => action.pk !== actionId) }
    })

    return {
      duplicates: newDuplicates,
    }
  }

const removeActionInExhibit =
  ({ actionId }: { actionId: number }) =>
  (exhibit: AnnotatedExhibit): Partial<AnnotatedExhibit> => ({
    actions: exhibit.actions.filter(action => action.pk !== actionId),
  })

interface DeletionMoreButtonProps {
  className?: string
  caseId: CaseId
  exhibitId: number
  duplicateId?: Nullable<number>
  actionId: number
}

const DeletionMoreButton: React.FC<DeletionMoreButtonProps> = ({
  className,
  caseId,
  exhibitId,
  duplicateId,
  actionId,
}) => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLButtonElement>>(null)
  const { showMessage } = useHandleMessages()
  const queryClient = useQueryClient()

  const handleOpen: React.MouseEventHandler<HTMLButtonElement> = event => {
    // stop propagation to prevent click from making it's way to parent table row's click
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { mutate, isLoading } = useMutation({
    mutationFn: removeAction,
    onSuccess: () => {
      queryClient.setQueryData<AnnotatedExhibit[]>([queryKeys.annotated_exhibits, caseId], oldData => {
        return updateAnnotatedExhibits({
          oldData,
          exhibitId,
          update: duplicateId
            ? removeActionInDuplicate({ duplicateId, actionId })
            : removeActionInExhibit({ actionId }),
        })
      })
      handleClose()
    },
    onError: () => {
      showMessage({
        type: "error",
        message:
          "Error when trying to undo deletion. Try again shortly and file an issue if the problem persists.",
      })
    },
  })

  return (
    <>
      <IconButton disabled={isLoading} onClick={handleOpen} className={className} size="small">
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        BackdropProps={{
          onClick: event => {
            event.stopPropagation()
            handleClose()
          },
          invisible: true,
        }}
      >
        <MenuItem
          disabled={isLoading}
          onClick={event => {
            event.stopPropagation()
            mutate({
              exhibitId,
              actionId,
            })
          }}
        >
          Undo Deletion
        </MenuItem>
      </Menu>
    </>
  )
}

export default DeletionMoreButton
