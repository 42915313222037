import React, { useCallback, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Global } from "@emotion/react"
import { Switch, Tooltip, Typography } from "@material-ui/core"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { Loading } from "common"
import { PdfRenderer } from "common/pdf-renderer"
import { disableHeaderFooterStyles } from "app/styled"
import { useSubscribe } from "message-broker/useSubscribe"
import { MESSAGE_TOPIC } from "message-broker/topics"
import { payloadToQueryParam, queryParamToPayload } from "./utils"
import { PDF_DATA_QUERY_PARAM, PDF_PINNED_QUERY_PARAM } from "./constants"

interface PdfPreviewProps {
  url: string
  fileName: string
  currentFileName?: string
  pageNumberAdjustment?: number
  page?: number
  withCredentials?: boolean
  deletedPages?: [number, number][]
}

export function PdfPreviewPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams()
  const initialParams = queryParamToPayload<PdfPreviewProps>(searchParams.get(PDF_DATA_QUERY_PARAM) ?? "")

  const [pdfData, setPdfData] = useState<Nullable<PdfPreviewProps>>(
    initialParams && initialParams.fileName && initialParams.url ? initialParams : null
  )
  const [pinned, setPinned] = useState<boolean>(
    searchParams.get(PDF_PINNED_QUERY_PARAM)?.toLowerCase() === "true"
  )
  const { showMessage } = useHandleMessages()

  const handlePinnedChange = useCallback(() => {
    setPinned(pinned => {
      const nextSearchParams = new URLSearchParams(Object.fromEntries(searchParams.entries()))

      nextSearchParams.set(PDF_PINNED_QUERY_PARAM, String(!pinned))
      setSearchParams(nextSearchParams)

      return !pinned
    })
  }, [searchParams, setSearchParams])

  useSubscribe(
    MESSAGE_TOPIC.RENDER_PDF,
    async ({ data }) => {
      const payload = (data.payload ?? {}) as PdfPreviewProps

      if (payload.fileName && payload.url) {
        const searchParams = new URLSearchParams()

        searchParams.set(PDF_DATA_QUERY_PARAM, payloadToQueryParam(payload))
        setSearchParams(searchParams, { replace: true })
        setPdfData(payload)
      } else {
        showMessage({
          type: "error",
          message: "Unable to render pdf file: filename or url is not provided.",
        })
      }
    },
    {
      enabled: !pinned,
    }
  )

  if (!pdfData) {
    return <Loading show label="Waiting for file..." />
  }

  return (
    <>
      <Global styles={disableHeaderFooterStyles} />
      <PdfRenderer
        key={pdfData.fileName}
        filename={pdfData.fileName}
        currentFileName={pdfData.currentFileName}
        pageNumberAdjustment={pdfData.pageNumberAdjustment}
        url={pdfData.url}
        page={pdfData.page ?? 1}
        withCredentials={pdfData.withCredentials}
        deletedPages={pdfData.deletedPages}
        rightControls={
          <>
            <Tooltip
              arrow
              title={
                <>
                  <Typography variant="caption">Pin current page</Typography>
                  <br />
                  Page will stop getting updates and will always show current page when pinned.
                </>
              }
            >
              <Switch
                name="pin-pdf-page"
                size="small"
                checked={pinned}
                onChange={handlePinnedChange}
                color="primary"
              />
            </Tooltip>
          </>
        }
      />
    </>
  )
}
