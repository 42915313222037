import React, { useState, useRef } from "react"
import { Box, Button, FormControlLabel, Switch, Typography } from "@material-ui/core"
import { Outlet } from "react-router-dom"
import { Alert, AlertTitle } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

import {
  STEP_STATUSES,
  SECTION_TO_EXPANDED_REQUESTS,
  FLOATING_PANEL_WIDTH,
  EXHIBIT_SECTION,
  MISSING_DOCS_SECTION,
} from "./constants"

import { useFormContext } from "./context"
import { SavingIndicator } from "./SavingIndicator"
import RequestPanel from "./RequestPanel"

import clsx from "clsx"
import { useDemandSteps } from "./steps"
import { CASE_STEP } from "./steps/enums"

const useStyles = makeStyles(theme => ({
  actions: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    margin: theme.spacing(2, 0),
    justifyContent: "flex-end",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  headerTitle: {
    fontSize: "30px",
    lineHeight: "34px",
    fontWeight: "bold",
    letterSpacing: "-0.6px",
  },
  headerSubtitle: {
    fontSize: "16px",
    lineHeight: 1.75,
    letterSpacing: "-0.3px",
  },
  label: {
    textTransform: "uppercase",
    fontWeight: "500",
    fontSize: ".75rem",
  },
  notice: {
    margin: theme.spacing(4),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: theme.spacing(8),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(0),
    },
  },
  formShift: {
    width: `calc(100% - ${FLOATING_PANEL_WIDTH}px - ${theme.spacing(4)}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - ${FLOATING_PANEL_WIDTH}px)`,
    },
  },
}))

const STANDALONE_SECTIONS = [EXHIBIT_SECTION, MISSING_DOCS_SECTION]

const SkippedSection = () => {
  const classes = useStyles()
  return (
    <Alert severity="info" className={classes.notice}>
      <AlertTitle>Section skipped</AlertTitle>
      This section will not be included in the demand —{" "}
      <strong>Please toggle the button above to update this section.</strong>
    </Alert>
  )
}

const AUTOSAVED_SECTIONS = [CASE_STEP.PROVIDERS, CASE_STEP.FUTURE_EXPENSES]

export default function Form({ currentSection, handleSave, handleNext, mobileNavButton, request = null }) {
  const { handleUpdateStepStatus } = useFormContext()
  const { section, currentStep } = useDemandSteps()
  const isSkipped = currentStep?.isSkipped ?? false
  const classes = useStyles()
  const saveRef = useRef(null)
  const [openRequestPanel, setOpenRequestPanel] = useState(false)

  const expandedRequestGroups = SECTION_TO_EXPANDED_REQUESTS[section] ?? []

  // we need to call each forms' save function
  const onHandleSave = async () => {
    try {
      await saveRef.current()
      handleSave()
    } catch (error) {
      // do nothing, mutation handlers should deal with displaying errors to the user
    }
  }

  const onHandleNext = async () => {
    if (!isSkipped) {
      try {
        if (!AUTOSAVED_SECTIONS.includes(section)) {
          await saveRef.current()
        }
        handleNext()
      } catch (error) {
        // do nothing, mutation handlers should deal with displaying errors to the user
      }
    } else {
      handleNext()
    }
  }

  const onHandleSkip = async event => {
    if (event.target.checked) {
      handleUpdateStepStatus({ status: STEP_STATUSES.skipped })
      handleNext()
    } else {
      handleUpdateStepStatus({ status: STEP_STATUSES.notStarted })
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Box className={clsx(classes.form, openRequestPanel && classes.formShift)}>
        <Box className={classes.header}>
          <Box display="flex" alignItems="center">
            {mobileNavButton}
            <Box>
              <Typography variant="h4" component="h1" className={classes.headerTitle}>
                {currentStep?.title}
              </Typography>
              {currentStep?.subtitle ? (
                <Typography className={classes.headerSubtitle}>{currentStep.subtitle}</Typography>
              ) : null}
            </Box>
          </Box>
          {currentStep?.optional && (
            <FormControlLabel
              classes={{ label: classes.label }}
              control={<Switch checked={isSkipped} onChange={onHandleSkip} name="skip-section" />}
              label="Skip section"
            />
          )}
        </Box>
        <>{isSkipped ? <SkippedSection /> : <Outlet context={[saveRef, handleSave]} />}</>
        {!STANDALONE_SECTIONS.includes(currentSection) && (
          <Box className={classes.actions}>
            <SavingIndicator />
            {currentSection !== CASE_STEP.PROVIDERS && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={onHandleSave}
                disabled={isSkipped}
                data-test="save-button"
              >
                Save
              </Button>
            )}
            <Button variant="contained" color="primary" onClick={onHandleNext} data-test="next-button">
              Next
            </Button>
          </Box>
        )}

        {request?.pk && (
          <RequestPanel
            open={openRequestPanel}
            toggleOpened={() => {
              setOpenRequestPanel(!openRequestPanel)
            }}
            request={request}
            expandedSections={expandedRequestGroups?.expand ?? []}
            highlightedFields={expandedRequestGroups?.highlight ?? []}
          />
        )}
      </Box>
    </DndProvider>
  )
}
