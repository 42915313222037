import React from "react"
import { RevisionRequest } from "common/models/revision-request"
import { RevisionRequestFormController } from "./RevisionRequestFormController"

interface CreateRevisionRequestProps {
  requestId: string
  onCreated: (revision: RevisionRequest) => void
}

export const CreateRevisionRequest = ({ requestId, onCreated }: CreateRevisionRequestProps): JSX.Element => {
  return <RevisionRequestFormController requestId={requestId} onSubmitted={onCreated} />
}
