import React, { useCallback, useEffect, useState } from "react"
import { Box, CircularProgress, Tooltip } from "@material-ui/core"

import { useQuery } from "react-query"
import { queryKeys } from "react-query/constants"
import { getAnnotatedProviderIcdCodes } from "api"

import { Header } from "../styled"
import { IcdCodeFields } from "../../../types"
import { IcdCode } from "./types"
import { Content, IcdCodeContainer, SmallCodeChip } from "./styled"
import { Container } from "../Container"
import { useFeatures, FEATURES } from "hooks/useFeatures"
import ErrorState from "../ErrorState"
import ContainerLabel from "../ContainerLabel"
import { PartitionEntry } from "../types"

interface IcdCodesProps {
  caseId: number
  providerId: number
  currentIcdCodes: IcdCodeFields[]
  onEntryClick: (entry: PartitionEntry) => void
  dateUpdated?: string
}

const IcdCodes = ({
  providerId,
  caseId,
  currentIcdCodes,
  onEntryClick,
  dateUpdated,
}: IcdCodesProps): JSX.Element => {
  const { isFeatureEnabled } = useFeatures()
  const isProviderAutofillEnabled = isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL)
  const [icdCodes, setIcdCodes] = useState<IcdCode[]>([])
  const [isDeviated, setIsDeviated] = useState<boolean>(false)
  const handleOnIcdClick = useCallback(
    (icdCode: IcdCode) => {
      onEntryClick({
        exhibitId: icdCode.entry.exhibit_id,
        partitionId: icdCode.entry.partition_id,
        page: icdCode.entry.page,
      })
    },
    [onEntryClick]
  )

  const checkForDeviation = useCallback(
    (annotatedIcdCodes: IcdCode[]) => {
      if (currentIcdCodes.length !== annotatedIcdCodes.length) {
        return setIsDeviated(true)
      }

      currentIcdCodes.forEach(({ code }) => {
        if (!annotatedIcdCodes.find(icdCode => icdCode.code === code)) {
          return setIsDeviated(true)
        }
      })

      setIsDeviated(false)
    },
    [currentIcdCodes]
  )

  const { isFetching, isError } = useQuery(
    [queryKeys.annotatedIcdCodes, caseId, providerId],
    async () => {
      return await getAnnotatedProviderIcdCodes(caseId, providerId)
    },
    {
      meta: {
        disableLoader: true,
      },
      onSuccess: response => {
        setIcdCodes(response)
      },
      enabled: isProviderAutofillEnabled,
    }
  )

  useEffect(() => {
    checkForDeviation(icdCodes)
  }, [checkForDeviation, icdCodes, currentIcdCodes])

  if (!isProviderAutofillEnabled) {
    return <></>
  }

  if (isFetching) {
    return (
      <IcdCodeContainer>
        <Box display="flex" m={"auto"}>
          <CircularProgress />
        </Box>
      </IcdCodeContainer>
    )
  }

  if (isError) {
    return (
      <IcdCodeContainer>
        <ErrorState message="There was an error retrieving annotated ICD Codes" />
      </IcdCodeContainer>
    )
  }

  if (!isFetching && !icdCodes.length) {
    return (
      <IcdCodeContainer>
        <Box display="flex" m={"auto"}>
          <Box>No Annotated ICD Codes</Box>
        </Box>
      </IcdCodeContainer>
    )
  }

  return (
    <Container>
      <Header>
        <Box>ICD Codes&#x3a;</Box>
        <ContainerLabel
          includeDeviation={isDeviated}
          includeAnnotationUpdated={!!dateUpdated}
          dateUpdated={dateUpdated}
        />
      </Header>
      <Content>
        {icdCodes?.map((icdCode: IcdCode, index) => {
          return (
            <Tooltip placement="bottom" arrow title={`${icdCode.code} - ${icdCode.description}`} key={index}>
              <SmallCodeChip
                label={`${icdCode.code}`}
                onClick={() => {
                  handleOnIcdClick(icdCode)
                }}
              />
            </Tooltip>
          )
        })}
      </Content>
    </Container>
  )
}

export { IcdCodes as default }
