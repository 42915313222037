import React, { useCallback, useMemo, useRef, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { CaseInfo } from "api/services/case/types"
import { AttributeFiltersData } from "common/attributes-filter"
import {
  getAttributeValues,
  getCaseTypeByRequestInfo,
  getJurisdictionByState,
  getPolicyType,
} from "common/attributes-filter/utils"
import { Attributes } from "common/types/attributes"
import { TemplateAttribute } from "./TemplateAttribute"
import { AttributesGroup, AttributesWrapper } from "./styled"
import { queryKeys } from "react-query/constants"
import { sectionTemplateService } from "api/services/section-template"
import { TemplateList } from "./TemplateList"
import { caseService } from "api/services/case"
import { useOutletContext } from "react-router-dom"
import { Loading } from "common"
import { first, isEmpty, isEqual } from "lodash"
import { MatchingTemplates } from "api/services/section-template/types"
import { CaseAlert } from "demand/components/CaseAlert"
import { FirmDto } from "settings/Firm/Firm"
import { RequestViewDto } from "requests/ViewRequest/types"

interface TemplatesProps {
  attributes: Attributes
  caseInfo: CaseInfo
  caseTypeAsset: { key: string; display: string }[]
  firmInfo?: FirmDto
  requestInfo?: RequestViewDto
}

export function Templates({
  attributes,
  caseInfo,
  firmInfo,
  requestInfo,
  caseTypeAsset,
}: TemplatesProps): JSX.Element {
  const [saveRef] = useOutletContext<[React.MutableRefObject<() => void>]>()

  const initialValues = useMemo(() => {
    if (isEmpty(caseInfo.attributeValues)) {
      const jurisdictionAttribute = getJurisdictionByState(attributes, first(firmInfo?.operating_in_states))
      const caseTypeAttribute = getCaseTypeByRequestInfo(attributes, caseTypeAsset, requestInfo)
      const casePolicyType = getPolicyType(attributes, requestInfo)

      return { ...jurisdictionAttribute, ...caseTypeAttribute, ...casePolicyType }
    }

    return getAttributeValues(attributes, caseInfo.attributeValues)
  }, [attributes, caseInfo, firmInfo, requestInfo, caseTypeAsset])
  const [values, setValues] = useState(initialValues)
  const templateAttributes = useMemo(() => attributes.getAttributesTree(values), [attributes, values])
  const queryClient = useQueryClient()
  const latestMatchingTemplates = useRef<MatchingTemplates>()

  const { isFetching: areTemplatesLoading } = useQuery(
    [queryKeys.sectionTemplates, values],
    () => sectionTemplateService.getMatchingTemplates({ data: values }),
    {
      meta: {
        disableLoader: true,
      },
      enabled: !isEqual(initialValues, values),
      onSuccess: matchingTemplates => {
        latestMatchingTemplates.current = matchingTemplates
      },
    }
  )

  const { data: caseTemplates, isFetching: areCaseTemplatesLoading } = useQuery(
    [queryKeys.caseTemplates, caseInfo],
    () => caseService.getMatchingTemplates({ caseId: caseInfo.pk }),
    {
      meta: {
        disableLoader: true,
      },
    }
  )

  const { mutateAsync: updateAttributes, isLoading: isSaving } = useMutation(
    async (...args: Parameters<typeof caseService.updataCaseAttributes>) => {
      const response = await caseService.updataCaseAttributes(...args)

      queryClient.setQueryData([queryKeys.case, response.pk, "-serialized"], response)
      queryClient.invalidateQueries([queryKeys.steps, caseInfo.pk])

      return response
    }
  )

  const handleSave = useCallback(async () => {
    await updateAttributes({ caseId: caseInfo.pk, data: values })

    queryClient.invalidateQueries(queryKeys.caseVariables)
  }, [updateAttributes, caseInfo, values, queryClient])

  const handleChange = useCallback(
    (newValues: AttributeFiltersData) => {
      setValues(currentValues =>
        getAttributeValues(attributes, {
          ...currentValues,
          ...newValues,
        })
      )
    },
    [attributes, setValues]
  )

  saveRef.current = handleSave

  const isLoading = areCaseTemplatesLoading || areTemplatesLoading
  const templates = isEqual(initialValues, values) ? caseTemplates : latestMatchingTemplates.current

  const userActionRequired = caseInfo.templatedSections.some(section => section.userActionRequired)

  return (
    <>
      {isSaving && <Loading show label="Updating matching templates..." />}
      {userActionRequired && (
        <CaseAlert
          canHide
          title="New templates have been added to reflect the selected attributes."
          message="Revise templates that are flagged in the side navigation to confirm desired changes."
        />
      )}
      <AttributesWrapper>
        {templateAttributes.map(attribute => (
          <AttributesGroup key={attribute.attribute.id}>
            <TemplateAttribute attribute={attribute} onChange={handleChange} isLoading={isLoading} />
          </AttributesGroup>
        ))}
      </AttributesWrapper>
      <TemplateList templates={templates ?? {}} attributes={attributes} isLoading={isLoading} />
    </>
  )
}
