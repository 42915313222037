import React, { useState, useRef } from "react"
import {
  Box,
  Button,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  Divider,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Close, MenuRounded, Person } from "@material-ui/icons"
import { ReactComponent as Logo } from "../common/logo.svg"
import { NavLink, useNavigate } from "react-router-dom"
import clsx from "clsx"

import { useAuth } from "../hooks/useAuth"
import useUser from "../hooks/useUser"
import { OSF } from "../common/models/roles"
import { APP_HEADER_ID, HEADER_NAV_HEIGHT_SPACE_MULTIPLIER, HEADER_NAV_Z_INDEX } from "./constants"

const useStyles = makeStyles(theme => ({
  nav: {
    display: "flex",
    flexDirection: "row",
    zIndex: HEADER_NAV_Z_INDEX,
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(HEADER_NAV_HEIGHT_SPACE_MULTIPLIER),
    boxShadow: "rgba(0, 0, 0, 0.08) 0px 1px 12px",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 2, 0, 0),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 1, 0, 0),
    },
    position: "fixed",
    top: 0,
    width: "100vw",
  },
  logo: {
    display: "flex",
    width: "200px",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      width: "150px",
    },
  },
  linksAndUser: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  links: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  mobileLinks: {
    display: "flex",
    gap: theme.spacing(1.5),
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  icon: {
    color: theme.palette.secondary.main,
    backgroundColor: "transparent",
    border: "1px solid",
    transition: "color 0.2s ease-in",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.action.hover,
    },
  },
  drawerList: {
    width: "250px",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    color: theme.palette.secondary.main,
  },
  navLink: {
    textDecoration: "none",
  },
  navButton: {
    position: "relative",
  },
  activeBar: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "2px",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(0.5),
  },
  navListButton: {
    borderRadius: 0,
    padding: theme.spacing(2, 2),
    justifyContent: "left",
  },
  activeNavListButton: {
    borderRight: "4px solid",
  },
}))

const NavButton: React.FC<{ to: string; children: React.ReactNode; end?: boolean }> = ({
  to,
  end,
  children,
}) => {
  const classes = useStyles()
  return (
    <NavLink className={classes.navLink} to={to} end={end}>
      {({ isActive }) => (
        <Button variant="text" color={isActive ? "secondary" : "primary"} className={classes.navButton}>
          {children}
          {isActive && <div className={classes.activeBar} />}
        </Button>
      )}
    </NavLink>
  )
}

const Links: React.FC<Record<string, never>> = () => {
  const { user } = useUser()
  const classes = useStyles()

  if (user.isInternal) {
    return (
      <Box className={classes.links}>
        <NavButton to="/demands" end>
          Demands List
        </NavButton>
        <NavButton to="/requests">Requests</NavButton>
        <NavButton to="/search">Search</NavButton>
      </Box>
    )
  }

  if (user.isExternal) {
    return (
      <Box className={classes.links}>
        <NavButton to="/requests">Requests</NavButton>
      </Box>
    )
  }
  if (user.role === OSF) {
    return (
      <Box className={classes.links}>
        <NavButton to="/demands" end>
          Demands List
        </NavButton>
        <NavButton to="/requests">Requests</NavButton>
      </Box>
    )
  }

  return null
}

const NavListButton: React.FC<{
  to: string
  children: React.ReactNode
  end?: boolean
}> = ({ to, end = false, children }) => {
  const classes = useStyles()
  return (
    <NavLink className={classes.navLink} to={to} end={end}>
      {({ isActive }) => (
        <Button
          variant="text"
          color={isActive ? "secondary" : "primary"}
          className={clsx(classes.navListButton, isActive && classes.activeNavListButton)}
          fullWidth
          size="large"
        >
          {children}
        </Button>
      )}
    </NavLink>
  )
}

const MobileLinks: React.FC<Record<string, never>> = () => {
  const [open, setOpen] = useState(false)
  const { user } = useUser()
  const auth = useAuth()
  const classes = useStyles()

  const handleLogout = () => {
    setOpen(false)
    auth.logout()
  }

  if (user.isInternal) {
    return (
      <>
        <IconButton onClick={() => setOpen(prev => !prev)}>
          <MenuRounded />
        </IconButton>

        <Drawer open={open} onClick={() => setOpen(false)} onClose={() => setOpen(false)} anchor="right">
          <Box className={classes.drawerList}>
            <Box display="flex" justifyContent="end">
              <IconButton>
                <Close />
              </IconButton>
            </Box>
            <NavListButton to="/demands" end>
              Demand List
            </NavListButton>
            <NavListButton to="/requests">Requests</NavListButton>
            <NavListButton to="/search">Search</NavListButton>
            <Divider />
            <Typography
              className={classes.navListButton}
              variant="caption"
              color="textPrimary"
            >{`Logged in as ${user.email}`}</Typography>
            <NavListButton to="/settings">Settings</NavListButton>
            <Button
              variant="text"
              className={clsx(classes.navListButton)}
              fullWidth
              size="large"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Box>
        </Drawer>
      </>
    )
  }

  if (user.isExternal) {
    return (
      <>
        <IconButton onClick={() => setOpen(prev => !prev)}>
          <MenuRounded />
        </IconButton>

        <Drawer open={open} onClick={() => setOpen(false)} onClose={() => setOpen(false)} anchor="right">
          <Box className={classes.drawerList}>
            <Box display="flex" justifyContent="end">
              <IconButton>
                <Close />
              </IconButton>
            </Box>
            <NavListButton to="/requests">Requests</NavListButton>
            <Divider />
            <Typography
              className={classes.navListButton}
              variant="caption"
              color="textPrimary"
            >{`Logged in as ${user.email}`}</Typography>
            <NavListButton to="/settings">Settings</NavListButton>
            <Button
              variant="text"
              className={clsx(classes.navListButton)}
              fullWidth
              size="large"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Box>
        </Drawer>
      </>
    )
  }

  return null
}

const UserMenu: React.FC<Record<string, never>> = () => {
  const { user } = useUser()
  const auth = useAuth()
  const classes = useStyles()

  // user menu controls
  const menuRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  const handleLogout = () => {
    setOpen(false)
    auth.logout()
  }

  const handleClose = () => setOpen(false)

  return (
    <Box>
      <Avatar className={classes.icon} ref={menuRef} onClick={() => setOpen(true)} data-test="user-menu-icon">
        <Person />
      </Avatar>
      <Menu id="user-menu" anchorEl={menuRef.current} open={open} onClose={handleClose} onClick={handleClose}>
        <MenuItem disabled onClick={handleClose}>
          {user.email}
        </MenuItem>
        <MenuItem data-test="settings-button" component={NavLink} to="/settings">
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogout} data-test="logout-button">
          Logout
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default function Header(): React.ReactNode {
  const classes = useStyles()
  const navigate = useNavigate()
  const { isLoading, isAuthorized } = useUser()

  return (
    <nav className={classes.nav} data-test="header" id={APP_HEADER_ID}>
      <Box className={classes.logo}>
        <Logo onClick={() => navigate("/")} />
      </Box>
      {!isLoading && !isAuthorized && (
        <Button color="secondary" component={NavLink} to="/login">
          Login
        </Button>
      )}
      {!isLoading && isAuthorized && (
        <>
          <Box className={classes.linksAndUser}>
            <Links />
            <UserMenu />
          </Box>
          <Box className={classes.mobileLinks}>
            <MobileLinks />
          </Box>
        </>
      )}
    </nav>
  )
}
