import { Box } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"

export const GridLayout = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(1),
  gridTemplateColumns: "1fr 3fr 3fr 3fr 3fr 4fr 4fr 1fr",
  padding: theme.spacing(3, 1),
  "&>*": {
    alignSelf: "center",
  },
}))

export const GridLayoutHeader = styled(GridLayout)(() => ({
  "&>*": {
    fontWeight: "bold",
    fontSize: "16px",
  },
}))

export const GridLayoutEditView = styled(GridLayout)(() => ({
  gridTemplateColumns: "1fr 3fr 3fr 3fr 3fr 4fr 5fr",
}))

export const InnerLayout = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "grid",
  gridTemplateColumns: "1fr 16fr 5fr",
  gap: theme.spacing(1),
}))

export const InnerContent = styled(Box)(() => ({
  gridColumn: 2,
}))

export const FutureMedicalItem = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
}))
