import { ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper } from "@material-ui/core"
import { MoreHoriz } from "@material-ui/icons"
import React, { useRef, useState } from "react"

interface ExcerptMoreButtonProps {
  className?: string
}

const ExcerptMoreButton: React.FC<ExcerptMoreButtonProps> = ({ className }) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const toggleOpen = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton onClick={toggleOpen} ref={anchorRef} className={className} size="small">
        <MoreHoriz />
      </IconButton>
      <Popper
        placement="right"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open}>
                  <MenuItem onClick={handleClose}>View</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default ExcerptMoreButton
