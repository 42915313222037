import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Container, Typography, Paper } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { queryKeys } from "../react-query/constants"

import { getCase, getRelevantDocuments } from "../api"

import CaseResult from "../common/case-result/CaseResult"
import { GenericError } from "../common"

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(2),
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  fabArea: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fab: {
    marginLeft: "10px",
  },
  emptyCases: {
    padding: theme.spacing(4),
  },
}))

const NoSavedCases = function ({ caseId }) {
  const classes = useStyles()

  return (
    <Paper className={classes.emptyCases}>
      <Typography gutterBottom variant="h5">
        No cases saved under this case name.
      </Typography>
      <Typography gutterBottom component="p">
        Find and add cases through <RouterLink to={`/demands/${caseId}/search`}>Search</RouterLink>!
      </Typography>
    </Paper>
  )
}

export function RelevantCases() {
  const { id: caseId } = useParams()

  const { isLoading, data: incident, error } = useQuery([queryKeys.case, caseId], getCase)
  const documentIds = incident?.relevant_documents.map(document => document.elasticsearch_id)

  const {
    isLoading: loadingDocuments,
    data: documents,
    error: documentsError,
  } = useQuery([queryKeys.documents, documentIds], getRelevantDocuments, {
    enabled: !!documentIds?.length,
  })

  if (error) return <GenericError />

  return (
    <Container maxWidth="xl">
      {!isLoading && <>{!documentIds?.length && <NoSavedCases caseId={caseId} />}</>}
      {!loadingDocuments &&
        documents?.results?.map(document => {
          document._is_relevant = true
          document.relevant_document =
            incident?.relevant_documents?.find(relevant_document => {
              return relevant_document.elasticsearch_id === document.id
            }) || null

          return <CaseResult data={document} key={document.id} caseId={caseId} />
        })}
      {documentsError && "An error has occurred: " + documentsError.message}
    </Container>
  )
}
