import React from "react"

import { useQuery, UseQueryResult } from "react-query"
import MissingFieldsAlert from "./MissingFieldsAlert"

import { getNonEconomicValidationFields } from "../../api"
import { queryKeys } from "../../react-query/constants"

interface Props {
  caseId: number
}

interface ValidationFields {
  date_of_incident: string
  gender: string
  date_of_birth: string
  state: string
}
type ValidationFieldKey = keyof ValidationFields

const errorFields: ValidationFieldKey[] = ["date_of_incident"]
export const MissingNonEconomicAlert = ({ caseId }: Props): JSX.Element => {
  const { data, isLoading }: UseQueryResult<ValidationFields, boolean> = useQuery(
    [queryKeys.nonEconomicValidation, caseId],
    async () => {
      return await getNonEconomicValidationFields(caseId)
    }
  )

  if (!data || isLoading) {
    return <></>
  }

  return <MissingFieldsAlert data={data} errorFields={errorFields} />
}
