import { getAPIServerURL } from "utils"

type ApiConfig = {
  host: string
}
type KiliConfig = {
  endpoint: string
  annotation_project_id: string
}
type Config = {
  env: string
  kili: KiliConfig
  api: ApiConfig
}

const deploy_env = process.env.REACT_APP_DEPLOYMENT_ENV || "dev"
let kili_config: KiliConfig

switch (deploy_env) {
  case "prod":
    kili_config = {
      endpoint: "https://kili.getevenup.com",
      annotation_project_id: "cl4dbhf0839nd0ltdgd4sfr41",
    }
    break
  default:
    kili_config = {
      endpoint: "https://stage.getevenup.com",
      annotation_project_id: "cl47bv1sp07qk0ls14k1855u8",
    }
    break
}

export const config: Config = {
  env: deploy_env,
  api: {
    host: getAPIServerURL() || "http://localhost:8000",
  },
  kili: kili_config,
}
