import React from "react"
import { Alert, AlertTitle } from "@material-ui/lab"

export default function GenericError() {
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      Something went wrong unexpectedly — <strong>Please contact the support team.</strong>
    </Alert>
  )
}
