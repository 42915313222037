import { FileMetadata } from "common/form-components/files/types"
import { toConstEnum } from "common/state/enumToConst"
import { Action } from "common/state/types"
import { FileUploadActions } from "../file-state"

enum FILE_UPLOADER_ACTIONS_ENUM {
  UPLOAD_NEW_FILE,
  UPDATE_UPLOAD_STATE,
  ASSIGN_UPLOAD,
  UNASSIGN_FILES,
  DELETE_FILE,
}

export const FILE_UPLOADER_ACTIONS = toConstEnum(FILE_UPLOADER_ACTIONS_ENUM)
export type FILE_UPLOADER_ACTIONS_TYPE = typeof FILE_UPLOADER_ACTIONS

export type UploadNewFileAction = Action<
  FILE_UPLOADER_ACTIONS_TYPE["UPLOAD_NEW_FILE"],
  { id: string; metadata: FileMetadata }
>
export type UpdateFileUploadStateAction = Action<
  FILE_UPLOADER_ACTIONS_TYPE["UPDATE_UPLOAD_STATE"],
  {
    id: string
    fileAction: FileUploadActions
  }
>
export type UnassignFilesAction = Action<FILE_UPLOADER_ACTIONS_TYPE["UNASSIGN_FILES"], Record<string, never>>
export type AssignUploadAction = Action<
  FILE_UPLOADER_ACTIONS_TYPE["ASSIGN_UPLOAD"],
  { uploadId: string; externalId: PrimaryKey }
>
export type DeleteFileAction = Action<FILE_UPLOADER_ACTIONS_TYPE["DELETE_FILE"], { id: string }>

export type FileUploaderAction =
  | UploadNewFileAction
  | UpdateFileUploadStateAction
  | UnassignFilesAction
  | DeleteFileAction
  | AssignUploadAction

export function fileUpdateActionCreator(
  id: string
): (action: FileUploadActions) => UpdateFileUploadStateAction {
  return action => ({
    type: "UPDATE_UPLOAD_STATE",
    payload: {
      id,
      fileAction: action,
    },
  })
}
