import React from "react"
import { Box } from "@material-ui/core"

import { Date } from "./types"
import { Entry } from "../types"
import OverflowCell from "../common/OverflowCell"
import { PopoverContainer, Row, Link, useStyles } from "../styled"
import { toUSDateString } from "utils"

interface DatesCellProps {
  dates: Date[]
  onEntryClick: (entry: Entry) => void
}

const DatesCell = ({ dates, onEntryClick }: DatesCellProps): JSX.Element => {
  const classes = useStyles()
  return (
    <OverflowCell
      data={dates}
      renderInTableDisplay={(date: any, index) => {
        const data = date as Date
        return (
          <Box
            className={classes.entry}
            key={`date-${index}`}
            onClick={() => {
              if (date?.entries?.length) {
                onEntryClick(date.entries[0])
              }
            }}
          >
            {toUSDateString(data.date)}
          </Box>
        )
      }}
      renderPopover={dates => {
        return <DatesPopover dates={dates as Date[]} onEntryClick={onEntryClick} />
      }}
    />
  )
}

interface DatesPopoverProps {
  dates: Date[]
  onEntryClick: (entry: Entry) => void
}

const DatesPopover = ({ dates, onEntryClick }: DatesPopoverProps): JSX.Element => {
  return (
    <PopoverContainer>
      {dates.map((date, index) => {
        return (
          <Row key={index}>
            <Box>{toUSDateString(date.date)}</Box>
            <Link
              onClick={() => {
                if (date?.entries?.length) {
                  onEntryClick(date.entries[0])
                }
              }}
            >
              Page {date.entries[0].page}
            </Link>
          </Row>
        )
      })}
    </PopoverContainer>
  )
}

export { DatesCell as default }
