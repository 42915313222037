import React from "react"
import { Box } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"

import ReportProblemIcon from "@material-ui/icons/ReportProblem"

interface ErrorStateProps {
  message?: string
}

export const ErrorIcon = styled(ReportProblemIcon)(({ theme }) => ({
  fontSize: "32px",
  color: theme.palette.error.main,
}))

const ErrorState = ({
  message = "There was an error retrieving annotated data",
}: ErrorStateProps): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" m={"auto"}>
      <Box m={"auto"}>
        <ErrorIcon />
      </Box>
      <Box m={"auto"}>{message}</Box>
    </Box>
  )
}

export { ErrorState as default }
