import React, { FC, ReactNode } from "react"
import { EDITOR_FEATURES } from "./constants"
import { EditorFeatureProps, EditorFeatureRendererProps } from "./types"
import { getFeaturesFromProps } from "./utils"
import { EditableWithVariables } from "./variables"

type EditorRenderers = Record<
  EDITOR_FEATURES,
  (props: EditorFeatureRendererProps<EDITOR_FEATURES> & { children: ReactNode }) => JSX.Element
>

export const EDITOR_FEATURE_RENDERERS: EditorRenderers = {
  [EDITOR_FEATURES.VARIABLES]: EditableWithVariables,
}

export function getFeaturesRenderer(
  props: EditorFeatureProps
): FC<EditorFeatureRendererProps<EDITOR_FEATURES>> {
  const features = getFeaturesFromProps(props)
  const renderers = features.map(feature => EDITOR_FEATURE_RENDERERS[feature])

  return function (renderProps) {
    return renderers.reduce((children, renderer) => {
      renderer({ ...renderProps, children })

      return renderer({ ...renderProps, children })
    }, <>{renderProps.children}</>)
  }
}
