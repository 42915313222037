import { BaseServiceDeserializer } from "api/BaseDeserializer"
import { AttributeFiltersData } from "common/attributes-filter"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { DEFAULT_VALUE } from "common/form-components/rich-text/defaultValue"
import { deserializeFromMarkdown } from "common/form-components/rich-text/serializer/markdown/deserializer"
import { AttributeValue } from "common/types/attributes"
import { CASE_SECTIONS } from "common/types/sections"
import { FEATURES, isFeatureEnabled } from "hooks/useFeatures"
import { isEqual, isNil } from "lodash"
import {
  CaseAttributeValue,
  CaseAttributeValueDto,
  CaseAttributeValuesDto,
  CaseDto,
  CaseFacts,
  CaseFactsDto,
  CaseFactsSectionUpdateDto,
  CaseFactsUpdateDto,
  CaseInfo,
  CaseTemplatedSection,
  CaseTemplatedSectionDto,
  ProviderTemplatedSectionOverride,
  ProviderTemplatedSectionUpdateDto,
  ProviderTemplateUpdataDto,
} from "./types"

type KeyOfType<T, TData> = {
  [K in keyof T as string]: T[K] extends TData ? K : never
}[string]

const CASE_SECTION_TO_FIELDS_MAPPING: Record<
  CASE_SECTIONS,
  {
    content: KeyOfType<CaseFactsDto, Nullable<EditorRoot<false>>>
    markdownContent: KeyOfType<CaseFactsDto, Nullable<string>>
  }
> = {
  [CASE_SECTIONS.FACTS]: {
    content: "facts_json",
    markdownContent: "facts",
  },
  [CASE_SECTIONS.LIABILITIES]: {
    content: "liability_details_json",
    markdownContent: "liability_details",
  },
}

export class CaseServiceDeserializer {
  static attributeValueFromJSON({
    attribute_id: id,
    value_id: valueId,
  }: CaseAttributeValueDto): CaseAttributeValue {
    return { id, valueId }
  }

  static attributeValuesFromJSON(attributeValues: CaseAttributeValueDto[]): AttributeFiltersData {
    return attributeValues.reduce(
      (values, value) => ({
        ...values,
        [value.attribute_id]: value.value_id,
      }),
      {}
    )
  }

  static fromJSON({ attributes, templated_sections, questionnaire_id, ...caseData }: CaseDto): CaseInfo {
    return {
      ...BaseServiceDeserializer.fromJSON(caseData),
      questionnaireId: questionnaire_id,
      attributeValues: CaseServiceDeserializer.attributeValuesFromJSON(attributes),
      templatedSections: templated_sections.map(BaseServiceDeserializer.fromJSON),
    }
  }

  static templatedSectionDataFromJSON({
    section,
    content,
    markdownContent,
  }: {
    section: CaseTemplatedSectionDto
    content: Nullable<EditorRoot<false>>
    markdownContent: Nullable<string>
  }): CaseTemplatedSection {
    return {
      id: section.pk,
      caseId: section.case_id,
      section: section.section,
      content:
        content ??
        section.custom_content ??
        section.template?.content ??
        (deserializeFromMarkdown(markdownContent) as EditorRoot<false>),
      template: section.template?.content ?? (DEFAULT_VALUE as EditorRoot<false>),
      userActionRequired: section.user_action_required,
    }
  }

  static templatedSectionFromJSON(data: CaseTemplatedSectionDto): CaseTemplatedSection {
    return CaseServiceDeserializer.templatedSectionDataFromJSON({
      section: data,
      content: null,
      markdownContent: null,
    })
  }

  static caseFactsFromJSON(data: CaseFactsDto): CaseFacts {
    const { templated_sections, ...caseFacts } = data
    const sections: CaseFacts["sections"] = {}

    for (const section of templated_sections) {
      const { content, markdownContent } = CASE_SECTION_TO_FIELDS_MAPPING[section.section]

      sections[section.section] = CaseServiceDeserializer.templatedSectionDataFromJSON({
        section,
        content: data[content],
        markdownContent: data[markdownContent],
      })
    }

    return {
      ...caseFacts,
      sections,
    }
  }
}

export class CaseServiceSerializer {
  static attributeValuesToJSON(data: AttributeFiltersData): CaseAttributeValuesDto {
    return {
      attribute_ids: Object.values(data).filter((value): value is AttributeValue["id"] => !isNil(value)),
    }
  }

  static templatedSectionToJSON(data: CaseTemplatedSection): CaseFactsSectionUpdateDto {
    return {
      pk: data.id,
      case_id: data.caseId,
      section: data.section,
      custom_content: !isEqual(data.content, data.template) ? data.content : null,
    }
  }

  static providerTemplatedSectionToJSON(data: { templateId: PrimaryKey }): ProviderTemplateUpdataDto {
    return { template_id: data.templateId }
  }

  static resolvedTemplatedSectionToJSON(data: CaseTemplatedSection): CaseFactsSectionUpdateDto {
    return {
      pk: data.id,
      case_id: data.caseId,
      section: data.section,
      custom_content: data.content,
    }
  }

  static resolvedProviderTemplatedSectionToJSON(
    data: ProviderTemplatedSectionOverride
  ): ProviderTemplatedSectionUpdateDto {
    return {
      custom_content: data.customContent,
    }
  }

  static caseFactsToJSON(data: CaseFacts): CaseFactsUpdateDto {
    const { sections, ...caseFacts } = data
    const templatedSections: CaseFactsUpdateDto["templated_sections"] = []

    if (isFeatureEnabled(FEATURES.CASE_ATTRIBUTES)) {
      for (const section of Object.values(sections)) {
        const { content, markdownContent } = CASE_SECTION_TO_FIELDS_MAPPING[section.section]

        templatedSections.push(CaseServiceSerializer.templatedSectionToJSON(section))

        caseFacts[content] = section.content
        caseFacts[markdownContent] = null
      }
    }

    return {
      ...caseFacts,
      templated_sections: templatedSections,
    }
  }
}
