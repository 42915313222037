import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useDroppable } from "@dnd-kit/core"
import clsx from "clsx"
import { DROP_TARGET_TYPE } from "./constants"

const useStyles = makeStyles(theme => ({
  emptyDropIndicator: {
    background: theme.palette.grey["100"],
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 0),
  },
  active: {
    background: theme.palette.dragging.main,
  },
}))

export function SortableEmptyPlaceholder({ id, canDrop, children }) {
  const classes = useStyles()
  const droppable = useDroppable({
    data: { type: DROP_TARGET_TYPE.EMPTY, id },
    id: `droppable-${id}-inner`,
    disabled: !canDrop,
  })

  return (
    <div
      ref={droppable.setNodeRef}
      className={clsx(classes.emptyDropIndicator, droppable.isOver && classes.active)}
    >
      {children}
    </div>
  )
}
