import React, { useEffect, useMemo } from "react"
import { useLocation, useParams } from "react-router-dom"
import { Global } from "@emotion/react"
import { useQuery } from "react-query"
import { queryKeys } from "react-query/constants"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { getExhibits } from "api"
import { AnnotatedExhibit } from "demand/Files/types"
import { Loading } from "common"
import { PdfRenderer } from "common/pdf-renderer"
import { getUrl } from "apiHelper"
import { disableHeaderFooterStyles } from "app/styled"
import { ExhibitDto } from "demand/Exhibits/Exhibit"

function isAnnotatedExhibit(exhibit: AnnotatedExhibit | ExhibitDto): exhibit is AnnotatedExhibit {
  return Array.isArray((exhibit as AnnotatedExhibit).splits)
}

function getExhibitDownloadPath(exhibit: AnnotatedExhibit | ExhibitDto): string {
  if (!isAnnotatedExhibit(exhibit) || !exhibit.annotation_file) {
    return "download"
  }
  if (exhibit.annotation_file.has_annotated_file) {
    return "download-annotated"
  }
  if (exhibit.annotation_file.has_pre_annotated_file) {
    return "download-pre-annotated"
  }

  return "download"
}

export function ExhibitPreviewPage(): JSX.Element {
  const { caseId, exhibitId, partitionId: partitionIdKey } = useParams()
  const { search } = useLocation()
  const page = parseInt(new URLSearchParams(search).get("page") ?? "1") || 1
  const { showMessage } = useHandleMessages()

  // TODO: need to replace API endpoint to query only needed exhibit
  const { data: annotatedExhibits, isLoading: areAnnotatedExhibitsLoading } = useQuery<AnnotatedExhibit[]>({
    queryKey: [queryKeys.annotated_exhibits, caseId],
    queryFn: () => getExhibits({ caseId, query: { annotations: true } }),
    meta: { disableLoader: true },
    retry: false,
  })

  const { data: nonAnnotatedExhibits, isLoading: areNonAnnotatedExhibitsLoading } = useQuery<ExhibitDto[]>({
    queryKey: [queryKeys.exhibits, caseId],
    queryFn: () => getExhibits({ caseId, query: { annotations: false } }),
    meta: { disableLoader: true },
    enabled: annotatedExhibits && !annotatedExhibits.find(exhibit => exhibit.pk.toString() === exhibitId),
    retry: false,
  })

  const exhibit = useMemo(() => {
    if (!annotatedExhibits) return null

    const annotatedExhibit = annotatedExhibits.find(exhibit => exhibit.pk.toString() === exhibitId)

    if (annotatedExhibit) return annotatedExhibit
    if (!nonAnnotatedExhibits) return null

    return nonAnnotatedExhibits.find(exhibit => exhibit.pk.toString() === exhibitId) ?? null
  }, [annotatedExhibits, exhibitId, nonAnnotatedExhibits])

  useEffect(() => {
    if (annotatedExhibits && nonAnnotatedExhibits && !exhibit) {
      showMessage({
        type: "error",
        message: "There was an error loading exhibit data.",
      })
    }
  }, [showMessage, annotatedExhibits, exhibit, exhibitId, nonAnnotatedExhibits])

  if (areAnnotatedExhibitsLoading || areNonAnnotatedExhibitsLoading) {
    return <Loading show label="Loading exhibit data..." />
  }

  if (!exhibit) {
    return <></>
  }

  const partitionId = partitionIdKey ? parseInt(partitionIdKey) : null
  const partition =
    isAnnotatedExhibit(exhibit) && partitionId !== null
      ? [...exhibit.duplicates, ...exhibit.splits].find(partition => partition.pk === partitionId) ?? null
      : null
  const url = getUrl(
    partition
      ? `/exhibits/${exhibitId}/partition/${partitionId}/download/?exclude_deletes=false`
      : `/case/${caseId}/exhibit/${exhibitId}/${getExhibitDownloadPath(exhibit)}/`
  )
  const pageNumberAdjustment = partition ? partition.start_page - 1 : 0
  const initialPage = Math.max(page - pageNumberAdjustment, 1)

  return (
    <>
      <Global styles={disableHeaderFooterStyles} />
      <PdfRenderer
        filename={exhibit.name}
        pageNumberAdjustment={pageNumberAdjustment}
        url={url}
        page={initialPage}
        withCredentials
      />
    </>
  )
}
