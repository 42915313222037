import React, { ReactElement } from "react"
import { TextFieldProps } from "@material-ui/core"
import { Control, Controller, FieldErrors, FieldValues, Path, UseControllerProps } from "react-hook-form"
import { CurrencyInput } from "./CurrencyInput"
import { get } from "lodash"

interface InputFieldProps<TFields extends FieldValues> {
  control: Control<TFields>
  name: Path<TFields>
  rules?: UseControllerProps<TFields>["rules"]
  errors?: FieldErrors<TFields>
}

export function CurrencyField<T extends FieldValues>({
  control,
  name,
  rules,
  errors,
  ...inputProps
}: InputFieldProps<T> & Partial<TextFieldProps>): ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        const error = get(errors, name)
        const errorMessage = error?.message

        return (
          <CurrencyInput
            error={Boolean(error)}
            {...inputProps}
            {...field}
            helperText={errorMessage || inputProps.helperText}
            value={field.value ?? ""}
            fullWidth
          />
        )
      }}
    />
  )
}
