import React, { useMemo } from "react"
import { BlockTabs, Tab, TabPanel } from "common/tabs/BlockTabs"
import { useSearchState } from "hooks/useSearchState"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import { LibraryBox } from "./styled"
import { DEFAULT_LIBRARY_TAB, LIBRARY_TABS, LIBRARY_TAB_LABELS } from "./constants"
import { LibraryFiltersProvider } from "./Filters"
import { LibraryTemplates, LibraryVariables, ProviderTemplates } from "./Tabs"

export function Library(): JSX.Element {
  const [currentTab, setCurrentTab] = useSearchState("tab", DEFAULT_LIBRARY_TAB, "string")
  const { isFeatureEnabled } = useFeatures()
  const showVariablesTab = isFeatureEnabled(FEATURES.TEMPLATE_VARIABLES_TAB)
  const showProviderTemplatesTab = true || isFeatureEnabled(FEATURES.PROVIDER_TEMPLATES_TAB)

  const tabs = useMemo(() => {
    const result = [
      <Tab
        key={LIBRARY_TABS.TEMPLATES}
        label={LIBRARY_TAB_LABELS[LIBRARY_TABS.TEMPLATES]}
        value={LIBRARY_TABS.TEMPLATES}
        data-test="library_templates_tab"
      />,
    ]

    if (showProviderTemplatesTab) {
      result.push(
        <Tab
          key={LIBRARY_TABS.PROVIDERS}
          label={LIBRARY_TAB_LABELS[LIBRARY_TABS.PROVIDERS]}
          value={LIBRARY_TABS.PROVIDERS}
          data-test="library_providers_tab"
        />
      )
    }

    if (showVariablesTab) {
      result.push(
        <Tab
          key={LIBRARY_TABS.VARIABLES}
          label={LIBRARY_TAB_LABELS[LIBRARY_TABS.VARIABLES]}
          value={LIBRARY_TABS.VARIABLES}
          data-test="library_variables_tab"
        />
      )
    }

    return result
  }, [showVariablesTab, showProviderTemplatesTab])

  const tabPanels = useMemo(() => {
    const result = [
      <TabPanel key={LIBRARY_TABS.TEMPLATES} value={LIBRARY_TABS.TEMPLATES}>
        <LibraryTemplates />
      </TabPanel>,
    ]

    if (showVariablesTab) {
      result.push(
        <TabPanel key={LIBRARY_TABS.VARIABLES} value={LIBRARY_TABS.VARIABLES}>
          <LibraryVariables />
        </TabPanel>
      )
    }

    if (showProviderTemplatesTab) {
      result.push(
        <TabPanel key={LIBRARY_TABS.PROVIDERS} value={LIBRARY_TABS.PROVIDERS}>
          <ProviderTemplates />
        </TabPanel>
      )
    }

    return result
  }, [showVariablesTab, showProviderTemplatesTab])

  return (
    <LibraryFiltersProvider>
      <LibraryBox>
        <BlockTabs currentTab={currentTab ?? DEFAULT_LIBRARY_TAB} setCurrentTab={setCurrentTab} tabs={tabs}>
          <>{tabPanels}</>
        </BlockTabs>
      </LibraryBox>
    </LibraryFiltersProvider>
  )
}
