import React, { forwardRef, useReducer, useState } from "react"
import { Box, Divider, IconButton } from "@material-ui/core"
import { EditBoxTitle, EditPaper, RangesWrapper, Text } from "./styled"
import TextButton from "common/buttons/TextButton"
import {
  CaseId,
  DeleteAction,
  FieldValidationErrors,
  FormRange,
  MIN_PAGE_NUMBER,
  AnnotatedExhibit,
  Duplicate,
} from "./types"
import { Cancel } from "@material-ui/icons"
import { v4 } from "uuid"
import { useMutation, useQueryClient } from "react-query"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { deleteReducer } from "./reducers/deleteReducer"
import EditableRange from "./EditableRange"
import { validateRanges } from "./validation/deleteValidation"
import { ALL_FIELDS, anyErrors } from "./validation/validationUtils"
import { setDeletions } from "api"
import { queryKeys } from "react-query/constants"
import { comparePageRanges, updateAnnotatedExhibits } from "./cacheUtils"

const deletionsToRanges = (deletions: DeleteAction[]): FormRange[] => {
  if (deletions.length > 0) {
    return deletions.map(del => ({ formId: v4(), start: del.start_page, end: del.end_page ?? NaN }))
  }

  return [{ formId: v4(), start: NaN, end: NaN }]
}

const duplicateActionsUpdate =
  ({ duplicateId, actions }: { duplicateId: number; actions: DeleteAction[] }) =>
  (exhibit: AnnotatedExhibit): Partial<AnnotatedExhibit> => {
    const newDuplicates = exhibit.duplicates.map(dup => {
      if (dup.pk !== duplicateId) {
        return dup
      }
      const nonDeleteActions = dup.actions?.filter(act => act.action_type !== "delete") ?? []
      const newActions = [...nonDeleteActions, ...actions].sort(comparePageRanges)
      return { ...dup, actions: newActions }
    })

    return {
      duplicates: newDuplicates,
    }
  }

const exhibitActionsUpdate =
  ({ actions }: { actions: DeleteAction[] }) =>
  (exhibit: AnnotatedExhibit): Partial<AnnotatedExhibit> => {
    const nonDeleteActions = exhibit.actions.filter(action => action.action_type !== "delete")
    const newActions = [...nonDeleteActions, ...actions].sort(comparePageRanges)
    return {
      actions: newActions,
    }
  }

interface DeletePagesBoxProps {
  onCancel: () => unknown
  onSuccessfulSave: () => unknown
  deletions: DeleteAction[]
  exhibitId: number
  caseId: CaseId
  numberOfPages: number
  duplicate?: Nullable<Duplicate>
}

export default forwardRef<HTMLElement, DeletePagesBoxProps>(function DeletePagesBox(
  { onCancel, deletions, exhibitId, caseId, onSuccessfulSave, numberOfPages, duplicate },
  ref
): React.ReactElement {
  const startBoundary = duplicate ? duplicate.start_page : MIN_PAGE_NUMBER
  const endBoundary = duplicate ? duplicate.end_page : numberOfPages

  const [state, dispatch] = useReducer(deleteReducer, { ranges: deletionsToRanges(deletions) })
  const { showMessage } = useHandleMessages()
  const queryClient = useQueryClient()
  const [validationErrors, setValidationErrors] = useState<FieldValidationErrors>({})

  const { mutate, isLoading } = useMutation({
    mutationFn: setDeletions,
    onSuccess: (data: DeleteAction[]) => {
      queryClient.setQueryData<AnnotatedExhibit[]>([queryKeys.annotated_exhibits, caseId], oldData => {
        return updateAnnotatedExhibits({
          oldData,
          exhibitId,
          update: duplicate
            ? duplicateActionsUpdate({ duplicateId: duplicate.pk, actions: data })
            : exhibitActionsUpdate({ actions: data }),
        })
      })
      onSuccessfulSave()
    },
    onError: () => {
      showMessage({
        type: "error",
        message: "Could not create deletions. Try again shortly and file an issue if the problem persists.",
      })
    },
  })

  const handleSave = () => {
    const errors = validateRanges({ ranges: state.ranges, startBoundary, endBoundary })
    setValidationErrors(errors)

    if (anyErrors(errors)) return

    mutate({
      exhibitId,
      data: {
        partition_id: duplicate?.pk,
        deletions: state.ranges.map(range => ({ start_page: range.start, end_page: range.end })),
      },
    })
  }

  return (
    <EditPaper ref={ref}>
      <Box display="flex" alignItems="baseline">
        <EditBoxTitle>Delete Pages from {duplicate ? "Duplicate" : "whole Document"}</EditBoxTitle>&nbsp;
        <Text>
          (pg. {startBoundary} - {endBoundary})
        </Text>
      </Box>
      <RangesWrapper mt={2}>
        {state.ranges.map(({ formId, start, end }) => (
          <Box key={formId}>
            <Box display="flex" alignItems="center">
              <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
                <EditableRange
                  start={start}
                  end={end}
                  onStartChange={event =>
                    dispatch({ type: "editRange", formId, start: event.target.valueAsNumber, end })
                  }
                  onEndChange={event =>
                    dispatch({ type: "editRange", formId, start, end: event.target.valueAsNumber })
                  }
                  startError={validationErrors[formId]?.some(
                    err => err.field === "start" || err.field === ALL_FIELDS
                  )}
                  endError={validationErrors[formId]?.some(
                    err => err.field === "end" || err.field === ALL_FIELDS
                  )}
                  disabled={isLoading}
                />
                {state.ranges.length > 1 && (
                  <IconButton onClick={() => dispatch({ type: "deleteRange", formId })} disabled={false}>
                    <Cancel />
                  </IconButton>
                )}
              </Box>
            </Box>
            {validationErrors[formId] && (
              <Box mt={1}>
                <Text color="error">
                  {validationErrors[formId]?.map(validationErr => validationErr.message).join(", ")}
                </Text>
              </Box>
            )}
          </Box>
        ))}
      </RangesWrapper>
      <Box mt={2} mb={1}>
        <Divider />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <TextButton onClick={() => dispatch({ type: "addRange" })} disabled={false}>
            + Add Deletion
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={onCancel} textColor="grey" disabled={false}>
            Cancel
          </TextButton>
          <TextButton onClick={handleSave} disabled={false}>
            Save
          </TextButton>
        </Box>
      </Box>
    </EditPaper>
  )
})
