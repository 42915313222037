import { SectionWithAttributes } from "common/attributes-filter"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import {
  LibraryVariableRowData,
  SectionTemplateTableRowData,
  ProviderTemplateRowData,
} from "common/models/library"
import { FORM_STATE_STATUS } from "./constants"

export interface SectionTemplateFormData {
  sectionWithAttributes: SectionWithAttributes
  content: EditorRoot<true>
}

export interface ProviderTemplateFormData {
  templateName: string
  generatorKey: string
  content: EditorRoot<true>
}

export interface LibraryVariableFormData {
  sectionWithAttributes: SectionWithAttributes
  name: string
  content: string
  groupId: Nullable<PrimaryKey>
}

export class NonUniqueAttributesError {}

export interface LibraryFormData {
  message: Nullable<JSX.Element>
  editForm: (footer: Nullable<JSX.Element>) => Nullable<JSX.Element>
  viewForm: Nullable<JSX.Element>
  isLoading: boolean
  handleSubmit: () => void
  handleCancel: () => void
  clearForm: () => void
  errorForm: boolean
}

export interface LibraryFormParams {
  entityId?: PrimaryKey
  onFinish: () => void
  initialData: SectionTemplateTableRowData & LibraryVariableRowData & ProviderTemplateRowData
  formStatusMessageMap: Record<FORM_STATE_STATUS, Nullable<JSX.Element>>
}

export type LibraryUseForm = (param: LibraryFormParams) => LibraryFormData
