import React, { useCallback, useEffect, useRef, useState } from "react"
import { PDFDocumentProxy, PDFPageProxy, RenderTask } from "pdfjs-dist/types/src/display/api"
import { Skeleton } from "@material-ui/lab"
import { PREVIEW_SIZE } from "./constants"
import {
  ThumbnailAnnotatedWrapper,
  ThumbnailLoading,
  ThumbnailPageIndicator,
  ThumbnailViewport,
  ThumbnailWrapper,
} from "./styled"
import { noop } from "lodash"
import { useThumbnailCache } from "./ThumbnailCache"
import { getAnnotationColors } from "./utils"

interface ThumbnailProps {
  document: PDFDocumentProxy
  page: number
  pageNumberAdjustment: number
  selected: boolean
  deleted: boolean
  onClick: (page: number) => void
}

export function Thumbnail({
  document,
  page,
  pageNumberAdjustment,
  selected,
  deleted,
  onClick,
}: ThumbnailProps): JSX.Element {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [pageProxy, setPageProxy] = useState<Nullable<PDFPageProxy>>(null)
  const [annotationColors, setAnnotationColors] = useState<Nullable<string[]>>(null)
  const [cachedItem, setCacheItem] = useThumbnailCache(page)
  const [isLoading, setIsLoading] = useState(!cachedItem)
  const isLoadingRef = useRef(isLoading)
  isLoadingRef.current = isLoading

  useEffect(() => {
    if (!pageProxy || !canvasRef.current || !isLoadingRef.current) return

    const viewport = pageProxy.getViewport({ scale: 1 })
    const scale = Math.min(PREVIEW_SIZE.WIDTH / viewport.width, PREVIEW_SIZE.HEIGHT / viewport.height)
    const canvasContext = canvasRef.current.getContext("2d")

    if (!canvasContext) return

    let renderTask: Nullable<RenderTask> = null
    let raf = requestAnimationFrame(() => {
      renderTask = pageProxy.render({
        intent: "print",
        annotationMode: 0,
        canvasContext,
        viewport: pageProxy.getViewport({ scale }),
      })

      renderTask.promise.then(() => {
        renderTask = null
        setIsLoading(false)

        raf = requestAnimationFrame(() => {
          const dataUrl = canvasRef.current?.toDataURL()

          if (dataUrl) {
            setCacheItem(pageProxy.pageNumber, dataUrl)
          }
        })
      }, noop)
    })

    return () => {
      cancelAnimationFrame(raf)

      if (renderTask) {
        renderTask.promise.catch(noop)
        renderTask.cancel()
      }
    }
  }, [pageProxy, canvasRef, isLoadingRef, setCacheItem])

  useEffect(() => {
    let cancelPageLoad: () => void = noop
    let unmounted = false

    new Promise<PDFPageProxy>((resolve, reject) => {
      cancelPageLoad = reject
      document.getPage(page).then(page => {
        !unmounted && resolve(page)
      }, noop)
    })
      .then(setPageProxy, noop)
      .catch(noop)

    return () => {
      unmounted = true
      cancelPageLoad()
    }
  }, [document, page])

  useEffect(() => {
    if (!pageProxy) return

    pageProxy.getAnnotations().then(getAnnotationColors).then(setAnnotationColors).catch(noop)
  }, [pageProxy])

  const handleThumbnailClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      onClick(page)
    },
    [onClick, page]
  )

  return (
    <>
      <ThumbnailWrapper selected={selected} loading={isLoading}>
        <ThumbnailAnnotatedWrapper colors={annotationColors} as="a" onClick={handleThumbnailClick}>
          <ThumbnailViewport deleted={deleted}>
            {!cachedItem && (
              <canvas ref={canvasRef} width={PREVIEW_SIZE.WIDTH} height={PREVIEW_SIZE.HEIGHT} />
            )}
            {cachedItem && <img src={cachedItem} width={PREVIEW_SIZE.WIDTH} height={PREVIEW_SIZE.HEIGHT} />}
            {isLoading && (
              <ThumbnailLoading>
                <Skeleton variant="rect" animation="wave" />
                <Skeleton animation="wave" height={6} />
                <Skeleton width="60%" height={6} animation="wave" />
              </ThumbnailLoading>
            )}
          </ThumbnailViewport>
        </ThumbnailAnnotatedWrapper>
      </ThumbnailWrapper>
      <ThumbnailPageIndicator>{page + pageNumberAdjustment}</ThumbnailPageIndicator>
    </>
  )
}
