import Box from "@material-ui/core/Box"
import { styled } from "@material-ui/core/styles"

export const StatusRowContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",

  "& > :not(:last-child)": {
    marginRight: theme.spacing(2),
  },
}))

export const StatusColumnContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  "& > :not(:last-child)": {
    marginBottom: theme.spacing(1),
  },
}))

export const RequestInlineStatus = styled(Box)(({ theme }) => ({
  display: "flex",
  "& > *": {
    whiteSpace: "nowrap",
    marginRight: theme.spacing(0.5),
  },
}))

export const MinorTitleContent = styled(Box)({
  textTransform: "uppercase",
  color: "#9A9A9A",
  fontWeight: 500,
  letterSpacing: "0.46px",
})
