import React, { useState, useCallback } from "react"
import { Link as RouterLink } from "react-router-dom"
import {
  Typography,
  Box,
  CircularProgress,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button,
  FormControl,
  TextField,
} from "@material-ui/core"
import {
  Add as AddIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  GetApp as DownloadApp,
} from "@material-ui/icons"
import { makeStyles, styled } from "@material-ui/core/styles"
import { useQuery, useMutation, useQueryClient } from "react-query"

import useUser from "../../hooks/useUser"
import { useHandleMessages } from "../../common/messages/useHandleMessages"

import { queryKeys } from "../../react-query/constants"
import { getFirms, deleteFirm, downloadContractCSV } from "../../api"
import { canRoleDeleteFirm, canRoleSeeDownloadContracts } from "./permissions"
import { GenericError } from "../../common"
import { useSearchState } from "../../hooks/useSearchState"
import { Pagination } from "common/pagination"
import { PageSizeSelect } from "common/pagination/PageSizeSelect"
import { INTERNAL_ROLES } from "common/models/roles"
import { DEFAULT_PAGE_SIZE } from "common/models/pagination"
import { StyledPageSizeWrapper } from "../Library/Tabs/styled"

const useStyles = makeStyles(theme => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  alignRight: {
    display: "flex",
    justifyContent: "right",
  },
  alignLeft: {
    display: "flex",
    justifyContent: "left",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  flexBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
}))

const DownloadButton = styled(Button)(({ theme }) => ({
  textDecoration: "uppercase",
  backgroundColor: theme.palette.grey[300],
  borderColor: theme.palette.grey[300],
  border: "solid 1px",
  borderRadius: "6px",
  fontSize: "12px",
}))

const TableFilters = ({ handleSearchQueryChange, searchQuery }) => {
  const classes = useStyles()

  return (
    <Box className={classes.alignRight}>
      <FormControl className={classes.searchBar}>
        <TextField
          label={"Search"}
          value={searchQuery}
          onChange={handleSearchQueryChange}
          variant="outlined"
          placeholder="Search by firm name"
          data-test="search-demand"
        />
      </FormControl>
    </Box>
  )
}

const PaginationHelper = ({ handlePageChange, currentPage, data, pageSize, handlePageSizeChange }) => {
  const classes = useStyles()

  return (
    <Box className={classes.actionContainer}>
      <Pagination
        onChange={handlePageChange}
        page={currentPage}
        pageCount={Math.ceil(data.count / pageSize)}
      />
      {/* Dropdown menu for selecting the page size */}
      <StyledPageSizeWrapper className={classes.alignRight}>
        <PageSizeSelect pageSize={pageSize} onChange={handlePageSizeChange} />
      </StyledPageSizeWrapper>
    </Box>
  )
}

export function FirmList() {
  const queryClient = useQueryClient()
  const classes = useStyles()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [searchQuery, setSearchQuery] = useSearchState("query", "", "string")
  const { isLoading, isFetching, data, error } = useQuery({
    queryKey: [
      queryKeys.firms,
      {
        currentPage,
        pageSize,
        searchQuery,
      },
    ],
    queryFn: () => getFirms({ page: currentPage, pageSize: pageSize, searchQuery: searchQuery }),
    meta: { disableLoader: true },
  })
  const { user } = useUser()
  const { showMessage } = useHandleMessages()

  const [isDownloadingContracts, setIsDownloadingContracts] = useState(false)

  const { mutate: handleDelete } = useMutation(deleteFirm, {
    onSuccess: () => queryClient.invalidateQueries(queryKeys.firms),
  })

  function buildConfirmMessage(firmName, numberOfCases, numberOfAttorneys) {
    return `
      Are you sure you want to delete the firm, ${firmName}.

      You would also be deleting ${numberOfCases} cases(s) and ${numberOfAttorneys} attorney(s).
    `
  }

  const handlePageChange = page => {
    setCurrentPage(page)
  }

  const handleSearchQueryChange = e => {
    handlePageChange(1)
    // don't replace history when value is empty
    // this creates a distinct entry when clearing input
    // so you can use back button to return to input before clearing
    setSearchQuery(e.target.value, { replace: e.target.value ? true : false })
  }

  const handlePageSizeChange = useCallback(pageSize => {
    setCurrentPage(1)
    setPageSize(pageSize)
  }, [])

  if (error) return <GenericError />

  const canDeleteFrim = canRoleDeleteFirm(user.role)
  const showDownloadContracts = canRoleSeeDownloadContracts(user.role)

  const handleDownloadingContracts = async () => {
    setIsDownloadingContracts(true)
    try {
      await downloadContractCSV()
    } catch (error) {
      showMessage({
        type: "error",
        message: "There was an error downloading the contract data.",
      })
    } finally {
      setIsDownloadingContracts(false)
    }
  }

  return (
    <Box>
      <Box display="flex" mb={2}>
        <Typography variant="h4">Firms List</Typography>
        {showDownloadContracts && (
          <Box ml="auto">
            <DownloadButton
              variant="outlined"
              startIcon={isDownloadingContracts ? <CircularProgress size="1em" /> : <DownloadApp />}
              onClick={handleDownloadingContracts}
              disabled={isDownloadingContracts}
              data-test="all-contracts-csv-button"
            >
              All Contracts CSV
            </DownloadButton>
          </Box>
        )}
      </Box>
      <TableFilters handleSearchQueryChange={handleSearchQueryChange} searchQuery={searchQuery} />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* This is where we render the items */}
          {!isLoading &&
            !isFetching &&
            data.results.map(row => (
              <TableRow key={row.pk}>
                <TableCell>
                  <RouterLink data-test="link-to-firm" to={"/settings/firms/" + row.pk}>
                    {row.name}
                  </RouterLink>
                </TableCell>
                <TableCell align="right">
                  <IconButton component={RouterLink} to={"/settings/firms/" + row.pk}>
                    <VisibilityIcon color="primary" />
                  </IconButton>
                  {canDeleteFrim && (
                    <IconButton
                      data-test="delete-firm-button"
                      onClick={() => {
                        if (
                          confirm(buildConfirmMessage(row.name, row.number_of_cases, row.number_of_attorneys))
                        ) {
                          handleDelete(row.pk)
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {(isFetching || isLoading) && (
        <div className={classes.flexBox}>{<CircularProgress color="secondary" />}</div>
      )}
      {user.isRole(INTERNAL_ROLES.LEGALOPS_ADMIN) && (
        <RouterLink to={"/settings/firms/new"} data-test="add-new-firm-button">
          <Fab aria-label="add" color="secondary" className={classes.fab}>
            <AddIcon />
          </Fab>
        </RouterLink>
      )}
      {/* Page bar to move between pages */}
      {!isLoading && data.count > 0 && (
        <PaginationHelper
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          data={data}
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
        />
      )}
    </Box>
  )
}
