export function toBase64(value: string): string {
  return window.btoa(encodeURIComponent(value))
}

export function fromBase64(value: string): string {
  return decodeURIComponent(window.atob(value))
}

export function payloadToQueryParam(input: Record<string, any>): string {
  try {
    return toBase64(JSON.stringify(input))
  } catch {
    return ""
  }
}

export function queryParamToPayload<T>(input: string): Nullable<T> {
  try {
    return JSON.parse(fromBase64(input))
  } catch {
    return null
  }
}
