import React from "react"
import { Box } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"

import { ordinalSuffixOf } from "../../utils"

import { PlaintiffDto } from "./types"

interface PlaintiffsViewProps {
  plaintiffs: PlaintiffDto[]
  titleClass: string
}

const Container = styled(Box)({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(18rem, 1fr))",
  gap: "1rem",
})

const PlaintiffContent = styled(Box)({
  textTransform: "capitalize",
  overflowWrap: "break-word",
})

const MINOR_LABEL = "(MINOR)"
export const PlaintiffsView: React.FC<PlaintiffsViewProps> = ({ plaintiffs, titleClass }): JSX.Element => {
  return (
    <Container>
      {plaintiffs.map((plaintiff: PlaintiffDto, index: number) => {
        const prefix = index === 0 ? "Primary" : ordinalSuffixOf(index + 1)
        const { first_name, last_name, role, pronoun, is_minor } = plaintiff

        return (
          <Box key={plaintiff.pk}>
            <Box className={titleClass}>{prefix} Plaintiff&rsquo;s Name</Box>
            <PlaintiffContent>
              {first_name} {last_name} {role ? `- ${role}` : ""} {pronoun ? `- ${pronoun}` : ""}{" "}
              {is_minor === true ? MINOR_LABEL : ""}
            </PlaintiffContent>
          </Box>
        )
      })}
    </Container>
  )
}
