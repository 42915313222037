import React from "react"
import { Box, Typography } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles"
import { isEmpty } from "lodash"

const useStyles = makeStyles(theme => ({
  validationsContainer: {
    margin: theme.spacing(2, 0),
  },
}))

export interface DemandValidationInfo {
  errors: Record<string, string[]>
  infos: Record<string, string[]>
  warnings: Record<string, string[]>
}

interface DemandValidationProps {
  validations: DemandValidationInfo
}

export function DemandValidation({ validations }: DemandValidationProps): JSX.Element {
  const classes = useStyles()
  const hasErrors = !isEmpty(validations.errors)
  const hasWarnings = !isEmpty(validations.warnings)
  const hasInfos = !isEmpty(validations.infos)

  return (
    <Box className={classes.validationsContainer}>
      {hasErrors && (
        <Alert severity="error">
          <AlertTitle>Errors, please fix</AlertTitle>
          <ul>
            {Object.entries(validations.errors).map(([section, messages]) =>
              messages.map((message, i) => (
                <li key={`${section}_${i}`}>
                  <Typography key={`${section}_${i}_message`} variant="body2">
                    {message}
                  </Typography>
                </li>
              ))
            )}
          </ul>
        </Alert>
      )}
      {hasWarnings && (
        <Alert severity="warning" style={{ marginTop: hasErrors ? "16px" : "" }}>
          <AlertTitle>Warning, demand may generate with mistakes</AlertTitle>
          <ul>
            {Object.entries(validations.warnings).map(([section, messages]) =>
              messages.map((message, i) => (
                <li key={`${section}_${i}`}>
                  <Typography key={`${section}_${i}_message`} variant="body2">
                    {message}
                  </Typography>
                </li>
              ))
            )}
          </ul>
        </Alert>
      )}
      {hasInfos && (
        <Alert severity="info" style={{ marginTop: hasErrors || hasWarnings ? "16px" : "" }}>
          <AlertTitle>Info, some notes on your demand</AlertTitle>
          <ul>
            {Object.entries(validations.infos).map(([section, messages]) =>
              messages.map((message, i) => (
                <li key={`${section}_${i}`}>
                  <Typography key={`${section}_${i}_message`} variant="body2">
                    {message}
                  </Typography>
                </li>
              ))
            )}
          </ul>
        </Alert>
      )}
    </Box>
  )
}
