import { Box, Chip, CircularProgress, Paper, Typography } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import styled from "@emotion/styled"
import { ExpandMore, GetApp, MoreHoriz } from "@material-ui/icons"
import { downloadPartition } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import React, { useCallback, useMemo, useState } from "react"
import ProviderMenu from "./ProviderMenu"
import { AnnotationFileIconButton, Text } from "./styled"
import {
  PartitionProvider,
  Partition,
  CaseId,
  DeleteAction,
  AnnotationFileExcerpt,
  AnnotationStatus,
} from "./types"
import { DuplicateMenu, SplitMenu } from "./PartitionMenus"
import { displayPageRange } from "./rangeUtils"
import ExcerptTable from "./tables/ExcerptTable"
import DeletionTable from "./tables/DeletionTable"
import { getRelevantDeletions, RelevantDeletion } from "./tables/getRelevantDeletions"
import { DEFAULT_WINDOW_CONFIGURATIONS } from "app/constants"

const SplitHeadingText = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: "bold",
}))

const AnnotationsPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
}))

const HidingExpandMore = styled(ExpandMore)(() => ({ visibility: "hidden" }))

const PrimaryProviderBaseButton = styled("button")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  border: "none",
  backgroundColor: "inherit",
  cursor: "pointer",
  [`&:hover ${SplitHeadingText}`]: {
    textDecoration: "underline",
  },
  "&:disabled": {
    textDecoration: "none",
    color: theme.palette.common.black,
    cursor: "auto",
  },
  [`&:hover ${HidingExpandMore}`]: {
    visibility: "visible",
  },
}))

interface PrimaryProviderButtonProps {
  provider?: PartitionProvider
  onClick: React.MouseEventHandler<HTMLElement>
  disabled?: boolean
}

const PrimaryProviderButton: React.FC<PrimaryProviderButtonProps> = ({
  provider,
  onClick,
  disabled = false,
}) => {
  const theme = useTheme()
  return (
    <Box display="flex" alignItems="center">
      <SplitHeadingText>Primary Provider: </SplitHeadingText>
      <PrimaryProviderBaseButton onClick={onClick} role="button" disabled={disabled}>
        <span
          style={{
            backgroundColor: provider?.color ? `#${provider.color}` : undefined,
            padding: "8px",
            border: provider?.name ? "none" : `1px solid ${theme.palette.error.main}`,
          }}
        >
          {provider?.name ? (
            <SplitHeadingText>{provider.name}</SplitHeadingText>
          ) : (
            <SplitHeadingText color="error">SELECT</SplitHeadingText>
          )}
        </span>
        <HidingExpandMore />
      </PrimaryProviderBaseButton>
    </Box>
  )
}

interface AnnotationSplitProps extends Partition {
  exhibitId: number
  caseId: CaseId
  partitionProviders?: PartitionProvider[]
  onDeletePagesClick: (partitionId: Nullable<number>) => unknown
  deletions: DeleteAction[]
  status?: Nullable<AnnotationStatus>
}

type AnnotationDuplicateProps = AnnotationSplitProps

interface AnnotationPartitionProps extends AnnotationSplitProps {
  duplicate?: boolean
}

const AnnotationPartition: React.FC<AnnotationPartitionProps> = ({
  excerpts,
  provider,
  start_page,
  end_page,
  pk,
  exhibitId,
  partitionProviders = [],
  caseId,
  duplicate = false,
  onDeletePagesClick,
  deletions,
  status,
  section,
}) => {
  const [downloadingFile, setDownloadingFile] = useState<boolean>(false)
  const { showMessage } = useHandleMessages()
  const [providerMenuAnchor, setProviderMenuAnchor] = useState<null | HTMLElement>(null)
  const [moreOptionsAnchor, setMoreOptionsAnchor] = useState<null | HTMLElement>(null)
  const { isFeatureEnabled } = useFeatures()
  const isSplittingEnabled = isFeatureEnabled(FEATURES.SPLIT_INTAKE_FILES)

  const handleProviderMenuClose = () => setProviderMenuAnchor(null)
  const toggleProviderMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (providerMenuAnchor) {
      handleProviderMenuClose()
    } else {
      setProviderMenuAnchor(event.currentTarget)
    }
  }

  const handleMoreOptionsMenuClose = () => setMoreOptionsAnchor(null)
  const handleMoreOptionsMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreOptionsAnchor(event.currentTarget)
  }
  const handleDeletePagesClick = () => {
    handleMoreOptionsMenuClose()
    // we only want to pass up the pk if duplicate clicked
    // so we can use that info to restrict the validation to this duplicate's page ranges
    // and only show deletions related to this duplicate
    onDeletePagesClick(duplicate ? pk : null)
  }

  const handleDownload = () => {
    setDownloadingFile(true)
    downloadPartition({
      exhibitId,
      partitionId: pk,
    })
      .catch(() => {
        showMessage({
          type: "error",
          message:
            "Failed to download file. Try again shortly and if your problem persists, please report your issue.",
        })
      })
      .finally(() => setDownloadingFile(false))
  }

  const handleOpenExcerptPreview = useCallback(
    (excerpt?: AnnotationFileExcerpt) => {
      if (excerpt) {
        window.open(
          `/exhibit-preview/${caseId}/exhibit/${exhibitId}/partition/${pk}?page=${excerpt.start_page}`,
          "_blank",
          DEFAULT_WINDOW_CONFIGURATIONS
        )
      } else {
        window.open(
          `/exhibit-preview/${caseId}/exhibit/${exhibitId}/partition/${pk}`,
          "_blank",
          DEFAULT_WINDOW_CONFIGURATIONS
        )
      }
    },
    [exhibitId, caseId, pk]
  )

  const handleOpenDeletionPreview = useCallback(
    (deletion: RelevantDeletion) => {
      window.open(
        `/exhibit-preview/${caseId}/exhibit/${exhibitId}/partition/${pk}?page=${deletion.start_page}`,
        "_blank",
        DEFAULT_WINDOW_CONFIGURATIONS
      )
    },
    [exhibitId, caseId, pk]
  )

  const relevantDeletions = useMemo(
    () =>
      getRelevantDeletions({
        start_page,
        end_page,
        deletions,
        excerpts: status === "complete" ? excerpts : [],
      }),
    [start_page, end_page, deletions, excerpts, status]
  )

  return (
    <AnnotationsPaper elevation={1}>
      <Box padding={2} width="100%">
        <Box pl={0.1} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Box visibility={isSplittingEnabled ? "visible" : "hidden"}>
            {section === "providers" && (
              <>
                <PrimaryProviderButton
                  provider={provider}
                  onClick={toggleProviderMenu}
                  disabled={!isSplittingEnabled}
                />
                <ProviderMenu
                  anchorEl={providerMenuAnchor}
                  onClose={handleProviderMenuClose}
                  partitionProviders={partitionProviders}
                  exhibitId={exhibitId}
                  caseId={caseId}
                  partitionId={pk}
                  provider={provider}
                />
              </>
            )}
            {section !== "providers" && <SplitHeadingText>{section ?? "No Auto-Import"}</SplitHeadingText>}
          </Box>
          <Box display="flex" alignItems="center">
            <Box visibility={duplicate ? "visible" : "hidden"} mr={2}>
              <Chip label="Duplicate" variant="outlined" size="small" />
            </Box>
            <Box mr={1}>
              <Text>Pages: {displayPageRange(start_page, end_page)}</Text>
            </Box>
            {downloadingFile ? (
              <Box p={1}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <AnnotationFileIconButton onClick={handleDownload}>
                <GetApp />
              </AnnotationFileIconButton>
            )}
            <AnnotationFileIconButton onClick={handleMoreOptionsMenuOpen}>
              <MoreHoriz />
            </AnnotationFileIconButton>
            {duplicate ? (
              <DuplicateMenu
                anchorEl={moreOptionsAnchor}
                onClose={handleMoreOptionsMenuClose}
                onDeleteDuplicateSuccess={handleMoreOptionsMenuClose}
                onDeletePagesClick={handleDeletePagesClick}
                exhibitId={exhibitId}
                partitionId={pk}
                caseId={caseId}
                section={section}
              />
            ) : (
              <SplitMenu
                anchorEl={moreOptionsAnchor}
                onClose={handleMoreOptionsMenuClose}
                onDeletePagesClick={handleDeletePagesClick}
                exhibitId={exhibitId}
                partitionId={pk}
                caseId={caseId}
                section={section}
              />
            )}
          </Box>
        </Box>
        <ExcerptTable
          status={status}
          excerpts={excerpts}
          onPreview={handleOpenExcerptPreview}
          startPage={start_page}
          endPage={end_page}
        />
        {relevantDeletions.length > 0 && (
          <Box mt={2}>
            <DeletionTable
              relevantDeletions={relevantDeletions}
              exhibitId={exhibitId}
              caseId={caseId}
              duplicateId={duplicate ? pk : null}
              onPreview={handleOpenDeletionPreview}
            />
          </Box>
        )}
      </Box>
    </AnnotationsPaper>
  )
}

export const AnnotationSplit: React.FC<AnnotationSplitProps> = props => <AnnotationPartition {...props} />
export const AnnotationDuplicate: React.FC<AnnotationDuplicateProps> = props => (
  <AnnotationPartition {...props} duplicate />
)
