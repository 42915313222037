import React from "react"
import { Box, Button } from "@material-ui/core"
import { makeStyles, styled } from "@material-ui/core/styles"

import { useForm } from "react-hook-form"
import { useQuery, useQueryClient } from "react-query"
import { useMutation } from "react-query"
import { useParams } from "react-router-dom"

import { InputField, SelectInput } from "../common/form-components"
import { useHandleMessages } from "../common/messages/useHandleMessages"
import { createMissingExhibit, updateMissingExhibit, getMinimalProviders } from "../api"
import { queryKeys } from "../react-query/constants"
import { SECTIONS } from "./constants"
import { BaseMissingExhibit, Provider } from "./interfaces"

const useStyles = makeStyles(() => ({
  formWrapper: {
    display: "grid",
    gridTemplateColumns: "10fr 1fr",
  },
}))

interface Props {
  missingExhibitId?: Nullable<number>
  section: string
  callback: () => void
  name?: string
  importance?: string
  description?: string
  caseId?: Nullable<number>
  providerId?: Nullable<number>
  canEditExistingProvider?: boolean
  canSelectProvider?: boolean
  onPendingCreate?: Nullable<(pending: BaseMissingExhibit) => void>
}

const Form = styled(Box)({})

const FormRow = styled(Box)({
  display: "grid",
  gridTemplateColumns: "3fr 1fr",
  "& > :not(:first-child)": {
    marginLeft: "1rem",
  },
})

const FormField = styled(Box)({
  display: "flex",
  flexDirection: "column",
})

const Title = styled(Box)({
  fontWeight: 600,
  fontSize: "0.85rem",
  lineHeight: "1.88rem",
})

const SpacedButton = styled(Button)({
  marginLeft: "1rem",
  fontWeight: 700,
})

const SaveButton = styled(SpacedButton)(({ theme }) => ({
  color: theme.palette.info.main,
}))

const MissingDocumentForm = ({
  missingExhibitId = null,
  section,
  callback,
  name = "",
  importance = "",
  description = "",
  caseId = null,
  providerId = null,
  canEditExistingProvider = true,
  canSelectProvider = true,
  onPendingCreate = null,
}: Props): JSX.Element => {
  const classes = useStyles()
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      section: section,
      provider_id: providerId,
      name: name ?? "",
      importance: importance ?? "",
      description: description ?? "",
    },
  })
  const { showMessage } = useHandleMessages()
  const { data: providers } = useQuery([queryKeys.providersMinimal], () => getMinimalProviders(caseId), {
    enabled: section === SECTIONS.PROVIDERS,
  })

  const { id: paramCaseId } = useParams()
  if (!caseId) {
    caseId = !paramCaseId ? caseId : +paramCaseId
  }

  const queryClient = useQueryClient()

  const createMissingExhibitMutation = useMutation(createMissingExhibit, {
    onSuccess: () => {
      callback()
      queryClient.invalidateQueries(queryKeys.missingExhibits)
    },
    onError: () => {
      showMessage({
        type: "error",
        message: "There was an error while creating this missing document.",
      })
    },
  })

  const updateMissingExhibitMutation = useMutation(updateMissingExhibit, {
    onSuccess: () => {
      callback()
      queryClient.invalidateQueries(queryKeys.missingExhibits)
    },
    onError: () => {
      showMessage({
        type: "error",
        message: "There was an error while updating this missing document.",
      })
    },
  })

  const handleSave = handleSubmit(async data => {
    if (missingExhibitId !== null) {
      updateMissingExhibitMutation.mutate({ caseId, missingExhibitId, data })
    } else {
      if (onPendingCreate) {
        return onPendingCreate({
          name: data.name,
          importance: data.importance,
          description: data.description,
        })
      }
      createMissingExhibitMutation.mutate({ caseId, data })
    }
  })

  const handleClose = () => {
    callback()
  }

  return (
    <div className={classes.formWrapper} data-test="missing-document-form">
      <Form>
        <FormRow>
          <FormField>
            <Title>Missing Document Name</Title>
            <InputField
              control={control}
              name="name"
              variant="outlined"
              size="small"
              errors={errors}
              rules={{ required: "Name is required" }}
            />
          </FormField>
          <FormField>
            <Title>Importance</Title>
            <SelectInput
              control={control}
              name="importance"
              options={[
                { key: "high", display: "High" },
                { key: "medium", display: "Medium" },
                { key: "low", display: "Low" },
              ]}
              size="small"
              errors={errors}
              rules={{ required: "Importance is required" }}
            />
          </FormField>
        </FormRow>

        {section === SECTIONS.PROVIDERS && providers && canSelectProvider && (
          <FormRow>
            <FormField>
              <Title>Provider</Title>
              <SelectInput
                control={control}
                disabled={!canEditExistingProvider && !!watch("provider_id")}
                name="provider_id"
                options={providers.map((provider: Provider) => {
                  return { key: provider.pk, display: provider.name }
                })}
                size="small"
                errors={errors}
                rules={{ required: "Provider is required" }}
              />
            </FormField>
          </FormRow>
        )}

        <FormField>
          <Title>
            Description <i>(optional)</i>
          </Title>
          <InputField
            control={control}
            name={"description"}
            variant="outlined"
            size="small"
            multiline={true}
            maxRows={5}
            minRows={3}
          />
        </FormField>
      </Form>
      <Box mt="auto" display="flex">
        <SaveButton onClick={handleSave} color="inherit" variant="text" data-test="save-missing-document">
          Save
        </SaveButton>
        <SpacedButton onClick={handleClose} color="primary" variant="text">
          Cancel
        </SpacedButton>
      </Box>
    </div>
  )
}

export { MissingDocumentForm as default }
