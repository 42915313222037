import React from "react"
import { Box, Paper } from "@material-ui/core"
import { makeStyles, styled } from "@material-ui/core/styles"
import { Link } from "react-router-dom"

import { DetailedContract } from "../../common/interfaces"
import { RadialProgress } from "../../common/RadialProgress/RadialProgress"
import { roundDown } from "common/math"

const useStyles = makeStyles(theme => ({
  hideHyperlinks: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
}))

const Container = styled(Paper)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(1.5, 3),
}))

const Label = styled(Box)(() => ({
  fontWeight: "bold",
  fontSize: "16px",
  lineHeight: 2.13,
}))

interface CreditTrackerProps {
  contract: DetailedContract
}

export const CreditTracker = ({ contract }: CreditTrackerProps): JSX.Element => {
  const classes = useStyles()
  const { current_month_credits_used, monthly_credits } = contract

  return (
    <Link className={classes.hideHyperlinks} to="/settings/credits" data-test="credit-tracker">
      <Container>
        <Label>
          Credits used this Month: &nbsp; {roundDown(current_month_credits_used, { decimalPlaces: 2 })} /{" "}
          {roundDown(monthly_credits, { decimalPlaces: 2 })}
        </Label>
        <Box ml={1}>
          <RadialProgress
            used={current_month_credits_used}
            total={monthly_credits}
            size="2rem"
            showTotals={false}
            thick={true}
            showDot={false}
          />
        </Box>
      </Container>
    </Link>
  )
}
