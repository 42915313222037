import React from "react"
import useUser from "hooks/useUser"
import { INTAKE_STATUSES } from "requests/constants"
import { EventMessage } from "requests/components/EventMessage"
import {
  REQUEST_STATUS_MESSAGES_COMMON,
  REQUEST_WITH_REVISION_STATUS_MESSAGES,
  REQUEST_STATUS_MESSAGES,
  REQUEST_STATUS_REWORK_MESSAGES,
} from "./constants"
import { FEATURES, useFeatures } from "hooks/useFeatures"

interface StatusInfoProps extends TestableComponentProps {
  status: INTAKE_STATUSES
  hasRevision: boolean
}

export const StatusInfo = ({ status, hasRevision, ...rest }: StatusInfoProps): JSX.Element => {
  const { user } = useUser()
  const { isFeatureEnabled } = useFeatures()
  const isRevisionFeatureEnabled = isFeatureEnabled(FEATURES.REVISION)

  if (!user.isExternal) {
    const message = REQUEST_STATUS_MESSAGES_COMMON[status]

    return message ? <EventMessage {...message} {...rest} /> : <></>
  }

  const revisionMessages = hasRevision ? REQUEST_WITH_REVISION_STATUS_MESSAGES : REQUEST_STATUS_MESSAGES
  const messages = isRevisionFeatureEnabled ? revisionMessages : REQUEST_STATUS_REWORK_MESSAGES
  const message = messages[status] || REQUEST_STATUS_MESSAGES_COMMON[status]

  if (!message) {
    return <></>
  }

  return <EventMessage {...message} {...rest} />
}
