import { SETTINGS_LINK_ALT_LABELS, SETTINGS_LINK_LABELS } from "./constants"
import { NavLink, SETTINGS_ROUTE_PREFIX } from "./types"

export function getNavLinkOption(route: SETTINGS_ROUTE_PREFIX, alternativeLabel = false): NavLink {
  if (alternativeLabel) {
    return {
      label: SETTINGS_LINK_ALT_LABELS[route] || SETTINGS_LINK_LABELS[route],
      link: route,
    }
  }

  return {
    label: SETTINGS_LINK_LABELS[route],
    link: route,
  }
}
