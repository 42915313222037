import { PaginatedOptions } from "api/PaginatedList"
import { handleEmptyResponse } from "api/utils"
import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"
import { ApiError } from "apiHelper"
import { AttributeFiltersData } from "common/attributes-filter/types"
import { NonUniqueAttributesError } from "common/template-form/types"
import { isUndefined } from "lodash"
import { apiService } from "../ApiService"
import { ApiServiceType } from "../types"
import {
  LibraryVariableGroupServiceDeserializer,
  LibraryVariableServiceDeserializer,
  LibraryVariableServiceSerializer,
} from "./serializers"
import { LibraryVariableDto, NewLibraryVariableDto } from "./types"

interface VariableServiceOptions {
  variableId: BaseEntity["pk"]
}

export type VariableServiceEntityOptionsArg<Exists extends boolean = false> = {
  data: LibraryVariableDto
} & (Exists extends true ? { options: VariableServiceOptions } : { options?: never })

export type NewVariableServiceEntityOptionsArg<Exists extends boolean = false> = {
  data: NewLibraryVariableDto
} & (Exists extends true ? { options: VariableServiceOptions } : { options?: never })

export type LibraryVariableListOptions = PaginatedOptions & {
  attributeValues?: AttributeFiltersData
  variableGroup?: Nullable<PrimaryKey>
}

enum VARIABLE_API_PATHS {
  BASE = "library/variable",
}

enum VARIABLE_GROUP_API_PATHS {
  BASE = "library/variable-group",
}

class LibraryVariableGroupService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(options?: VariableServiceOptions, path?: VARIABLE_GROUP_API_PATHS): string {
    const pathParts = ["", VARIABLE_GROUP_API_PATHS.BASE, options?.variableId, path]

    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getVariableGroupList = withResponseSerializer(LibraryVariableGroupServiceDeserializer.fromListJSON, () =>
    handleEmptyResponse(this.apiService.get(null, this.getPath()))
  )

  createVariableGroup = withResponseSerializer(
    LibraryVariableGroupServiceDeserializer.definitionFromJSON,
    (data: { name: string }) => {
      return handleEmptyResponse(this.apiService.create(data, this.getPath()))
    }
  )
}

class LibraryVariableService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(options?: VariableServiceOptions, path?: VARIABLE_API_PATHS): string {
    const pathParts = ["", VARIABLE_API_PATHS.BASE, options?.variableId, path]

    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getVariablesList = withResponseSerializer(
    LibraryVariableServiceDeserializer.fromPaginatedListJSON,
    ({ attributeValues, variableGroup, ...options }: LibraryVariableListOptions) => {
      const queryItems: string[] = []

      if (variableGroup) {
        queryItems.push(`variable_group_id=${variableGroup}`)
      }

      if (attributeValues) {
        const values = LibraryVariableServiceSerializer.toAttributeValuesJSON(attributeValues)
        values.length && queryItems.push(`attribute_value_ids=${values.join(",")}`)
      }

      const query = queryItems.length ? `?${queryItems.join("&")}` : null

      return this.apiService.getPaginatedList(this.getPath(), query, options)
    }
  )

  createVariable = withRequestSerializer(
    LibraryVariableServiceSerializer.newVariableToDefinitionJSON,
    withResponseSerializer(
      LibraryVariableServiceDeserializer.definitionFromJSON,
      async ({ data }: NewVariableServiceEntityOptionsArg) => {
        try {
          return await handleEmptyResponse(this.apiService.create(data, this.getPath()))
        } catch (error) {
          if (error instanceof ApiError && error.response.status === 400) {
            throw new NonUniqueAttributesError()
          }

          throw error
        }
      }
    )
  )

  updateVariable = withRequestSerializer(
    LibraryVariableServiceSerializer.toDefinitionJSON,
    withResponseSerializer(
      LibraryVariableServiceDeserializer.definitionFromJSON,
      async ({ data, options }: VariableServiceEntityOptionsArg<true>) => {
        try {
          return await handleEmptyResponse(this.apiService.replace(data, this.getPath(options)))
        } catch (error) {
          if (error instanceof ApiError && error.response.status === 400) {
            throw new NonUniqueAttributesError()
          }

          throw error
        }
      }
    )
  )
}

export const libraryVariableService = new LibraryVariableService(apiService)

export const libraryVariableGroupService = new LibraryVariableGroupService(apiService)
