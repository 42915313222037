import React from "react"
import { Box } from "@material-ui/core"
import { User } from "common/models/user"
import { Info, RevisionHeader, RevisionTitle } from "./styled"
import { REVISION_REQUEST_STATE } from "./constants"
import { getRevisionRequestInfo } from "./utils"

interface RevisionRequestHeaderProps {
  user: User
  state: REVISION_REQUEST_STATE
  children?: Nullable<React.ReactElement>
}

export const RevisionRequestHeader = ({ state, user, children }: RevisionRequestHeaderProps): JSX.Element => {
  const info = getRevisionRequestInfo(state, user)

  return (
    <RevisionHeader>
      <Box>
        <RevisionTitle>Revision</RevisionTitle>
        {info && <Info>{info}</Info>}
      </Box>
      {children && <Box>{children}</Box>}
    </RevisionHeader>
  )
}
