import React, { useMemo } from "react"
import { AccordionDetails, AccordionSummary } from "@material-ui/core"
import { isEmpty, isNil } from "lodash"
import { CASE_SECTIONS } from "common/types/sections"
import { SectionTemplateDefinition } from "common/types/templates"
import { Attributes, AttributeValue } from "common/types/attributes"
import { AttributeFiltersData } from "common/attributes-filter"
import { getAttributeValues } from "common/attributes-filter/utils"
import {
  StyledAccordion,
  TemplateRowItem,
  TemplatesContainer,
  TemplatesTitle,
  TemplatesWrapper,
} from "./styled"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons"
import { Loading } from "common"

interface TemplateListProps {
  templates: Partial<Record<CASE_SECTIONS, SectionTemplateDefinition>>
  attributes: Attributes
  isLoading?: boolean
}

export function TemplateList({ templates, attributes, isLoading }: TemplateListProps): JSX.Element {
  const hasTemplates = !isEmpty(templates)
  const title = hasTemplates ? "Returned Templates" : "No returned templates"

  return (
    <TemplatesWrapper>
      <TemplatesTitle>{title}</TemplatesTitle>
      {(hasTemplates || isLoading) && (
        <TemplatesContainer>
          <Loading show={!!isLoading} inline label="Loading matching templates" />
          {Object.entries(templates).map(([section, template]) => (
            <TemplateListItem
              key={template.id}
              template={template}
              section={section as CASE_SECTIONS}
              attributes={attributes}
            />
          ))}
        </TemplatesContainer>
      )}
    </TemplatesWrapper>
  )
}

interface TemplateListItemProps {
  template: SectionTemplateDefinition
  section: CASE_SECTIONS
  attributes: Attributes
}

export function TemplateListItem({ template, attributes, section }: TemplateListItemProps): JSX.Element {
  const attributeValues = useMemo<AttributeFiltersData>(
    () =>
      getAttributeValues(attributes, {
        ...template.attributes.reduce((values, value) => ({ ...values, [value.id]: value.valueId }), {}),
      }),
    [attributes, template.attributes]
  )
  const attributesTree = useMemo(
    () => attributes.getAttributesTree(attributeValues),
    [attributeValues, attributes]
  )
  const values = useMemo(
    () =>
      attributesTree.map(attribute =>
        [attribute.value?.value, ...attribute.children.map(child => child.value?.value)].filter(
          (value): value is AttributeValue["value"] => !isNil(value)
        )
      ),
    [attributesTree]
  )
  const displayValue = [[section], ...values]
    .map(group => group.join("-"))
    .filter(group => Boolean(group))
    .join(" - ")

  return (
    <StyledAccordion TransitionProps={{ unmountOnExit: true }} elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <TemplateRowItem>{displayValue}</TemplateRowItem>
      </AccordionSummary>
      <AccordionDetails>
        <RichTextEditor readonly value={template.content} />
      </AccordionDetails>
    </StyledAccordion>
  )
}
