import React from "react"
import { Box } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"
import { useStyles } from "../styled"

import clsx from "clsx"

import { amountInDollars } from "utils"

import { Bill } from "./types"
import { Entry } from "../types"
import DatesCell from "./DatesCell"
import WritesOffCell from "./WriteOffsCell"

const Container = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1, 0, 2),
}))

const Table = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
}))

const Row = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
}))

const SummaryRow = styled(Row)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
}))

const FileName = styled(Box)(() => ({
  fontSize: "11px",
  fontStyle: "italic",
  lineHeight: 1.64,
  letterSpacing: "0.2px",
}))

interface BillTableProps {
  bill: Bill
  onEntryClick: (entry: Entry) => void
}

const BillTable = ({ bill, onEntryClick }: BillTableProps): JSX.Element => {
  const classes = useStyles()
  const { file_name, charges, total_billed, total_write_off } = bill

  return (
    <Container>
      <Table>
        {charges.map(({ charge, write_offs, dates }, index) => {
          return (
            <Row className={clsx(index % 2 === 0 ? classes.darkRow : classes.lightRow)} key={index}>
              <DatesCell dates={dates} onEntryClick={onEntryClick} />
              <Box>
                <Box
                  className={classes.entry}
                  onClick={() => {
                    if (charge?.entries?.length) {
                      onEntryClick(charge.entries[0])
                    }
                  }}
                >
                  {amountInDollars(charge.amount)}
                </Box>
              </Box>
              <WritesOffCell writeOffs={write_offs} onEntryClick={onEntryClick} />
            </Row>
          )
        })}
        <SummaryRow>
          <Box></Box>
          <Box>{amountInDollars(total_billed ?? 0)}</Box>
          <Box>{amountInDollars(total_write_off ?? 0)}</Box>
        </SummaryRow>
      </Table>
      <FileName>{file_name}</FileName>
    </Container>
  )
}

export { BillTable as default }
