import { REVISION_REQUEST_REASONS } from "common/models/revision-request"
import { RevisionRequestStateMessage } from "./types"

export const REVISION_REQUEST_REASON_LABELS: Record<REVISION_REQUEST_REASONS, string> = {
  [REVISION_REQUEST_REASONS.NEW_DOCUMENTS]: "New Documents",
  [REVISION_REQUEST_REASONS.ERROR_IN_DEMAND]: "Error in Demand",
  [REVISION_REQUEST_REASONS.OTHER]: "Other (Explained in Comments)",
}

export const revisionRequestReasonOptions: ValueOptions<REVISION_REQUEST_REASONS> = Object.entries(
  REVISION_REQUEST_REASON_LABELS
).map<ValueOption<REVISION_REQUEST_REASONS>>(([key, value]) => ({
  key: key as REVISION_REQUEST_REASONS,
  display: value,
}))

export enum REVISION_REQUEST_STATE {
  NOT_REQUESTED,
  REQUESTED,
  IN_PROGRESS,
  COMPLETED,
}

export const REVISION_REQUEST_STATE_MESSAGES: Record<
  REVISION_REQUEST_STATE,
  Nullable<RevisionRequestStateMessage>
> = {
  [REVISION_REQUEST_STATE.NOT_REQUESTED]: null,
  [REVISION_REQUEST_STATE.REQUESTED]: {
    title: "Your revision request has been submitted!",
    message:
      "A Legal Operations Associate will start on your request as soon as possible. Please expect to receive the revision within 5 business days.",
    conditionalMessage: "A revision request for missing records may incur additional credits.",
  },
  [REVISION_REQUEST_STATE.IN_PROGRESS]: {
    title: "A Legal Operations Associate has started on your request.",
    message: "You can expect to receive the revision within 5 business days.",
    conditionalMessage: "A revision request for missing records may incur additional credits.",
  },
  [REVISION_REQUEST_STATE.COMPLETED]: null,
}

export enum REVISION_REQUEST_INFO {
  EXTERNAL = "If you would like to request a revision, fill out the information as well as upload any documents below.",
  INTERNAL = "If the customer has requested a revision via email, please create the revision request here for them.",
}
