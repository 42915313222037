import React from "react"
import { Typography, Box } from "@material-ui/core"
import { UserAccountForm } from ".."

export function NewUserAccount() {
  return (
    <Box>
      <Typography variant="h4" component="h1">
        Create New User
      </Typography>
      <UserAccountForm />
    </Box>
  )
}
