import React, { CSSProperties, PropsWithChildren, useEffect, useMemo, useRef, useState } from "react"
import { NavigationPanel } from "./styled"

export function PdfNavigationPanel({ children }: PropsWithChildren<Record<never, never>>): JSX.Element {
  const [marginLeft, setMarginLeft] = useState(0)
  const panelRef = useRef<HTMLDivElement>(null)
  const viewportBoxRef = useRef<Nullable<DOMRectReadOnly>>(null)
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(([entry]) => {
        if (!panelRef.current) return

        if (!viewportBoxRef.current || viewportBoxRef.current.width !== entry.contentRect.width) {
          setMarginLeft(-panelRef.current.offsetLeft)
        }

        viewportBoxRef.current = entry.contentRect
      }),
    []
  )
  const style: CSSProperties = useMemo(
    () => ({
      marginLeft,
    }),
    [marginLeft]
  )

  useEffect(() => {
    resizeObserver.observe(document.documentElement)

    return () => resizeObserver.unobserve(document.documentElement)
  })

  return (
    <NavigationPanel style={style} ref={panelRef}>
      {children}
    </NavigationPanel>
  )
}
