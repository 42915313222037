import { isUndefined } from "lodash"
import { handleEmptyResponse } from "api/utils"
import { withResponseSerializer } from "api/withSerializers"

import { ApiServiceType } from "../types"
import { apiService } from "../ApiService"
import { SummariesDeserializer } from "./serializers"

enum SUMMARIES_API_PATHS {
  BASE = "summaries",
  TEMPLATE_KEYS = "llm/provider_template_keys",
  SUMMARIZE = "llm/summarize",
}
interface SummariesServiceOptions {
  providerId: BaseEntity["pk"]
  refresh: boolean
}

class SummariesService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(paths: SUMMARIES_API_PATHS): string {
    const pathParts = ["", SUMMARIES_API_PATHS.BASE, paths]
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getGeneratorTemplates = withResponseSerializer(SummariesDeserializer.fromJSON, () => {
    return handleEmptyResponse(this.apiService.get(null, this.getPath(SUMMARIES_API_PATHS.TEMPLATE_KEYS)))
  })

  getProviderSummary = ({ providerId, refresh = false }: SummariesServiceOptions) => {
    let query = `?provider=${providerId}`

    if (refresh) {
      query = query.concat(`&refresh=true`)
    }

    return handleEmptyResponse(this.apiService.get(null, this.getPath(SUMMARIES_API_PATHS.SUMMARIZE), query))
  }
}

export const summariesApiService = new SummariesService(apiService)
