import React from "react"
import { styled } from "@material-ui/core/styles"
import { Alert } from "@material-ui/lab"
import { TextField } from "@material-ui/core"

interface StyledMessageProps {
  error?: boolean
}

export const StyledEditorWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

export const StyledMessageWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  height: theme.spacing(5),
}))

export const StyledMessage = styled(({ error, ...props }: StyledMessageProps) => (
  <Alert {...props} severity={error ? "error" : "info"} icon={false} />
))(({ theme }) => ({
  borderRadius: "6px",
  padding: theme.spacing(1, 3),
  lineHeight: "24px",

  "& .MuiAlert-message": {
    padding: 0,
  },
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))
