import { useQuery } from "react-query"
import { queryKeys as defaultQueryKeys } from "react-query/constants"
import { getUserSession } from "api"
import { useState } from "react"
import { AuthorizedUser, NotAuthorizedUser, UserDto } from "common/models/user"
import { elasticApm } from "infrastructure/apm"

interface GetUserResponse {
  data: {
    user: UserDto
  }
}

interface UseUserHookReturn {
  isInitialized: boolean
  isLoading: boolean
  error: unknown // TODO: prepare common typed errors mechanism
  user: AuthorizedUser | NotAuthorizedUser
  isAuthorized: boolean
}

// TODO: move types to api when moved to typescript
function getUser(): Promise<GetUserResponse> {
  return getUserSession()
}

let cachedUser: AuthorizedUser | NotAuthorizedUser = new NotAuthorizedUser()

export default function useUser(queryKey: string = defaultQueryKeys.session): UseUserHookReturn {
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [user, setUser] = useState<AuthorizedUser | NotAuthorizedUser>(cachedUser)

  const { error, isLoading } = useQuery(queryKey, getUser, {
    retry: false,
    onSuccess: response => {
      setIsInitialized(true)

      if (response === null) {
        setUser(new NotAuthorizedUser())
        return
      }

      const nextUser = AuthorizedUser.fromJSON(response.data.user)

      if (!user.isEqual(nextUser)) {
        const user = AuthorizedUser.fromJSON(response.data.user)
        setUser(user)

        elasticApm.setUserContext(user)

        cachedUser = nextUser
      }
    },
    onError: () => {
      setIsInitialized(true)
      cachedUser = NotAuthorizedUser.fromJSON()
      setUser(cachedUser)
    },
  })

  return {
    isInitialized,
    isLoading,
    error,
    user,
    isAuthorized: user instanceof AuthorizedUser,
  }
}
