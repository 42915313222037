import React, { useState } from "react"
import {
  Box,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ErrorIcon from "@material-ui/icons/Error"
import { useParams } from "react-router-dom"
import { isEmpty, isEqual } from "lodash"
import DownloadIcon from "@material-ui/icons/GetApp"

import { GoToRequestButton } from "common/buttons/GoToRequestButton"
import { queryKeys } from "../react-query/constants"
import { dateFormat, timeFormat } from "../utils"
import { generateDemand, listGeneratedDemandsV2, downloadDemandV2, validateDemand } from "../api"

import GenericError from "../common/GenericError"
import { useInterval } from "../common/utils"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useHandleMessages } from "../common/messages/useHandleMessages"
import { DemandValidation } from "./DemandValidation"

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonProgress: {
    position: "absolute",
    left: "50%",
    top: "50%",
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
  },
}))

const STATES = {
  IDLE: "",
  DOWNLOADING: "inProgress",
  DONE: "success",
  ERROR: "error",
}

function DemandGenerationRequests({ caseId }) {
  const [generatedRequests, setGeneratedRequests] = useState(null)

  useQuery([queryKeys.allGeneratedDemands], () => listGeneratedDemandsV2(caseId), {
    onSuccess: data => {
      if (!isEqual(data.results, generatedRequests)) {
        setGeneratedRequests(data.results)
      }
    },
  })

  const listGeneratedDemandsMutation = useMutation(listGeneratedDemandsV2, {
    onSuccess: data => {
      if (!isEqual(data.results, generatedRequests)) {
        setGeneratedRequests(data.results)
      }
    },
  })

  const downloadDemand = async requestId => {
    try {
      const response = await downloadDemandV2(caseId, requestId)

      // create file url based on blob
      const fileBlob = await response.blob()
      const url = window.URL.createObjectURL(new Blob([fileBlob]))
      // create a link element
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `${caseId}.zip`)

      // add link to body and click on it
      document.body.appendChild(link)
      link.click()
      // clean up the link
      link.parentNode.removeChild(link)
    } catch (err) {
      // we should throw an error here
      // eslint-disable-next-line
      console.error(err)
    }
  }

  useInterval(() => {
    listGeneratedDemandsMutation.mutateAsync(caseId)
  }, 1000 * 5)

  const formatDateTime = dateStr => {
    const dateText = dateFormat.format(new Date(dateStr))
    const timeText = timeFormat.format(new Date(dateStr))

    return `${dateText} ${timeText}`
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Started</TableCell>
          <TableCell>Last Update</TableCell>
          <TableCell>Completed At</TableCell>
          <TableCell>Download</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!!generatedRequests?.length &&
          generatedRequests.map(r => (
            <TableRow key={r.pk}>
              <TableCell>{formatDateTime(r.requested_at)}</TableCell>
              <TableCell>{r.last_updated_at ? formatDateTime(r.last_updated_at) : "--"}</TableCell>
              <TableCell>{r.completed_at ? formatDateTime(r.completed_at) : "--"}</TableCell>
              <TableCell>
                {r.status == "in_progress" ? (
                  <IconButton disabled>
                    <CircularProgress size={18} />
                  </IconButton>
                ) : r.status == "error" ? (
                  <IconButton disabled>
                    <ErrorIcon color={"error"} />
                  </IconButton>
                ) : r.status == "complete" ? (
                  <>
                    <IconButton color={"green"} onClick={() => downloadDemand(r.pk)}>
                      <DownloadIcon />
                    </IconButton>
                  </>
                ) : (
                  "Not Started"
                )}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}

export function Review() {
  const classes = useStyles()
  const { id: caseId } = useParams()
  const [status, setStatus] = useState({ state: STATES.IDLE, message: "" })
  const { isLoading, data: validations, error } = useQuery([queryKeys.validateDemand, caseId], validateDemand)
  const [extendedExhibits, setExtendedExhibits] = useState(false)
  const queryClient = useQueryClient()
  const { showMessage } = useHandleMessages()

  const startDemand = async () => {
    try {
      await generateDemand(caseId, extendedExhibits)
      queryClient.invalidateQueries(queryKeys.allGeneratedDemands)
    } catch (err) {
      showMessage({
        type: "error",
        message: "There was an error creating the demand. Please contact engineering.",
      })
      // eslint-disable-next-line
      console.error(err)
      setStatus({ state: STATES.ERROR })
    }
  }

  if (error) {
    return <GenericError />
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.buttonWrapper}>
        <GoToRequestButton />
      </Box>
      {!isLoading &&
        (!isEmpty(validations?.errors) ||
          !isEmpty(validations?.warnings) ||
          !isEmpty(validations?.infos)) && <DemandValidation validations={validations} />}

      <FormControlLabel
        label="Also include inline images as exhibits."
        control={
          <Checkbox checked={extendedExhibits} onChange={(event, checked) => setExtendedExhibits(checked)} />
        }
      />
      <Button
        variant="contained"
        color="secondary"
        disabled={status.state === STATES.DOWNLOADING}
        onClick={startDemand}
        style={{ marginTop: "10px" }}
      >
        Generate Demand
      </Button>
      <DemandGenerationRequests caseId={parseInt(caseId)} />
      {status.state === STATES.DOWNLOADING && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Box>
  )
}
