import React from "react"

import { useQuery, UseQueryResult } from "react-query"
import MissingFieldsAlert from "./MissingFieldsAlert"

import { getHouseholdServicesValidationFields } from "../../api"
import { queryKeys } from "../../react-query/constants"

interface Props {
  caseId: number
}

type ValidationFieldValue = Nullable<string>
interface ValidationFields {
  future_household_impaired_years: ValidationFieldValue
  future_household_impaired_days: ValidationFieldValue
  future_household_impairment_rate: ValidationFieldValue
  date_of_birth: ValidationFieldValue
  state: ValidationFieldValue
}
type ValidationFieldKey = keyof ValidationFields

const errorFields: ValidationFieldKey[] = ["state", "date_of_birth"]
const MissingLossOfHouseholdAlert = ({ caseId }: Props): JSX.Element => {
  const { data, isLoading }: UseQueryResult<ValidationFields, boolean> = useQuery(
    [queryKeys.householdValidation, caseId],
    async () => {
      return await getHouseholdServicesValidationFields(caseId)
    }
  )

  if (isLoading || !data) {
    return <></>
  }

  return <MissingFieldsAlert data={data} errorFields={errorFields} />
}

export { MissingLossOfHouseholdAlert as default }
