import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import Button from "@material-ui/core/Button"
import { styled } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import HelpCenterOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import { Document, Page, pdfjs } from "react-pdf"
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api"
import { useQueryClient } from "react-query"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Divider } from "@material-ui/core"
import { previewDemand, validateDemand } from "../api"
import { useHandleMessages } from "../common/messages/useHandleMessages"
import { queryKeys } from "../react-query/constants"
import isEmpty from "lodash/isEmpty"
import { DemandValidation, DemandValidationInfo } from "./DemandValidation"
import "react-pdf/dist/esm/Page/TextLayer.css"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const StyledButton = styled(Button)(({ theme }) => {
  return {
    color: "#000000",
    fontSize: "14px",
    backgroundColor: theme.palette.button.grey,
    width: "150px",
    marginTop: theme.spacing(3),
    fontWeight: 600,
  }
})

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paper": {
      minWidth: "450px",
    },
  },
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  loadingContent: {
    display: "flex",
    alignItems: "center",
  },
  loader: {
    marginRight: theme.spacing(1),
  },
}))

interface DemandPreviewDialogProps {
  open: boolean
  onClose: () => void
  caseId: number
  extendedExhibits: boolean
}

function DemandPreviewDialog({
  open,
  onClose,
  caseId,
  extendedExhibits,
}: DemandPreviewDialogProps): JSX.Element {
  const classes = useStyles()

  const { showMessage } = useHandleMessages()

  const queryClient = useQueryClient()
  const [numPages, setNumPages] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = useCallback(() => {
    setIsLoading(false)
    onClose()
  }, [setIsLoading, onClose])

  const onDocumentLoadSuccess = useCallback(
    (pdfData: PDFDocumentProxy) => {
      setNumPages(pdfData.numPages)
    },
    [setNumPages]
  )

  const [pdfData, setPdfData] = useState<Nullable<Uint8Array>>(null)
  const [validation, setValidation] = useState<Nullable<DemandValidationInfo>>(null)
  const hasValidationInfo =
    validation !== null &&
    (!isEmpty(validation.errors) || !isEmpty(validation.infos) || !isEmpty(validation.warnings))

  useEffect(() => {
    const fetchPreview = async () => {
      setIsLoading(true)

      try {
        const demandValidation = await queryClient.fetchQuery<DemandValidationInfo>(
          [queryKeys.validateDemand, caseId],
          validateDemand
        )

        setValidation(demandValidation)

        if (Object.keys(demandValidation.errors).length > 0) {
          setIsLoading(false)
          return
        }
        const response = (await previewDemand(caseId, extendedExhibits)) as Response
        const buffer = await response.arrayBuffer()
        const data = new Uint8Array(buffer)

        setPdfData(data)
      } catch (error) {
        setPdfData(null)
        onClose()
        showMessage({
          type: "error",
          message: "Error occured while generating demand preview",
        })
      } finally {
        setIsLoading(false)
      }
    }

    if (open) {
      fetchPreview()
    }

    return () => {
      if (open) {
        setPdfData(null)
        setNumPages(0)
        setValidation(null)
      }
    }
  }, [
    open,
    onClose,
    showMessage,
    setIsLoading,
    setPdfData,
    setValidation,
    caseId,
    extendedExhibits,
    queryClient,
  ])

  const file = useMemo(() => (pdfData ? { data: pdfData } : null), [pdfData])

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="preview-demand-dialog-title"
      open={open}
      maxWidth="md"
      className={classes.root}
    >
      <DialogTitle id="preview-demand-dialog-title" className={classes.title}>
        <Typography variant="h6">Demand Preview</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          data-test="close-demand-preview"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        {hasValidationInfo && <DemandValidation validations={validation} />}
        {isLoading && (
          <Typography
            variant="body1"
            gutterBottom
            className={classes.loadingContent}
            data-test="loading-preview"
          >
            <CircularProgress color="secondary" size="1em" className={classes.loader} />
            Loading preview
          </Typography>
        )}
        {file && (
          <div data-test="demand-preview-wrapper">
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
              {Array(numPages)
                .fill(true)
                .map((_, i) => (
                  <Fragment key={i + 1}>
                    <Page pageNumber={i + 1} key={i + 1} renderAnnotationLayer={false} />
                    <Divider />
                  </Fragment>
                ))}
            </Document>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

interface DemandPreviewProps {
  caseId: number
  extendedExhibits: boolean
  iconButton?: boolean
}

const StyledPreviewButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "2px",
  borderRadius: "4px",
  cursor: "pointer",
  "&:hover": {
    background: theme.palette.grey[300],
  },
}))

export function DemandPreview({
  caseId,
  extendedExhibits,
  iconButton = false,
}: DemandPreviewProps): JSX.Element {
  const [isDialogOpened, setIsDialogOpened] = useState(false)
  const handleClose = useCallback(() => setIsDialogOpened(false), [setIsDialogOpened])
  const handleOpen = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      setIsDialogOpened(true)
    },
    [setIsDialogOpened]
  )

  if (iconButton) {
    return (
      <StyledPreviewButtonWrapper>
        <HelpCenterOutlinedIcon color={"action"} onClick={handleOpen} />
        <DemandPreviewDialog
          open={isDialogOpened}
          onClose={handleClose}
          caseId={caseId}
          extendedExhibits={extendedExhibits}
        />
      </StyledPreviewButtonWrapper>
    )
  }

  return (
    <>
      <StyledButton size="small" disabled={isDialogOpened} onClick={handleOpen} data-test="preview-demand">
        Preview Demand
      </StyledButton>
      <DemandPreviewDialog
        open={isDialogOpened}
        onClose={handleClose}
        caseId={caseId}
        extendedExhibits={extendedExhibits}
      />
    </>
  )
}
