import { ROOT_ITEM_KEY } from "../constants"
import { findParent } from "./projection"

export function isDragging(data, draggingItemId, itemId) {
  if (draggingItemId === itemId) {
    return true
  }

  const parent = findParent(data, itemId)

  return parent && parent.id !== ROOT_ITEM_KEY && isDragging(data, draggingItemId, parent.id)
}
