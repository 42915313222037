import React from "react"
import { ListItemText } from "@material-ui/core"
import { FileUploaderItem } from "../uploader-state/types"
import { FileListItem, FileListItemIcon } from "./styled"
import { FileUploadItemIcon } from "./FileUploadItemIcon"

export const FileUploadItem: React.FC<FileUploaderItem> = ({ metadata, state }) => {
  return (
    <FileListItem status={state.current} button>
      <FileListItemIcon>
        <FileUploadItemIcon state={state} />
      </FileListItemIcon>
      <ListItemText primary={metadata.name} />
    </FileListItem>
  )
}
