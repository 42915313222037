import React, { useCallback, useEffect, useState } from "react"
import { Box, Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { Message } from "./Message"

interface MessagesSnackbarProps {
  state: Nullable<{
    message: Message
    timeout: number
  }>
}

export const MessagesSnackbar: React.FC<MessagesSnackbarProps> = ({ children, state }) => {
  const [isShowed, setIsShowed] = useState<boolean>(true)
  const onClose = useCallback(() => setIsShowed(false), [])

  useEffect(() => {
    setIsShowed(true)
  }, [state?.message])

  return (
    <>
      {children}
      <Snackbar
        open={state !== null && isShowed}
        autoHideDuration={state?.timeout}
        onClose={onClose}
        data-test="message-snackbar"
      >
        <Box>
          {state !== null && (
            <Alert severity={state.message.type} onClose={onClose}>
              {state.message.message}
            </Alert>
          )}
        </Box>
      </Snackbar>
    </>
  )
}
