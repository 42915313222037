import { EXHIBIT_ITEM_TYPES, SECTIONS } from "./enums"
import {
  Exhibit,
  ExhibitDto,
  ExhibitGroup,
  ExhibitGroupDto,
  NewExhibitGroup,
  NewExhibitGroupDto,
  ExhibitSection,
  ExhibitSectionDto,
  Partition,
  PartitionDto,
} from "./Exhibit"
import { EXHIBIT_SORTING_VALUES, EXHIBIT_GROUPING_VALUES } from "../../settings/Firm/enums"
import {
  sortAllBillsThenRecords,
  sortAllRecordsThenBills,
  sortPerProviderBillsThenRecords,
  sortPerProviderRecordsThenBills,
  groupAsIndividualFile,
  groupAsPerProvider,
  groupAsPerProviderAndFileType,
} from "./sortingAndGrouping"

import { v4 } from "uuid"
import { BILL_TYPES, RECORD_TYPES } from "../../common/constants"

export function deserializeExhibitsItem(item: ExhibitDto): Exhibit {
  return {
    ...item,
    fileType: item.type,
    pk: item.pk.toString(),
    type: EXHIBIT_ITEM_TYPES.EXHIBIT,
    targetId: `${EXHIBIT_ITEM_TYPES.EXHIBIT}_${item.pk}`,
    first_contact: item.first_contact ? item.first_contact.replaceAll("-", "/") : null,
  }
}

export function deserializePartitionItem(item: PartitionDto): Partition {
  return {
    ...item,
    fileType: item.type,
    pk: item.pk.toString(),
    type: EXHIBIT_ITEM_TYPES.PARTITION,
    targetId: `${EXHIBIT_ITEM_TYPES.PARTITION}_${item.pk}`,
    provider: item.provider_id,
  }
}

export function deserializeExhibitsGroup(
  item: ExhibitGroupDto,
  isProviderAutofillEnabled: boolean
): ExhibitGroup {
  let itemChildren = item.children
  if (!isProviderAutofillEnabled) {
    itemChildren = itemChildren.filter(child => {
      return !(child as PartitionDto)?.start_page
    })
  }

  const children = itemChildren.map(child => {
    if (child.type === EXHIBIT_ITEM_TYPES.PARTITION) {
      return deserializePartitionItem(child as PartitionDto)
    }

    return deserializeExhibitsItem(child as ExhibitDto)
  })

  const partitionChildren = isProviderAutofillEnabled
    ? item.children_partitions.map(deserializePartitionItem)
    : []

  const allChildren = [...children, ...partitionChildren].sort((a, b) => {
    return (a.group_index ?? 0) - (b.group_index ?? 0) || (b.section_index ?? 0) - (a.section_index ?? 0)
  })

  return {
    ...item,
    pk: item.pk.toString(),
    type: EXHIBIT_ITEM_TYPES.GROUP,
    children: allChildren,
    section: item.section,
    targetId: `${EXHIBIT_ITEM_TYPES.GROUP}_${item.pk}`,
    render_title_page: item.render_title_page,
  }
}

export function deserializeExhibitsSection(
  item: ExhibitSectionDto,
  isProviderAutofillEnabled: boolean
): ExhibitSection {
  let itemChildren = item.children
  if (!isProviderAutofillEnabled) {
    itemChildren = itemChildren.filter(child => {
      return !(child as PartitionDto)?.start_page
    })
  }

  const children = itemChildren.map(child => {
    if ((child as ExhibitGroupDto).children) {
      return deserializeExhibitsGroup(child as ExhibitGroupDto, isProviderAutofillEnabled)
    }

    if ((child as PartitionDto)?.start_page) {
      return deserializePartitionItem(child as PartitionDto)
    }

    return deserializeExhibitsItem(child as ExhibitDto)
  })

  return {
    ...item,
    pk: item.section,
    type: EXHIBIT_ITEM_TYPES.SECTION,
    children: children,
    targetId: item.section,
  }
}

export function deserializeExhibits(
  items: ExhibitSectionDto[],
  isProviderAutofillEnabled: boolean
): ExhibitSection[] {
  return items.map(section => {
    return deserializeExhibitsSection(section, isProviderAutofillEnabled)
  })
}

export function serializeExhibitItem(item: Exhibit & Partial<{ children: [] }>): ExhibitDto {
  const { pk, ...dto } = item

  return {
    ...dto,
    pk: parseInt(pk),
  }
}

export function serializePartitionItem(item: Partition & Partial<{ children: [] }>): PartitionDto {
  const { pk, ...dto } = item

  return {
    ...dto,
    pk: parseInt(pk),
  }
}

export function serializeExhibitGroup(
  item: ExhibitGroup | NewExhibitGroup
): ExhibitGroupDto | NewExhibitGroupDto {
  const { children, pk, ...dto } = item
  const serializedChildren = children.map(child => {
    if (child.targetId.includes(EXHIBIT_ITEM_TYPES.PARTITION)) {
      return serializePartitionItem(child as Partition)
    }
    return serializeExhibitItem(child as Exhibit)
  })

  if ((item as NewExhibitGroup).isNew) {
    return {
      name: dto.name,
      children: serializedChildren,
      render_title_page: item.render_title_page,
    } as NewExhibitGroupDto
  }

  return {
    ...dto,
    pk: parseInt(pk),
    children: serializedChildren,
    render_title_page: item.render_title_page,
  }
}

export function serializeExhibitSection(item: ExhibitSection): ExhibitSectionDto {
  const { children, ...dto } = item
  const serializedChildren = children.map(child => {
    if (child.type === EXHIBIT_ITEM_TYPES.GROUP) {
      return serializeExhibitGroup(child as ExhibitGroup)
    }

    if (child.targetId.includes(EXHIBIT_ITEM_TYPES.PARTITION)) {
      return serializePartitionItem(child as Partition)
    }

    return serializeExhibitItem(child as Exhibit)
  })

  return {
    ...dto,
    children: serializedChildren,
  }
}

export function serializeExhibits(items: ExhibitSection[]): ExhibitSectionDto[] {
  return items.map(serializeExhibitSection)
}

export const addNewGroup = (
  section: SECTIONS,
  name = "Exhibit Name",
  renderTitlePage = true
): NewExhibitGroup => {
  return {
    name: name,
    children: [],
    childrenPartitions: [],
    children_partitions: [],
    pk: v4(),
    targetId: v4(),
    type: EXHIBIT_ITEM_TYPES.GROUP,
    isNew: true,
    section: section,
    render_title_page: renderTitlePage,
  } as NewExhibitGroup
}

export const isExhibitBillType = (exhibit: Exhibit): boolean => {
  return BILL_TYPES.includes(exhibit.fileType)
}

export const isExhibitRecordType = (exhibit: Exhibit): boolean => {
  return RECORD_TYPES.includes(exhibit.fileType)
}

const sortSections = (
  sections: ExhibitSection[],
  sortingOption: EXHIBIT_SORTING_VALUES
): ExhibitSection[] => {
  switch (sortingOption) {
    case EXHIBIT_SORTING_VALUES.PER_PROVIDER_RECORDS_AND_BILLS:
      return sortPerProviderRecordsThenBills(sections)
    case EXHIBIT_SORTING_VALUES.PER_PROVIDER_BILLS_AND_RECORDS:
      return sortPerProviderBillsThenRecords(sections)
    case EXHIBIT_SORTING_VALUES.ALL_RECORDS_THEN_BILLS:
      return sortAllRecordsThenBills(sections)
    case EXHIBIT_SORTING_VALUES.ALL_BILLS_THEN_RECORDS:
      return sortAllBillsThenRecords(sections)
    default:
      return sortPerProviderRecordsThenBills(sections)
  }
}

const groupSections = (
  sections: ExhibitSection[],
  groupingOption: EXHIBIT_GROUPING_VALUES,
  sortingOption: EXHIBIT_SORTING_VALUES
): ExhibitSection[] => {
  switch (groupingOption) {
    case EXHIBIT_GROUPING_VALUES.INDIVIDUAL_FILES:
      return groupAsIndividualFile(sections)
    case EXHIBIT_GROUPING_VALUES.PER_PROVIDER:
      return groupAsPerProvider(sections)
    case EXHIBIT_GROUPING_VALUES.ONE_FILE:
      return groupAsPerProviderAndFileType(sections, sortingOption)
    case EXHIBIT_GROUPING_VALUES.PER_PROVIDER_AND_FILETYPE:
      return groupAsPerProviderAndFileType(sections, sortingOption)
    default:
      throw new Error("Invalid Grouping Value")
  }
}

export const sortAndGroupExhibitsByOptions = (
  sections: ExhibitSection[],
  sortingOption: EXHIBIT_SORTING_VALUES,
  groupingOption: EXHIBIT_GROUPING_VALUES
): ExhibitSection[] => {
  sections = sortSections(sections, sortingOption)
  return groupSections(sections, groupingOption, sortingOption)
}
