import { useMemo } from "react"

export enum FEATURES {
  REVISION = "revision",
  TEMPLATES = "templates",
  ANNOTATION = "annotation",
  PROVIDER_TEMPLATES = "provider_templates",
  TEMPLATE_VARIABLES_TAB = "template_variables_tab",
  CASE_ATTRIBUTES = "case_attributes",
  PROVIDER_TEMPLATES_TAB = "provider_templates_tab",
  ANNOTATED_EXHIBIT_PREVIEW = "annotated_exhibit_preview",
  SPLIT_INTAKE_FILES = "split_intake_files",
  PROVIDER_AUTOFILL = "provider_autofill",
}

type FEATURE_LITERALS = `${FEATURES}`

type FeaturesReturn = {
  isFeatureEnabled: (feature: FEATURE_LITERALS, value?: string) => boolean
}

export function isFeatureEnabled(feature: FEATURE_LITERALS, value = "true"): boolean {
  const envProperty = `REACT_APP_${feature.toUpperCase()}`

  return process.env[envProperty] === value
}

export function useFeatures(): FeaturesReturn {
  const result = useMemo(() => ({ isFeatureEnabled }), [])

  return result
}
