import React, { ReactNode } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useQuery } from "react-query"

import { FirmFormController } from "./FirmFormController"
import { queryKeys } from "../../react-query/constants"
import { getFirmExtended } from "../../api"

export function FirmEdit(): ReactNode {
  const { id: firmId } = useParams()
  const navigate = useNavigate()
  const { isLoading, data, isFetching } = useQuery([queryKeys.firm, firmId], getFirmExtended)
  const onSaveAndExit = () => navigate(`/settings/firms`)

  if (isLoading || isFetching) {
    return null
  }

  return <FirmFormController data={data} onClose={onSaveAndExit} />
}
