import React from "react"
import { Box } from "@material-ui/core"
import { styled, Theme } from "@material-ui/core/styles"

interface CircleBadgeStyleProps {
  size: string
}

interface CircleBadgeProps extends CircleBadgeStyleProps {
  label: JSX.Element | string
}

const CircleBadgeContainer = styled(Box)<Theme, CircleBadgeStyleProps>(({ theme, size }) => ({
  margin: "auto",
  width: size,
  height: size,
  lineHeight: size,
  fontSize: `calc(${size} / 2)`,
  border: `solid ${theme.palette.common.black} 1px`,
  borderRadius: "50%",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.common.black,
  textAlign: "center",
  paddingRight: theme.spacing(0.25),
}))

export const CircleBadge: React.FC<CircleBadgeProps> = ({ label, size = "1.5rem" }): JSX.Element => {
  return <CircleBadgeContainer size={size}>{label}</CircleBadgeContainer>
}
