import { Variable } from "common/types/variables"
import { useContext, useMemo } from "react"
import { CaseVariablesContext } from "./context"

export interface UseCaseVariablesReturn {
  variables: Variable[]
}

export function useCaseVariables(customVariables: Variable[] = []): UseCaseVariablesReturn {
  const { variables } = useContext(CaseVariablesContext)
  const variablesMap = useMemo(() => {
    const entries = [...variables, ...customVariables].map<[Variable["name"], Variable]>(variable => [
      variable.name,
      variable,
    ])
    return new Map(entries)
  }, [variables, customVariables])

  const result = useMemo<UseCaseVariablesReturn>(
    () => ({ variables: [...variablesMap.values()] }),
    [variablesMap]
  )

  return result
}
