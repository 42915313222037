import React, { useCallback, useMemo, useReducer, useRef, useState } from "react"
import { DEFAULT_PAGE_SIZE } from "common/models/pagination"
import { queryKeys } from "react-query/constants"
import { useQuery } from "react-query"
import { Loading } from "common"
import { Pagination } from "common/pagination"
import { PageSizeSelect } from "common/pagination/PageSizeSelect"
import { LibraryVariableTableViewModel } from "common/models/library"
import { useLibraryVariableForm } from "common/template-form/useLibraryVariableForm"
import { libraryVariableGroupService, libraryVariableService } from "api/services/library-variable"
import { UPDATE_VARIABLE_FORM_STATE_MESSAGES } from "common/template-form/constants"
import { TextField, TextFieldProps } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { LibraryTable } from "../LibraryTable"
import { LibraryFilters, useAttributes, useFilterValues } from "../Filters"
import { templatesTabReducer } from "../State/templatesReducer"
import { INITIAL_TEMPLATES_TAB_STATE, LibraryTabStateContext } from "../State/constants"
import { NewVariable } from "../Forms/NewVariable"
import { StyledFiltersWrapper, StyledPageSizeWrapper, StyledSeparator } from "./styled"
import { LibraryVariableGroupDefinition } from "common/types/libraryVariables"
import useUser from "hooks/useUser"
import { canEditLibrary } from "settings/permissions"

const getOptionLabel = (data: LibraryVariableGroupDefinition) => data.name
const renderInput = (params: TextFieldProps) => (
  <TextField {...params} fullWidth variant={"outlined"} margin="none" label="Variable group" />
)

export function LibraryVariables(): Nullable<JSX.Element> {
  const attributes = useAttributes()
  const [state, dispatch] = useReducer(templatesTabReducer, INITIAL_TEMPLATES_TAB_STATE)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [variableGroup, setVariableGroup] = useState<Nullable<number>>(null)
  const { attributeValues } = useFilterValues()
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)
  const { user } = useUser()
  const canEdit = canEditLibrary(user.role)

  const variablesRef = useRef<Awaited<ReturnType<typeof libraryVariableService.getVariablesList>>>()
  const handleChangePageSize = useCallback((pageSize: number) => {
    setCurrentPage(1)
    setPageSize(pageSize)
  }, [])
  const { data: variableGroups } = useQuery(
    [queryKeys.libraryVariableGroups],
    libraryVariableGroupService.getVariableGroupList
  )
  const { isFetching } = useQuery(
    [queryKeys.libraryVariables, currentPage, pageSize, attributeValues, variableGroup],
    () =>
      libraryVariableService.getVariablesList({
        page: currentPage,
        pageSize,
        attributeValues,
        variableGroup,
      }),
    {
      meta: {
        disableLoader: true,
      },
      enabled: Boolean(attributes),
      onSuccess: nextTemplates => {
        variablesRef.current = nextTemplates
      },
    }
  )

  const onChangeHandler = useCallback((_, newValue: Nullable<LibraryVariableGroupDefinition>) => {
    if (newValue === null) {
      setVariableGroup(null)

      return
    }

    setVariableGroup(newValue.id)
  }, [])

  if (!variablesRef.current && !attributes) return null

  if (!attributes || !variableGroups) return <Loading show label="Loading attributes" />

  if (!variablesRef.current) {
    return (
      <LibraryTabStateContext.Provider value={contextValue}>
        <StyledFiltersWrapper>
          <LibraryFilters />
        </StyledFiltersWrapper>
        <Loading show label="Loading variables..." />
      </LibraryTabStateContext.Provider>
    )
  }

  const templatesTableData = new LibraryVariableTableViewModel(variablesRef.current.items, attributes)

  return (
    <LibraryTabStateContext.Provider value={contextValue}>
      <StyledFiltersWrapper>
        <Autocomplete
          style={{ width: "250px", marginRight: "8px", paddingTop: "8px" }}
          size="small"
          blurOnSelect
          clearOnBlur
          onChange={onChangeHandler}
          getOptionLabel={getOptionLabel}
          options={variableGroups}
          renderInput={renderInput}
        />
        <LibraryFilters showSection={false} />
        <StyledPageSizeWrapper>
          <PageSizeSelect pageSize={pageSize} onChange={handleChangePageSize} />
        </StyledPageSizeWrapper>
      </StyledFiltersWrapper>
      {canEdit ? <NewVariable /> : <StyledSeparator />}
      <LibraryTable
        tableData={templatesTableData}
        loading={isFetching}
        entityName="variables"
        getFormData={useLibraryVariableForm}
        formStatusMessageMap={UPDATE_VARIABLE_FORM_STATE_MESSAGES}
      />
      {variablesRef.current.count > 0 && (
        <Pagination
          pageCount={variablesRef.current.pageCount}
          page={variablesRef.current.page}
          count={variablesRef.current.count}
          onChange={setCurrentPage}
        />
      )}
    </LibraryTabStateContext.Provider>
  )
}
