import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import { Loading } from "common/loading"

import AppRoutes from "./Routes"
import { useHandleMessages } from "../common/messages/useHandleMessages"
import { MessagesSnackbar } from "../common/messages/MessagesSnackbar"
import {
  APP_CONTENT_ID,
  FOOTER_HEIGHT_MULTIPLIER,
  FOOTER_MARGIN_BOTTOM_MULTIPLIER,
  FOOTER_MARGIN_TOP_MULTIPLIER,
} from "./constants"

const useStyles = makeStyles(theme => ({
  main: {
    overflow: "auto",
    display: "grid",
    minHeight: `calc(100% - ${theme.spacing(
      FOOTER_HEIGHT_MULTIPLIER + FOOTER_MARGIN_TOP_MULTIPLIER + FOOTER_MARGIN_BOTTOM_MULTIPLIER
    )}px)`,
    gridTemplateRows: "1fr auto",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(0, 6),
    },
  },
}))

const MainContent = React.memo(function MainContent() {
  const classes = useStyles()
  return (
    <main className={classes.main} id={APP_CONTENT_ID}>
      <Loading />
      <AppRoutes />
    </main>
  )
})

export default function Main() {
  const { state: messageState, HandleMessagesProvider } = useHandleMessages()

  if (!HandleMessagesProvider) {
    return <MainContent />
  }

  return (
    <HandleMessagesProvider>
      <MainContent />
      <MessagesSnackbar state={messageState} />
    </HandleMessagesProvider>
  )
}
