import Markdown from "markdown-it"
import Token from "markdown-it/lib/token"
import { Descendant } from "slate"
import { DEFAULT_VALUE } from "../../defaultValue"
import {
  createList,
  createListItem,
  createListItemContent,
  createParagraph,
  createSoftLineBreak,
  createText,
} from "../../create"
import {
  CustomElement,
  CustomText,
  InlineContent,
  ListElement,
  ParagraphElement,
  StyledText,
} from "../../CustomEditor"
import { LEAF_BLOCK_ELEMENTS, LIST_BLOCK_ELEMENTS, LIST_ITEM_BLOCK_ELEMENTS } from "../../elements"

const parser = new Markdown({
  breaks: true,
})

type BlockToken = Token & { block: true }

const BOLD_STYLE_SEPARATOR = "\u200B\u02D0\u200B"
const BOLD_STYLE_ISOLATOR = "\u02B4\u02D0\u02B5"
const BOLD_ITALIC_STYLE_ISOLATOR = "^\u02D0\u02B4\u02D0^"
const STYLED_WHITESPACE_ISOLATOR = "^^\u02D0\u200B\u02D0^^"

export function encodeSpecialSymbols(input: string): string {
  return input
    .replaceAll(
      /(_\*\*|\*\*|_)(\s+)(\*\*_|\*\*|_)/g,
      `$1${STYLED_WHITESPACE_ISOLATOR}$2${STYLED_WHITESPACE_ISOLATOR}$3`
    )
    .replaceAll(
      /([^\\]|^)(_\*\*)([^*_])/g,
      `$1${BOLD_ITALIC_STYLE_ISOLATOR}$2${BOLD_ITALIC_STYLE_ISOLATOR}$3`
    )
    .replaceAll(
      /([^\\*_])(\*\*_)([^*_]|$)/g,
      `$1${BOLD_ITALIC_STYLE_ISOLATOR}$2${BOLD_ITALIC_STYLE_ISOLATOR}$3`
    )
    .replaceAll(/([^\\]|^)(\*\*)(\*\*)/g, `$1$2${BOLD_STYLE_SEPARATOR}$3`)
    .replaceAll(/([^*_]|^)(\*\*)([^*_]|$)/g, `$1${BOLD_STYLE_ISOLATOR}$2${BOLD_STYLE_ISOLATOR}$3`)
    .replaceAll(/((?:^|\n)\d+\.\s.+?\n\n)(\d+\.)/g, `$1${STYLED_WHITESPACE_ISOLATOR}\n\n$2`)
    .replaceAll(/((?:^|\n)\*\s+.+?\n\n)(\*\s+)/g, `$1${STYLED_WHITESPACE_ISOLATOR}\n\n$2`)
}

export function decodeSpecialSymbols(input: string): string {
  return input
    .replaceAll(STYLED_WHITESPACE_ISOLATOR, "")
    .replaceAll(BOLD_STYLE_SEPARATOR, "")
    .replaceAll(BOLD_STYLE_ISOLATOR, "")
    .replaceAll(BOLD_ITALIC_STYLE_ISOLATOR, "")
}

function getBlockByType(token: BlockToken): CustomElement {
  switch (token.tag) {
    case "p":
      return createParagraph()
    case "ul":
      return createList([], LIST_BLOCK_ELEMENTS.UNORDERED_LIST)
    case "ol":
      return createList([], LIST_BLOCK_ELEMENTS.ORDERED_LIST)
    case "li":
      return createListItem([createListItemContent([])])
    default:
      return createParagraph()
  }
}

const listBlockTypes: string[] = Object.values(LIST_BLOCK_ELEMENTS)

function appendChildBlock(parent: CustomElement, child: CustomElement): boolean {
  if (parent.type === LEAF_BLOCK_ELEMENTS.PARAGRAPH) return false

  if (parent.type === LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM && child.type === LEAF_BLOCK_ELEMENTS.PARAGRAPH) {
    parent.children[0].children.push(child)
    return true
  }

  if (parent.type === LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM && listBlockTypes.includes(child.type)) {
    if (!parent.children[0] || parent.children[1]) return false
    parent.children[1] = child as ListElement

    if (!parent.children[0].children.length) {
      parent.children[0].children.push(createParagraph())
    }

    return true
  }

  if (listBlockTypes.includes(parent.type) && child.type === LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM) {
    const parentList = parent as ListElement
    parentList.children.push(child)
    return true
  }

  return false
}

function appendInlineChildren(parent: CustomElement, children: InlineContent[]): boolean {
  if (parent.type === LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM) {
    const contentChildren = parent.children[0].children
    const parentBlock = contentChildren[contentChildren.length - 1] as ParagraphElement
    parentBlock.children = children
    return true
  }

  if (parent.type === LEAF_BLOCK_ELEMENTS.PARAGRAPH) {
    const previousInlineElement = parent.children[parent.children.length - 1]

    if (
      previousInlineElement &&
      Object.prototype.hasOwnProperty.call(previousInlineElement, "text") &&
      (previousInlineElement as CustomText).text === ""
    ) {
      parent.children.pop()
    }
    parent.children.push(...children)
    return true
  }

  return false
}

function processBlockToken(token: BlockToken, stack: CustomElement[], result: CustomElement[]): void {
  const block = getBlockByType(token as BlockToken)

  if (token.nesting === -1) {
    while (stack[stack.length - 1] && stack[stack.length - 1]?.type !== block.type) {
      stack.pop()
    }

    const lastBlock = stack.pop()

    if (lastBlock?.type === LEAF_BLOCK_ELEMENTS.PARAGRAPH && !lastBlock.children.length) {
      lastBlock.children.push(createText())
    }

    if (
      lastBlock?.type === LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM &&
      lastBlock.children[0].children.length === 0
    ) {
      lastBlock.children[0].children.push(createParagraph())
    }

    return
  }

  if (token.nesting === 1) {
    const lastBlock = stack[stack.length - 1]

    if (!lastBlock) {
      result.push(block)
      stack.push(block)
      return
    }

    const isAdded = appendChildBlock(stack[stack.length - 1] as CustomElement, block)

    if (isAdded) stack.push(block)
  }
}

function insertLineBreak(stack: CustomElement[], result: CustomElement[]): void {
  const block = stack[stack.length - 1]
  const lineBreak = createSoftLineBreak()

  if (!block) {
    result.push(createParagraph([{ text: "" }, lineBreak, { text: "" }]))
    return
  }

  appendInlineChildren(block, [lineBreak])
}

function insertText(textElements: CustomText[], stack: CustomElement[], result: CustomElement[]): void {
  const block = stack[stack.length - 1]

  if (!block) {
    result.push(createParagraph(textElements))
    return
  }

  appendInlineChildren(block, textElements)
}

function processInlineToken(token: Token, stack: CustomElement[], result: CustomElement[]): void {
  if (!token.children) return

  let textElements: CustomText[] = []
  let currentStyle: StyledText = {}

  for (const { content: text, tag, nesting } of token.children) {
    if (tag === "" && text) {
      const textContent = decodeSpecialSymbols(text)
      textElements.push(createText({ text: textContent, ...currentStyle }))
    }

    switch (tag) {
      case "em":
        currentStyle.italic = nesting === 1
        break
      case "strong":
        currentStyle.bold = nesting === 1
        break
      case "br":
        insertText(textElements, stack, result)
        insertLineBreak(stack, result)
        textElements = []
        currentStyle = {}
        break
    }
  }

  insertText(textElements, stack, result)
}

export function deserializeFromMarkdown(input: Nullable<string>): Descendant[] {
  if (input === null || input === "") {
    return DEFAULT_VALUE
  }

  const encodedInput = encodeSpecialSymbols(input)
  const parsedInput = parser.parse(encodedInput, {})

  const stack: CustomElement[] = []
  const result: CustomElement[] = []

  for (const token of parsedInput) {
    if (token.block && token.type !== "inline") {
      processBlockToken(token as BlockToken, stack, result)
      continue
    }

    if (token.type === "inline") {
      processInlineToken(token, stack, result)
    }
  }

  if (result.length) {
    return result
  }

  return DEFAULT_VALUE
}
