import React, { useMemo } from "react"
import { useQuery } from "react-query"
import { getEducationLevels } from "api"
import { queryKeys } from "react-query/constants"
import { InputField, SelectInput } from "common/form-components"

import { StyledGridRow } from "../styled"

export function NoSalaryForm({ control }) {
  const { isLoading, data: levels } = useQuery([queryKeys.steps], getEducationLevels)

  const optionstest = useMemo(() => {
    return [
      { key: "please_select", display: "Please select", disabled: true },
      ...(!isLoading ? levels.results.map(item => ({ key: item.key, display: item.display })) : []),
    ]
  }, [isLoading, levels])

  return (
    <StyledGridRow>
      <InputField name="occupation" control={control} variant="outlined" label="Occupation" />
      <SelectInput
        name="level_of_education"
        control={control}
        label="Level of education"
        options={optionstest}
      />
    </StyledGridRow>
  )
}
