import React, { useCallback, useEffect, useMemo, useState } from "react"
import { LibraryUseForm, NonUniqueAttributesError } from "common/template-form/types"
import { useAttributes } from "settings/Library/Filters"
import { SectionTemplateDefinitionAttribute } from "common/types/templates"
import { queryClient } from "react-query/queryClient"
import { getAttributeValues } from "common/attributes-filter/utils"
import { LibraryVariableFormData as LibraryVariableDataType } from "./types"
import { FORM_STATE_STATUS } from "./constants"
import { TemplateFormMessage } from "./TemplateFormMessage"
import { useMutation } from "react-query"
import { queryKeys } from "react-query/constants"
import { LibraryVariableFormData } from "./LibraryVariableFormData"
import { libraryVariableService } from "api/services/library-variable"

const mapAttribute = (attribute: SectionTemplateDefinitionAttribute) => [attribute.id, attribute.valueId]

export const useLibraryVariableForm: LibraryUseForm = ({
  entityId,
  onFinish,
  initialData,
  formStatusMessageMap,
}) => {
  const [formStatus, setFormStatus] = useState<FORM_STATE_STATUS>(FORM_STATE_STATUS.IDLE)
  const attributes = useAttributes()
  const [formData, setFormData] = useState<Nullable<LibraryVariableDataType>>(null)

  const initialAttributeValues = useMemo(() => {
    if (!initialData.initialAttributes || !attributes) return undefined

    return getAttributeValues(attributes, Object.fromEntries(initialData.initialAttributes.map(mapAttribute)))
  }, [attributes, initialData])

  const onSuccess = useCallback(() => {
    setFormStatus(FORM_STATE_STATUS.SUCCESS)
  }, [])

  const onError = useCallback((error: unknown) => {
    if (error instanceof NonUniqueAttributesError) {
      setFormStatus(FORM_STATE_STATUS.DATA_ERROR)
    } else {
      setFormStatus(FORM_STATE_STATUS.API_ERROR)
    }
  }, [])

  const mutationOptions = useMemo(
    () => ({
      onSuccess: () => {
        onSuccess()
        onFinish()

        queryClient.invalidateQueries(queryKeys.libraryVariables)
        queryClient.invalidateQueries(queryKeys.libraryVariableGroups)
      },
      onError: (error: unknown) => onError(error),
    }),
    [onSuccess, onFinish, onError]
  )

  const createVariableMutation = useMutation(libraryVariableService.createVariable, mutationOptions)
  const updateVariableMutation = useMutation(libraryVariableService.updateVariable, mutationOptions)

  const clearForm = useCallback(() => {
    setFormStatus(FORM_STATE_STATUS.IDLE)
  }, [])

  const handleCancel = useCallback(() => {
    onFinish()
    clearForm()
  }, [onFinish, clearForm])

  const handleSubmit = useCallback(async () => {
    if (!formData || !formData.content || !formData.name || !formData.sectionWithAttributes.attributeValues) {
      return setFormStatus(FORM_STATE_STATUS.LACK_OF_DATA)
    }

    if (typeof entityId === "undefined" && formData.groupId) {
      createVariableMutation.mutate({
        data: {
          content: formData.content,
          groupId: formData.groupId,
          attributes: formData.sectionWithAttributes.attributeValues,
        },
      })

      return
    }

    if (formData.groupId) {
      updateVariableMutation.mutate({
        options: { variableId: entityId },
        data: {
          groupId: formData.groupId,
          content: formData.content,
          attributes: formData.sectionWithAttributes.attributeValues,
        },
      })

      return
    }

    createVariableMutation.mutate({
      data: {
        content: formData.content,
        groupName: formData.name,
        attributes: formData.sectionWithAttributes.attributeValues,
      },
    })
  }, [formData, entityId, createVariableMutation, updateVariableMutation])

  const message = useMemo(() => {
    if (formStatus === FORM_STATE_STATUS.IDLE) return null

    const messageText = formStatusMessageMap[formStatus]

    if (!messageText) return null

    return <TemplateFormMessage clear={clearForm} message={messageText} formStatus={formStatus} />
  }, [clearForm, formStatus, formStatusMessageMap])

  const viewForm = useMemo(() => {
    return (
      <div>
        <p>{initialData.initialVariableContent}</p>
      </div>
    )
  }, [initialData])

  const editForm = useCallback(
    (footer: Nullable<JSX.Element>) =>
      attributes ? (
        <>
          <LibraryVariableFormData
            attributes={attributes}
            initialAttributeValues={initialAttributeValues}
            initialVariableContent={initialData.initialVariableContent}
            initialGroupId={initialData.initialGroupId}
            initialGroupName={initialData.initialGroupName}
            onChange={setFormData}
            error={formStatus === FORM_STATE_STATUS.DATA_ERROR}
            highlightEmptyFields={formStatus === FORM_STATE_STATUS.LACK_OF_DATA}
          />
          {footer}
        </>
      ) : null,
    [
      attributes,
      initialAttributeValues,
      initialData.initialVariableContent,
      initialData.initialGroupId,
      initialData.initialGroupName,
      formStatus,
    ]
  )

  const errorForm = formStatus === FORM_STATE_STATUS.API_ERROR || formStatus === FORM_STATE_STATUS.DATA_ERROR

  useEffect(() => {
    clearForm()
  }, [formData, clearForm])

  const isLoading = createVariableMutation.isLoading || updateVariableMutation.isLoading

  return { message, editForm, handleSubmit, handleCancel, isLoading, viewForm, clearForm, errorForm }
}
