import React, { useState } from "react"
import { Box, Chip, IconButton } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from "@material-ui/icons"

import { DetailItem, MinorTitle } from "./Firm"

import Table, { Column } from "../../../common/tables/Table"
import LinkButton from "../../../common/buttons/LinkButton"
import { Contract } from "../../../common/interfaces"
import { amountInDollars, formatDate } from "../../../utils"
import { isCancelled, isExpired } from "common/contracts/utils"
import { Alert, AlertTitle } from "@material-ui/lab"

const ActiveChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.mint.main,
  color: theme.palette.common.white,
}))

interface ContractLogProps {
  contracts: Contract[]
  onEditClick: (id: number) => void
}

const ContextualContractInfo: React.FC<{ contract: Contract }> = ({ contract }) => {
  if (contract.cancellation_date) {
    if (isCancelled({ contract })) {
      return <>{contract.cancellation_note}</>
    }

    return (
      <>
        <strong>Cancellation pending:</strong> {formatDate(contract.cancellation_date, "MM/dd/yyyy", true)}
      </>
    )
  }

  return <>{contract.note}</>
}

const CancelAlert: React.FC<{ contract: Contract }> = ({ contract }) =>
  isCancelled({ contract }) ? (
    <Alert severity="info">
      <AlertTitle>
        Contract finished on: {formatDate(contract.cancellation_date, "MM/dd/yyyy", true)}
      </AlertTitle>
      {contract.cancellation_note}
    </Alert>
  ) : (
    <Alert severity="info">
      <AlertTitle>
        Scheduled for cancellation: {formatDate(contract.cancellation_date, "MM/dd/yyyy", true)}
      </AlertTitle>
      {contract.cancellation_note}
    </Alert>
  )

export const ContractLog = ({ contracts, onEditClick }: ContractLogProps): React.ReactElement => {
  const [expandedRows, setExpandedRows] = useState<number[]>([])
  const columns: Column[] = [
    {
      id: "monthly_price",
      title: "Monthly Price",
      cellComponent: (contract: Contract) => {
        return amountInDollars(contract.monthly_price)
      },
    },
    {
      id: "start_date",
      title: "Start Date",
      cellComponent: (contract: Contract) => {
        return formatDate(contract.start_date, "MM/dd/yyyy", true)
      },
    },
    {
      id: "term_length",
      title: "# Months",
      align: "right",
      cellComponent: (contract: Contract) => {
        return contract.term_length
      },
    },
    {
      id: "credits_per_month",
      title: "Credits / Month",
      align: "right",
      cellComponent: (contract: Contract) => {
        return contract.monthly_credits
      },
    },
    {
      id: "notes",
      title: "Notes",
      cellComponent: (contract: Contract) => {
        return (
          <Box maxWidth="290px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            <ContextualContractInfo contract={contract} />
          </Box>
        )
      },
    },
    {
      id: "status",
      title: "",
      align: "center",
      cellComponent: (contract: Contract) => {
        if (isCancelled({ contract })) {
          return <Chip label="Cancelled" size="small" />
        }

        if (isExpired({ contract })) {
          return <Chip label="Expired" color="primary" size="small" />
        }

        if (contract.active) {
          return <ActiveChip label="Active" size="small" />
        }
        return ""
      },
    },
    {
      id: "edit",
      title: "",
      align: "center",
      cellComponent: (contract: Contract) => {
        if (isExpired({ contract }) || isCancelled({ contract })) {
          return ""
        }

        return (
          <LinkButton
            small={true}
            onClick={() => {
              onEditClick(contract.pk)
            }}
            data-test={`edit-contract-button-${contract.pk}`}
          >
            Edit
          </LinkButton>
        )
      },
    },
    {
      id: "expand",
      title: "",
      align: "center",
      cellComponent: (contract: Contract) => {
        const isExpanded = expandedRows.includes(contract.pk)
        return (
          <IconButton
            onClick={() => {
              if (isExpanded) {
                setExpandedRows(expandedRows.filter((id: number) => id !== contract.pk))
              } else {
                setExpandedRows([...expandedRows, contract.pk])
              }
            }}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )
      },
    },
  ]

  return (
    <Box data-test="contract-log">
      <Table
        records={contracts ?? []}
        columns={columns}
        expandElement={<ExpandedContractView record={null} />}
        expandedRows={expandedRows}
        emptyMessage="No contracts found for this firm"
      />
    </Box>
  )
}

interface ExpandedContractViewProps {
  record: Nullable<Contract>
}

const ExpandedContractView = ({ record }: ExpandedContractViewProps) => {
  if (record === null) return <></>

  return (
    <Box
      borderTop="solid 1px black"
      borderBottom="solid 1px black"
      width="100%"
      padding={2}
      data-test="expanded-contract"
    >
      {record.cancellation_date && <CancelAlert contract={record} />}
      {record.note && (
        <Box mt={1}>
          <MinorTitle mb={0.5} fontWeight="bold">
            Notes:
          </MinorTitle>
          <Box>{record.note}</Box>
        </Box>
      )}
      <Box mt={1} display="grid" gridTemplateColumns={"1fr 1fr 1fr"}>
        <Box lineHeight={2.14}>
          <DetailItem data-test="term-ends">
            <MinorTitle>Term Ends:</MinorTitle>
            <Box>{formatDate(record.end_date, "MM/dd/yyyy", true)}</Box>
          </DetailItem>
          <DetailItem data-test="total-credits-per-term">
            <MinorTitle>Total Credits Per Term:</MinorTitle>
            <Box>{record.monthly_credits * record.term_length}</Box>
          </DetailItem>
        </Box>
        <Box lineHeight={2.14}>
          <DetailItem data-test="medical-record-page-limit">
            <MinorTitle>Medical Record Page Limit:</MinorTitle>
            <Box>{record.medical_pages_cap}</Box>
          </DetailItem>
          <DetailItem data-test="overage-pages">
            <MinorTitle>Overage Pages:</MinorTitle>
            <Box>{record.medical_pages_overage}</Box>
          </DetailItem>
        </Box>
        <Box lineHeight={2.14}>
          <DetailItem data-test="overage-fees">
            <MinorTitle>Overage Fees:</MinorTitle>
            <Box>{amountInDollars(record.medical_pages_overage_fee)}</Box>
          </DetailItem>
          <DetailItem data-test="verdict-fee">
            <MinorTitle>Verdict Fee:</MinorTitle>
            <Box>{amountInDollars(record.verdict_fee)}</Box>
          </DetailItem>
        </Box>
      </Box>
    </Box>
  )
}
