import { Box } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"

export const StackCell = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}))

export const Link = styled(Box)(({ theme }) => ({
  color: theme.palette.blue.main,
  textDecoration: "underline",
  "&:hover": {
    cursor: "pointer",
  },
}))
