import React from "react"
import { Box, Divider, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { HelpOutline } from "@material-ui/icons"
import { amountInDollars } from "../../utils"
import { parseISO } from "date-fns"

const useStyles = makeStyles(theme => ({
  summaryItem: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    padding: theme.spacing(0, 4),
    "&:first-child": {
      paddingLeft: 0,
    },
  },
  summaryLabel: {
    fontSize: "12px",
    letterSpacing: "0.5px",
    textTransform: "uppercase",
  },
  summaryValue: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  helpIcon: {
    fontSize: "1rem",
    verticalAlign: "text-bottom",
  },
}))

const SummaryItem = ({ label, value }) => {
  const classes = useStyles()

  return (
    <Box className={classes.summaryItem}>
      <span className={classes.summaryLabel}>{label}</span>
      <span className={classes.summaryValue}>{value}</span>
    </Box>
  )
}

const SummaryWrapper = ({ children }) => (
  <Box display="flex">
    {React.Children.map(children, (child, index) => {
      if (child === null || child === false || index === React.Children.count(children) - 1) {
        // non-displayable child or last element, don't append divider
        return child
      }
      return (
        <>
          {child}
          <Divider orientation="vertical" flexItem />
        </>
      )
    })}
  </Box>
)

const Summary = ({ providers, hasCollateralSourceRule }) => {
  const classes = useStyles()
  const { totalMedicalExpenses, amountAccepted, beginContactDate, endContactDate } = getSummaryData(providers)

  return (
    <SummaryWrapper>
      <SummaryItem
        label={
          <>
            Total Medical Expenses{" "}
            <Tooltip placement="top" arrow title="Sum of all billed amounts">
              <HelpOutline className={classes.helpIcon} />
            </Tooltip>
          </>
        }
        value={providers.length ? amountInDollars(totalMedicalExpenses) : "N/A"}
      />
      {!hasCollateralSourceRule && (
        <SummaryItem
          label={
            <>
              Amount Accepted{" "}
              <Tooltip placement="top" arrow title="Total medical expenses minus the adjusted amounts">
                <HelpOutline className={classes.helpIcon} />
              </Tooltip>
            </>
          }
          value={providers.length ? amountInDollars(amountAccepted) : "N/A"}
        />
      )}
      <SummaryItem
        label="Date range of treatment"
        value={`${beginContactDate?.toLocaleDateString() || "N/A"} - ${
          endContactDate?.toLocaleDateString() || "N/A"
        }`}
      />
    </SummaryWrapper>
  )
}

export const getSummaryData = providers => {
  let totalMedicalExpenses = 0
  let totalAdjustedAmount = 0
  let beginContactDate = null
  let endContactDate = null

  // TODO: Add dates to array and select the most and least recent
  providers.forEach(provider => {
    totalMedicalExpenses += (provider.bills || []).reduce((sum, bill) => {
      if (bill.billed_amount) return sum + bill.billed_amount
      return sum
    }, 0)
    totalAdjustedAmount += (provider.bills || []).reduce((sum, bill) => {
      if (bill.adjusted_amount) return sum + bill.adjusted_amount
      return sum
    }, 0)
    if (provider.first_contact) {
      const firstContact = parseISO(provider.first_contact)
      if (!beginContactDate || firstContact < beginContactDate) {
        beginContactDate = firstContact
      }

      if (!endContactDate || firstContact > endContactDate) {
        endContactDate = firstContact
      }
    }
    if (provider.last_contact) {
      const lastContact = parseISO(provider.last_contact)
      if (!endContactDate || lastContact > endContactDate) {
        endContactDate = lastContact
      }
    }
  })

  return {
    totalMedicalExpenses,
    amountAccepted: totalMedicalExpenses - totalAdjustedAmount,
    beginContactDate,
    endContactDate,
  }
}

export default Summary
