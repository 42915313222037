import React, { useCallback, useMemo } from "react"
import { FormControl, FormHelperText, TextField } from "@material-ui/core"
import { AttributeFiltersData } from "common/attributes-filter"
import { NONE_VALUE, getAttributeValueOptions } from "common/attributes-filter/utils"
import { RootAttributeTreeItem, AttributeTreeItem } from "common/types/attributes"
import { AttributeField, AttributeTitle } from "./styled"
import { ATTRIBUTES_HELPER_TEXT_MAP } from "common/attributes-filter/constants"
import { Autocomplete } from "@material-ui/lab"

interface TemplateAttributeProps {
  optional?: boolean
  isLoading?: boolean
  attribute: RootAttributeTreeItem | AttributeTreeItem
  onChange: (values: AttributeFiltersData) => void
}

export function TemplateAttribute({
  attribute,
  onChange,
  isLoading,
  optional = false,
}: TemplateAttributeProps): JSX.Element {
  const handleChange = useCallback(
    (_, value) => {
      onChange({ [attribute.attribute.id]: !value || value?.key === NONE_VALUE ? null : value.key })
    },
    [onChange, attribute]
  )
  const valueOptions = getAttributeValueOptions(attribute.attribute)
  const helperText = ATTRIBUTES_HELPER_TEXT_MAP[attribute.attribute.title]
  const value = useMemo(
    () => valueOptions.options.find(({ key }) => (attribute.value?.id ?? NONE_VALUE) === key),
    [attribute.value?.id, valueOptions.options]
  )

  const renderInput = useCallback(params => {
    return <TextField {...params} variant="outlined" />
  }, [])

  const renderOption = useCallback(data => {
    return <span data-test={data.display}>{data.display}</span>
  }, [])

  const getOptionLabel = useCallback(option => option.display, [])

  const childrenRender = useCallback(
    childAttribute => {
      return (
        <TemplateAttribute
          key={childAttribute.attribute.id}
          attribute={childAttribute}
          onChange={onChange}
          optional
        />
      )
    },
    [onChange]
  )

  return (
    <>
      <AttributeField>
        <AttributeTitle>
          {attribute.attribute.title}
          {optional ? " (optional)" : null}
        </AttributeTitle>
        <FormControl size="small">
          <Autocomplete
            loading={isLoading}
            size="small"
            blurOnSelect
            style={{ minWidth: 210 }}
            value={value}
            data-test={attribute.attribute.title}
            options={valueOptions.options}
            renderInput={renderInput}
            renderOption={renderOption}
            getOptionLabel={getOptionLabel}
            onChange={handleChange}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      </AttributeField>
      {"children" in attribute ? attribute.children.map(childrenRender) : null}
    </>
  )
}
