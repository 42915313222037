export const LOCAL_STORAGE_KEY = "evenup-providers"
const DAYS = 30
const HOURS = 24
const MINUTES = 60
const SECONDS = 60

const THIRTY_DAYS_MS = DAYS * HOURS * MINUTES * SECONDS * 1000

export interface LocalStorageProvider {
  date: number
  caseId: string
  editing: boolean
  providerId: PrimaryKey
}

export class ProviderLocalStorage {
  private providers: LocalStorageProvider[]

  constructor() {
    const stringifiedProviders = localStorage.getItem(LOCAL_STORAGE_KEY)

    this.providers = stringifiedProviders ? JSON.parse(stringifiedProviders) : []
    // remove providers that are stored but have been not changed for the last month
    this.checkExpiredProviders()
  }

  private checkExpiredProviders() {
    const currentDate = new Date().valueOf()

    const expiredIds = this.providers.reduce((acc: string[], current) => {
      if (currentDate - current.date > THIRTY_DAYS_MS) {
        acc.push(current.caseId)
      }

      return acc
    }, [])

    this.removeProviders(expiredIds)
  }

  private removeProviders(caseIds: string[]) {
    this.providers = this.providers.filter(({ caseId: providerCaseId }) => !caseIds.includes(providerCaseId))

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.providers))
  }

  onChange(editing: boolean, caseId: string, providerId: PrimaryKey): void {
    if (!caseId) {
      return
    }

    const currentProvider = this.providers.find(({ caseId: providerCaseId }) => providerCaseId === caseId)

    if (currentProvider) {
      this.providers = this.providers.reduce((currentProviders: LocalStorageProvider[], provider) => {
        if (provider.caseId !== caseId) {
          currentProviders.push(provider)
        } else {
          currentProviders.push({ caseId, editing, providerId, date: Date.now() })
        }

        return currentProviders
      }, [])
    } else {
      this.providers.push({ caseId, editing, providerId, date: Date.now() })
    }

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.providers))
  }

  removeProvider(caseId: string): void {
    this.removeProviders([caseId])
  }

  getSavedProvider(caseId: string): LocalStorageProvider | undefined {
    const localStorageProvider = this.providers.find(
      ({ caseId: providerCaseId }) => providerCaseId === caseId
    )

    return localStorageProvider
  }
}

export const providerLocalStorage = new ProviderLocalStorage()
