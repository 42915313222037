import React from "react"
import { Box, IconButton, IconButtonProps, PropTypes, Toolbar } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useSlate, ReactEditor } from "slate-react"
import clsx from "clsx"
import { Editor } from "../../Editor"
import { Editor as WithVariablesEditor } from "../../features/variables/Editor"
import { TEXT_STYLES, ELEMENT_STYLES } from "../../styles"
import { CustomEditor } from "../../CustomEditor"
import { LEAF_BLOCK_ELEMENTS, LIST_BLOCK_ELEMENTS } from "../../elements"
import {
  FormatBold as FormatBoldIcon,
  FormatIndentDecrease as FormatIndentDecreaseIcon,
  FormatIndentIncrease as FormatIndentIncreaseIcon,
  FormatItalic as FormatItalicIcon,
  FormatUnderlined as FormatUnderlinedIcon,
  FormatColorFill as FormatColorFillIcon,
  FormatListBulleted as FormatListBulleted,
  FormatListNumbered as FormatListNumberedIcon,
  Redo as RedoIcon,
  Undo as UndoIcon,
} from "@material-ui/icons"
import { EDITOR_FEATURES } from "../../features/constants"
import { AddVariableButton, AddVariableButtonProps } from "./AddVariableButton"
import { Variable } from "common/types/variables"
import { noop } from "lodash"

type BLOCK_ELEMENTS = LEAF_BLOCK_ELEMENTS | LIST_BLOCK_ELEMENTS

type ToolbarState = {
  [K in TEXT_STYLES]: boolean
} & {
  [K in keyof ELEMENT_STYLES]: ELEMENT_STYLES[K]
} & {
  [K in BLOCK_ELEMENTS]: boolean
} & {
  [K in EDITOR_FEATURES]: Nullable<boolean>
}

type ToolbarButtonHandler = (...args: any[]) => void

type ToolbarButtonHandlers = {
  [K in keyof ToolbarState]: ToolbarButtonHandler
}

const useStyles = makeStyles(theme => ({
  toolbar: {
    padding: theme.spacing(1),
    minHeight: 0,
    display: "flex",
  },
  toolbarActive: {
    opacity: 1,
  },
  toolbarGroup: {
    "&:not(:last-child)": {
      paddingRight: theme.spacing(1),
      marginRight: theme.spacing(1),
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  button: {
    borderRadius: theme.shape.borderRadius,
    margin: "0 1px",
  },
  textButton: {
    display: "flex",
    width: theme.spacing(3),
    height: theme.spacing(3),
    justifyContent: "center",
    alignItems: "center",
  },
  activeStyleButton: {
    background: theme.palette.grey[200],
    "&:hover": {
      background: theme.palette.grey[300],
    },
  },
  notActiveStyleButton: {},
}))

function getToolbarButtonHandlerSafe(handler: ToolbarButtonHandler): ToolbarButtonHandler {
  return (...args) => {
    try {
      return handler(...args)
    } catch {
      return
    }
  }
}

function getToolbarButtonHandlers(editor: CustomEditor): ToolbarButtonHandlers {
  return {
    [TEXT_STYLES.BOLD]: getToolbarButtonHandlerSafe(() => Editor.toggleTextStyle(editor, TEXT_STYLES.BOLD)),
    [TEXT_STYLES.ITALIC]: getToolbarButtonHandlerSafe(() =>
      Editor.toggleTextStyle(editor, TEXT_STYLES.ITALIC)
    ),
    [TEXT_STYLES.UNDERLINE]: getToolbarButtonHandlerSafe(() =>
      Editor.toggleTextStyle(editor, TEXT_STYLES.UNDERLINE)
    ),
    [TEXT_STYLES.HIGHLIGHT]: getToolbarButtonHandlerSafe(() =>
      Editor.toggleTextStyle(editor, TEXT_STYLES.HIGHLIGHT)
    ),
    [LEAF_BLOCK_ELEMENTS.PARAGRAPH]: getToolbarButtonHandlerSafe(() =>
      Editor.setElementType(editor, LEAF_BLOCK_ELEMENTS.PARAGRAPH)
    ),
    [LIST_BLOCK_ELEMENTS.ORDERED_LIST]: getToolbarButtonHandlerSafe(() =>
      Editor.toggleList(editor, LIST_BLOCK_ELEMENTS.ORDERED_LIST)
    ),
    [LIST_BLOCK_ELEMENTS.UNORDERED_LIST]: getToolbarButtonHandlerSafe(() =>
      Editor.toggleList(editor, LIST_BLOCK_ELEMENTS.UNORDERED_LIST)
    ),
    [EDITOR_FEATURES.VARIABLES]: getToolbarButtonHandlerSafe((variable: Variable) => {
      if (!Editor.isVariablesEditor(editor)) return

      if (variable) {
        WithVariablesEditor.insertVariable(editor, variable)
      }
    }),
  }
}

function getToolbarStateValueSafe(getState: () => boolean): boolean {
  try {
    return getState()
  } catch {
    return false
  }
}

function getToolbarState(editor: CustomEditor): ToolbarState {
  return {
    [TEXT_STYLES.BOLD]: getToolbarStateValueSafe(() => Editor.isTextStyleActive(editor, TEXT_STYLES.BOLD)),
    [TEXT_STYLES.ITALIC]: getToolbarStateValueSafe(() =>
      Editor.isTextStyleActive(editor, TEXT_STYLES.ITALIC)
    ),
    [TEXT_STYLES.UNDERLINE]: getToolbarStateValueSafe(() =>
      Editor.isTextStyleActive(editor, TEXT_STYLES.UNDERLINE)
    ),
    [TEXT_STYLES.HIGHLIGHT]: getToolbarStateValueSafe(() =>
      Editor.isTextStyleActive(editor, TEXT_STYLES.HIGHLIGHT)
    ),
    [LEAF_BLOCK_ELEMENTS.PARAGRAPH]: getToolbarStateValueSafe(() =>
      Editor.isElementsOfType(editor, LEAF_BLOCK_ELEMENTS.PARAGRAPH)
    ),
    [LIST_BLOCK_ELEMENTS.ORDERED_LIST]: getToolbarStateValueSafe(() =>
      Editor.isInListOfType(editor, LIST_BLOCK_ELEMENTS.ORDERED_LIST)
    ),
    [LIST_BLOCK_ELEMENTS.UNORDERED_LIST]: getToolbarStateValueSafe(() =>
      Editor.isInListOfType(editor, LIST_BLOCK_ELEMENTS.UNORDERED_LIST)
    ),
    [EDITOR_FEATURES.VARIABLES]: (function () {
      if (!Editor.isVariablesEditor(editor)) return null

      return Boolean(editor.selection) && editor.variables.size > 0
    })(),
  }
}

export const RichTextToolbar: React.FC = () => {
  const classes = useStyles()
  const editor = useSlate()
  const toolbarState = getToolbarState(editor)
  const toolbarHandlers = getToolbarButtonHandlers(editor)
  const toolbarClassName = clsx(classes.toolbar)
  const getButtonColor = (isActive: boolean): PropTypes.Color => (isActive ? "primary" : "default")
  const getButtonClass = (isActive: boolean) =>
    isActive ? classes.activeStyleButton : classes.notActiveStyleButton
  const getDefaultButtonProps = (isActive: boolean) => ({
    size: "small" as IconButtonProps["size"],
    color: getButtonColor(isActive),
    className: clsx(classes.button, getButtonClass(isActive)),
  })
  const getTextStyleButtonProps = (state: TEXT_STYLES) => ({
    ...getDefaultButtonProps(toolbarState[state]),
    onMouseDown: (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      toolbarHandlers[state]()
    },
  })
  const getListTypeButtonProps = (type: LIST_BLOCK_ELEMENTS) => {
    const defaultButtonProps = getDefaultButtonProps(toolbarState[type])

    return {
      ...defaultButtonProps,
      onMouseDown: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        toolbarHandlers[type]()
      },
    }
  }
  const getListIndentButtonProps = (type: "increase" | "decrease") => ({
    ...getDefaultButtonProps(false),
    disabled:
      !toolbarState[LIST_BLOCK_ELEMENTS.ORDERED_LIST] && !toolbarState[LIST_BLOCK_ELEMENTS.UNORDERED_LIST],
    onMouseDown: (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      type === "increase" ? Editor.increaseListDepth(editor) : Editor.decreaseListDepth(editor)
    },
  })
  const getVariablesButtonProps = (): AddVariableButtonProps => {
    if (!Editor.isVariablesEditor(editor)) {
      return {
        disabled: true,
        onVariableSelect: noop,
        onVariablePreview: noop,
        onOpen: noop,
        onClose: noop,
      }
    }

    return {
      disabled: !toolbarState[EDITOR_FEATURES.VARIABLES],
      onVariableSelect: variable => {
        ReactEditor.focus(editor)
        WithVariablesEditor.finalizeAddVariable(editor, variable)
      },
      onVariablePreview: variable => {
        WithVariablesEditor.updateDraftVariable(editor, variable)
      },
      onOpen: () => {
        WithVariablesEditor.insertDraftVariable(editor)
      },
      onClose: () => {
        WithVariablesEditor.clearDraftVariables(editor, { undoSideEffect: true })
      },
    }
  }

  const hasUndos = Boolean(editor.history.undos.length)
  const hasRedos = Boolean(editor.history.redos.length)

  const handleUndo = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    hasUndos && editor.undo()
  }

  const handleRedo = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    hasRedos && editor.redo()
  }

  const handleInsertSectionSymbol = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    Editor.insertSectionSymbol(editor)
  }

  return (
    <Toolbar variant="dense" disableGutters className={toolbarClassName}>
      <Box className={classes.toolbarGroup}>
        <IconButton {...getDefaultButtonProps(false)} onMouseDown={handleUndo} disabled={!hasUndos}>
          <UndoIcon />
        </IconButton>
        <IconButton {...getDefaultButtonProps(false)} onMouseDown={handleRedo} disabled={!hasRedos}>
          <RedoIcon />
        </IconButton>
      </Box>
      <Box className={classes.toolbarGroup}>
        <IconButton {...getTextStyleButtonProps(TEXT_STYLES.BOLD)}>
          <FormatBoldIcon />
        </IconButton>
        <IconButton {...getTextStyleButtonProps(TEXT_STYLES.ITALIC)}>
          <FormatItalicIcon />
        </IconButton>
        <IconButton {...getTextStyleButtonProps(TEXT_STYLES.UNDERLINE)}>
          <FormatUnderlinedIcon />
        </IconButton>
        <IconButton {...getTextStyleButtonProps(TEXT_STYLES.HIGHLIGHT)}>
          <FormatColorFillIcon />
        </IconButton>
      </Box>
      <Box className={classes.toolbarGroup}>
        <IconButton {...getListTypeButtonProps(LIST_BLOCK_ELEMENTS.UNORDERED_LIST)}>
          <FormatListBulleted />
        </IconButton>
        <IconButton {...getListTypeButtonProps(LIST_BLOCK_ELEMENTS.ORDERED_LIST)}>
          <FormatListNumberedIcon />
        </IconButton>
      </Box>
      <Box className={classes.toolbarGroup}>
        <IconButton {...getListIndentButtonProps("decrease")}>
          <FormatIndentDecreaseIcon />
        </IconButton>
        <IconButton {...getListIndentButtonProps("increase")}>
          <FormatIndentIncreaseIcon />
        </IconButton>
      </Box>
      <Box className={classes.toolbarGroup}>
        <IconButton {...getDefaultButtonProps(false)} onMouseDown={handleInsertSectionSymbol}>
          <span className={classes.textButton}>§</span>
        </IconButton>
      </Box>
      {toolbarState[EDITOR_FEATURES.VARIABLES] !== null && (
        <Box className={classes.toolbarGroup}>
          <AddVariableButton {...getVariablesButtonProps()} />
        </Box>
      )}
    </Toolbar>
  )
}
