import React, { PropsWithChildren, createContext, memo, useCallback, useContext } from "react"

export type ThumbnailCacheContextType = Partial<Record<number, string>>

const ThumbnailCacheContext = createContext<ThumbnailCacheContextType>({})

export const ThumbnailCacheProvider = memo<PropsWithChildren<{ value: ThumbnailCacheContextType }>>(
  function ThumbnailCacheProvider({ children, value }): JSX.Element {
    return <ThumbnailCacheContext.Provider value={value}>{children}</ThumbnailCacheContext.Provider>
  }
)

export function useThumbnailCache(page: number): [string | undefined, (page: number, value: string) => void] {
  const cache = useContext(ThumbnailCacheContext)

  const setCacheItem = useCallback(
    (page: number, value: string): void => {
      cache[page] = value
    },
    [cache]
  )

  return [cache[page], setCacheItem]
}
