import React from "react"
import { Box, Checkbox, Chip, FormControlLabel, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { useFormContext } from "./FormContext"
import FacetSearch from "./FacetSearch"
import { AwardAmountFacet } from "./AwardAmountFacet"

const useStyles = makeStyles(() => ({
  checkboxGroup: {
    display: "flex",
    alignItems: "center",
    maxWidth: "14rem",
  },
  label: {
    fontSize: "0.75rem",
    maxWidth: "7.5rem",
    overflow: "visible",
    wordWrap: "break-word",
    whiteSpace: "break-spaces",
  },
  chip: {
    marginLeft: "auto",
    fontSize: "0.75rem",
  },
}))

export const FacetItem = ({ facetValue, facetKey, onChange = null }) => {
  const classes = useStyles()
  const { queryActions, queryState, handleQueryUpdate } = useFormContext()
  const { label, data, type } = facetValue

  const handleChange = event => {
    const name = event.target.dataset.facet
    const value = event.target.value

    event.target.checked
      ? handleQueryUpdate(queryActions.APPLY_FACET, { name, value })
      : handleQueryUpdate(queryActions.REMOVE_FACET, { name, value })
  }

  const dataItems = data?.map((dataPoint, index) => {
    // since the value can be an array (for ranged facets), we cannot rely on the includes check
    // we need to check array equality in that case
    const isChecked = Array.isArray(dataPoint.value)
      ? queryState.query[facetKey] && dataPoint.value.every(val => queryState.query[facetKey].includes(val))
      : !!queryState.query[facetKey]?.includes(dataPoint.value)

    return (
      <Box key={`${facetKey}-${index}`}>
        <Box className={classes.checkboxGroup}>
          <FormControlLabel
            control={
              <Checkbox
                value={dataPoint.value}
                onChange={onChange || handleChange}
                checked={isChecked}
                inputProps={{ "data-facet": facetKey, name: `${facetKey}-${dataPoint.value}` }}
              />
            }
            label={
              <Typography variant="caption" display="block" className={classes.label}>
                {dataPoint.label || dataPoint.value || "(no label)"}
              </Typography>
            }
          />
          <Chip variant="outlined" size="small" label={dataPoint.count} className={classes.chip} />
        </Box>
      </Box>
    )
  })

  return (
    <Box key={facetKey}>
      <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
        {label}
      </Typography>
      {type === "keyword" && <FacetSearch facetKey={facetKey} facetLabel={label} />}
      <Box style={{ display: "flex", flexDirection: "column" }}>{dataItems}</Box>
    </Box>
  )
}

export default function Facets({ facets = {} }) {
  const facetList = []
  const {
    total_settlement_amount: awardAmountFacet,
    state: stateFacet,
    county: countyFacet,
    ...bucketedFacets
  } = facets

  if (stateFacet) {
    facetList.push(<FacetItem facetValue={stateFacet} facetKey="state" key="state" />)
  }

  if (countyFacet) {
    facetList.push(<FacetItem facetValue={countyFacet} facetKey="county" key="county" />)
  }

  if (awardAmountFacet) {
    facetList.push(
      <AwardAmountFacet
        key="total_settlement_amount"
        facetValue={awardAmountFacet}
        facetKey={"total_settlement_amount"}
      />
    )
  }

  for (let facet in bucketedFacets) {
    facetList.push(<FacetItem facetValue={bucketedFacets[facet]} facetKey={facet} key={facet} />)
  }

  return <div>{facetList}</div>
}
