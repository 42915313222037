import React, { useCallback, useContext, useMemo } from "react"
import { Collapse } from "@material-ui/core"
import { TransitionGroup } from "react-transition-group"
import { TableRowViewModel } from "common/models/library"
import { Loading } from "common/loading"
import { FORM_STATE_STATUS } from "common/template-form/constants"
import { LibraryTableListItemButtons } from "./LibraryTableListItemButtons"
import { createFinishEditTemplateAction, createStartCreatingTemplateAction } from "../State/templatesActions"
import { LibraryTabStateContext } from "../State/constants"
import {
  StyledTableRow,
  StyledTableListItemData,
  StyledLibraryTableItemDetailsWrapper,
  StyledLibraryTableItemDetails,
  StyledLibraryTableMessageWrapper,
} from "./styled"
import { LibraryUseForm } from "common/template-form/types"

interface LibraryTableRowProps {
  row: TableRowViewModel
  getFormData: LibraryUseForm
  formStatusMessageMap: Record<FORM_STATE_STATUS, Nullable<JSX.Element>>
  entityName: string
  canDuplicate?: boolean
}

export function LibraryTableRow({
  row: { rowId, items, rowData },
  getFormData,
  formStatusMessageMap,
  entityName,
  canDuplicate = true,
}: LibraryTableRowProps): JSX.Element {
  const { state, dispatch } = useContext(LibraryTabStateContext)
  const expanded = state.expandedEntityIds.includes(rowId)
  const edit = state.editingEntityId === rowId
  const showDetails = edit || expanded

  const onFinish = useCallback(() => {
    dispatch(createFinishEditTemplateAction())
  }, [dispatch])

  const { message, editForm, viewForm, isLoading, handleSubmit, handleCancel } = getFormData({
    entityId: rowId,
    onFinish,
    initialData: rowData,
    formStatusMessageMap,
  })

  const handleDuplicate = useCallback(() => {
    if (!canDuplicate) return

    dispatch(createStartCreatingTemplateAction({ ...rowData }))
  }, [dispatch, rowData, canDuplicate])

  const editTemplateForm = useMemo(() => (edit ? editForm(null) : null), [edit, editForm])
  const viewTemplateForm = useMemo(() => (expanded ? viewForm : null), [expanded, viewForm])

  return (
    <>
      <StyledTableRow>
        {items.map(item => (
          <StyledTableListItemData key={item.key}>{item.value}</StyledTableListItemData>
        ))}
        <LibraryTableListItemButtons
          rowId={rowId}
          handleCancel={handleCancel}
          handleSave={handleSubmit}
          handleDuplicate={handleDuplicate}
          canDuplicate={canDuplicate}
        />
      </StyledTableRow>
      <StyledLibraryTableItemDetailsWrapper>
        <StyledLibraryTableItemDetails colSpan={items.length + 1}>
          {message && <StyledLibraryTableMessageWrapper>{message}</StyledLibraryTableMessageWrapper>}
          {showDetails && (
            <>
              <TransitionGroup>
                <Collapse appear>
                  {viewTemplateForm}
                  {editTemplateForm}
                </Collapse>
              </TransitionGroup>
              <Loading show={isLoading} label={`Updating ${entityName}...`} />
            </>
          )}
        </StyledLibraryTableItemDetails>
      </StyledLibraryTableItemDetailsWrapper>
    </>
  )
}
