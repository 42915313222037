import { useFormContext } from "./FormContext"
import React from "react"
import { Box, Button, Checkbox, Chip, FormControlLabel, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import uniqBy from "lodash/uniqBy"
import { ALL_BUILT_IN_RANGES, DEFAULT_AWARD_AMOUNT_RANGES } from "./constants"
import { CurrencyField } from "common/form-components"

const useStyles = makeStyles(theme => ({
  customRangeForm: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(1),
  },
  customRangeInputs: {
    display: "flex",
    flexDirection: "column",
  },
  customRangeControls: {
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
  },
  awardAmountFacet: {
    marginBottom: theme.spacing(1),
  },
  checkboxGroup: {
    display: "flex",
    alignItems: "center",
    maxWidth: "14rem",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "0.75rem",
    maxWidth: "7.5rem",
    overflow: "visible",
    wordWrap: "break-word",
    whiteSpace: "break-spaces",
  },
}))

function removeDuplicatedRanges(selectedRanges) {
  return uniqBy(selectedRanges, item => item[0] + item[1])
}

function findInRangeList(rangeList, range) {
  return rangeList.findIndex(item => {
    return item[0] === range[0] && item[1] === range[1]
  })
}

const schema = yup
  .object({
    min: yup.number().positive().required(),
    max: yup
      .number()
      .positive()
      .integer()
      .required()
      .moreThan(yup.ref("min"), "Max should be > min")
      .test("already-exist", "This range already exists, please specify another range", (max, schema) => {
        const index = findInRangeList(ALL_BUILT_IN_RANGES, [schema.parent.min, max])
        return index < 0
      }),
  })
  .required()

export const AwardAmountFacet = ({ facetValue }) => {
  const classes = useStyles()
  const { queryActions, queryState, handleQueryUpdate } = useFormContext()
  const queryElement = queryState.query["total_settlement_amount"]
  let selectedRanges
  if (queryElement) {
    try {
      selectedRanges = JSON.parse(queryElement)
    } catch (e) {
      selectedRanges = DEFAULT_AWARD_AMOUNT_RANGES
    }
  } else {
    selectedRanges = DEFAULT_AWARD_AMOUNT_RANGES
  }
  selectedRanges = removeDuplicatedRanges(selectedRanges)
  const customRangeIndex = selectedRanges.findIndex(selectedRange => {
    return ALL_BUILT_IN_RANGES.every(builtinRange => {
      return builtinRange[0] !== selectedRange[0] && builtinRange[1] !== selectedRange[1]
    })
  })
  const customRanges =
    customRangeIndex >= 0
      ? {
          min: selectedRanges[customRangeIndex][0],
          max: selectedRanges[customRangeIndex][1],
        }
      : {
          min: "",
          max: "",
        }

  const {
    control,
    handleSubmit: handleSubmitCustomRange,
    reset: resetCustomRange,
    formState,
  } = useForm({
    defaultValues: {
      ...customRanges,
    },
    resolver: yupResolver(schema),
  })

  function validDataPoint(data) {
    return data.value != null && data.value.toString().includes("-")
  }

  const labelledValues = facetValue.data.filter(validDataPoint).map(dataPoint => {
    // need to convert unbounded ranges to range facets:
    // eg: [*-10000.0] => [null, 10000]
    const value = dataPoint.value.split("-").map(num => (isNaN(Number(num)) ? null : Number(num)))

    // values from the backend are returned as numeric floats
    // convert them to simplified currency representations to make it easier to read
    // 1E7 --→ $10M
    const label = dataPoint.value
      .split("-")
      .map((num, index) => {
        const numAsNumber = Number(num)

        // for unbounded ranges eg: [*-10000.0]
        if (isNaN(numAsNumber)) {
          return index === 0 ? "0" : "upwards"
        }

        return Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          notation: "compact",
          compactDisplay: "short",
        }).format(numAsNumber)
      })
      .join(" - ")

    return {
      ...dataPoint,
      label,
      value,
    }
  })

  const handleRangeSubmit = handleSubmitCustomRange(data => {
    // remove customized range and replace with new custom range
    selectedRanges = [[Number(data.min), Number(data.max)]]
    handleQueryUpdate(queryActions.APPLY_RANGE_FACET, {
      name: "total_settlement_amount",
      value: JSON.stringify(selectedRanges),
    })
  })

  const handleReset = () => {
    resetCustomRange(
      {
        min: "",
        max: "",
      },
      { keepValues: false }
    )
    handleQueryUpdate(queryActions.APPLY_RANGE_FACET, {
      name: "total_settlement_amount",
      value: JSON.stringify(DEFAULT_AWARD_AMOUNT_RANGES),
    })
  }

  const handleChange = event => {
    const value = event.target.value.split(",").map(num => (num ? Number(num) : null))

    if (event.target.checked) {
      selectedRanges.push(value)
    } else {
      const findIndex = findInRangeList(selectedRanges, value)
      if (findIndex >= 0) {
        selectedRanges.splice(findIndex, 1)
      }
    }
    selectedRanges = removeDuplicatedRanges(selectedRanges)
    handleQueryUpdate(queryActions.APPLY_RANGE_FACET, {
      name: "total_settlement_amount",
      value: JSON.stringify(selectedRanges),
    })
  }

  const dataItems = labelledValues?.map((dataPoint, index) => {
    // since the value can be an array (for ranged facets), we cannot rely on the includes check
    // we need to check array equality in that case

    const isChecked = selectedRanges.some(item => {
      const min = item[0]
      const max = item[1]
      return min === dataPoint.value[0] && max === dataPoint.value[1]
    })

    return (
      <Box key={`total_settlement_amount-${index}`}>
        <Box className={classes.checkboxGroup}>
          <FormControlLabel
            control={
              <Checkbox
                value={dataPoint.value}
                onChange={handleChange}
                checked={isChecked}
                inputProps={{ "data-facet": "total_settlement_amount", name: "total_settlement_amount" }}
              />
            }
            label={
              <Typography variant="caption" display="block" className={classes.label}>
                {dataPoint.label || dataPoint.value || "(no label)"}
              </Typography>
            }
          />
          <Chip variant="outlined" size="small" label={dataPoint.count} className={classes.chip} />
        </Box>
      </Box>
    )
  })

  return (
    <Box className={classes.awardAmountFacet} key={"total_settlement_amount"}>
      <Box>
        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
          {facetValue.label}
        </Typography>
        <form className={classes.customRangeForm} noValidate autoComplete="off" onSubmit={handleRangeSubmit}>
          <Box className={classes.customRangeInputs}>
            <CurrencyField
              size="small"
              name="min"
              control={control}
              id="award-min-input"
              label="Min Award Amount"
              helperText={formState?.errors?.min?.message}
              error={!!formState?.errors?.min}
            />
            <CurrencyField
              size="small"
              name="max"
              margin="normal"
              control={control}
              id="award-max-input"
              label="Max Award Amount"
              helperText={formState?.errors?.max?.message}
              error={!!formState?.errors?.max}
            />
          </Box>
          <Box className={classes.customRangeControls}>
            <Button size="small" color="secondary" type="submit">
              Apply Range
            </Button>
            <Button size="small" onClick={handleReset}>
              Reset
            </Button>
          </Box>
        </form>
        <Box style={{ display: "flex", flexDirection: "column" }}>{dataItems}</Box>
      </Box>
    </Box>
  )
}
