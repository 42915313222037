import React, { useCallback, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  IconButton,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import DownloadIcon from "@material-ui/icons/GetApp"
import DeleteIcon from "@material-ui/icons/Delete"

import { useMutation, useQueries, useQuery, useQueryClient } from "react-query"
import { compareAsc, compareDesc, differenceInDays } from "date-fns"
import clsx from "clsx"

import useUser from "hooks/useUser"
import {
  deleteRequest,
  deleteRequestDemandPackageFile,
  downloadRequestDemandPackageFiles,
  downloadRequestFiles,
  fetchRequest,
  getCaseByRequestId,
  getIntakeAsset,
  startDemand,
  updateFiles,
  updateRequest,
  updateRequestInternalAssignees,
} from "api"
import {
  amountInDollars,
  formatDate,
  getObjectIds,
  JSONparseWithDefaultValue,
  stringListToArray,
} from "utils"
import {
  canSeeEditRequestButton,
  canUserDeleteRequest,
  canUserEditRequest,
  canUserInteractWithDemand,
  canUserOverwriteStatus,
  canUserViewCreditAmount,
  canUserEditCreditAmount,
} from "../permissions/requestAction"
import { RequestContextProvider, useRequestContext } from "../context"
import { useHandleMessages } from "common/messages/useHandleMessages"

import EmptyState from "common/tables/EmptyState"
import { Loading } from "common/loading"
import GenericError from "common/GenericError"
import DragDropFileUploader from "common/DragDropFileUploader"
import ConfirmDialog from "common/ConfirmDialog"
import Tooltip from "common/Tooltip"
import Table from "common/tables/Table"
import FileAttachment from "../FileAttachment"
import StatusActions from "../RequestStatus/StatusActions"
import requestStatusProperties from "../RequestStatus/requestStatusProperties"
import AssigneePicker from "../AssigneePicker"
import { INTERNAL_ROLES, OSF } from "common/models/roles"
import {
  ADDITIONAL_INFO,
  ADJUSTER,
  ALL,
  ASSET_KEYS,
  ASSET_TYPES,
  BASIC_INFORMATION,
  CARRIER_INFORMATION,
  CARRIER_NAME,
  CASE_FACTS,
  CASE_TYPE,
  CLAIM_NUMBER,
  DATE_OF_INCIDENT,
  DEFENDANT_TYPE_KEYS,
  DEFENDANTS,
  DEMAND_PACKAGE,
  DEMAND_PACKAGE_FILE_TYPES,
  DEPENDANT_STATUS,
  EDUCATION_LEVEL,
  FAMILY_STATUS,
  FILES,
  FIRM_NAME,
  FUTURE_TREATMENTS,
  HOUSEHOLD_IMPACT,
  HOUSEHOLD_IMPACT_PERCENTAGE,
  INDIVIDUAL,
  INJURY_IMPACTS,
  INJURY_IMPACTS_KEYS,
  INTAKE_STATUSES,
  ONGOING_COMPLAINTS,
  OTHER,
  PLAINTIFF_INFORMATION,
  PLAINTIFF_NAME,
  POLICY_COVERAGE_TYPE,
  COMMERCIAL_POLICY_COVERAGE_TYPE_KEYS,
  POLICY_LIMIT,
  POLICY_NUMBER,
  POLICY_TYPE,
  POLICY_TYPE_KEYS,
  RECIPIENT_ADDRESS,
  RECIPIENT_EMAIL,
  SUBMITTER,
  UPLOADED,
  MISSING_DOCS,
  DEMAND_TYPE,
  ASSIGNED_ATTORNEY,
  INDIVIDUAL_POLICY_COVERAGE_TYPE_KEYS,
  COMMERCIAL,
} from "../constants"
import { queryKeys } from "../../react-query/constants"
import { MEDICAL_FILE_OPTIONS } from "../../demand/constants"
import { assignedToUser, downloadAllFiles, getEditDisabledMessageByStatus } from "../utils"
import { partition } from "lodash"
import CreditManagementForm from "./CreditManagementForm"
import { ALL_ACCEPTED_FILE_TYPES } from "common/constants"
import { TAG_LABEL_TYPE } from "common/TagLabel"
import { RequestStatusBlock } from "./RequestStatusBlock"
import { FileUploadProvider, useFileUploader } from "common/file-uploader"
import { requestService } from "api/services/request"
import { PlaintiffsView } from "./PlaintiffsView"

const useStyles = makeStyles(theme => ({
  header: {
    display: "grid",
    gridTemplateColumns: "4fr 2fr 4fr",
    margin: theme.spacing(2, 0),
  },
  updatedAt: {
    margin: theme.spacing(2, 0),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  listView: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
  statusAndInfo: {
    display: "grid",
  },
  statusAndInfoInline: {
    display: "flex",
    "& > :first-child": {
      margin: theme.spacing(2.5, 3, 1, 0),
    },
  },
  assignees: {
    marginTop: theme.spacing(4),
    minWidth: theme.spacing(10),
  },
  assignButton: {
    marginLeft: theme.spacing(4),
  },
  centerItems: {
    display: "flex",
    flexGrow: "1",
  },
  deleteRequestButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  startDemandButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  },
  downloadAllButton: {
    margin: "auto",
  },
  smallMarginRight: {
    marginRight: theme.spacing(0.5),
  },
  defendantsContainer: {
    display: "grid",
    gridTemplateColumns: "1fr",
    lineHeight: "15px",
    marginBottom: theme.spacing(1),
  },
  defendantContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    margin: theme.spacing(1, 0),
    "&:first-of-type": {
      fontWeight: 700,
    },
  },
  fileLink: {
    textDecoration: "none",
    color: "black",
  },
  fieldContentContainer: {
    margin: theme.spacing(1, 0),
  },
  highlight: {
    backgroundColor: "yellow",
  },
  fieldTitle: {
    fontWeight: 700,
    fontSize: "1rem",
  },
  collapse: {
    width: "100%",
    borderTop: "1px #e0e0e0 solid",
  },
  collapseHeader: {
    display: "flex",
    flexDirection: "row",
    height: "3rem",
    padding: theme.spacing(1, 0.5),
    fontWeight: 600,
  },
  additionalNotesField: {
    width: "100%",
  },
  clearNotesButton: {
    color: "red",
  },
  fullWidth: {
    width: "100%",
  },
}))

const OTHER_SUFFIX = "_other"

const getDisplayFromAssetListByKeyAndId = (assets, key, id) => {
  if (!assets[key]) {
    return OTHER
  }

  const assetObject = assets[key].find(level => {
    return level.key === id
  })

  return assetObject ? assetObject.display : OTHER
}

const getDisplayFromListByObjectKey = (list, key) => {
  return list[key] ? list[key].display : OTHER
}

const getKeyFromListById = (list, id) => {
  return list[id] ? list[id].key : OTHER
}

const getPolicyCoverageType = (request, policyType) => {
  const policyTypeLower = policyType.toLowerCase()
  switch (policyTypeLower) {
    case INDIVIDUAL:
      return INDIVIDUAL_POLICY_COVERAGE_TYPE_KEYS[request?.policy_coverage_type]?.display ?? OTHER
    case COMMERCIAL:
      return COMMERCIAL_POLICY_COVERAGE_TYPE_KEYS[request?.policy_coverage_type]?.display ?? OTHER
    default:
      return OTHER
  }
}

const AccordionView = ({ summary, detailsChild, expanded = true }) => {
  const classes = useStyles()
  const [overwriteExpanded, setOverwriteExpanded] = useState(null)
  const expand = overwriteExpanded === null ? expanded : overwriteExpanded

  return (
    <Accordion
      expanded={expand}
      onChange={() => {
        setOverwriteExpanded(overwriteExpanded === null ? !expanded : !overwriteExpanded)
      }}
    >
      <AccordionSummary className={classes.accordionOverrides} expandIcon={<ExpandMoreIcon />}>
        {summary}
      </AccordionSummary>
      <AccordionDetails>{detailsChild}</AccordionDetails>
    </Accordion>
  )
}

const FieldContent = ({ children, title, id, highlight = false }) => {
  const classes = useStyles()
  return (
    <Box className={clsx(classes.fieldContentContainer, highlight && classes.highlight)} data-test={id}>
      {title && <Box className={classes.fieldTitle}>{title}</Box>}
      {children}
    </Box>
  )
}

const RequestSection = ({ fields }) => {
  const { highlightedFields } = useRequestContext()

  const splitNewLines = content => {
    if (content && typeof content.split === "function") {
      return content.split("\n")
    } else return [content]
  }

  return fields.map(({ content, key, title, shouldRender = true }) => {
    if (!shouldRender) {
      return
    }

    return (
      <FieldContent key={key} highlight={highlightedFields.includes(key)} title={title} id={key}>
        {content && splitNewLines(content).map((value, i) => <p key={i}>{value}</p>)}
      </FieldContent>
    )
  })
}

const BasicInfo = ({ request }) => {
  const classes = useStyles()
  const { user } = useUser()

  const requestedDate = formatDate(request.date_requested)
  const submitter = request?.submitter

  const fields = [
    {
      key: DEMAND_TYPE,
      title: "Demand Type",
      shouldRender: request?.firm?.can_create_basic_plus_requests === true && request.type,
      content: `${request?.type?.charAt(0).toUpperCase() + request?.type?.slice(1)}`,
    },
    {
      key: PLAINTIFF_NAME,
      title: "",
      shouldRender: request?.plaintiff_first_name && request.plaintiff_last_name,
      content: <PlaintiffsView plaintiffs={request.plaintiffs} titleClass={classes.fieldTitle} />,
    },
    {
      key: CARRIER_NAME,
      title: "Carrier Name",
      content: `${request.carrier_name}`,
    },
    {
      key: FIRM_NAME,
      title: "Firm Name",
      content: `${request.firm?.name}`,
    },
    {
      key: ASSIGNED_ATTORNEY,
      title: "Assigned Attorney",
      shouldRender: !!request?.assigned_attorney,
      content: `${request?.assigned_attorney}`,
    },
    {
      key: DEFENDANTS,
      title: "Defendants",
      shouldRender: request?.defendants?.length,
      content: (
        <Box className={classes.defendantsContainer}>
          <Box className={classes.defendantContainer} mb="2">
            <Box>Type</Box>
            <Box>Name</Box>
          </Box>

          {request?.defendants?.map((defendant, index) => {
            const defendantType = DEFENDANT_TYPE_KEYS[defendant?.type]?.display ?? "N/A"
            const defendantName =
              defendant?.type === INDIVIDUAL
                ? `${defendant.first_name} ${defendant.last_name}`
                : `${defendant.name}`

            return (
              <Box key={`defendant-${index}`} className={classes.defendantContainer}>
                <Box>{defendantType}</Box>
                <Box>{defendantName}</Box>
              </Box>
            )
          })}
        </Box>
      ),
    },
    {
      key: ADDITIONAL_INFO,
      title: "Additional Information",
      shouldRender: !!request.additional_information,
      content: `${request.additional_information}`,
    },
  ]

  if (user.role !== OSF) {
    fields.splice(3, 0, {
      key: SUBMITTER,
      title: "Submitter",
      content: (
        <Box data-test="request-submitter-information">
          {submitter?.first_name} {submitter?.last_name} ({submitter?.email}) on {requestedDate}
        </Box>
      ),
    })
  }

  return (
    <Box className={classes.fullWidth} data-test="basic-information">
      <RequestSection fields={fields} />
    </Box>
  )
}

const CarrierInformation = ({ request }) => {
  const policy_type = POLICY_TYPE_KEYS[request?.policy_type]?.display ?? OTHER
  const policy_coverage_type = getPolicyCoverageType(request, policy_type)
  const adjusterAddress = request?.adjuster_address
  const hasAddress =
    adjusterAddress?.street ||
    adjusterAddress?.street_2 ||
    adjusterAddress?.city ||
    adjusterAddress?.state ||
    adjusterAddress?.zip_code

  // TODO: Make this more robust
  const getAddressString = useCallback(address => {
    if (!address) return null
    const { street, street_2, city, state, zip_code } = address

    return `${street ?? ""}${street_2 ? ` ${street_2}` : ""}${city ? `, ${city}` : ""}${
      state ? `, ${state}` : ""
    }${zip_code ? ` - ${zip_code}` : ""}`
  }, [])

  const fields = [
    {
      key: ADJUSTER,
      title: "Recipient Name",
      shouldRender: request?.adjuster_first_name && request?.adjuster_last_name,
      content: `${request.adjuster_first_name} ${request.adjuster_last_name}`,
    },
    {
      key: RECIPIENT_EMAIL,
      title: "Recipient Email",
      shouldRender: request?.adjuster_email,
      content: `${request.adjuster_email}`,
    },
    {
      key: POLICY_NUMBER,
      title: "Policy Number",
      shouldRender: request?.policy_number,
      content: `${request.policy_number}`,
    },
    {
      key: POLICY_TYPE,
      title: "Policy Type",
      content: `${policy_type}`,
    },
    {
      key: POLICY_COVERAGE_TYPE,
      title: "Policy Coverage Type",
      content: `${policy_coverage_type}`,
    },
    {
      key: POLICY_LIMIT,
      title: "Policy Limit",
      shouldRender: request?.policy_limit,
      content: `${amountInDollars(request?.policy_limit) ?? "N/A"} `,
    },
    {
      key: CLAIM_NUMBER,
      title: "Claim Number",
      shouldRender: request?.claim_number,
      content: `${request.claim_number}`,
    },
    {
      key: RECIPIENT_ADDRESS,
      title: "Recipient Address",
      shouldRender: hasAddress,
      content: getAddressString(adjusterAddress),
    },
  ]

  return (
    <Box data-test="carrier-information">
      <RequestSection fields={fields} />
    </Box>
  )
}

const CaseFacts = ({ request, assets }) => {
  const fields = [
    {
      key: DATE_OF_INCIDENT,
      title: "Date of Incident",
      shouldRender: request?.date_of_incident,
      content: `${request.date_of_incident}`,
    },
    {
      key: CASE_TYPE,
      title: "Case Type",
      shouldRender: request?.case_type,
      content: `${getDisplayFromAssetListByKeyAndId(assets, ASSET_KEYS.caseTypes, request.case_type)}`,
    },
    {
      key: CASE_FACTS,
      title: "Facts",
      shouldRender: request?.case_facts,
      content: `${request.case_facts}`,
    },
    {
      key: ONGOING_COMPLAINTS,
      title: "Ongoing Complaints",
      shouldRender: request?.ongoing_complaints,
      content: `${request.ongoing_complaints}`,
    },
    {
      key: FUTURE_TREATMENTS,
      title: "Future Treatments",
      shouldRender: request?.future_treatments,
      content: `${request.future_treatments}`,
    },
  ]

  return (
    <Box data-test="case-facts">
      <RequestSection fields={fields} />
    </Box>
  )
}

const HouseholdImpactDisplay = ({ household_start_of_impairment, household_end_of_impairment }) => {
  if (!household_start_of_impairment) return <></>

  if (!household_end_of_impairment) {
    return (
      <Box>
        {household_start_of_impairment} to present <i>(ongoing)</i>
      </Box>
    )
  }

  const householdImpairmentDays = differenceInDays(
    new Date(household_end_of_impairment),
    new Date(household_start_of_impairment)
  )

  const isSingularDay = householdImpairmentDays === 1 ? true : false
  return (
    <Box>
      {household_start_of_impairment} to {household_end_of_impairment} (
      {`${householdImpairmentDays} day${isSingularDay ? `` : `s`}`})
    </Box>
  )
}

const PlaintiffInformation = ({ request, assets = [] }) => {
  const classes = useStyles()
  const dependentStatusValues = stringListToArray(request.dependent_status)

  const fields = [
    {
      key: EDUCATION_LEVEL,
      title: "Education Level",
      shouldRender: request?.education_level,
      content: `${getDisplayFromAssetListByKeyAndId(
        assets,
        ASSET_KEYS.educationLevel,
        request.education_level
      )}`,
    },
    {
      key: FAMILY_STATUS,
      title: "Family Status",
      shouldRender: request?.family_status,
      content: `${getDisplayFromAssetListByKeyAndId(assets, ASSET_KEYS.familyStatus, request.family_status)}`,
    },
    {
      key: DEPENDANT_STATUS,
      title: "Dependant Status",
      shouldRender: request?.dependendStatusValues?.length,
      content: (
        <>
          <Box>
            {dependentStatusValues.map((dependentStatusId, index) => {
              return (
                <Chip
                  className={classes.chip}
                  key={index}
                  label={getDisplayFromAssetListByKeyAndId(
                    assets,
                    ASSET_KEYS.dependentStatus,
                    dependentStatusId
                  )}
                />
              )
            })}
            {request.dependent_status_other && (
              <Chip
                className={classes.chip}
                label={getDisplayFromAssetListByKeyAndId(
                  assets,
                  ASSET_KEYS.dependentStatus,
                  request.dependent_status_other
                )}
              />
            )}
          </Box>
        </>
      ),
    },
    {
      key: HOUSEHOLD_IMPACT,
      title: "Household Impact",
      shouldRender: request?.household_start_of_impairment,
      content: (
        <HouseholdImpactDisplay
          household_start_of_impairment={request?.household_start_of_impairment}
          household_end_of_impairment={request?.household_end_of_impairment}
        />
      ),
    },
    {
      key: HOUSEHOLD_IMPACT_PERCENTAGE,
      title: "Impairment Percentage of Household Chores",
      shouldRender: request?.household_percent_of_chores,
      content: `${request?.household_percent_of_chores}%`,
    },
  ]

  return (
    <Box data-test="personal-information">
      <RequestSection fields={fields} />
    </Box>
  )
}

const InjuryImpacts = ({ request, assets }) => {
  const classes = useStyles()
  const injuryImpacts = Object.keys(INJURY_IMPACTS_KEYS)
  // Uncomment if we want to not show 'Other title' - const injuryImpacts = Object.keys(INJURY_IMPACTS_KEYS_TO_VALUES)

  return (
    <div data-test="injury-impacts">
      {injuryImpacts.map(impactId => {
        const impactTitle = getDisplayFromListByObjectKey(INJURY_IMPACTS_KEYS, impactId)
        const injuryImpactKey = getKeyFromListById(INJURY_IMPACTS_KEYS, impactId)
        const impactValues = request[injuryImpactKey]
        const otherImpact = request[`${injuryImpactKey}${OTHER_SUFFIX}`]

        if ((Array.isArray(impactValues) && impactValues?.length > 0) || otherImpact) {
          const otherImpactPercentage = otherImpact?.impact_percentage
          return (
            <div key={`injury-impact-group-${impactId}`}>
              <Typography>{impactTitle}</Typography>
              <div>
                {Array.isArray(impactValues) &&
                  impactValues.map((value, key) => {
                    const label = getDisplayFromAssetListByKeyAndId(assets, ASSET_KEYS[impactId], value.key)
                    return (
                      <Chip
                        className={classes.chip}
                        key={`group-${impactId}-chip-${key}`}
                        label={`${label} - ${value?.impact_percentage}%`}
                      ></Chip>
                    )
                  })}
                {otherImpact?.key && (
                  <Chip
                    className={classes.chip}
                    label={`${otherImpact.key} - ${otherImpactPercentage}%`}
                  ></Chip>
                )}
              </div>
            </div>
          )
        }
      })}
    </div>
  )
}

const DownloadAllFilesButton = ({
  requestId,
  downloadAllEndpoint,
  fileName = "files",
  text = "Download All Files",
  disabled = false,
  disabledText = "",
  type = ALL,
}) => {
  const classes = useStyles()
  const { showMessage } = useHandleMessages()
  const downloadAllFilesMutation = useMutation(
    async () => {
      await downloadAllFiles(downloadAllEndpoint, fileName, requestId, type)
    },
    {
      onError: () => {
        showMessage({
          type: "error",
          message:
            "Something went wrong downloading all files. Try downloading individual files and report an issue if your problem persists.",
        })
      },
    }
  )

  return (
    <Tooltip
      className={classes.downloadAllButton}
      variant="outlined"
      color="primary"
      onClick={() => {
        downloadAllFilesMutation.mutateAsync()
      }}
      disabled={disabled}
      tooltipText={disabledText}
      component="div"
      data-test="download-all-files-button"
    >
      <Button>
        <DownloadIcon className={classes.smallMarginRight} />
        <Box className={classes.smallMarginRight}>{text}</Box>
      </Button>
    </Tooltip>
  )
}

const FileList = ({
  files,
  fileTypes,
  downloadAllFileName,
  downloadAllEndpoint,
  emptyMessage,
  deleteMethod = null,
  type = null,
  overrideOrder = true,
  hideFiles = false,
  hiddenFilesMessage = "",
}) => {
  const { isPanel } = useRequestContext()
  if (isPanel) {
    return (
      <Box>
        <Box sx={{ fontStyle: "italic", fontSize: "0.75rem" }}>Drag and drop documents from below.</Box>
        {files.map(file => {
          return <FileAttachment pk={file.pk} key={file.pk} name={file.name} url={file.file_url} />
        })}
      </Box>
    )
  }

  return (
    <FileTable
      files={files}
      fileTypes={fileTypes}
      downloadAllFileName={downloadAllFileName}
      downloadAllEndpoint={downloadAllEndpoint}
      emptyMessage={emptyMessage}
      deleteMethod={deleteMethod}
      type={type}
      overrideOrder={overrideOrder}
      hideFiles={hideFiles}
      hiddenFilesMessage={hiddenFilesMessage}
    />
  )
}

const FileTable = ({
  files,
  fileTypes,
  downloadAllFileName,
  downloadAllEndpoint,
  emptyMessage,
  deleteMethod = null,
  type = null,
  overrideOrder = true,
  hideFiles = false,
  hiddenFilesMessage = "",
}) => {
  const { id: requestId } = useParams()
  const [sortDirection, setSortDirection] = useState("desc")

  const orderedFiles = overrideOrder
    ? files.sort((a, b) => {
        const aDate = a.date_created || a.external_date_created
        const bDate = b.date_created || b.external_date_created
        return sortDirection === "asc"
          ? compareAsc(new Date(aDate), new Date(bDate))
          : compareDesc(new Date(aDate), new Date(bDate))
      })
    : files

  const columns = [
    {
      id: "name",
      title: "File Name",
      cellComponent: file => {
        return <Box>{file.name}</Box>
      },
    },
    {
      id: "type",
      title: "Type",
      cellComponent: file => {
        return <Box>{fileTypes[file.type] ?? OTHER}</Box>
      },
    },
    {
      id: "number_of_pages",
      title: "# of Pages",
      cellComponent: file => {
        return <Box>{file.number_of_pages ?? "N/A"}</Box>
      },
    },
    {
      id: "date_created",
      title: "Date Created",
      sortable: true,
      cellComponent: file => {
        const dateField = file.date_created || file.external_date_created
        const formattedDate = (dateField && formatDate(dateField, "MMM do, yyy hh:mm")) ?? "N/A"
        return <Box>{formattedDate}</Box>
      },
    },
    {
      id: "actions",
      title: "Actions",
      align: "right",
      cellComponent: file => {
        return (
          <Box>
            <a download href={file.file_url}>
              <IconButton>
                <DownloadIcon />
              </IconButton>
            </a>
            {deleteMethod && (
              <IconButton
                onClick={() => {
                  deleteMethod && deleteMethod(file)
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        )
      },
    },
  ]

  const tableSummary = [
    {
      id: "name",
      cellComponent: files => {
        const totalFiles = files?.length ?? 0
        return <Box>Total File Count: {totalFiles}</Box>
      },
    },
    {
      id: "type",
      cellComponent: () => {
        return null
      },
    },
    {
      id: "number_of_pages",
      cellComponent: files => {
        const totalPages = files.reduce((total, file) => {
          return total + (file.number_of_pages ?? 0)
        }, 0)
        return <Box>{totalPages}</Box>
      },
    },
    {
      id: "date_created",
      cellComponent: () => {
        return null
      },
    },
    {
      id: "actions",
      cellComponent: () => {
        return null
      },
    },
  ]

  if (orderedFiles.length === 0) {
    return <EmptyState message={emptyMessage} />
  }

  return (
    <Box>
      <DownloadAllFilesButton
        requestId={requestId}
        filesName={downloadAllFileName}
        downloadAllEndpoint={downloadAllEndpoint}
        type={type}
        disabled={hideFiles}
      />
      {hideFiles ? (
        <Box mt={2} pl={1}>
          {hiddenFilesMessage}
        </Box>
      ) : (
        <Table
          columns={columns}
          records={orderedFiles}
          sortDirection={sortDirection}
          summary={tableSummary}
          sortField={"external_date_created"}
          onSortChange={() => {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc")
          }}
        />
      )}
    </Box>
  )
}

const FileAttachments = ({ request, files, type = ALL, hideFiles = false, hiddenFilesMessage = "" }) => {
  const classes = useStyles()
  const downloadAllFileName = `${request.plaintiff_first_name}_${request.plaintiff_last_name}_files`
  return (
    <Box className={`${classes.centerItems} ${classes.listView}`} data-test="file-attachments">
      <FileList
        files={files}
        downloadAllEndpoint={downloadRequestFiles}
        fileTypes={MEDICAL_FILE_OPTIONS}
        downloadAllFileName={downloadAllFileName}
        emptyMessage="No Files"
        type={type}
        hideFiles={hideFiles}
        hiddenFilesMessage={hiddenFilesMessage}
      />
    </Box>
  )
}

const DemandPackageFile = ({ request }) => {
  const classes = useStyles()
  const { user } = useUser()

  const queryClient = useQueryClient()
  const { uploadFiles, isUploading } = useFileUploader()
  const { showMessage } = useHandleMessages()
  const [filesToUpload, setFilesToUpload] = useState([])
  // const [isEditingNotes, setIsEditingNotes] = useState(false)
  // const ref = useRef(null)

  // const { control, formState, setValue, watch, handleSubmit, reset } = useForm({
  //   defaultValues: {
  //     notes: request?.notes ?? "",
  //   },
  // })
  // const { errors, dirtyFields } = formState

  const handleUploadError = useCallback(
    (fileName, reason) => {
      const info = (
        <>
          There was an error uploading this file: <b>{fileName}</b>
        </>
      )
      const message = reason ? (
        <>
          {info}.
          <br />
          Details: {reason}.
        </>
      ) : (
        info
      )

      showMessage({ type: "error", message })
    },
    [showMessage]
  )

  const uploadDemandPackageFile = useMutation(requestService.uploadDemandPackageFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.request)
      setFilesToUpload([])
    },
    onError: (_, { data }) => handleUploadError(data.name),
  })

  const deleteDemandPackageFile = useMutation(deleteRequestDemandPackageFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.request)
    },
  })

  const handleUploadDemandPackage = useCallback(async () => {
    const uploadResult = await uploadFiles(filesToUpload.map(({ file }) => file))

    if (uploadResult.failedFiles) {
      uploadResult.failedFiles.forEach(({ name, reason }) => handleUploadError(name, reason))
    }

    uploadResult.forEach((uploadId, index) => {
      const { file, fileType } = filesToUpload[index]
      const { name } = file

      if (typeof uploadId !== "string") {
        return handleUploadError(name, uploadId?.reason?.message)
      }

      uploadDemandPackageFile.mutate({
        requestId: request.pk,
        data: { uploadId, name, type: fileType },
      })
    })
  }, [request.pk, uploadDemandPackageFile, filesToUpload, uploadFiles, handleUploadError])

  const handleDeleteFile = file => {
    deleteDemandPackageFile.mutate({ fileId: file.pk, requestId: request.pk })
  }

  // const handleBlur = handleSubmit(async data => {
  //   if (isEmpty(dirtyFields)) return
  //   updateRequestMutation.mutate({
  //     requestId: request.pk,
  //     data: data,
  //   })
  //   reset(data)
  //   setIsEditingNotes(false)
  // })

  const downloadAllFileName = `${request.plaintiff_first_name}_${request.plaintiff_last_name}_demand_package_files`
  return (
    <Box className={`${classes.centerItems} ${classes.listView}`} data-test="demand-package-container">
      <FileList
        files={request.demand_package_files}
        fileTypes={DEMAND_PACKAGE_FILE_TYPES}
        downloadAllEndpoint={downloadRequestDemandPackageFiles}
        deleteMethod={user.isInternal ? handleDeleteFile : null}
        downloadAllFileName={downloadAllFileName}
        emptyMessage="No Demand Package"
        overrideOrder={false}
      />

      {user.isInternal && (
        <DragDropFileUploader
          fileOptions={DEMAND_PACKAGE_FILE_TYPES}
          acceptedTypes={ALL_ACCEPTED_FILE_TYPES}
          defaultType="demand_package"
          files={filesToUpload}
          onAdd={newFiles => setFilesToUpload([...filesToUpload, ...newFiles])}
          onChange={newFiles => setFilesToUpload(newFiles)}
          onSave={handleUploadDemandPackage}
          data-test="drag-and-drop-file-uploader"
        />
      )}

      {/* Leaving this in to quickly add back, but disabling for now until email formatting is sorted. */}
      {/* <form
        className={classes.fieldContentContainer}
        noValidate
        onBlur={handleBlur}
        onKeyDown={e => {
          if (e.code === "Enter") {
            e.preventDefault()
            e.target.blur()
          }
        }}
      >
        <Box className={classes.fieldTitle}>
          Additional Notes
          {user.isInternal && (
            <IconButton onClick={() => setIsEditingNotes(true)}>
              <EditIcon />
            </IconButton>
          )}
        </Box>
        {user.isInternal && isEditingNotes ? (
          <>
            <InputField
              className={classes.additionaNotesField}
              control={control}
              name="notes"
              type="text"
              multiline
              rows={8}
              helperText="Additional information to share with the client along with submission."
              errors={errors}
              variant="outlined"
            />
            <Button color="secondary" disabled={!watch("notes")} variant="outlined" onClick={handleBlur}>
              Save Notes
            </Button>
          </>
        ) : (
          <Typography>{request.notes}</Typography>
        )}
      </form> */}
      <Loading show={isUploading} label="Uploading files..." />
    </Box>
  )
}

const DemandButton = ({ onClick, demandId = null }) => {
  const classes = useStyles()
  const demandExists = demandId !== null
  const buttonText = demandExists ? "Go To Demand" : "Start Demand"

  if (demandExists) {
    return (
      <Button
        className={classes.startDemandButton}
        component={Link}
        data-test="go-to-demand-button"
        to={`/demands/${demandId}`}
        variant="outlined"
      >
        {buttonText}
      </Button>
    )
  }

  return (
    <Button
      className={classes.startDemandButton}
      data-test="start-demand-button"
      onClick={onClick}
      variant="outlined"
    >
      {buttonText}
    </Button>
  )
}

export function RequestView({
  request,
  isPanel = false,
  expandedGroups = [ALL],
  highlightedFields = [],
  obscureAttachedFiles = false,
  obscureAttachedFilesReason = "",
}) {
  const assetQueries = ASSET_TYPES.map(assetType => {
    return { queryKey: [queryKeys.intakeAsset, assetType], queryFn: getIntakeAsset }
  })

  const assetResults = useQueries(assetQueries)
  const assets = assetResults.reduce((assetMap, asset, index) => {
    if (asset.isSuccess) {
      const assetKey = ASSET_TYPES[index]
      assetMap[assetKey] = asset.data?.results
    }

    return assetMap
  }, {})

  const files = (request?.files ?? []).filter(file => {
    return (
      file?.missing_exhibit_event === undefined ||
      file?.missing_exhibit_event === null ||
      (file?.missing_exhibit_event !== null && file?.accepted === true)
    )
  })
  const [missingDocFiles, uploadedFiles] = partition(files, file => file?.missing_exhibit_event)

  const showAttachedFiles = uploadedFiles.length > 0
  const showMissingDocFiles = missingDocFiles.length > 0

  const showCarrierInformation = !!(
    request.defendants ||
    request.adjuster_first_name ||
    request.adjuster_last_name ||
    request.claim_number ||
    request.policy_number ||
    request.policy_limit
  )
  const showPlaintiffInformation = !!(
    request.education_level ||
    request.family_status ||
    request.dependent_status ||
    request.dependent_status_other ||
    request.household_start_of_impairment ||
    request.household_end_of_impairment ||
    request.household_percent_of_chores
  )
  const showCaseFacts = !!(
    request.date_of_incident ||
    request.case_type ||
    request.case_facts ||
    request.ongoing_complaints ||
    request.future_treatments
  )

  const showInjuryImpacts = !!(
    request?.injury_impacts_recreation?.length ||
    request?.injury_impacts_recreation_other ||
    request?.injury_impacts_home_activities?.length ||
    request?.injury_impacts_home_activities_other ||
    request?.injury_impacts_self_care?.length ||
    request?.injury_impacts_self_care_other ||
    request?.injury_impacts_entertainment?.length ||
    request?.injury_impacts_entertainment_other ||
    request?.injury_impacts_social?.length ||
    request?.injury_impacts_social_other
  )

  const expandAll = expandedGroups.includes(ALL)

  return (
    <RequestContextProvider value={{ highlightedFields, isPanel }}>
      <AccordionView
        summary={"Basic Information"}
        detailsChild={<BasicInfo request={request} />}
        expanded={expandAll || expandedGroups.includes(BASIC_INFORMATION)}
      />
      {showCarrierInformation && (
        <AccordionView
          summary={"Carrier Information"}
          detailsChild={<CarrierInformation request={request} />}
          expanded={expandAll || expandedGroups.includes(CARRIER_INFORMATION)}
        />
      )}
      {showCaseFacts && (
        <AccordionView
          summary={"Case Facts"}
          detailsChild={<CaseFacts request={request} assets={assets} />}
          expanded={expandAll || expandedGroups.includes(CASE_FACTS)}
        />
      )}
      {showPlaintiffInformation && (
        <AccordionView
          summary={"Plaintiff Information"}
          detailsChild={<PlaintiffInformation request={request} assets={assets} />}
          expanded={expandAll || expandedGroups.includes(PLAINTIFF_INFORMATION)}
        />
      )}
      {showAttachedFiles && (
        <AccordionView
          summary={"Attached Files"}
          detailsChild={
            <FileAttachments
              request={request}
              files={uploadedFiles}
              type={UPLOADED}
              hideFiles={obscureAttachedFiles}
              hiddenFilesMessage={obscureAttachedFilesReason}
            />
          }
          expanded={expandAll || expandedGroups.includes(FILES)}
        />
      )}
      {showMissingDocFiles && (
        <AccordionView
          summary={"Missing Documents from Client"}
          detailsChild={<FileAttachments request={request} files={missingDocFiles} type={MISSING_DOCS} />}
          expanded={expandAll || expandedGroups.includes(FILES)}
        />
      )}
      {showInjuryImpacts && (
        <AccordionView
          summary={"Injury Impacts"}
          detailsChild={<InjuryImpacts request={request} assets={assets} />}
          expanded={expandAll || expandedGroups.includes(INJURY_IMPACTS)}
        />
      )}
    </RequestContextProvider>
  )
}

export function Request({ request, revisionRequest }) {
  const { id } = useParams()
  const { user } = useUser()
  const { showMessage } = useHandleMessages()
  const [internalAssignees, setInternalAssignees] = useState(request?.internal_assignees)
  const ids = getObjectIds(request.internal_assignees)
  const [assignedSelf, setAssignedSelf] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showStatusChangeConfirmation, setShowStatusChangeConfirmation] = useState(false)
  const [pendingStatusChange, setPendingStatusChange] = useState(null)

  useEffect(() => {
    setAssignedSelf(user.isAuthorized && ids.includes(user.id))
  }, [user, ids])

  const isOSF = user.role === OSF

  const navigate = useNavigate()

  const queryClient = useQueryClient()
  const updateRequestMutation = useMutation(updateRequest, {
    onSuccess: data => {
      // Reloads the request with new changes
      queryClient.setQueryData([queryKeys.request, id], data)
    },
  })
  const updateFilesMutation = useMutation(updateFiles, {
    onSuccess: data => {
      queryClient.setQueryData([queryKeys.request, id], data)
    },
  })

  const startDemandMutation = useMutation(startDemand, {
    onSuccess: data => {
      navigate(`/demands/${data.case_id}/form`)
    },
    onError: error => {
      if (error.message.includes("duplicate key value violates unique constraint ")) {
        showMessage({
          type: "error",
          message: "Could not create a new demand, there is already a demand created for this request.",
        })
      } else {
        showMessage({
          type: "error",
          message: "There was an error creating the demand for this request.",
        })
      }
    },
  })

  const deleteRequestMutation = useMutation(deleteRequest, {
    onSuccess: () => {
      navigate("/requests", { state: { deleted: true } })
    },
  })

  const saveInternalAssigneesRelationMutation = useMutation(updateRequestInternalAssignees, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.request)
      if (user.role === INTERNAL_ROLES.LEGALOPS) {
        setAssignedSelf(!assignedSelf)
      }
    },
    onError: error => {
      const status = error?.response?.status
      if ((status === 400 && error?.response?.code === "invalid_intake_status") || status === 404) {
        showMessage({
          type: "error",
          message: "Could not claim this request, it might be claimed by someone else.",
        })
        navigate(`/requests/`)
      }

      if (status === 400) {
        showMessage({
          type: "error",
          message: JSONparseWithDefaultValue(error?.message)?.error,
        })
      }
    },
  })

  const { error } = useQuery([queryKeys.request, id], fetchRequest, {
    enabled: !request,
    onSuccess: data => {
      request = data
      const ids = getObjectIds(data.internal_assignees)
      setInternalAssignees(data.internal_assignees)
      setAssignedSelf(user.isAuthorized && ids.includes(user.id))
    },
    onError: err => {
      if (err?.response?.status === 404) {
        showMessage({
          type: "error",
          message:
            "You are not authorized to access this request, it might be unassigned from you or deleted",
        })
      }
    },
  })

  const isReadyForTask = request?.intake_status === INTAKE_STATUSES.ready_for_tasker
  const { data: caseData } = useQuery([queryKeys.case, id], getCaseByRequestId)

  const classes = useStyles()

  const handleUpdateStatus = (status, showConfirmationDialog = true) => {
    if (showConfirmationDialog) {
      setPendingStatusChange(status)
      setShowStatusChangeConfirmation(true)
    } else {
      updateRequestMutation.mutate({
        requestId: id,
        data: {
          intake_status: status,
        },
      })
    }
  }

  const handleUpdateFiles = () => {
    updateFilesMutation.mutate({
      requestId: id,
    })
  }

  const handleStartDemand = () => {
    startDemandMutation.mutate({
      requestId: id,
    })
  }

  const handleSelfAssign = () => {
    saveInternalAssigneesRelationMutation.mutate({ requestId: id, data: [] })
  }

  if (error) {
    return <GenericError />
  }

  if (!request) {
    return <></>
  }

  const AssigneeSelector = ({ assignedSelf, internalAssignees, user }) => {
    const handleInternalAssigneesChange = ({ newValues: values }) => {
      const ids = values.map(value => {
        return value.pk
      })
      saveInternalAssigneesRelationMutation.mutate({ requestId: id, data: ids })
    }

    const name = (first, last) => {
      return `${first} ${last}`
    }
    return (
      <Box>
        {[INTERNAL_ROLES.LEGALOPS_ADMIN, INTERNAL_ROLES.LEGALOPS_MANAGER].includes(user.role) && (
          <AssigneePicker
            value={internalAssignees}
            onChange={handleInternalAssigneesChange}
            isInternal={true}
          />
        )}

        {user.role === INTERNAL_ROLES.LEGALOPS && (
          <>
            Assignees: {request?.internal_assignees.map(u => name(u.first_name, u.last_name)).join(", ")}
            {assignedSelf ? (
              <Button
                className={classes.assignButton}
                variant="outlined"
                color="primary"
                onClick={() => handleSelfAssign()}
              >
                Unassign from myself
              </Button>
            ) : (
              <Button
                className={classes.assignButton}
                variant="outlined"
                color="primary"
                onClick={() => handleSelfAssign()}
              >
                Assign to myself
              </Button>
            )}
          </>
        )}
      </Box>
    )
  }

  const editDisabled = !canUserEditRequest(user, request)
  const disabledEditMessage = editDisabled ? getEditDisabledMessageByStatus(request.intake_status) : ``

  const canUserDeletePermission = canUserDeleteRequest(user.role)
  const canUserOverwriteStatusPermission = canUserOverwriteStatus(user.role)
  const canUserInteractWithDemandPermission = canUserInteractWithDemand(user.role)
  const canUserViewCreditAmountPermission = canUserViewCreditAmount(user.role)

  const demandId = caseData?.pk ?? null

  let statusChangeConfirmationText = [
    `Are you sure you want to change this request's status to ${
      requestStatusProperties(pendingStatusChange, user.role)?.text || ""
    }?`,
  ]
  const isTaskerCompletingTask = user.role === OSF && pendingStatusChange === INTAKE_STATUSES.tasker_completed
  if (isTaskerCompletingTask) {
    statusChangeConfirmationText.push(
      <Box fontWeight={700}>
        You will immediately lose access to this request and be redirected to the Request list
      </Box>
    )
  }

  const handleTaskerConfirm = () => {
    setShowStatusChangeConfirmation(false)
    updateRequestMutation.mutate({
      requestId: id,
      data: {
        intake_status: pendingStatusChange,
      },
    })
    setPendingStatusChange(null)
    // moving this into the tasker confirm
    // since we don't want to update the requests list on every request update
    queryClient.invalidateQueries(queryKeys.requests)

    if (isTaskerCompletingTask) {
      navigate(`/requests/`)
    }
  }

  const tags = []
  if (request.revised) {
    tags.push({ type: TAG_LABEL_TYPE.INFO, label: "Revision" })
  }

  if (request.annotation_status && user.isInternal) {
    if (request.annotation_status == "pre_annotating" || request.annotation_status == "annotating") {
      tags.push({ type: TAG_LABEL_TYPE.ANNOTATION_IN_PROGRESS, label: "Annotation in Progress" })
    } else if (request.annotation_status == "complete") {
      tags.push({ type: TAG_LABEL_TYPE.ANNOTATION_COMPLETE, label: "Annotation Completed" })
    } else if (request.annotation_status == "auto_complete") {
      tags.push({ type: TAG_LABEL_TYPE.ANNOTATION_COMPLETE, label: "Automatic Annotation Completed" })
    }
  }

  const inlineStatus = user.role !== OSF && !canUserOverwriteStatusPermission

  return (
    <Box>
      <Loading showOnMutation={true} />
      <Box className={classes.header}>
        <Box className={classes.statusAndInfo}>
          <Box className={clsx(inlineStatus && classes.statusAndInfoInline)}>
            <RequestStatusBlock
              status={request.intake_status}
              user={user}
              onChange={handleUpdateStatus}
              demandId={demandId}
              editable={canUserOverwriteStatusPermission}
              display={canUserOverwriteStatusPermission ? "row" : "column"}
              tags={tags}
              revised={!!request.revised}
            />
            <StatusActions
              requestId={id || String(request.pk)}
              status={request.intake_status}
              onUpdateStatus={handleUpdateStatus}
              submitter={request.submitter}
              updatedFilesEnabled={!!request.sharepoint_link}
              onUpdateFiles={handleUpdateFiles}
              revisionRequest={revisionRequest}
            />
          </Box>
        </Box>
        <Box>
          <AssigneeSelector
            className={classes.assignees}
            assignedSelf={assignedSelf}
            setAssignedSelf={setAssignedSelf}
            internalAssignees={internalAssignees}
            setInternalAssignees={setInternalAssignees}
            user={user}
          />
        </Box>
        <Box ml="auto">
          {isOSF && isReadyForTask && (
            <Button
              data-test="assign-myself-osf-button"
              className={classes.startDemandButton}
              variant="outlined"
              onClick={handleSelfAssign}
            >
              Assign to myself
            </Button>
          )}
          {canUserDeletePermission && (
            <Button
              data-test="delete-request-button"
              className={classes.deleteRequestButton}
              variant="outlined"
              onClick={() => {
                setShowDeleteConfirmation(true)
              }}
            >
              Delete Request
            </Button>
          )}

          {canSeeEditRequestButton(user, request) && (
            <Tooltip
              tooltipText={disabledEditMessage}
              disabled={editDisabled}
              variant="outlined"
              color="secondary"
              component={Link}
              to="edit"
            >
              <Button data-test="edit-request-button">Edit Request</Button>
            </Tooltip>
          )}

          {(canUserInteractWithDemandPermission ||
            (user.role === OSF && request.intake_status === INTAKE_STATUSES.tasker_assigned)) && (
            <DemandButton onClick={handleStartDemand} demandId={demandId} />
          )}
        </Box>
      </Box>
      {canUserViewCreditAmountPermission && !!request?.firm?.current_contract && (
        <CreditManagementForm
          // use updated_at in the key to make sure default credit amount also gets updated on save
          // prevents old values from popping up on cancel
          key={`${request.pk}_${request.updated_at}`}
          requestId={request.pk}
          contract={request.firm.current_contract}
          manualCreditAmount={request?.manual_credit_amount}
          autoCreditAmount={request?.auto_credit_amount}
          creditComments={request?.credit_comments ?? null}
          editable={canUserEditCreditAmount(user.role)}
          filesPageCount={request?.files_page_count}
          plaintiffCount={request?.plaintiffs.length ?? 0}
          verdictSearch={request?.verdict_search}
          revisionFilesPageCount={request?.revision_files_page_count}
          manualFilesPageCount={request?.manual_files_page_count}
          manualPlaintiffCount={request?.manual_plaintiff_count}
          manualVerdictSearch={request?.manual_verdict_search}
          manualRevisionFilesPageCount={request?.manual_revision_files_page_count}
        />
      )}

      {/* Keep Demand Package out since RequestView is used on the form submission page */}
      {!isOSF && (
        <FileUploadProvider>
          <AccordionView
            summary={DEMAND_PACKAGE}
            detailsChild={
              <DemandPackageFile request={request} updateRequestMutation={updateRequestMutation} />
            }
            expanded={!!request?.demand_package_files?.length}
          />
        </FileUploadProvider>
      )}
      <RequestView
        request={request}
        expandedGroups={["all"]}
        obscureAttachedFiles={isOSF && !assignedToUser({ user, request })}
        obscureAttachedFilesReason="You must assign yourself to see the attached files list."
      />
      <ConfirmDialog
        open={showDeleteConfirmation}
        onClose={() => {
          setShowDeleteConfirmation(false)
        }}
        onConfirm={() => {
          setShowDeleteConfirmation(false)
          deleteRequestMutation.mutate({
            requestId: request.pk,
          })
        }}
        title="Delete Request?"
        body="Are you sure you want to delete this request? Once deleted, the request will not be available for any user."
        confirmText="Delete"
      />

      <ConfirmDialog
        open={showStatusChangeConfirmation}
        onClose={() => setShowStatusChangeConfirmation(false)}
        onConfirm={handleTaskerConfirm}
        title={"Change Status?"}
        body={statusChangeConfirmationText}
      />
    </Box>
  )
}
