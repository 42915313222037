import React, { useEffect, useMemo, useState } from "react"
import { Box, Divider, Tab as MuiTab, Tabs as MuiTabs } from "@material-ui/core"
import { TabContext, TabPanel as MuiTabPanel } from "@material-ui/lab"
import { styled } from "@material-ui/core/styles"

import { useQuery } from "react-query"
import { Navigate, useParams } from "react-router-dom"

import { Request } from "./Request"
import MissingDocumentClientView from "./MissingDocumentClientView"

import {
  fetchRequest,
  getCaseByRequestId,
  getMissingExhibitEvents,
  getCurrentMissingExhibits,
  getMissingExhibits,
} from "../../api"
import { queryKeys } from "../../react-query/constants"
import useUser from "../../hooks/useUser"
import {
  canMissingDocumentsBeEdited,
  canUserViewMissingDocs,
  canUserViewRevisions,
} from "../permissions/requestAction"
import { MissingExhibit, MissingExhibitEvent } from "../../missing-docs/interfaces"
import { formatTimeSinceNow } from "../../utils"
import { useHandleMessages } from "../../common/messages/useHandleMessages"
import { Helmet } from "react-helmet"
import { OSF } from "../../common/models/roles"
import { REQUEST_VIEW_TABS } from "./enums"
import { REQUEST_VIEW_HISTORY, REQUEST_VIEW_URL_PREFIX } from "./constants"
import { RequestViewDto } from "./types"
import { FEATURES, useFeatures } from "../../hooks/useFeatures"
import { RevisionRequestView } from "./Revision/RevisionRequestView"
import { revisionEventService } from "api/services/revision-event"
import { canUserViewRevisionsTab } from "./utils"
import { INSTRUCTIONS } from "missing-docs/constants"
import { RequestTypeBadge } from "./RequestTypeBadge"

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
}))

const TitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(2),
}))

const Title = styled(Box)({
  fontWeight: 400,
  fontSize: "2rem",
})

const Tabs = styled(MuiTabs)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}))

const TabBox = styled(MuiTab)(({ theme }) => ({
  "&:first-child": {
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
  },
  "&:last-child": {
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
  },
  borderStyle: "solid",
  borderWidth: "1px 1px 1px 0px",
  textTransform: "none",
  "&:not(:last-child)": {
    borderRightWidth: "1px",
  },
  "&.Mui-selected": {
    backgroundColor: "black",
    color: "white",
    borderColor: "black",
  },
  "&.Mui-disabled": {
    borderColor: theme.palette.disabled.main,
    color: `${theme.palette.disabled.main}`,
  },
}))

const TabPanel = styled(MuiTabPanel)(() => ({
  padding: 0,
}))

const Tab = ({ ...inputProps }) => {
  return <TabBox {...inputProps} />
}

interface Props {
  tab?: REQUEST_VIEW_TABS
}

function getRequestViewUrl(tab: REQUEST_VIEW_TABS, useRelativeUrl = false) {
  const url = useRelativeUrl ? window.location.pathname : window.location.href
  const baseUrl = url
    .replace(`/${REQUEST_VIEW_URL_PREFIX.MISSING_DOCS}`, "")
    .replace(`/${REQUEST_VIEW_URL_PREFIX.REVISION}`, "")
  const urlPath = REQUEST_VIEW_URL_PREFIX[tab] ? `/${REQUEST_VIEW_URL_PREFIX[tab]}` : ""

  return `${baseUrl}${urlPath}`
}

export const ViewRequest = ({ tab = REQUEST_VIEW_TABS.REQUEST }: Props): JSX.Element => {
  const { id: requestId } = useParams()
  const { user } = useUser()
  const { showMessage } = useHandleMessages()
  const { isFeatureEnabled } = useFeatures()
  const isRevisionFeatureEnabled = isFeatureEnabled(FEATURES.REVISION)

  const { data: caseData } = useQuery([queryKeys.case, requestId], getCaseByRequestId)
  const { data: request, isLoading: isRequestLoading } = useQuery<RequestViewDto>(
    [queryKeys.request, requestId],
    fetchRequest,
    {
      onError: err => {
        if ((err as any)?.response?.status === 404) {
          showMessage({
            type: "error",
            message:
              "You are not authorized to access this request, it might be unassigned from you or deleted",
          })
        }
      },
    }
  )
  const caseId = caseData?.pk
  const canViewRevisions = canUserViewRevisions(user.role)
  const canUserViewMissingDocsPermission = canUserViewMissingDocs(user.role)

  const { data: revisionRequestEvents, isLoading: areRevisionsLoading } = useQuery(
    [queryKeys.requestRevisionEvents, requestId],
    async () => {
      if (!requestId) {
        throw new Error("requestId is not defined")
      }

      return revisionEventService.getRevisionRequestEvents({ requestId })
    },
    {
      enabled: canViewRevisions && Boolean(request?.revised),
    }
  )

  const showRevisionsTab = canUserViewRevisionsTab(
    request,
    isRequestLoading,
    user,
    revisionRequestEvents,
    areRevisionsLoading
  )

  const permissions: Record<REQUEST_VIEW_TABS, boolean> = useMemo(
    () => ({
      [REQUEST_VIEW_TABS.REQUEST]: true,
      [REQUEST_VIEW_TABS.MISSING_DOCS]: canUserViewMissingDocsPermission,
      [REQUEST_VIEW_TABS.REVISION]: showRevisionsTab,
    }),
    [canUserViewMissingDocsPermission, showRevisionsTab]
  )

  const [currentTab, setCurrentTab] = useState<REQUEST_VIEW_TABS>(tab)

  useEffect(() => {
    if (!isRequestLoading) {
      setCurrentTab(permissions[tab] ? tab : REQUEST_VIEW_TABS.REQUEST)
    }
  }, [tab, isRequestLoading, permissions])

  const canEditMissingDocuments = !isRevisionFeatureEnabled || canMissingDocumentsBeEdited(request)
  const { data: communicationEvents } = useQuery<MissingExhibitEvent[]>(
    [queryKeys.missingExhibitEvents, caseId],
    async () => {
      return await getMissingExhibitEvents({ caseId: caseId })
    },
    { enabled: !!caseId && canUserViewMissingDocsPermission }
  )

  const { data: missingExhibits } = useQuery<MissingExhibit[]>(
    [queryKeys.missingExhibits, caseId, !canEditMissingDocuments],
    async () => {
      return canEditMissingDocuments
        ? getCurrentMissingExhibits({ caseId: caseId })
        : getMissingExhibits({ caseId, onlyUnresolved: false })
    },
    {
      enabled: !!caseId && user.role !== OSF,
    }
  )

  const plaintiffs = request?.plaintiffs ?? []
  const primaryPlaintiff = plaintiffs[0] ?? {}

  if (!requestId || !request) {
    return <></>
  }

  let lastModifiedMessage = ""
  if (request.updated_at) {
    lastModifiedMessage = formatTimeSinceNow(request.updated_at)
  }

  const tabs = [
    <Tab
      key={REQUEST_VIEW_TABS.REQUEST}
      label="Demand Package"
      value={REQUEST_VIEW_TABS.REQUEST}
      data-test="request-details-tab"
    />,
  ]
  const tabPanels = [
    <TabPanel key={REQUEST_VIEW_TABS.REQUEST} value={REQUEST_VIEW_TABS.REQUEST}>
      <Request request={request} revisionRequest={revisionRequestEvents?.activeEvent?.revisionRequest} />
    </TabPanel>,
  ]

  if (canUserViewMissingDocsPermission) {
    const canOpenMissingDocs = communicationEvents?.length
    const allMissignExhibits = missingExhibits || []
    const notUploaded = allMissignExhibits.filter(
      missingExhibit => missingExhibit.instructions !== INSTRUCTIONS.UPLOAD
    )
    const missingDocsLabel =
      notUploaded.length && canOpenMissingDocs
        ? `Missing Documents (${notUploaded.length})`
        : `Missing Documents`

    tabs.push(
      <Tab
        key={REQUEST_VIEW_TABS.MISSING_DOCS}
        label={missingDocsLabel}
        value={REQUEST_VIEW_TABS.MISSING_DOCS}
        disabled={!canOpenMissingDocs}
        data-test="missing-documents-tab"
      />
    )
    tabPanels.push(
      <TabPanel key={REQUEST_VIEW_TABS.MISSING_DOCS} value={REQUEST_VIEW_TABS.MISSING_DOCS}>
        <MissingDocumentClientView caseId={caseData?.pk} isCompleted={!canEditMissingDocuments} />
      </TabPanel>
    )
  }

  if (isRevisionFeatureEnabled) {
    tabs.push(
      <Tab
        key={REQUEST_VIEW_TABS.REVISION}
        label="Revision"
        value={REQUEST_VIEW_TABS.REVISION}
        disabled={!permissions[REQUEST_VIEW_TABS.REVISION]}
        data-test="revisions-tab"
      />
    )
    tabPanels.push(
      <TabPanel key={REQUEST_VIEW_TABS.REVISION} value={REQUEST_VIEW_TABS.REVISION}>
        {permissions[REQUEST_VIEW_TABS.REVISION] ? (
          <RevisionRequestView requestId={requestId} requestStatus={request.intake_status} />
        ) : (
          <Navigate to={getRequestViewUrl(REQUEST_VIEW_TABS.REQUEST, true)} replace />
        )}
      </TabPanel>
    )
  }

  const oneTab = tabs.length === 1

  const setHref = (nextTab: REQUEST_VIEW_TABS) => {
    if (!permissions[nextTab]) {
      nextTab = REQUEST_VIEW_TABS.REQUEST
    }

    if (nextTab === currentTab) {
      return
    }

    const historyRecord = REQUEST_VIEW_HISTORY[nextTab]
    window.history.replaceState(historyRecord, historyRecord, getRequestViewUrl(nextTab))
  }

  return (
    <>
      <Helmet>
        <title>
          Request: {primaryPlaintiff.first_name} {primaryPlaintiff.last_name} - EvenUp
        </title>
      </Helmet>
      <Container>
        <TitleContainer display="flex">
          <Title>
            {primaryPlaintiff.first_name} {primaryPlaintiff.last_name}
          </Title>
          {request?.firm?.can_create_basic_plus_requests === true && (
            <Box ml={4} mt="auto" mb="auto">
              <RequestTypeBadge type={request.type} />
            </Box>
          )}
        </TitleContainer>
        <TabContext value={currentTab}>
          <Box display="flex" mb={2}>
            {!oneTab && (
              <Tabs
                TabIndicatorProps={{ style: { display: "none" } }}
                value={currentTab}
                onChange={(_, newValue) => {
                  setHref(newValue as REQUEST_VIEW_TABS)
                  setCurrentTab(newValue)
                }}
              >
                {tabs}
              </Tabs>
            )}
            <Box data-test="last-updated-time" mt="auto" mb="auto" ml="auto">
              Last Updated: {lastModifiedMessage}
            </Box>
          </Box>
          <Divider />

          {tabPanels}
        </TabContext>
      </Container>
    </>
  )
}
