import { ALL, INTAKE_STATUSES } from "./constants"

export const getEditDisabledMessageByStatus = statusId => {
  switch (statusId) {
    case INTAKE_STATUSES.inProgress:
      return `Cannot edit a demand that is In Progress. Please email legalops@evenuplaw.com for edit requests and include the name of the claimant in the email title.`
    case INTAKE_STATUSES.cancelled:
      return `Cannot edit a demand that is Cancelled. Please start a new request.`
    case INTAKE_STATUSES.completed:
      return `Cannot edit a demand that is Completed. You may download the demand package at anytime. If a rework is necessary, please request rework.`
    case INTAKE_STATUSES.rework:
      return `Cannot edit a demand that is in Rework. Please email legalops@evenuplaw.com for edit requests and include the name of the claimant in the email title.`
    case INTAKE_STATUSES.redelivered:
      return `Cannot edit a demand that is Redelivered. You may download the demand package at anytime. If a rework is necessary, please request rework.`
    default:
      return ``
  }
}

export const downloadAllFiles = async (endpoint, fileName, requestId, type = ALL) => {
  const response = await endpoint(requestId, type)

  // create file url based on blob
  const fileBlob = await response.blob()
  const url = window.URL.createObjectURL(new Blob([fileBlob]))
  // create a link element
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", `${fileName}-${requestId}.zip`)

  // add link to body and click on it
  document.body.appendChild(link)
  link.click()
  // clean up the link
  link.parentNode.removeChild(link)
}

export const assignedToUser = ({ user, request }) =>
  !!request.internal_assignees.find(assignee => assignee.pk === user.id)
