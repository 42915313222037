import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { WORKING_HOURS_TIMEFRAME, WORKING_HOURS_TIMEFRAME_OPTIONS } from "demand/constants"
import { SelectInput, InputField, CurrencyField } from "common/form-components"

import { StyledGridRow } from "../styled"

const useStyles = makeStyles(theme => ({
  percentages: {
    gridColumn: "1 / 3",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: theme.spacing(2),
  },
}))

export function HourlySalaryForm({ control, watch }) {
  const classes = useStyles()
  const workingHoursTimeframe = WORKING_HOURS_TIMEFRAME[watch("working_hours_timeframe")]
  const hoursLabel = workingHoursTimeframe
    ? `Hours worked per ${workingHoursTimeframe} before incident`
    : "Please select the working hours timeframe"

  return (
    <StyledGridRow>
      <Box className={classes.percentages}>
        <CurrencyField
          name="hourly_salary"
          control={control}
          label="Hourly rate"
          placeholder="Hourly rate prior to incident"
        />
        <SelectInput
          control={control}
          name="working_hours_timeframe"
          label="Working Hours Timeframe"
          options={WORKING_HOURS_TIMEFRAME_OPTIONS}
        />
        <InputField
          name="hours_worked"
          control={control}
          label={hoursLabel}
          placeholder="How many working hours"
          type="number"
          variant="outlined"
          inputProps={{ min: 0, step: 100 }}
          disabled={!workingHoursTimeframe}
        />
      </Box>
    </StyledGridRow>
  )
}
