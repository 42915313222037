import React, { useRef } from "react"
import { Control, Controller, ControllerProps, FieldValues, get as getValueByPath } from "react-hook-form"
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlProps,
} from "@material-ui/core"
import clsx from "clsx"
import { FieldPathWithValue } from "../types/helper"

type FieldError = {
  message?: Nullable<string>
}
type ErrorsFor<TFields extends FieldValues> = {
  [K in keyof TFields]?: Nullable<FieldError>
}

interface SelectInputProps<TFields extends FieldValues, TValue> extends FormControlProps {
  control: Control<TFields>
  name: FieldPathWithValue<TFields, TValue>
  options: ValueOptions<TValue>
  label?: Nullable<string>
  errors?: Nullable<ErrorsFor<TFields>>
  rules?: ControllerProps<TFields>["rules"]
}

export function SelectInput<TFields extends FieldValues, TValue>({
  control,
  name,
  label = "",
  options,
  errors = {},
  className = "",
  rules = {},
  disabled = false,
  size = "medium",
}: SelectInputProps<TFields, TValue>): JSX.Element {
  // need to do this in order to fix the label overflow
  // ref: https://github.com/mui-org/material-ui/issues/16954
  const labelRef = useRef<HTMLLabelElement>(null)
  const labelWidth = labelRef.current ? labelRef.current.clientWidth : 0
  const hasLabel = Boolean(label)
  const error = getValueByPath(errors, name)

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormControl variant="outlined" error={Boolean(error)} className={clsx(className)} size={size}>
          {hasLabel && (
            <InputLabel ref={labelRef} id={`${label}-label`}>
              {label}
            </InputLabel>
          )}

          <Select
            labelId={`${label}-label`}
            id={`select-input-${name}`}
            data-test={`select-input-${name}`}
            label={hasLabel ? label : undefined}
            labelWidth={hasLabel ? labelWidth : undefined}
            disabled={disabled}
            {...field}
          >
            {options?.map(option => (
              <MenuItem
                key={option.key as unknown as string}
                value={option.key as unknown as string}
                disabled={Boolean(option.disabled)}
              >
                {option.display}
              </MenuItem>
            ))}
          </Select>

          {error && (
            <FormHelperText>{error.message || "Please select an option from the list."}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}
