import React from "react"
import { useQuery } from "react-query"

import AutoCompleteFilter from "./AutoCompleteFilter"
import { getFirms } from "../../../api"
import { queryKeys } from "../../../react-query/constants"
import { FILTER_OPTION_ALL } from "../../../app/constants"

const FirmFilter = ({ onChange, value, className = "" }) => {
  const { isLoading, data, error } = useQuery([queryKeys.firms], getFirms)

  if (isLoading) {
    return <></>
  }

  if (error) {
    return <></>
  }

  const options = [
    FILTER_OPTION_ALL,
    ...data?.map(field => {
      return {
        key: field.pk,
        display: field.name,
      }
    }),
  ]

  const valueOption = options.find(option => option.key === value)

  return (
    <AutoCompleteFilter
      value={valueOption}
      onChange={onChange}
      options={options}
      label="Firm"
      className={className}
      id="firm-filter"
    />
  )
}

export { FirmFilter as default }
