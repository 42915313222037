import React, { useCallback, useEffect, useReducer, useRef, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { Box, Button, Typography, LinearProgress } from "@material-ui/core"
import { Sort } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import {
  createProviderExhibit,
  deleteProvider,
  fetchProvidersByCase,
  getCase,
  getProviderUpdatesForCase,
  getSectionMissingExhibits,
  importProviders,
  saveProvider,
  updateCptCodesForProvider,
  updateIcdCodesForProvider,
  updateProvider,
  updateProviderOrder,
} from "api"
import { reorderImmutable } from "utils"
import { queryKeys } from "react-query/constants"
import {
  ADD_PROVIDER,
  DELETE_PROVIDER,
  INITIAL_FORM_STATE,
  providerReducer,
  SET_PROVIDERS,
  SET_INITIAL_PROVIDERS,
  SET_VALIDATION_ERRORS,
  SORT_PROVIDERS,
  TOGGLE_DELETING,
  TOGGLE_SAVING,
} from "./reducer"
import { formSectionsToRoutes, STEP_STATUSES } from "../constants"
import { useFormContext } from "../context"
import { ApiError } from "apiHelper"
import { ChunkFileUploader } from "common/ChunkFileUploader"
import { validate } from "./validation"
import Summary from "./Summary"
import { ProviderListItem } from "./Provider/ProviderListItem"
import { GenericError } from "common"
import { SECTIONS } from "missing-docs/constants"
import useUser from "hooks/useUser"
import { isNotNotSetupRole } from "common/permission"
import { useHandleMessages } from "common/messages/useHandleMessages"
import TextButton from "common/buttons/TextButton"
import { getFilteredCodes } from "./Provider/utils"
import {
  updateProviderActions,
  updateInBackgroundProviderActions,
  updateWhenBlurredProviderActions,
  getNewProviderData,
  providerDataToFormData,
  createSetOfCodes,
} from "./utils"
import {
  PROVIDER_CONFIRMATION_MESSAGES,
  PROVIDER_ERRORS_MESSAGES,
  PROVIDER_SUCCESS_MESSAGES,
} from "./constants"
import { queryClient } from "react-query/queryClient"
import { useFeatures, FEATURES } from "hooks/useFeatures"

const useStyles = makeStyles(theme => ({
  summaryWrapper: {
    display: "flex",
    margin: theme.spacing(7, 0, 5, 0),
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(2),
      alignItems: "flex-start",
    },
  },
}))

export function ProviderList({ lastVisited }) {
  const [{ activeProviderId, providers, validationErrors }, dispatch] = useReducer(
    providerReducer,
    INITIAL_FORM_STATE
  )
  const chunkFileUploaderRef = useRef(null)
  const activeProviderCodes = useRef(null)
  const currentProviderRef = useRef()
  const { caseId, handleUpdateStepStatus, request } = useFormContext()
  const classes = useStyles()
  const { showMessage } = useHandleMessages()
  const { isFeatureEnabled } = useFeatures()
  const isProviderAutofillEnabled = isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL)
  const [importingProviders, setImportingProviders] = useState(isProviderAutofillEnabled)

  currentProviderRef.current = providers.find(({ pk }) => activeProviderId === pk)

  useQuery([], async () => importProviders(caseId), {
    enabled: importingProviders,
    onSuccess: () => {
      setImportingProviders(false)
    },
    meta: {
      disableLoader: true,
    },
  })

  const { isLoading: providersLoading, isError: providersError } = useQuery(
    [queryKeys.providers, caseId],
    fetchProvidersByCase,
    {
      onSuccess: data => {
        // only set the data once on initial load
        // this guard is here since calling queryClient.setQueryData triggers this onSuccess

        if (!data.length) handleUpdateStepStatus({ status: STEP_STATUSES.started })

        const providerFormData = data.map(providerDataToFormData)
        dispatch({
          type: SET_INITIAL_PROVIDERS,
          payload: { providers: providerFormData, caseId, isProviderAutofillEnabled },
        })
      },
      enabled: !importingProviders,
    }
  )

  const { data: providerUpdates } = useQuery(
    [queryKeys.providerUpdates, caseId],
    async () => {
      return getProviderUpdatesForCase(caseId)
    },
    {
      enabled: isProviderAutofillEnabled,
      meta: {
        disableLoader: true,
      },
    }
  )

  const { data: caseData } = useQuery([queryKeys.case, caseId], getCase)
  const hasCollateralSourceRule = !!caseData?.firm?.has_collateral_source_rule
  const { user } = useUser()

  const { data: missingExhibits } = useQuery(
    [queryKeys.missingExhibits, caseId],
    async () => {
      return await getSectionMissingExhibits({ caseId: caseId, section: SECTIONS.PROVIDERS })
    },
    {
      enabled: isNotNotSetupRole(user.role) && !!request?.pk,
    }
  )

  const saveMutation = useMutation(saveProvider)
  const updateMutation = useMutation(updateProvider)
  const deleteProviderMutation = useMutation(deleteProvider)
  const updateIcdCodesMutation = useMutation(updateIcdCodesForProvider)
  const updateCptCodesMutation = useMutation(updateCptCodesForProvider)
  const orderProvidersMutation = useMutation(updateProviderOrder)
  const createExhibitMutation = useMutation(createProviderExhibit)

  const handleSortProviders = () => {
    orderProvidersMutation.mutate({ data: { by: "first_contact" }, caseId })
    dispatch({ type: SORT_PROVIDERS })
  }

  const createExhibitFromQuestionnaireFile = useCallback(
    async (name, type, providerId, questionnaireFileId) => {
      let data = new FormData()
      data.append("name", name)
      data.append("type", type)
      data.append("section", "providers")
      data.append("section_index", 0)
      data.append("file", new Blob())
      data.append("questionnaire_file_id", questionnaireFileId)

      const exhibit = await createExhibitMutation.mutateAsync({
        data,
        caseId,
        providerId,
        isFormData: true,
      })

      return await exhibit.json()
    },
    [caseId, createExhibitMutation]
  )

  const uploadExhibit = useCallback(
    async ({ file, providerId, index }) => {
      const { file: fileObject, type, name } = file
      const { questionnaireFileId = null } = fileObject

      if (questionnaireFileId) {
        return await createExhibitFromQuestionnaireFile(name, type, providerId, questionnaireFileId)
      }

      const [uploadedFile] = await chunkFileUploaderRef.current.upload([fileObject])
      // chunkFileUploaderRef doesn't throw when uploading a chunk has failed
      // TODO test what happens when one chunk success but another fails
      if (!uploadedFile) {
        throw new Error(PROVIDER_ERRORS_MESSAGES.UPLOADNIG_FILE_FAIL)
      }
      const data = {
        name,
        type,
        upload_id: uploadedFile.uploadId,
        section: "providers",
        section_index: index,
      }

      const resultData = await createExhibitMutation.mutateAsync({
        data,
        providerId,
        caseId,
      })
      chunkFileUploaderRef.current.assignUploaded(uploadedFile.id, resultData.pk)

      return resultData
    },
    [caseId, createExhibitMutation, createExhibitFromQuestionnaireFile]
  )

  const handleExhibitDelete = useCallback(
    exhibitId => {
      chunkFileUploaderRef.current.removeRecentlyUploaded(exhibitId)
    },
    [chunkFileUploaderRef]
  )

  const createProviderUpdater = ({
    partiallyUpdatedAction,
    fullyUpdatedAction,
    showSaving,
    showMessages,
  }) => {
    return async provider => {
      if (!activeProviderCodes.current) return

      const [updateCPT, updateICD] = [
        activeProviderCodes.current?.cptCodes.size != provider.cpt_codes.length ||
          provider.cpt_codes.some(({ code }) => !activeProviderCodes.current?.cptCodes.has(code)),
        activeProviderCodes.current?.icdCodes.size != provider.icd_codes.length ||
          provider.icd_codes.some(({ code }) => !activeProviderCodes.current?.icdCodes.has(code)),
      ]

      if (updateCPT || updateICD) {
        activeProviderCodes.current.cptCodes = createSetOfCodes(provider.cpt_codes)
        activeProviderCodes.current.icdCodes = createSetOfCodes(provider.icd_codes)
      }

      dispatch({ type: SET_VALIDATION_ERRORS, payload: { validationErrors: [] } })

      const validation = validate(provider)
      if (validation) {
        dispatch({ type: SET_VALIDATION_ERRORS, payload: { validationErrors: validation } })
        showMessage({ type: "error", message: PROVIDER_ERRORS_MESSAGES.INPUT_ERROR })
        return
      }

      if (showSaving) {
        // set provider as saving to disable form
        dispatch({ type: TOGGLE_SAVING, payload: { id: provider.pk } })
      }

      const errors = []

      let icdCodesResult = null
      let cptCodesResult = null

      if (updateICD) {
        icdCodesResult = await updateIcdCodesMutation.mutateAsync({
          providerId: provider.pk,
          caseId,
          data: {
            // prefer the icd_code_id
            // when data is loaded from the server the pk is the id of the row of the relationship
            // not the actual icd code. The pk returned when selecting icd codes is the correct
            // id of the icd code itself.
            codes: getFilteredCodes(provider?.icd_codes).map(code => code.icd_code_id ?? code.pk),
          },
        })
      }

      if (updateCPT) {
        cptCodesResult = updateCptCodesMutation.mutateAsync({
          providerId: provider.pk,
          caseId,
          data: {
            codes: getFilteredCodes(provider?.cpt_codes).map(code => code.code),
          },
        })
      }

      const [...fileUploadResults] = await Promise.allSettled(
        provider?.filesToUpload?.map(file => uploadExhibit({ file, providerId: provider.pk })) ?? []
      )

      if (icdCodesResult?.status === "rejected") {
        errors.push(PROVIDER_ERRORS_MESSAGES.ICD_SAVING_ERROR)
      }

      if (cptCodesResult?.status === "rejected") {
        errors.push(PROVIDER_ERRORS_MESSAGES.CPT_SAVING_ERROR)
      }

      let updateProvider = {}
      const fileFormIdsToExhibitPks = {}
      const failedToUploadFormIds = {}
      fileUploadResults?.forEach((result, index) => {
        const formId = provider.filesToUpload[index].formId
        if (result.status === "rejected") {
          errors.push(PROVIDER_ERRORS_MESSAGES.UPLOADNIG_FILE_PROBLEM(provider.filesToUpload[index].name))
          failedToUploadFormIds[formId] = true
        } else {
          const newExhibit = result.value
          if (!updateProvider.exhibits) {
            updateProvider.exhibits = []
          }
          updateProvider.exhibits.push(newExhibit)
          fileFormIdsToExhibitPks[formId] = newExhibit.pk
        }
      })

      const bills = provider.bills.map(bill => {
        if (bill.file_to_upload_id) {
          return {
            ...bill,
            file_to_upload_id: null,
            exhibit_id: failedToUploadFormIds[bill.file_to_upload_id]
              ? null
              : fileFormIdsToExhibitPks[bill.file_to_upload_id],
          }
        }
        return bill
      })

      let providerResult = null
      try {
        providerResult = await updateMutation.mutateAsync({
          caseId,
          providerId: provider.pk,
          data: { ...provider, bills },
        })
      } catch (error) {
        if (error instanceof ApiError && error.validationErrors) {
          dispatch({ type: SET_VALIDATION_ERRORS, payload: { validationErrors: error.validationErrors } })
          showMessage({ type: "error", message: PROVIDER_ERRORS_MESSAGES.INPUT_ERROR })
        } else {
          showMessage({ type: "error", message: PROVIDER_ERRORS_MESSAGES.PROVIDER_UPDATING_ERROR })
        }
        if (showSaving) {
          dispatch({ type: TOGGLE_SAVING, payload: { id: provider.pk } })
        }
        return
      }

      if (errors.length) {
        showMessage({
          type: "warning",
          message: PROVIDER_ERRORS_MESSAGES.PROVIDER_SAVING_WITH_ERRORS(provider.name, errors),
        })
      }

      if (showSaving && provider) {
        const newFilesToUpload = provider.filesToUpload?.filter(({ formId }) => failedToUploadFormIds[formId])

        updateProvider = { ...updateProvider, ...providerResult, filesToUpload: newFilesToUpload }

        const formProvider = providerDataToFormData(updateProvider)

        dispatch({ type: TOGGLE_SAVING, payload: { id: provider.pk } })

        if (errors.length) {
          // there were errors with previous api calls but we were still able to update the provider
          dispatch(partiallyUpdatedAction(formProvider, caseId, isProviderAutofillEnabled))
        } else {
          dispatch(fullyUpdatedAction(formProvider, caseId, isProviderAutofillEnabled))
          if (showMessages) {
            showMessage({ type: "success", message: PROVIDER_SUCCESS_MESSAGES.PROVIDER_SAVED(provider.name) })
          }
        }
      }
    }
  }

  const handleUpdate = createProviderUpdater(updateProviderActions)
  const handleUpdateInBackground = createProviderUpdater(updateInBackgroundProviderActions)
  const handleUpdateWhenBlurred = createProviderUpdater(updateWhenBlurredProviderActions)

  const handleDelete = useCallback(
    async provider => {
      if (confirm(PROVIDER_CONFIRMATION_MESSAGES.DELETE_PROVIDER(provider.name))) {
        dispatch({ type: TOGGLE_DELETING, payload: { id: provider.pk } })
        try {
          await deleteProviderMutation.mutateAsync({ caseId, providerId: provider.pk })
        } catch (_error) {
          showMessage({
            type: "error",
            message: PROVIDER_ERRORS_MESSAGES.DELETING_PROVIDER_PROBLEM(provider.name),
          })
          dispatch({ type: TOGGLE_DELETING, payload: { id: provider.pk } })
          return
        }

        showMessage({ type: "success", message: PROVIDER_SUCCESS_MESSAGES.PROVIDER_DELETED(provider.name) })
        dispatch({ type: DELETE_PROVIDER, payload: { pk: provider.pk, caseId } })
        queryClient.invalidateQueries([queryKeys.steps, caseId])
      }
    },
    [caseId, deleteProviderMutation, showMessage]
  )

  const handleDragEnd = useCallback(
    ({ destination, source }) => {
      // dropped outside the list or moved to same position
      if (!destination || destination.index === source.index) return

      const newProviders = reorderImmutable(providers, source.index, destination.index)
      dispatch({ type: SET_PROVIDERS, payload: { providers: newProviders } })
      const order = {}
      newProviders.forEach((provider, index) => {
        // only set order for existing providers
        if (!provider.pk) return
        order[provider.pk] = index
      })
      orderProvidersMutation.mutate({ data: { provider_order: order }, caseId })
    },
    [caseId, orderProvidersMutation, providers]
  )

  const handleCreateNewProvider = useCallback(async () => {
    let provider = null

    try {
      provider = await saveMutation.mutateAsync({ caseId, data: getNewProviderData(providers) })
    } catch (error) {
      return showMessage({ type: "error", message: PROVIDER_ERRORS_MESSAGES.PROVIDER_CREATING_ERROR })
    }

    dispatch({ type: ADD_PROVIDER, payload: { provider, caseId } })
  }, [saveMutation, showMessage, caseId, providers])

  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.providers
  })

  useEffect(() => {
    const currentProvider = currentProviderRef.current

    if (currentProvider) {
      activeProviderCodes.current = {
        icdCodes: createSetOfCodes(currentProvider.icd_codes),
        cptCodes: createSetOfCodes(currentProvider.cpt_codes),
      }
    }

    return () => {
      activeProviderCodes.current = null
    }
  }, [activeProviderId, currentProviderRef])

  if (importingProviders) {
    return (
      <Box maxWidth="600px" margin="auto">
        <Typography>Importing Providers ... </Typography>
        <Box mt={2}>
          <LinearProgress />
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      <Box className={classes.summaryWrapper}>
        <Summary hasCollateralSourceRule={hasCollateralSourceRule} providers={providers} />
        <Button variant="contained" startIcon={<Sort />} onClick={handleSortProviders} disableElevation>
          Sort by First Contact
        </Button>
      </Box>

      {providersError ? (
        <GenericError />
      ) : (
        !providersLoading && (
          <>
            <Box mb={2}>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="providerList">
                  {(droppableProvided, droppableSnapshot) => (
                    <Box {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                      {providers.map((provider, index) => (
                        <ProviderListItem
                          key={String(provider.pk)}
                          provider={provider}
                          active={activeProviderId === provider.pk}
                          index={index}
                          anyProviderBeingDragged={Boolean(droppableSnapshot.draggingFromThisWith)}
                          dispatch={dispatch}
                          validationErrors={validationErrors}
                          uploadExhibit={uploadExhibit}
                          onUpdate={handleUpdate}
                          onUpdateInBackground={handleUpdateInBackground}
                          onUpdateWhenBlurred={handleUpdateWhenBlurred}
                          onDelete={handleDelete}
                          onDeleteExhibit={handleExhibitDelete}
                          providerDataToFormData={providerDataToFormData}
                          hasCollateralSourceRule={hasCollateralSourceRule}
                          missingExhibits={
                            missingExhibits?.filter(missingExhibit => {
                              if (!missingExhibit.provider) return false
                              return missingExhibit.provider.pk === provider.pk
                            }) ?? []
                          }
                          updates={providerUpdates ? providerUpdates[provider.pk] ?? [] : []}
                        />
                      ))}
                      {droppableProvided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
            <TextButton
              onClick={handleCreateNewProvider}
              disabled={Boolean(activeProviderId)}
              data-test="add-provider"
            >
              + Add Provider
            </TextButton>
            {Boolean(activeProviderId) && (
              <Box ml={2}>
                <Typography variant="caption" color="textSecondary">
                  Finish editing current provider to add a new one
                </Typography>
              </Box>
            )}
          </>
        )
      )}
      <ChunkFileUploader ref={chunkFileUploaderRef} onlyShowWhenUploading />
    </Box>
  )
}
