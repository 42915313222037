import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Select, MenuItem, FormControl, FormHelperText } from "@material-ui/core"

import { encodeExhibitValue } from "./utils"
import { EXHIBIT_ID, FILE_TO_UPLOAD_ID, PARTITION_ID } from "./constants"
import { EXHIBIT } from "demand/Providers/constants"
import { useFeatures, FEATURES } from "hooks/useFeatures"

const useStyles = makeStyles(() => ({
  billsSelect: {
    alignSelf: "flex-start",
  },
}))

export const ExhibitSelect = ({ value, onChange, disabled, provider, error, helperText }) => {
  const classes = useStyles()
  const { isFeatureEnabled } = useFeatures()
  const isProviderAutofillEnabled = isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL)

  return (
    <FormControl
      className={classes.billsSelect}
      variant="outlined"
      disabled={disabled}
      error={error}
      data-test="related-file-select"
    >
      <Select value={value ?? ""} onChange={onChange}>
        <MenuItem value="" disabled>
          Please Select
        </MenuItem>
        {provider?.filesToUpload?.map(({ name, formId }) => (
          <MenuItem
            data-test="related-file-name"
            key={formId}
            value={encodeExhibitValue(FILE_TO_UPLOAD_ID, formId)}
            disabled={disabled}
          >
            {name}
          </MenuItem>
        ))}
        {provider?.exhibits?.map(({ name, pk, exhibitType = null }) => (
          <MenuItem
            data-test="related-file-name"
            key={pk}
            value={
              !isProviderAutofillEnabled || !exhibitType || exhibitType === EXHIBIT
                ? encodeExhibitValue(EXHIBIT_ID, pk)
                : encodeExhibitValue(PARTITION_ID, pk)
            }
            disabled={disabled}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
      {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
