import React from "react"
import clsx from "clsx"
import { Box, Button, TextField, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useForm, Controller } from "react-hook-form"
import { useMutation } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import useUser from "../../hooks/useUser"
import { useAuth } from "../../hooks/useAuth"
import { changePassword } from "../../api"

const useStyles = makeStyles(theme => ({
  formFields: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr));",
    gap: theme.spacing(4),
  },
  twoCol: {
    gridColumn: "1 / 2",
  },
  fullWidth: {
    width: "500px",
    gridColumn: "1 / 5",
  },
  actions: {
    display: "flex",
    "& > *": {
      width: "100%",
    },
    "& > :last-child": {
      marginLeft: theme.spacing(2),
    },
  },
}))

const EMPTY_FORM_VALUES = {
  password: "",
  confirmPassword: "",
}

export function ResetPassword() {
  const classes = useStyles()
  const navigate = useNavigate()
  const { id: userId } = useParams()
  const { logout } = useAuth()
  const { user } = useUser()

  const {
    control,
    handleSubmit,
    watch,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: EMPTY_FORM_VALUES,
  })

  const updateUserPassword = useMutation(changePassword, {
    onSuccess: async () => {
      if (userId) {
        // changing a different user's password
        navigate("/settings/accounts")
      } else {
        await logout()
      }
    },
  })

  const submitForm = handleSubmit(async data => {
    const { password } = data
    // if the password is being changed from the profile page,
    // use the user's pk
    const id = userId ? userId : user.id
    await updateUserPassword.mutateAsync({ userId: id, data: { password } })
  })

  const isMatching = watch("password") === watch("confirmPassword")

  return (
    <Box>
      <Typography variant="h4" component="h1">
        Change Password
      </Typography>
      <form className={classes.formFields} onSubmit={submitForm} noValidate>
        <Controller
          name="password"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                className={classes.fullWidth}
                type="password"
                variant="outlined"
                label="New Password"
                {...field}
              />
            )
          }}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                type="password"
                className={classes.fullWidth}
                variant="outlined"
                label="Confirm password"
                error={dirtyFields.confirmPassword && !isMatching}
                helperText={dirtyFields.confirmPassword && !isMatching && "Passwords do not match"}
                {...field}
              />
            )
          }}
        />
        <Box className={clsx([classes.fullWidth, classes.actions])}>
          <Button disabled={!isMatching} type="submit" color="secondary" variant="contained">
            Save changes
          </Button>
          <Button variant="text" color="primary" onClick={() => navigate("../")}>
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  )
}
