import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Button, Drawer, IconButton, Typography } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

const useStyles = makeStyles(theme => ({
  listView: {
    display: "flex",
    flexDirection: "column",
  },
  drawer: {
    padding: theme.spacing(3, 3, 5),
    width: theme.spacing(55),
    height: "100%",
  },
  headerContainer: {
    margin: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "row",
  },
  title: {
    marginRight: "auto",
    fontWeight: 500,
    fontSize: theme.spacing(2.5),
    marginLeft: theme.spacing(1),
  },
  closeButton: {
    marginLeft: "auto",
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  childrenContainer: {
    width: "100%",
    margin: theme.spacing(0, 1),
  },
  childContainer: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
  filler: {
    flexGrow: 1,
    height: "100%",
  },
  bottomContianer: {
    paddingTop: theme.spacing(1),
    width: theme.spacing(4),
    flexGrow: 1,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    transition: "0.2s ease-out",
    "&:hover": {
      backgroundColor: "white",
      color: theme.palette.primary.main,
    },
  },
}))

const ActionDrawer = ({ children, open, onClose, onSave, title }) => {
  const classes = useStyles()

  const handleClose = e => {
    e.preventDefault()
    e.stopPropagation()
    onClose(e)
  }

  const handleSave = async e => {
    await onSave(e)
    handleClose(e)
    return e
  }

  const childrenComponents = children.length ? [...children] : [children]

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <Box className={`${classes.listView} ${classes.drawer}`}>
        <div className={classes.headerContainer}>
          <Typography className={classes.title}>{title}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon className={classes.closeButton} />
          </IconButton>
        </div>
        <Box className={classes.childrenContainer}>
          {childrenComponents.map((child, index) => {
            if (child === false) return
            return (
              <Box key={`drawer-item-${index}`} className={classes.childContainer}>
                {child}
              </Box>
            )
          })}
        </Box>
        <div className={classes.filler}></div>
        <div className={classes.bottomContianer}>
          <Button
            className={classes.button}
            onClick={handleSave}
            size="medium"
            variant="contained"
            data-test="confirm-assignee"
          >
            Confirm
          </Button>
        </div>
      </Box>
    </Drawer>
  )
}

export { ActionDrawer as default }
