import React, { useCallback, useMemo, useEffect, useRef } from "react"
import { useMutation } from "react-query"
import { Box, Collapse, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Draggable } from "react-beautiful-dnd"
import clsx from "clsx"
import { useHandleMessages } from "common/messages/useHandleMessages"
import {
  deleteExhibit,
  downloadExhibit,
  downloadPartition,
  updateExhibit,
  updatePartition,
  unlinkPartitionFromProvider,
} from "api"
import { useDebouncedCallback } from "use-debounce/lib"
import {
  APPEND_EXHIBIT,
  SET_EXHIBITS,
  DELETE_EXHIBIT,
  UPDATE_PROVIDER,
  CLOSE_EDITING_PROVIDER,
  DELETE_PARTITION,
} from "demand/Providers/reducer"
import { useFormContext } from "demand/context"

import { ProviderForm } from "./ProviderForm/ProviderForm"
import ProviderDetails from "./ProviderDetails"
import { ProviderCollapsedRow } from "./ProviderCollapsedRow"
import { shouldUpdateWithDebounce, shouldUpdateImmideately as shouldUpdateImmediately } from "./utils"
import usePrompt from "hooks/usePrompt"
import { FEATURES, isFeatureEnabled } from "hooks/useFeatures"
import { StyledConflictIndicator } from "./styled"
import { PARTITIONED_EXHIBIT } from "../constants"
import { DEFAULT_WINDOW_CONFIGURATIONS } from "app/constants"

const DEBOUNCE_TIME = 2000

const useStyles = makeStyles(theme => ({
  providerContainer: {
    border: props => `1px solid ${props.hasConflict ? theme.palette.error.main : theme.palette.grey[300]}`,
    marginTop: "-1px",
    "$activeProviderContainer + &": {
      borderTopColor: theme.palette.blue.main,
    },
  },
  providerContainerDragHappening: {
    borderColor: `${theme.palette.grey[300]} !important`,
  },
  activeProviderContainer: {
    borderColor: `${theme.palette.blue.main} !important`,
  },
  providerWithConflict: {
    zIndex: 1,
    position: "relative",
  },
}))

export const ProviderListItem = ({
  provider,
  active,
  index,
  anyProviderBeingDragged,
  dispatch,
  validationErrors,
  uploadExhibit,
  onUpdate,
  onDelete,
  onDeleteExhibit,
  providerDataToFormData,
  hasCollateralSourceRule,
  missingExhibits,
  onUpdateInBackground,
  onUpdateWhenBlurred,
  updates,
}) => {
  const previousProvider = useRef(provider)
  const { caseId } = useFormContext()
  const updateExhibitMutation = useMutation(updateExhibit)
  const deleteExhibitMutation = useMutation(deleteExhibit)
  const updatePartitionMutation = useMutation(updatePartition)
  const downloadExhibitMutation = useMutation(downloadExhibit)
  const downloadPartitionedExhibitMutation = useMutation(downloadPartition)
  const unlinkPartitionMutation = useMutation(unlinkPartitionFromProvider)
  const isProviderTemplatesFeatureEnabled = isFeatureEnabled(FEATURES.PROVIDER_TEMPLATES)
  const isProviderAutofillEnabled = isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL)
  const isReviewNeeded = provider?.templated_sections?.[0]?.user_action_required
  const hasConflict = !active && isReviewNeeded && isProviderTemplatesFeatureEnabled
  const classes = useStyles({ hasConflict })

  const { showMessage } = useHandleMessages()

  const handleExhibitReorder = newExhibits => {
    newExhibits.forEach((exhibit, exhibitIndex) => {
      if (isProviderAutofillEnabled && exhibit.exhibitType === PARTITIONED_EXHIBIT) {
        updatePartitionMutation.mutate({
          exhibitId: exhibit.exhibit_id,
          partitionId: exhibit.pk,
          data: {
            section_index: exhibitIndex,
          },
        })
      } else {
        updateExhibitMutation.mutate({
          caseId,
          exhibitId: exhibit.pk,
          data: {
            case_id: caseId,
            pk: exhibit.pk,
            section_index: exhibitIndex,
          },
        })
      }
    })
    dispatch({
      type: SET_EXHIBITS,
      payload: { caseId, providerPk: provider.pk, exhibits: newExhibits },
    })
  }

  const debouncedUpdate = useDebouncedCallback(newProvider => {
    const result = { ...newProvider, filesToUpload: undefined }
    onUpdateInBackground(result, index)
  }, DEBOUNCE_TIME)

  const handleProviderChange = useCallback(
    newProvider => {
      dispatch({ type: UPDATE_PROVIDER, payload: { newProvider, caseId } })
    },
    [dispatch, caseId]
  )

  const handleExhibitDelete = exhibitToDelete => {
    if (
      provider.bills?.some(bill => bill.exhibit_id === exhibitToDelete.pk) &&
      !confirm(
        "One or more bills have this file attached. If you delete it you will need to choose a new file for those bills. Would you like to delete this file?"
      )
    ) {
      return
    }

    deleteExhibitMutation.mutate({
      caseId,
      providerId: provider.pk,
      exhibitId: exhibitToDelete.pk,
    })

    dispatch({
      type: DELETE_EXHIBIT,
      payload: { caseId, providerPk: provider.pk, exhibitPk: exhibitToDelete.pk },
    })

    showMessage({ type: "success", message: `Deleted ${exhibitToDelete.name}` })
    onDeleteExhibit(exhibitToDelete.pk)
  }

  const handleExhibitDownload = async exhibitToDownload => {
    let result

    try {
      result = await downloadExhibitMutation.mutateAsync({
        caseId,
        exhibitId: exhibitToDownload.pk,
        fileName: exhibitToDownload.name,
        downloadFile: true,
      })
    } catch (error) {
      showMessage({
        type: "error",
        message:
          "Error updating provider. Please try again shortly and if your problem persists contact a dev.",
      })
    }

    return result
  }

  const handlePartitionDelete = partitionToDelete => {
    if (
      provider.bills?.some(bill => bill.partition_id === partitionToDelete.pk) &&
      !confirm(
        "One or more bills have this file attached. If you delete it you will need to choose a new file for those bills. Would you like to delete this file?"
      )
    ) {
      return
    }

    unlinkPartitionMutation.mutate({
      partitionId: partitionToDelete.pk,
      exhibitId: partitionToDelete.exhibit_id,
      data: {},
    })

    dispatch({
      type: DELETE_PARTITION,
      payload: {
        caseId,
        providerPk: provider.pk,
        partitionPk: partitionToDelete.pk,
      },
    })

    showMessage({ type: "success", message: `Deleted ${partitionToDelete.name}` })
    onDeleteExhibit(partitionToDelete.pk)
  }

  const handlePartitionedExhibitDownload = async partitionedExhibitToDownload => {
    let result

    try {
      result = await downloadPartitionedExhibitMutation.mutateAsync({
        caseId,
        exhibitId: partitionedExhibitToDownload.exhibit_id,
        partitionId: partitionedExhibitToDownload.pk,
      })
    } catch (error) {
      showMessage({
        type: "error",
        message:
          "Error downloading partition. Please try again shortly and if your problem persists contact a dev.",
      })
    }

    return result
  }

  const handleSave = () => {
    debouncedUpdate.cancel()

    onUpdate(provider, index)
  }

  const handleCancel = () => {
    dispatch({
      type: CLOSE_EDITING_PROVIDER,
      payload: {
        caseId,
        pk: provider.pk,
      },
    })
  }

  const handleAppendExhibit = useCallback(
    (exhibit, providerId) => {
      dispatch({
        type: APPEND_EXHIBIT,
        payload: {
          caseId,
          providerId: providerId,
          exhibit: exhibit,
        },
      })
    },
    [caseId, dispatch]
  )

  const wrapperClassName = useMemo(() => {
    // if anything is being dragged, set non-active element border colors to grey
    const className = active
      ? classes.activeProviderContainer
      : anyProviderBeingDragged && classes.providerContainerDragHappening

    const providerWithConflictClassName = hasConflict && classes.providerWithConflict
    return clsx(classes.providerContainer, className, providerWithConflictClassName)
  }, [active, anyProviderBeingDragged, classes, hasConflict])

  const handleBlur = useCallback(() => {
    if (debouncedUpdate.isPending()) {
      debouncedUpdate.flush()
    }
  }, [debouncedUpdate])

  const disabled = !!(
    provider.saving ||
    provider.deleting ||
    provider.filesToUpload?.some(({ uploading }) => Boolean(uploading))
  )

  usePrompt(
    "You are currently editing a provider, if you leave this page any unsaved changes will be lost. Would you like to leave the page?",
    active && (debouncedUpdate.isPending() || disabled)
  )

  useEffect(() => {
    if (shouldUpdateImmediately(previousProvider.current, provider)) {
      onUpdateWhenBlurred(provider)
    }

    if (shouldUpdateWithDebounce(previousProvider.current, provider)) {
      debouncedUpdate(provider)
    }

    previousProvider.current = provider
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider])

  const openPDFViewerWindow = useCallback(
    ({ exhibitId, partitionId, page }) => {
      if (!isProviderAutofillEnabled) {
        return
      }

      const partitionPath = partitionId ? `/partition/${partitionId}` : ""
      const pageQuery = page ? `?page=${page}` : ""
      window.open(
        `/exhibit-preview/${caseId}/exhibit/${exhibitId}${partitionPath}${pageQuery}`,
        "_blank",
        DEFAULT_WINDOW_CONFIGURATIONS
      )
    },
    [caseId, isProviderAutofillEnabled]
  )

  return (
    <Draggable key={String(provider.pk)} draggableId={String(provider.pk)} index={index}>
      {draggableProvided => (
        <Box
          data-test="provider"
          className={wrapperClassName}
          {...draggableProvided.draggableProps}
          ref={draggableProvided.innerRef}
        >
          {hasConflict && (
            <Tooltip title="Provider has a conflict">
              <StyledConflictIndicator />
            </Tooltip>
          )}
          <form onBlur={handleBlur}>
            <ProviderCollapsedRow
              dispatch={dispatch}
              provider={provider}
              active={active}
              dragHandleProps={draggableProvided.dragHandleProps}
              updates={updates}
            />
            <Collapse in={provider.open} mountOnEnter unmountOnExit={!active}>
              {active ? (
                <ProviderForm
                  disabled={disabled}
                  provider={provider}
                  uploadExhibit={uploadExhibit}
                  validationErrors={validationErrors}
                  onChange={handleProviderChange}
                  onExhibitReorder={handleExhibitReorder}
                  onExhibitDelete={handleExhibitDelete}
                  onExhibitDownload={handleExhibitDownload}
                  onPartitionDelete={handlePartitionDelete}
                  onPartitionDownload={handlePartitionedExhibitDownload}
                  onSave={handleSave}
                  onCancel={handleCancel}
                  onDelete={onDelete}
                  providerDataToFormData={providerDataToFormData}
                  hasCollateralSourceRule={hasCollateralSourceRule}
                  missingExhibits={missingExhibits}
                  onAppendExhibit={handleAppendExhibit}
                  dispatch={dispatch}
                  updateExhibitMutation={updateExhibitMutation}
                  updatePartitionMutation={updatePartitionMutation}
                  openPDFViewerWindow={openPDFViewerWindow}
                  updates={updates}
                />
              ) : (
                <ProviderDetails
                  provider={provider}
                  onExhibitReorder={handleExhibitReorder}
                  onExhibitDelete={handleExhibitDelete}
                  onExhibitDownload={handleExhibitDownload}
                  onPartitionDelete={handlePartitionDelete}
                  onPartitionDownload={handlePartitionedExhibitDownload}
                  hasCollateralSourceRule={hasCollateralSourceRule}
                  missingExhibits={missingExhibits}
                  onAppendExhibit={handleAppendExhibit}
                  openPDFViewerWindow={openPDFViewerWindow}
                  caseId={caseId}
                  updates={updates}
                />
              )}
            </Collapse>
          </form>
        </Box>
      )}
    </Draggable>
  )
}
