import React, { CSSProperties, memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FixedSizeList } from "react-window"
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api"
import { ThumbnailsContainer } from "./styled"
import { Thumbnail } from "./Thumbnail"
import { PREVIEW_ITEM_HEIGHT, PREVIEW_ITEM_INDICATOR_HEIGHT } from "./constants"
import { ThumbnailCacheContextType, ThumbnailCacheProvider } from "./ThumbnailCache"

interface PdfThumbnailsProps {
  document: PDFDocumentProxy
  currentPage: number
  pageNumberAdjustment?: number
  onPageSelect: (page: number) => void
  deletedPages: Set<number>
}

interface PdfThumbnailRendererProps {
  data: Required<PdfThumbnailsProps>
  index: number
  style: CSSProperties
}

const PdfThumbnailRenderer = memo(function PdfThumbnailRenderer({
  data: { document, currentPage, onPageSelect, pageNumberAdjustment, deletedPages },
  index,
  style,
}: PdfThumbnailRendererProps): JSX.Element {
  const pageNumber = index + 1

  return (
    <div style={style} data-page-index={pageNumber}>
      <Thumbnail
        deleted={deletedPages.has(pageNumber)}
        document={document}
        page={pageNumber}
        pageNumberAdjustment={pageNumberAdjustment}
        selected={pageNumber === currentPage}
        onClick={onPageSelect}
      />
    </div>
  )
})

export const PdfThumbnails = memo(function PdfThumbnails({
  document,
  currentPage,
  pageNumberAdjustment = 0,
  onPageSelect,
  deletedPages,
}: PdfThumbnailsProps): JSX.Element {
  const pagesCount = useMemo(() => document.numPages, [document])
  const thumbnailData = useMemo(
    () => ({
      document,
      currentPage,
      pageNumberAdjustment,
      onPageSelect,
      deletedPages,
    }),
    [document, currentPage, pageNumberAdjustment, onPageSelect, deletedPages]
  )
  const [listHeight, setListHeight] = useState<Nullable<number>>(null)
  const ref = useRef<HTMLDivElement>(null)
  const offsetRef = useRef<Nullable<number>>(null)
  const cache = useMemo<ThumbnailCacheContextType>(() => ({}), [])
  const initialScrollOffset = useRef(
    (PREVIEW_ITEM_HEIGHT + PREVIEW_ITEM_INDICATOR_HEIGHT) * (Math.min(currentPage, pagesCount) - 1)
  )
  const listRef = useRef<FixedSizeList>(null)

  const handleResize = useCallback(() => {
    if (!ref.current) return

    setListHeight(ref.current.getBoundingClientRect().height)
  }, [])

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(entries => {
        if (!ref.current) return

        for (const entry of entries) {
          setListHeight(entry.contentRect.height - (offsetRef.current ?? 0))
        }
      }),
    []
  )

  useEffect(() => {
    if (ref.current) {
      offsetRef.current = ref.current.offsetTop
      resizeObserver.observe(window.document.documentElement)

      return () => resizeObserver.unobserve(window.document.documentElement)
    }
  }, [handleResize, resizeObserver])

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(currentPage - 1, "auto")
    }
  }, [listHeight, currentPage])

  return (
    <ThumbnailCacheProvider value={cache}>
      <ThumbnailsContainer ref={ref} data-test="pdf-thumbnails-list">
        {listHeight !== null && (
          <FixedSizeList
            ref={listRef}
            height={listHeight}
            width={"100%"}
            itemCount={pagesCount}
            itemSize={PREVIEW_ITEM_HEIGHT + PREVIEW_ITEM_INDICATOR_HEIGHT}
            itemData={thumbnailData}
            overscanCount={2}
            initialScrollOffset={initialScrollOffset.current}
          >
            {PdfThumbnailRenderer}
          </FixedSizeList>
        )}
      </ThumbnailsContainer>
    </ThumbnailCacheProvider>
  )
})
