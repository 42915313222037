import { BaseServiceDeserializer } from "api/BaseDeserializer"
import { handleEmptyResponse } from "api/utils"
import { withResponseSerializer } from "api/withSerializers"
import { RawModel } from "common/helpers/object"
import { isUndefined } from "lodash"
import { apiService } from "./ApiService"
import { ApiServiceType } from "./types"

enum USER_API_PATHS {
  BASE = "users",
  IS_TRIALING = "isTrialing",
}

interface TrialData {
  is_trialing: boolean
}

class UserService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(path?: USER_API_PATHS): string {
    const pathParts = ["", USER_API_PATHS.BASE, path]
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getTrialData = withResponseSerializer<RawModel<TrialData>, TrialData, null>(
    BaseServiceDeserializer.fromJSON,
    () => {
      const path = this.getPath(USER_API_PATHS.IS_TRIALING)
      return handleEmptyResponse(this.apiService.get(null, path))
    }
  )
}

export const userService = new UserService(apiService)
