import React, { useContext } from "react"
import { SectionAndAttributesFilter } from "common/attributes-filter"
import { LibraryFiltersContext } from "./context"

interface LibraryFiltersProps {
  onFilterChange?: () => void
  showSection?: boolean
}

export function LibraryFilters({ onFilterChange, showSection = true }: LibraryFiltersProps): JSX.Element {
  const { attributes, setFilterValues, filterValues } = useContext(LibraryFiltersContext)

  if (!attributes) {
    return <></>
  }

  return (
    <>
      <SectionAndAttributesFilter
        showSection={showSection}
        attributes={attributes}
        beforeAttributeChange={onFilterChange}
        onChange={setFilterValues}
        initialSection={filterValues.section}
        initialAttributeValues={filterValues.attributeValues}
      />
      <div style={{ flexGrow: 1 }} />
    </>
  )
}
