import React from "react"
import { variablesService } from "api/services/variables"
import { Control } from "react-hook-form"
import { useQuery } from "react-query"
import { queryKeys } from "react-query/constants"
import { RichTextField } from "../../common/form-components/rich-text"
import { FirmData } from "./Firm"
import Placeholders from "./Placeholders"
import { Variable } from "common/types/variables"

export type FirmDemandDataFields = Partial<FirmData> &
  Pick<
    FirmData,
    "demand_introduction" | "demand_conclusion" | "demand_introduction_json" | "demand_conclusion_json"
  >

interface FirmDemandProps {
  control: Control<FirmDemandDataFields>
}

export const FirmDemandEditorFields: React.FC<FirmDemandProps & { variables: Variable[] }> = ({
  control,
  variables,
}) => {
  return (
    <>
      <RichTextField
        control={control}
        name="demand_introduction_json"
        markdownName="demand_introduction"
        label="Firm-specific demand introduction"
        withVariables
        variables={variables}
      />
      <RichTextField
        control={control}
        name="demand_conclusion_json"
        markdownName="demand_conclusion"
        label="Firm-specific demand conclusion"
        withVariables
        variables={variables}
      />
    </>
  )
}

export const FirmDemand: React.FC<FirmDemandProps> = ({ control }) => {
  const { data: variables } = useQuery(queryKeys.defaultVariables, variablesService.getDefaultCaseVariables, {
    keepPreviousData: true,
  })

  return (
    <>
      <Placeholders />
      <FirmDemandEditorFields control={control} variables={variables ?? []} />
    </>
  )
}
