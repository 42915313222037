import React, { useCallback } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box } from "@material-ui/core"
import { queryKeys } from "react-query/constants"
import { SECTIONS } from "missing-docs/constants"
import { useQuery } from "react-query"
import { INCOME_FILE_OPTIONS } from "demand/constants"
import { ExhibitList } from "common"
import { getSectionMissingExhibits } from "api"
import { FileUploader } from "demand/FileUploader"
import MissingDocumentSection from "demand/MissingDocumentSection"
import { isNotNotSetupRole } from "common/permission"
import useUser from "hooks/useUser"

import { IncomeLossTable } from "./IncomeLossTable"

const useStyles = makeStyles(theme => ({
  fullWidth: {
    gridColumn: "1 / 3",
  },
  files: {
    marginTop: theme.spacing(2),
  },
}))

export function AdditionalInfoSection({
  endDate,
  startDate,
  files,
  caseId,
  fileToUpload,
  queryClient,
  setFileToUpload,
  handleFileChange,
  request,
  salaryInformationType,
}) {
  const classes = useStyles()
  const { user } = useUser()
  const showIncomeLossTable = startDate && endDate

  const { data: missingExhibits } = useQuery(
    [queryKeys.missingExhibits, caseId],
    async () => {
      return await getSectionMissingExhibits({ caseId: caseId, section: SECTIONS.INCOME_LOSS })
    },
    {
      enabled: isNotNotSetupRole(user.role),
    }
  )
  const onSaveFile = useCallback(() => queryClient.invalidateQueries(queryKeys.incomeLoss), [queryClient])

  return (
    <>
      {Boolean(files?.length) && (
        <ExhibitList
          caseId={caseId}
          exhibits={files}
          fileTypeMap={INCOME_FILE_OPTIONS}
          onDelete={exhibit => handleFileChange(exhibit, true)}
          onSuccessfulSave={onSaveFile}
        />
      )}
      <Box className={classes.files}>
        <FileUploader
          fileTypes={INCOME_FILE_OPTIONS}
          fileToUpload={fileToUpload}
          setFileToUpload={setFileToUpload}
          handleFileChange={handleFileChange}
        />
      </Box>
      {Boolean(request?.pk) && (
        <Box className={classes.fullWidth} mt={2}>
          <MissingDocumentSection
            missingDocs={missingExhibits}
            title="Missing Documents List"
            section={SECTIONS.INCOME_LOSS}
          />
        </Box>
      )}
      {showIncomeLossTable && <IncomeLossTable caseId={caseId} salaryType={salaryInformationType} />}
    </>
  )
}
