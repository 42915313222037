import React, { useCallback, useEffect, useState, useMemo } from "react"
import { useImmerReducer } from "use-immer"
import { Box, Button, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { queryKeys } from "react-query/constants"
import { fetchProvidersByCase, getCase, getCaseFacts } from "api"

import { FormProvider } from "./FormContext"
import { actions as queryActions, INITIAL_STATE, QueryReducer } from "./state"
import SearchResults from "./SearchResults"
import { Helmet } from "react-helmet"
import { FREE_TEXT_QUERY } from "./constants"
import { getSummaryData } from "demand/Providers/Summary"
import { noop } from "lodash"
import { getQueryParamsFromState, getSearchParamsFromQueryString } from "./utils"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    width: "90vw",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },

  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: `10%`,
    left: `10%`,
    transform: `translate(-10%, -10%)`,
  },
}))

export function Search({ lastSearchParams, setLastSearchParams = noop }) {
  const classes = useStyles()
  const { id: caseId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [text, setText] = useState("")
  const [isStateChanged, setIsStateChanged] = useState(false)
  const [isInitialStateApplied, setIsInitialStateApplied] = useState(false)

  const { data: caseInfo, isLoading } = useQuery([queryKeys.case, caseId], getCase, {
    enabled: !!caseId,
  })

  // HACK (T-7052) - Getting case facts for case type
  const { data: caseFactsInfo, isLoading: isFactsLoading } = useQuery(
    [queryKeys.caseFactsInfo, caseId],
    async () => getCaseFacts(caseId),
    {
      enabled: !!caseId,
    }
  )

  // HACK (T-7052) - Map from case type to search param
  const caseTypeToSearchParam = useMemo(() => {
    return {
      motor_vehicle: "motor vehicle",
      med_malpractice: "medical malpractice",
    }
  }, [])

  const { isLoading: providersLoading, data: providers } = useQuery(
    [queryKeys.providers, caseId],
    fetchProvidersByCase,
    {
      enabled: !!caseId,
    }
  )
  const [queryState, dispatch] = useImmerReducer(QueryReducer, INITIAL_STATE)

  const handleQueryInputChange = useCallback(event => {
    setText(event.target.value)
  }, [])

  const handleQueryUpdate = useCallback(
    (action, payload) => {
      setIsStateChanged(true)
      dispatch({ action, payload })
    },
    [dispatch]
  )

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      handleQueryUpdate(queryActions.UPDATE_QUERY_TEXT, text)
    },
    [handleQueryUpdate, text]
  )

  const applyInitialParams = useCallback(
    initialParams => {
      setIsInitialStateApplied(true)
      dispatch({ action: queryActions.SET_INITIAL_STATE_PARAMS, payload: initialParams })
    },
    [dispatch]
  )

  useEffect(() => {
    // should be called only once
    // priority of the params: location.search => lastSearch => params based on the corresponding demand info
    if (isInitialStateApplied) {
      return
    }

    if (location.search) {
      const paramsFromURLQuery = getSearchParamsFromQueryString(location.search)

      setText(paramsFromURLQuery[FREE_TEXT_QUERY])
      applyInitialParams(paramsFromURLQuery)

      return
    }

    if (isLoading || providersLoading || isFactsLoading) {
      return
    }

    const initialSearchParams = { [FREE_TEXT_QUERY]: "", query: {} }

    if (caseInfo?.state) {
      initialSearchParams.query.state = [caseInfo.state]
    }

    // HACK (T-7052) - Applying case type if it is set automatically
    if (caseFactsInfo?.type && caseTypeToSearchParam[caseFactsInfo?.type]) {
      initialSearchParams.query.high_level_categories = [caseTypeToSearchParam[caseFactsInfo.type]]
    }

    if (providers) {
      const { totalMedicalExpenses } = getSummaryData(providers)

      if (totalMedicalExpenses !== 0) {
        initialSearchParams.query.total_settlement_amount = `[[${Math.ceil(
          totalMedicalExpenses * 3
        )},${Math.ceil(totalMedicalExpenses * 100)}]]`
      }
    }

    if (lastSearchParams) {
      const paramsFromLastSearch = getSearchParamsFromQueryString(`?${lastSearchParams}`)

      initialSearchParams[FREE_TEXT_QUERY] = paramsFromLastSearch[FREE_TEXT_QUERY]
      initialSearchParams.query = { ...initialSearchParams.query, ...paramsFromLastSearch.query }

      setText(initialSearchParams.initialText)
      navigate(`?${lastSearchParams}`, { replace: true })
    }

    applyInitialParams(initialSearchParams)
  }, [
    applyInitialParams,
    caseInfo,
    caseFactsInfo,
    caseTypeToSearchParam,
    isInitialStateApplied,
    isLoading,
    isFactsLoading,
    lastSearchParams,
    location.search,
    navigate,
    providers,
    providersLoading,
  ])

  useEffect(() => {
    if (!isStateChanged) return
    // sync state to URL
    const newParams = getQueryParamsFromState(queryState)

    if (newParams && location.search !== `?${newParams}`) {
      setLastSearchParams(newParams)
      navigate(`?${newParams}`)
    }
  }, [location.search, navigate, queryState, setLastSearchParams, isInitialStateApplied, isStateChanged])

  return (
    <>
      <Helmet>
        <title>Search - EvenUp</title>
      </Helmet>
      <Box maxWidth="md" component="main">
        <div className={classes.paper}>
          <FormProvider value={{ queryState, handleQueryUpdate, queryActions }}>
            <form className={classes.form} onSubmit={handleSubmit} autoComplete="off">
              <Box maxWidth={"60%"}>
                <TextField
                  id="query"
                  label="Case facts / Injuries"
                  placeholder="Describe the facts or injuries for the case"
                  variant="outlined"
                  name="facts"
                  value={text}
                  onChange={handleQueryInputChange}
                  autoFocus
                  fullWidth
                  data-test="search-bar"
                />
              </Box>
              <Button
                className={classes.submit}
                variant="contained"
                color="primary"
                type="submit"
                data-test="search-button"
                onClick={handleSubmit}
              >
                Search
              </Button>
            </form>
            <SearchResults caseId={caseId} />
          </FormProvider>
        </div>
      </Box>
    </>
  )
}
