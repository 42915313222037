import { EXHIBIT_ID, FILE_TO_UPLOAD_ID, PARTITION_ID } from "./constants"

// uuid, the library we use for generating frontend form ids, does not use ":" so we are safe to use it for encoding
// e.g, "file_to_upload_id:some-long-uuid" --> ["file_to_upload_id", "some-long-uuid"]
export const decodeExhibitValue = value => {
  const [type, stringId] = value.split(":", 2)
  const id = type === EXHIBIT_ID ? parseInt(stringId) : stringId

  return [type, id]
}

// e.g, "file_to_upload_id", "some-long-uuid" --> "file_to_upload_id:some-long-uuid"
export const encodeExhibitValue = (type, id) => `${type}:${id}`

export const getBillExhibitValue = bill => {
  if (bill.exhibit_id) {
    return encodeExhibitValue(EXHIBIT_ID, bill.exhibit_id)
  }

  if (bill.partition_id) {
    return encodeExhibitValue(PARTITION_ID, bill.partition_id)
  }

  if (bill.file_to_upload_id) {
    return encodeExhibitValue(FILE_TO_UPLOAD_ID, bill.file_to_upload_id)
  }

  return ""
}
