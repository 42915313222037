import React, { useState, useEffect, useCallback, useMemo } from "react"
import { Box, Divider, TextField, Tooltip, Typography } from "@material-ui/core"
import { makeStyles, styled } from "@material-ui/core/styles"
import { HelpOutline } from "@material-ui/icons"
import { queryKeys } from "react-query/constants"
import { getFutureExpenses, createFutureExpense, reorderFutureExpenses, updateCase } from "api"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { FutureExpense } from "./FutureExpense"
import EmptyState from "../../common/tables/EmptyState"
import { formSectionsToRoutes } from "../constants"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { reorderImmutable } from "utils"
import { useHandleMessages } from "common/messages/useHandleMessages"
import useFirm from "hooks/useFirm"
import { CustomDamageSectionType } from "settings/Firm/enums"
import { deserializeFromMarkdown } from "common/form-components/rich-text/serializer/markdown/deserializer"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import { replaceMatches } from "common/form-components/rich-text/utils"
import { useDebouncedCallback } from "use-debounce/lib"
import useCase from "hooks/useCase"
import { useFormContext } from "demand/context"
import { GridLayoutHeader } from "./FutureExpenseStyled"
import TextButton from "common/buttons/TextButton"

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: "1rem",
    marginLeft: theme.spacing(0.5),
    justifyItems: "center",
    color: theme.palette.grey[500],
  },
}))

const PreviewContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("xs")]: {
    display: "block",
  },
}))

const PreviewBox = styled(Box)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}))

const PLACEHOLDER_STATE = {
  cost: 0.0,
  procedure_name: "New Expense",
  number_of_years: 0,
  annual_frequency: 0,
}

const HeaderWithTooltip = ({ title, tooltipText }) => {
  const classes = useStyles()
  return (
    <Box display="flex" flexDirection="row" justifyContent="right">
      <Box>{title}</Box>
      <Box display="flex" alignItems="center">
        <Tooltip placement="bottom" arrow title={tooltipText} data-test="tooltip">
          <HelpOutline className={classes.tooltip} />
        </Tooltip>
      </Box>
    </Box>
  )
}

export function FutureExpenses({ lastVisited }) {
  const queryClient = useQueryClient()
  const { caseId, handleUpdateStepStatus, request } = useFormContext()
  const [toEdit, setToEdit] = useState(false)
  const { showMessage } = useHandleMessages()
  const { firm, isLoading: isFirmLoading } = useFirm(request?.firm_id)
  const { caseObj, isLoading: isCaseLoading, updateCache: updateCaseCache } = useCase(caseId)

  const createMutation = useMutation(createFutureExpense, {
    onSuccess: data => {
      if (data?.pk) {
        setToEdit(data.pk)
      }
      queryClient.invalidateQueries([queryKeys.futureExpense, caseId])
    },
  })
  const reorderMutation = useMutation(reorderFutureExpenses, {
    onError: () =>
      showMessage({
        type: "error",
        message:
          "Error re-ordering future expenses. Please try again shortly and contact support if your problem persists.",
      }),
  })
  const updateCaseMutation = useMutation(updateCase, {
    onError: () =>
      showMessage({
        type: "error",
        message:
          "Error updating future expenses description. Please try again shortly and contact support if your problem persists.",
      }),
  })
  const debounceUpdateCase = useDebouncedCallback(text => {
    updateCaseMutation.mutate({
      id: caseId,
      updates: { future_expenses_text: text },
    })
  }, 500)

  const addExpense = () => {
    const data = PLACEHOLDER_STATE
    createMutation.mutateAsync({ caseId, data })
  }

  const saveCallback = () => {
    setToEdit(-1)
  }

  const { data: expenses, isLoading } = useQuery([queryKeys.futureExpense, caseId], getFutureExpenses, {
    onSuccess: async data => {
      if (data?.length) {
        await handleUpdateStepStatus({ status: "completed" })
      } else {
        await handleUpdateStepStatus({ status: "started" })
      }
    },
  })

  const handleDragEnd = useCallback(
    ({ destination, source }) => {
      // dropped outside the list or moved to same position
      if (!destination || destination.index === source.index) return

      const newExpenses = reorderImmutable(expenses, source.index, destination.index)
      reorderMutation.mutate({ caseId, newIdsInOrder: newExpenses.map(expense => expense.pk) })
      queryClient.setQueryData([queryKeys.futureExpense, caseId], newExpenses)
    },
    [caseId, reorderMutation, expenses, queryClient]
  )

  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.future_expenses
  })

  const futureMedicalExpensesSection = firm?.sections?.find(
    section => section.section_type === CustomDamageSectionType.FUTURE_EXPENSES
  )
  const templateTextData = useMemo(() => {
    if (!futureMedicalExpensesSection?.text && !futureMedicalExpensesSection?.text_json) {
      return null
    }

    return (
      futureMedicalExpensesSection.text_json || deserializeFromMarkdown(futureMedicalExpensesSection.text)
    )
  }, [futureMedicalExpensesSection])

  if (isLoading || isFirmLoading || isCaseLoading) return null
  return (
    <>
      <Box display="grid">
        <PreviewContainer>
          <PreviewBox mr={5}>
            <Typography component="label" variant="h6" htmlFor="future-expenses-text">
              Describe future expenses
            </Typography>
            <TextField
              id="future-expenses-text"
              fullWidth
              defaultValue={caseObj?.future_expenses_text ?? ""}
              onChange={event => {
                const text = event.target.value
                updateCaseCache({ future_expenses_text: text })
                debounceUpdateCase(text)
              }}
              multiline
              variant="outlined"
              minRows={6}
              helperText="Will replace [[future_procedures]] with the text you type in."
            />
          </PreviewBox>
          <PreviewBox>
            <Typography variant="h6">Preview</Typography>
            <Box>
              {templateTextData ? (
                <RichTextEditor
                  value={replaceMatches(templateTextData, {
                    "[[future_procedures]]": caseObj?.future_expenses_text || "[[future_procedures]]",
                  })}
                  readonly={true}
                />
              ) : (
                "No preview text available. This can be set in your selected firms settings by adding a Future Medical Expenses section."
              )}
            </Box>
          </PreviewBox>
        </PreviewContainer>
        <Box mt={4} mb={2}>
          <Divider />
        </Box>
        {expenses?.length ? (
          <Box data-test="future-medical-expenses">
            <GridLayoutHeader data-test="future-medical-expenses-header">
              <Box gridColumn={2}>Procedure</Box>
              <Box align="right">Cost</Box>
              <Box>
                <HeaderWithTooltip title="# Per Year" tooltipText="Number of treatments per year." />
              </Box>
              <Box>
                <HeaderWithTooltip title="Years" tooltipText="Number of years the treatment will last." />
              </Box>
              <Box align="right">Total</Box>
            </GridLayoutHeader>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="futureExpensesList">
                {droppableProvided => (
                  <Box {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                    {expenses.map((expense, index) => {
                      const isEditing = expense.pk === toEdit
                      return (
                        <FutureExpense
                          key={expense.pk}
                          futureExpense={expense}
                          initialEdit={isEditing}
                          saveCallback={isEditing ? saveCallback : null}
                          index={index}
                        />
                      )
                    })}
                    {droppableProvided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        ) : (
          <EmptyState message="No Expenses" data-test="empty-state" />
        )}
      </Box>
      <Box m={2}>
        <TextButton onClick={addExpense} data-test="add-expense">
          + Add Expense
        </TextButton>
      </Box>
    </>
  )
}
