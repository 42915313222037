import React from "react"
import {
  Box,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  IconButton,
} from "@material-ui/core"
import { styled } from "@material-ui/core/styles"
import { Close } from "@material-ui/icons"

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  paddingRight: theme.spacing(1),
}))

const LeftDialogActions = styled(DialogActions)(() => ({
  justifyContent: "flex-start",
}))

const SplitDialogActions = styled(DialogActions)(() => ({
  justifyContent: "space-between",
}))

const CenterDialogActions = styled(DialogActions)(() => ({
  justifyContent: "space-between",
}))

const FlexButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}))

type ButtonPlacement = "left" | "right" | "first-left" | "center"

interface DialogForm extends MuiDialogProps {
  header: React.ReactNode
  buttonPlacement?: ButtonPlacement
  buttons: React.ReactNode[]
  onClose: () => unknown
}

interface ActionsProps {
  buttonPlacement: ButtonPlacement
  buttons: React.ReactNode[]
}

const Actions: React.FC<ActionsProps> = ({ buttonPlacement, buttons }) => {
  switch (buttonPlacement) {
    case "left":
      return (
        <LeftDialogActions>
          <FlexButtonBox>{buttons}</FlexButtonBox>
        </LeftDialogActions>
      )
    case "first-left":
      return (
        <SplitDialogActions>
          <FlexButtonBox>{buttons[0]}</FlexButtonBox>
          <FlexButtonBox>{buttons.slice(1)}</FlexButtonBox>
        </SplitDialogActions>
      )
    case "center":
      return <CenterDialogActions>{buttons}</CenterDialogActions>
    case "right":
    default:
      return (
        <DialogActions>
          <FlexButtonBox>{buttons}</FlexButtonBox>
        </DialogActions>
      )
  }
}

const DialogForm: React.FC<DialogForm> = ({
  header,
  children,
  buttonPlacement = "right",
  buttons,
  onClose,
  ...rest
}) => {
  return (
    <MuiDialog maxWidth="sm" fullWidth onClose={onClose} {...rest}>
      <CustomDialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="baseline">
          <Box>{header}</Box>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </CustomDialogTitle>
      <DialogContent>
        <Box pb={2}>{children}</Box>
      </DialogContent>
      <Actions buttonPlacement={buttonPlacement} buttons={buttons} />
    </MuiDialog>
  )
}

export default DialogForm
