import { useIsMutating } from "react-query"
import { useFormContext } from "./context"
import React, { useEffect } from "react"
import { VerticalCenterBox } from "../common/FlexBox"
import { CircularProgress, Typography } from "@material-ui/core"
import { Done } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  saving: {
    color: theme.palette.info.main,
  },
  saved: {
    color: theme.palette.success.main,
  },
}))
export function SavingIndicator({ text }) {
  const isMutating = useIsMutating() > 0
  const classes = useStyles()
  const { savedSuccessfully, setSavedSuccessfully } = useFormContext()

  useEffect(() => {
    if (savedSuccessfully) {
      setTimeout(() => {
        setSavedSuccessfully(false)
      }, 3000)
    }
  }, [savedSuccessfully, setSavedSuccessfully])

  return (
    <>
      {text && (
        <Typography varaint="body" paragraph>
          {text}
        </Typography>
      )}
      {isMutating && (
        <VerticalCenterBox className={classes.saving}>
          <CircularProgress color={"inherit"} disableShrink size={"1.5em"} /> &nbsp;
          <div>Saving</div>
        </VerticalCenterBox>
      )}
      {!isMutating && savedSuccessfully && (
        <VerticalCenterBox className={classes.saved}>
          <Done /> &nbsp;
          <div>Saved</div>
        </VerticalCenterBox>
      )}
    </>
  )
}
