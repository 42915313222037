import React, { useEffect, useState } from "react"
import { Box, Collapse, Divider, IconButton } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from "@material-ui/icons"

import { useQuery } from "react-query"

import CommunicationItem from "./CommunicationItem"

import { MinorTitle } from "../styled"
import { MissingExhibitEvent } from "../interfaces"
import { queryKeys } from "../../react-query/constants"
import { getMissingExhibitEvents } from "../../api"
import { formatDate } from "../../utils"
import differenceInDays from "date-fns/differenceInDays"

interface Props {
  title: string
  caseId: number
  seperateItems?: boolean
  setCommentsEnabled?: Nullable<(arg0: boolean) => void>
  unresolvedMissingExhibitCount?: number
}

const CommunicationContainer = styled(Box)(({ theme }) => ({
  border: "solid 1px #E0E0E0",
  padding: theme.spacing(3, 3, 1, 3),
  marginTop: theme.spacing(3),
}))

const Header = styled(Box)({
  display: "flex",
})

const CommunicationEvents = ({
  title,
  caseId,
  seperateItems = false,
  setCommentsEnabled = null,
  unresolvedMissingExhibitCount = 0,
}: Props): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true)

  const { data: communicationEvents } = useQuery<MissingExhibitEvent[]>(
    [queryKeys.missingExhibitEvents, caseId],
    async () => {
      return await getMissingExhibitEvents({ caseId: caseId })
    }
  )

  useEffect((): void => {
    if (!setCommentsEnabled) return
    if (unresolvedMissingExhibitCount === 0) {
      return setCommentsEnabled(false)
    }

    if (!communicationEvents?.length) return setCommentsEnabled(true)
    const firstEvent = communicationEvents[0]

    if (
      (firstEvent.internal === true && differenceInDays(new Date(firstEvent.created_at), new Date()) < 1) ||
      unresolvedMissingExhibitCount === 0
    ) {
      return setCommentsEnabled(false)
    }

    setCommentsEnabled(true)
  }, [communicationEvents, setCommentsEnabled, unresolvedMissingExhibitCount])

  if (!communicationEvents?.length) {
    return <></>
  }

  return (
    <CommunicationContainer data-test="communication-events">
      <Header>
        <MinorTitle>{title}</MinorTitle>
        {/* TODO: Can make this reusable? */}
        <Box m="auto 0 auto auto">
          <IconButton
            onClick={() => {
              setIsExpanded(!isExpanded)
            }}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </Header>

      <Collapse in={isExpanded}>
        {communicationEvents.map((event: MissingExhibitEvent, index: number) => {
          const creatorName = `${event.creator.first_name} ${event.creator.last_name}`
          const dateCreated = formatDate(event.created_at, "MMMM dd, HH:mm aaa")

          return (
            <React.Fragment key={index}>
              <CommunicationItem
                key={`communication-${index}`}
                title={`${dateCreated} by ${creatorName}`}
                description={event.comment}
                files={event.questionnaire_files}
              />
              {seperateItems && <Divider />}
            </React.Fragment>
          )
        })}
      </Collapse>
    </CommunicationContainer>
  )
}

export { CommunicationEvents as default }
