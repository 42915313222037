import { POLICY_LIMIT } from "../requests/constants"
import {
  PLAINTIFF_NAME,
  ADJUSTER,
  POLICY_NUMBER,
  POLICY_TYPE,
  POLICY_COVERAGE_TYPE,
  CLAIM_NUMBER,
  BASIC_INFORMATION,
  CARRIER_INFORMATION,
  CASE_FACTS,
  PLAINTIFF_INFORMATION,
  FILES,
  INJURY_IMPACTS,
  ALL,
} from "../requests/constants"

export const PROVIDERS_SECTION = "providers"
export const FUTURE_EXPENSES_SECTION = "future_expenses"
export const EXHIBIT_SECTION = "exhibit"
export const DEMAND_FLOW_WRAPPER = "demand-flow-wrapper"
export const LEFT_NAV_PANEL_ORDER = 1
export const MISSING_DOCS_SECTION = "missing_docs"

export const formSectionsToRoutes = {
  plaintiff: "plaintiff",
  carrier: "carrier",
  facts: "facts",
  [PROVIDERS_SECTION]: "providers",
  [FUTURE_EXPENSES_SECTION]: "future-expenses",
  non_economic: "non-economic",
  income_loss: "income-loss",
  household_loss: "other",
  law_firm: "firm",
  damages_sections: "damages",
  [MISSING_DOCS_SECTION]: "missing-docs",
  [EXHIBIT_SECTION]: "exhibit",
}

export const FILE_OPTIONS = {
  medical: "Medical / Hospital Record",
  police_report: "Police Report",
  insurance: "Insurance Document",
  lien: "Lien Document",
  pleading: "Pleading Document",
  other: "Other",
}

export const MEDICAL_FILE_OPTIONS = {
  medical: "Medical / Hospital Record",
  medical_bill: "Medical Bill",
  police_report: "Police Report",
  insurance: "Insurance Document",
  lien: "Lien Document",
  pleading: "Pleading Document",
  other: "Other",
}

export const INCOME_FILE_OPTIONS = {
  tax: "Tax Document",
  employer_letter: "Employer Letter",
  other: "Other",
}

export const HOUSEHOLD_SERVICE_FILE_OPTIONS = {
  letter: "Letter",
  affidavit: "Affidavit",
  other: "Other",
}

export const STEP_STATUSES = {
  notStarted: "not_started",
  started: "started",
  completed: "completed",
  skipped: "skipped",
  error: "error",
}

export const MAX_FILE_NAME_LENGTH = 500
export const FLOATING_PANEL_WIDTH = 375

export const SECTION_TO_EXPANDED_REQUESTS = {
  plaintiff: {
    expand: [BASIC_INFORMATION],
    highlight: [PLAINTIFF_NAME],
  },
  [formSectionsToRoutes.carrier]: {
    expand: [BASIC_INFORMATION, CARRIER_INFORMATION],
    highlight: [ADJUSTER, POLICY_TYPE, POLICY_COVERAGE_TYPE, POLICY_NUMBER, POLICY_LIMIT, CLAIM_NUMBER],
  },
  [formSectionsToRoutes.facts]: {
    expand: [CASE_FACTS, FILES],
    highlight: [],
  },
  [PROVIDERS_SECTION]: {
    expand: [CASE_FACTS, FILES],
    highlight: [],
  },
  [FUTURE_EXPENSES_SECTION]: {
    expand: [INJURY_IMPACTS],
    highlight: [],
  },
  non_economic: {
    expand: [CASE_FACTS, INJURY_IMPACTS],
    highlight: [],
  },
  income_loss: {
    expand: [CARRIER_INFORMATION],
    highlight: [],
  },
  household_loss: {
    expand: [PLAINTIFF_INFORMATION, INJURY_IMPACTS],
    highlight: [],
  },
  law_firm: {
    expand: [],
    highlight: [],
  },
  damages_sections: {
    expand: [ALL],
    highlight: [],
  },
}

export const WORKING_HOURS_TIMEFRAME = {
  week: "Week",
  month: "Month",
  year: "Year",
}

export const WORKING_HOURS_TIMEFRAME_OPTIONS = Object.keys(WORKING_HOURS_TIMEFRAME).map(key => {
  return {
    key: key,
    display: WORKING_HOURS_TIMEFRAME[key],
  }
})

export const IMPAIRMENT_TIME_UNIT_MAP = new Map([
  ["days", { key: "days", display: "Days" }],
  ["years", { key: "years", display: "Years" }],
])
