export const ATTRIBUTES_HELPER_TEXT_MAP: Record<string, string> = {
  Jurisdiction: "State of Incident",
}

export const JURISDICTION_ATTRIBUTE = "Jurisdiction"

export const CASE_TYPE_ATTRIBUTE = "Case Type"

export const POLICY_TYPE_ATTRIBUTE = "Policy Type"

export const COVERAGE_ATTRIBUTE = "Coverage"
