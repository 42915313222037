import { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from "react"
import { v4 } from "uuid"
import { FileUploaderContext } from "./context"
import { createUploadFlow } from "./upload-flow/file"
import { fileUploaderReducer } from "./uploader-state/reducer"
import {
  getChunkedFiles,
  getDuplicatesFromState,
  getUploadFailureReason,
  getUploadIdFromState,
  hasDuplicates,
} from "./utils"

interface FileUploadErrorResult {
  name?: string
  reason: string
}

type UploadFilesReturn = Promise<
  (string | Nullable<FileUploadErrorResult>)[] | { failedFiles: FileUploadErrorResult[] }
>
type UploadFiles = (files: File[]) => UploadFilesReturn

interface UseFileUploaderReturn {
  uploadFiles: UploadFiles
  cancelUpload: () => void
  assignUploaded: (uploadId: string, externalId: PrimaryKey) => void
  isUploading: boolean
}

export function useFileUploader(): UseFileUploaderReturn {
  const [fileStates, dispatch] = useReducer(fileUploaderReducer, {})
  const uploadFlow = useMemo(() => createUploadFlow(dispatch), [])
  const actualState = useRef(fileStates)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const uploadingStack = useRef<string[]>([])
  const { updateState } = useContext(FileUploaderContext)

  const startUpload = useCallback((): (() => void) => {
    const id = v4()
    uploadingStack.current.push(id)
    setIsUploading(true)

    return () => {
      uploadingStack.current = uploadingStack.current.filter(uploadingId => uploadingId !== id)
      setIsUploading(uploadingStack.current.length > 0)
    }
  }, [uploadingStack, setIsUploading])

  useEffect(() => {
    actualState.current = fileStates
    updateState(fileStates)
  }, [fileStates, actualState, updateState])

  useEffect(() => {
    return () => {
      uploadFlow.cancel()
      updateState(fileUploaderReducer(actualState.current, { type: "UNASSIGN_FILES", payload: {} }))
    }
  }, [actualState, updateState, uploadFlow])

  const uploadFiles = useCallback<UploadFiles>(
    async files => {
      if (hasDuplicates(actualState.current, files)) {
        return {
          failedFiles: getDuplicatesFromState(actualState.current, files).map(file => ({
            name: file.name,
            reason: "File has already been uploaded!",
          })),
        }
      }

      const finishUpload = startUpload()
      // Get files with chunks and upload
      const chunkedFiles = getChunkedFiles(files)
      const results = await Promise.allSettled(chunkedFiles.map(fileData => uploadFlow.run(fileData, {})))

      // Process upload results
      const ids = results.map(result => (result.status === "fulfilled" ? result.value : null))
      const uploadResults = ids.map(
        id => getUploadIdFromState(actualState.current, id) || getUploadFailureReason(actualState.current, id)
      )

      finishUpload()

      return uploadResults
    },
    [uploadFlow, actualState, startUpload]
  )

  const cancelUpload = useCallback(() => {
    uploadFlow.cancel()
  }, [uploadFlow])

  const assignUploaded = useCallback(
    (uploadId: string, externalId: PrimaryKey) => {
      dispatch({ type: "ASSIGN_UPLOAD", payload: { uploadId, externalId } })
    },
    [dispatch]
  )

  return {
    uploadFiles,
    cancelUpload,
    assignUploaded,
    isUploading,
  }
}
