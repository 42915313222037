import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import getTextColorFromTheme, { ButtonColor } from "./getTextColorFromTheme"

type CssProps = { disabled?: boolean; textColor?: ButtonColor }

const useStyles = makeStyles(theme => ({
  linkButton: {
    cursor: ({ disabled }: CssProps) => (disabled ? "not-allowed" : "pointer"),
    fontSize: "1rem",
    color: ({ textColor, disabled }: CssProps) => {
      if (disabled) {
        return theme.palette.grey[600]
      }

      return getTextColorFromTheme({ theme, textColor })
    },
    border: "none",
    background: "none",
    textDecoration: "underline",
  },
  linkButtonSmall: { padding: 0 },
}))

interface LinkButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  small?: boolean
  className?: string | undefined
  textColor?: ButtonColor
}

const LinkButton: React.FC<LinkButtonProps> = ({
  children,
  className,
  disabled,
  small = false,
  textColor = "blue",
  ...buttonProps
}) => {
  const classes = useStyles({ disabled, textColor })

  return (
    <button
      type="button"
      className={clsx(classes.linkButton, small && classes.linkButtonSmall, className && className)}
      disabled={disabled}
      {...buttonProps}
    >
      {children}
    </button>
  )
}

export default LinkButton
