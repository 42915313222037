import React from "react"
import { MenuItem } from "@material-ui/core"

import { FILTER_OPTION_ALL } from "../../app/constants"
import getStatusToProperties, { getDisplayStatusToPropertiesByRole } from "./requestStatusProperties"
import StatusLabel from "../../common/status/StatusLabel"

import ListFilter from "../../common/tables/filters/ListFilter"
import useUser from "../../hooks/useUser"

const StatusFilter = ({ onChange, value, className = "" }) => {
  const {
    user: { role },
  } = useUser()

  let statusToProperties = getDisplayStatusToPropertiesByRole(role)

  return (
    <ListFilter onChange={onChange} value={value} className={className} label="Status" id="status-filter">
      <MenuItem key={FILTER_OPTION_ALL.key} value={FILTER_OPTION_ALL.key}>
        {FILTER_OPTION_ALL.display}
      </MenuItem>
      {Object.keys(statusToProperties).map(key => {
        const statusStyle = getStatusToProperties(key, role)
        return (
          <MenuItem key={key} value={key}>
            <StatusLabel
              status={key}
              color={statusStyle.color}
              text={statusStyle.text}
              filled={statusStyle.filled}
            />
          </MenuItem>
        )
      })}
    </ListFilter>
  )
}

export { StatusFilter as default }
