import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { DragIndicator } from "@material-ui/icons"
import { useDraggable, useDroppable } from "@dnd-kit/core"
import clsx from "clsx"
import { DROP_TARGET_TYPE, ITEM_REF } from "./constants"
import { VerticalCenterBox } from "../FlexBox"

const useStyles = makeStyles(theme => ({
  dropIndicator: {
    height: "0px",
    boxShadow: "0 0 0 1px rgba(0,0,0,0.03)",
    transform: `translateY(${theme.spacing(-1)}px)`,
    position: "relative",
  },
  activeDropIndicator: {
    zIndex: 100,
    boxShadow: `0 0 0 2px ${theme.palette.dragging.main}`,
  },
  draggableListItem: {
    opacity: 1,
    transition: "0.2s opacity ease-out",
  },
  dragging: {
    opacity: 0.2,
  },
  nestedListItem: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(2, 0),
    border: "1px solid lightgray",
    borderRadius: "2px",
    background: theme.palette.background.default,
  },
  nestedListItemContent: {
    "& svg": {
      color: theme.palette.grey.A200,
      transition: `50ms color ease`,
    },
    "&:hover svg": {
      color: theme.palette.grey["700"],
    },
  },
  dragHandle: {
    cursor: "grab",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    transition: "50ms background ease",
    "& > svg": {
      height: "1.5em",
    },
    "&:hover": {
      background: theme.palette.grey["200"],
    },
  },
  dragHandleDisabled: {
    cursor: "not-allowed",
    opacity: 0.5,
    "&, &:hover": {
      background: "none",
    },
  },
  innerContent: {
    flexGrow: 1,
  },
}))

export function SortableNestedListItem({
  id,
  children,
  canDrop,
  canDrag,
  className,
  renderContent,
  backgroundColor,
  ...props
}) {
  const classes = useStyles()
  const draggable = useDraggable({ id })

  const bottomDroppable = useDroppable({
    data: { type: DROP_TARGET_TYPE.AFTER, id },
    id: `droppable-${id}-bottom`,
    disabled: draggable.isDragging || !canDrop,
  })

  const topDroppable = useDroppable({
    data: { type: DROP_TARGET_TYPE.BEFORE, id },
    id: `droppable-${id}-top`,
    disabled: draggable.isDragging || !canDrop,
  })

  const selfDroppable = useDroppable({
    data: { type: DROP_TARGET_TYPE.SELF, id },
    id: `droppable-${id}-self`,
    disabled: !draggable.isDragging,
  })

  function setNodeRef(element) {
    selfDroppable.setNodeRef(element)
    draggable.setNodeRef(element)
  }

  function getDropIndicatorClassName({ isOver }) {
    return clsx(classes.dropIndicator, isOver && classes.activeDropIndicator)
  }

  function renderDropIndicator({ setNodeRef, isOver }) {
    if (draggable.isDragging || !canDrop) {
      return null
    }

    return <div ref={setNodeRef} className={getDropIndicatorClassName({ isOver })} />
  }

  return (
    <div data-test="exhibits-list-item">
      {renderDropIndicator(topDroppable)}
      <div
        ref={setNodeRef}
        className={clsx(classes.draggableListItem, draggable.isDragging && classes.dragging, className)}
      >
        <div className={classes.nestedListItem} style={{ backgroundColor: backgroundColor }}>
          <VerticalCenterBox sx={{ width: "100%" }} className={classes.nestedListItemContent}>
            {canDrag ? (
              <div
                {...draggable.listeners}
                {...draggable.attributes}
                className={classes.dragHandle}
                data-test="drag-indicator"
              >
                <DragIndicator />
              </div>
            ) : (
              <div className={clsx(classes.dragHandle, classes.dragHandleDisabled)}>
                <DragIndicator />
              </div>
            )}
            <div className={classes.innerContent}>{renderContent({ item: props[ITEM_REF] })}</div>
          </VerticalCenterBox>
          <div>{children}</div>
        </div>
      </div>
      {renderDropIndicator(bottomDroppable)}
    </div>
  )
}
