import React, { memo, useMemo } from "react"
import { Route, RouteProps, Routes, RoutesProps } from "react-router-dom"
import { withTransaction } from "@elastic/apm-rum-react"

function isRouteElement(child: React.ReactNode): child is React.ReactElement<RouteProps> {
  if (!React.isValidElement(child)) return false

  return child.type === Route
}

function wrapRoutesWithTransactionBoundary(children?: React.ReactNode, parentPath = ""): React.ReactNode {
  if (!children) return children

  return React.Children.map(children, child => {
    if (!isRouteElement(child)) return child

    const routeElement = child.props.element
    const path = child.props.path ? `${parentPath}/${child.props.path}` : parentPath

    const ApmRoute = withTransaction(path, "route-change")(() => <>{routeElement}</>)

    return React.cloneElement(child, {
      element: <ApmRoute />,
      children: wrapRoutesWithTransactionBoundary(child.props.children, path),
    })
  })
}

export const ApmRouter = memo(function ApmRoutes({
  children,
  location,
}: RoutesProps): React.ReactElement<typeof Routes> {
  const childrenWithTransition = useMemo(() => wrapRoutesWithTransactionBoundary(children), [children])

  return <Routes location={location}>{childrenWithTransition}</Routes>
})
