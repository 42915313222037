import React from "react"
import { RevisionRequestEventList } from "common/models/revision-request-event"
import { RevisionRequestEventDetails } from "./RevisionRequestEventDetails"
import { DetailsContainer, RevisionListContainer, RevisionListitem, RevisionSubtitle } from "./styled"

interface RevisionListProps {
  events: RevisionRequestEventList
}

export function RevisionList({ events }: RevisionListProps): JSX.Element {
  if (!events.completedEvents.length) {
    return <></>
  }

  return (
    <DetailsContainer>
      <RevisionSubtitle>Previous Revisions</RevisionSubtitle>
      <RevisionListContainer>
        {events.completedEvents.map(event => (
          <RevisionListitem key={event.pk}>
            <RevisionRequestEventDetails event={event} />
          </RevisionListitem>
        ))}
      </RevisionListContainer>
    </DetailsContainer>
  )
}
