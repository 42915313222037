import { Box, TextField } from "@material-ui/core"
import React from "react"
import { BoldText } from "./styled"

interface EditableRangeProps {
  start: number
  end: number
  onStartChange: (event: React.ChangeEvent<HTMLInputElement>) => unknown
  onEndChange: (event: React.ChangeEvent<HTMLInputElement>) => unknown
  disabled?: boolean
  startError?: boolean
  endError?: boolean
}

const EditableRange: React.FC<EditableRangeProps> = ({
  start,
  end,
  onStartChange,
  onEndChange,
  disabled,
  startError = false,
  endError = false,
}) => {
  return (
    <Box display="flex" alignItems="center">
      <BoldText color={startError ? "error" : "initial"}>From page</BoldText>
      <Box maxWidth="80px" ml={1} mr={1}>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          value={Number.isNaN(start) ? "" : start}
          onChange={onStartChange}
          disabled={disabled}
          error={startError}
          autoFocus
        />
      </Box>
      <BoldText color={endError ? "error" : "initial"}>to</BoldText>
      <Box maxWidth="80px" ml={1} mr={1}>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          value={Number.isNaN(end) ? "" : end}
          onChange={onEndChange}
          disabled={disabled}
          error={endError}
        />
      </Box>
    </Box>
  )
}

export default EditableRange
