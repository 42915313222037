import { isEqual } from "lodash"
import { Node } from "slate"
import { createListItem, createSoftLineBreak, createText } from "../create"
import { CustomElement, EditorRoot } from "../CustomEditor"
import { INLINE_ELEMENTS, LEAF_BLOCK_ELEMENTS, LIST_BLOCK_ELEMENTS } from "../elements"
import { isTextNode } from "../queries"

function* nodeIterator(nodes: Node[]): Generator<CustomElement, void> {
  for (const node of nodes) {
    if (!isTextNode(node) && "type" in node) {
      yield* nodeIterator(node.children)
      yield node
    }
  }
}

const listTypes: string[] = [LIST_BLOCK_ELEMENTS.ORDERED_LIST, LIST_BLOCK_ELEMENTS.UNORDERED_LIST]

export function initialNormalize(nodes: EditorRoot): EditorRoot {
  for (const node of nodeIterator(nodes)) {
    // Initial normalize for lists
    if (listTypes.includes(node.type)) {
      // Normalize empty list - add default list item
      if (!node.children.length) {
        node.children = [createListItem()]
      }

      continue
    }

    // Normalize nodes that can contain text nodes
    if (node.type === LEAF_BLOCK_ELEMENTS.PARAGRAPH) {
      const firstChild = node.children[0]

      // First child in paragraph is not text (but should be, at least empty text node)
      if (!firstChild || !isTextNode(firstChild)) {
        node.children = [createText(), ...node.children]
      }

      const lastChildIndex = node.children.length - 1
      const lastChild = node.children[lastChildIndex]

      // Last child in paragraph is not text (but should be, at least empty text node)
      if (!lastChild || !isTextNode(lastChild)) {
        node.children = [...node.children, createText()]
      }

      continue
    }

    // Normalize line break
    if (node.type === INLINE_ELEMENTS.SOFT_LINE_BREAK) {
      const defaultChildren = createSoftLineBreak().children

      if (!isEqual(defaultChildren, node.children)) {
        node.children = defaultChildren
      }

      continue
    }
  }

  return nodes
}
