import { handleEmptyResponse } from "api/utils"
import { withResponseSerializer } from "api/withSerializers"
import { isUndefined } from "lodash"
import { apiService } from "../ApiService"
import { ApiServiceType } from "../types"
import { AttributesServiceDeserializer } from "./serializers"

enum ATTRIBUTES_API_PATHS {
  BASE = "library/attribute",
}

class AttributesService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(path?: ATTRIBUTES_API_PATHS): string {
    const pathParts = ["", ATTRIBUTES_API_PATHS.BASE, path]
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getAvailableAttributes = withResponseSerializer(AttributesServiceDeserializer.fromListJSON, () => {
    return handleEmptyResponse(this.apiService.get(null, this.getPath()))
  })
}

export const attributesService = new AttributesService(apiService)
