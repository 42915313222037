import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { size } from "lodash"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useOutletContext } from "react-router-dom"
import { DateField } from "common/form-components"

import {
  bulkDeleteExhibits,
  deleteExhibit,
  downloadExhibit,
  getSectionMissingExhibits,
  updateExhibit,
  uploadExhibit,
} from "../api"
import { DnDExhibitList, ExhibitList } from "../common"
import { queryKeys } from "../react-query/constants"
import usePrompt from "../hooks/usePrompt"
import { getChangedFields, getCommonMutateOptions } from "../utils"
import useAutosave from "../hooks/useAutosave"
import { useHandleMessages } from "../common/messages/useHandleMessages"
import { SECTIONS } from "../missing-docs/constants"

import { useDialog } from "../hooks/useDialog"

import { useFormContext } from "./context"
import { FILE_OPTIONS, formSectionsToRoutes, STEP_STATUSES } from "./constants"
import PendingFilesForm from "./DragAndDropFileUploader/PendingFilesForm"
import PendingImageForm from "./DragAndDropFileUploader/PendingImagesForm"
import MissingDocumentSection from "./MissingDocumentSection"
import useUser from "../hooks/useUser"
import { isNotNotSetupRole } from "../common/permission"
import FileDropzone from "../common/form-components/files/FileDropzone"
import { ACCEPT_IMAGE_TYPE } from "../common/form-components/files/constants"
import TextButton from "../common/buttons/TextButton"
import ConfirmDialog from "../common/ConfirmDialog"
import { CaseEditorField } from "./components/CaseEditor"
import { caseService } from "api/services/case"
import { CASE_SECTIONS } from "common/types/sections"
import { CaseAlert } from "./components/CaseAlert"
import { FEATURES, useFeatures } from "hooks/useFeatures"

const useStyles = makeStyles(theme => ({
  formFields: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr));",
    gap: theme.spacing(4),
  },
  fullWidth: {
    gridColumn: "1 / 3",
  },
  uploaderContainer: {
    display: "flex",
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
}))

const CASE_TYPES = {
  motor_vehicle: "Motor Vehicle",
  slip_and_fall: "Slip & Fall",
  med_malpractice: "Medical Malpractice",
  dog_bites: "Dog Bites",
  tort: "Intentional Tort",
  other: "Other",
}

const EMPTY_FORM_VALUES = {
  facts: "",
  facts_json: null,
  liability_details: "",
  liability_details_json: null,
  type: "",
  date_of_incident: "",
  files: [],
  images: [],
  exhibits: [],
  sections: {},
}

const PendingImages = ({ onSubmit, pendingImages, setPendingImages }) => {
  if (!pendingImages.length) {
    return <></>
  }

  return (
    <Box>
      <PendingImageForm pendingImages={pendingImages} setPendingImages={setPendingImages} />
      <Button variant="contained" color="secondary" onClick={onSubmit} data-test="done-adding-images">
        Done Adding Images
      </Button>
    </Box>
  )
}

export function CaseFacts({ lastVisited }) {
  const classes = useStyles()
  const [saveRef] = useOutletContext()
  const { showMessage } = useHandleMessages()
  const [errors, setErrors] = useState(null)
  const [files, setFiles] = useState(null)
  const [factsImages, setFactsImages] = useState(null)
  const [liabilitiesImages, setLiabilitiesImages] = useState(null)
  const [filesToUpload, setFilesToUpload] = useState([])
  const [factsImagesToUpload, setFactsImagesToUpload] = useState([])
  const [liabilitiesImagesToUpload, setLiabilitiesImagesToUpload] = useState([])
  const [pendingImagesToDelete, setPendingImagesToDelete] = useState([])
  const { caseId, queryClient, setSavedSuccessfully, showErrorMessage, handleUpdateStepStatus, request } =
    useFormContext()
  const { control, handleSubmit, reset, formState, getValues, watch, setValue } = useForm({
    defaultValues: EMPTY_FORM_VALUES,
  })

  const dateOfIncident = watch("date_of_incident")

  const handleChangeDateOfIncident = useCallback(
    newDateOfIncident => setValue("date_of_incident", newDateOfIncident, { shouldDirty: true }),
    [setValue]
  )

  const {
    isOpen: isDeleteAllOpen,
    openDialog: openDeleteAllDialog,
    closeDialog: closeDeleteAllDialog,
  } = useDialog()

  useQuery([queryKeys.facts, caseId], async () => {
    const data = await caseService.getCaseFacts({ caseId })
    if (data) {
      reset(data)
      setFiles(data?.exhibits.filter(i => !!i.file))
      setFactsImages(data?.exhibits.filter(i => !!i.image && i.section == "facts"))
      setLiabilitiesImages(data?.exhibits.filter(i => !!i.image && i.section == "liabilities"))
    }
    return data
  })
  const { user } = useUser()

  const { data: missingExhibits } = useQuery(
    [queryKeys.missingExhibits, caseId],
    async () => {
      return await getSectionMissingExhibits({ caseId: caseId, section: SECTIONS.CASE_FACTS })
    },
    {
      enabled: isNotNotSetupRole(user.role),
    }
  )

  const factsMutation = useMutation(
    caseService.saveCaseFacts,
    getCommonMutateOptions({
      setErrors,
      reset,
      setSavedSuccessfully,
      showErrorMessage,
      onSuccessExtra: () => handleUpdateStepStatus({ status: STEP_STATUSES.completed }),
    })
  )

  // succeed/fail silently
  const autosaveMutation = useMutation(caseService.saveCaseFacts)

  const deleteFileMutation = useMutation(deleteExhibit, {
    onSuccess: () => {
      showMessage({
        type: "success",
        message: "File Deleted",
      })
      queryClient.invalidateQueries(queryKeys.facts)
    },
    onError: () => {
      showMessage({
        type: "error",
        message:
          "There was an error deleting this file. Please try again, and if your problem persists contact a dev.",
      })
    },
  })

  const uploadFileMutation = useMutation(uploadExhibit, {
    onSuccess: () => {
      setFilesToUpload([])
      queryClient.invalidateQueries(queryKeys.facts)
    },
    onError: () => {
      showMessage({
        type: "error",
        message: "There was an error uploading this file.",
      })
    },
  })
  const uploadImageMutation = useMutation(uploadExhibit, {
    onSuccess: () => {
      setFactsImagesToUpload([])
      setLiabilitiesImagesToUpload([])
      queryClient.invalidateQueries(queryKeys.facts)
    },
  })
  const deleteImageMutation = useMutation(deleteExhibit, {
    onSuccess: () => {
      showMessage({ type: "success", message: "Deleted Image" })
      queryClient.invalidateQueries(queryKeys.facts)
    },
    onError: () => {
      showMessage({
        type: "error",
        message: "Error deleting image. Please try again shortly and if your problem persists contact a dev.",
      })
    },
  })

  const bulkDeleteExhibitsMutation = useMutation(bulkDeleteExhibits, {
    onSuccess: () => {
      setPendingImagesToDelete([])
      showMessage({ type: "success", message: "Images were deleted" })
      queryClient.invalidateQueries(queryKeys.facts)
    },
    onError: () => {
      showMessage({
        type: "error",
        message:
          "Error deleting images. Please try again shortly and if your problem persists contact a dev.",
      })
    },
  })

  const updateImageMutation = useMutation(updateExhibit, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.facts)
    },
  })

  const reorderImages = newImages => {
    for (let i in newImages) {
      updateImageMutation.mutate({
        caseId,
        exhibitId: newImages[i].pk,
        data: { case_id: caseId, pk: newImages[i].pk, section_index: i },
      })
    }
  }

  const handleDelete = async fileId => {
    deleteImageMutation.mutate({ exhibitId: fileId, caseId })
  }

  const uploadImages = async (images, type) => {
    const imagePromises = images.map(image => {
      const formData = new FormData()
      formData.append("image", image.file)
      formData.append("name", image?.name ? image.name : image.file.name)
      formData.append("section", type)
      formData.append("type", "image")
      return uploadImageMutation.mutateAsync({ data: formData, caseId })
    })
    return Promise.all(imagePromises)
  }

  const uploadAllImages = async () => {
    await Promise.all([
      uploadImages(factsImagesToUpload, "facts"),
      uploadImages(liabilitiesImagesToUpload, "liabilities"),
    ])
  }

  const handleRemoveFile = async file => deleteFileMutation.mutate({ exhibitId: file.pk, caseId })

  const handleDownloadFile = async file => {
    await downloadExhibit({ caseId, exhibitId: file.pk, fileName: file.name, downloadFile: true })
  }

  const handleUploadFiles = async files => {
    if (files.length) {
      files.forEach(file => {
        let fileData = new FormData()
        const { file: fileObject, documentType, name, questionnaireFileId = null } = file

        fileData.append("file", fileObject ?? new Blob())
        fileData.append("name", name)
        fileData.append("type", documentType)
        fileData.append("section", "facts")
        if (questionnaireFileId) {
          fileData.append("questionnaire_file_id", questionnaireFileId)
        }

        uploadFileMutation.mutate({ data: fileData, caseId })
      })

      queryClient.invalidateQueries(queryKeys.facts)
    }
  }

  const downloadImage = useCallback(
    async image => {
      return await downloadExhibit({ caseId, exhibitId: image.pk })
    },
    [caseId]
  )

  const handleSave = handleSubmit(async data => {
    await uploadAllImages()
    if (filesToUpload.length) {
      await handleUploadFiles(filesToUpload)
    }
    factsMutation.mutate({ caseId, data })
  })

  const handleOnBlur = handleSubmit(async data => {
    const changesFields = getChangedFields(data, formState)
    if (size(changesFields) > 0) {
      factsMutation.mutate({ caseId, data })
    }
  })

  const handleDeleteAllPhotoClick = images => {
    setPendingImagesToDelete(images.map(image => image.pk))
    openDeleteAllDialog()
  }

  const deleteAllImages = () => {
    bulkDeleteExhibitsMutation.mutate({ caseId, ids: pendingImagesToDelete })
  }

  useEffect(() => {
    saveRef.current = handleSave
  }, [handleSave, saveRef])

  useAutosave({
    shouldAutosave: formState.isDirty && getValues("pk"),
    save: () => {
      autosaveMutation.mutate({ caseId, data: { ...getValues(), case_id: caseId } })
    },
  })

  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.facts
  })

  usePrompt(
    "You have images or files that are not yet uploaded. Are you sure you want to leave this page?",
    factsImagesToUpload.length || liabilitiesImagesToUpload.length || filesToUpload.length
  )

  const mapAttachedFilesToObject = file => {
    return {
      file: file,
      name: file.name,
      ...file,
    }
  }

  const { isFeatureEnabled } = useFeatures()
  const queryKeysForInvalidation = useMemo(
    () => [queryKeys.facts, queryKeys.case, [queryKeys.steps, caseId]],
    [caseId]
  )
  const areCaseTemplatedSectionsEnabled = isFeatureEnabled(FEATURES.CASE_ATTRIBUTES)
  const isUsingTemplatedData =
    areCaseTemplatedSectionsEnabled && !!Object.values(getValues().sections ?? {}).length
  const userActionRequired =
    areCaseTemplatedSectionsEnabled &&
    Object.values(getValues().sections).some(section => !!section.userActionRequired)

  return (
    <>
      <form className={classes.formFields} noValidate onBlur={handleOnBlur}>
        <DateField
          initialValue={dateOfIncident}
          onChange={handleChangeDateOfIncident}
          label="Date of Incident"
          fieldProps={{ name: "date_of_incident" }}
          error={errors?.date_of_incident}
          helperText={errors?.date_of_incident}
        />

        <Controller
          name="type"
          control={control}
          render={({ field }) => {
            const selector = (
              <FormControl variant="outlined" error={errors && errors["type"]}>
                <InputLabel id="case-type-label">Case type</InputLabel>
                <Select
                  labelId="case-type-label"
                  label="Case type"
                  data-test="case-type"
                  variant="outlined"
                  {...field}
                  value={field.value ?? ""}
                  disabled={isUsingTemplatedData}
                >
                  {Object.entries(CASE_TYPES).map(([value, label]) => {
                    return (
                      <MenuItem key={value} value={value.toString()}>
                        {label}
                      </MenuItem>
                    )
                  })}
                </Select>
                {errors && errors["type"] && <FormHelperText>Please select a policy type.</FormHelperText>}
              </FormControl>
            )

            if (isUsingTemplatedData) {
              return (
                <Tooltip title={"Case type is set in the 'Select Template' section"} placement={"top"}>
                  {selector}
                </Tooltip>
              )
            }

            return selector
          }}
        />

        <Divider className={classes.fullWidth} />

        {userActionRequired && (
          <CaseAlert
            className={classes.fullWidth}
            title="New templates have been added to reflect the newly selected attributes."
            message="Ensure templates are modified appropriately below."
          />
        )}

        <Box className={classes.fullWidth}>
          <Typography variant="h6" component="h3" className={classes.sectionTitle}>
            Facts
          </Typography>
          <CaseEditorField
            key={getValues("pk")}
            control={control}
            name="facts_json"
            markdownName="facts"
            section={CASE_SECTIONS.FACTS}
            invalidateQueryKeys={queryKeysForInvalidation}
            dataTest="case-facts"
            label="What are the facts of the case?"
          />
          <Box className={classes.fullWidth}>
            {!!factsImages?.length && (
              <>
                <DnDExhibitList
                  id="facts-images"
                  onDelete={handleDelete}
                  caseId={caseId}
                  exhibits={factsImages}
                  onDownload={downloadImage}
                  onReorder={reorderImages}
                  onMediaClick={handleDownloadFile}
                  onSuccessfulSave={() => queryClient.invalidateQueries(queryKeys.facts)}
                />
                <TextButton
                  onClick={() => {
                    handleDeleteAllPhotoClick(factsImages)
                  }}
                >
                  REMOVE ALL PHOTOS
                </TextButton>
              </>
            )}
          </Box>
          <Box className={classes.fullWidth} mt={2}>
            <FileDropzone
              acceptedFileTypes={ACCEPT_IMAGE_TYPE}
              onDrop={newFiles => {
                setFactsImagesToUpload(previousFiles => [
                  ...previousFiles,
                  ...newFiles.map(mapAttachedFilesToObject),
                ])
              }}
            />
            <PendingImages
              pendingImages={factsImagesToUpload}
              setPendingImages={setFactsImagesToUpload}
              onSubmit={() => uploadImages(factsImagesToUpload, "facts")}
            />
          </Box>
        </Box>

        <Divider className={classes.fullWidth} />

        <Box className={classes.fullWidth}>
          <Typography variant="h6" component="h3" className={classes.sectionTitle}>
            Liabilities
          </Typography>
          <CaseEditorField
            key={getValues("pk")}
            control={control}
            name="liability_details_json"
            markdownName="liability_details"
            section={CASE_SECTIONS.LIABILITIES}
            invalidateQueryKeys={queryKeysForInvalidation}
            dataTest="liabilities"
            label="What are the liability details?"
          />
          <Box className={classes.fullWidth}>
            {!!liabilitiesImages?.length && (
              <>
                <DnDExhibitList
                  id="liabilities-images"
                  onDelete={handleDelete}
                  caseId={caseId}
                  exhibits={liabilitiesImages}
                  onDownload={downloadImage}
                  onReorder={reorderImages}
                  onMediaClick={handleDownloadFile}
                  onSuccessfulSave={() => queryClient.invalidateQueries(queryKeys.facts)}
                />
                <TextButton
                  onClick={() => {
                    handleDeleteAllPhotoClick(liabilitiesImages)
                  }}
                >
                  REMOVE ALL PHOTOS
                </TextButton>
              </>
            )}
          </Box>
          <Box className={classes.fullWidth} mt={2} mb={1}>
            <FileDropzone
              acceptedFileTypes={ACCEPT_IMAGE_TYPE}
              onDrop={newFiles => {
                setLiabilitiesImagesToUpload(previousFiles => [
                  ...previousFiles,
                  ...newFiles.map(mapAttachedFilesToObject),
                ])
              }}
            />
            <PendingImages
              pendingImages={liabilitiesImagesToUpload}
              setPendingImages={setLiabilitiesImagesToUpload}
              onSubmit={() => uploadImages(liabilitiesImagesToUpload, "liabilities")}
            />
          </Box>
        </Box>

        <Divider className={classes.fullWidth} />

        {!!files?.length && (
          <Box className={classes.fullWidth}>
            <ExhibitList
              exhibits={files}
              caseId={caseId}
              fileTypeMap={FILE_OPTIONS}
              onDelete={handleRemoveFile}
              onSuccessfulSave={() => queryClient.invalidateQueries(queryKeys.facts)}
            />
          </Box>
        )}

        <Box className={classes.fullWidth}>
          <FileDropzone
            onDrop={newFiles => {
              setFilesToUpload(previousFiles => [...previousFiles, ...newFiles.map(mapAttachedFilesToObject)])
            }}
          />
          {!!filesToUpload.length && (
            <>
              <PendingFilesForm filesToUpload={filesToUpload} setFilesToUpload={setFilesToUpload} />
              <Button
                disabled={filesToUpload.some(file => !file.documentType)}
                onClick={() => {
                  handleUploadFiles(filesToUpload)
                }}
                variant="outlined"
                data-test="add-file-button"
              >
                Add File{filesToUpload.length > 1 ? "s" : ""}
              </Button>
            </>
          )}
        </Box>
      </form>

      {request?.pk && (
        <Box className={classes.fullWidth} mt={2}>
          <MissingDocumentSection
            missingDocs={missingExhibits}
            title="Missing Documents List"
            section={SECTIONS.CASE_FACTS}
          />
        </Box>
      )}

      <ConfirmDialog
        open={isDeleteAllOpen}
        onClose={() => {
          setPendingImagesToDelete([])
          closeDeleteAllDialog()
        }}
        onConfirm={() => {
          closeDeleteAllDialog()
          deleteAllImages()
        }}
        title="Are you sure you want to delete all images?"
        cancelText="Cancel"
        confirmText="Delete"
      />
    </>
  )
}
