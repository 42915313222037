import React, { useContext } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { LeftNavContext } from "demand/context"

interface StyleProps {
  leftNavTabIsExpanded: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    flexDirection: props => (props.leftNavTabIsExpanded ? "column" : "row"),
    gap: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}))

interface SectionContainerProps {
  children: React.ReactNode
}

const SectionContainer = ({ children }: SectionContainerProps): JSX.Element => {
  const { leftNavTabIsExpanded } = useContext(LeftNavContext)
  const classes = useStyles({ leftNavTabIsExpanded })
  return <div className={classes.container}>{children}</div>
}

export { SectionContainer as default }
