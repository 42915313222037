import React, { useEffect, useState, useCallback } from "react"
import { Box, TextField, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useMutation, useQuery } from "react-query"
import { useOutletContext } from "react-router-dom"
import { Controller, useForm } from "react-hook-form"
import { debounce, size } from "lodash"
import { DateField, SelectInput } from "common/form-components"

import { getNonEconomic, updateNonEconomic, calculateNonEconomic } from "../api"
import { queryKeys } from "../react-query/constants"
import { useFormContext } from "./context"
import { getChangedFields, getCommonMutateOptions } from "../utils"
import NonEconomicPreview from "./NonEconomicPreview"
import RelevantDatesTable from "./RelevantDatesTable"
import { formSectionsToRoutes, IMPAIRMENT_TIME_UNIT_MAP, STEP_STATUSES } from "./constants"
import { queryClient } from "../react-query/queryClient"
import { MissingNonEconomicAlert } from "./Alerts/MissingNonEconomicAlert"
import { HelpOutline } from "@material-ui/icons"

const INITIAL_FORM_STATE = {
  hourly_rate_pain_suffering: 0,
  future_hourly_rate_pain_suffering: 0,
  waking_hours_per_day: 16,
  future_impaired_years: 0,
  future_impaired_days: 0,
  end_of_past_pain_suffering_date: "",
  impairment_timeframe: "years",
}

const useStyles = makeStyles(theme => ({
  formFields: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: theme.spacing(4),
  },
  actions: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    margin: theme.spacing(2, 0),
    justifyContent: "flex-end",
  },
  fullWidth: {
    gridColumn: "1 / 4",
  },
}))

export function NonEconomic({ lastVisited }) {
  const classes = useStyles()
  const [saveRef] = useOutletContext()
  const [isDirty, setIsDirty] = useState(false)
  const { caseId, setSavedSuccessfully, showErrorMessage, handleUpdateStepStatus } = useFormContext()
  const { control, handleSubmit, reset, formState, watch, setValue } = useForm({
    defaultValues: INITIAL_FORM_STATE,
  })
  const [errors, setErrors] = useState(null)

  useQuery([queryKeys.nonEconomic, caseId], async () => {
    const data = await getNonEconomic(caseId)
    if (data?.length) {
      data[0].hourly_rate_pain_suffering = data[0].hourly_rate_pain_suffering ?? 0
      data[0].future_hourly_rate_pain_suffering = data[0].future_hourly_rate_pain_suffering ?? 0
      data[0].future_impaired_years = data[0].future_impaired_years ?? 0
      data[0].future_impaired_days = data[0].future_impaired_days ?? 0
      data[0].waking_hours_per_day = data[0].waking_hours_per_day ?? 16
      reset(data[0])
    }
  })

  const endDate = watch("end_of_past_pain_suffering_date")

  const handleChangeEndDate = useCallback(
    newEndDate => setValue("end_of_past_pain_suffering_date", newEndDate, { shouldDirty: true }),
    [setValue]
  )

  const impairmentTimeframe = watch("impairment_timeframe")
  const futureImpairmentError =
    impairmentTimeframe === "years" ? errors?.future_impaired_years : errors?.future_impaired_days
  const futureImpairmentTooltipTitle =
    impairmentTimeframe === "years" ? `Decimal values are accepted for years, i.e. "10.17"` : ""
  if (impairmentTimeframe === "years") {
    setValue("future_impaired_days", 0, { shouldDirty: true })
  } else {
    setValue("future_impaired_years", 0, { shouldDirty: true })
  }

  const getFutureImpairmentLabel = timeframe => {
    const impairmentTimeLabel = `Subsequent Impaired ${IMPAIRMENT_TIME_UNIT_MAP.get(timeframe).display}`
    if (timeframe === "years") {
      return (
        <Box display={"flex"}>
          <Box>{impairmentTimeLabel}</Box>
          <Box ml={1} mt={-0.5}>
            <HelpOutline />
          </Box>
        </Box>
      )
    } else {
      return impairmentTimeLabel
    }
  }

  const { error, data } = useQuery(
    [queryKeys.calculateNonEconomic, caseId, "results"],
    () => {
      setIsDirty(true)
      return calculateNonEconomic(caseId)
    },
    {
      meta: {
        disableLoader: true,
      },
      onSuccess: () => {
        setIsDirty(false)
      },
    }
  )

  const mutationOptions = getCommonMutateOptions({
    setErrors,
    reset,
    setSavedSuccessfully,
    showErrorMessage,
    onSuccessExtra: () => {
      handleUpdateStepStatus({ status: STEP_STATUSES.completed })
      queryClient.invalidateQueries(queryKeys.calculateNonEconomic)
    },
  })
  const mutation = useMutation(updateNonEconomic, mutationOptions)

  const handleOnChange = handleSubmit(async data => {
    const changesFields = getChangedFields(data, formState)
    if (size(changesFields) > 0) {
      mutation.mutateAsync({ caseId, data })
    }
  })

  useEffect(() => {
    saveRef.current = handleOnChange
  }, [handleOnChange, saveRef])

  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.non_economic
  })

  const handleOnChangeDebounce = debounce(handleOnChange, 300)

  return (
    <>
      <MissingNonEconomicAlert caseId={caseId} />
      <RelevantDatesTable />
      <form className={classes.formFields} noValidate onChange={handleOnChangeDebounce}>
        <Controller
          name="hourly_rate_pain_suffering"
          control={control}
          render={({ field }) => (
            <TextField
              type="number"
              label="Initial Hourly Rate (P & S)"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: 0,
              }}
              {...field}
            />
          )}
        />
        <Controller
          name="future_hourly_rate_pain_suffering"
          control={control}
          render={({ field }) => (
            <TextField
              type="number"
              label="Subsequent Hourly Rate (P & S)"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: 0,
              }}
              {...field}
            />
          )}
        />
        <Controller
          name="waking_hours_per_day"
          control={control}
          render={({ field }) => (
            <TextField
              type="number"
              label="Waking Hours"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: 0,
                max: 25,
              }}
              {...field}
            />
          )}
        />
        <DateField
          initialValue={endDate}
          onChange={handleChangeEndDate}
          onClose={handleOnChangeDebounce}
          label="Initial P & S End Date"
          fieldProps={{ name: "end_of_past_pain_suffering_date" }}
          helperText={errors?.end_of_past_pain_suffering_date}
        />
        <SelectInput
          name="impairment_timeframe"
          control={control}
          label="Impairment Timeframe"
          options={Array.from(IMPAIRMENT_TIME_UNIT_MAP.values())}
        />
        <Controller
          name={`future_impaired_${impairmentTimeframe}`}
          control={control}
          render={({ field }) => (
            <Tooltip placement="top" title={futureImpairmentTooltipTitle}>
              <TextField
                type="number"
                label={getFutureImpairmentLabel(impairmentTimeframe)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: 0,
                }}
                error={futureImpairmentError}
                helperText={futureImpairmentError}
                {...field}
              />
            </Tooltip>
          )}
        />
      </form>
      <NonEconomicPreview {...{ isLoading: isDirty, error, data }} />
    </>
  )
}
