import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core"
import { Close } from "@material-ui/icons"
import { resetPartitionsAndActions } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import React from "react"
import { useMutation, useQueryClient } from "react-query"
import { queryKeys } from "react-query/constants"
import { updateAnnotatedExhibits } from "./cacheUtils"
import { AnnotatedExhibit } from "./types"

interface ResetDialogProps {
  open: boolean
  onClose: () => unknown
  exhibitId: number
  fileName: string
  caseId: string | number
}

const ResetDialog: React.FC<ResetDialogProps> = ({ open, onClose, exhibitId, fileName, caseId }) => {
  const queryClient = useQueryClient()
  const { showMessage } = useHandleMessages()

  const { mutate: reset, isLoading: isResetting } = useMutation({
    mutationFn: resetPartitionsAndActions,
    onSuccess: (data: AnnotatedExhibit) => {
      queryClient.setQueryData<AnnotatedExhibit[]>([queryKeys.annotated_exhibits, caseId], oldData => {
        return updateAnnotatedExhibits({
          oldData,
          exhibitId,
          update: data,
        })
      })
      onClose()
    },
    onError: () => {
      showMessage({
        type: "error",
        message:
          "Failed to reset file. Try again shortly and if your problem persists, please report your issue.",
      })
    },
  })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={`partition-reset-dialog-title-${exhibitId}`}
      aria-describedby={`partition-reset-dialog-description-${exhibitId}`}
      fullWidth
      maxWidth="xs"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle id={`partition-reset-dialog-title-${exhibitId}`}>Reset Changes</DialogTitle>
        <Box mr={1}>
          <IconButton onClick={onClose} disabled={isResetting}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        <DialogContentText id={`partition-reset-dialog-description-${exhibitId}`}>
          Reset changes to {fileName}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} disabled={isResetting}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isResetting}
          onClick={() => reset({ exhibitId, caseId })}
        >
          Reset
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ResetDialog
