import React from "react"
import { Box } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"
import { DragIndicator } from "@material-ui/icons"
import { useFormContext } from "../context"
import { useMutation, useQueryClient } from "react-query"
import { queryKeys } from "react-query/constants"
import { updateFutureExpense } from "api"
import { getCommonMutateOptions } from "utils"
import { useForm } from "react-hook-form"
import { CurrencyField, InputField } from "common/form-components"

import { amountInDollars } from "../../utils"
import { FutureMedicalItem, InnerLayout, GridLayoutEditView, InnerContent } from "./FutureExpenseStyled"
import { RichTextField } from "../../common/form-components/rich-text"
import { Draggable } from "react-beautiful-dnd"
import TextButton from "common/buttons/TextButton"

const DescriptionText = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  lineHeight: 1.42,
  color: theme.palette.grey[600],
}))

export function FutureExpenseEditor({ data, onSaveCallback, index }) {
  const queryClient = useQueryClient()
  const { caseId, handleUpdateStepStatus } = useFormContext()
  const { control, handleSubmit, reset, setValue, setErrors, setSavedSuccessfully, showErrorMessage, watch } =
    useForm({
      defaultValues: data,
    })

  const mutationOptions = getCommonMutateOptions({
    setErrors,
    reset,
    setSavedSuccessfully,
    showErrorMessage,
    setValue,
  })

  const updateMutation = useMutation(updateFutureExpense, {
    mutationOptions,
  })

  const handleFormSubmit = handleSubmit(async data => {
    try {
      await updateMutation.mutateAsync({ caseId, data })
    } catch (error) {
      // do nothing
    }
    await handleUpdateStepStatus({ status: "completed" })
  })

  const handleSave = async update => {
    handleFormSubmit().then(() => {
      if (update) {
        queryClient.invalidateQueries([queryKeys.futureExpense, caseId])
      }
    })
  }
  const handleOnBlur = () => {
    handleSave(false)
  }

  const [cost, annual_frequency, number_of_years] = watch(["cost", "annual_frequency", "number_of_years"])
  const total = amountInDollars(cost * annual_frequency * number_of_years)

  return (
    <Draggable key={data.pk} draggableId={String(data.pk)} index={index}>
      {draggableProvided => (
        <FutureMedicalItem
          key={data.pk}
          onBlur={handleOnBlur}
          {...draggableProvided.draggableProps}
          ref={draggableProvided.innerRef}
        >
          <Box>
            <GridLayoutEditView>
              <Box {...draggableProvided.dragHandleProps}>
                <DragIndicator />
              </Box>
              <Box>
                <InputField
                  name="procedure_name"
                  control={control}
                  type="text"
                  aria-describedby="procedure-heading"
                />
              </Box>
              <Box>
                <CurrencyField
                  name="cost"
                  control={control}
                  aria-describedby="cost-heading"
                  variant="standard"
                />
              </Box>
              <Box>
                <InputField
                  name="annual_frequency"
                  control={control}
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  aria-describedby="annual-frequency-heading"
                />
              </Box>
              <Box>
                <InputField
                  name="number_of_years"
                  control={control}
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  aria-describedby="years-heading"
                />
              </Box>
              <Box align="right">{total}</Box>
              <Box align="right">
                <TextButton
                  textColor="grey"
                  onClick={() => {
                    reset(data)
                    onSaveCallback && onSaveCallback()
                  }}
                >
                  Cancel
                </TextButton>
                <TextButton
                  textColor="blue"
                  data-test="done-adding-expense"
                  onClick={() => {
                    handleSave(true)
                    onSaveCallback && onSaveCallback()
                  }}
                >
                  Save
                </TextButton>
              </Box>
            </GridLayoutEditView>
            <InnerLayout>
              <InnerContent>
                <RichTextField
                  control={control}
                  name="description"
                  markdownName="description"
                  dataTest="procedure-description"
                  label="Notes, external source links, etc."
                />
                <DescriptionText>
                  This note will appear in the table in the final demand and will have to be moved into
                  footnotes.
                </DescriptionText>
              </InnerContent>
            </InnerLayout>
          </Box>
        </FutureMedicalItem>
      )}
    </Draggable>
  )
}
