import React, { useState, useCallback, useEffect } from "react"
import { Box, CircularProgress } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"

import { AnnotatedContainer, Header } from "../styled"
import ErrorState from "../ErrorState"

import { useQuery } from "react-query"
import { queryKeys } from "react-query/constants"
import { getAnnotatedProviderBills } from "api"

import BillTable from "./BillTable"

import { amountInDollars } from "utils"
import { Bill, BillsResponse } from "./types"
import { Bill as ProviderBill } from "demand/Providers/types"
import { Container } from "../Container"

import { useFeatures, FEATURES } from "hooks/useFeatures"
import { Entry, PartitionEntry } from "../types"

const BillsHeader = styled(Header)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  boxShadow: `0px 0px 6px 0 ${theme.palette.grey[200]}`,
  backgroundColor: theme.palette.grey[50],
}))

const SubHeader = styled(Header)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  marginBottom: theme.spacing(0),
}))

const Content = styled(Box)(() => ({
  overflowY: "scroll",
}))

interface BillsProps {
  providerId: number
  caseId: number
  currentBills: ProviderBill[]
  onEntryClick: (entry: PartitionEntry) => void
  dateUpdated?: string
}

const Bills = ({ providerId, caseId, currentBills, onEntryClick, dateUpdated }: BillsProps): JSX.Element => {
  const { isFeatureEnabled } = useFeatures()
  const isProviderAutofillEnabled = isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL)
  const [billResponse, setBillResponse] = useState<Nullable<BillsResponse>>(null)
  const [isDeviated, setIsDeviated] = useState<boolean>(false)

  const handleEntryClick = useCallback(
    (entry: Entry) => {
      onEntryClick({
        exhibitId: entry.exhibit_id,
        partitionId: entry.partition_id,
        page: entry.page,
      })
    },
    [onEntryClick]
  )

  const isSameBill = useCallback((annotatedBill: Bill, providerBill: ProviderBill) => {
    return annotatedBill.charges.find(billCharge => {
      const chargeWriteOffTotal = billCharge.write_offs.reduce((sum, writeOff) => sum + writeOff.amount, 0)
      return (
        billCharge.charge.amount === (providerBill.billed_amount ?? 0) &&
        chargeWriteOffTotal === (providerBill.adjusted_amount ?? 0)
      )
    })
  }, [])

  const checkForDeviation = useCallback(
    (annotatedBills: Bill[]) => {
      const annotatedCharges =
        annotatedBills
          .map(bill => {
            return bill.charges
          })
          .flat() ?? []

      if (annotatedCharges.length !== currentBills.length) {
        return setIsDeviated(true)
      }

      const matchesAll = currentBills.every(currentBill => {
        return annotatedBills.find(annotatedBill => {
          return isSameBill(annotatedBill, currentBill)
        })
      })

      if (!matchesAll) {
        return setIsDeviated(true)
      }

      const matchesAllReverse = annotatedBills.every(annotatedBill => {
        return currentBills.find(currentBill => {
          return isSameBill(annotatedBill, currentBill)
        })
      })

      if (!matchesAllReverse) {
        return setIsDeviated(true)
      }

      setIsDeviated(false)
    },
    [currentBills, isSameBill]
  )

  const { isFetching, isError } = useQuery(
    [queryKeys.annotatedBills, caseId, providerId],
    async () => {
      return await getAnnotatedProviderBills(caseId, providerId)
    },
    {
      meta: {
        disableLoader: true,
      },
      onSuccess: response => {
        setBillResponse(response)
      },
      enabled: isProviderAutofillEnabled,
    }
  )

  useEffect(() => {
    checkForDeviation(billResponse?.bills || [])
  }, [billResponse, checkForDeviation, currentBills])

  if (!isProviderAutofillEnabled) {
    return <></>
  }

  if (isFetching) {
    return (
      <AnnotatedContainer>
        <Box display="flex" m={"auto"}>
          <CircularProgress />
        </Box>
      </AnnotatedContainer>
    )
  }

  if (!isError && !isFetching && !billResponse?.bills?.length) {
    return (
      <Container>
        <Box display="flex" m={"auto"}>
          <Box>No Annotated Bills</Box>
        </Box>
      </Container>
    )
  }

  if (isError || !billResponse) {
    return (
      <Container>
        <ErrorState message="There was an error retrieving annotated bills" />
      </Container>
    )
  }

  const { billed_total, write_off_total, bills } = billResponse

  return (
    <Container
      includeDeviation={isDeviated}
      includeAnnotationUpdated={!!dateUpdated}
      dateUpdated={dateUpdated}
    >
      <BillsHeader>
        <Box>Bills</Box>
        <Box>Total&#x3a;&nbsp;{amountInDollars(billed_total)}</Box>
        <Box>Adjustments&#x3a;&nbsp;{amountInDollars(write_off_total)}</Box>
      </BillsHeader>
      <SubHeader>
        <Box>Date of Service</Box>
        <Box>Charges</Box>
        <Box>Adjustments</Box>
      </SubHeader>
      <Content>
        {!!bills.length &&
          bills.map((bill: Bill, index) => {
            return <BillTable bill={bill} key={index} onEntryClick={handleEntryClick} />
          })}
      </Content>
    </Container>
  )
}

export { Bills as default }
