import useUser from "hooks/useUser"
import React, { useCallback, useContext } from "react"
import { canEditLibrary } from "settings/permissions"
import { LibraryTabStateContext } from "../State/constants"
import {
  createCollapseTemplateAction,
  createExpandTemplateAction,
  createStartEditTemplateAction,
} from "../State/templatesActions"
import {
  StyledTableListItem,
  StyledEditWrapper,
  StyledEdit,
  StyledExpandArrow,
  StyledCancelButton,
  StyledSaveButton,
} from "./styled"

interface LibraryTableListItemButtonsProps {
  handleCancel: () => void
  handleSave: () => void
  handleDuplicate: () => void
  rowId: number
  canDuplicate: boolean
}

export function LibraryTableListItemButtons({
  handleCancel,
  handleSave,
  rowId,
  handleDuplicate,
  canDuplicate,
}: LibraryTableListItemButtonsProps): JSX.Element {
  const { user } = useUser()
  const canEdit = canEditLibrary(user.role)
  const { state, dispatch } = useContext(LibraryTabStateContext)
  const disabled = !!(state.creatingData || state.editingEntityId)
  const edit = state.editingEntityId === rowId
  const expanded = state.expandedEntityIds.includes(rowId)
  const showDetails = edit || expanded

  const handleExpand = useCallback(() => {
    if (edit) return

    const actionCreator = expanded ? createCollapseTemplateAction : createExpandTemplateAction

    dispatch(actionCreator({ templateId: rowId }))
  }, [dispatch, expanded, rowId, edit])

  const handleStartEditing = useCallback(() => {
    dispatch(createStartEditTemplateAction({ templateId: rowId }))
  }, [dispatch, rowId])

  return (
    <>
      <StyledTableListItem>
        {canEdit && edit ? (
          <>
            <StyledCancelButton onClick={handleCancel}>cancel</StyledCancelButton>
            <StyledSaveButton onClick={handleSave}>save</StyledSaveButton>
          </>
        ) : (
          <StyledEditWrapper>
            {canEdit && canDuplicate && (
              <StyledEdit onClick={handleDuplicate} disabled={disabled}>
                Duplicate
              </StyledEdit>
            )}
            {canEdit && (
              <StyledEdit onClick={handleStartEditing} disabled={disabled}>
                Edit
              </StyledEdit>
            )}
            <StyledExpandArrow collapsed={showDetails} onClick={handleExpand} />
          </StyledEditWrapper>
        )}
      </StyledTableListItem>
    </>
  )
}
