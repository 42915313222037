import { Button } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"

export const StyledFiltersWrapper = styled("div")(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: "flex",
  // justifyContent: "space-between",
  alignItems: "top",
}))

export const BaseActionButton = styled(Button)(({ theme }) => ({
  minWidth: theme.spacing(13),
  fontWeight: 600,
}))
BaseActionButton.defaultProps = {
  variant: "contained",
  disableElevation: true,
}

export const StyledCancelButton = styled(BaseActionButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
}))

export const StyledAddButton = styled(BaseActionButton)({})
StyledAddButton.defaultProps = {
  color: "primary",
}

export const StyledPageSizeWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(1, 0, 1, 3),
}))

export const StyledSeparator = styled("div")(({ theme }) => ({
  height: theme.spacing(6),
}))
