import { createContext, useContext } from "react"

export const RequestContext = createContext({})

export const RequestContextProvider = RequestContext.Provider

export function useRequestContext() {
  const context = useContext(RequestContext)

  if (!context) {
    throw new Error("useRequestContext must be used within a RequestContextProvider")
  }

  return context
}
