import React, { useState } from "react"
import { styled } from "@material-ui/core/styles"
import { Box, List, MenuItem, Popover, PopoverOrigin } from "@material-ui/core"
import { AnnotatedExhibit, Duplicate, PartitionProvider, Split } from "./types"
import { useAutocomplete } from "@material-ui/lab"
import { useMutation, useQueryClient } from "react-query"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { createPartitionProvider } from "api"
import { queryKeys } from "react-query/constants"
import { mapUpdatePartition, updateAnnotatedExhibits } from "./cacheUtils"
import useUpdatePartitionMutation from "./hooks/useUpdatePartitionMutation"

const StyledInput = styled("input")(({ theme }) => ({
  padding: theme.spacing(1),
  width: "100%",
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  // make sure the background gets highlighted when navigating menu items via keyboard
  "&[data-focus]": {
    backgroundColor: theme.palette.grey[200],
  },
}))

const ANCHOR_OPTIONS: PopoverOrigin = { vertical: "bottom", horizontal: "left" }
const CREATE_PK = -1
const CREATE_PREFIX = "Create: "
const CREATE_VALUE_WRAPPING_CHAR = '"'

const getValueFromCreateString = (createStr: string) => {
  let value: string = createStr.replaceAll(CREATE_PREFIX, "")
  value = value.replaceAll(CREATE_VALUE_WRAPPING_CHAR, "")
  return value
}

interface ProviderAutocompleteProps {
  selected?: PartitionProvider
  options: PartitionProvider[]
  onChange: (value: Nullable<PartitionProvider>) => unknown
  onCreate: (newProviderName: string) => unknown
  disabled?: boolean
}

// autocomplete must be placed in it's own component when using open: true as an option
// if rendered inside of a Popper or Popover with open: true, some of the refs won't
// exist when the useAutocomplete does all it's stuff under the hood
const ProviderAutocomplete: React.FC<ProviderAutocompleteProps> = ({
  options,
  onChange,
  onCreate,
  disabled,
  selected,
}) => {
  const [value, setValue] = useState<string>("")

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    value: null,
    inputValue: value,
    open: true,
    freeSolo: false,
    options,
    getOptionLabel: opt => opt.name,
    includeInputInList: true,
    onInputChange: (event, value, reason) => {
      // "reset" is used to change the input on select of an option
      // we don't want to fill the input in this case, we would just close the autocomplete
      if (reason === "reset") {
        return
      }
      setValue(value)
    },
    onChange: (event, value, reason) => {
      // ignore onChange event for things like "clear" and "create-option"
      if (!disabled && value?.pk !== selected?.pk && reason === "select-option") {
        if (value?.pk === CREATE_PK) {
          onCreate(getValueFromCreateString(value.name))
        } else {
          onChange(value)
        }
      }
    },
  })

  if (value) {
    groupedOptions.push({
      pk: CREATE_PK,
      name: `${CREATE_PREFIX}${CREATE_VALUE_WRAPPING_CHAR}${value}${CREATE_VALUE_WRAPPING_CHAR}`,
      color: "",
    })
  }

  // when there is a value in the input box, we will append the "Create" option
  const noOptions = value === "" ? groupedOptions.length === 0 : groupedOptions.length === 1

  return (
    <Box maxWidth="400px" {...getRootProps()}>
      <Box py={1.5} px={1}>
        <StyledInput disabled={disabled} {...getInputProps()} placeholder="Search/Create" />
      </Box>
      <Box maxHeight="240px" overflow="scroll">
        <List disablePadding {...getListboxProps()}>
          {noOptions && <MenuItem button={false}>No providers</MenuItem>}
          {groupedOptions.map((option, index) => {
            return (
              <StyledMenuItem
                key={option.pk}
                disabled={disabled || selected?.pk === option.pk}
                {...getOptionProps({ option, index })}
              >
                {!noOptions && (
                  <Box
                    style={{ backgroundColor: option.color ? `#${option.color}` : undefined }}
                    width="16px"
                    height="16px"
                    mr={1}
                  />
                )}
                {option.name}
              </StyledMenuItem>
            )
          })}
        </List>
      </Box>
    </Box>
  )
}

interface ProviderMenuProps {
  anchorEl: Nullable<HTMLElement>
  onClose: () => unknown
  partitionProviders: PartitionProvider[]
  exhibitId: number
  caseId: string | number
  partitionId: number
  provider?: PartitionProvider
}

const ProviderMenu: React.FC<ProviderMenuProps> = ({
  anchorEl,
  onClose,
  partitionProviders,
  exhibitId,
  caseId,
  partitionId,
  provider,
}) => {
  const queryClient = useQueryClient()
  const { showMessage } = useHandleMessages()

  const { isUpdating, update } = useUpdatePartitionMutation({
    caseId,
    exhibitId,
    partitionId,
    onSuccess: onClose,
  })

  const { mutate: create, isLoading: isCreating } = useMutation({
    mutationFn: createPartitionProvider,
    onSuccess: (data: PartitionProvider) => {
      // 1) update annotated exhibit query
      queryClient.setQueryData<AnnotatedExhibit[]>([queryKeys.annotated_exhibits, caseId], oldData => {
        return updateAnnotatedExhibits({
          oldData,
          exhibitId,
          update: exhibit => {
            const split = exhibit.splits.find(split => split.pk === partitionId)
            const update: Partial<Duplicate> | Partial<Split> = { provider: data }

            if (split) {
              return {
                splits: mapUpdatePartition({ partitions: exhibit.splits, partitionId, update }),
              } as Partial<AnnotatedExhibit>
            }
            // must be a duplicate if could not find partition id in splits
            return {
              duplicates: mapUpdatePartition({ partitions: exhibit.duplicates, partitionId, update }),
            } as Partial<AnnotatedExhibit>
          },
        })
      })
      // 2) update fetched list of partition providers
      queryClient.setQueryData<PartitionProvider[]>([queryKeys.partition_providers, caseId], oldData => {
        if (!oldData) return []

        return [...oldData, data]
      })

      onClose()
    },
    onError: () => {
      showMessage({
        type: "error",
        message: "Could not create provider. Try again shortly and file an issue if the problem persists.",
      })
    },
  })

  const handleCreate = (newProviderName: string) => {
    if (partitionProviders.some(provider => provider.name === newProviderName)) {
      showMessage({
        type: "error",
        message: `A provider already exists with name: "${newProviderName}". Enter a new name and try again.`,
      })
      return
    }

    create({ caseId, data: { name: newProviderName, partition_id: partitionId } })
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={ANCHOR_OPTIONS}
      getContentAnchorEl={null}
    >
      <ProviderAutocomplete
        options={partitionProviders}
        onChange={partitionProvider =>
          update({ exhibitId, partitionId, data: { partition_provider_id: partitionProvider?.pk } })
        }
        onCreate={handleCreate}
        disabled={isUpdating || isCreating}
        selected={provider}
      />
    </Popover>
  )
}

export default ProviderMenu
