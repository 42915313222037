import React, { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"

import { makeStyles } from "@material-ui/core/styles"

import { useAuth } from "./hooks/useAuth"
import { useHandleMessages } from "./common/messages/useHandleMessages"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function Login() {
  // use for routing
  const navigate = useNavigate()
  const { search } = useLocation()
  const auth = useAuth()
  const { showMessage } = useHandleMessages()

  const classes = useStyles()
  // set default values
  const defaultValues = {
    email: "",
    password: "",
  }
  const [formValues, setFormValues] = useState(defaultValues)

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    try {
      await auth.login(formValues)
      const params = new URLSearchParams(search)
      if (params.has("redirectTo")) {
        const redirectTo = params.get("redirectTo")
        const preventRedirectToUrls = ["/settings/profile/change-password", "/settings/profile/change-email"]

        if (redirectTo && preventRedirectToUrls.includes(redirectTo)) {
          navigate(redirectTo)
        } else {
          navigate("/")
        }
      } else {
        navigate("/")
      }
    } catch (err) {
      return showMessage({
        type: "error",
        message: err?.message ?? "Couldn't log you in, please try again.",
      })
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            value={formValues.email}
            onChange={handleInputChange}
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          ></TextField>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            value={formValues.password}
            onChange={handleInputChange}
            label="Password"
            type="password"
            id="password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            data-test="submit-button"
          >
            Login
          </Button>
        </form>
      </div>
    </Container>
  )
}
