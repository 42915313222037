import React from "react"
import { RenderElementProps } from "slate-react"
import { SoftLineBreak as SoftLineBreakElement } from "../CustomEditor"

interface SoftLineBreakProps extends RenderElementProps {
  element: SoftLineBreakElement
}

export const SoftLineBreak: React.FC<SoftLineBreakProps> = ({ attributes, children }) => {
  return (
    <span {...attributes} contentEditable={false}>
      {children}
      <br />
    </span>
  )
}
