import React, { createContext, useContext, useRef, useState } from "react"
import { Box, Button, Divider, IconButton } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"
import { Close as CloseIcon } from "@material-ui/icons"

import {
  Control,
  FieldErrors,
  useForm,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useParams } from "react-router-dom"

import {
  createMissingExhibitEvent,
  getCurrentMissingExhibits,
  getMissingExhibits,
  sendMissingExhibitNotification,
  updateMissingExhibit,
  uploadRequestFile,
} from "../../api"
import { queryKeys } from "../../react-query/constants"
import { queryClient } from "../../react-query/queryClient"
import usePrompt from "../../hooks/usePrompt"
import { CheckboxInput, InputField, SelectInput } from "../../common/form-components"
import { useHandleMessages } from "../../common/messages/useHandleMessages"
import { ChunkFileUploader, handleChunkUploadResult } from "../../common/ChunkFileUploader"
import { MissingExhibit } from "../../missing-docs/interfaces"
import MissingDocumentItem from "../../missing-docs/MissingDocumentItem"
import CommunicationEvents from "../../missing-docs/Events/CommunicationEvents"
import useUser from "../../hooks/useUser"

import { INSTRUCTIONS, SECTIONS } from "../../missing-docs/constants"
import { MinorTitle } from "../../missing-docs/styled"
import { Loading } from "../../common"
import theme from "../../theme"
import { FileToUploadType } from "../../common/form-components/files/interfaces"
import FileDropzone from "../../common/form-components/files/FileDropzone"
import { EventMessage } from "requests/components/EventMessage"

interface ContextType {
  control: Control<FormValues, Record<string, any>>
  getValues: UseFormGetValues<FormValues>
  setValue: UseFormSetValue<FormValues>
  watch: UseFormWatch<FormValues>
  caseId: number
  errors: FieldErrors
}

const MissingDocumentContext = createContext<ContextType | null>(null)

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0, 0, 8),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(7, 0, 0, 0),
  },
}))

const SectionContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}))

const CommentTitle = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1.5rem",
  marginBottom: theme.spacing(3),
}))

const SectionFormContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: "3fr 2fr",
})

const StatusMessage = styled(EventMessage)(({ theme }) => ({
  maxWidth: "100%",
  marginBottom: theme.spacing(5),
}))

const Title = styled(Box)({
  fontWeight: 600,
  fontSize: "1.875rem",
  letterSpacing: "-0.6px",
  marginBottom: theme.spacing(4),
})

const SubTitle = styled(Box)({
  fontWeight: 1000,
  fontSize: "0.8rem",
  marginBottom: theme.spacing(1),
})

const EmptyContent = styled(Box)({
  fontStyle: "italic",
})

const Note = styled(Box)({
  fontSize: "0.8rem",
  margin: theme.spacing(2, 0, 4, 0),
})

interface SectionProps {
  label: string
  missingDocuments: MissingExhibit[]
  section: SECTIONS
  editable: boolean
}

interface MissingDocItem {
  instructions: {
    wait: boolean | undefined
    proceed: boolean | undefined
    upload: boolean | undefined
    unresolved: boolean | undefined
  }
  message: string | undefined
}

type PendingFileName =
  | "factsPendingFiles"
  | "providersPendingFiles"
  | "incomeLossPendingFiles"
  | "householdLossPendingFiles"

interface FormValues {
  comment: string
  missingDocs: MissingDocItem[]
  factsPendingFiles: AttachedFile[]
  providersPendingFiles: AttachedFile[]
  incomeLossPendingFiles: AttachedFile[]
  householdLossPendingFiles: AttachedFile[]
}

interface AttachedFile extends File {
  missingExhibitId: Nullable<number>
  file: File
  section: SECTIONS
}

const getPendingFilesName = (section: SECTIONS): PendingFileName => {
  switch (section) {
    case SECTIONS.CASE_FACTS:
      return "factsPendingFiles"
    case SECTIONS.PROVIDERS:
      return "providersPendingFiles"
    case SECTIONS.INCOME_LOSS:
      return "incomeLossPendingFiles"
    case SECTIONS.HOUSEHOLD_LOSS:
      return "householdLossPendingFiles"
    default:
      return "factsPendingFiles"
  }
}

const Section = ({ label, section, missingDocuments, editable }: SectionProps): JSX.Element => {
  const { control, getValues, setValue, watch, caseId, errors } = useContext(
    MissingDocumentContext
  ) as ContextType
  const { id: requestId } = useParams()

  if (!missingDocuments.length) {
    return <></>
  }

  const missingExhibitsOptions: ValueOptions<number> = missingDocuments.map((missingDoc: MissingExhibit) => {
    return {
      display:
        missingDoc.provider === null ? missingDoc.name : missingDoc.provider.name + " | " + missingDoc.name,
      key: missingDoc.pk,
    }
  })

  // TOOD: Add in once we figure out what to do with "Other"
  // missingExhibitsOptions.push({
  //   display: "Other",
  //   key: 0,
  // })

  let onlyMissingExhibitId: Nullable<number> = null
  if (missingExhibitsOptions.length === 1) {
    onlyMissingExhibitId = missingExhibitsOptions[0].key
  }

  const handleOnCheckboxClick = (
    option: INSTRUCTIONS,
    missingDocId: number,
    setValue: UseFormSetValue<FormValues>,
    getValues: UseFormGetValues<FormValues>
  ) => {
    const otherOption = option === INSTRUCTIONS.WAIT ? INSTRUCTIONS.PROCEED_WITHOUT : INSTRUCTIONS.WAIT

    setValue(
      `missingDocs.${missingDocId}.instructions.${option}`,
      !getValues(`missingDocs.${missingDocId}.instructions.${option}`)
    )

    if (watch(`missingDocs.${missingDocId}.instructions.${otherOption}`) === true) {
      setValue(`missingDocs.${missingDocId}.instructions.${otherOption}`, false)
    }
  }

  const pendingFilesName = getPendingFilesName(section)
  const pendingFiles = watch(pendingFilesName)

  const fileHasMissingExhibit = (pendingFiles: AttachedFile[], index: number): boolean => {
    return !!pendingFiles?.[index]?.missingExhibitId
  }

  return (
    <SectionContainer data-test={`section-${section}`} mb={1}>
      <MinorTitle>{label}</MinorTitle>
      {missingDocuments.map((missingDocument: MissingExhibit, index) => {
        const missingDocError = errors.missingDocs
        const error = missingDocError ? missingDocError[missingDocument.pk]?.message : null

        return (
          <SectionFormContainer key={index} data-test="missing-document-item-container">
            <MissingDocumentItem
              missingDoc={missingDocument}
              showActions={false}
              showFiles={false}
              section={section}
              caseId={caseId}
              requestId={requestId ? +requestId : 0}
              showStatus={true}
              test-data="missing-document-item"
            />
            {editable && (
              <Box minWidth={"32vh"} ml={2} mt={0.75}>
                <Box
                  fontSize={"13px"}
                  fontWeight={600}
                  color={error ? "red" : "inherit"}
                  data-test="checkbox-label"
                  className={error ? "error-label" : "label"}
                >
                  If you’re not uploading documents, how would you like us to proceed?
                </Box>
                <CheckboxInput
                  control={control}
                  checked={!!watch(`missingDocs.${missingDocument.pk}.instructions.${INSTRUCTIONS.WAIT}`)}
                  name={`missingDocs.${missingDocument.pk}.instructions.${INSTRUCTIONS.WAIT}`}
                  onClick={() => {
                    handleOnCheckboxClick(INSTRUCTIONS.WAIT, missingDocument.pk, setValue, getValues)
                  }}
                  label="Wait, I'll upload later"
                  data-test="wait-checkbox"
                />
                <CheckboxInput
                  control={control}
                  name={`missingDocs.${missingDocument.pk}.instructions.${INSTRUCTIONS.PROCEED_WITHOUT}`}
                  checked={
                    !!watch(`missingDocs.${missingDocument.pk}.instructions.${INSTRUCTIONS.PROCEED_WITHOUT}`)
                  }
                  onClick={() => {
                    handleOnCheckboxClick(
                      INSTRUCTIONS.PROCEED_WITHOUT,
                      missingDocument.pk,
                      setValue,
                      getValues
                    )
                  }}
                  label="Proceed Without"
                  data-test="proceed-checkbox"
                />
              </Box>
            )}
          </SectionFormContainer>
        )
      })}
      {editable && (
        <Box mt={2}>
          <DndProvider backend={HTML5Backend}>
            <FileDropzone
              onDrop={(newFiles: FileToUploadType[] | File[]) => {
                const sectionFiles = newFiles.map((file: any) => {
                  const newFile = {
                    file: file,
                    name: file.name,
                    ...file,
                    section: section,
                  }

                  if (onlyMissingExhibitId) {
                    newFile.missingExhibitId = onlyMissingExhibitId
                  }
                  return newFile
                })
                setValue(pendingFilesName, sectionFiles)
              }}
            >
              {pendingFiles.length
                ? (pendingFiles ?? []).map((pendingFile, index) => {
                    const missingExhibitAssigned = fileHasMissingExhibit(pendingFiles, index)
                    return (
                      <Box
                        key={index}
                        sx={{ display: "grid", gridTemplateColumns: "12fr 6fr 1fr" }}
                        data-test="attached-file"
                        paddingLeft={2}
                      >
                        <Box fontWeight={600} mt={"auto"} mb={"auto"}>
                          {pendingFile?.file?.name}
                        </Box>
                        <Box display={"flex"} flexDirection="column" data-test="missing-document-selector">
                          <SelectInput
                            control={control}
                            options={missingExhibitsOptions as ValueOptions<unknown>}
                            size={"small"}
                            name={`${pendingFilesName}.${index}.missingExhibitId`}
                          />
                          {errors?.[pendingFilesName]?.[index] && !missingExhibitAssigned && (
                            <Box mb={1} color="red" data-test="attached-file-errors">
                              {errors[pendingFilesName][index].message}
                            </Box>
                          )}
                        </Box>
                        <Box>
                          <IconButton
                            onClick={e => {
                              e.stopPropagation()
                              const pendingFiles = watch(pendingFilesName)
                              pendingFiles.splice(index, 1)
                              setValue(pendingFilesName, pendingFiles)
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    )
                  })
                : null}
            </FileDropzone>
          </DndProvider>
        </Box>
      )}
      <Box mt={8} mb={4}>
        {/* TODO: Add this to theme */}
        <Divider color={"#D9D9D9"} />
      </Box>
    </SectionContainer>
  )
}

interface MissingDocumentClientViewProps {
  caseId: number
  isCompleted?: boolean
}

const MissingDocumentClientView = ({
  caseId,
  isCompleted = false,
}: MissingDocumentClientViewProps): JSX.Element => {
  // TODO: Handle empty state (no Missing Exhibits)
  // TOOD: Handle nothing to do state (no Missing Exhibits that client needs to handle)
  const { user } = useUser()
  const { id: requestId } = useParams()
  const [processingUpdates, setProcessingUpdates] = useState<boolean>(false)
  const { showMessage } = useHandleMessages()
  const chunkFileUploaderRef = useRef<any | void>(null)

  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
    setError,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      comment: "",
      factsPendingFiles: [],
      providersPendingFiles: [],
      incomeLossPendingFiles: [],
      householdLossPendingFiles: [],
    },
  })

  const createEventMutation = useMutation(createMissingExhibitEvent, {
    onSuccess: ({ pk: eventId }) => {
      const updates = missingExhibits.map((missingExhibits: MissingExhibit) => {
        let instruction = null
        if (getValues(`missingDocs.${missingExhibits.pk}.instructions.${INSTRUCTIONS.WAIT}`) === true) {
          instruction = INSTRUCTIONS.WAIT
        } else if (
          getValues(`missingDocs.${missingExhibits.pk}.instructions.${INSTRUCTIONS.PROCEED_WITHOUT}`) === true
        ) {
          instruction = INSTRUCTIONS.PROCEED_WITHOUT
        } else {
          instruction = INSTRUCTIONS.UPLOAD
        }

        if (!instruction) return true
        return updateMissingExhibitMutation.mutate({
          caseId: caseId,
          missingExhibitId: missingExhibits.pk,
          data: {
            instructions: instruction,
          },
        })
      })

      Promise.allSettled(updates)
        .then(async () => {
          const filesToUpload = [
            ...getValues("factsPendingFiles"),
            ...getValues("providersPendingFiles"),
            ...getValues("incomeLossPendingFiles"),
            ...getValues("householdLossPendingFiles"),
          ]

          let uploadedFiles: any[] = []
          if (chunkFileUploaderRef.current) {
            uploadedFiles = await chunkFileUploaderRef.current.upload(filesToUpload.map(item => item.file))
          }

          await Promise.allSettled(
            filesToUpload.map(async pendingFile => {
              const formData = new FormData()
              handleChunkUploadResult(uploadedFiles, pendingFile.file, formData)
              formData.append("file", pendingFile.file)
              formData.append("name", pendingFile.name)
              formData.append("type", "other")
              formData.append("section", pendingFile.section)
              formData.append("missing_exhibit_event_id", eventId)
              if (pendingFile.missingExhibitId) {
                formData.append("missing_exhibit_id", `${pendingFile.missingExhibitId}`)
              }

              formData.delete("upload_id")

              return uploadDocumentMutation.mutate({
                requestId,
                data: formData,
              })
            })
          ).then(() => {
            // Send notification
            sendNotifcationMutation.mutate({
              caseId: caseId,
              missingExhibitId: eventId,
              data: {},
            })
          })
        })
        .catch(() => {
          setProcessingUpdates(false)
        })
    },
    onError: () => {
      showMessage({
        type: "error",
        message: "There was an error updating the missing document records.",
      })
      setProcessingUpdates(false)
    },
  })

  const updateMissingExhibitMutation = useMutation(updateMissingExhibit)
  const uploadDocumentMutation = useMutation(uploadRequestFile, {
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.missingExhibitEvents)
    },
  })
  const sendNotifcationMutation = useMutation(sendMissingExhibitNotification, {
    onSuccess: () => {
      showMessage({
        type: "success",
        message: "Updates have been successfully logged.",
      })

      reset()
      setValue("comment", "")
      setValue("factsPendingFiles", [])
      setValue("providersPendingFiles", [])
    },
    onSettled: () => {
      setProcessingUpdates(false)
      queryClient.invalidateQueries(queryKeys.missingExhibitEvents)
      queryClient.invalidateQueries(queryKeys.missingExhibits)
    },
  })

  const setFormErrors = (missingExhibits: any, files: AttachedFile[]): number[] => {
    const missingDocs = missingExhibits
      .map((ms: any, index: number) => {
        if (!ms) return
        return [index, ms.instructions]
      })
      .filter((ms: any) => ms)

    return missingDocs
      .filter((doc: any) => {
        const [missingDocId, instructions] = doc
        const hasFileUploaded = !!files.find((file: AttachedFile) => {
          return file.missingExhibitId === missingDocId
        })

        return (
          instructions[INSTRUCTIONS.PROCEED_WITHOUT] !== true &&
          instructions[INSTRUCTIONS.WAIT] !== true &&
          !hasFileUploaded
        )
      })
      .map((doc: any) => {
        const id = doc[0]
        setError(`missingDocs.${id}`, {
          type: "custom",
          message: "Must select instructions to proceed or upload a file for each missing document",
        })
        return id
      })
  }

  const setPendingFileErrors = (data: any) => {
    return [
      ...setPendingFileErrorsBySection(data, "factsPendingFiles"),
      ...setPendingFileErrorsBySection(data, "providersPendingFiles"),
      ...setPendingFileErrorsBySection(data, "incomeLossPendingFiles"),
      ...setPendingFileErrorsBySection(data, "householdLossPendingFiles"),
    ].filter(value => value === true)
  }

  const setPendingFileErrorsBySection = (data: any, pendingFilesName: PendingFileName) => {
    return data[pendingFilesName].map((pendingFile: AttachedFile, index: number) => {
      if (!pendingFile?.missingExhibitId) {
        setError(`${pendingFilesName}.${index}`, {
          type: "custom",
          message: "Must select a missing document for this file",
        })
        return true
      }
    })
  }

  const handleSubmitMissingExhibits = () => {
    const data = getValues()

    setProcessingUpdates(true)

    const unsetIds = setFormErrors(data.missingDocs, [
      ...data.factsPendingFiles,
      ...data.providersPendingFiles,
      ...data.incomeLossPendingFiles,
      ...data.householdLossPendingFiles,
    ])
    const pendingFilesErrors = setPendingFileErrors(data)

    if (unsetIds.length || pendingFilesErrors.length) {
      showMessage({
        type: "error",
        message: "Missing instructions or files for missing documents.",
      })
      setProcessingUpdates(false)
      return
    }

    createEventMutation.mutate({
      caseId: caseId,
      data: {
        comment: getValues("comment") ?? "",
      },
    })
  }

  // TODO: Edit copy
  usePrompt(
    "Are you sure you want to exit?",
    processingUpdates ||
      !!getValues("factsPendingFiles").length ||
      !!getValues("providersPendingFiles").length ||
      !!getValues("incomeLossPendingFiles").length ||
      !!getValues("householdLossPendingFiles").length
  )

  const { data: missingExhibits } = useQuery([queryKeys.missingExhibits, caseId, isCompleted], async () => {
    return isCompleted
      ? getMissingExhibits({ caseId, onlyUnresolved: false })
      : getCurrentMissingExhibits({ caseId: caseId })
  })

  if (!missingExhibits) return <></>

  // TODO: move to helper function as it's used in both views
  const factsMissingExhibits: MissingExhibit[] = []
  const providersMissingExhibits: MissingExhibit[] = []
  const lossOfHouseholdServicesMissingExhibits: MissingExhibit[] = []
  const lossOfIncomeMissingExhibits: MissingExhibit[] = []

  missingExhibits.forEach((file: MissingExhibit) => {
    if (file.instructions === INSTRUCTIONS.UPLOAD) return
    if (file.section === SECTIONS.CASE_FACTS) factsMissingExhibits.push(file)
    if (file.section === SECTIONS.PROVIDERS) providersMissingExhibits.push(file)
    if (file.section === SECTIONS.HOUSEHOLD_LOSS) lossOfHouseholdServicesMissingExhibits.push(file)
    if (file.section === SECTIONS.INCOME_LOSS) lossOfIncomeMissingExhibits.push(file)
  })

  return (
    <MissingDocumentContext.Provider
      value={{
        control,
        getValues,
        setValue,
        watch,
        caseId,
        errors,
      }}
    >
      <Container>
        <Title>Missing Documents</Title>
        {isCompleted && (
          <SectionFormContainer>
            <StatusMessage
              title="This demand has been completed with the following missing documents."
              message="If you received these documents and would like to include them, please open a revision request in the next tab."
            />
          </SectionFormContainer>
        )}

        <Section
          label={"Case Facts Section"}
          section={SECTIONS.CASE_FACTS}
          missingDocuments={factsMissingExhibits}
          editable={!isCompleted}
        />
        <Section
          label={"Providers Information Section"}
          section={SECTIONS.PROVIDERS}
          missingDocuments={providersMissingExhibits}
          editable={!isCompleted}
        />
        <Section
          label={"Loss of Income Section"}
          section={SECTIONS.INCOME_LOSS}
          missingDocuments={lossOfIncomeMissingExhibits}
          editable={!isCompleted}
        />
        <Section
          label={"Loss of Household Services Section"}
          section={SECTIONS.HOUSEHOLD_LOSS}
          missingDocuments={lossOfHouseholdServicesMissingExhibits}
          editable={!isCompleted}
        />

        {!isCompleted && missingExhibits.length > 0 && (
          <Box data-test="comments-container">
            <Box mt={2}>
              <CommentTitle>Comments {user.isExternal && <>(optional)</>}</CommentTitle>
              {user.isExternal && <SubTitle>Anything else you would like us to know?</SubTitle>}
              {/* TODO: Hide for internal? */}
              {/* TODO: Replace with RTF */}
              {/* <RichTextField control={control} name="comment_json" markdownName="comment" /> */}
              <InputField
                control={control}
                name="comment"
                variant="outlined"
                multiline={true}
                minRows={3}
                fullWidth={true}
              />
            </Box>
            <Box mt={5} mb={5}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setError("missingDocs", {})
                  setError("factsPendingFiles", {})
                  setError("providersPendingFiles", {})
                  setError("incomeLossPendingFiles", {})
                  setError("householdLossPendingFiles", {})
                  handleSubmitMissingExhibits()
                }}
                disabled={processingUpdates}
                data-test="submit-button"
              >
                {user.isInternal ? <>Notify Client</> : <>Submit</>}
              </Button>
              {user.isInternal && (
                <Note>
                  When ready, click &quot;Notify Client&quot; and an email will be sent containing the
                  information above. <br />
                  You can only notify the client every 24 hours.
                </Note>
              )}
            </Box>
          </Box>
        )}
        {missingExhibits.length == 0 && (
          <EmptyContent pb={5} data-test="empty-message">
            No more missing documents reported.
          </EmptyContent>
        )}
        <CommunicationEvents title="Documents Received / Comments" caseId={caseId} seperateItems={true} />
        <ChunkFileUploader ref={chunkFileUploaderRef} />
      </Container>
      <Loading showOnMutation={true} />
    </MissingDocumentContext.Provider>
  )
}

export { MissingDocumentClientView as default }
