import React from "react"
import { RenderElementProps } from "slate-react"
import { ListItemContentElement } from "../CustomEditor"

interface ListItemContentProps extends RenderElementProps {
  element: ListItemContentElement
}

export const ListItemContent: React.FC<ListItemContentProps> = ({ attributes, children }) => {
  return <div {...attributes}>{children}</div>
}
