export enum EXHIBIT_ITEM_TYPES {
  SECTION = "section",
  GROUP = "group",
  EXHIBIT = "exhibit",
  PARTITION = "partition",
}

export enum SECTIONS {
  PROVIDERS = "providers",
  FACTS = "facts",
}
