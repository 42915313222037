import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { useMutation, useQuery, useQueryClient } from "react-query"
// External Components
import { Box, Button, CircularProgress, FormControl, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import useUser from "../../hooks/useUser"
import clsx from "clsx"

// Internal Components
import { Loading } from "common/loading"
import PaginatedTable from "../../common/tables/PaginatedTable"
import FirmFilter from "../../common/tables/filters/FirmFilter"
import StatusFilter from "../RequestStatus/StatusFilter"
import UserFilter from "../../common/tables/filters/UserFilter"
import AlertSnackBar from "../../common/AlertSnackBar"
import { getColumns } from "./tableCells"

// Helpers
import { RequestContextProvider, useRequestContext } from "../context"
import { queryKeys } from "../../react-query/constants"
import { downloadAllRequestsCSV, getIsInSquad, getRequestsForFirm, updatePageSize } from "../../api"
import {
  canUserSeeCreditsUsedOnList,
  canUserFilterByAssignee,
  canUserFilterByFirm,
  canUserRequestNewDemand,
} from "../permissions/requestAction"
import { GenericError } from "../../common"
import { useSearchState } from "../../hooks/useSearchState"
import { INTERNAL_ROLES, INTERNAL_ROLES_VALUES, OSF } from "../../common/models/roles"
import { Alert } from "@material-ui/lab"
import { CreditTracker } from "./CreditTracker"
import { GetApp } from "@material-ui/icons"
import { useHandleMessages } from "common/messages/useHandleMessages"
import useFirm from "hooks/useFirm"

const useStyles = makeStyles(theme => ({
  requestsWrapper: {
    margin: theme.spacing(2, 0),
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  newRequestButton: {
    height: theme.spacing(7),
    minWidth: theme.spacing(25),
  },
  allRequestsButton: {
    minWidth: theme.spacing(25),
  },
  hideHyperlinks: {
    textDecoration: "none",
    color: "black",
  },
  linkToRequest: {
    display: "flex",
    width: "100%",
    transition: "font-weight 0.2s ease-out",
    "&:hover": {
      fontWeight: "bold",
    },
  },
  filterSelector: {
    width: theme.spacing(25),
    marginRight: theme.spacing(4),
  },
  filterLeft: {
    marginRight: "auto",
  },
  filterRight: {
    marginLeft: "auto",
  },
  searchBar: {
    width: theme.spacing(35),
    marginRight: theme.spacing(2),
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  filterContainer: {
    minWidth: theme.spacing(20),
    marginRight: theme.spacing(2),
  },
  plaintiffColumn: {
    maxWidth: "275px",
    overflowWrap: "anywhere",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "500px",
    },
  },
}))

const TableFilters = ({
  setPage,
  setFilterFirmId,
  setFilterStatusId,
  setFilterAssigneeId,
  setSearchQuery,
  filterFirmId,
  filterStatusId,
  filterAssigneeId,
  searchQuery,
}) => {
  const classes = useStyles()
  const { user, firm } = useRequestContext()
  const [allRequestsDownloading, setAllRequestsDownloading] = useState(false)
  const { showMessage } = useHandleMessages()

  const handleFirmIdFilterChange = e => {
    setPage(0)
    setFilterFirmId(e.target.value)
  }

  const handleStatusFilterChange = e => {
    setPage(0)
    setFilterStatusId(e.target.value)
  }

  const handleAssigneeFilterChange = e => {
    setPage(0)
    setFilterAssigneeId(e.target.value)
  }

  const handleSearchQueryChange = e => {
    setPage(0)
    // don't replace history when value is empty
    // this creates a distinct entry when clearing input
    // so you can use back button to return to input before clearing
    setSearchQuery(e.target.value, { replace: e.target.value ? true : false })
  }

  const handleRequestsCSVDownload = async () => {
    setAllRequestsDownloading(true)
    try {
      await downloadAllRequestsCSV()
    } catch (error) {
      showMessage({
        type: "error",
        message:
          "There was an error downloading the requests data. Try again later and if your error persists contact a dev.",
      })
    } finally {
      setAllRequestsDownloading(false)
    }
  }

  const canUserFilterByFirmPermission = !!canUserFilterByFirm(user.role)
  const canUserFilterByAssigneePermission = !!canUserFilterByAssignee(user.role)
  const canUserSeeCreditsUsed = canUserSeeCreditsUsedOnList(user.role, !!firm?.current_contract)

  return (
    <Box>
      <Box className={classes.actionContainer}>
        {canUserFilterByFirmPermission && (
          <FirmFilter
            onChange={handleFirmIdFilterChange}
            value={filterFirmId}
            className={classes.filterContainer}
          />
        )}
        <StatusFilter
          onChange={handleStatusFilterChange}
          value={filterStatusId}
          className={classes.filterContainer}
        />
        {canUserFilterByAssigneePermission && (
          <UserFilter
            onChange={handleAssigneeFilterChange}
            value={filterAssigneeId}
            className={classes.filterContainer}
            label={"Assignee"}
          />
        )}
        {canUserSeeCreditsUsed && <CreditTracker contract={firm.current_contract} />}

        <div className={clsx(classes.filterRight)}>
          <FormControl className={classes.searchBar}>
            <TextField
              label={"Search"}
              value={searchQuery ?? ""}
              onChange={handleSearchQueryChange}
              variant="outlined"
              placeholder="Search by plaintiff's name"
              data-test="searchbar"
            />
          </FormControl>

          {canUserRequestNewDemand(user.role) && (
            <Button
              data-test="new-request-button"
              className={classes.newRequestButton}
              color="primary"
              component={Link}
              to="new"
              variant="contained"
            >
              {`Request New Demand`}
            </Button>
          )}
        </div>
      </Box>
      {user.isRole(INTERNAL_ROLES.LEGALOPS_ADMIN) && (
        <Box textAlign="right">
          <Button
            className={classes.allRequestsButton}
            startIcon={allRequestsDownloading ? <CircularProgress size="1em" /> : <GetApp />}
            disabled={allRequestsDownloading}
            variant="contained"
            onClick={handleRequestsCSVDownload}
          >
            All Requests CSV
          </Button>
        </Box>
      )}
    </Box>
  )
}

const FiltersAndTable = () => {
  const { userIsLoading, user, queryClient } = useRequestContext()
  const classes = useStyles()

  const DEFAULT_SORT_FIELD = "updated_at"
  const ASC = "asc"
  const DESC = "desc"
  const [sortBy, setSortBy] = useState(DEFAULT_SORT_FIELD)
  const [sortDirection, setSortDirection] = useState(ASC)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(user.rowsPerPage)

  const [filterFirmId, setFilterFirmId] = useSearchState("firmId", 0, "number")
  const [filterStatusId, setFilterStatusId] = useSearchState("status", 0, "number")
  const [filterAssigneeId, setFilterAssigneeId] = useSearchState("assignee", 0, "number")
  const [searchQuery, setSearchQuery] = useSearchState("query", "", "string")

  const {
    data,
    error: requestError,
    isLoading,
  } = useQuery(
    [
      queryKeys.requests,
      {
        page: page + 1,
        pageSize,
        sortBy,
        sortDirection,
        firmId: filterFirmId,
        statusId: filterStatusId,
        assigneeId: filterAssigneeId,
        searchQuery: searchQuery,
      },
      user.role,
    ],
    getRequestsForFirm
  )

  const { data: isInSquadData } = useQuery([queryKeys.isInSquad], getIsInSquad, {
    enabled: INTERNAL_ROLES_VALUES.includes(user.role) || user.role === OSF,
  })

  const requests = data?.results ?? []
  const totalCount = data?.count ?? 0

  const mutateInternalUser = useMutation(updatePageSize, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.userSettings)
    },
  })

  const onPageSizeChange = value => {
    setPageSize(value)
    return mutateInternalUser.mutateAsync(value)
  }

  const onSortClick = id => {
    if (id !== sortBy) {
      setPage(0)
      setSortBy(id)
    } else {
      setSortDirection(sortDirection === DESC ? ASC : DESC)
    }
  }

  useEffect(() => {
    // once user is loaded, the state to their current configured page size
    setPageSize(user.rowsPerPage)
  }, [userIsLoading, user.rowsPerPage])

  if (requestError) {
    return <GenericError />
  }

  return (
    <>
      {isInSquadData?.is_squad_required && isInSquadData?.is_in_squad === false && (
        <Alert severity="warning">
          You are not currently assigned to a Squad.{" "}
          {user.role === OSF
            ? "You are not able to see any open tasks"
            : "No Takers will be able to work on your Requests"}
          . Message an admin on slack for support.
        </Alert>
      )}
      <TableFilters
        setPage={setPage}
        setFilterFirmId={setFilterFirmId}
        setFilterStatusId={setFilterStatusId}
        setFilterAssigneeId={setFilterAssigneeId}
        setSearchQuery={setSearchQuery}
        filterFirmId={filterFirmId}
        filterStatusId={filterStatusId}
        filterAssigneeId={filterAssigneeId}
        searchQuery={searchQuery}
      />

      <PaginatedTable
        columns={getColumns({ user, classes })}
        records={requests}
        userRole={user.role}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSortClick={onSortClick}
        page={page}
        pageSize={pageSize}
        onPageChange={setPage}
        onRowsPerPageChange={onPageSizeChange}
        totalCount={totalCount}
        emptyMessage="No Requests"
        recordsLoading={isLoading}
      />
    </>
  )
}

export function RequestsList() {
  const classes = useStyles()

  const { user, isLoading: userIsLoading } = useUser(queryKeys.userSettings)
  const { firm } = useFirm(user.firmId ?? 0)

  const location = useLocation()
  const [showDeleted, setShowDeleted] = useState(location?.state?.deleted || false)

  useEffect(() => {
    let timeoutId = null
    if (showDeleted) {
      timeoutId = setTimeout(() => {
        setShowDeleted(false)

        // Removing the 'deleted' flag from the window state
        delete window.history.state?.usr?.deleted
        window.history.replaceState(window.history.state, document.title)
      }, 3000)
      return () => {
        if (timeoutId) clearTimeout(timeoutId)
      }
    }
  }, [location, showDeleted])

  const queryClient = useQueryClient()

  return (
    <Box className={classes.requestsWrapper} data-test="request-list-page">
      <Loading showOnMutation={true} />
      <RequestContextProvider value={{ user, queryClient, userIsLoading, firm }}>
        <FiltersAndTable />
        <AlertSnackBar message={"Request successfully deleted"} open={showDeleted} />
      </RequestContextProvider>
    </Box>
  )
}
