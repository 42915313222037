import React from "react"
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useQuery } from "react-query"

import { queryKeys } from "../react-query/constants"
import { useFormContext } from "./context"
import { getHouseholdRelevantDate } from "../api"
import { getFormattedDateString } from "./utils"

const useStyles = makeStyles(theme => ({
  dateTableBox: {
    margin: theme.spacing(1, 2, 2, 2),
  },
  tableHeading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}))

interface Provider {
  pk: number
  first_contact: string
  last_contact: string | null
  name: string
}

interface RelevantDates {
  past_pain_suffering_date: string | null
  date_of_incident: string | null
  providers: Provider[]
  providers_last_contact_date: string | null
}

const RelevantDatesTable = (): JSX.Element => {
  const classes = useStyles()
  const { caseId } = useFormContext()
  const { data: relevantDates } = useQuery<RelevantDates>(
    [queryKeys.householdRelevantDates, caseId],
    getHouseholdRelevantDate,
    {
      enabled: !!caseId,
    }
  )

  if (
    !relevantDates?.date_of_incident &&
    !relevantDates?.past_pain_suffering_date &&
    !relevantDates?.providers?.length
  ) {
    return <></>
  }

  return (
    <Box className={classes.dateTableBox}>
      <Typography variant="h6" className={classes.tableHeading}>
        Possible values for start date and end date of past impairment:
      </Typography>
      <Table size="small">
        <TableBody>
          {relevantDates?.date_of_incident && (
            <TableRow>
              <TableCell component="th" scope="row">
                Incident date
              </TableCell>
              <TableCell align="right">{getFormattedDateString(relevantDates.date_of_incident)}</TableCell>
            </TableRow>
          )}
          {relevantDates?.past_pain_suffering_date && (
            <TableRow>
              <TableCell component="th" scope="row">
                Past Pain & Suffering end date
              </TableCell>
              <TableCell align="right">
                {getFormattedDateString(relevantDates.past_pain_suffering_date)}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {!!relevantDates?.providers?.length && (
        <>
          <Typography variant="subtitle1" className={classes.tableHeading}>
            Medical Providers dates
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left">Provider name</TableCell>
                <TableCell align="right">Date of first contact</TableCell>
                <TableCell align="right">Date of last contact</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {relevantDates.providers.map((provider: Provider) => (
                <TableRow key={provider.pk}>
                  <TableCell align="left">{provider.name}</TableCell>
                  <TableCell align="right">{getFormattedDateString(provider.first_contact)}</TableCell>
                  <TableCell align="right">{getFormattedDateString(provider?.last_contact)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </Box>
  )
}

export { RelevantDatesTable as default }
