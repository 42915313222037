import React, { useCallback } from "react"
import { PaginatedListFields } from "common/models/pagination"
import { PaginationItem } from "./PaginationItem"
import { StyledPagination } from "./styled"

interface PaginationProps extends PaginatedListFields {
  isLoading?: boolean
  onChange: (page: number) => void
}

export function Pagination({ pageCount, page, isLoading, onChange }: PaginationProps): JSX.Element {
  const handleChange = useCallback(
    (_, page: number) => {
      onChange(page)
    },
    [onChange]
  )

  return (
    <StyledPagination
      count={pageCount}
      page={page}
      disabled={isLoading}
      onChange={handleChange}
      shape="rounded"
      size="small"
      renderItem={PaginationItem}
    />
  )
}
