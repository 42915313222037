import React from "react"
import { ITEM_REF } from "./constants"
import { NestedListItem } from "./NestedListItem"

export function defaultRenderItem(renderContent, props) {
  return <NestedListItem renderContent={renderContent} {...props} />
}

export function defaultRenderEmptyPlaceholder() {
  return null
}

export function NestedList({
  data,
  renderItemContent,
  renderItem = defaultRenderItem,
  renderEmptyPlaceholder = defaultRenderEmptyPlaceholder,
}) {
  function getRef(item) {
    return item[ITEM_REF] || ITEM_REF
  }

  function renderNestedItem({ children, ...props }) {
    if (!children?.length) {
      return renderItem(renderItemContent, { ...props, children: [renderEmptyPlaceholder(props)] })
    }

    const childElements = children.map(child =>
      renderNestedItem(
        { ...child, [ITEM_REF]: getRef(child) },
        renderItemContent,
        renderItem,
        renderEmptyPlaceholder
      )
    )

    return renderItem(renderItemContent, { ...props, children: childElements })
  }

  return <>{data.map(item => renderNestedItem({ ...item, [ITEM_REF]: getRef(item) }))}</>
}
