import { AnnotatedExhibit, Duplicate, Split } from "./types"

interface UpdateAnnotatedExhibitProps {
  oldData?: AnnotatedExhibit[]
  exhibitId: number
  update: Partial<AnnotatedExhibit> | ((exhibit: AnnotatedExhibit) => Partial<AnnotatedExhibit>)
}

export const updateAnnotatedExhibits = ({
  oldData,
  exhibitId,
  update,
}: UpdateAnnotatedExhibitProps): AnnotatedExhibit[] => {
  // to satisfy typescript
  if (!oldData) return []

  return oldData.map(exhibit => {
    if (exhibit.pk !== exhibitId) {
      return exhibit
    }
    const updatesToCommit = typeof update === "function" ? update(exhibit) : update
    return {
      ...exhibit,
      ...updatesToCommit,
    }
  })
}

interface MapUpdatePartitionProps {
  partitions: Split[] | Duplicate[]
  partitionId: number
  update: Partial<Duplicate> | Partial<Split>
}

export const mapUpdatePartition = ({
  partitions,
  partitionId,
  update,
}: MapUpdatePartitionProps): Split[] | Duplicate[] => {
  return partitions.map(partition => {
    if (partition.pk !== partitionId) {
      return partition
    }
    return { ...partition, ...update }
  })
}

export const A_FIRST = -1
export const B_FIRST = 1
export const EQUAL = 0
type SortResult = typeof A_FIRST | typeof B_FIRST | typeof EQUAL
interface CompareablePageRange {
  start_page?: Nullable<number>
  end_page?: Nullable<number>
}
interface CompletePageRange {
  start_page: number
  end_page: number
}

const getCompletePageRange = (range: CompareablePageRange): CompletePageRange => ({
  start_page: range.start_page ?? NaN,
  end_page: range.end_page ?? NaN,
})

export const comparePageRanges = (rangeA: CompareablePageRange, rangeB: CompareablePageRange): SortResult => {
  const a = getCompletePageRange(rangeA)
  const b = getCompletePageRange(rangeB)

  if (Number.isNaN(a.start_page) && !Number.isNaN(b.start_page)) {
    return B_FIRST
  }

  if (!Number.isNaN(a.start_page) && Number.isNaN(b.start_page)) {
    return A_FIRST
  }

  if (a.start_page < b.start_page) {
    return A_FIRST
  }

  if (a.start_page > b.start_page) {
    return B_FIRST
  }

  if (a.end_page < b.end_page) {
    return A_FIRST
  }

  if (a.end_page > b.end_page) {
    return B_FIRST
  }

  if (Number.isNaN(a.end_page) && !Number.isNaN(b.end_page)) {
    return B_FIRST
  }

  if (!Number.isNaN(a.end_page) && Number.isNaN(b.end_page)) {
    return A_FIRST
  }

  return EQUAL
}
