export enum SETTINGS_ROUTE_PREFIX {
  PROFILE = "profile",
  FIRMS = "firms",
  ACCOUNTS = "accounts",
  CREDITS = "credits",
  LIBRARY = "library",
}

export interface NavLink {
  link: SETTINGS_ROUTE_PREFIX
  label: string
}
