import React, { useCallback, useContext, useReducer, useRef } from "react"
import { Message } from "./Message"
import { HandleMessagesProvider as HandleMessagesProviderComponent } from "./HandleMessagesProvider"
import { HandleMessagesContext } from "./HandleMessagesContext"

type HandleMessageArg = Message

interface HandleEmptyErrorArg {
  type: "error"
}

type HandleMessageCallback = (message: HandleMessageArg | HandleEmptyErrorArg, timeout?: number) => void

interface UseHandleMessagesCallbackReturn {
  showMessage: HandleMessageCallback
}

interface UseHandleMessagesProviderReturn {
  showMessage: HandleMessageCallback
  state: HandleMessagesState
  HandleMessagesProvider: React.FC
}

type UseHandleMessagesReturn = UseHandleMessagesCallbackReturn | UseHandleMessagesProviderReturn

interface ShowMessageAction {
  type: "showMessage"
  payload: {
    message: Message
    timeout: number
  }
}

interface HideMessageAction {
  type: "hideMessage"
}

type HandleMessagesAction = ShowMessageAction | HideMessageAction

interface ShowMessagesState {
  message: Message
  timeout: number
}

type HandleMessagesState = Nullable<ShowMessagesState>

type HandleMessagesReducer = (state: HandleMessagesState, action: HandleMessagesAction) => HandleMessagesState

const handleMessagesReducer: HandleMessagesReducer = (state, action) => {
  switch (action.type) {
    case "showMessage":
      return { ...action.payload }
    case "hideMessage":
      return null
    default:
      return state
  }
}

export function useHandleMessages(): UseHandleMessagesReturn {
  const messagesContext = useContext(HandleMessagesContext)
  const bubbleMessageRef = useRef(messagesContext?.handleMessage)
  const [state, dispatch] = useReducer(handleMessagesReducer, null)

  bubbleMessageRef.current = messagesContext?.handleMessage

  const handleMessage = useCallback<HandleMessageCallback>((payload, timeout = 10_000) => {
    const messagePayload: Message = {
      ...payload,
      message:
        (payload as HandleMessageArg).message ||
        "Something went wrong, try again and if your issue persists please contact our support team",
    }

    if (bubbleMessageRef.current) {
      return bubbleMessageRef.current(messagePayload, timeout)
    }

    dispatch({ type: "showMessage", payload: { message: messagePayload, timeout } })
  }, [])

  const HandleMessagesProvider: React.FC = useCallback(
    props => {
      return <HandleMessagesProviderComponent {...props} handleMessage={handleMessage} />
    },
    [handleMessage]
  )

  if (bubbleMessageRef.current) {
    return {
      showMessage: handleMessage,
    }
  }

  return {
    showMessage: handleMessage,
    state,
    HandleMessagesProvider,
  }
}
