import { Typography } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"
import { Alert, AlertTitle } from "@material-ui/lab"

export const EventMessageBox = styled(Alert)(({ theme }) => ({
  maxWidth: "42rem",
  lineHeight: "1.3125rem",
  padding: theme.spacing(1, 3),
}))

export const EventMessageTitle = styled(AlertTitle)({
  fontSize: "0.875rem",
  margin: 0,
  padding: 0,
})

export const EventMessageText = styled(Typography)({
  fontSize: "0.75rem",
  margin: 0,
  padding: 0,
})
