import React, { useEffect, useState } from "react"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { Box, Button, CircularProgress, Container, FormControlLabel, Switch } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"
import { useForm } from "react-hook-form"

import {
  getCase,
  getExhibitsOrder,
  deleteExhibitGroup,
  deleteExhibitGroupsBySection,
  updateExhibitsOrder,
  updateShouldCombineExhibits,
} from "../api"

import useFirm from "hooks/useFirm"
import { useHandleMessages } from "../common/messages/useHandleMessages"

import { SortableNestedList } from "../common/nested-list/SortableNestedList"
import { queryKeys } from "../react-query/constants"
import { EXHIBIT_ITEM_TYPES, SECTIONS } from "./Exhibits/enums"
import { deserializeExhibits, serializeExhibits } from "./Exhibits/utils"
import { ExhibitsListSection } from "./Exhibits/ExhibitsListSection"
import { ExhibitsListGroup } from "./Exhibits/ExhibitsListGroup"
import { ExhibitsListItem } from "./Exhibits/ExhibitsListItem"
import { canDrag, canDrop, canDropAsChild } from "./Exhibits/dragDropUtils"
import { sortAndGroupExhibitsByOptions } from "./Exhibits/utils"
import { MainTitle } from "../app/styled"
import { ExhibitOrganizationSelectors } from "settings/Firm/ExhibitOrganizationSelectors"
import { EXHIBIT_GROUPING_VALUES, EXHIBIT_SORTING_VALUES } from "settings/Firm/enums"
import { useFeatures, FEATURES } from "hooks/useFeatures"

const SortAndGroupContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingBottom: theme.spacing(3),
  borderBottom: `2px solid ${theme.palette.grey[500]}`,
}))

const SelectorContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(2),
  "& > *": {
    marginRight: theme.spacing(2),
  },
}))

const SortingLoaderContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, "auto", 2),
}))

const SortingLabel = styled(Box)(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: "400",
  marginBottom: theme.spacing(1),
}))

const DEFAULT_SORTING_VALUE = EXHIBIT_SORTING_VALUES.PER_PROVIDER_RECORDS_AND_BILLS
const DEFAULT_GROUPING_VALUE = EXHIBIT_GROUPING_VALUES.INDIVIDUAL_FILES

const ExhibitPage = () => {
  const { id: caseId } = useParams()
  const { showMessage } = useHandleMessages()
  const queryClient = useQueryClient()
  const [exhibits, setExhibits] = useState(null)
  const [caseFirm, setCaseFirm] = useState(null)
  const [isSorting, setIsSorting] = useState(false)
  const [combineExhibits, setCombineExhibits] = useState(false)
  const { isFeatureEnabled } = useFeatures()
  const isProviderAutofillEnabled = isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL)

  useQuery([queryKeys.case, caseId], getCase, {
    onSuccess: data => {
      setCaseFirm(data?.firm?.pk ?? null)
      setCombineExhibits(data?.combine_exhibits ?? false)
    },
  })

  const { firm } = useFirm(caseFirm)

  const { control, reset, watch } = useForm({
    defaultValues: {
      exhibit_sorting_option: firm?.exhibit_sorting_option ?? DEFAULT_SORTING_VALUE,
      exhibit_grouping_option: firm?.exhibit_grouping_option ?? DEFAULT_GROUPING_VALUE,
    },
    mode: "onChange",
  })

  useEffect(() => {
    reset({
      exhibit_sorting_option: firm?.exhibit_sorting_option ?? DEFAULT_SORTING_VALUE,
      exhibit_grouping_option: firm?.exhibit_grouping_option ?? DEFAULT_GROUPING_VALUE,
    })
  }, [firm, reset])

  const sortingOption = watch("exhibit_sorting_option")
  const groupingOption = watch("exhibit_grouping_option")

  const handleSortingError = () => {
    showMessage({
      message:
        "There was an error during the sorting and grouping of exhibits. Please try again or contact Engineering if the issue continues.",
      type: "error",
    })
  }

  const { isLoading } = useQuery([queryKeys.exhibits, caseId], async () => getExhibitsOrder({ caseId }), {
    onSuccess: async data => {
      setExhibits(deserializeExhibits(data.data, isProviderAutofillEnabled))
    },
  })

  const { mutateAsync: updateExhibitOrder } = useMutation(updateExhibitsOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.exhibits)
    },
    onError: handleSortingError,
    onSettled: () => {
      setIsSorting(false)
    },
  })

  const { mutateAsync: callDeleteExhibitGroup } = useMutation(deleteExhibitGroup, {
    onSuccess: () => queryClient.invalidateQueries(queryKeys.exhibits),
  })

  const { mutateAsync: deleteExhibitGroupsBySectionMutation } = useMutation(deleteExhibitGroupsBySection, {
    onSuccess: () => queryClient.invalidateQueries(queryKeys.exhibits),
    onError: () => {
      setIsSorting(false)
      handleSortingError()
    },
  })

  const { mutateAsync: setCombineExhibitsMutation } = useMutation(updateShouldCombineExhibits, {
    onSuccess: data => {
      setCombineExhibits(data.results?.combine_exhibits)
    },
  })

  const updateCombineExhibits = value => {
    return setCombineExhibitsMutation({ caseId, ce: value })
  }

  const deleteGroup = groupItem => {
    if (!groupItem.isNew) {
      callDeleteExhibitGroup({ caseId, groupId: groupItem.pk })
    }
    setExhibits(currentExhibits => {
      const groupSection = groupItem.section
      const sectionExhibitsIndex = currentExhibits.findIndex(value => value.section == groupSection)
      const sectionExhibits = currentExhibits[sectionExhibitsIndex]
      const sectionIndex = sectionExhibits.children.findIndex(
        value => (value.type == "group") & (value.pk == groupItem.pk)
      )
      sectionExhibits.children.splice(sectionIndex, 1)
      currentExhibits[sectionExhibitsIndex] = sectionExhibits

      return currentExhibits
    })
  }

  const handleExhibitSectionChange = updatedItem => {
    const itemIndex = exhibits.findIndex(exhibit => exhibit.pk === updatedItem.pk)
    const updatedExhibits = [...exhibits]

    updatedExhibits[itemIndex] = updatedItem

    setExhibits(updatedExhibits)
  }

  const handleExhibitGroupChange = async updatedItem => {
    for (const section of exhibits) {
      for (const [itemIndex, item] of section.children.entries()) {
        if (item.pk === updatedItem.pk) {
          section.children[itemIndex] = updatedItem
          const updatedExhibits = [...exhibits]
          setExhibits(updatedExhibits)
          const data = serializeExhibits(updatedExhibits)
          await updateExhibitOrder({ caseId, data })

          return
        }
      }
    }
  }

  const updateExhibits = async updatedExhibits => {
    const data = serializeExhibits(updatedExhibits)
    const result = await updateExhibitOrder({ caseId, data })

    setExhibits(deserializeExhibits(result.data))
  }

  function renderEmptyPlaceholder(item) {
    if (item.type === EXHIBIT_ITEM_TYPES.GROUP) {
      return (
        <div>
          <i>Add exhibits here to combine them.</i>
        </div>
      )
    }

    return null
  }

  function renderItemContent({ item }) {
    switch (item.type) {
      case EXHIBIT_ITEM_TYPES.SECTION:
        return <ExhibitsListSection item={item} onChange={handleExhibitSectionChange} />
      case EXHIBIT_ITEM_TYPES.GROUP:
        return <ExhibitsListGroup item={item} onChange={handleExhibitGroupChange} onDelete={deleteGroup} />
      case EXHIBIT_ITEM_TYPES.EXHIBIT:
      case EXHIBIT_ITEM_TYPES.PARTITION:
        return <ExhibitsListItem item={item} />
      default:
        return null
    }
  }

  function getBackgroundColor(props) {
    switch (props.type) {
      case EXHIBIT_ITEM_TYPES.SECTION:
        return "#F4F3F5"
      case EXHIBIT_ITEM_TYPES.GROUP:
        return "#E1E1E1"
      case EXHIBIT_ITEM_TYPES.EXHIBIT:
      case EXHIBIT_ITEM_TYPES.PARTITION:
        return "#C5C5C6"
      default:
        return "#FFFFFF"
    }
  }

  const handleSortAndGroupClick = async () => {
    setIsSorting(true)
    const sortedExhibits = sortAndGroupExhibitsByOptions(exhibits, sortingOption, groupingOption)

    await deleteExhibitGroupsBySectionMutation({ caseId, section: SECTIONS.PROVIDERS })
    updateExhibits(sortedExhibits)

    // Changing the value of the combine exhibits based on the dropdown
    if (groupingOption === EXHIBIT_GROUPING_VALUES.ONE_FILE && !combineExhibits) {
      updateCombineExhibits(true)
    } else if (groupingOption !== EXHIBIT_GROUPING_VALUES.ONE_FILE && combineExhibits) {
      updateCombineExhibits(false)
    }
  }

  if (isLoading) {
    return null
  }

  const hasSortingAndGroupingOptions = sortingOption && groupingOption

  return (
    <Container maxWidth="lg" data-test="exhibits-list">
      {hasSortingAndGroupingOptions && (
        <>
          <SortAndGroupContainer>
            <SortingLabel>
              Select the sorting and combining option below and click &#8220;Sort And Combine Exhibits&#8221;.
              The firm defaults are pre-selected.
            </SortingLabel>
            <SelectorContainer>
              <ExhibitOrganizationSelectors control={control} watch={watch} />

              <Box ml={1} mt="auto" mb="auto">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSortAndGroupClick}
                  disabled={isSorting}
                >
                  Sort And Combine Exhibits
                </Button>
              </Box>
            </SelectorContainer>
          </SortAndGroupContainer>

          <Box pt={2}>
            <FormControlLabel
              data-test="reorder-exhibits"
              control={<Switch checked={combineExhibits} color="primary" />}
              label="Combine exhibits in 1 PDF"
              onChange={event => updateCombineExhibits(event.target.checked)}
            />
          </Box>
        </>
      )}

      <Box display="flex" flexDirection="column">
        {isSorting ? (
          <SortingLoaderContainer>
            <CircularProgress size="10rem" />
            <MainTitle mt={2}>Sorting Exhibits</MainTitle>
          </SortingLoaderContainer>
        ) : (
          <SortableNestedList
            {...{
              data: exhibits,
              uniqueKey: "targetId",
              onUpdate: updateExhibits,
              renderEmptyPlaceholder,
              renderItemContent,
              canDrag,
              canDrop,
              canDropAsChild,
              getBackgroundColor,
            }}
          />
        )}
      </Box>
    </Container>
  )
}

export default ExhibitPage
