import "@emotion/react"
import { red, green, blueGrey } from "@material-ui/core/colors"
import { createTheme, Theme as MuiTheme } from "@material-ui/core/styles"

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    blue: {
      main: string
      light: string
    }
    dragging: {
      main: string
    }
    label: {
      ignore: string
      postpone: string
      action: string
      info: string
      warning: string
      pending: string
    }
    disabled: {
      main: string
    }
    button: {
      grey: string
    }
    mint: {
      main: string
      hover: string
      text: string
    }
    annotation: {
      pre_annotating: string
      annotating: string
      auto_complete: string
      complete: string
    }
  }
  interface PaletteOptions {
    blue: {
      main: string
      light: string
    }
    dragging: {
      main: string
    }
    label: {
      ignore: string
      postpone: string
      action: string
      info: string
      warning: string
      pending: string
    }
    disabled: {
      main: string
    }
    button: {
      grey: string
    }
    mint: {
      main: string
      hover: string
      text: string
    }
    annotation: {
      pre_annotating: string
      annotating: string
      auto_complete: string
      complete: string
    }
  }
}

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: ['"Inter"', "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#041D28",
      dark: "#02141C",
    },
    secondary: {
      main: "#F25C51",
    },
    blue: {
      main: "#177ba7",
      light: "#D5E4EE",
    },
    error: {
      main: red.A400,
    },
    success: {
      main: green[600],
    },
    background: {
      default: "#FFFFFF",
    },
    info: {
      main: "#177BA7",
    },
    dragging: {
      main: blueGrey.A100,
    },
    label: {
      ignore: "#000000",
      postpone: "#7D7D7D",
      action: "#5F0A87",
      info: "#3CD5AD",
      warning: "#B71C1C",
      pending: "#00B1FF",
    },
    disabled: {
      main: "#A0A0A0",
    },
    button: {
      grey: "#EBEBE6",
    },
    mint: {
      main: "rgb(60, 213, 173)",
      hover: "rgba(60, 213, 173, 0.3)",
      text: "#009b77",
    },
    annotation: {
      pre_annotating: "#f57c00",
      annotating: "#715c8e",
      auto_complete: "#00a186",
      complete: "#00a186",
    },
  },
  shape: {
    borderRadius: 3,
  },
  overrides: {
    MuiContainer: {
      maxWidthXl: {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 0,
        "@media (min-width: 1920px)": {
          maxWidth: "100%",
        },
      },
    },
  },
})

declare module "@emotion/react" {
  // eslint-disable-next-line
  export interface Theme extends MuiTheme {}
}

export default theme
