import React from "react"
import { Control, Controller, FieldErrors, FieldValues, Path } from "react-hook-form"
import { FormControlLabel, Checkbox as MuiCheckbox, Typography, CheckboxProps } from "@material-ui/core"

export interface CheckboxInputProps<TFields extends FieldValues> extends CheckboxProps {
  control: Control<TFields>
  // TODO: move to FieldPathWithValue with strict value type check
  // Blocker - MissingDocumentClientView
  name: Path<TFields>
  label?: string
  errors?: FieldErrors<TFields>
}

export function CheckboxInput<TFields extends FieldValues>({
  control,
  name,
  label,
  ...inputProps
}: CheckboxInputProps<TFields>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormControlLabel
            control={<MuiCheckbox checked={field.value} {...field} {...inputProps} />}
            label={
              <Typography variant="caption" display="block">
                {label}
              </Typography>
            }
          />
        )
      }}
    />
  )
}
