import React, { useCallback } from "react"
import { useMutation } from "react-query"
import { RevisionRequestForm } from "./RevisionRequestForm"
import { NewRevisionRequest, RevisionRequest, RevisionRequestData } from "common/models/revision-request"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { revisionService } from "api/services/revision"
import { PendingFile } from "common/form-components/files/types"
import { useFileUploader } from "common/file-uploader/useFileUploader"
import { isNullable } from "common/helpers/array"

interface RevisionRequestFormControllerProps {
  requestId: string
  request?: RevisionRequest
  onSubmitted?: (request: RevisionRequest) => void
  onUpdated?: (request: RevisionRequest) => void
  onFailed?: () => void
  onCancel?: () => void
}

export const RevisionRequestFormController = ({
  requestId,
  request,
  onSubmitted,
  onFailed,
  onCancel,
}: RevisionRequestFormControllerProps): JSX.Element => {
  const { showMessage } = useHandleMessages()
  const createRevisionRequestMutation = useMutation(revisionService.createRevisionRequest)
  const updateRevisionRequestMutation = useMutation(revisionService.updateRevisionRequest)
  const { uploadFiles } = useFileUploader()
  const handleSubmit = useCallback(
    async (nextRevisionRequest: NewRevisionRequest | RevisionRequestData, files: PendingFile[]) => {
      let revisionRequest: Awaited<ReturnType<typeof revisionService.createRevisionRequest>>

      try {
        const uploadResult = files.length ? await uploadFiles(files.map(({ file }) => file)) : []

        if (!Array.isArray(uploadResult)) {
          const failedFilenames = uploadResult.failedFiles.map(({ name }) => name)
          const message = `Failed to upload duplicate files: ${failedFilenames.join(", ")}`
          showMessage({ type: "error", message })

          throw new Error("Upload error")
        }

        if (isNullable(uploadResult)) {
          showMessage({ type: "error", message: "Upload failed for one or more files" })
          throw new Error("Upload error")
        }

        if (files.length) {
          nextRevisionRequest.setUploads(
            files.map(({ file, type }, i) => ({
              upload: uploadResult[i],
              name: file.name,
              type,
            }))
          )
        }

        if (nextRevisionRequest instanceof NewRevisionRequest) {
          revisionRequest = await createRevisionRequestMutation.mutateAsync({
            requestId,
            data: nextRevisionRequest,
          })
        } else {
          revisionRequest = await updateRevisionRequestMutation.mutateAsync({
            requestId,
            data: nextRevisionRequest,
          })
        }
      } catch (e) {
        showMessage({ type: "error", message: "Unable to create or update Revision Request" })

        return onFailed && onFailed()
      }

      onSubmitted && onSubmitted(revisionRequest)
    },
    [
      requestId,
      showMessage,
      createRevisionRequestMutation,
      updateRevisionRequestMutation,
      onFailed,
      onSubmitted,
      uploadFiles,
    ]
  )

  return <RevisionRequestForm onSubmit={handleSubmit} request={request} onCancel={onCancel} />
}
