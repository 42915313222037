import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core"
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react"
import { createNewChunkedUpload, uploadChunkedUpload, cndUploadChunkedUpload } from "../api"
import {
  CheckCircleRounded as CheckCircleRoundedIcon,
  Close as CloseIcon,
  Error as ErrorIcon,
  ExpandMore as ExpandMoreIcon,
  HighlightOffRounded as HighlightOffRoundedIcon,
} from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import { useHandleMessages } from "./messages/useHandleMessages"
import isNil from "lodash/isNil"
import truncate from "lodash/truncate"
import { CircularProgressWithLabel } from "./CircularProgressWithLabel"
import { VerticalCenterBox } from "./FlexBox"
import retry from "async-retry"
import pLimit from "p-limit"
import clsx from "clsx"

// 10MB
const SPLIT_LIMIT = 10 * 1024 * 1024

const STATUS_UPLOADING = "uploading"
const STATUS_COMPLETED = "uploaded"
const STATUS_FAILED = "failed"
const STATUS_ADDED = "added"
const STATUS_REMOVED = "removed"

const useStyles = makeStyles(theme => ({
  statusSuccess: {
    color: theme.palette.success.main,
  },
  statusError: {
    color: theme.palette.error.main,
  },
  accordionStatus: {
    fontSize: "0.9em",
    "& .MuiAccordionDetails-root": {
      paddingTop: "0px",
    },
  },
  summaryStatus: {
    minHeight: "1em !important",
    "& .MuiAccordionSummary-content": {
      marginBottom: "10px",
      marginTop: "10px",
    },
    "& MuiButtonBase-root": {
      flexDirection: "reverse-row",
    },
    "& .MuiIconButton-root.MuiAccordionSummary-expandIcon": {
      paddingLeft: 0,
    },
  },
  itemStatusContainer: {
    paddingLeft: 0,
    "& .MuiTypography-body2": {
      fontSize: "0.9em",
    },
  },
  statusIcon: {
    minWidth: "1em",
    marginRight: "0.5em",
    fontSize: "0.6em",
    fontWeight: "bold",
  },
  detailStatus: {
    maxHeight: "200px",
    maxWidth: "400px",
    minWidth: "200px",
    overflowX: "auto",
    overflowY: "auto",
    "& .MuiList-root": {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
    },
  },
  closeButton: {
    minWidth: "10px",
    color: theme.palette.action.active,
    padding: 0,
    marginRight: "1em",
    "& .MuiButton-label": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  removedItem: {
    textDecoration: "line-through",
  },
}))

let FILE_SLICE_METHOD = null

const isChunkingSupported = () => {
  FILE_SLICE_METHOD = null
  if ("Blob" in window) {
    FILE_SLICE_METHOD = Blob.prototype.slice || Blob.prototype.webkitSlice || Blob.prototype.mozSlice
  }

  return !!FILE_SLICE_METHOD
}
const CHUNKING_SUPPORT = isChunkingSupported()

export function handleChunkUploadResult(uploadedFiles, file, formData) {
  const uploaded = uploadedFiles.find(item => item && item.name === file.name && item.size === file.size)
  if (uploaded) {
    formData.append("upload_id", uploaded.id)
  } else {
    formData.append("file", file)
  }
}

const getChunkDataFromFile = (file, start, end) => {
  const blob = FILE_SLICE_METHOD?.call(file, start, end, file.type)

  if (blob) {
    blob.name = file.name
    blob.lastModified = file.lastModified
  }

  return blob
}

function findInUploadStatus(uploadStatus, file) {
  return uploadStatus.find(upItem => {
    return upItem.name === file.name && upItem.size === file?.size && upItem.status !== STATUS_REMOVED
  })
}

function getChunks(file) {
  const fileSize = file?.size
  const chunkCount = process.env.REACT_APP_CND_UPLOAD ? 1 : Math.ceil(fileSize / SPLIT_LIMIT)

  let chunks = []
  for (let i = 0; i < chunkCount; i++) {
    let end = (i + 1) * SPLIT_LIMIT
    if (end > fileSize) end = fileSize
    if (process.env.REACT_APP_CND_UPLOAD) end = fileSize

    const chunkDataFromFile = getChunkDataFromFile(file, i * SPLIT_LIMIT, end)
    chunks.push({
      index: i,
      chunkFile: chunkDataFromFile,
      file: file,
      chunkCount: chunkCount,
    })
  }
  return { chunkCount, chunks }
}

async function uploadChunk(
  chunk,
  uploadItemsStatus,
  setUploadItemsStatus,
  showMessage,
  onlyShowWhenUploading
) {
  let currentUploadingItem = findInUploadStatus(uploadItemsStatus, chunk.file)
  const uploadObj = chunk.uploadObj

  const formData = new FormData()
  formData.append("file", chunk.chunkFile)
  formData.append("offset", chunk.index)
  formData.append("upload", uploadObj.id)

  currentUploadingItem.status = STATUS_UPLOADING
  setUploadItemsStatus([...uploadItemsStatus])

  // retry up to 3 times
  try {
    await retry(
      async () => {
        if (process.env.REACT_APP_CND_UPLOAD) {
          const fetchRes = await cndUploadChunkedUpload({
            signUrl: uploadObj.sign_url,
            data: chunk.chunkFile,
            headers: {
              "content-type": chunk.chunkFile.type,
            },
          })
          return await fetchRes
        } else {
          const fetchRes = await uploadChunkedUpload({
            uploadId: uploadObj.id,
            data: formData,
          })
          return await fetchRes.json()
        }
      },
      {
        retries: 5,
      }
    )

    const uploaded = currentUploadingItem.uploaded
    uploaded.push(chunk.index)
    const chunkCount1 = chunk.chunkCount
    currentUploadingItem.progress = (uploaded.length / chunkCount1) * 100
    setUploadItemsStatus([...uploadItemsStatus])

    if (chunkCount1 === uploaded.length) {
      currentUploadingItem.status = STATUS_COMPLETED
      setUploadItemsStatus([...uploadItemsStatus])
    }
  } catch (e) {
    if (!onlyShowWhenUploading) {
      showMessage({
        type: "error",
        message: `Error happen when uploading ${chunk.file.name}, please try again: ${truncate(e.message, {
          length: 100,
        })}`,
      })
    }
    currentUploadingItem.status = STATUS_FAILED
    setUploadItemsStatus([...uploadItemsStatus])
  }
}

// eslint-disable-next-line react/display-name
export const ChunkFileUploader = forwardRef(({ onlyShowWhenUploading = false }, ref) => {
  const [uploadItemsStatus, setUploadItemsStatus] = useState([])
  const classes = useStyles()
  const { showMessage } = useHandleMessages()

  const [statusAccordionExpanded, setStatusAccordionExpanded] = useState(true)

  const doUpload = useCallback(
    async files => {
      // if chunking isnt supported, user must be using IE or haven't upgraded browser in ~ 10 years
      if (!CHUNKING_SUPPORT) {
        alert("Please upgrade to newer browser version in order to upload file")
      }

      let newItems = []
      for (const file of files) {
        const existingUploadStatus = findInUploadStatus(uploadItemsStatus, file)
        // only add new files that is not kept track yet
        if (isNil(existingUploadStatus)) {
          newItems.push({
            name: file.name,
            progress: 0,
            size: file.size,
            status: STATUS_ADDED,
            uploaded: [],
            file: file,
            uploadId: null,
            entityId: null,
          })
        }
      }

      // nothing new just return all completed
      if (newItems.length === 0) {
        return uploadItemsStatus.filter(item => item.status === STATUS_COMPLETED)
      }

      let newStatus = [...newItems, ...uploadItemsStatus]
      setUploadItemsStatus(newStatus)
      setStatusAccordionExpanded(true)

      let allChunks = []
      let allUploads = []

      const limitUploadCreation = pLimit(10)

      const uploadCreationTasks = newItems.map(item => {
        return limitUploadCreation(async () => {
          const file = item.file
          let { chunkCount, chunks } = getChunks(file)
          const uploadObj = await createNewChunkedUpload({
            data: {
              chunk_count: chunkCount,
              size: file.size,
              content_type: file.type,
            },
          })
          // Save uploadId for uploaded item so we can match files later
          item.uploadId = uploadObj.id
          allUploads.push({ ...uploadObj, ...item })
          chunks.forEach(item => (item.uploadObj = uploadObj))
          allChunks.push(...chunks)
        })
      })
      await Promise.all(uploadCreationTasks)

      const limitChunkUpload = pLimit(5)

      const chunkUploadTasks = allChunks.map(item =>
        limitChunkUpload(
          async () =>
            await uploadChunk(item, newStatus, setUploadItemsStatus, showMessage, onlyShowWhenUploading)
        )
      )
      await Promise.all(chunkUploadTasks)
      return allUploads
    },
    [uploadItemsStatus, showMessage, onlyShowWhenUploading]
  )

  const removeRecentlyUploaded = useCallback(
    entityId => {
      const itemIndex = uploadItemsStatus.findIndex(item => item.entityId === entityId)

      if (itemIndex !== -1) {
        const newUploadItemsStatus = [...uploadItemsStatus]
        newUploadItemsStatus[itemIndex] = { ...uploadItemsStatus[itemIndex], status: STATUS_REMOVED }
        setUploadItemsStatus(newUploadItemsStatus)
      }
    },
    [uploadItemsStatus]
  )

  const assignUploaded = useCallback(
    (uploadId, entityId) => {
      const itemToAssign = uploadItemsStatus.find(item => item.uploadId === uploadId)
      if (itemToAssign) itemToAssign.entityId = entityId
    },
    [uploadItemsStatus]
  )

  const uploaderInstance = useMemo(
    () => ({
      upload: doUpload,
      removeRecentlyUploaded,
      assignUploaded,
    }),
    [doUpload, removeRecentlyUploaded, assignUploaded]
  )

  useEffect(() => {
    ref.current = uploaderInstance
  }, [ref, uploaderInstance])

  const progressList = uploadItemsStatus.map(item => {
    const progress = item.progress
    const status = item.status
    let icon
    if (status === STATUS_ADDED) {
      icon = <CircularProgress size={25} />
    } else if (status === STATUS_UPLOADING) {
      if (progress === 0) {
        icon = <CircularProgress size={25} />
      } else {
        icon = <CircularProgressWithLabel size={25} variant="determinate" value={progress} />
      }
    } else if (status === STATUS_COMPLETED) {
      icon = <CheckCircleRoundedIcon className={classes.statusSuccess} />
    } else if (status === STATUS_REMOVED) {
      icon = <HighlightOffRoundedIcon />
    } else if (status === STATUS_FAILED) {
      icon = (
        <Tooltip title={"An error has occured. Click save to try again"}>
          <ErrorIcon className={classes.statusError} />
        </Tooltip>
      )
    }

    return (
      <ListItem
        className={classes.itemStatusContainer}
        dense={true}
        key={item.name + String(Math.random())}
        button
      >
        <ListItemIcon className={classes.statusIcon}>{icon}</ListItemIcon>
        <ListItemText
          primary={item.name}
          className={clsx(status === STATUS_REMOVED && classes.removedItem)}
        />
      </ListItem>
    )
  })

  const noUploading = uploadItemsStatus.filter(
    item => item.status === STATUS_UPLOADING || item.status === STATUS_ADDED
  ).length
  const noCompleted = uploadItemsStatus.filter(item => item.status === STATUS_COMPLETED).length
  const noFailed = uploadItemsStatus.filter(item => item.status === STATUS_FAILED).length
  let overallStatusLine = []
  if (noUploading > 0) {
    overallStatusLine.push(
      <span data-test="upload-completion" key={"uploading"}>
        Uploading {noUploading}{" "}
      </span>
    )
  } else {
    if (noCompleted > 0)
      overallStatusLine.push(
        <span data-test="upload-completion" key={"completed"}>
          {" "}
          {noCompleted} uploads completed{" "}
        </span>
      )

    if (noFailed > 0)
      overallStatusLine.push(
        <span data-test="upload-completion" key={"failed"}>
          {" "}
          {noFailed} uploads failed{" "}
        </span>
      )
  }

  const allSettled = noUploading === 0
  const handleClose = () => {
    setUploadItemsStatus([])
    setStatusAccordionExpanded(false)
  }

  if (allSettled && onlyShowWhenUploading) {
    return null
  }

  return (
    uploadItemsStatus.length > 0 && (
      <Box
        bgcolor="background.paper"
        color="text.primary"
        position="absolute"
        right="2%"
        bottom="2%"
        zIndex="modal"
      >
        <Accordion className={classes.accordionStatus} expanded={statusAccordionExpanded}>
          <AccordionSummary
            className={classes.summaryStatus}
            onClick={() => {
              setStatusAccordionExpanded(!statusAccordionExpanded)
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <VerticalCenterBox>
              {allSettled && (
                <Button
                  className={classes.closeButton}
                  size={"small"}
                  onClick={handleClose}
                  data-test="close-uploading-info"
                >
                  <CloseIcon sx={{ fontSize: 5 }} />
                </Button>
              )}
              {overallStatusLine}
            </VerticalCenterBox>
          </AccordionSummary>

          <AccordionDetails className={classes.detailStatus}>
            <List dense={true} component="nav" aria-label="contacts">
              {progressList}
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  )
})
