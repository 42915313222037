import React, { useCallback, forwardRef, Ref } from "react"
import NumberFormat from "react-number-format"
import { Box, TextField, InputAdornment, TextFieldProps } from "@material-ui/core"

interface CustomProps {
  onChange: (data: { target: { value: string } }) => void
  inputRef?: () => void
}

function NumberFormatCustom(props: CustomProps) {
  const { onChange, inputRef, ...other } = props

  const onValueChange = useCallback(
    ({ value }) => {
      onChange({ target: { value } })
    },
    [onChange]
  )

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={onValueChange}
      thousandSeparator
      isNumericString
    />
  )
}

export const CurrencyInput = forwardRef(function CurrencyInput(
  props: TextFieldProps,
  ref: Ref<HTMLInputElement>
) {
  return (
    <Box>
      <TextField
        {...props}
        ref={ref}
        variant={props.variant ?? "outlined"}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          inputComponent: NumberFormatCustom as any,
        }}
      />
    </Box>
  )
})
