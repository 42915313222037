import React, { useCallback, useEffect, useState } from "react"
import { Box, IconButton, TextField } from "@material-ui/core"
import { Cancel } from "@material-ui/icons"
import { useFormContext } from "demand/context"
import { DELETE_BILL, UPDATE_BILL } from "demand/Providers/reducer"
import { CurrencyInput } from "common/form-components"

export const BillRow = ({
  bill,
  validationErrors,
  dispatch,
  disabled,
  hasCollateralSourceRule,
  exhibitSection,
  providerId,
}) => {
  const { caseId } = useFormContext()
  const billId = bill.pk ?? bill.formId
  const [currentBill, updateCurrentBill] = useState(bill)

  const handleChangeDescription = useCallback(event => {
    updateCurrentBill(bill => ({ ...bill, description: event.target.value }))
  }, [])

  const handleChangeBilledAmount = useCallback(event => {
    updateCurrentBill(bill => ({
      ...bill,
      billed_amount: Number(event.target.value ?? 0),
    }))
  }, [])

  const handleChangeAdjustedAmount = useCallback(event => {
    updateCurrentBill(bill => ({
      ...bill,
      adjusted_amount: Number(event.target.value ?? 0),
    }))
  }, [])

  const handleBlur = useCallback(() => {
    dispatch({
      type: UPDATE_BILL,
      payload: { caseId, providerId, billId, updates: currentBill },
    })
  }, [dispatch, caseId, providerId, billId, currentBill])

  const handleBillDelete = useCallback(() => {
    dispatch({
      type: DELETE_BILL,
      payload: { caseId, providerId, billId },
    })
  }, [dispatch, caseId, providerId, billId])

  useEffect(() => {
    updateCurrentBill(bill)
  }, [bill])

  return (
    <>
      <TextField
        variant="outlined"
        value={currentBill.description ?? ""}
        data-test="bill-description"
        onChange={handleChangeDescription}
        disabled={disabled}
        error={!!validationErrors["description"]}
        helperText={validationErrors["description"]}
        onBlur={handleBlur}
      />
      <CurrencyInput
        value={currentBill.billed_amount ?? ""}
        data-test="billed-amount"
        onChange={handleChangeBilledAmount}
        disabled={disabled}
        error={!!validationErrors["billed_amount"]}
        helperText={validationErrors["billed_amount"]}
        onBlur={handleBlur}
      />
      {!hasCollateralSourceRule && (
        <CurrencyInput
          value={currentBill.adjusted_amount ?? ""}
          data-test="adjusted-amount"
          onChange={handleChangeAdjustedAmount}
          disabled={disabled}
          error={!!validationErrors["adjusted_amount"]}
          helperText={validationErrors["adjusted_amount"]}
          onBlur={handleBlur}
        />
      )}
      {exhibitSection}
      <Box>
        <IconButton onClick={handleBillDelete} disabled={disabled}>
          <Cancel />
        </IconButton>
      </Box>
    </>
  )
}
