import { cloneDeep, isEqual } from "lodash"

export const updateImmutable = (provider, update) => ({
  ...provider,
  ...update,
})

export function getFilteredCodes(codes) {
  if (!codes) {
    return []
  }

  return codes.filter(Boolean)
}

const PROVIDER_MODEL_FIELDS_NAME_FOR_DEBOUNCED_UPDATE = ["details", "details_json", "name"]

const PROVIDER_MODEL_FIELDS_NAME_FOR_INSTANT_UPDATE_IF_LENGTH_CHANGED = ["cpt_codes", "icd_codes"]

const PROVIDER_MODEL_FIELDS_NAME_FOR_INSTANT_UPDATE_IF_SOMETHING_IS_CHAGNED = [
  "is_ongoing_appointment",
  "include_table",
  "first_contact",
  "last_contact",
  "visit_count",
  "is_one_day_appointment",
  "bills",
]

const setUnnecessaryFieldsAsNull = bill => {
  bill.net_amount = null

  if (bill.exhibit) {
    bill.exhibit = null
  }
}

export const shouldUpdateWithDebounce = (previousProvider, currentProvider) => {
  if (!previousProvider || !currentProvider) {
    return false
  }

  if (
    PROVIDER_MODEL_FIELDS_NAME_FOR_INSTANT_UPDATE_IF_LENGTH_CHANGED.some(
      fieldName => previousProvider[fieldName].length !== currentProvider[fieldName].length
    )
  ) {
    return true
  }

  return PROVIDER_MODEL_FIELDS_NAME_FOR_DEBOUNCED_UPDATE.some(fieldName => {
    return !isEqual(previousProvider[fieldName], currentProvider[fieldName])
  })
}

export const shouldUpdateImmideately = (previousProvider, currentProvider) => {
  if (!previousProvider || !currentProvider) {
    return false
  }

  if (previousProvider.bills.length !== currentProvider.bills.length) {
    return true
  }

  return PROVIDER_MODEL_FIELDS_NAME_FOR_INSTANT_UPDATE_IF_SOMETHING_IS_CHAGNED.some(fieldName => {
    const previous = cloneDeep(previousProvider[fieldName])
    const current = cloneDeep(currentProvider[fieldName])

    if (fieldName === "bills") {
      if (previous.some(({ formId }) => !!formId) || current.some(({ formId }) => !!formId)) {
        return false
      }

      previous.forEach(setUnnecessaryFieldsAsNull)
      current.forEach(setUnnecessaryFieldsAsNull)
    }

    const res = !isEqual(previous, current)

    return res
  })
}
