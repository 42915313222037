import React, { useContext, useMemo } from "react"
import clsx from "clsx"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import { Box, IconButton, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { DragIndicator, ExpandLess, ExpandMore } from "@material-ui/icons"
import LinkButton from "common/buttons/LinkButton"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { SET_EDITING, TOGGLE_OPEN, Action } from "demand/Providers/reducer"
import { amountInDollars, dateDisplay } from "utils"
import { useFeatures, FEATURES } from "hooks/useFeatures"

import { Provider } from "../types"
import { LeftNavContext, useFormContext } from "demand/context"
import ProviderRowLabel from "./ProviderRowLabel"

import { useMutation } from "react-query"
import { setProviderReviewedAtDate } from "api"

interface Props {
  active: boolean
  provider: Provider
  dispatch: React.Dispatch<Action>
  dragHandleProps: DraggableProvidedDragHandleProps
  updates: { [key: string]: string }[]
}

const useStyles = makeStyles(theme => ({
  providerRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  },
  activeProviderRow: {
    backgroundColor: theme.palette.blue.light,
  },
  providerDragIndicator: {
    fontSize: "1.9rem",
    marginRight: theme.spacing(4),
  },
  providerName: {
    fontWeight: "bold",
    width: "200px",
    marginRight: theme.spacing(4),
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  providerFirstContact: {
    marginRight: theme.spacing(6),
  },
  providerTotal: {
    marginRight: theme.spacing(4),
  },
  providerStatusText: {
    fontSize: "1rem",
    marginRight: theme.spacing(4),
  },
  editProvider: {
    marginRight: theme.spacing(4),
  },
  expandToggle: {
    color: theme.palette.text.primary,
  },
}))

export const ProviderCollapsedRow = ({
  dispatch,
  provider,
  dragHandleProps,
  active,
  updates,
}: Props): JSX.Element => {
  const { caseId } = useFormContext()
  const classes = useStyles()
  const { showMessage } = useHandleMessages()
  const { isFeatureEnabled } = useFeatures()
  const { setLeftNavTabIsExpanded } = useContext(LeftNavContext)

  const setReviewedAtMutation = useMutation(setProviderReviewedAtDate, {
    onError: () => {
      showMessage({
        type: "error",
        message: "There was an error setting the reviewed at time for the Provider.",
      })
    },
  })

  const handleEditProvider = () => {
    isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL) &&
      setReviewedAtMutation.mutate({
        caseId: caseId,
        providerId: provider.pk,
      })
    setLeftNavTabIsExpanded(false)
    dispatch({ type: SET_EDITING, payload: { id: provider.pk, caseId } })
  }

  const handleOpen = () => {
    isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL) &&
      setReviewedAtMutation.mutate({
        caseId: caseId,
        providerId: provider.pk,
      })
    dispatch({
      type: TOGGLE_OPEN,
      payload: { id: provider.pk },
    })
  }

  const billsSum = useMemo(() => {
    if (!provider.bills || !provider.bills.length) {
      return 0
    }

    const sumOfBills = provider.bills.reduce((currentSum, bill) => {
      if (bill.billed_amount) {
        return currentSum + Number(bill.billed_amount)
      }

      return currentSum
    }, 0)
    return amountInDollars(sumOfBills)
  }, [provider.bills])

  return (
    <Box className={clsx(classes.providerRow, active && classes.activeProviderRow)}>
      <Box display="flex" alignItems="center">
        <Box {...dragHandleProps} data-test="drag-indicator">
          <DragIndicator className={classes.providerDragIndicator} />
        </Box>
        <Typography className={classes.providerName}>{provider.name || "Name"}</Typography>
        <Typography className={classes.providerFirstContact}>
          {dateDisplay(provider.first_contact)}
        </Typography>
        <Typography className={classes.providerTotal}>{billsSum}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <ProviderRowLabel provider={provider} updates={updates} />
        {active ? (
          <Box className={classes.providerStatusText}>Editing...</Box>
        ) : (
          <LinkButton
            className={clsx(classes.editProvider)}
            onClick={handleEditProvider}
            data-test="edit-provider-button"
          >
            Edit Provider
          </LinkButton>
        )}
        <IconButton onClick={handleOpen} data-test="expand-toggle">
          {provider.open ? (
            <ExpandLess className={classes.expandToggle} fontSize="large" />
          ) : (
            <ExpandMore className={classes.expandToggle} fontSize="large" />
          )}
        </IconButton>
      </Box>
    </Box>
  )
}
