import React, { useCallback, useEffect, useMemo, useState } from "react"
import { queryKeys } from "react-query/constants"
import { useQuery } from "react-query"
import { Attributes as AttributesType } from "common/types/attributes"
import {
  AttributeFiltersData,
  SectionAndAttributesFilter,
  SectionWithAttributes,
} from "common/attributes-filter"
import { TextField } from "@material-ui/core"
import { libraryVariableGroupService } from "api/services/library-variable"
import { Loading } from "common/loading"
import { createFilterOptions } from "@mui/material"
import { isUndefined, uniqBy } from "lodash"
import { LibraryVariableGroupDefinition } from "common/types/libraryVariables"
import { Autocomplete, UseAutocompleteProps } from "@material-ui/lab"
import { LibraryVariableFormData as LibraryVariableFormDataType } from "./types"
import { StyledEditorWrapper, StyledTextField } from "./styled"

interface TemplateFormProps {
  attributes: AttributesType
  onChange: (data: LibraryVariableFormDataType) => void
  highlightEmptyFields: boolean
  initialAttributeValues?: AttributeFiltersData
  initialVariableContent?: string
  initialGroupId?: PrimaryKey
  initialGroupName?: string
  error?: boolean
}
interface Option {
  title: string
  groupId: Nullable<PrimaryKey>
  inputValue?: string
}

const filter = createFilterOptions<Option>()
const INITIAL_SECTION_WITH_ATTIRIBUTES: SectionWithAttributes = { section: null, attributeValues: {} }
const INITIAL_VARIABLE_CONTENT = ""

const getOptionLabel = (option: string | Option) => {
  // Value selected with enter, right from the input
  if (typeof option === "string") {
    return option
  }
  // Add "xxx" variable option created dynamically
  if (option.inputValue) {
    return option.title
  }
  // Regular option
  return option.title
}

const filterOptions: UseAutocompleteProps<Option, undefined, undefined, true>["filterOptions"] = (
  options,
  params
) => {
  const filtered = filter(options, params)

  if (!filtered.length && params.inputValue !== "") {
    filtered.push({
      title: `Add "${params.inputValue}"`,
      groupId: null,
      inputValue: params.inputValue,
    })
  }

  return filtered
}
const getOptions = (variableGroups: LibraryVariableGroupDefinition[] | undefined) => {
  if (!variableGroups) return []

  return uniqBy(
    variableGroups.map(variableGroup => ({
      title: variableGroup.name,
      groupId: variableGroup.id,
    })),
    ({ title }) => title
  )
}

export function LibraryVariableFormData({
  attributes,
  onChange,
  error,
  initialAttributeValues,
  initialVariableContent,
  initialGroupId,
  initialGroupName,
  highlightEmptyFields,
}: TemplateFormProps): JSX.Element {
  const [sectionWithAttributes, setSectionWithAttributes] = useState(INITIAL_SECTION_WITH_ATTIRIBUTES)
  const [content, setContent] = useState(initialVariableContent || INITIAL_VARIABLE_CONTENT)
  const initialValue =
    !isUndefined(initialGroupId) && !isUndefined(initialGroupName)
      ? { title: initialGroupName, groupId: initialGroupId }
      : null
  const [value, setValue] = React.useState<Option | null>(initialValue)

  const { data: variableGroups } = useQuery(
    [queryKeys.libraryVariableGroups],
    libraryVariableGroupService.getVariableGroupList
  )

  const onChangeHandler = useCallback((event, newValue) => {
    if (typeof newValue === "string") {
      return setValue({ title: newValue.trim(), groupId: null })
    }

    if (newValue && newValue.inputValue) {
      return setValue({ title: newValue.inputValue.trim(), groupId: null })
    }

    setValue(newValue)
  }, [])

  const renderInput = useCallback(
    params => (
      <TextField
        {...params}
        fullWidth
        variant={"outlined"}
        margin="none"
        error={error || (highlightEmptyFields && !value)}
        label="Variable group"
      />
    ),
    [highlightEmptyFields, value, error]
  )

  const variableGroupOptions = useMemo<Option[]>(() => getOptions(variableGroups), [variableGroups])

  const handleChangeValue = useCallback(e => {
    setContent(e.target.value)
  }, [])

  useEffect(() => {
    let groupId = null
    let name = ""

    if (value) {
      groupId = value.groupId
      name = value.title
    }

    onChange({ sectionWithAttributes, content, groupId, name })
  }, [onChange, sectionWithAttributes, content, value])

  if (!variableGroups) return <Loading show label="Loading variables..." />

  return (
    <>
      <SectionAndAttributesFilter
        showSection={false}
        initialAttributeValues={initialAttributeValues}
        attributes={attributes}
        onChange={setSectionWithAttributes}
        error={error}
      />
      <StyledEditorWrapper>
        <Autocomplete
          fullWidth
          freeSolo
          blurOnSelect
          clearOnBlur
          value={value}
          onChange={onChangeHandler}
          getOptionLabel={getOptionLabel}
          filterOptions={filterOptions}
          options={variableGroupOptions}
          renderInput={renderInput}
        />
        <StyledTextField
          fullWidth
          variant="outlined"
          value={content}
          error={highlightEmptyFields && !content}
          onChange={handleChangeValue}
          label="Variable value"
        />
      </StyledEditorWrapper>
    </>
  )
}
