import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { getUsStates } from "api"
import React from "react"
import { useQuery } from "react-query"
import { queryKeys } from "react-query/constants"

type USStateObject = {
  key: string
  display: string
}

type StateAutocompleteProps = {
  multiple?: boolean
  value: string | string[]
  id: string
  onChange: (event: React.ChangeEvent, value: string | string[]) => void
}

const statesToMap = (states: USStateObject[]): Record<string, string> => {
  const map: Record<string, string> = {}
  states.forEach(state => (map[state.key] = state.display))
  return map
}

const statesToKeys = (states: USStateObject[]): string[] => states.map(state => state.key)

const StateAutocomplete: React.FC<StateAutocompleteProps> = ({ value, multiple, id, onChange }) => {
  const { isLoading, data, isError } = useQuery<{ results: USStateObject[] }>(
    [queryKeys.usStates],
    getUsStates
  )
  const states = data?.results
  const stateKeys = statesToKeys(states ?? [])
  const stateMap = statesToMap(states ?? [])

  return (
    <Autocomplete<string, boolean | undefined, boolean | undefined, boolean | undefined>
      multiple={multiple}
      id={id}
      data-test={id}
      options={stateKeys ?? []}
      loading={isLoading}
      value={value}
      filterSelectedOptions={multiple}
      // @ts-expect-error mui autocomplete types were too hard to figure out, but this works
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          label={multiple ? "Select states" : "Select a state"}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          helperText={isError && "Error loading states. Please try again shortly."}
        />
      )}
      getOptionLabel={option => stateMap[option]}
    />
  )
}

export default StateAutocomplete
