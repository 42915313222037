import { ROOT_ITEM_KEY, ITEM_REF } from "../constants"

export function getItemProjection(item, index, parent, projection, uniqueKey) {
  const itemId = String(item[uniqueKey] || `${parent.id}_${index}`)
  const projectedItem = { ...item, id: itemId, children: [], [ITEM_REF]: item }

  if (parent) {
    parent.children.push(itemId)
  }

  projection[itemId] = projectedItem

  if (!item.children) {
    return projection
  }

  return item.children.reduce(
    (data, child, childIndex) => getItemProjection(child, childIndex, projectedItem, data, uniqueKey),
    projection
  )
}

export function getFlatDataProjection(data, uniqueKey) {
  return getItemProjection({ [uniqueKey]: ROOT_ITEM_KEY, children: data }, 0, null, {}, uniqueKey)
}

export function getItemFromProjection(projectedData, itemId) {
  const projectedItem = projectedData[itemId]
  const item = projectedItem[ITEM_REF]

  item.children = projectedItem.children.map(childId => getItemFromProjection(projectedData, childId))

  return item
}

export function getDataFromProjection(projectedData) {
  return projectedData[ROOT_ITEM_KEY].children.map(childId => getItemFromProjection(projectedData, childId))
}

export function normalizeItemFromProjection(projectedData, itemId) {
  const projectedItem = { ...projectedData[itemId] }

  projectedItem.children = projectedItem.children.map(childId =>
    normalizeItemFromProjection(projectedData, childId)
  )

  return projectedItem
}

export function normalizeFromProjectionWithRef(projectedData) {
  return projectedData[ROOT_ITEM_KEY].children.map(childId =>
    normalizeItemFromProjection(projectedData, childId)
  )
}

export function findParent(data, itemId) {
  const result = Object.values(data).find(item => item.children.includes(itemId))
  return result
}

export function findFurthestParent(data, itemId) {
  const parent = findParent(data, itemId)
  return parent.id === ROOT_ITEM_KEY ? data[itemId] : findFurthestParent(data, parent.id)
}
