import { useQuery, useQueryClient } from "react-query"
import { queryKeys as defaultQueryKeys } from "../react-query/constants"
import { Case } from "case/types"
import { getCase } from "api"

interface UseCaseReturn {
  // case is a reserved word so we use caseObj
  caseObj: Nullable<Case>
  updateCache: (firm: Partial<Case>) => unknown
  isLoading: boolean
}

export default function useCase(
  caseId: number | string,
  queryKey: string = defaultQueryKeys.case
): UseCaseReturn {
  const queryClient = useQueryClient()
  const hasValidCaseId = !!caseId

  const { data: caseObj, isLoading } = useQuery(
    [queryKey, caseId],
    async (): Promise<Nullable<Case>> => {
      return await getCase({ queryKey: [null, caseId] })
    },
    {
      enabled: hasValidCaseId,
    }
  )

  const updateCache = (newCase: Partial<Case>) => {
    queryClient.setQueryData([queryKey, caseId], (oldCase: Partial<Case> | undefined) => {
      if (oldCase === undefined) return newCase

      return {
        ...oldCase,
        ...newCase,
      }
    })
  }

  return {
    caseObj: caseObj ?? null,
    isLoading,
    updateCache,
  }
}
