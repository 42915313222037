import React, { useCallback, useEffect, useState, useMemo } from "react"
import { format } from "date-fns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { TextFieldProps } from "@material-ui/core"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { isValidDate } from "utils"
import { StyledTextField } from "./styled"

type DateValue = Nullable<Date>
type InitialValue = Nullable<string>

interface DateFieldProps {
  initialValue: InitialValue
  label?: Nullable<string>
  onChange: (date: InitialValue) => void
  onClose?: () => void
  disabled?: boolean
  className?: string
  // need to specify this type more accurate
  fieldProps?: {
    id?: string
    name?: string
    margin?: TextFieldProps["margin"]
    size?: TextFieldProps["size"]
    autoFocus?: boolean
    fullWidth?: boolean
    placeholder?: string
  }
  error?: boolean
  helperText?: string
  dataTest?: string
}

export const DateField = ({
  initialValue,
  label,
  onChange,
  onClose,
  disabled = false,
  className,
  error,
  helperText,
  fieldProps,
  dataTest,
}: DateFieldProps): JSX.Element => {
  const formattedInitialValue = useMemo(() => {
    if (initialValue && initialValue.replaceAll) {
      return initialValue.replaceAll("-", "/")
    }

    return null
  }, [initialValue])
  const [value, setValue] = useState<DateValue>(
    formattedInitialValue ? new Date(formattedInitialValue) : null
  )
  const [editing, setEditing] = useState<boolean>(false)
  const checkValueAndChange = useCallback(
    (newValue: Date) => {
      const year = newValue.getFullYear()

      if (year >= 1900 && year <= 2300) {
        onChange(format(newValue, "yyyy-MM-dd"))
      }
    },
    [onChange]
  )

  const handleChange = useCallback(
    newValue => {
      setValue(newValue)

      if (!newValue) return onChange(null)

      if (newValue instanceof Date && newValue.valueOf()) {
        checkValueAndChange(newValue)
      }
    },
    [onChange, checkValueAndChange]
  )
  const onFocus = useCallback(() => setEditing(true), [])
  const onBlur = useCallback(() => setEditing(false), [])

  useEffect(() => {
    if (editing) return

    if (!initialValue && value) return setValue(null)

    if (!initialValue || !formattedInitialValue) return

    if (!value || !isValidDate(value) || initialValue !== format(value, "yyyy-MM-dd")) {
      setValue(new Date(formattedInitialValue))
    }
  }, [value, initialValue, editing, formattedInitialValue])

  const renderInput = useCallback(
    params => {
      return (
        <StyledTextField
          {...params}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          onBlur={onBlur}
          onFocus={onFocus}
          error={error}
          className={className}
          helperText={helperText}
          {...fieldProps}
          inputProps={{ ...params.inputProps, "data-test": dataTest }}
        />
      )
    },
    [className, dataTest, error, fieldProps, helperText, onBlur, onFocus]
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        value={value}
        onChange={handleChange}
        onClose={onClose}
        inputFormat="MM/dd/yyyy"
        disabled={disabled}
        minDate={new Date("1900/01/01")}
        maxDate={new Date("2300/01/01")}
        renderInput={renderInput}
      />
    </LocalizationProvider>
  )
}
