import React from "react"
import { styled } from "@material-ui/core/styles"
import { Box, Chip, Paper } from "@material-ui/core"

export const SectionHeading = styled(Box)({
  fontSize: "18px",
  fontWeight: "bold",
  lineHeight: 1.89,
  letterSpacing: "-0.36px",
})

export const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 6),
  borderTop: "2px solid black",
}))

export const UploadedFiles = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(6),
}))

export const NoItems = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(2),
}))

export const AppointmentsGrid = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: theme.spacing(1),
}))

export const BillRow = ({ children }) => <>{children}</>

export const Label = styled(Box)({
  fontWeight: "bold",
})

export const Italic = styled(Box)({
  fontStyle: "italic",
})

export const CodeList = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  flexWrap: "wrap",
}))

export const StyledInputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),
  flexWrap: "wrap",
  marginBottom: theme.spacing(5),
  justifyContent: "space-between",
}))

export const CodeChip = styled(Chip)(() => ({
  width: "230px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  borderRadius: "4px",
}))
