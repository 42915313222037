import React from "react"
import { IconButton } from "@material-ui/core"
import { Delete as DeleteIcon } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  list: {
    gridColumn: "1 / 3",
  },
  filesRow: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 10% 1fr",
    lineHeight: "48px",
  },
  header: {
    fontWeight: "bold",
  },
}))

export function RequestFilesList({ files = [], fileOptions, deleteFile }) {
  const classes = useStyles()
  return (
    <div className={classes.list}>
      <div className={classes.filesRow}>
        <div className={classes.header}>Name</div>
        <div className={classes.header}>File Type</div>
        <div />
      </div>
      {files?.map((item, i) => (
        <div className={classes.filesRow} key={i}>
          <div>
            {item.name}
            {item.number_of_pages && <> - Page Count: {item.number_of_pages}</>}
          </div>
          <div>{fileOptions[item.type]}</div>
          <div>
            <IconButton aria-label="delete" onClick={() => deleteFile(item)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  )
}
