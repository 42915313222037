import React from "react"
import { Box, Container, Paper, Tooltip } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"

import { useQuery } from "react-query"
import { queryKeys } from "../../react-query/constants"
import useUser from "../../hooks/useUser"
import { getFirmsCurrentContract } from "../../api"

import { formatDate } from "../../utils"

import { RadialProgress } from "../../common/RadialProgress/RadialProgress"
import { HelpOutline } from "@material-ui/icons"
import { roundDown } from "common/math"

const PaperContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(3, 4),
  width: "400px",
}))

const OverflowText = styled(Box)({
  fontSize: "14px",
  fontStyle: "italic",
})

export const Credits = (): React.ReactElement => {
  const { user, isLoading } = useUser()

  const { data: contract } = useQuery(
    [queryKeys.firmContract],
    async () => {
      return getFirmsCurrentContract({ firmId: user.firmId })
    },
    {
      enabled: !!user.firmId && !isLoading,
    }
  )

  if (!contract) {
    return <></>
  }

  const endOfMonth = formatDate(contract.end_of_month, "MM/dd/yyyy", true)
  const usedAllCredits = contract.current_month_credits_used >= contract.monthly_credits

  return (
    <Container data-test="credits-container">
      <Box fontSize="22px" fontWeight="bold" lineHeight={1.55} mb={4}>
        Credits
      </Box>
      <PaperContainer>
        <Box display="flex" alignItems="center" fontSize="20px" fontWeight="bold" lineHeight={1.7}>
          Credits Used This Month&nbsp;
          <Tooltip title="Credits below may be rounded down and do not include rollover credits.">
            <HelpOutline />
          </Tooltip>
        </Box>
        <Box fontSize="16px" display="flex">
          <Box fontWeight="bold" mr={1}>
            Credits reset on:
          </Box>
          <Box>{endOfMonth}</Box>
        </Box>
        <Box mt={4} display="flex" mr="auto" ml="auto" mb={4}>
          <RadialProgress
            used={roundDown(contract.current_month_credits_used, { decimalPlaces: 2 })}
            total={roundDown(contract.monthly_credits, { decimalPlaces: 2 })}
          />
        </Box>
        {usedAllCredits && <OverflowText>Keep sending requests to use your rollover credits!</OverflowText>}
      </PaperContainer>
    </Container>
  )
}
