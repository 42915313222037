import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = (color: string, disabled: boolean) =>
  makeStyles(theme => ({
    container: {
      display: "flex",
      flexDirection: "column",
      color: disabled ? theme.palette.disabled.main : color,
    },
    topContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    dot: {
      height: theme.spacing(1),
      width: theme.spacing(1),
      borderRadius: "50%",
      margin: theme.spacing("auto", theme.spacing(0.1), "auto", theme.spacing(0)),
    },
    filledDot: {
      backgroundColor: disabled ? theme.palette.disabled.main : color,
    },
    nonFilledDot: {
      border: `0.17rem solid ${disabled ? theme.palette.disabled.main : color}`,
    },
    statusText: {
      color: disabled ? theme.palette.disabled.main : color,
    },
  }))

interface StatusLabelInterface {
  status: string
  text: string
  color: string
  filled: boolean
  showDot?: boolean
  subtext?: string
  disabled?: boolean
}

const StatusLabel = ({
  status,
  text,
  color,
  filled,
  showDot = true,
  subtext = "",
  disabled = false,
}: StatusLabelInterface): JSX.Element => {
  const classes = useStyles(color, disabled)()

  return (
    <Box className={classes.container}>
      <Box className={classes.topContainer} data-test={`status-label-${status}`}>
        {showDot && (
          <Box className={`${classes.dot} ${filled ? classes.filledDot : classes.nonFilledDot}`}></Box>
        )}
        <Box className={classes.statusText}>{text}</Box>
      </Box>
      {subtext && <Box>{subtext}</Box>}
    </Box>
  )
}

export { StatusLabel as default }
