import React from "react"
import { Box, Button, Dialog, IconButton, Paper } from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"
import { makeStyles, styled } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  icon: {
    marginLeft: "auto",
  },
}))

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(4, 4),
}))

const Title = styled(Box)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "2rem",
  margin: theme.spacing(0.5, "auto"),
}))

const Body = styled(Box)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1.45rem",
  textAlign: "center",
  margin: theme.spacing("1", "auto"),
}))

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  margin: "1rem auto",
  "& > button": {
    margin: theme.spacing(0, 1),
    width: "8rem",
  },
}))

interface Props {
  title: string
  description: string
  show: boolean
  onClose: () => void
  onConfirm: () => void
}

const MarkAsResolvedConfirmation = ({ show, onClose, onConfirm, title, description }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Dialog open={show} onClose={onClose} PaperComponent={Paper}>
      <IconButton className={classes.icon} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Container>
        <Title>{title}</Title>
        <Body>{description}</Body>
        <ButtonContainer>
          <Button variant="contained" onClick={onClose} data-test="cancel-button">
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={onConfirm} data-test="yes-button">
            Yes
          </Button>
        </ButtonContainer>
      </Container>
    </Dialog>
  )
}

export { MarkAsResolvedConfirmation as default }
