import { DROP_TARGET_TYPE } from "../constants"
import { removeFromParent, appendTo, appendBefore, appendAfter } from "./reorder"

export function handleDrop(data, itemId, targetId, dropType) {
  if (dropType === DROP_TARGET_TYPE.SELF) {
    return data
  }

  const updatedData = { ...data }
  const parent = removeFromParent(updatedData, itemId)
  data[parent.id] = parent

  let updatedItem

  switch (dropType) {
    case DROP_TARGET_TYPE.EMPTY:
      updatedItem = appendTo(updatedData, targetId, itemId)
      break
    case DROP_TARGET_TYPE.BEFORE:
      updatedItem = appendBefore(updatedData, targetId, itemId)
      break
    case DROP_TARGET_TYPE.AFTER:
      updatedItem = appendAfter(updatedData, targetId, itemId)
      break
  }

  if (updatedItem) {
    updatedData[updatedItem.id] = updatedItem
  }

  return updatedData
}
