import React, { useState, ChangeEvent } from "react"
import { CircularProgress, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"

import { useQuery, UseQueryResult } from "react-query"
import { queryKeys } from "../react-query/constants"
import { getCases } from "../api"

interface Demand {
  name: string
  pk: number
}

interface Response {
  results: Demand[]
}

interface Option {
  label: string
  id: number
}

interface Props {
  onChange: (event: ChangeEvent<Record<string, unknown>>, value: Nullable<Option>) => void
  value: Option | null
}

const UserDemandSelector = ({ value, onChange }: Props): JSX.Element => {
  const [search, setSearch] = useState<string>("")
  const [openOverride, setOpenOverride] = useState<boolean>(false)

  const { data, isLoading }: UseQueryResult<Response, boolean> = useQuery(
    [
      queryKeys.cases,
      {
        searchQuery: search,
      },
    ],
    getCases,
    {
      enabled: search.length > 2,
    }
  )

  const options: Option[] =
    data?.results?.map((demand: Demand) => {
      return {
        label: demand.name ?? "No Name Set",
        id: demand.pk,
      }
    }) ?? []

  return (
    <Autocomplete
      getOptionLabel={option => option.label}
      inputValue={search}
      loading={isLoading}
      noOptionsText={"No results"}
      open={(search.length > 2 && value === null) || openOverride}
      onChange={(e, value) => {
        setOpenOverride(false)
        onChange(e, value)
      }}
      onInputChange={(_, input) => {
        if (search.length > 2) {
          setOpenOverride(true)
        }
        setSearch(input)
      }}
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          label="Search for a Demand"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      value={value}
    />
  )
}

export { UserDemandSelector as default }
