import { useCallback, useRef } from "react"
import { useSearchParams } from "react-router-dom"

export type NavigateOpts = { replace?: boolean; state?: any }
export type Value = string | number | boolean | null
export type SetSearchState = (nextValue: Value, opts?: NavigateOpts) => void
export type ValueType = "string" | "number" | "boolean"
export type UseSearchStateReturn = [Value, SetSearchState]

const duplicateSearchParams = (params: URLSearchParams): URLSearchParams =>
  new URLSearchParams(Object.fromEntries(params.entries()))

export const useSearchState = (
  key: string,
  defaultValue?: Value,
  type: ValueType = "string"
): UseSearchStateReturn => {
  const searchStateSet = useRef(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const setSearchState = useCallback<SetSearchState>(
    (nextValue, opts) => {
      searchStateSet.current = true
      const newParams = duplicateSearchParams(searchParams)
      newParams.set(key, String(nextValue))
      setSearchParams(newParams, opts)
    },
    [key, searchParams, setSearchParams]
  )

  let val = searchParams.get(key) as Value

  if (val === null && !searchStateSet.current) {
    return [defaultValue ?? null, setSearchState]
  }

  if (val === "null") {
    val = null
  }

  if (val !== null && type === "number") {
    val = Number(val)
  }

  if (val !== null && type === "boolean") {
    val = val === "false" ? false : Boolean(val)
  }

  return [val, setSearchState]
}
