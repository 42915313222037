import React, { useCallback, useState } from "react"
import { Box, Dialog, Typography } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"
import { useParams, useNavigate, useLocation, Outlet } from "react-router-dom"

import { useDialog } from "../../../hooks/useDialog"
import { useMutation, useQuery } from "react-query"
import { useSearchState } from "../../../hooks/useSearchState"
import { useHandleMessages } from "common/messages/useHandleMessages"
import useUser from "../../../hooks/useUser"
import useFirm from "hooks/useFirm"

import { queryKeys } from "../../../react-query/constants"
import { getContracts, updateFirm, updateFirmManagers } from "../../../api"

import { MainTitle } from "../../../app/styled"
import AttorneyTable from "./AttorneyTable"
import FirmManagers from "./FirmManagers"

import TextButton from "../../../common/buttons/TextButton"
import LinkButton from "../../../common/buttons/LinkButton"
import { ExtendedFirmDto, Manager } from "../Firm"
import AttorneyForm from "../AttorneyForm"
import { ContractForm } from "./ContractForm"
import { BlockTabs, Tab, TabPanel } from "../../../common/tabs/BlockTabs"
import { CONTRACT_INFORMATION, FIRM_DETAILS_LABELS, LIST_OF_ATTORNEYS } from "../../../common/constants"
import { ContractInformation } from "./ContractInformation"
import { Contract, DetailedContract } from "../../../common/interfaces"
import { INTERNAL_ROLES } from "../../../common/models/roles"
import StateAutocomplete from "common/form-components/StateAutocomplete"
import { ProgressCheckbox } from "common/ProgressCheckbox"
import AssigneePicker from "requests/AssigneePicker"
import { ConfirmDialog } from "common"

import {
  canRoleManageContracts,
  canRoleEditFirmManagers,
  canRoleSetBasicPlusCreationOption,
  canRoleSetAutoAnnotation,
  canRoleSetManualAnnotation,
} from "../permissions"

import CancelContractDialogs from "./CancelContractDialogs"
import { formatDate } from "utils"

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(4),
}))

const TopContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
})

export const DetailItem = styled(Box)({
  display: "flex",
  flexDirection: "row",
})

const Subtitle = styled(Box)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "bold",
  lineHeight: 1.89,
  marginBottom: theme.spacing(1),
}))

export const MinorTitle = styled(Box)(({ theme }) => ({
  fontWeight: "bold",
  letterSpacing: "normal",
  fontSize: "14px",
  marginRight: theme.spacing(1),
}))

const SectionContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(5, 0),
}))

export const Firm = (): JSX.Element => {
  const { id: firmId } = useParams()
  const {
    isOpen: isContractFormOpen,
    openDialog: openContractFormDialog,
    closeDialog: closeContractFormDialog,
  } = useDialog()
  const { user } = useUser()
  const { firm, updateCache: updateFirmCache } = useFirm(firmId ? +firmId : user.firmId ?? 0)

  const canRoleManageContractPermission = canRoleManageContracts(user.role)
  const canRoleSetBasicPlusCreationPermission = canRoleSetBasicPlusCreationOption(user.role)
  const canRoleSetAutoAnnotationPermission = canRoleSetAutoAnnotation(user.role)
  const canRoleSetManualAnnotationPermission = canRoleSetManualAnnotation(user.role)

  const { data: contracts } = useQuery(
    [queryKeys.firmContracts, firmId],
    async () => {
      return await getContracts({ firm_id: firmId })
    },
    {
      enabled: canRoleManageContractPermission,
    }
  )

  const navigate = useNavigate()
  const location = useLocation()
  const { showMessage } = useHandleMessages()
  const isViewingAttorneys = location.pathname.includes("attorneys")

  const [isCreatingNewAttorney, setIsCreatingNewAttorney] = useState(false)
  const [editingContractId, setEditingContractId] = useState<Nullable<number>>(null)
  const [isChangingCanClientView, setIsChangingCanClientView] = useState(false)
  const [isChangingCanCreateBasicPlus, setIsChangingCanCreateBasicPlus] = useState(false)
  const [isChangingEnableAutoAnnotations, setIsChangingEnableAutoAnnotations] = useState(false)
  const [isChangingEnableManualAnnotations, setIsChangingEnableManualAnnotations] = useState(false)

  const DEFAULT_TAB = user.isRole(INTERNAL_ROLES.LEGALOPS_ADMIN) ? CONTRACT_INFORMATION : LIST_OF_ATTORNEYS
  const [currentTab, setCurrentTab] = useSearchState("tab", DEFAULT_TAB, "string")

  const [cancelFormOpen, setCancelFormOpen] = useState(false)
  const [newFirmAssignees, setNewFirmAssignees] = useState<Nullable<Manager[]>>(null)
  const handleCancelFormOpen = useCallback(() => setCancelFormOpen(true), [setCancelFormOpen])
  const handleCancelFormClose = useCallback(() => setCancelFormOpen(false), [setCancelFormOpen])

  const userCanEditManagers = canRoleEditFirmManagers(user.role)

  const updateFirmMutation = useMutation(updateFirm, {
    onSuccess: (firmData: ExtendedFirmDto) => {
      updateFirmCache({
        can_client_view_credits: firmData?.can_client_view_credits,
        can_create_basic_plus_requests: firmData?.can_create_basic_plus_requests,
        enable_annotations: firmData?.enable_annotations,
        enable_complete_annotation_pipeline: firmData?.enable_complete_annotation_pipeline,
      } as Partial<ExtendedFirmDto>)
    },
    onError: () => {
      showMessage({
        type: "error",
        message: "There was an error updating the firm. Please contract eng. if this continues.",
      })
    },
    onSettled: () => {
      setIsChangingCanClientView(false)
      setIsChangingCanCreateBasicPlus(false)
      setIsChangingEnableAutoAnnotations(false)
      setIsChangingEnableManualAnnotations(false)
    },
  })

  const saveManagerRelation = useMutation(updateFirmManagers, {
    onSuccess: () => {
      showMessage({
        type: "success",
        message: "Successfully updated firm managers.",
      })
    },
    onError: () => {
      showMessage({
        type: "error",
        message: "There was an error updating the firm's managers. Please reload and try again.",
      })
    },
  })

  if (!firm) {
    return <></>
  }

  const handleOnEditClick = (id: Nullable<number>) => {
    if (id !== null) {
      setEditingContractId(id)
      openContractFormDialog()
    }
  }

  const handleOnCreateNewClick = () => {
    setEditingContractId(null)
    openContractFormDialog()
  }

  const handleToggleClientViewCredits = (value: boolean) => {
    setIsChangingCanClientView(true)
    updateFirmMutation.mutate({
      data: {
        can_client_view_credits: value,
      },
      firmId,
    })
  }

  const handleToggleClientCreateBasicPlus = (value: boolean) => {
    setIsChangingCanCreateBasicPlus(true)
    updateFirmMutation.mutate({
      data: {
        can_create_basic_plus_requests: value,
      },
      firmId,
    })
  }

  interface AutoCompleteOnChangeParams {
    event: React.ChangeEvent
    newValues: Manager[]
    reason: string
  }

  const saveFirmAssignees = (assignees: Manager[]) => {
    const ids = assignees.map(assignee => {
      return assignee.pk
    })
    updateFirmCache({
      managers: [...assignees],
    } as ExtendedFirmDto)
    saveManagerRelation.mutate({ firmId: firm.pk, data: ids })
  }

  function handleConfirmFirmAssignees() {
    saveFirmAssignees(newFirmAssignees ?? [])
    setNewFirmAssignees(null)
  }

  const handleChangeManagers = ({ newValues, reason }: AutoCompleteOnChangeParams) => {
    if (reason === "remove-option" || reason === "clear") {
      setNewFirmAssignees(newValues)
    } else {
      saveFirmAssignees(newValues)
    }
  }

  interface UpdateAnnotationData {
    enable_annotations?: boolean
    enable_complete_annotation_pipeline?: boolean
  }

  const handleToggleEnableAutoAnnotations = (value: boolean) => {
    setIsChangingEnableAutoAnnotations(true)
    updateFirmMutation.mutate({
      data: {
        enable_annotations: value,
      },
      firmId,
    })
  }

  const handleToggleEnableManualAnnotations = (value: boolean) => {
    setIsChangingEnableManualAnnotations(true)
    const updateData: UpdateAnnotationData = {
      enable_complete_annotation_pipeline: value,
    }
    if (value === true) {
      updateData.enable_annotations = true
    }
    updateFirmMutation.mutate({
      data: updateData,
      firmId,
    })
  }

  const handleStateAutocompleteChange = async (_event: React.ChangeEvent, value: string | string[]) => {
    // update firm.operating_in_states cache so if someone clears then re-enters same value it still works
    updateFirmCache({
      operating_in_states: value,
    } as ExtendedFirmDto)

    try {
      await updateFirm({ data: { operating_in_states: value }, firmId })
    } catch (e) {
      showMessage({
        type: "error",
        message:
          "Your change could not be saved due to a server issue, please try again shortly or contact a dev if the error persists.",
      })
    }
  }

  const contract = firm?.current_contract
  const hasContract = !!contract
  const EMPTY_VALUE = `—`

  const creditsPerMonth = hasContract
    ? `${contract.monthly_credits - contract.current_month_credits_used} / ${contract.monthly_credits}`
    : EMPTY_VALUE
  const endOfThisMonth = hasContract ? contract.end_of_month : EMPTY_VALUE
  const availableCredits = hasContract
    ? `${contract.monthly_credits - contract.current_month_credits_used} + ${
        contract.rollover_credits
      } rollover`
    : EMPTY_VALUE

  return (
    <>
      <Container>
        <TopContainer>
          <Box>
            <DetailItem alignItems="baseline">
              <MainTitle mr={1}>{firm.name}</MainTitle>
              <Box ml={"auto"}>
                <LinkButton data-test="edit-firm" onClick={() => navigate(`/settings/firms/${firmId}/edit`)}>
                  Edit Firm
                </LinkButton>
              </Box>
            </DetailItem>
            {userCanEditManagers ? (
              <Box mb={2} mt={2} data-test="firm-manager-picker">
                <AssigneePicker
                  value={firm.managers}
                  isInternal={true}
                  onChange={handleChangeManagers}
                  variant="outlined"
                />
                <Box pl={0.5}>
                  <Typography variant="caption">* Saves automatically</Typography>
                </Box>
              </Box>
            ) : (
              <DetailItem alignItems="center" data-test="firm-assignees">
                <MinorTitle mr={1}>Assigned to: </MinorTitle>
                <FirmManagers managers={firm?.managers || []} />
              </DetailItem>
            )}
            <Box lineHeight={2.1} data-test="credit-information">
              <>
                <DetailItem data-test="credits-per-month">
                  <MinorTitle>Credits per month: </MinorTitle>
                  <Box>{creditsPerMonth}</Box>
                </DetailItem>
                <DetailItem data-test="ends-for-this-month">
                  <MinorTitle>Ends for this Month: </MinorTitle>
                  <Box>{hasContract ? formatDate(endOfThisMonth, "MM/dd/yyyy", true) : EMPTY_VALUE}</Box>
                </DetailItem>
                <DetailItem data-test="current-available-credits">
                  <MinorTitle>Current Available Credits: </MinorTitle>
                  <Box>{availableCredits}</Box>
                </DetailItem>
              </>
            </Box>
            {canRoleManageContractPermission && (
              <Box display={"flex"} data-test="clients-view-credits-checkbox">
                <ProgressCheckbox
                  checked={!!firm.can_client_view_credits}
                  onChange={handleToggleClientViewCredits}
                  label={
                    <Box mt="auto" mb="auto" fontSize="12px">
                      Allow client to view monthly available credits
                    </Box>
                  }
                  isChanging={isChangingCanClientView}
                />
              </Box>
            )}

            {canRoleSetBasicPlusCreationPermission && (
              <Box display={"flex"} data-test="clients-create-basic-plus-checkbox">
                <ProgressCheckbox
                  checked={!!firm.can_create_basic_plus_requests}
                  onChange={handleToggleClientCreateBasicPlus}
                  label={
                    <Box mt="auto" mb="auto" fontSize="12px">
                      Allow client to create Basic+ requests
                    </Box>
                  }
                  isChanging={isChangingCanCreateBasicPlus}
                />
              </Box>
            )}

            {canRoleSetAutoAnnotationPermission && (
              <Box display={"flex"}>
                <ProgressCheckbox
                  checked={!!firm.enable_annotations || !!firm.enable_complete_annotation_pipeline}
                  disabled={!!firm.enable_complete_annotation_pipeline}
                  onChange={handleToggleEnableAutoAnnotations}
                  label={
                    <Box mt="auto" mb="auto" fontSize="12px">
                      Enable auto annotations
                    </Box>
                  }
                  isChanging={isChangingEnableAutoAnnotations}
                />
              </Box>
            )}
            {canRoleSetManualAnnotationPermission && (
              <Box display={"flex"}>
                <ProgressCheckbox
                  checked={!!firm.enable_complete_annotation_pipeline}
                  onChange={handleToggleEnableManualAnnotations}
                  label={
                    <Box mt="auto" mb="auto" fontSize="12px">
                      Enable complete annotation pipeline
                    </Box>
                  }
                  isChanging={isChangingEnableManualAnnotations}
                />
              </Box>
            )}
          </Box>
        </TopContainer>
        <Box mt={0.5}>
          <Box mb={2}>
            <MinorTitle>States that this firm operates in:</MinorTitle>
          </Box>
          <StateAutocomplete
            multiple
            value={firm.operating_in_states}
            id="firm-state-selector"
            onChange={handleStateAutocompleteChange}
          />
          <Box pl={0.5}>
            <Typography variant="caption">* Saves automatically</Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <BlockTabs
            currentTab={currentTab ?? DEFAULT_TAB}
            setCurrentTab={(tabName: string) => {
              if (tabName !== currentTab) {
                setCurrentTab(tabName)
              }
            }}
            tabs={[
              <Tab
                key={CONTRACT_INFORMATION}
                label={FIRM_DETAILS_LABELS[CONTRACT_INFORMATION]}
                value={CONTRACT_INFORMATION}
                disabled={!user.isRole(INTERNAL_ROLES.LEGALOPS_ADMIN)}
                data-test="contract-information-tab"
              />,
              <Tab
                key={LIST_OF_ATTORNEYS}
                label={FIRM_DETAILS_LABELS[LIST_OF_ATTORNEYS]}
                value={LIST_OF_ATTORNEYS}
                data-test="list-of-attorneys-tab"
              />,
            ]}
          >
            <>
              <TabPanel key={CONTRACT_INFORMATION} value={CONTRACT_INFORMATION}>
                {user.isRole(INTERNAL_ROLES.LEGALOPS_ADMIN) && (
                  <ContractInformation
                    activeContract={hasContract ? (firm.current_contract as DetailedContract) : null}
                    contracts={contracts ?? []}
                    onEditActiveClick={handleOnEditClick}
                    onCreateNewClick={handleOnCreateNewClick}
                    onCancelAllClick={handleCancelFormOpen}
                  />
                )}
              </TabPanel>
              <TabPanel key={LIST_OF_ATTORNEYS} value={LIST_OF_ATTORNEYS}>
                <SectionContainer>
                  {isViewingAttorneys ? (
                    <Outlet />
                  ) : (
                    <>
                      <Subtitle>List of Attorneys</Subtitle>
                      <AttorneyTable attorneys={firm.attorneys} firmId={firmId ? +firmId : 0} />
                      <TextButton
                        disabled={isCreatingNewAttorney}
                        onClick={() => {
                          setIsCreatingNewAttorney(true)
                        }}
                        data-test="add-new-attorney-button"
                      >
                        + ADD NEW ATTORNEY
                      </TextButton>
                      {isCreatingNewAttorney && (
                        <AttorneyForm
                          data={undefined}
                          callback={() => {
                            setIsCreatingNewAttorney(false)
                          }}
                          navigateBack={false}
                        />
                      )}
                    </>
                  )}
                </SectionContainer>
              </TabPanel>
            </>
          </BlockTabs>
        </Box>
      </Container>
      <Dialog open={isContractFormOpen} onClose={closeContractFormDialog} maxWidth="lg">
        <ContractForm
          contract={
            editingContractId
              ? contracts.find((contract: Contract) => contract.pk === editingContractId)
              : null
          }
          firmId={firm.pk ?? 0}
          onClose={closeContractFormDialog}
          onSubmitCallback={closeContractFormDialog}
          contracts={contracts}
        />
      </Dialog>
      <CancelContractDialogs
        cancelFormOpen={cancelFormOpen}
        handleCancelFormOpen={handleCancelFormOpen}
        handleCancelFormClose={handleCancelFormClose}
        firmId={firmId ?? ""}
      />
      <ConfirmDialog
        open={!!newFirmAssignees}
        onClose={() => setNewFirmAssignees(null)}
        onConfirm={handleConfirmFirmAssignees}
        title="Are you sure you want to remove firm managers?"
      />
    </>
  )
}
