import React, { useEffect } from "react"
import { useMutation, useQuery } from "react-query"
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useOutletContext } from "react-router-dom"
import { Controller, useForm } from "react-hook-form"

import { queryKeys } from "../react-query/constants"
import { fetchLawFirmConfig, setAttorney, getFirmAttorneys } from "../api"
import { getChangedFields, getCommonMutateOptions } from "../utils"

import { formSectionsToRoutes, STEP_STATUSES } from "./constants"
import { useFormContext } from "./context"

const INITIAL_FORM_STATE = {
  attorney_id: "",
  firm_id: "",
}

const useStyles = makeStyles(theme => ({
  formFields: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: theme.spacing(4),
  },
  actions: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    margin: theme.spacing(2, 0),
    justifyContent: "flex-end",
  },
  fullWidth: {
    gridColumn: "1 / 4",
  },
}))

export function LawFirm({ lastVisited }) {
  const classes = useStyles()
  const [saveRef] = useOutletContext()
  const { caseId, firms, setSavedSuccessfully, showErrorMessage, handleUpdateStepStatus } = useFormContext()
  const { control, handleSubmit, reset, formState, watch, setValue } = useForm({
    defaultValues: INITIAL_FORM_STATE,
  })
  const firm_id = watch("firm_id")
  const { data: attorneys, isLoading: isLoadingAttorneys } = useQuery(
    [queryKeys.attorneys, firm_id],
    getFirmAttorneys,
    {
      enabled: !!firm_id,
    }
  )

  useQuery([queryKeys.template, caseId], async () => {
    const data = await fetchLawFirmConfig(caseId)
    if (data) {
      reset({
        attorney_id: data?.attorney?.pk,
        firm_id: data?.firm?.pk,
      })
    }
  })
  const setAttorneyMutation = useMutation(
    setAttorney,
    getCommonMutateOptions({
      reset,
      setSavedSuccessfully,
      showErrorMessage,
      onSuccessExtra: () => {
        handleUpdateStepStatus({ status: STEP_STATUSES.completed })
      },
    })
  )

  const handleOnBlur = handleSubmit(async data => {
    const changedFields = getChangedFields(data, formState)
    // we only want to save if there is an attorney (or a change to the attorney)
    if (changedFields.attorney_id) {
      setAttorneyMutation.mutate({ caseId, data })
    }
  })
  useEffect(() => {
    saveRef.current = handleOnBlur
  }, [handleOnBlur, saveRef])

  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.law_firm
  })

  if (!firms) {
    return <div></div>
  }

  return (
    <form className={classes.formFields} noValidate onBlur={handleOnBlur}>
      <Controller
        name="firm_id"
        control={control}
        render={({ field }) => (
          <FormControl variant="outlined">
            <InputLabel id="firm-label">Select Firm</InputLabel>
            <Select
              labelId="firm-label"
              label="Select firm"
              {...field}
              onChange={event => {
                setValue("attorney_id", "")
                field.onChange(event)
              }}
            >
              {firms.map(firm => (
                <MenuItem key={firm.pk} value={firm.pk} name="firm_id">
                  {firm.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      {firm_id && (
        <Controller
          name="attorney_id"
          control={control}
          render={({ field }) => (
            <FormControl variant="outlined">
              <InputLabel id="attorney-label">Select Attorney</InputLabel>
              <Select labelId="attorney-label" label="Select attorney" {...field} data-test="select-attorney">
                <MenuItem value="" disabled>
                  Please select
                </MenuItem>
                {!isLoadingAttorneys &&
                  attorneys?.map(attorney => (
                    <MenuItem key={attorney.pk} value={attorney.pk} name="attorney_id">
                      {`${attorney.first_name} ${attorney.last_name}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        />
      )}
    </form>
  )
}
