import { PaginatedOptions } from "api/PaginatedList"
import { handleEmptyResponse } from "api/utils"
import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"
import { ApiError } from "apiHelper"
import { AttributeFiltersData } from "common/attributes-filter/types"
import { NonUniqueAttributesError } from "common/template-form/types"
import { CASE_SECTIONS } from "common/types/sections"
import { isUndefined } from "lodash"
import { apiService } from "../ApiService"
import { ApiServiceType } from "../types"
import { SectionTemplateServiceDeserializer, SectionTemplateServiceSerializer } from "./serializers"
import { SectionTemplateDto } from "./types"

interface TemplateServiceOptions {
  templateId: BaseEntity["pk"]
}

export type TemplateServiceEntityOptionsArg<Exists extends boolean = false> = {
  data: SectionTemplateDto
} & (Exists extends true ? { options: TemplateServiceOptions } : { options?: never })

export type SectionTemplateListOptions = PaginatedOptions & {
  section?: Nullable<CASE_SECTIONS>
  attributeValues?: AttributeFiltersData
}

enum TEMPLATE_API_PATHS {
  BASE = "library/template",
  SECTIONS = "sections",
}

export class SectionTemplateService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(options?: TemplateServiceOptions, path?: TEMPLATE_API_PATHS): string {
    const pathParts = ["", TEMPLATE_API_PATHS.BASE, options?.templateId, path]

    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getTemplatesList = withResponseSerializer(
    SectionTemplateServiceDeserializer.fromPaginatedListJSON,
    ({ section, attributeValues, ...options }: SectionTemplateListOptions) => {
      const queryItems: string[] = []

      if (section) {
        queryItems.push(`section=${section}`)
      }

      if (attributeValues) {
        const values = SectionTemplateServiceSerializer.toAttributeValuesJSON(attributeValues)
        values.length && queryItems.push(`attribute_value_ids=${values.join(",")}`)
      }

      const query = queryItems.length ? `?${queryItems.join("&")}` : null

      return this.apiService.getPaginatedList(this.getPath(), query, options)
    }
  )

  createTemplate = withRequestSerializer(
    SectionTemplateServiceSerializer.toDefinitionJSON,
    withResponseSerializer(
      SectionTemplateServiceDeserializer.definitionFromJSON,
      async ({ data }: TemplateServiceEntityOptionsArg) => {
        try {
          return await handleEmptyResponse(this.apiService.create(data, this.getPath()))
        } catch (error) {
          if (error instanceof ApiError && error.response.status === 400) {
            throw new NonUniqueAttributesError()
          }

          throw error
        }
      }
    )
  )

  updateTemplate = withRequestSerializer(
    SectionTemplateServiceSerializer.toDefinitionJSON,
    withResponseSerializer(
      SectionTemplateServiceDeserializer.definitionFromJSON,
      async ({ data, options }: TemplateServiceEntityOptionsArg<true>) => {
        try {
          return await handleEmptyResponse(this.apiService.replace(data, this.getPath(options)))
        } catch (error) {
          if (error instanceof ApiError && error.response.status === 400) {
            throw new NonUniqueAttributesError()
          }

          throw error
        }
      }
    )
  )

  getMatchingTemplates = withRequestSerializer(
    SectionTemplateServiceSerializer.toAttributeValuesJSON,
    withResponseSerializer(
      SectionTemplateServiceDeserializer.templatesBySectionFromJSON,
      ({ data }: { data: number[] }) => {
        const query = data.length ? `?attribute_value_ids=${data.join(",")}` : null

        return handleEmptyResponse(
          this.apiService.get(null, this.getPath(undefined, TEMPLATE_API_PATHS.SECTIONS), query)
        )
      }
    )
  )
}

export const sectionTemplateService = new SectionTemplateService(apiService)
