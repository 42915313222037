import React, { useCallback, useEffect, useMemo, useState } from "react"
import { LibraryUseForm, NonUniqueAttributesError } from "common/template-form/types"
import { useAttributes } from "settings/Library/Filters"
import { NewSectionTemplate, SectionTemplateDefinitionAttribute } from "common/types/templates"
import { queryClient } from "react-query/queryClient"
import { getAttributeValues } from "common/attributes-filter/utils"
import { SectionTemplateFormData as TemplateFormDataType } from "./types"
import { FORM_STATE_STATUS } from "./constants"
import { TemplateFormMessage } from "./TemplateFormMessage"
import { SectionTemplateFormData } from "./SectionTemplateFormData"
import { useMutation, useQuery } from "react-query"
import { queryKeys } from "react-query/constants"
import { variablesService } from "api/services/variables"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import { sectionTemplateService } from "api/services/section-template"

const mapAttribute = (attribute: SectionTemplateDefinitionAttribute) => [attribute.id, attribute.valueId]

export const useSectionTemplateForm: LibraryUseForm = ({
  entityId,
  onFinish,
  initialData,
  formStatusMessageMap,
}) => {
  const [formStatus, setFormStatus] = useState<FORM_STATE_STATUS>(FORM_STATE_STATUS.IDLE)
  const attributes = useAttributes()
  const [errorInSection, setErrorInSection] = useState<boolean>(false)
  const [formData, setFormData] = useState<Nullable<TemplateFormDataType>>(null)

  const { data: variables } = useQuery(queryKeys.defaultVariables, variablesService.getDefaultCaseVariables, {
    keepPreviousData: true,
  })

  const initialAttributeValues = useMemo(() => {
    if (!initialData.initialAttributes || !attributes) return undefined

    return getAttributeValues(attributes, Object.fromEntries(initialData.initialAttributes.map(mapAttribute)))
  }, [attributes, initialData])

  const onSuccess = useCallback(() => {
    setFormStatus(FORM_STATE_STATUS.SUCCESS)
  }, [])

  const onError = useCallback((error: unknown) => {
    if (error instanceof NonUniqueAttributesError) {
      setFormStatus(FORM_STATE_STATUS.DATA_ERROR)
    } else {
      setFormStatus(FORM_STATE_STATUS.API_ERROR)
    }
  }, [])

  const mutationOptions = useMemo(
    () => ({
      onSuccess: () => {
        onSuccess()
        onFinish()

        queryClient.invalidateQueries(queryKeys.sectionTemplates)
      },
      onError: (error: unknown) => onError(error),
    }),
    [onSuccess, onFinish, onError]
  )

  const createTemplateMutation = useMutation(sectionTemplateService.createTemplate, mutationOptions)
  const updateTemplateMutation = useMutation(sectionTemplateService.updateTemplate, mutationOptions)

  const handleFormDataChange = useCallback(
    (data: TemplateFormDataType) => {
      setFormData(data)

      if (data.sectionWithAttributes.section !== null) {
        setErrorInSection(false)
      }
    },
    [setFormData, setErrorInSection]
  )

  const clearForm = useCallback(() => {
    setFormStatus(FORM_STATE_STATUS.IDLE)
    setErrorInSection(false)
  }, [])

  const handleCancel = useCallback(() => {
    onFinish()
    clearForm()
  }, [onFinish, clearForm])

  const handleSubmit = useCallback(() => {
    if (!formData || !formData.sectionWithAttributes.section) {
      setErrorInSection(true)

      return
    }

    const data: NewSectionTemplate = {
      content: formData.content,
      section: formData.sectionWithAttributes.section,
      attributes: formData.sectionWithAttributes.attributeValues,
    }

    if (typeof entityId === "undefined") {
      createTemplateMutation.mutate({ data })
    } else {
      updateTemplateMutation.mutate({ options: { templateId: entityId }, data })
    }
  }, [createTemplateMutation, updateTemplateMutation, formData, setErrorInSection, entityId])

  const message = useMemo(() => {
    if (formStatus === FORM_STATE_STATUS.IDLE) return null

    const messageText = formStatusMessageMap[formStatus]

    if (!messageText) return null

    return <TemplateFormMessage clear={clearForm} message={messageText} formStatus={formStatus} />
  }, [clearForm, formStatus, formStatusMessageMap])

  const viewForm = useMemo(() => {
    return <RichTextEditor readonly value={initialData.initialContent} />
  }, [initialData])

  const editForm = useCallback(
    (footer: Nullable<JSX.Element>) =>
      attributes ? (
        <>
          <SectionTemplateFormData
            attributes={attributes}
            initialAttributeValues={initialAttributeValues}
            initialContent={initialData.initialContent}
            initialSection={initialData.initialSection}
            onChange={handleFormDataChange}
            error={formStatus === FORM_STATE_STATUS.DATA_ERROR}
            errorSection={errorInSection}
            variables={variables}
          />
          {footer}
        </>
      ) : null,
    [
      attributes,
      errorInSection,
      formStatus,
      handleFormDataChange,
      initialAttributeValues,
      initialData,
      variables,
    ]
  )

  const errorForm = formStatus === FORM_STATE_STATUS.API_ERROR || formStatus === FORM_STATE_STATUS.DATA_ERROR

  useEffect(() => {
    clearForm()
  }, [formData, clearForm])

  const isLoading = createTemplateMutation.isLoading || updateTemplateMutation.isLoading

  return { message, editForm, handleSubmit, handleCancel, isLoading, viewForm, clearForm, errorForm }
}
