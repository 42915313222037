import { Box, Divider } from "@material-ui/core"
import { createDuplicate } from "api"
import TextButton from "common/buttons/TextButton"
import { useHandleMessages } from "common/messages/useHandleMessages"
import React, { forwardRef, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { queryKeys } from "react-query/constants"
import { comparePageRanges, updateAnnotatedExhibits } from "./cacheUtils"
import EditableRange from "./EditableRange"
import { EditBoxTitle, EditPaper, Text } from "./styled"
import { AnnotatedExhibit, CaseId, Duplicate } from "./types"
import { DuplicateValidationErrors, validateDuplicate } from "./validation/duplicateValidation"
import { anyErrors } from "./validation/validationUtils"

interface DuplicateBoxProps {
  onCancel: () => unknown
  onSuccessfulSave: () => unknown
  exhibitId: number
  caseId: CaseId
  numberOfPages: number
}

export default forwardRef<HTMLElement, DuplicateBoxProps>(function DuplicateBox(
  { onCancel, exhibitId, caseId, onSuccessfulSave, numberOfPages },
  ref
): React.ReactElement {
  const [start, setStart] = useState<number>(NaN)
  const [end, setEnd] = useState<number>(NaN)
  const [validationErrors, setValidationErrors] = useState<DuplicateValidationErrors>({})
  const queryClient = useQueryClient()

  const { showMessage } = useHandleMessages()
  const { mutate, isLoading } = useMutation({
    mutationFn: createDuplicate,
    onSuccess: (data: Duplicate) => {
      queryClient.setQueryData<AnnotatedExhibit[]>([queryKeys.annotated_exhibits, caseId], oldData => {
        return updateAnnotatedExhibits({
          oldData,
          exhibitId,
          update: exhibit => ({ duplicates: [...exhibit.duplicates, data].sort(comparePageRanges) }),
        })
      })
      onSuccessfulSave()
    },
    onError: () => {
      showMessage({
        type: "error",
        message: "Could not create duplicate. Try again shortly and file an issue if the problem persists.",
      })
    },
  })

  const handleSave = () => {
    const errors = validateDuplicate({ start, end, numberOfPages })

    setValidationErrors(errors)

    if (anyErrors(errors)) return

    mutate({
      exhibitId,
      data: {
        start_page: start,
        end_page: end,
      },
    })
  }
  return (
    <EditPaper ref={ref}>
      <EditBoxTitle>Create Duplicate</EditBoxTitle>
      <Box mt={2}>
        <Box display="flex" alignItems="center">
          <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
            <EditableRange
              start={start}
              end={end}
              onStartChange={event => setStart(event.target.valueAsNumber)}
              onEndChange={event => setEnd(event.target.valueAsNumber)}
              disabled={isLoading}
              startError={!!validationErrors.start}
              endError={!!validationErrors.end}
            />
          </Box>
        </Box>
        {(validationErrors.start || validationErrors.end) && (
          <Box mt={1}>
            <Text color="error">{Object.values(validationErrors).join(", ")}</Text>
          </Box>
        )}
      </Box>
      <Box mt={2} mb={1}>
        <Divider />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Box>
          <TextButton onClick={onCancel} textColor="grey" disabled={isLoading}>
            Cancel
          </TextButton>
          <TextButton onClick={handleSave} disabled={isLoading}>
            Save
          </TextButton>
        </Box>
      </Box>
    </EditPaper>
  )
})
