import React, { createContext, useContext } from "react"
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import EmptyState from "./EmptyState"

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: theme.spacing(2),
  },
  tableHeader: {
    backgroundColor: "#EBEBEB",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4, "20%"),
  },
  loadingContent: {
    margin: theme.spacing(1, "auto"),
  },
}))

const TableContext = createContext({})

const TableHeadRow = () => {
  const { columns, userRole, sortBy, sortDirection, onSortClick } = useContext(TableContext)
  return (
    <TableRow>
      {columns?.map(column => {
        if (typeof column?.restrictView === "function" && !column.restrictView(userRole)) {
          return
        }

        const cellKey = `table-header-cell-${column.id}`
        return (
          <TableCell
            key={cellKey}
            data-test={cellKey}
            align={column.align ?? "left"}
            padding={column.disablePadding ? "none" : "normal"}
            style={column.style ?? {}}
          >
            {column.sortable === true ? (
              <TableSortLabel
                active={sortBy === column.id ? true : false}
                direction={sortDirection}
                onClick={() => {
                  onSortClick(column.id)
                }}
              >
                {column.text}
              </TableSortLabel>
            ) : (
              column.text
            )}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

const LoadingCaption = () => {
  const classes = useStyles()

  return (
    <caption>
      <Box className={classes.loadingContainer}>
        <CircularProgress className={classes.loadingContent} />
        <Typography variant="h5" className={classes.loadingContent}>
          Loading...
        </Typography>
      </Box>
    </caption>
  )
}

const TableBodyContent = () => {
  const { records, columns, userRole, emptyMessage } = useContext(TableContext)

  if (records?.length) {
    return (
      <TableBody>
        <TableBodyRows records={records} columns={columns} userRole={userRole} />
      </TableBody>
    )
  } else {
    return (
      <caption>
        <EmptyState message={emptyMessage} />
      </caption>
    )
  }
}

const TableBodyRows = () => {
  const { records } = useContext(TableContext)
  return (
    records?.map((record, recordIndex) => {
      return <TableBodyRow key={`table-row-${recordIndex}`} record={record} />
    }) || <></>
  )
}

const TableBodyRow = ({ record }) => {
  const { columns, userRole } = useContext(TableContext)
  return (
    <TableRow hover data-test={record?.pk ? `table-row-${record.pk}` : `table-row`}>
      {columns?.map((column, columnIndex) => {
        if (typeof column?.restrictView === "function" && !column.restrictView(userRole)) {
          return
        }

        const CellComponent = column.cellComponent

        return (
          <TableCell
            data-test={`table-cell-${column.text}`}
            key={`table-column-${columnIndex}`}
            align={column?.align || "left"}
            className={column?.className ?? ""}
          >
            <CellComponent record={record} />
          </TableCell>
        )
      })}
    </TableRow>
  )
}

const PaginatedTable = ({
  records,
  columns,
  onSortClick,
  sortDirection,
  sortBy,
  page,
  totalCount,
  onPageChange,
  onRowsPerPageChange,
  userRole,
  emptyMessage = "No Data",
  rowsPerPageOptions = [5, 10, 50],
  loadingQueryKey = null,
  pageSize,
  recordsLoading = false,
}) => {
  const classes = useStyles()

  return (
    <TableContext.Provider
      value={{
        columns,
        userRole,
        sortBy,
        sortDirection,
        onSortClick,
        records,
        recordsLoading,
        emptyMessage,
        loadingQueryKey,
      }}
    >
      <TableContainer component={Paper} className={classes.table} data-test="table">
        <Table>
          <TableHead className={classes.tableHeader}>
            <TableHeadRow />
          </TableHead>
          {recordsLoading ? <LoadingCaption /> : <TableBodyContent />}
        </Table>
      </TableContainer>
      <TablePagination
        onRowsPerPageChange={e => {
          onRowsPerPageChange(e.target.value)
        }}
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount ?? 0}
        rowsPerPage={pageSize ?? 50}
        // since paging is done on backend, when we are loading next page there is technically
        // "no page", setting it to 0 when loading prevents dev error in console
        page={recordsLoading ? 0 : page}
        onPageChange={(_, newPage) => {
          onPageChange(newPage)
        }}
      ></TablePagination>
    </TableContext.Provider>
  )
}

export { PaginatedTable as default }
