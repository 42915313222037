import React, { useState, forwardRef } from "react"
import {
  Box,
  CircularProgress,
  Paper,
  IconButton,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core"
import { Cancel, InsertDriveFileOutlined, GetApp as DownloadIcon } from "@material-ui/icons"
import { makeStyles, styled, Theme } from "@material-ui/core/styles"

import { MAX_FILE_NAME_LENGTH } from "demand/constants"
import { ExhibitValidationError } from "demand/Providers/types"
import LinkButton from "../../buttons/LinkButton"
import splitFileName from "./splitFileName"
import TextButton from "../../buttons/TextButton"
import { DraggableProvidedDraggableProps } from "react-beautiful-dnd"
import FileTag from "./FileTag"

interface StyleProps {
  highlightOnHover: boolean
  editing?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    display: "flex",
    padding: theme.spacing(2),
    alignItems: "center",
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: props =>
        props.highlightOnHover && !props.editing ? theme.palette.blue.light : "inherit",
      cursor: props => (props.highlightOnHover && !props.editing ? "pointer" : "inherit"),
    },
  },
  existingFileIcon: {
    fontSize: "1.9rem",
    marginRight: theme.spacing(2),
  },
  existingFileName: {
    fontWeight: "bold",
  },
  disabled: {
    color: theme.palette.action.disabled,
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(1),
    justifySelf: "end",
  },
}))

const EditFileBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  width: "100%",
  marginRight: theme.spacing(2),
}))

export interface UploadedFileProps {
  id: number
  fileName?: string
  fileType?: string
  fileTypeMap: Record<string, string>
  editing?: boolean
  onFileNameChange: (fileName: string) => void
  onFileTypeChange: (fileType: string) => void
  onEdit: () => void
  onSave: () => void
  onCancel: () => void
  onDelete: () => void
  onDownload: (fileName: string) => void
  onClick?: (id: number, exhibitType: string | undefined) => void
  disabled: boolean
  validationError?: ExhibitValidationError | null
  editable?: boolean
  draggableProps?: DraggableProvidedDraggableProps
  dragHandle?: React.ReactNode
  tagLabel?: string
  highlightOnHover?: boolean
  exhibitType?: string
}

type EditFileFormProps = Pick<
  UploadedFileProps,
  | "id"
  | "fileName"
  | "fileType"
  | "fileTypeMap"
  | "onFileNameChange"
  | "onFileTypeChange"
  | "disabled"
  | "validationError"
>

const EditFileForm: React.FC<EditFileFormProps> = ({
  id,
  fileName,
  fileType,
  onFileNameChange,
  onFileTypeChange,
  disabled,
  validationError,
  fileTypeMap,
}) => {
  const [name, extension] = splitFileName(fileName ?? "")
  const handleFileNameChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    onFileNameChange(event.target.value + extension)
  }

  const handleFileTypeChange: (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode
  ) => void = event => {
    onFileTypeChange(event.target.value as string)
  }

  return (
    <EditFileBox>
      <TextField
        label="File name"
        value={name}
        variant="outlined"
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="end">{extension}</InputAdornment>,
        }}
        onChange={handleFileNameChange}
        disabled={disabled}
        margin="dense"
        // slightly less than the max to account for file ext getting added automatically
        inputProps={{ maxLength: MAX_FILE_NAME_LENGTH - extension.length }}
        error={!!validationError?.name}
        helperText={validationError?.name}
      />
      <FormControl
        fullWidth
        variant="outlined"
        disabled={disabled}
        margin="dense"
        error={!!validationError?.type}
      >
        <InputLabel id={`file-type-label-${id}`}>File type</InputLabel>
        <Select
          value={fileType ?? ""}
          labelId={`file-type-label-${id}`}
          id={`file-type-${id}`}
          label="File type"
          onChange={handleFileTypeChange}
          aria-describedby={validationError?.type ? `file-type-helper-text-${id}` : undefined}
        >
          <MenuItem value="" disabled>
            Please Select
          </MenuItem>
          {Object.entries(fileTypeMap).map(([key, label]) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </Select>
        {!!validationError?.type && <FormHelperText>{validationError?.type}</FormHelperText>}
      </FormControl>
    </EditFileBox>
  )
}

const UploadedFile: React.ForwardRefRenderFunction<React.Ref<HTMLElement>, UploadedFileProps> = (
  {
    id,
    editing,
    fileName,
    fileType,
    fileTypeMap,
    onDelete,
    onDownload,
    disabled,
    onFileNameChange,
    onFileTypeChange,
    onSave,
    onEdit,
    onCancel,
    validationError,
    editable = false,
    draggableProps,
    dragHandle,
    exhibitType,
    tagLabel = "",
    onClick = undefined,
    highlightOnHover = false,
  },
  ref
) => {
  const classes = useStyles({ highlightOnHover, editing })
  const [isDownloading, setIsDownloading] = useState(false)

  return (
    <Paper
      {...draggableProps}
      ref={ref}
      component={Box}
      variant="outlined"
      className={classes.container}
      data-test="uploaded-file"
      onClick={() => {
        !editing && onClick && onClick(id, exhibitType)
      }}
    >
      {dragHandle}
      {!editing && (
        <>
          <InsertDriveFileOutlined className={classes.existingFileIcon} />
          <Box width="100%">
            <Box className={classes.existingFileName}>{fileName}</Box>
            <Box>{fileType ? fileTypeMap[fileType] ?? fileType : fileType}</Box>
          </Box>
        </>
      )}
      {editing && (
        <EditFileForm
          id={id}
          fileName={fileName}
          fileType={fileType}
          onFileNameChange={onFileNameChange}
          onFileTypeChange={onFileTypeChange}
          disabled={disabled}
          validationError={validationError}
          fileTypeMap={fileTypeMap}
        />
      )}
      <Box className={classes.buttons}>
        {editing ? (
          <>
            <TextButton
              disabled={disabled}
              textColor="blue"
              onClick={e => {
                e.stopPropagation()
                onSave()
              }}
            >
              Save
            </TextButton>
            <TextButton
              disabled={disabled}
              textColor="grey"
              onClick={e => {
                e.stopPropagation()
                onCancel()
              }}
            >
              Cancel
            </TextButton>
          </>
        ) : (
          <>
            <Box alignSelf="center" mr={2}>
              <FileTag label={tagLabel} />
            </Box>
            {editable === true && (
              <Box mr={1} alignSelf="center">
                <LinkButton
                  onClick={e => {
                    e.stopPropagation()
                    onEdit()
                  }}
                >
                  Edit
                </LinkButton>
              </Box>
            )}

            {isDownloading ? (
              <IconButton disabled={true}>
                <CircularProgress size={20} />
              </IconButton>
            ) : (
              <IconButton
                disabled={disabled}
                onClick={async e => {
                  e.stopPropagation()
                  try {
                    setIsDownloading(true)
                    await onDownload(fileName ?? "")
                  } finally {
                    setIsDownloading(false)
                  }
                }}
              >
                <DownloadIcon />
              </IconButton>
            )}

            <IconButton
              disabled={disabled}
              onClick={e => {
                e.stopPropagation()
                onDelete()
              }}
            >
              <Cancel />
            </IconButton>
          </>
        )}
      </Box>
    </Paper>
  )
}

export default forwardRef(UploadedFile)
