import { Editor, Node, NodeEntry, Point, Range } from "slate"
import { CustomEditor, VariableElement } from "../CustomEditor"
import { INLINE_ELEMENTS } from "../elements"

export function getVariables(editor: CustomEditor, at?: Range | Point): NodeEntry<VariableElement>[] {
  const atRange = at || editor.selection

  if (!atRange) return []

  const variableNodeEntries = Editor.nodes<VariableElement>(editor, {
    at: atRange,
    match: node => Editor.isVoid(editor, node) && node.type === INLINE_ELEMENTS.VARIABLE,
  })

  return [...variableNodeEntries]
}

export function getVariable(editor: CustomEditor, at: Point): Nullable<NodeEntry<VariableElement>> {
  const variableNodeEntries = getVariables(editor, at)

  return variableNodeEntries.length ? variableNodeEntries[0] : null
}

export function isVariable(editor: CustomEditor, node: Node): node is VariableElement {
  return Editor.isInline(editor, node) && node.type === INLINE_ELEMENTS.VARIABLE
}

export function isVariableNode(node: Node): node is VariableElement {
  return "type" in node && node.type === INLINE_ELEMENTS.VARIABLE
}

export function isVariableHTMLNode(node: HTMLElement): boolean {
  return node.hasAttribute("data-variable-name")
}

export function getVariableHTMLNodeName(node: HTMLElement): string {
  return node.getAttribute("data-variable-name") || ""
}
