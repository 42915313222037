import React from "react"
import { Skeleton } from "@material-ui/lab"
import { DefaultPage } from "./styled"
import { Hidden } from "@material-ui/core"

interface PdfLoadingPageProps {
  scale: number
}

export function PdfLoadingPage({ scale }: PdfLoadingPageProps): JSX.Element {
  return (
    <DefaultPage scale={scale}>
      <Skeleton variant="rect" height={118} animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton width="60%" animation="wave" />
      <Hidden xsDown>
        <Skeleton height={118} animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton width="60%" animation="wave" />
      </Hidden>
    </DefaultPage>
  )
}
