import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { Attributes, RootAttributeTreeItem } from "common/types/attributes"
import { LibraryVariableDefinition } from "common/types/libraryVariables"
import { ProviderTemplateDefinition } from "common/types/providerTemplates"
import { CASE_SECTIONS } from "common/types/sections"
import { SectionTemplateDefinition } from "common/types/templates"

export interface TableViewModelCellData {
  key: string | number
  value: Nullable<string | number>
}

export interface SectionTemplateTableRowData {
  initialAttributes?: SectionTemplateDefinition["attributes"]
  initialSection?: CASE_SECTIONS
  initialContent?: EditorRoot
}

export interface LibraryVariableRowData {
  initialAttributes?: LibraryVariableDefinition["attributes"]
  initialGroupName?: string
  initialGroupId?: PrimaryKey
  initialVariableContent?: string
}

export interface ProviderTemplateRowData {
  initialContent?: EditorRoot
  initialTemplateName?: string
  initialGeneratorKey?: string
}

export class TableRowViewModel {
  readonly rowId: number
  readonly items: TableViewModelCellData[]
  readonly rowData: SectionTemplateTableRowData

  constructor(rowId: number, items: TableViewModelCellData[], rowData: SectionTemplateTableRowData) {
    this.rowId = rowId
    this.items = items
    this.rowData = rowData
  }
}

export class TemplateTableRowViewModel extends TableRowViewModel {
  constructor(
    rowId: number,
    items: TableViewModelCellData[],
    rowData: SectionTemplateTableRowData | LibraryVariableRowData | ProviderTemplateRowData
  ) {
    super(rowId, items, rowData)
  }
}

export interface TableViewModel {
  readonly headers: string[]
  readonly rows: TableRowViewModel[]
}

function getAttributeValue(
  attribute: RootAttributeTreeItem | undefined,
  templateAttributes: SectionTemplateDefinition["attributes"]
): string {
  if (!attribute) {
    return "—"
  }

  const value = attribute.value?.value

  if (!value) {
    const templateAttributeValue = templateAttributes.find(
      item => item.id === attribute.attribute.id
    )?.displayValue

    return templateAttributeValue ?? "—"
  }

  const childrenValue = attribute.children
    .map(child => child.value?.value)
    .filter((value): value is string => typeof value === "string")
    .join(", ")

  if (!childrenValue) return value

  return `${value} - ${childrenValue}`
}

export class SectionTemplatesTableViewModel implements TableViewModel {
  readonly headers: string[]
  readonly rows: TemplateTableRowViewModel[]

  constructor(templates: SectionTemplateDefinition[], attributes: Attributes) {
    const rootAttributes = attributes.getAttributesList({})

    this.headers = ["Template Name", ...rootAttributes.map(attribute => attribute.title), "Last Edited"]
    this.rows = templates.map(template => {
      const attributesTree = attributes.getAttributesTree(
        Object.fromEntries(template.attributes.map(attribute => [attribute.id, attribute.valueId]))
      )

      const items = [
        { key: "section", value: template.section },
        ...rootAttributes.map(attribute => {
          const item = attributesTree.find(item => item.attribute.id === attribute.id)

          return {
            key: attribute.id,
            value: getAttributeValue(item, template.attributes),
          }
        }),
        { key: "updatedAt", value: template.updatedAt },
      ]

      const rowData = {
        initialContent: template.content,
        initialAttributes: template.attributes,
        initialSection: template.section,
      }

      return new TemplateTableRowViewModel(template.id, items, rowData)
    })
  }
}

export class ProviderTemplatesTableViewModel implements TableViewModel {
  readonly headers: string[]
  readonly rows: TemplateTableRowViewModel[]

  constructor(providers: ProviderTemplateDefinition[]) {
    this.headers = ["Template Name", "Last Edited"]
    this.rows = providers.map(provider => {
      const items = [
        { key: "section", value: provider.templateName },
        { key: "updatedAt", value: provider.updatedAt },
      ]

      const rowData = {
        initialContent: provider.content,
        initialTemplateName: provider.templateName,
        initialGeneratorKey: provider.generatorKey,
      }

      return new TemplateTableRowViewModel(provider.id, items, rowData)
    })
  }
}

export class LibraryVariableTableViewModel implements TableViewModel {
  readonly headers: string[]
  readonly rows: TemplateTableRowViewModel[]

  constructor(variables: LibraryVariableDefinition[], attributes: Attributes) {
    const rootAttributes = attributes.getAttributesList({})

    this.headers = ["Variable Name", ...rootAttributes.map(attribute => attribute.title), "Last Edited"]
    this.rows = variables.map(vairable => {
      const attributesTree = attributes.getAttributesTree(
        Object.fromEntries(vairable.attributes.map(attribute => [attribute.id, attribute.valueId]))
      )

      const items = [
        { key: "name", value: vairable.groupName },
        ...rootAttributes.map(attribute => {
          const item = attributesTree.find(item => item.attribute.id === attribute.id)

          return {
            key: attribute.id,
            value: getAttributeValue(item, vairable.attributes),
          }
        }),
        { key: "updatedAt", value: vairable.updatedAt },
      ]

      const rowData = {
        initialGroupName: vairable.groupName,
        initialGroupId: vairable.groupId,
        initialVariableContent: vairable.content,
        initialAttributes: vairable.attributes,
      }

      return new TemplateTableRowViewModel(vairable.id, items, rowData)
    })
  }
}
