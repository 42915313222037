import React, { ReactElement, useEffect, useRef, useState } from "react"
import { Control, Controller, FieldErrors, FieldValues, Path, UseControllerProps } from "react-hook-form"
import { Button, CircularProgress, FormControlLabel, IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  GetApp as DownloadIcon,
} from "@material-ui/icons"
import clsx from "clsx"
import { get } from "lodash"

const useStyles = makeStyles(theme => ({
  inputWrapper: {
    "& .MuiFormControlLabel-root": {
      marginLeft: 0,
    },
    "& .MuiFormControlLabel-label": {
      marginLeft: theme.spacing(2),
    },
  },
}))

interface FileInputProps<TFields extends FieldValues> {
  control: Control<TFields>
  name: Path<TFields>
  rules?: UseControllerProps<TFields>["rules"]
  errors?: FieldErrors<TFields>
  label?: Nullable<string>
  className?: Nullable<string>
  accept?: string
  buttonLabel: string
  syncState: Partial<Record<keyof TFields, boolean>>
  onDelete: () => void
  onFileDownload: (fileName: string) => void
}

export function FileInput<T extends FieldValues>({
  control,
  label,
  buttonLabel,
  name,
  rules,
  accept,
  syncState = {},
  className,
  onDelete,
  onFileDownload,
}: FileInputProps<T>): ReactElement {
  const classes = useStyles()
  const inputRef = useRef<Nullable<HTMLInputElement>>(null)
  const buttonRef = useRef<Nullable<HTMLButtonElement>>(null)
  const [value, setValue] = useState("")
  const [isDownloading, setIsDownloading] = useState(false)
  const isUploading = Boolean(get(syncState, name))

  useEffect(() => {
    return () => {
      buttonRef.current = null
    }
  }, [])

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        const focusInput = () => inputRef.current?.click()
        const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value)

          if (event.target.files?.length) {
            field.onChange({ target: { value: event.target.files[0] } })

            // Here a fix for hidden input type="file" fields
            // In order to trigger form's blur - we need to blur some shown interactive element
            buttonRef.current?.focus()
            requestAnimationFrame(() => buttonRef.current?.blur())
          }
        }
        const onFileDelete = () => {
          setValue("")
          field.onChange({ target: { value: null } })
          onDelete()
        }

        return (
          <div className={clsx(classes.inputWrapper, className)}>
            <FormControlLabel
              control={
                <>
                  <input
                    type="file"
                    {...field}
                    value={value}
                    onChange={onChange}
                    ref={inputRef}
                    style={{ display: "none" }}
                    accept={accept}
                    disabled={isUploading}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={!isUploading ? <CloudUploadIcon /> : <CircularProgress size={20} />}
                    ref={buttonRef}
                    onKeyPress={focusInput}
                    onClick={focusInput}
                    disabled={isUploading}
                  >
                    {buttonLabel}
                  </Button>
                </>
              }
              label={label}
              labelPlacement="end"
            />

            {label && (
              <>
                {isDownloading ? (
                  <IconButton disabled={true}>
                    <CircularProgress size={20} />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={async () => {
                      try {
                        setIsDownloading(true)
                        await onFileDownload(label)
                      } finally {
                        setIsDownloading(false)
                      }
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                )}
                <IconButton onClick={onFileDelete}>
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </div>
        )
      }}
    />
  )
}
