import React from "react"
import {
  Details,
  DetailsRow,
  DetailsRowItems,
  RevisionDate,
  RevisionDescription,
  RevisionFiles,
} from "./styled"
import { REVISION_REQUEST_REASON_LABELS } from "./constants"
import { RevisionRequestEvent } from "common/models/revision-request-event"
import LinkButton from "common/buttons/LinkButton"
import { FileList } from "common/form-components/files/FileList"

export type RevisionRequestEventDetailsProps = {
  event: RevisionRequestEvent
} & EitherProps<
  {
    editable?: false
    onEdit?: undefined
  },
  {
    editable: true
    onEdit: () => void
  }
>

export const RevisionRequestEventDetails = ({
  event: { revisionRequest, createdAt },
  editable,
  onEdit,
}: RevisionRequestEventDetailsProps): JSX.Element => {
  return (
    <Details>
      <DetailsRow>
        <DetailsRowItems>
          <RevisionDate>{createdAt}</RevisionDate>
          {editable ? <LinkButton onClick={onEdit}>Edit Request</LinkButton> : null}
        </DetailsRowItems>
      </DetailsRow>
      <DetailsRow data-test="revision-details-reason">
        <strong>Reason for Revision:</strong> {REVISION_REQUEST_REASON_LABELS[revisionRequest.reason]}
      </DetailsRow>
      {revisionRequest.additionalInformation ? (
        <DetailsRow>
          <RevisionDescription>{revisionRequest.additionalInformation}</RevisionDescription>
        </DetailsRow>
      ) : null}
      {revisionRequest.uploadedFiles?.length ? (
        <DetailsRow>
          <RevisionFiles>
            <FileList files={revisionRequest.uploadedFiles} />
          </RevisionFiles>
        </DetailsRow>
      ) : null}
    </Details>
  )
}
