import React, { useMemo } from "react"
import { Chip } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"
import { Provider } from "../types"

import { useFeatures, FEATURES } from "hooks/useFeatures"

interface ProviderRowLabelProps {
  provider: Provider
  updates: { [key: string]: string }[]
}

const BoxChip = styled(Chip)(({ theme }) => ({
  borderRadius: "5px",
  fontStyle: "italic",
  fontSize: "10px",
  lineHeight: 1.4,
  letterSpacing: "-0.31px",
  marginRight: theme.spacing(1),
}))

const NEW_IMPORT = "New Import"
const IMPORT_UPDATED = "Import Updated"

const ProviderRowLabel = ({ provider, updates }: ProviderRowLabelProps): JSX.Element => {
  const { isFeatureEnabled } = useFeatures()
  const isProviderAutofillEnabled = isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL)

  const label = useMemo(() => {
    if (provider.newly_imported) {
      return NEW_IMPORT
    }

    if (updates.length) {
      return IMPORT_UPDATED
    }

    return null
  }, [provider, updates])

  if (!label || !isProviderAutofillEnabled) {
    return <></>
  }

  return <BoxChip label={label} variant="outlined" size="small" />
}

export { ProviderRowLabel as default }
