import React, { useCallback, useRef, useState, useMemo, useEffect } from "react"
import { useSlate } from "slate-react"
import TextButton, { TextButtonProps } from "common/buttons/TextButton"
import { MultiLevelMenu } from "common/multilevel-menu"
import { Variable } from "common/types/variables"
import { Editor } from "../../Editor"

interface AddVariableSideEffectHandlers {
  onOpen: () => void
  onClose: () => void
}

export interface AddVariableButtonProps extends TextButtonProps, AddVariableSideEffectHandlers {
  disabled: boolean
  onVariableSelect: (variable: Variable) => void
  onVariablePreview: (variable: Nullable<Variable>) => void
}

export function AddVariableButton({
  disabled,
  onVariableSelect,
  onVariablePreview,
  onOpen,
  onClose,
}: AddVariableButtonProps): Nullable<JSX.Element> {
  const [menuOpened, setMenuOpened] = useState(false)

  const menuAnchorRef = useRef<HTMLButtonElement>(null)
  const editor = useSlate()

  const handleMenuToggle = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      setMenuOpened(!menuOpened)
    },
    [menuOpened]
  )

  const handleMenuClose = useCallback(() => {
    setMenuOpened(false)
  }, [])

  const handleVariableSelect = useCallback(
    (variable: Variable) => {
      onVariableSelect(variable)

      requestAnimationFrame(() => {
        handleMenuClose()
      })
    },
    [handleMenuClose, onVariableSelect]
  )

  const handleVariableHover = useCallback(
    (variable: Nullable<Variable>) => {
      onVariablePreview(variable)
    },
    [onVariablePreview]
  )

  // Store handlers for side effects
  const sideEffectHandlers = useRef<AddVariableSideEffectHandlers>({ onOpen, onClose })
  // Pass latest props to handlers ref
  sideEffectHandlers.current = { onOpen, onClose }

  // Execute side effect from props when opened state changed
  useEffect(() => {
    if (menuOpened) {
      sideEffectHandlers.current.onOpen()
      return () => sideEffectHandlers.current.onClose()
    }
  }, [menuOpened, sideEffectHandlers])

  // Variables list from editor
  const variables = useMemo(
    () => (Editor.isVariablesEditor(editor) && menuOpened ? editor.variables.getItems() : []),
    [editor, menuOpened]
  )

  if (!Editor.isVariablesEditor(editor)) {
    return null
  }

  return (
    <>
      <TextButton
        aria-controls="categories-list"
        disabled={disabled}
        size="small"
        onMouseDown={handleMenuToggle}
        ref={menuAnchorRef}
      >
        + Add Variable
      </TextButton>

      <MultiLevelMenu
        items={variables}
        anchorRef={menuAnchorRef}
        open={menuOpened}
        group={"category"}
        label={"name"}
        displayLabel={"label"}
        onSelect={handleVariableSelect}
        onHover={handleVariableHover}
        onClose={handleMenuClose}
      />
    </>
  )
}
