import React, { useMemo } from "react"
import { SelectInput } from "../../common/form-components"
import { Control, UseFormWatch } from "react-hook-form"
import { EXHIBIT_GROUPING_OPTIONS, EXHIBIT_SORTING_OPTIONS } from "./constants"
import { EXHIBIT_GROUPING_VALUES, EXHIBIT_SORTING_VALUES } from "./enums"
import { FirmDetailDataFields } from "./FirmDetailFields"
import { ExhibitOrganization } from "./Firm"

export type EOSFields = Partial<FirmDetailDataFields> &
  Pick<ExhibitOrganization, "exhibit_grouping_option" | "exhibit_sorting_option">

interface SelectorsProps {
  control: Control<any>
  watch: UseFormWatch<any>
  className?: string
}

export const ExhibitOrganizationSelectors: React.FC<SelectorsProps> = ({
  control,
  watch,
  className,
}): JSX.Element => {
  const EXHIBIT_GROUPING_OPTIONS_WITHOUT_PER_PROVIDER = useMemo(() => {
    return EXHIBIT_GROUPING_OPTIONS.filter((value: FilterOption) => {
      return value.key !== EXHIBIT_GROUPING_VALUES.PER_PROVIDER
    })
  }, [])

  const getGroupingSettingOptions = (sortingOption: string) => {
    if (
      ![
        EXHIBIT_SORTING_VALUES.PER_PROVIDER_BILLS_AND_RECORDS,
        EXHIBIT_SORTING_VALUES.PER_PROVIDER_RECORDS_AND_BILLS,
      ].includes(sortingOption as EXHIBIT_SORTING_VALUES)
    ) {
      return EXHIBIT_GROUPING_OPTIONS_WITHOUT_PER_PROVIDER
    }

    return EXHIBIT_GROUPING_OPTIONS
  }

  const exhibitSortingOption = watch("exhibit_sorting_option")

  return (
    <>
      <SelectInput
        control={control}
        name="exhibit_sorting_option"
        label="Exhibit Sorting Rule"
        options={EXHIBIT_SORTING_OPTIONS}
        className={className}
      />

      <SelectInput
        control={control}
        name="exhibit_grouping_option"
        label="Combine Exhibit Option"
        options={getGroupingSettingOptions(exhibitSortingOption)}
        className={className}
        defaultValue={EXHIBIT_GROUPING_VALUES.INDIVIDUAL_FILES}
      />
    </>
  )
}
