import React, { useMemo } from "react"
import { Box, Avatar } from "@material-ui/core"
import { NoteAddOutlined as FileAddIcon, PhotoLibrary as PhotoLibraryIcon } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import LinkButton from "../../buttons/LinkButton"
import { ACCEPT_ALL_TYPE, ACCEPT_IMAGE_TYPE, ACCEPT_TYPE, DOCUMENTS, IMAGES } from "./constants"
import { fileTypeRestrictionText } from "./utils"

interface StyleProps {
  isDragActive: boolean
  isFocused: boolean
  disabled: boolean
}

const useStyles = makeStyles(theme => ({
  container: ({ isDragActive, isFocused, disabled }: StyleProps) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),

    border: disabled
      ? `2px dashed ${theme.palette.grey[400]}`
      : isDragActive || isFocused
      ? `2px dashed ${theme.palette.blue.main}`
      : `2px dashed ${theme.palette.grey[500]}`,
    borderRadius: "16px",
    cursor: disabled ? "not-allowed" : "pointer",
    backgroundColor: isDragActive ? theme.palette.blue.light : theme.palette.common.white,
  }),
  fileDrop: () => ({
    margin: theme.spacing(4),
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
    transition: "all .2s",
  }),
  fileDropAvatar: ({ isDragActive, disabled }: StyleProps) => ({
    width: "56px",
    height: "56px",
    backgroundColor: disabled
      ? theme.palette.grey[600]
      : isDragActive
      ? theme.palette.blue.main
      : theme.palette.primary.main,
    color: disabled ? theme.palette.grey[200] : theme.palette.primary.contrastText,
    transition: "background-color .2s",
  }),
  fileDropInstructions: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: "bold",
    marginBottom: theme.spacing(0.5),
  },
  fileDropRestrictions: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.grey[600],
  },
  childrenContainer: {
    "&:hover": {
      cursor: "default",
    },
  },
}))

interface Props {
  isDragActive: boolean
  isFocused: boolean
  disabled: boolean
  children: React.ReactNode
  fileTypes?: ACCEPT_TYPE
}

const getFileTypeStringByFileTypes = (fileTypes: ACCEPT_TYPE): string => {
  if (fileTypes === ACCEPT_IMAGE_TYPE) {
    return IMAGES
  }
  return DOCUMENTS
}

const getRestrictionsText = (fileTypes: ACCEPT_TYPE): string => {
  const suffix = "• No max limit"

  return `${fileTypeRestrictionText(fileTypes)} ${suffix}`
}

const getFileTypeIconByFileTypes = (fileTypes: ACCEPT_TYPE): JSX.Element => {
  if (fileTypes === ACCEPT_IMAGE_TYPE) {
    return <PhotoLibraryIcon fontSize="large" />
  }
  return <FileAddIcon fontSize="large" />
}

const FileDropper = ({
  isDragActive,
  isFocused,
  disabled,
  children,
  fileTypes = ACCEPT_ALL_TYPE,
}: Props): JSX.Element => {
  const classes = useStyles({ isDragActive, isFocused, disabled })

  const fileTypeString = useMemo(() => getFileTypeStringByFileTypes(fileTypes), [fileTypes])
  const icon = useMemo(() => getFileTypeIconByFileTypes(fileTypes), [fileTypes])
  const restrictionsText = useMemo(() => getRestrictionsText(fileTypes), [fileTypes])

  return (
    <Box className={classes.container}>
      <Box className={classes.fileDrop}>
        <Avatar className={classes.fileDropAvatar}>{icon}</Avatar>
        <Box>
          <Box className={classes.fileDropInstructions}>
            Drop {fileTypeString} here or{" "}
            <LinkButton disabled={disabled} small>
              browse
            </LinkButton>
          </Box>
          <Box className={classes.fileDropRestrictions}>{restrictionsText}</Box>
        </Box>
      </Box>
      {!!children && (
        <Box className={classes.childrenContainer} onClick={e => e.stopPropagation()}>
          {children}
        </Box>
      )}
    </Box>
  )
}

export default FileDropper
