import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { ExhibitGroup } from "./Exhibit"
import { Box, FormGroup, FormControlLabel, IconButton, Switch } from "@material-ui/core"
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons"
import { ExhibitsListGroupForm } from "./ExhibitsListGroupForm"

const useStyles = makeStyles(theme => ({
  groupTitle: {
    padding: theme.spacing(1),
    fontWeight: "bold",
  },
}))

interface ExhibitsListGroupProps {
  item: ExhibitGroup
  onChange: (item: ExhibitGroup) => void
  onDelete: (item: ExhibitGroup) => void
}

export const ExhibitsListGroup: React.FC<ExhibitsListGroupProps> = ({ item, onChange, onDelete }) => {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const onTitleToggle = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    item.render_title_page = checked
    onChange(item)
  }

  const handleChange = (item: ExhibitGroup) => {
    onChange(item)
    setIsEditing(false)
  }
  const handleClose = () => setIsEditing(false)

  if (isEditing) {
    return <ExhibitsListGroupForm item={item} onChange={handleChange} onClose={handleClose} />
  }

  return (
    <Box display="flex" flexDirection="row" justifyContent={"space-between"}>
      <Box>
        <span className={classes.groupTitle}>{item.name}</span>
        <IconButton size="medium" onClick={() => setIsEditing(true)}>
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton size="medium" onClick={() => onDelete(item)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box>
        <FormGroup>
          <FormControlLabel
            control={<Switch onChange={onTitleToggle} checked={item.render_title_page} color="primary" />}
            label={<i>Add Title Pages</i>}
          />
        </FormGroup>
      </Box>
    </Box>
  )
}
