import { Editor, Path, Transforms } from "slate"
import { CustomEditor, ListItemElement } from "../../CustomEditor"
import { LIST_ITEM_BLOCK_ELEMENTS } from "../../elements"
import { increaseListItemDepth } from "./increaseListItemDepth"
import { closestListNode, isListItem } from "./queries"

export function decreaseListItemDepth(editor: CustomEditor, at: Path): void {
  const [node] = Editor.node(editor, at)

  if (!Editor.isBlock(editor, node) || node.type !== LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM) return

  const list = closestListNode(editor, at)

  // Should never happen
  if (!list) return

  const [listNode, listPath] = list
  const index = at[at.length - 1] || 0
  const nextSiblings = listNode.children.slice(index + 1)

  nextSiblings.forEach(() => {
    increaseListItemDepth(editor, [...listPath, index + 1])
  })

  const itemEntryBefore = Editor.above<ListItemElement>(editor, {
    at: listPath,
    mode: "lowest",
    match: node => isListItem(editor, node),
  })

  Editor.withoutNormalizing(editor, () => {
    if (!itemEntryBefore) {
      const moveTo = Path.next(listPath)
      const [listItemContent, nestedList] = node.children

      if (nestedList?.children.length || nextSiblings.length) {
        const nestedListPath = [...at, 1]

        Transforms.setNodes(editor, { type: listNode.type }, { at: nestedListPath })
        Transforms.moveNodes(editor, { at: nestedListPath, to: moveTo })
      }

      Transforms.moveNodes(editor, {
        at: {
          anchor: Editor.start(editor, [...at, 0, 0]),
          focus: Editor.end(editor, [...at, 0, listItemContent.children.length - 1]),
        },
        to: moveTo,
      })
      Transforms.removeNodes(editor, { at })
    } else {
      const [, itemPathBefore] = itemEntryBefore
      const targetPath = [
        ...itemPathBefore.slice(0, -1),
        (itemPathBefore[itemPathBefore.length - 1] || 0) + 1,
      ]

      Transforms.moveNodes(editor, { at, to: targetPath })
    }

    if (index === 0) {
      Transforms.removeNodes(editor, { at: listPath })
    }
  })
}
