import { REQUEST_VIEW_TABS } from "./enums"

export const REQUEST_VIEW_URL_PREFIX: Record<REQUEST_VIEW_TABS, string> = {
  [REQUEST_VIEW_TABS.REQUEST]: "",
  [REQUEST_VIEW_TABS.MISSING_DOCS]: "missing-docs",
  [REQUEST_VIEW_TABS.REVISION]: "revision",
}

export const REQUEST_VIEW_HISTORY: Record<REQUEST_VIEW_TABS, string> = {
  [REQUEST_VIEW_TABS.REQUEST]: "Request Demand",
  [REQUEST_VIEW_TABS.MISSING_DOCS]: "Missing Documents",
  [REQUEST_VIEW_TABS.REVISION]: "Request Revision",
}
