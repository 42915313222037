import { SERVICE_WORKER_PATH } from "./constants"
import { workbox } from "./workbox"

export function getServiceWorker(): Promise<ServiceWorker> {
  return new Promise<ServiceWorker>((resolve, reject) => {
    Promise.race([
      workbox.getSW(),
      navigator.serviceWorker.getRegistration(SERVICE_WORKER_PATH).then(registration => {
        if (!registration || !registration.active || registration.waiting || registration.installing) {
          return workbox.getSW()
        }

        return registration.active
      }),
    ]).then(serviceWorker => {
      if (serviceWorker) {
        resolve(serviceWorker)
      } else {
        reject("No service worker found")
      }
    })
  })
}
