import React, { useCallback, useEffect, useRef, useState } from "react"
import { FormControl, IconButton } from "@material-ui/core"
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons"
import { ZoomControlWrapper, ZoomSelect, ZoomSelectMenuItem } from "./styled"

const PREDEFINED_ZOOM_VALUES = [25, 50, 75, 100, 125, 150, 175, 200]
const FIT_PAGE_ZOOM_VALUE = "fit_page" as const

interface ZoomControlProps {
  estimatedZoomRatio: number
  onChange: (value: Nullable<number>) => void
}

export function ZoomControl({ onChange, estimatedZoomRatio }: ZoomControlProps): JSX.Element {
  const [zoom, setZoom] = useState<typeof FIT_PAGE_ZOOM_VALUE | number>(FIT_PAGE_ZOOM_VALUE)

  const defaultZoom = useRef(estimatedZoomRatio * 100)
  defaultZoom.current = estimatedZoomRatio * 100

  const handleChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    setZoom(event.target.value as typeof FIT_PAGE_ZOOM_VALUE | number)
  }, [])

  const handleZoomIn = useCallback(() => {
    setZoom(currentZoom => {
      const zoomValue = currentZoom === FIT_PAGE_ZOOM_VALUE ? defaultZoom.current : currentZoom
      const nextZoomValue = PREDEFINED_ZOOM_VALUES.find(value => value > zoomValue) ?? zoomValue

      return nextZoomValue
    })
  }, [])

  const handleZoomOut = useCallback(() => {
    setZoom(currentZoom => {
      const zoomValue = currentZoom === FIT_PAGE_ZOOM_VALUE ? defaultZoom.current : currentZoom
      const nextZoomValue =
        [...PREDEFINED_ZOOM_VALUES].reverse().find(value => value < zoomValue) ?? zoomValue

      return nextZoomValue
    })
  }, [])

  useEffect(() => {
    onChange(zoom === FIT_PAGE_ZOOM_VALUE ? null : zoom / 100)
  }, [onChange, zoom])

  const canZoomIn = zoom !== PREDEFINED_ZOOM_VALUES[PREDEFINED_ZOOM_VALUES.length - 1]
  const canZoomOut = zoom !== PREDEFINED_ZOOM_VALUES[0]

  return (
    <ZoomControlWrapper id="zoom-control">
      <IconButton size="small" disabled={!canZoomOut} onClick={handleZoomOut}>
        <RemoveIcon />
      </IconButton>
      <FormControl size="small">
        <ZoomSelect value={zoom} onChange={handleChange}>
          <ZoomSelectMenuItem value={FIT_PAGE_ZOOM_VALUE}>Fit pages</ZoomSelectMenuItem>
          {PREDEFINED_ZOOM_VALUES.map(value => (
            <ZoomSelectMenuItem key={value} value={value}>
              {value}%
            </ZoomSelectMenuItem>
          ))}
        </ZoomSelect>
      </FormControl>
      <IconButton size="small" disabled={!canZoomIn} onClick={handleZoomIn}>
        <AddIcon />
      </IconButton>
    </ZoomControlWrapper>
  )
}
