import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Tooltip, Avatar } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  avatar: {
    height: theme.spacing(3.5),
    width: theme.spacing(3.5),
    fontSize: theme.spacing(1.5),
    marginRight: theme.spacing(0.5),
  },
}))

const AssigneeAvatar = ({ firstName, lastName }) => {
  const label = `${firstName[0] ?? ``}${lastName[0] ?? ``}`
  const fullName = `${firstName} ${lastName}`

  const classes = useStyles()

  return (
    <Tooltip title={fullName}>
      <Avatar className={classes.avatar}>{label}</Avatar>
    </Tooltip>
  )
}

export { AssigneeAvatar as default }
