import React, { useRef } from "react"
import { Box, Button, List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core"
import {
  CloudUpload as CloudUploadIcon,
  AttachFile as AttachFileIcon,
  DeleteForever,
} from "@material-ui/icons"
import { default as mergeRefs } from "react-merge-refs"
interface FileUploaderProps {
  accept?: string
  files: File[]
  // eslint-disable-next-line no-unused-vars
  setFiles: (files: File[]) => void
}

export const FileUploader = React.forwardRef((props: FileUploaderProps, ref) => {
  function removeFile(file: File) {
    props.setFiles(props.files.filter(f => f != file))
  }

  // The state stored here is simply to display the files. Use the input values for form submission
  const inputRef = useRef<HTMLInputElement>(null)
  return (
    <Box>
      <List>
        {Array.from(props.files).map(file => {
          return (
            <ListItem key={file.name}>
              <ListItemIcon>
                <AttachFileIcon />
              </ListItemIcon>
              <ListItemText> {file.name}</ListItemText>
              <Button onClick={() => removeFile(file)}>
                <DeleteForever />
              </Button>
            </ListItem>
          )
        })}
      </List>
      <input
        type="file"
        accept={props.accept || "*"}
        multiple
        style={{ visibility: "hidden" }}
        onChange={event => {
          props.setFiles([...props.files, ...(event.target.files || [])])
        }}
        ref={mergeRefs([inputRef, ref])}
      />
      <Box>
        <Button
          startIcon={<CloudUploadIcon />}
          variant="outlined"
          color="primary"
          onClick={() => inputRef?.current?.click()}
        >
          Upload
        </Button>
      </Box>
    </Box>
  )
})
FileUploader.displayName = "FileUploader"

export default FileUploader
