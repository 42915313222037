import React, { useState } from "react"
import { Box, Button, Typography, List, Divider, CircularProgress } from "@material-ui/core"
import { CloudDownloadOutlined as DownloadIcon } from "@material-ui/icons"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { downloadAll, getAnnotationRequest } from "./api"
import { queryKeys } from "../react-query/constants"
import { makeStyles } from "@material-ui/core/styles"
import { dateFormat, getAPIServerURL, timeFormat } from "../utils"
import { GenericError, Loading } from "../common"
import AnnotationRequestView from "./AnnotationFileView"
import { config } from "../common/config"
import { EmptyResponse } from "../apiHelper"

const useStyles = makeStyles(theme => ({
  contentBox: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "70%",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  actionLeft: {
    justifySelf: "flex-start",
    margin: theme.spacing(1),
  },
  actionRight: {
    justifySelf: "flex-end",
    margin: theme.spacing(1),
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}))

export default function AnnotationRequest(): JSX.Element {
  const classes = useStyles()
  const { id: requestIdParam } = useParams()
  const [isWorking, setIsWorking] = useState<boolean>(false)
  const requestId = parseInt(requestIdParam ?? "")
  const { data, isLoading, error } = useQuery([queryKeys.annotationRequests, requestId], getAnnotationRequest)

  if (isLoading) {
    return <Loading />
  }

  if (error || !data || data instanceof EmptyResponse) {
    return <GenericError />
  }

  function downloadAllFiles(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, requestId: number) {
    setIsWorking(true)
    downloadAll(requestId)
      .catch(() => null /* Evenutally we'll handle errors here */)
      .finally(() => setIsWorking(false))
  }

  return (
    <>
      <Box className={classes.contentBox}>
        {!isLoading && !!data && !!data.create_date && (
          <>
            <Typography variant="h4">{data.name}</Typography>
            <Typography variant="body1">
              Created on {dateFormat.format(new Date(data.create_date))} at
              {timeFormat.format(new Date(data.create_date))}.
            </Typography>
            <Box className={classes.actions}>
              <Box>
                <Button
                  className={classes.actionLeft}
                  variant="outlined"
                  target="_blank"
                  href={`${config.kili.endpoint}/label/projects/${config.kili.annotation_project_id}/explore?metadata%5Brequest_id%5D=${requestId}`}
                >
                  Kili
                </Button>
              </Box>
              <Box>
                <Button
                  className={classes.actionRight}
                  variant="outlined"
                  onClick={e => downloadAllFiles(e, data.pk)}
                >
                  {isWorking ? (
                    <>
                      <CircularProgress color="secondary" size="1em" className={classes.buttonIcon} />
                      Working...
                    </>
                  ) : (
                    <>
                      <DownloadIcon className={classes.buttonIcon} /> Download all as zip
                    </>
                  )}
                </Button>
                <Button
                  className={classes.actionRight}
                  variant="outlined"
                  disabled={!data?.json_annotations}
                  href={`${getAPIServerURL()}/annotation/${data.pk}/download-summary`}
                >
                  <DownloadIcon className={classes.buttonIcon} /> Download Summary and Companion Doc
                </Button>
              </Box>
            </Box>

            <Typography variant="h5">Files</Typography>
            <Divider />
            <List>
              {data?.annotation_files
                .sort((left, right) => {
                  if (left.order_number === -1) {
                    return 1
                  }

                  if (right.order_number === -1) {
                    return -1
                  }

                  return left.order_number - right.order_number
                })
                .map(file => (
                  <AnnotationRequestView key={file.pk} file={file} requestId={requestId} />
                ))}
            </List>
          </>
        )}
      </Box>
    </>
  )
}
