import React from "react"
import { Alert as MuiAlert, AlertTitle, Color } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: theme.spacing(1),
  },
}))

interface Props {
  title: string
  severity: Color
  children: JSX.Element[] | JSX.Element | string
}

const Alert = ({ title, severity = "warning", children }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <MuiAlert severity={severity} className={classes.alert}>
      <AlertTitle>{title}</AlertTitle>
      {children}
    </MuiAlert>
  )
}

export { Alert as default }
