import { SPLIT_LIMIT } from "./constants"
import { Chunk, ChunkedFile } from "./types"

export function* getChunks(file: File): Generator<Chunk> {
  const fileSize = file.size
  const chunkCount = Math.ceil(fileSize / SPLIT_LIMIT)

  for (let i = 0; i < chunkCount; i++) {
    const start = i * SPLIT_LIMIT
    const possibleEnd = (i + 1) * SPLIT_LIMIT
    const end = Math.min(fileSize, possibleEnd)
    const chunkBlob = Blob.prototype.slice.call(file, start, end, file.type)

    yield {
      file: chunkBlob,
      offset: i,
    }
  }
}

export function getChunkedFile(file: File): ChunkedFile {
  const chunks = Array.from(getChunks(file))

  return {
    file,
    name: file.name,
    lastModified: file.lastModified,
    chunkCount: chunks.length,
    chunks,
  }
}
