import React, { useCallback, useEffect, useMemo, useState } from "react"
import { LibraryUseForm, NonUniqueAttributesError } from "common/template-form/types"
import { queryClient } from "react-query/queryClient"
import { ProviderTemplateFormData as ProviderTemplateFormDataType } from "./types"
import { FORM_STATE_STATUS } from "./constants"
import { TemplateFormMessage } from "./TemplateFormMessage"
import { ProviderTemplateFormData } from "./ProviderTemplateFormData"
import { useMutation, useQuery } from "react-query"
import { queryKeys } from "react-query/constants"
import { variablesService } from "api/services/variables"
import { summariesApiService } from "api/services/summaries"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import { NewProviderTemplate } from "common/types/providerTemplates"
import { providerTemplateService } from "api/services/provider-template"

export const useProviderTemplateForm: LibraryUseForm = ({
  entityId,
  onFinish,
  initialData,
  formStatusMessageMap,
}) => {
  const [formStatus, setFormStatus] = useState<FORM_STATE_STATUS>(FORM_STATE_STATUS.IDLE)
  const [formData, setFormData] = useState<Nullable<ProviderTemplateFormDataType>>(null)

  const { data: variables } = useQuery(queryKeys.defaultVariables, variablesService.getDefaultCaseVariables, {
    keepPreviousData: true,
  })

  const { data: generatorKeys } = useQuery(
    queryKeys.generatorKeys,
    summariesApiService.getGeneratorTemplates,
    {
      retry: false,
    }
  )

  const onSuccess = useCallback(() => {
    setFormStatus(FORM_STATE_STATUS.SUCCESS)
  }, [])

  const onError = useCallback((error: unknown) => {
    if (error instanceof NonUniqueAttributesError) {
      setFormStatus(FORM_STATE_STATUS.DATA_ERROR)
    } else {
      setFormStatus(FORM_STATE_STATUS.API_ERROR)
    }
  }, [])

  const mutationOptions = useMemo(
    () => ({
      onSuccess: () => {
        onSuccess()
        onFinish()

        queryClient.invalidateQueries(queryKeys.providerTemplates)
      },
      onError: (error: unknown) => onError(error),
    }),
    [onSuccess, onFinish, onError]
  )

  const createTemplateMutation = useMutation(providerTemplateService.createProviderTemplate, mutationOptions)
  const updateTemplateMutation = useMutation(providerTemplateService.updateProviderTemplate, mutationOptions)

  const clearForm = useCallback(() => {
    setFormStatus(FORM_STATE_STATUS.IDLE)
  }, [])

  const handleCancel = useCallback(() => {
    onFinish()
    clearForm()
  }, [onFinish, clearForm])

  const handleSubmit = useCallback(() => {
    if (!formData || !formData.templateName) {
      return setFormStatus(FORM_STATE_STATUS.LACK_OF_DATA)
    }

    const data: NewProviderTemplate = {
      content: formData.content,
      templateName: formData.templateName,
      generatorKey: formData.generatorKey,
    }

    if (typeof entityId === "undefined") {
      createTemplateMutation.mutate({ data })
    } else {
      updateTemplateMutation.mutate({ options: { templateId: entityId }, data })
    }
  }, [createTemplateMutation, updateTemplateMutation, formData, entityId])

  const message = useMemo(() => {
    if (formStatus === FORM_STATE_STATUS.IDLE) return null

    const messageText = formStatusMessageMap[formStatus]

    if (!messageText) return null

    return <TemplateFormMessage clear={clearForm} message={messageText} formStatus={formStatus} />
  }, [clearForm, formStatus, formStatusMessageMap])

  const viewForm = useMemo(() => {
    return <RichTextEditor readonly value={initialData.initialContent} />
  }, [initialData])

  const editForm = useCallback(
    (footer: Nullable<JSX.Element>) => (
      <>
        <ProviderTemplateFormData
          initialContent={initialData.initialContent}
          initialTemplateName={initialData.initialTemplateName}
          initialGeneratorKey={initialData.initialGeneratorKey}
          onChange={setFormData}
          error={formStatus === FORM_STATE_STATUS.DATA_ERROR}
          variables={variables}
          generatorKeys={generatorKeys}
          highlightEmptyFields={formStatus === FORM_STATE_STATUS.LACK_OF_DATA}
        />
        {footer}
      </>
    ),
    [formStatus, initialData, variables, generatorKeys]
  )

  const errorForm = formStatus === FORM_STATE_STATUS.API_ERROR || formStatus === FORM_STATE_STATUS.DATA_ERROR

  useEffect(() => {
    clearForm()
  }, [formData, clearForm])

  const isLoading = createTemplateMutation.isLoading || updateTemplateMutation.isLoading

  return { message, editForm, handleSubmit, handleCancel, isLoading, viewForm, clearForm, errorForm }
}
