import React, { CSSProperties, memo, useCallback, useEffect, useRef, useState } from "react"
import { Page } from "react-pdf"
import { PDFPageProxy } from "pdfjs-dist/types/src/display/api"
import { PageWrapper, StyledPage } from "./styled"
import { SCALE, US_LETTER_SIZE } from "./constants"
import { PdfLoadingPage } from "./PdfLoadingPage"
import { PdfErrorPage } from "./PdfErrorPage"

export interface PdfPageData {
  onPageLoaded(pageProxy: PDFPageProxy, scale: number): void
  scale: number
  isControlledScale: boolean
  viewportWidth: number
  deletedPages: Set<number>
}

export interface PdfPageProps {
  data: PdfPageData
  index: number
  style: CSSProperties
}

export const PdfPage = memo(function PdfPage({
  data: { onPageLoaded, scale: initialScale, viewportWidth, deletedPages, isControlledScale },
  index,
  style,
}: PdfPageProps): JSX.Element {
  const [scale, setScale] = useState(initialScale)
  const pdfPageProxyRef = useRef<PDFPageProxy>()
  const pageNumber = index + 1
  const placeholderScale = isControlledScale
    ? scale / SCALE
    : Math.min(viewportWidth / US_LETTER_SIZE.WIDTH, 1)

  const handlePageLoad = useCallback(
    (pageProxy: PDFPageProxy) => {
      const possibleWidth = pageProxy.getViewport({ scale: initialScale }).width
      const actualScale =
        !isControlledScale && possibleWidth / viewportWidth > 1
          ? initialScale / ((possibleWidth + 1) / viewportWidth)
          : initialScale

      setScale(actualScale)
      pdfPageProxyRef.current = pageProxy
      onPageLoaded(pageProxy, actualScale)
    },
    [viewportWidth, onPageLoaded, initialScale, isControlledScale]
  )

  useEffect(() => {
    if (pdfPageProxyRef.current) {
      handlePageLoad(pdfPageProxyRef.current)
    }
  }, [viewportWidth, handlePageLoad])

  return (
    <PageWrapper id={`pdf_page_${pageNumber}`} style={style}>
      <StyledPage deleted={deletedPages.has(pageNumber)}>
        <Page
          pageNumber={pageNumber}
          key={pageNumber}
          loading={<PdfLoadingPage scale={placeholderScale} />}
          error={<PdfErrorPage scale={placeholderScale} />}
          onLoadSuccess={handlePageLoad}
          scale={scale}
        />
      </StyledPage>
    </PageWrapper>
  )
})
