import React from "react"
import { useQuery } from "react-query"
import { queryKeys } from "react-query/constants"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { getAvailableSteps, getStepStatuses } from "api"
import { caseService } from "api/services/case"
import { useFeatures, FEATURES } from "hooks/useFeatures"
import { ROUTES_TO_SECTION } from "./constants"
import { DemandStepsProvider } from "./DemandStepsProvider"
import { CaseStepConfig, CaseStepInfo, CASE_ATTRIBUTES_STEP } from "./types"
import { useHandleMessages } from "common/messages/useHandleMessages"
import useUser from "hooks/useUser"
import { OSF } from "common/models/roles"

export const DemandSteps: React.FC = ({ children }) => {
  const { id: caseIdParam } = useParams()
  const caseId: Nullable<PrimaryKey> = typeof caseIdParam !== "undefined" ? parseInt(caseIdParam) : null
  const {
    user: { role: userRole },
  } = useUser()
  const navigate = useNavigate()
  const location = useLocation()
  const { showMessage } = useHandleMessages()

  if (caseId === null) {
    navigate("/demands")
  }

  const { isFeatureEnabled } = useFeatures()
  const enableCaseAttributesStep = isFeatureEnabled(FEATURES.CASE_ATTRIBUTES)

  const { data: availableSteps } = useQuery(
    [queryKeys.formSections, "-formatted"],
    async () => {
      const { results }: { results: CaseStepConfig[] } = await getAvailableSteps()

      if (enableCaseAttributesStep) {
        results.unshift({
          key: CASE_ATTRIBUTES_STEP,
          display: "Select Templates",
          label: "Select from the attributes below in order to choose templates for this demand.",
          optional: false,
        })
      }

      return results
    },
    { enabled: caseId !== null }
  )

  const { data: stepsWithStatus } = useQuery(
    [queryKeys.steps, caseId],
    async args => {
      return (await getStepStatuses(args)) as CaseStepInfo[]
    },
    {
      onError: err => {
        if ((err as any)?.response?.status === 404) {
          if (userRole === OSF) {
            showMessage({
              type: "error",
              message:
                "You are not authorized to access this demand request, it might be unassigned or marked as Completed",
            })
            setTimeout(() => {
              navigate("/demands")
            }, 3000)
          } else {
            showMessage({
              type: "error",
              message: "Demand not found or you are not authorized to access this demand request",
            })
          }
        }
      },
      enabled: caseId !== null,
    }
  )

  const { data: caseInfo } = useQuery(
    [queryKeys.case, caseId, "-serialized"],
    () => caseService.getCase({ caseId: caseId as PrimaryKey }),
    { enabled: caseId !== null, keepPreviousData: true }
  )

  const [path] = location.pathname.split("/").reverse()
  const section = ROUTES_TO_SECTION[path]

  if (!caseInfo || !stepsWithStatus || !availableSteps) {
    return null
  }

  return (
    <DemandStepsProvider
      caseInfo={caseInfo}
      statuses={stepsWithStatus}
      steps={availableSteps}
      section={section}
    >
      {children}
    </DemandStepsProvider>
  )
}
