import { CaseInfo } from "api/services/case/types"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import React, { useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { FORM_SECTION_ROUTES } from "./constants"
import { CaseStepsContext } from "./context"
import { CASE_STEP } from "./enums"
import { getCurrentCaseSection } from "./getCaseSection"
import {
  CASE_STEP_STATUS,
  CaseStepConfig,
  CASE_SECTIONS,
  CaseStepItem,
  CaseSteps,
  CASE_ATTRIBUTES_STEP,
  CaseStepInfo,
} from "./types"

export interface DemandStepsProviderProps {
  caseInfo: CaseInfo
  statuses: CaseStepInfo[]
  steps: CaseStepConfig[]
  section: CASE_SECTIONS
}

export const DemandStepsProvider: React.FC<DemandStepsProviderProps> = ({
  caseInfo,
  statuses,
  steps,
  section,
  children,
}) => {
  const navigate = useNavigate()
  const { isFeatureEnabled } = useFeatures()
  const isCaseTemplatesEnabled = isFeatureEnabled(FEATURES.CASE_ATTRIBUTES)
  const isProvidersTemplatesEnabled = isFeatureEnabled(FEATURES.PROVIDER_TEMPLATES)

  useEffect(() => {
    const currentStep = getCurrentCaseSection(section, caseInfo, statuses)

    if (currentStep !== section) {
      navigate(FORM_SECTION_ROUTES[currentStep])
    }
  }, [section, caseInfo, statuses, navigate])

  const items = useMemo<CaseStepItem[]>(
    () =>
      steps.map((stepConfig, index) => {
        const stepInfo: CaseStepInfo | undefined = statuses.find(({ step }) => step === stepConfig.key)
        const showCaseFactsConflict = stepConfig.key === CASE_STEP.FACTS && isCaseTemplatesEnabled
        const showProvidersConflict = stepConfig.key === CASE_STEP.PROVIDERS && isProvidersTemplatesEnabled
        const isReviewNeeded = stepInfo?.conflict && (showCaseFactsConflict || showProvidersConflict)

        return {
          step: stepConfig.key,
          title: stepConfig.display,
          subtitle: stepConfig.label,
          index,
          optional: Boolean(stepConfig.optional),
          isCompleted:
            stepConfig.key === CASE_ATTRIBUTES_STEP
              ? caseInfo.templatedSections.length > 0
              : stepInfo?.status === CASE_STEP_STATUS.COMPLETED,
          isSkipped: stepInfo?.status === CASE_STEP_STATUS.SKIPPED,
          isReviewNeeded,
          disabled: getCurrentCaseSection(stepConfig.key, caseInfo, statuses) !== stepConfig.key,
        }
      }),
    [steps, statuses, isCaseTemplatesEnabled, isProvidersTemplatesEnabled, caseInfo]
  )

  const currentItem = useMemo(() => items.find(item => item.step === section) ?? null, [items, section])
  const nextItem = useMemo<Nullable<CaseStepItem>>(
    () => (currentItem ? items[currentItem.index + 1] ?? null : null),
    [items, currentItem]
  )
  const context = useMemo<CaseSteps>(
    () => ({
      caseId: caseInfo.pk,
      items,
      section,
      currentStep: currentItem,
      nextStep: nextItem,
    }),
    [items, currentItem, nextItem, section, caseInfo]
  )

  return <CaseStepsContext.Provider value={context}>{children}</CaseStepsContext.Provider>
}
