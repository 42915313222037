import { Box, Button, Slide } from "@material-ui/core"
import { TransitionProps } from "@material-ui/core/transitions"
import DialogForm from "common/dialog/DialogForm"
import React, { useCallback, useState } from "react"
import { styled } from "@material-ui/core/styles"
import ContractsCancelForm from "./ContractsCancelForm"
import { useForm } from "react-hook-form"
import { dateDisplay } from "utils"
import { useMutation, useQueryClient } from "react-query"
import { cancelContracts } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { queryKeys } from "react-query/constants"

const SlideLeftTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />
})

const DetailItem = styled(Box)({
  display: "flex",
  flexDirection: "row",
})

const MinorTitle = styled(Box)(({ theme }) => ({
  fontWeight: "bold",
  letterSpacing: "normal",
  fontSize: "14px",
  marginRight: theme.spacing(1),
}))

interface CancelContractDialogsProps {
  cancelFormOpen: boolean
  handleCancelFormOpen: () => unknown
  handleCancelFormClose: () => unknown
  firmId: string
}

const CancelContractDialogs: React.FC<CancelContractDialogsProps> = ({
  cancelFormOpen,
  handleCancelFormOpen,
  handleCancelFormClose,
  firmId,
}) => {
  const DEFAULT_VALUES = { cancellation_date: "", cancellation_note: "" }
  const {
    control: cancelContractControl,
    handleSubmit: cancelContractSubmit,
    getValues: getCancelContractValues,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: DEFAULT_VALUES,
  })

  const [cancelConfirmOpen, setCancelConfirmOpen] = useState(false)
  const handleCancelConfirmOpen = useCallback(() => {
    handleCancelFormClose()
    setCancelConfirmOpen(true)
  }, [setCancelConfirmOpen, handleCancelFormClose])
  const handleCancelConfirmClose = useCallback(() => {
    handleCancelFormOpen()
    setCancelConfirmOpen(false)
  }, [setCancelConfirmOpen, handleCancelFormOpen])
  const handleAllClose = useCallback(() => {
    handleCancelFormClose()
    setCancelConfirmOpen(false)
  }, [handleCancelFormClose, setCancelConfirmOpen])

  const { showMessage } = useHandleMessages()
  const queryClient = useQueryClient()

  const { isLoading, mutate } = useMutation(cancelContracts, {
    onSuccess: () => {
      handleAllClose()
      showMessage({ type: "success", message: "Contracts have been cancelled." })
      // invalidate contracts so contract log gets updated with new data
      queryClient.invalidateQueries(queryKeys.firmContracts)
      // invalidate firm so current contract gets updated with new data
      queryClient.invalidateQueries(queryKeys.firm)
    },
    onError: () => {
      showMessage({
        type: "error",
        message:
          "An error occurred while trying to cancel contracts. Please try again shortly and report the issue if your problem persists.",
      })
    },
  })

  return (
    <>
      <DialogForm
        open={cancelFormOpen}
        onClose={handleCancelFormClose}
        header={"Cancel All Contracts"}
        buttons={[
          <Button
            onClick={handleCancelFormClose}
            type="button"
            key="cancel-contract-close"
            variant="contained"
            disableElevation
          >
            Close
          </Button>,
          <Button
            type="submit"
            form="contracts-cancel-form"
            key="cancel-contract-review"
            variant="contained"
            disableElevation
            color="primary"
            disabled={!isDirty}
            data-test="cancel-contract-review"
          >
            Review Cancellation
          </Button>,
        ]}
        buttonPlacement="right"
      >
        <ContractsCancelForm
          id="contracts-cancel-form"
          onReviewCancellation={handleCancelConfirmOpen}
          control={cancelContractControl}
          handleSubmit={cancelContractSubmit}
          errors={errors}
        />
      </DialogForm>
      <DialogForm
        open={cancelConfirmOpen || isLoading}
        onClose={handleCancelConfirmClose}
        TransitionComponent={SlideLeftTransition}
        header="Review & Confirm Cancellation"
        buttons={[
          <Button
            onClick={handleCancelConfirmClose}
            type="button"
            key="confirm-cancel-close"
            variant="contained"
            disableElevation
            disabled={isLoading}
          >
            Edit / Go Back
          </Button>,
          <Button
            key="cancel-contract-review"
            variant="contained"
            disableElevation
            color="secondary"
            onClick={() =>
              mutate({
                firmId,
                cancellation_date: getCancelContractValues("cancellation_date"),
                cancellation_note: getCancelContractValues("cancellation_note"),
              })
            }
            disabled={isLoading}
            data-test="confirm-cancel-all-contracts"
          >
            {isLoading ? "Cancelling all contracts" : "Yes, cancel all contracts"}
          </Button>,
        ]}
        buttonPlacement="right"
      >
        <>
          <Box color="red" fontWeight={600} mb={3}>
            Cancel all contracts for this firm.
          </Box>
          <DetailItem mb={1.5}>
            <MinorTitle>Cancellation date: </MinorTitle>
            <Box data-test="review-cancellation-date">
              {dateDisplay(getCancelContractValues("cancellation_date"))}
            </Box>
          </DetailItem>
          <Box mb={1}>
            <MinorTitle>Notes: </MinorTitle>
          </Box>
          <Box data-test="review-cancellation-note">{getCancelContractValues("cancellation_note")}</Box>
        </>
      </DialogForm>
    </>
  )
}

export default CancelContractDialogs
