import React, { useCallback, useMemo } from "react"
import { DEFAULT_VALUE } from "./defaultValue"
import { EditorRoot } from "./CustomEditor"
import { EditorFeatureProps } from "./features/types"
import { RichTextEditor } from "./RichTextEditor"
import { deserializeFromMarkdown } from "./serializer/markdown/deserializer"
import { serializeToMarkdown } from "./serializer/markdown/serializer"
import { RichTextWithActions } from "./RichTextWithActions"

interface RichTextInputOnChangeArg {
  value: EditorRoot
  markdownValue: string
}

export interface RichTextInputProps {
  value: Nullable<EditorRoot>
  markdownValue: Nullable<string>
  dataTest?: string
  disabled?: boolean
  name?: string
  error?: boolean
  label?: string
  helperText?: string
  actions?: Nullable<React.ReactElement>
  onChange: (values: RichTextInputOnChangeArg) => void
}

export function RichTextInput({
  value,
  markdownValue,
  onChange,
  dataTest,
  disabled,
  error,
  helperText,
  label,
  actions,
  name,
  ...featureProps
}: RichTextInputProps & EditorFeatureProps): JSX.Element {
  const editorValue = useMemo<EditorRoot>(() => {
    if (value && value.length > 0) return value
    if (markdownValue) return deserializeFromMarkdown(markdownValue) as EditorRoot
    return DEFAULT_VALUE
  }, [value, markdownValue])

  const handleChange = useCallback(
    (nextValue: EditorRoot) => {
      onChange({
        value: nextValue,
        markdownValue: serializeToMarkdown(nextValue),
      })
    },
    [onChange]
  )

  if (disabled) {
    return <RichTextEditor value={editorValue} readonly name={name} />
  }

  return (
    <RichTextWithActions label={label} actions={actions} helperText={helperText}>
      <RichTextEditor
        value={editorValue}
        onChange={handleChange}
        keepValue={true}
        dataTest={dataTest}
        name={name}
        error={error}
        {...featureProps}
      />
    </RichTextWithActions>
  )
}
