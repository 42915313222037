import { useState } from "react"

interface Response {
  isOpen: boolean
  openDialog: () => void
  closeDialog: () => void
  toggleDialog: () => void
}

export function useDialog(): Response {
  const [isOpen, setIsOpen] = useState(false)
  const openDialog = () => setIsOpen(true)
  const closeDialog = () => setIsOpen(false)
  const toggleDialog = () => setIsOpen(!isOpen)

  return {
    isOpen,
    openDialog,
    closeDialog,
    toggleDialog,
  }
}
