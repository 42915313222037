import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  tabContent: {
    padding: theme.spacing(2),
  },
}))

interface TabProps {
  className: string
  title: string
}

export const Tab: React.FC<TabProps> = ({ className, children }) => {
  const classes = useStyles()

  return <Box className={clsx(classes.tabContent, className)}>{children}</Box>
}
