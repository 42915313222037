import React from "react"
import { TableViewModel } from "common/models/library"
import { Loading } from "common"
import EmptyState from "common/tables/EmptyState"
import { useSectionTemplateForm } from "common/template-form/useSectionTemplateForm"
import { useLibraryVariableForm } from "common/template-form/useLibraryVariableForm"
import { FORM_STATE_STATUS } from "common/template-form/constants"
import { LibraryTableRow } from "./LibraryTableRow"
import {
  StyledTable,
  StyledTableHeaderRow,
  StyledTableHeaderItem,
  StyledTableBody,
  StyledLoadingRow,
} from "./styled"

interface LibraryTableProps {
  tableData: Nullable<TableViewModel>
  loading?: boolean
  getFormData: typeof useSectionTemplateForm | typeof useLibraryVariableForm
  formStatusMessageMap: Record<FORM_STATE_STATUS, Nullable<JSX.Element>>
  entityName: string
}

export function LibraryTable({
  tableData,
  loading,
  getFormData,
  formStatusMessageMap,
  entityName,
}: LibraryTableProps): Nullable<JSX.Element> {
  if (!tableData) return null

  const columnWidth = Math.floor(75 / tableData.headers.length)

  return (
    <StyledTable>
      <thead>
        <StyledTableHeaderRow>
          {tableData.headers.map(header => (
            <StyledTableHeaderItem width={`${columnWidth}%`} key={header}>
              {header}
            </StyledTableHeaderItem>
          ))}
          <StyledTableHeaderItem width="25%" />
        </StyledTableHeaderRow>
      </thead>
      <StyledTableBody>
        {tableData.rows.map(row => (
          <LibraryTableRow
            key={row.rowId}
            row={row}
            getFormData={getFormData}
            formStatusMessageMap={formStatusMessageMap}
            entityName={entityName}
          />
        ))}
        {tableData.rows.length === 0 && (
          <tr>
            <td colSpan={tableData.headers.length + 1}>
              <EmptyState message={`No ${entityName}`} imageSize={"40px"} fontSize="medium" />
            </td>
          </tr>
        )}
        {loading && (
          <StyledLoadingRow>
            <td colSpan={tableData.headers.length + 1}>
              <Loading show label={`Loading ${entityName}...`} inline />
            </td>
          </StyledLoadingRow>
        )}
      </StyledTableBody>
    </StyledTable>
  )
}
