import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { RadioButtons } from "../../common/form-components"
import { useRequestContext } from "../context"

import { POLICY_TYPES, POLICY_COVERAGE_TYPE_MAP } from "../constants"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
  },
  buttons: {
    margin: theme.spacing(0, 4, 0, 0),
  },
}))

export const PolicyInformation = () => {
  const { control, watch } = useRequestContext()
  const classes = useStyles()

  const policyType = watch("policy_type")

  return (
    <Box className={classes.container}>
      <RadioButtons
        className={classes.buttons}
        control={control}
        name="policy_type"
        options={POLICY_TYPES}
        label="Policy Type"
      />
      <RadioButtons
        className={classes.buttons}
        control={control}
        name="policy_coverage_type"
        options={POLICY_COVERAGE_TYPE_MAP.get(policyType)}
        label="Policy Coverage Type"
      />
    </Box>
  )
}
