import React, { useEffect, useState } from "react"
import { Link as RouterLink, Outlet } from "react-router-dom"
import { Box, Fab, FormControl, TextField } from "@material-ui/core"
import { Add as AddIcon } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { useDebounce } from "use-debounce"
import useUser from "../../hooks/useUser"

import { queryKeys } from "../../react-query/constants"
import { getCases, updatePageSize } from "../../api"

import PaginatedTable from "../../common/tables/PaginatedTable"
import FirmFilter from "../../common/tables/filters/FirmFilter"
import UserFilter from "../../common/tables/filters/UserFilter"
import { columns } from "./tableCells"
import { GenericError } from "../../common"
import { canUserSeeUserFilter } from "../permissions"
import { useSearchState } from "../../hooks/useSearchState"

const useStyles = makeStyles(theme => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  container: {
    margin: theme.spacing(3, 0),
  },
  filtersContainer: {
    display: "flex",
  },
  filterContainer: {
    minWidth: theme.spacing(20),
    marginRight: theme.spacing(2),
  },
  searchBar: {
    marginLeft: "auto",
    minWidth: theme.spacing(35),
  },
}))

const DEFAULT_SORT_FIELD = "date_of_incident"
const ASC = "asc"
const DESC = "desc"

const TableFilters = ({
  setPage,
  setFilterFirmId,
  setFilterCreatedBy,
  setSearchQuery,
  filterFirmId,
  filterCreatedBy,
  searchQuery,
}) => {
  const classes = useStyles()
  const { user } = useUser()
  const handleFirmIdFilterChange = e => {
    setPage(0)
    setFilterFirmId(e.target.value)
  }

  const handleCreatedByFilterChange = e => {
    setPage(0)
    setFilterCreatedBy(e.target.value)
  }

  const handleSearchQueryChange = e => {
    setPage(0)
    // don't replace history when value is empty
    // this creates a distinct entry when clearing input
    // so you can use back button to return to input before clearing
    setSearchQuery(e.target.value, { replace: e.target.value ? true : false })
  }

  return (
    <Box className={classes.filtersContainer}>
      <FirmFilter
        value={filterFirmId}
        onChange={handleFirmIdFilterChange}
        className={classes.filterContainer}
      />
      {canUserSeeUserFilter(user.role) && (
        <UserFilter
          value={filterCreatedBy}
          onChange={handleCreatedByFilterChange}
          className={classes.filterContainer}
          label={"Created By"}
        />
      )}

      <FormControl className={classes.searchBar}>
        <TextField
          label={"Search"}
          value={searchQuery}
          onChange={handleSearchQueryChange}
          variant="outlined"
          placeholder="Search by case name"
          data-test="search-demand"
        />
      </FormControl>
    </Box>
  )
}

const CaseTableAndFilters = ({ pageSize, setPageSize, user }) => {
  const [sortBy, setSortBy] = useState(DEFAULT_SORT_FIELD)
  const [sortDirection, setSortDirection] = useState(DESC)
  const [page, setPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const [filterFirmId, setFilterFirmId] = useSearchState("firmId", 0, "number")
  const [filterCreatedBy, setFilterCreatedBy] = useSearchState("createdBy", 0, "number")
  const [searchQuery, setSearchQuery] = useSearchState("query", "", "string")
  const [debouncedSearchQuery] = useDebounce(searchQuery, 250)

  const { data: cases, error } = useQuery(
    [
      queryKeys.cases,
      {
        page: page + 1,
        pageSize,
        sortBy,
        sortDirection,
        filterFirmId,
        filterCreatedBy,
        searchQuery: debouncedSearchQuery,
      },
    ],
    getCases,
    {
      onSuccess: data => {
        setTotalCount(data.count)
      },
    }
  )

  if (error) return <GenericError />

  return (
    <>
      <TableFilters
        setPage={setPage}
        setFilterFirmId={setFilterFirmId}
        setFilterCreatedBy={setFilterCreatedBy}
        setSearchQuery={setSearchQuery}
        filterFirmId={filterFirmId}
        filterCreatedBy={filterCreatedBy}
        searchQuery={searchQuery}
      />
      <PaginatedTable
        records={cases?.results}
        columns={columns}
        onSortClick={id => {
          if (id !== sortBy) {
            setPage(0)
            setSortBy(id)
          } else {
            setSortDirection(sortDirection === DESC ? ASC : DESC)
          }
        }}
        sortDirection={sortDirection}
        sortBy={sortBy}
        page={page}
        onPageChange={setPage}
        totalCount={totalCount}
        pageSize={pageSize}
        onRowsPerPageChange={setPageSize}
        userRole={user?.role}
        emptyMessage="No Cases"
      />
    </>
  )
}

export function CaseList({ setLastSearchParams }) {
  const queryClient = useQueryClient()
  const classes = useStyles()
  const { user } = useUser(queryKeys.userSettings)
  const pageSize = user.rowsPerPage

  const mutateInternalUser = useMutation(updatePageSize, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.userSettings)
    },
  })

  const setPageSize = value => {
    return mutateInternalUser.mutateAsync(value)
  }

  useEffect(() => {
    setLastSearchParams(null)
  }, [setLastSearchParams])

  return (
    <Box className={classes.container}>
      <CaseTableAndFilters setPageSize={setPageSize} pageSize={pageSize} user={user} />
      <RouterLink to={"new"}>
        <Fab aria-label="add" color="secondary" className={classes.fab}>
          <AddIcon />
        </Fab>
      </RouterLink>
      <Outlet />
    </Box>
  )
}
