export enum LEAF_BLOCK_ELEMENTS {
  PARAGRAPH = "paragraph",
  // HEADING_1 = "heading_1",
  // HEADING_2 = "heading_2",
}

export enum LIST_BLOCK_ELEMENTS {
  ORDERED_LIST = "ordered_list",
  UNORDERED_LIST = "unordered_list",
}

export enum LIST_ITEM_BLOCK_ELEMENTS {
  LIST_ITEM = "list_item",
  LIST_ITEM_CONTENT = "list_item_content",
}

export type ELEMENTS = LEAF_BLOCK_ELEMENTS | LIST_BLOCK_ELEMENTS | LIST_ITEM_BLOCK_ELEMENTS

export enum INLINE_ELEMENTS {
  SOFT_LINE_BREAK = "soft_line_break",
  VARIABLE = "variable",
}
