import React, { useCallback, useState } from "react"
import { isEqual } from "lodash"
import styled from "@emotion/styled"
import { useCaseVariables } from "demand/Variables"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import { QueryKey, useMutation, useQuery } from "react-query"
import { Box, Button, CircularProgress, IconButton } from "@material-ui/core"
import { CaseEditorInput } from "demand/components/CaseEditor"
import { Provider } from "demand/Providers/types"
import { caseService } from "api/services/case"
import { ProviderSummaryResult } from "api/services/summaries/types"
import { summariesApiService } from "api/services/summaries"
import { Action, UPDATE_INJURY_DETAILS, UPDATE_TEMPLATE } from "demand/Providers/reducer"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { TextVariable } from "common/types/variables"
import { TemplatePreview } from "demand/components/TemplatePreview"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { DEFAULT_VALUE } from "common/form-components/rich-text/defaultValue"
import { ReactComponent as MagicIcon } from "common/images/sparkle.svg"
import { StyledNoTemplate } from "demand/components/styled"
import { queryClient } from "react-query/queryClient"
import { queryKeys } from "react-query/constants"

const ActionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}))

type ProviderFormEditorInvalidationProps = {
  invalidateQueryKeys?: QueryKey[]
}

type ProviderFormEditorProps = {
  provider: Provider
  dispatch: React.Dispatch<Action>
  variables: TextVariable[]
  caseId: PrimaryKey
  disabled: boolean
}

interface RequestSummaryProps {
  onRequestSummary: () => void
  loading: boolean
}

const RequestSummary = ({ onRequestSummary, loading }: RequestSummaryProps) => (
  <>
    {!loading && (
      <IconButton onClick={onRequestSummary}>
        <MagicIcon />
      </IconButton>
    )}
    {loading && <CircularProgress size="1.5rem" />}
  </>
)

export function ProviderFormEditor({
  variables: customVariables = [],
  dispatch,
  provider,
  disabled,
  caseId,
}: ProviderFormEditorInvalidationProps & ProviderFormEditorProps): JSX.Element {
  const { variables } = useCaseVariables(customVariables)
  const { isFeatureEnabled } = useFeatures()
  const { showMessage } = useHandleMessages()
  const [summaryRequested, setSummaryRequested] = useState(false)
  const [intervalMs, setIntervalMs] = useState<number | false>(false)
  const [forceRefresh, setForceRefresh] = useState(false)
  const isProviderTemplateFeatureEnabled = isFeatureEnabled(FEATURES.PROVIDER_TEMPLATES)
  const hasTemplate = isProviderTemplateFeatureEnabled && provider?.templated_sections?.[0]
  const isReviewNeeded = provider?.templated_sections?.[0]?.user_action_required ?? false
  const templateContent = provider?.templated_sections?.[0]?.template?.content

  const { mutate: resolveConflict } = useMutation(caseService.overrideProviderTemplate, {
    onSuccess: data => {
      dispatch({
        type: UPDATE_TEMPLATE,
        payload: { providerId: provider.pk, template: data },
      })
      queryClient.invalidateQueries([queryKeys.steps, caseId])
    },
    onError: () => {
      showMessage({ type: "error", message: "There was an error while trying to update provider." })
    },
  })

  const handleChangeInjuryDetails = useCallback(
    ({ value, markdownValue }: { value: EditorRoot; markdownValue: string }) => {
      const customContent = hasTemplate ? value : null

      dispatch({
        type: UPDATE_INJURY_DETAILS,
        payload: { providerId: provider.pk, markdownValue, value, customContent },
      })
    },
    [provider, dispatch, hasTemplate]
  )

  const handleResolve = useCallback(() => {
    if (!provider?.templated_sections?.[0]?.template || !provider.details_json) return

    resolveConflict({
      data: { customContent: provider.details_json },
      caseId,
      providerId: provider.pk,
      templateSectionId: provider?.templated_sections[0]?.pk,
    })
  }, [caseId, provider.pk, provider?.templated_sections, provider?.details_json, resolveConflict])

  const { data: summaryData } = useQuery(
    [queryKeys.providerSummary, provider.pk],
    () => summariesApiService.getProviderSummary({ providerId: provider.pk, refresh: forceRefresh }),
    {
      onSuccess: (data: ProviderSummaryResult) => {
        const hasSummary = data?.status === "success"
        setForceRefresh(false)
        if (hasSummary) {
          setSummaryRequested(false)
          setIntervalMs(false)
        }
      },
      onError: () => {
        showMessage({
          type: "error",
          message: "Error getting provider summary",
        })
        setSummaryRequested(false)
        setIntervalMs(false)
        setForceRefresh(true)
      },
      enabled: summaryRequested || !!intervalMs,
      meta: { disableLoader: true },
      // Refetch the data every second
      refetchInterval: intervalMs,
      retry: false,
    }
  )

  const handleRequestSummary = useCallback(() => {
    setSummaryRequested(true)
    // poll every 1s
    setIntervalMs(1000)
  }, [setSummaryRequested, setIntervalMs])

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box fontSize="13px" fontWeight="600">
          Injury details
        </Box>
        <ActionsContainer>
          {isReviewNeeded ? (
            <Button variant="contained" disableElevation size="small" onClick={handleResolve}>
              Resolve
            </Button>
          ) : null}
          <RequestSummary onRequestSummary={handleRequestSummary} loading={summaryRequested} />
        </ActionsContainer>
      </Box>
      <CaseEditorInput
        key={provider.templated_sections?.[0]?.template?.pk ?? "provider-injury-details"}
        name="injury-details"
        dataTest="injury-details"
        error={isReviewNeeded}
        // actions={}
        disabled={disabled}
        onChange={handleChangeInjuryDetails}
        variables={variables}
        value={provider.details_json}
        markdownValue={provider.details}
      />
      {isReviewNeeded &&
        templateContent &&
        (isEqual(templateContent, DEFAULT_VALUE) ? (
          <StyledNoTemplate>No template is returned</StyledNoTemplate>
        ) : (
          <TemplatePreview
            key={`${provider.templated_sections?.[0]?.template?.pk}-preview`}
            template={templateContent}
            variables={variables}
          />
        ))}
      {summaryData?.results && (
        <TemplatePreview
          key={`${provider.templated_sections?.[0]?.template?.pk}-summary`}
          template={summaryData.results}
          templateHeader="AI Generated Summary"
          variables={variables}
        />
      )}
    </>
  )
}
