import { isNil, isNull, memoize, pickBy, reduce } from "lodash"
import { addMinutes, format, formatDistance, parse } from "date-fns"

export const getAPIServerURL = () => {
  if (!window?.location?.hostname) return process.env.REACT_APP_API_SERVER || ""

  const result =
    window.location.hostname === "localhost" ? "http://localhost:8000" : process.env.REACT_APP_API_SERVER

  return result || ""
}

export const partialAddress = address => {
  if (
    !(address.street && address.city && address.state && address.zip_code) &&
    (address.street || address.city || address.state || address.zip_code)
  ) {
    return true
  }
  return false
}

export function getChangedFields(data, formState) {
  return pickBy(data, (value, key) => !!formState.dirtyFields[key])
}

export function cleanNulls(data) {
  const nullValues = pickBy(data, isNull)
  const nullToEmptyString = reduce(
    nullValues,
    function (result, value, key) {
      // set null value to empty string
      result[key] = ""
      return result
    },
    {}
  )
  return { ...data, ...nullToEmptyString }
}

export function getCommonMutateOptions({
  setErrors,
  reset,
  setValue,
  setSavedSuccessfully,
  showErrorMessage,
  onSuccessExtra,
}) {
  return {
    onMutate: () => {
      setErrors && setErrors(null)
    },
    onSuccess: data => {
      reset(data, { keepValues: true })
      if (data?.pk) {
        setValue && setValue("pk", data.pk)
      }
      setSavedSuccessfully && setSavedSuccessfully(true)
      onSuccessExtra && onSuccessExtra(data)
    },
    onError: error => {
      if (error?.response?.status === 400) {
        let errorObject = isNil(error?.message) ? null : JSON.parse(error?.message)
        return setErrors && setErrors(errorObject)
      }
      if (error?.response?.status === 500) {
        // in dev env full HTML message will be too large to display on a snackbar
        return showErrorMessage && showErrorMessage()
      }
    },
  }
}

export const formatTimeSinceNow = timestamp => {
  return formatDistance(new Date(timestamp), new Date(), { addSuffix: true })
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(Number(d))
}

export const amountInDollars = amount => {
  const numAmount = parseFloat(amount, 10)
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(numAmount)
}

export const stringListToArray = value => {
  return value ? JSON.parse(value.replaceAll(/'/g, "")).map(item => item.toString()) : []
}

// memoize so result can be used in useEffect/useCallback dependency arrays
export const getObjectIds = memoize(objs => {
  if (objs) {
    return objs.map(obj => obj.pk)
  } else {
    return []
  }
})

export const formatDate = (timestamp, formatString = "MMM do, yyy", ignoreLocalTimezone = false) => {
  const NOT_APPLICABLE = "N/A"
  if (!timestamp) {
    return NOT_APPLICABLE
  }

  const date = new Date(timestamp)
  if (ignoreLocalTimezone && !dateIsMidnight(date)) {
    return format(addMinutes(date, date.getTimezoneOffset()), formatString)
  }

  return format(date, formatString) ?? NOT_APPLICABLE
}

export const dateFormat = new Intl.DateTimeFormat("en-US")
export const timeFormat = new Intl.DateTimeFormat("en-US", {
  hour: "numeric",
  minute: "numeric",
})

export const formatDateWithTime = (timestamp, formatString = "MMM do, yyy", ignoreLocalTimezone = false) => {
  const NOT_APPLICABLE = "N/A"
  if (!timestamp) {
    return NOT_APPLICABLE
  }

  const formattedDate = formatDate(timestamp, formatString, ignoreLocalTimezone)
  const formattedTime = timeFormat.format(timestamp).replace(/\s/g, "").toLowerCase()

  return `${formattedDate} ${formattedTime}`
}

export const reorderImmutable = (array, startIndex, endIndex) => {
  // duplicate array
  const result = Array.from(array)
  // swap the start and end index
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const getFileExtension = name => {
  if (name) {
    const parts = name.split(".")
    if (parts.length > 1) {
      return "." + parts.pop()
    } else {
      return ""
    }
  }
}

export const dateDisplay = (dateString, defaultValue = "mm/dd/yyyy") => {
  if (!dateString) return defaultValue
  const date = parse(dateString, "yyyy-MM-dd", new Date())
  return date.toLocaleDateString()
}

export function JSONparseWithDefaultValue(string, defaultValue = {}) {
  try {
    return JSON.parse(string)
  } catch (e) {
    return defaultValue
  }
}

export const setFormErrors = (error, setError) => {
  const errorObj = JSON.parse(error?.message) ?? {}
  for (const [key, value] of Object.entries(errorObj)) {
    let name = key
    let currentValue = value
    let errorMessage = currentValue[0]

    while (typeof errorMessage !== "string") {
      const newKey = Object.keys(currentValue)[0]
      name = `${name}.${newKey}`
      currentValue = currentValue[newKey]
      errorMessage = currentValue[0]
    }

    setError(name, { type: "custom", message: errorMessage })
  }
}

/**
 * Returns the last day of the given month by year
 * @param {int} year - Year value
 * @param {int} month - Month value - NOTE: Month is offset by 1 (Jan = 0, Feb = 1, ...)
 * @returns int - day of the month
 */
export const getLastDayOfMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate()
}

export const dateIsMidnight = date => {
  return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0
}

// 2022-12-25 --> // 12/25/2022
export const toUSDateString = internationalDateStr => {
  const [year, month, day] = internationalDateStr.split("-")
  return `${month}/${day}/${year}`
}

// 1 -> 1st, 10 -> 10th
export const ordinalSuffixOf = value => {
  const i = value % 10
  const j = value % 100

  if (i == 1 && j != 11) {
    return value + "st"
  }
  if (i == 2 && j != 12) {
    return value + "nd"
  }
  if (i == 3 && j != 13) {
    return value + "rd"
  }
  return value + "th"
}
