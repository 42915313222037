import React, { useCallback } from "react"
import { Box, Collapse, IconButton } from "@material-ui/core"
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from "@material-ui/icons"

import CommaEntryList from "./LineEntryList"

import { Content, DateTitle, Header, ImportantMedicalPassageContainer, InjuryDetailContainer } from "./styled"
import { useStyles } from "../styled"

import { ImportantMedicalPassage, InjuryDetail } from "./types"
import { Entry } from "../types"
import ContainerLabel from "../ContainerLabel"
import { formatDate } from "utils"

interface InjuryDetailRecordProps {
  date: string
  injuryDetails: InjuryDetail
  onEntryClick: (entry: Entry) => void
  open: boolean
  onToggleOpen: (date: string) => void
  annotationExtractUpdated: boolean
}

const InjuryDetailRecord = ({
  date,
  injuryDetails,
  onEntryClick,
  open,
  onToggleOpen,
  annotationExtractUpdated,
}: InjuryDetailRecordProps): JSX.Element => {
  const classes = useStyles()
  const {
    medical_professionals = [],
    statements_on_causality = [],
    important_medical_passages = [],
    referrals = [],
  } = injuryDetails

  const handleEntryClick = useCallback(
    (entry: Entry) => {
      onEntryClick(entry)
    },
    [onEntryClick]
  )

  return (
    <InjuryDetailContainer>
      <Header>
        <DateTitle>{formatDate(date, "MMMM dd, yyyy", true)}</DateTitle>
        <Box display={"flex"}>
          <Box mr={2}>
            <ContainerLabel includeAnnotationExtractUpdated={annotationExtractUpdated} />
          </Box>
          <IconButton
            size="small"
            onClick={() => {
              onToggleOpen(date)
            }}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </Header>
      <Collapse in={open}>
        <Content>
          <CommaEntryList
            data={medical_professionals}
            title={"Attended by:"}
            onEntryClick={onEntryClick}
            separator={","}
          />
          <CommaEntryList
            data={statements_on_causality}
            title={"Statement on Causality:"}
            onEntryClick={onEntryClick}
            separator={";"}
          />
          <CommaEntryList data={referrals} title={"Referrals:"} onEntryClick={onEntryClick} separator={";"} />

          <Box>
            {important_medical_passages.map((importantMedicalPassage: ImportantMedicalPassage, index) => {
              const { value, entries } = importantMedicalPassage
              return (
                <ImportantMedicalPassageContainer
                  mb={2}
                  key={`${index}_${value}`}
                  className={classes.entry}
                  onClick={() => {
                    handleEntryClick(entries[0])
                  }}
                >
                  {value}
                </ImportantMedicalPassageContainer>
              )
            })}
          </Box>
        </Content>
      </Collapse>
    </InjuryDetailContainer>
  )
}

export { InjuryDetailRecord as default }
