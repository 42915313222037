import React from "react"
import { styled, Theme } from "@material-ui/core/styles"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
} from "@material-ui/core"
import { Close as CloseIcon, ExpandMore as ExpandMoreIcon } from "@material-ui/icons"
import { FILE_UPLOAD_STATE_TYPE } from "../file-state/states"
import { getIconColorByStatus, getOpacityByStatus } from "./utils"

export const UploadViewRoot = styled(Box)(({ theme }) => ({
  minWidth: theme.spacing(40),
  background: theme.palette.background.paper,
  color: theme.palette.text.primary,
  position: "absolute",
  right: "2%",
  bottom: "2%",
  zIndex: theme.zIndex.modal,
}))

export const FilesAccordion = styled(Accordion)({
  fontSize: "0.9em",
  "& .MuiAccordionDetails-root": {
    paddingTop: "0px",
  },
})

export const UploadSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: "1em !important",
  "& .MuiAccordionSummary-content": {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  "& MuiButtonBase-root": {
    flexDirection: "row-reverse",
  },
  "& .MuiIconButton-root.MuiAccordionSummary-expandIcon": {
    paddingLeft: 0,
  },

  "& span:not(:first-of-type)": {
    marginLeft: theme.spacing(1),
  },
}))

UploadSummary.defaultProps = {
  expandIcon: <ExpandMoreIcon />,
}

export const CloseButton = styled(Button)(({ theme }) => ({
  minWidth: "10px",
  color: theme.palette.action.active,
  padding: 0,
  marginRight: theme.spacing(2),
  "& .MuiButton-label": {
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

CloseButton.defaultProps = {
  children: <CloseIcon />,
  size: "small",
}

export const FileListContainer = styled(AccordionDetails)({
  maxHeight: "200px",
  maxWidth: "400px",
  width: "100%",
  overflowX: "auto",
  overflowY: "auto",
  "& .MuiList-root": {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
})

export const FileList = styled(List)({
  width: "100%",
})

FileList.defaultProps = {
  dense: true,
  "aria-label": "contacts",
}

interface FileListItemProps {
  status: keyof FILE_UPLOAD_STATE_TYPE
}

export const FileListItem = styled(ListItem)<Theme, FileListItemProps>(({ theme, status }) => ({
  paddingLeft: 0,
  "& .MuiTypography-body2": {
    fontSize: "0.9em",
  },
  opacity: getOpacityByStatus(status),

  "& .MuiListItemText-root": {
    textDecoration: status === "CANCELLED" ? "line-through" : "none",
  },

  "& .MuiListItemIcon-root": {
    color: getIconColorByStatus(status, theme),
  },
}))

FileListItem.defaultProps = {
  dense: true,
}

export const FileListItemIcon = styled(ListItemIcon)({
  minWidth: "1em",
  marginRight: "0.5em",
  fontSize: "0.6em",
  fontWeight: "bold",
})
