import React from "react"
import { Box } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"

import { AttachedFileInterface } from "../interfaces"
import AttachedFile from "../AttachedFile"
import { SECTIONS } from "../constants"

const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",

  padding: "16px 0",
}))

const FileContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: theme.spacing(0, 0, 2, 0),
  padding: "8px 24px",
  border: "solid 1px #BEBEBE",
  borderRadius: theme.spacing(1),
}))

const Title = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1rem",
  marginBottom: theme.spacing(1),
}))

const Subtitle = styled(Box)(() => ({
  fontWeight: 600,
  fontSize: "1rem",
}))

const Description = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: 1.36,
  marginBottom: theme.spacing(2),
}))

interface Props {
  title: string
  description: string
  files: AttachedFileInterface[]
}

type sectionMap = {
  [name: string]: string
}

type FileMap = Record<SECTIONS, AttachedFileInterface[]> | Record<string, never>

const CommunicationItem = ({ title, description, files }: Props): JSX.Element => {
  const filesMap: FileMap = files.reduce((previousValue: FileMap, currentValue: AttachedFileInterface) => {
    if (!!currentValue.section && previousValue[currentValue.section] === undefined) {
      previousValue[currentValue.section] = [currentValue]
    } else if (currentValue.section) {
      previousValue[currentValue.section].push(currentValue)
    }
    return previousValue
  }, {})
  const filesMapEntries = Object.entries(filesMap)
  const sectionMap: sectionMap = {
    facts: "Case Facts Section",
    providers: "Providers Information Section",
    income_loss: "Loss of Income Section",
    household_loss: "Loss of Household Services Section",
  }

  return (
    <Container data-test="communication-item">
      {/* TODO: Include when we decide what to show */}
      <Subtitle>{title}</Subtitle>
      <Description data-test="communication-description">{description}</Description>
      {filesMapEntries.map(([section, sectionFiles]: [string, AttachedFileInterface[]]) => {
        return (
          sectionFiles?.length && (
            <>
              <Title>{sectionMap[section]}</Title>
              <FileContainer>
                {sectionFiles.map((file: AttachedFileInterface) => {
                  return (
                    <AttachedFile
                      key={`attached-file-${file.pk}`}
                      pk={file.pk}
                      name={file.name}
                      file_url={file.file_url}
                      date_created={file.date_created}
                      accepted={file.accepted}
                      allowDownload={true}
                      questionnaire_id={file.questionnaire_id}
                    />
                  )
                })}
              </FileContainer>
            </>
          )
        )
      })}
    </Container>
  )
}

export { CommunicationItem as default }
