import React, { useRef } from "react"
import { FormControl, InputLabel, Select } from "@material-ui/core"

const ListFilter = ({ onChange, value, className = "", label, children, id }) => {
  const labelRef = useRef()
  const labelWidth = labelRef.current ? labelRef.current.clientWidth : 0

  return (
    <FormControl variant="outlined" className={className}>
      <InputLabel ref={labelRef} id={`${label}-label`}>
        {label}
      </InputLabel>
      <Select
        data-test={id}
        labelId={`${label}-label`}
        label={label}
        labelWidth={labelWidth}
        onChange={onChange}
        value={value}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export { ListFilter as default }
