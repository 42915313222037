import React, { useState } from "react"
import { Link, Outlet } from "react-router-dom"
import {
  Box,
  Fab,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
} from "@material-ui/core"
import { Add as AddIcon } from "@material-ui/icons"

import { makeStyles } from "@material-ui/core/styles"
import { useQuery } from "react-query"
import { queryKeys } from "../react-query/constants"
import { getAnnotationRequests } from "./api"
import { GenericError } from "../common"
import { EmptyResponse } from "../apiHelper"

const useStyles = makeStyles(theme => ({
  contentBox: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  footer: {
    margin: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
}))

export default function AnnotationList(): JSX.Element {
  const pageSize = 100
  const [page, setPage] = useState<number>(1)
  const { data, isLoading, error } = useQuery([queryKeys.annotationRequests, page], async () =>
    getAnnotationRequests({ page, pageSize })
  )
  const classes = useStyles()

  if (isLoading) return <Box>Loading...</Box>
  if (error || !data || data instanceof EmptyResponse) {
    return <GenericError />
  }
  const pages = Math.ceil(data.count / pageSize)

  return (
    <Box className={classes.contentBox}>
      <Typography variant="h4">Annotation Requests</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell size="small">Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.results?.map((item, index: number) => (
            <TableRow key={item.pk}>
              <TableCell>
                <Link to={"/annotation/" + item.pk}>
                  <Typography key={index}>{item.name}</Typography>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box className={classes.footer}>
        <Typography>
          Page {page} / {pages}
        </Typography>
        <Box>
          <Button onClick={() => setPage(page - 1)} disabled={page <= 1}>
            Prev
          </Button>
          <Button onClick={() => setPage(page + 1)} disabled={page >= pages}>
            Next
          </Button>
        </Box>
      </Box>
      <Link to={"new"}>
        <Fab className={classes.fab} aria-label="add" color="secondary">
          <AddIcon />
        </Fab>
      </Link>
      <Outlet />
    </Box>
  )
}
