import {
  Box,
  BoxProps,
  CircularProgress,
  Divider,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core"
import { useTheme, styled, Theme } from "@material-ui/core/styles"
import { GetApp, InsertDriveFileOutlined, MoreHoriz } from "@material-ui/icons"
import { downloadExhibit } from "api"
import { DEFAULT_WINDOW_CONFIGURATIONS } from "app/constants"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import React, { useEffect, useRef, useState, useCallback, useMemo } from "react"
import { AnnotationDuplicate, AnnotationSplit } from "./AnnotationPartition"
import { comparePageRanges } from "./cacheUtils"
import DeletePagesBox from "./DeletePagesBox"
import DuplicateBox from "./DuplicateBox"
import ResetDialog from "./ResetDialog"
import SplitBox from "./SplitBox"
import { AnnotationFileIconButton, BoldText, Text } from "./styled"
import {
  AnnotationStatus as AnnotationStatusType,
  PartitionProvider,
  Duplicate,
  Split,
  CaseId,
  Action,
  DeleteAction,
} from "./types"

const STATUS_TO_TEXT: Record<AnnotationStatusType, string> = {
  pre_annotating: "Pre-Annotation In Progress",
  annotating: "Annotation In Progress",
  auto_complete: "Automated Annotation Completed",
  complete: "Annotation Completed",
}

interface AnnotationStatusProps extends BoxProps {
  status: AnnotationStatusType
}

const AnnotationStatus: React.FC<AnnotationStatusProps> = ({ status, ...boxProps }) => {
  const theme = useTheme()
  const color = theme.palette.annotation[status]

  return (
    <Box
      borderRadius={theme.shape.borderRadius}
      px={1}
      py={0.5}
      bgcolor={color}
      color={theme.palette.common.white}
      {...boxProps}
    >
      <Text>{STATUS_TO_TEXT[status]}</Text>
    </Box>
  )
}

interface AdditionalClickableBoxProps {
  clickable: boolean
}

type ClickableBoxProps = AdditionalClickableBoxProps & Omit<BoxProps, keyof AdditionalClickableBoxProps>

const ClickableBox = styled((props: ClickableBoxProps) => {
  const { clickable: _clickable, ...rest } = props
  return <Box {...rest} />
})<Theme, AdditionalClickableBoxProps>(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(1, 2),
  alignItems: "center",
  justifyContent: "space-between",
  "&:hover": {
    backgroundColor: props =>
      props.clickable ? theme.palette.action.hover : theme.palette.background.default,
    cursor: props => (props.clickable ? "pointer" : "default"),
  },
}))

const FileName = styled(Typography)(() => ({
  maxWidth: "350px",
  overflowWrap: "break-word",
  fontSize: "12px",
}))

const FileNameLink = styled("button")({
  border: "none",
  background: "none",
  padding: 0,
  margin: 0,
  font: "inherit",
  cursor: "pointer",
})

const AnnotationFilesDetailsPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(1),
  borderTop: `3px solid ${theme.palette.common.black}`,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}))

type BoxName = "split" | "duplicate" | "delete"

interface MergePartitionsProps {
  splits: Split[]
  duplicates: Duplicate[]
}

const mergePartitions = ({ splits, duplicates }: MergePartitionsProps): (Split | Duplicate)[] => {
  const merged: (Split | Duplicate)[] = [...splits, ...duplicates].sort(comparePageRanges)
  return merged
}

interface AnnotationFileProps {
  caseId: CaseId
  exhibitId: number
  fileName: string
  numberOfPages: number
  status?: Nullable<AnnotationStatusType>
  clickable?: boolean
  splits: Split[]
  partitionProviders?: PartitionProvider[]
  duplicates: Duplicate[]
  actions: Action[]
}

const AnnotationFile: React.FC<AnnotationFileProps> = ({
  caseId,
  exhibitId,
  fileName,
  numberOfPages,
  status,
  clickable = true,
  splits,
  partitionProviders,
  duplicates,
  actions,
}) => {
  const [downloadingFile, setDownloadingFile] = useState<boolean>(false)
  const [duplicateForDeletions, setDuplicateForDeletions] = useState<Nullable<number>>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [resetDialogOpen, setResetDialogOpen] = useState(false)
  const [splittingBoxOpen, setSplittingBoxOpen] = useState(false)
  const [duplicateBoxOpen, setDuplicateBoxOpen] = useState(false)
  const [deleteBoxOpen, setDeleteBoxOpen] = useState(false)
  const splitBoxRef = useRef<HTMLElement>(null)
  const duplicateBoxRef = useRef<HTMLElement>(null)
  const deleteBoxRef = useRef<HTMLElement>(null)
  const { showMessage } = useHandleMessages()
  const { isFeatureEnabled } = useFeatures()
  const isSplittingEnabled = isFeatureEnabled(FEATURES.SPLIT_INTAKE_FILES)

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => setAnchorEl(null)

  const handleBoxOpen = (boxName: BoxName) => {
    if (boxName === "split") {
      setDuplicateBoxOpen(false)
      setDeleteBoxOpen(false)
      setSplittingBoxOpen(true)
    }

    if (boxName === "duplicate") {
      setSplittingBoxOpen(false)
      setDeleteBoxOpen(false)
      setDuplicateBoxOpen(true)
    }

    if (boxName === "delete") {
      setSplittingBoxOpen(false)
      setDuplicateBoxOpen(false)
      setDeleteBoxOpen(true)
    }
  }

  const handleDeleteBoxClose = () => {
    setDeleteBoxOpen(false)
    setDuplicateForDeletions(null)
  }

  const handleIconDownload = () => {
    let promise = null
    setDownloadingFile(true)
    if (!fileName.endsWith(".pdf") || status === "pre_annotating") {
      promise = downloadExhibit({ caseId, exhibitId, downloadFile: true })
    } else if (status === "annotating" || status == "auto_complete") {
      promise = downloadExhibit({
        caseId,
        exhibitId,
        downloadFile: true,
        source: "download-pre-annotated",
      })
    } else if (status === "complete") {
      promise = downloadExhibit({
        caseId,
        exhibitId,
        downloadFile: true,
        source: "download-annotated",
      })
    }
    promise
      ?.catch(() => {
        showMessage({
          type: "error",
          message:
            "Failed to download file. Try again shortly and if your problem persists, please report your issue.",
        })
      })
      .finally(() => setDownloadingFile(false))
  }

  useEffect(() => {
    if (splittingBoxOpen && splitBoxRef.current) {
      splitBoxRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }

    if (duplicateBoxOpen && duplicateBoxRef.current) {
      duplicateBoxRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }

    if (deleteBoxOpen && deleteBoxRef.current) {
      deleteBoxRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }
  }, [splittingBoxOpen, duplicateBoxOpen, deleteBoxOpen])

  const handleOpenPreview = useCallback(() => {
    window.open(`/exhibit-preview/${caseId}/exhibit/${exhibitId}`, "_blank", DEFAULT_WINDOW_CONFIGURATIONS)
  }, [caseId, exhibitId])

  const partitions = useMemo(() => {
    return mergePartitions({ splits, duplicates })
  }, [splits, duplicates])

  const deletions = useMemo(() => {
    return actions.filter(action => action.action_type === "delete") as DeleteAction[]
  }, [actions])

  const activeDuplicate = useMemo(() => {
    if (duplicateForDeletions) {
      return duplicates.find(dup => dup.pk === duplicateForDeletions)
    }
  }, [duplicates, duplicateForDeletions])

  const activeDuplicateDeletions = useMemo(() => {
    return (activeDuplicate?.actions?.filter(action => action.action_type === "delete") ??
      []) as DeleteAction[]
  }, [activeDuplicate])

  return (
    <Box>
      <Paper variant="outlined">
        <ClickableBox clickable={clickable}>
          <Box display="flex" alignItems="center">
            <Box display="flex" mr={4} alignItems="center">
              <Box mr={1}>
                <InsertDriveFileOutlined />
              </Box>
              <FileName>
                {fileName.endsWith(".pdf") ? (
                  <FileNameLink onClick={handleOpenPreview}>
                    <strong>{fileName}</strong>
                  </FileNameLink>
                ) : (
                  <strong>{fileName}</strong>
                )}
              </FileName>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" mr={7}>
              {status && status !== "complete" && <AnnotationStatus status={status} mr={2} />}
              {numberOfPages !== null && numberOfPages !== undefined && (
                <BoldText>Total Pages: {numberOfPages}</BoldText>
              )}
            </Box>
            {splits.length > 0 ? (
              <Box>
                <AnnotationFileIconButton onClick={handleMenuOpen}>
                  <MoreHoriz />
                </AnnotationFileIconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {/* Menu complains about having fragments as children so we do one isSplittingEnabled check for each menu item */}
                  {isSplittingEnabled && (
                    <MenuItem
                      onClick={() => {
                        handleBoxOpen("split")
                        handleMenuClose()
                      }}
                    >
                      {splits.length > 1 ? "Modify Splits" : "Create Splits"}
                    </MenuItem>
                  )}
                  {isSplittingEnabled && (
                    <MenuItem
                      onClick={() => {
                        handleBoxOpen("duplicate")
                        handleMenuClose()
                      }}
                    >
                      Create Duplicate
                    </MenuItem>
                  )}
                  {isSplittingEnabled && (
                    <MenuItem
                      onClick={() => {
                        setResetDialogOpen(true)
                        handleMenuClose()
                      }}
                    >
                      Reset Changes
                    </MenuItem>
                  )}
                  {!isSplittingEnabled && <Divider />}
                  {!isSplittingEnabled && <ListSubheader>Coming Soon</ListSubheader>}
                  {!isSplittingEnabled && (
                    <MenuItem disabled onClick={handleMenuClose}>
                      Create Splits
                    </MenuItem>
                  )}
                  {!isSplittingEnabled && (
                    <MenuItem disabled onClick={handleMenuClose}>
                      Create Duplicate Create Duplicate
                    </MenuItem>
                  )}
                  {!isSplittingEnabled && (
                    <MenuItem disabled onClick={handleMenuClose}>
                      Reset Changes
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" justifyItems="center">
                {downloadingFile ? (
                  <Box p={1}>
                    <CircularProgress size={24} />
                  </Box>
                ) : (
                  <AnnotationFileIconButton onClick={handleIconDownload}>
                    <GetApp />
                  </AnnotationFileIconButton>
                )}
              </Box>
            )}
          </Box>
        </ClickableBox>
      </Paper>
      {/* we will have excerpts as soon as the labeller submits the document
          but we do not want to display them until the labels are reviewed
          (i.e. status "complete") */}
      {!!partitions.length && (
        <AnnotationFilesDetailsPaper variant="outlined">
          {partitions.map(partition => {
            if ("partition_type" in partition && partition.partition_type === "duplicate") {
              return (
                <AnnotationDuplicate
                  key={partition.pk}
                  {...partition}
                  exhibitId={exhibitId}
                  partitionProviders={partitionProviders}
                  caseId={caseId}
                  onDeletePagesClick={partitionId => {
                    setDuplicateForDeletions(partitionId)
                    handleBoxOpen("delete")
                  }}
                  deletions={
                    (partition.actions?.filter(action => action.action_type === "delete") ??
                      []) as DeleteAction[]
                  }
                  status={status}
                />
              )
            }
            return (
              <AnnotationSplit
                key={partition.pk}
                {...partition}
                exhibitId={exhibitId}
                partitionProviders={partitionProviders}
                caseId={caseId}
                onDeletePagesClick={() => handleBoxOpen("delete")}
                deletions={deletions}
                status={status}
              />
            )
          })}
          {splittingBoxOpen && (
            <SplitBox
              ref={splitBoxRef}
              splits={splits}
              onCancel={() => setSplittingBoxOpen(false)}
              onSuccessfulSave={() => setSplittingBoxOpen(false)}
              exhibitId={exhibitId}
              caseId={caseId}
              numberOfPages={numberOfPages}
            />
          )}
          {duplicateBoxOpen && (
            <DuplicateBox
              ref={duplicateBoxRef}
              onCancel={() => setDuplicateBoxOpen(false)}
              onSuccessfulSave={() => setDuplicateBoxOpen(false)}
              exhibitId={exhibitId}
              caseId={caseId}
              numberOfPages={numberOfPages}
            />
          )}
          {deleteBoxOpen && (
            <DeletePagesBox
              ref={deleteBoxRef}
              deletions={activeDuplicate ? activeDuplicateDeletions : deletions}
              onCancel={handleDeleteBoxClose}
              onSuccessfulSave={handleDeleteBoxClose}
              exhibitId={exhibitId}
              caseId={caseId}
              numberOfPages={numberOfPages}
              duplicate={activeDuplicate}
            />
          )}
        </AnnotationFilesDetailsPaper>
      )}
      <ResetDialog
        open={resetDialogOpen}
        onClose={() => setResetDialogOpen(false)}
        exhibitId={exhibitId}
        fileName={fileName}
        caseId={caseId}
      />
    </Box>
  )
}

export default AnnotationFile
