import { v4 } from "uuid"
import produce from "immer"
import { uniqBy } from "lodash"
import {
  Bill,
  Exhibit,
  FileToUpload,
  ExhibitValidationError,
  ID,
  Provider,
  CaseIcdCode,
  ProviderFormState,
  ValidationErrors,
  CaseCptCode,
  PartitionedExhibit,
} from "./types"
import { providerLocalStorage } from "./ProviderLocalStorage"
import { ProviderTemplatedSectionDto } from "api/services/case/types"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { serializeToMarkdown } from "../../common/form-components/rich-text/serializer/markdown/serializer"
import { EXHIBIT, PARTITIONED_EXHIBIT } from "./constants"

export const INITIAL_FORM_STATE: ProviderFormState = {
  activeProviderId: null,
  providers: [],
  validationErrors: {},
}

export const SET_PROVIDERS = "SET_PROVIDERS"
export const SET_INITIAL_PROVIDERS = "SET_INITIAL_PROVIDERS"
export const UPDATE_PROVIDER = "UPDATE_PROVIDER"
export const UPDATE_PROVIDER_NAME = "UPDATE_PROVIDER_NAME"
export const UPDATE_INCLUDE_TABLE = "UPDATE_INCLUDE_TABLE"
export const UPDATE_FIRST_CONTACT = "UPDATE_FIRST_CONTACT"
export const UPDATE_LAST_CONTACT = "UPDATE_LAST_CONTACT"
export const UPDATE_VISIT_COUNT = "UPDATE_VISIT_COUNT"
export const UPDATE_ONGOING_APPOINTMENT = "UPDATE_ONGOING_APPOINTMENT"
export const UPDATE_ONE_DAY_APPOINTMENT = "UPDATE_ONE_DAY_APPOINTMENT"
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE"
export const ADD_PROVIDER = "ADD_PROVIDER"
export const TOGGLE_OPEN = "TOGGLE_OPEN"
export const UPDATE_ICD_CODES = "UPDATE_ICD_CODES"
export const UPDATE_CPT_CODES = "UPDATE_CPT_CODES"
export const UPDATE_INJURY_DETAILS = "UPDATE_INJURY_DETAILS"
export const EXHIBIT_CHANGE = "EXHIBIT_CHANGE"
export const SET_EDITING = "SET_EDITING"
export const TOGGLE_SAVING = "TOGGLE_SAVING"
export const TOGGLE_DELETING = "TOGGLE_DELETING"
export const SAVE_IN_BACKGROUND = "SAVE_IN_BACKGROUND"
export const PROVIDER_UPDATE_SUCCESS = "PROVIDER_UPDATE_SUCCESS"
export const PROVIDER_UPDATE_PARTIAL_SUCCESS = "PROVIDER_UPDATE_PARTIAL_SUCCESS"
export const SET_VALIDATION_ERRORS = "SET_VALIDATION_ERRORS"
export const CLOSE_EDITING_PROVIDER = "CLOSE_EDITING_PROVIDER"
export const DELETE_PROVIDER = "DELETE_PROVIDER"
export const ADD_FILES_TO_UPLOAD = "ADD_FILES_TO_UPLOAD"
export const UPDATE_FILES_TO_UPLOAD = "UPDATE_FILES_TO_UPLOAD"
export const DELETE_FILE_TO_UPLOAD = "DELETE_FILE_TO_UPLOAD"
export const SET_FILE_VALIDATION = "SET_FILE_VALIDATION"
export const FILE_UPLOAD_START = "FILE_UPLOAD_START"
export const FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR"
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS"
export const SET_EXHIBITS = "SET_EXHIBITS"
export const DELETE_EXHIBIT = "DELETE_EXHIBIT"
export const UPDATE_EXHIBITS = "UPDATE_EXHIBITS"
export const SET_EXHIBIT_EDITING = "SET_EXHIBIT_EDITING"
export const RESET_EXHIBIT = "RESET_EXHIBIT"
export const EXHIBIT_SAVE_START = "EXHIBIT_SAVE_START"
export const EXHIBIT_SAVE_SUCCESS = "EXHIBIT_SAVE_SUCCESS"
export const EXHIBIT_SAVE_ERROR = "EXHIBIT_SAVE_ERROR"
export const SORT_PROVIDERS = "SORT_PROVIDERS"
export const ADD_BILL = "ADD_BILL"
export const UPDATE_BILL = "UPDATE_BILL"
export const DELETE_BILL = "DELETE_BILL"
export const APPEND_EXHIBIT = "APPEND_EXHIBIT"
export const DELETE_PARTITION = "DELETE_PARTITION"

const setProviderExhibits = (provider: Provider, isProviderAutofillEnabled: boolean) => {
  const exhibits =
    (provider.exhibits ?? []).map((e: Exhibit) => {
      return {
        ...e,
        exhibitType: EXHIBIT,
      }
    }) ?? []

  if (!isProviderAutofillEnabled) {
    return exhibits.sort((a, b) => {
      return (a.section_index ?? 0) - (b.section_index ?? 0)
    })
  }

  const exhibitPartitions = (provider.exhibit_partitions ?? []).map((pe: PartitionedExhibit) => {
    return {
      ...pe,
      exhibitType: PARTITIONED_EXHIBIT,
    }
  })

  return [...exhibits, ...exhibitPartitions].sort((a, b) => {
    if (a.section_index === null && b.section_index === null) return 0
    if (a.section_index !== null && b.section_index === null) return 1
    if (a.section_index === null && b.section_index !== null) return -1

    return (a.section_index ?? -1) - (b.section_index ?? -1)
  })
}

export const providerReducer = produce((state: ProviderFormState, action: Action) => {
  switch (action.type) {
    case SET_PROVIDERS: {
      const { providers } = action.payload

      state.providers = providers

      break
    }
    case SET_INITIAL_PROVIDERS: {
      const { providers, caseId, isProviderAutofillEnabled } = action.payload
      const savedProvider = providerLocalStorage.getSavedProvider(caseId)

      if (savedProvider && savedProvider.editing) {
        state.providers = providers.map(provider =>
          provider.pk === savedProvider.providerId
            ? {
                ...provider,
                open: true,
                exhibits: setProviderExhibits(provider, isProviderAutofillEnabled),
              }
            : {
                ...provider,
                exhibits: setProviderExhibits(provider, isProviderAutofillEnabled),
              }
        )
        state.activeProviderId = savedProvider.providerId

        return
      }

      state.providers = providers.map(provider => {
        return {
          ...provider,
          exhibits: setProviderExhibits(provider, isProviderAutofillEnabled),
        }
      })

      break
    }
    case UPDATE_PROVIDER: {
      const { newProvider } = action.payload
      const providerIndex = state.providers.findIndex(({ pk }) => newProvider.pk === pk)

      if (providerIndex !== -1) {
        state.providers[providerIndex] = newProvider
      }

      break
    }
    case UPDATE_PROVIDER_NAME: {
      const { providerId, name } = action.payload
      const providerIndex = state.providers.findIndex(({ pk }) => providerId === pk)

      if (providerIndex !== -1) {
        state.providers = state.providers.map(stateProvider =>
          stateProvider.pk === providerId ? { ...stateProvider, name } : stateProvider
        )
      }

      break
    }
    case UPDATE_INCLUDE_TABLE: {
      const { providerId, includeTable } = action.payload
      const providerIndex = state.providers.findIndex(({ pk }) => providerId === pk)

      if (providerIndex !== -1) {
        state.providers = state.providers.map(stateProvider =>
          stateProvider.pk === providerId ? { ...stateProvider, include_table: includeTable } : stateProvider
        )
      }

      break
    }
    case UPDATE_FIRST_CONTACT: {
      const { providerId, firstContact } = action.payload
      const providerIndex = state.providers.findIndex(({ pk }) => providerId === pk)

      if (providerIndex !== -1) {
        state.providers = state.providers.map(stateProvider =>
          stateProvider.pk === providerId ? { ...stateProvider, first_contact: firstContact } : stateProvider
        )
      }

      break
    }
    case UPDATE_VISIT_COUNT: {
      const { providerId, visitCount } = action.payload
      const providerIndex = state.providers.findIndex(({ pk }) => providerId === pk)

      if (providerIndex !== -1) {
        state.providers = state.providers.map(stateProvider =>
          stateProvider.pk === providerId ? { ...stateProvider, visit_count: visitCount } : stateProvider
        )
      }

      break
    }
    case UPDATE_ONGOING_APPOINTMENT: {
      const { providerId, ongoingAppointment } = action.payload
      const providerIndex = state.providers.findIndex(({ pk }) => providerId === pk)

      if (providerIndex !== -1) {
        state.providers = state.providers.map(stateProvider =>
          stateProvider.pk === providerId
            ? { ...stateProvider, is_ongoing_appointment: ongoingAppointment }
            : stateProvider
        )
      }

      break
    }
    case UPDATE_ONE_DAY_APPOINTMENT: {
      const { providerId, oneDayAppointment } = action.payload
      const providerIndex = state.providers.findIndex(({ pk }) => providerId === pk)

      if (providerIndex !== -1) {
        state.providers = state.providers.map(stateProvider => {
          if (stateProvider.pk === providerId) {
            return {
              ...stateProvider,
              is_one_day_appointment: oneDayAppointment,
              last_contact: null,
              visit_count: 1,
            }
          }

          return stateProvider
        })
      }

      break
    }
    case UPDATE_LAST_CONTACT: {
      const { providerId, lastContact } = action.payload
      const providerIndex = state.providers.findIndex(({ pk }) => providerId === pk)

      if (providerIndex !== -1) {
        state.providers = state.providers.map(stateProvider =>
          stateProvider.pk === providerId ? { ...stateProvider, last_contact: lastContact } : stateProvider
        )
      }

      break
    }
    case UPDATE_TEMPLATE: {
      const { providerId, template } = action.payload
      const providerIndex = state.providers.findIndex(({ pk }) => providerId === pk)

      if (providerIndex !== -1) {
        state.providers = state.providers.map(stateProvider => {
          if (stateProvider.pk === providerId) {
            const addtions: { details_json?: EditorRoot; details?: string } = {}

            if (template.custom_content) {
              addtions.details_json = template.custom_content
              addtions.details = serializeToMarkdown(template.custom_content)
            } else if (template.template?.content) {
              addtions.details_json = template.template.content
              addtions.details = serializeToMarkdown(template.template.content)
            }

            return { ...stateProvider, templated_sections: [template], ...addtions }
          }

          return stateProvider
        })
      }

      break
    }
    case UPDATE_ICD_CODES: {
      const { providerId, icdCodes } = action.payload
      const providerIndex = state.providers.findIndex(({ pk }) => providerId === pk)
      const icdCodesToSave = uniqBy(icdCodes, "code")

      if (providerIndex !== -1) {
        state.providers = state.providers.map(stateProvider =>
          stateProvider.pk === providerId ? { ...stateProvider, icd_codes: icdCodesToSave } : stateProvider
        )
      }

      break
    }
    case UPDATE_CPT_CODES: {
      const { providerId, cptCodes } = action.payload
      const providerIndex = state.providers.findIndex(({ pk }) => providerId === pk)

      if (providerIndex !== -1) {
        const cptCodesToSave = uniqBy(cptCodes, "code")

        state.providers = state.providers.map(stateProvider =>
          stateProvider.pk === providerId ? { ...stateProvider, cpt_codes: cptCodesToSave } : stateProvider
        )
      }

      break
    }
    case UPDATE_INJURY_DETAILS: {
      const { providerId, value, markdownValue, customContent } = action.payload
      const providerIndex = state.providers.findIndex(({ pk }) => providerId === pk)

      if (providerIndex !== -1) {
        state.providers = state.providers.map(stateProvider => {
          if (stateProvider.pk === providerId) {
            if (stateProvider.templated_sections && customContent) {
              return {
                ...stateProvider,
                details_json: customContent,
                details: serializeToMarkdown(customContent),
                templated_sections: [
                  { ...stateProvider.templated_sections[0], custom_content: customContent },
                ],
              }
            }

            return {
              ...stateProvider,
              details_json: value,
              details: markdownValue,
            }
          }

          return stateProvider
        })
      }

      break
    }
    case ADD_PROVIDER: {
      const { provider, caseId } = action.payload

      providerLocalStorage.onChange(true, caseId, provider.pk)
      state.providers.push({ ...provider, open: true })
      state.activeProviderId = provider.pk

      break
    }
    case TOGGLE_OPEN: {
      const { id } = action.payload
      const providerToToggle = state.providers.find(provider => provider.pk === id)

      if (providerToToggle) {
        providerToToggle.open = !providerToToggle.open
      }

      break
    }
    case SET_EDITING: {
      const { id, caseId } = action.payload
      const newProviders: Provider[] = []

      state.providers.forEach(prevProvider => {
        // if provider is entering the editing state, make sure it's open
        if (prevProvider.pk && prevProvider.pk === id) {
          providerLocalStorage.onChange(true, caseId, id)
          newProviders.push({ ...prevProvider, open: true })
        }
        // else if another existing provider is active, close it
        else if (prevProvider.pk && prevProvider.pk === state.activeProviderId) {
          newProviders.push({ ...prevProvider, open: false })
        }
        // make sure we only add providers with pks because
        // we want to remove any providers that may have been adding when an existing provider enters the editing state
        else if (prevProvider.pk) {
          newProviders.push(prevProvider)
        }
      })
      state.providers = newProviders
      state.validationErrors = {}
      state.activeProviderId = id

      break
    }
    case TOGGLE_SAVING: {
      const { id } = action.payload
      const providerToToggle = state.providers.find(provider => provider.pk === id)

      if (providerToToggle) {
        providerToToggle.saving = !providerToToggle.saving
      }

      break
    }
    case SAVE_IN_BACKGROUND: {
      const { provider, isProviderAutofillEnabled = false } = action.payload

      state.providers = state.providers.map(stateProvider => {
        return stateProvider.pk === provider.pk
          ? {
              ...stateProvider,
              ...provider,
              exhibits: setProviderExhibits(provider, isProviderAutofillEnabled),
            }
          : stateProvider
      })

      break
    }
    case TOGGLE_DELETING: {
      const { id } = action.payload
      const newProviders = state.providers.map(provider => {
        if (provider.pk === id) {
          return { ...provider, deleting: !provider.deleting }
        }

        return provider
      })

      return { ...state, providers: newProviders }
    }
    case PROVIDER_UPDATE_SUCCESS: {
      const { provider, caseId, isProviderAutofillEnabled = false } = action.payload
      const providerIndex = state.providers.findIndex(stateProvider => stateProvider.pk === provider.pk)

      if (providerIndex !== -1) {
        state.providers[providerIndex] = {
          ...provider,
          saving: false,
          open: false,
          exhibits: setProviderExhibits(provider, isProviderAutofillEnabled),
        }
      }

      state.validationErrors = {}
      state.activeProviderId = null
      providerLocalStorage.removeProvider(caseId)

      break
    }
    case PROVIDER_UPDATE_PARTIAL_SUCCESS: {
      const { provider, caseId } = action.payload
      const providerIndex = state.providers.findIndex(stateProvider => stateProvider.pk === provider.pk)

      if (providerIndex !== -1) {
        state.providers[providerIndex] = { ...provider, saving: false, open: true }
      }

      state.validationErrors = {}
      providerLocalStorage.removeProvider(caseId)

      break
    }
    case SET_VALIDATION_ERRORS: {
      const { validationErrors } = action.payload

      state.validationErrors = validationErrors

      break
    }
    case CLOSE_EDITING_PROVIDER: {
      const { pk, caseId } = action.payload
      const providerIndex = state.providers.findIndex(stateProvider => stateProvider.pk === pk)

      if (providerIndex !== -1) {
        state.providers[providerIndex].open = false
      }

      state.validationErrors = {}
      state.activeProviderId = null
      providerLocalStorage.removeProvider(caseId)

      break
    }
    case DELETE_PROVIDER: {
      const { pk, caseId } = action.payload

      state.providers = state.providers.filter(provider => provider.pk !== pk)
      state.activeProviderId = null
      state.validationErrors = {}
      providerLocalStorage.removeProvider(caseId)

      break
    }
    case ADD_FILES_TO_UPLOAD: {
      const { files, id } = action.payload
      const filesToUpload: FileToUpload[] = files.map(file => ({
        formId: v4(),
        name: file.name,
        type: null,
        file,
      }))
      const provider = state.providers.find(stateProvider => stateProvider.pk === id)

      if (provider) {
        if (provider.filesToUpload) {
          provider.filesToUpload = provider.filesToUpload.concat(filesToUpload)
        } else {
          provider.filesToUpload = [...filesToUpload]
        }
      }

      break
    }
    case UPDATE_FILES_TO_UPLOAD: {
      const { id, filesToUpload } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === id)

      if (provider) {
        provider.filesToUpload = filesToUpload
      }

      break
    }
    case DELETE_FILE_TO_UPLOAD: {
      const { id, fileFormId } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === id)

      if (provider) {
        provider.filesToUpload = provider.filesToUpload?.filter((file, index) => {
          // filter the file out of the array
          if (file.formId === fileFormId) {
            // also filter any validation errors associated with that file
            if (state.validationErrors.filesToUpload?.[index] !== undefined) {
              state.validationErrors.filesToUpload = state.validationErrors.filesToUpload.filter(
                (_, errorIndex) => errorIndex !== index
              )
            }
            return false
          }
          return true
        })
        provider.bills = provider.bills?.map(bill =>
          bill.file_to_upload_id === fileFormId ? { ...bill, file_to_upload_id: null } : bill
        )
      }

      break
    }
    case SET_FILE_VALIDATION: {
      const { validationError, index, providerPk } = action.payload

      // if no validation errors yet create an array of null entries for each fileToUpload
      if (!state.validationErrors.filesToUpload) {
        const provider = state.providers.find(provider => provider.pk === providerPk)
        state.validationErrors.filesToUpload = provider?.filesToUpload?.map(() => null) ?? []
      }

      state.validationErrors.filesToUpload[index] = validationError

      break
    }
    case FILE_UPLOAD_START: {
      const { providerPk, formId } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (provider) {
        provider.filesToUpload = provider.filesToUpload?.map((file, index) => {
          if (file.formId === formId) {
            if (state.validationErrors.filesToUpload?.[index] !== undefined) {
              state.validationErrors.filesToUpload[index] = null
            }
            return { ...file, error: undefined, uploading: true }
          }
          return file
        })
      }

      break
    }
    case FILE_UPLOAD_ERROR: {
      const { providerPk, formId, error } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === providerPk)
      const fileToUpload = provider?.filesToUpload?.find(file => file.formId === formId)

      if (fileToUpload) {
        fileToUpload.error = error
        fileToUpload.uploading = false
      }

      break
    }
    case FILE_UPLOAD_SUCCESS: {
      const { providerPk, formId, exhibit } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (provider) {
        provider.filesToUpload = provider.filesToUpload?.filter(file => file.formId !== formId)
        provider.exhibits = provider.exhibits ?? []
        provider.exhibits.push({ ...exhibit, exhibitType: EXHIBIT })
        provider.bills = provider.bills?.map(bill => {
          if (bill.file_to_upload_id === formId) {
            return { ...bill, file_to_upload_id: null, exhibit_id: exhibit.pk }
          }
          return bill
        })
      }

      break
    }
    case SET_EXHIBITS: {
      const { exhibits, providerPk } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (provider) {
        provider.exhibits = exhibits
      }

      break
    }
    case DELETE_EXHIBIT: {
      const { exhibitPk, providerPk } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (provider) {
        provider.exhibits = provider.exhibits?.filter(
          exhibit => exhibit.pk !== exhibitPk || (exhibit.exhibitType && exhibit.exhibitType !== EXHIBIT)
        )
        provider.bills = provider.bills?.map(bill =>
          bill.exhibit_id === exhibitPk ? { ...bill, exhibit_id: null } : bill
        )
      }

      break
    }
    case UPDATE_EXHIBITS: {
      const { providerPk, exhibits } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (provider) {
        provider.exhibits = exhibits
      }

      break
    }
    case SET_EXHIBIT_EDITING: {
      const {
        providerPk,
        exhibitPk,
        editing,
        exhibitType,
        isProviderAutofillEnabled = false,
      } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === providerPk)
      const exhibit = provider?.exhibits?.find(providerExhibit => {
        if (providerExhibit.pk !== exhibitPk) {
          return false
        }

        if (
          !isProviderAutofillEnabled ||
          !providerExhibit.exhibitType ||
          providerExhibit.exhibitType === exhibitType
        ) {
          return true
        }

        return false
      })

      if (exhibit) {
        exhibit.editing = editing
      }

      break
    }
    case RESET_EXHIBIT: {
      const { providerPk, exhibitPk, exhibit } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (provider) {
        provider.exhibits = provider.exhibits?.map((providerExhibit, index) => {
          if (providerExhibit.pk === exhibitPk) {
            if (state.validationErrors.exhibits?.[index] !== undefined) {
              state.validationErrors.exhibits[index] = null
            }
            return exhibit
          }
          return providerExhibit
        })
      }

      break
    }
    case EXHIBIT_SAVE_START: {
      const { providerPk, exhibitPk, exhibitType } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (provider) {
        provider.exhibits = provider.exhibits?.map((providerExhibit, index) => {
          if (
            providerExhibit.pk === exhibitPk &&
            (!providerExhibit.exhibitType || providerExhibit.exhibitType === exhibitType)
          ) {
            if (state.validationErrors.exhibits?.[index] !== undefined) {
              state.validationErrors.exhibits[index] = null
            }
            return { ...providerExhibit, saving: true }
          }
          return providerExhibit
        })
      }

      break
    }
    case EXHIBIT_SAVE_SUCCESS: {
      const { providerPk, exhibitPk, exhibit } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (provider) {
        provider.exhibits = provider.exhibits?.map(providerExhibit => {
          if (
            providerExhibit.pk === exhibitPk &&
            (!exhibit.exhibitType || providerExhibit.exhibitType === exhibit.exhibitType)
          ) {
            return exhibit
          }
          return providerExhibit
        })
      }

      break
    }
    case EXHIBIT_SAVE_ERROR: {
      const { validationError, index, providerPk } = action.payload
      const provider = state.providers.find(provider => provider.pk === providerPk)
      const exhibit = provider?.exhibits?.[index]

      if (exhibit) {
        exhibit.saving = false
      }

      // if no validation errors yet create an array of null entries for each exhibit
      if (!state.validationErrors.exhibits) {
        state.validationErrors.exhibits = provider?.exhibits?.map(() => null) ?? []
      }

      state.validationErrors.exhibits[index] = validationError

      break
    }
    case SORT_PROVIDERS: {
      state.providers.sort(
        (a, b) => Number(new Date(a.first_contact || "9999")) - Number(new Date(b.first_contact || "9999"))
      )
      break
    }
    case ADD_BILL: {
      const { providerId } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === providerId)

      if (provider) {
        const newBill = { formId: v4(), description: "", billed_amount: "0" }

        if (provider.bills) {
          provider.bills?.push(newBill)
        } else {
          provider.bills = [newBill]
        }
      }

      break
    }
    case UPDATE_BILL: {
      const { providerId, billId, updates } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === providerId)

      if (provider) {
        provider.bills = provider.bills?.map(bill =>
          bill.pk === billId || bill.formId === billId ? { ...bill, ...updates } : bill
        )
      }

      break
    }
    case EXHIBIT_CHANGE: {
      const { bill, index, type, id, providerId } = action.payload

      const provider = state.providers.find(stateProvider => stateProvider.pk === providerId)

      const newBill = {
        ...bill,
        exhibit_id: null,
        file_to_upload_id: null,
        partition_id: null,
        [type]: id,
      } as Bill

      const prevBills = provider?.bills || []
      const newBills = [...prevBills]

      // @typescript-disable-next-line
      newBills[index] = newBill

      if (provider) {
        provider.bills = newBills
      }

      break
    }
    case DELETE_BILL: {
      const { providerId, billId } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === providerId)

      if (provider) {
        provider.bills = provider.bills?.filter((bill, index) => {
          if (bill.pk === billId || bill.formId === billId) {
            if (state.validationErrors.bills?.[index] !== undefined) {
              state.validationErrors.bills = state.validationErrors.bills.filter(
                (_, errorIndex) => errorIndex !== index
              )
            }
            return false
          }
          return true
        })
      }

      break
    }
    case APPEND_EXHIBIT: {
      const { providerId, exhibit } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === providerId)

      if (provider) {
        provider.exhibits?.push(exhibit)
      }

      break
    }
    case DELETE_PARTITION: {
      const { providerPk, partitionPk } = action.payload
      const provider = state.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (provider) {
        provider.exhibits = provider.exhibits?.filter(exhibit => {
          return (
            exhibit.pk !== partitionPk || (exhibit.exhibitType && exhibit.exhibitType !== PARTITIONED_EXHIBIT)
          )
        })
        provider.bills = provider.bills?.map(bill =>
          bill.partition_id === partitionPk ? { ...bill, exhibit_id: null } : bill
        )
      }

      break
    }
  }
})

interface BasePayloadAction<T> {
  type: string
  payload: T
}

export interface SetProvidersAction extends BasePayloadAction<{ providers: Provider[] }> {
  type: typeof SET_PROVIDERS
}
export interface SetInitialProvidersAction
  extends BasePayloadAction<{ providers: Provider[]; caseId: string; isProviderAutofillEnabled: boolean }> {
  type: typeof SET_INITIAL_PROVIDERS
}
export interface UpdateProviderAction extends BasePayloadAction<{ newProvider: Provider }> {
  type: typeof UPDATE_PROVIDER
}
export interface AddProviderAction extends BasePayloadAction<{ provider: Provider; caseId: string }> {
  type: typeof ADD_PROVIDER
}
export interface UpdateProviderNameAction
  extends BasePayloadAction<{ name: string; providerId: PrimaryKey }> {
  type: typeof UPDATE_PROVIDER_NAME
}
export interface UpdateIncludeTableAction
  extends BasePayloadAction<{ includeTable: boolean; providerId: PrimaryKey }> {
  type: typeof UPDATE_INCLUDE_TABLE
}
export interface UpdateFirstContactAction
  extends BasePayloadAction<{ firstContact: Nullable<string>; providerId: PrimaryKey }> {
  type: typeof UPDATE_FIRST_CONTACT
}
export interface UpdateVisitCountAction
  extends BasePayloadAction<{ visitCount: Nullable<number>; providerId: PrimaryKey }> {
  type: typeof UPDATE_VISIT_COUNT
}
export interface UpdateOngoingAppointmentAction
  extends BasePayloadAction<{ ongoingAppointment: boolean; providerId: PrimaryKey }> {
  type: typeof UPDATE_ONGOING_APPOINTMENT
}
export interface UpdateOneDayAppointmentAction
  extends BasePayloadAction<{ oneDayAppointment: boolean; providerId: PrimaryKey }> {
  type: typeof UPDATE_ONE_DAY_APPOINTMENT
}
export interface UpdateLastContactAction
  extends BasePayloadAction<{ lastContact: Nullable<string>; providerId: PrimaryKey }> {
  type: typeof UPDATE_LAST_CONTACT
}
export interface UpdateTemplate
  extends BasePayloadAction<{ providerId: PrimaryKey; template: ProviderTemplatedSectionDto }> {
  type: typeof UPDATE_TEMPLATE
}
export interface UpdateIcdCodesAction
  extends BasePayloadAction<{ icdCodes: CaseIcdCode[]; providerId: PrimaryKey }> {
  type: typeof UPDATE_ICD_CODES
}
export interface UpdateCptCodesAction
  extends BasePayloadAction<{ cptCodes: CaseCptCode[]; providerId: PrimaryKey }> {
  type: typeof UPDATE_CPT_CODES
}
export interface UpdateInjuryDetailsAction
  extends BasePayloadAction<{
    value: Nullable<EditorRoot>
    markdownValue: Nullable<string>
    customContent: Nullable<EditorRoot>
    providerId: PrimaryKey
  }> {
  type: typeof UPDATE_INJURY_DETAILS
}
export interface ToggleOpenAction extends BasePayloadAction<{ id: ID }> {
  type: typeof TOGGLE_OPEN
}
export interface SetEditingAction extends BasePayloadAction<{ id: ID; caseId: string }> {
  type: typeof SET_EDITING
}
export interface ExhibitChangeAction
  extends BasePayloadAction<{
    bill: Bill
    index: number
    type: "file_to_upload_id" | "billed_amount" | "exhibit_id" | "partition_id"
    id: number | string
    providerId: ID
  }> {
  type: typeof EXHIBIT_CHANGE
}
export interface ToggleSavingAction extends BasePayloadAction<{ id: ID }> {
  type: typeof TOGGLE_SAVING
}
export interface SaveInBackgroundAction
  extends BasePayloadAction<{ provider: Provider; isProviderAutofillEnabled?: boolean }> {
  type: typeof SAVE_IN_BACKGROUND
}
export interface ToggleDeletingAction extends BasePayloadAction<{ id: ID }> {
  type: typeof TOGGLE_DELETING
}
export interface ProviderUpdateSuccessAction
  extends BasePayloadAction<{ provider: Provider; caseId: string; isProviderAutofillEnabled?: boolean }> {
  type: typeof PROVIDER_UPDATE_SUCCESS
}
export interface ProviderUpdatePartialSuccessAction
  extends BasePayloadAction<{ provider: Provider; caseId: string }> {
  type: typeof PROVIDER_UPDATE_PARTIAL_SUCCESS
}
export interface SetValidationErrorsAction extends BasePayloadAction<{ validationErrors: ValidationErrors }> {
  type: typeof SET_VALIDATION_ERRORS
}
export interface CloseEdititnProviderAction extends BasePayloadAction<{ pk: ID; caseId: string }> {
  type: typeof CLOSE_EDITING_PROVIDER
}
export interface DeleteProviderAction extends BasePayloadAction<{ pk: number; caseId: string }> {
  type: typeof DELETE_PROVIDER
}
export interface AddFilesToUploadAction extends BasePayloadAction<{ id: ID; files: File[] }> {
  type: typeof ADD_FILES_TO_UPLOAD
}
export interface UpdateFilesToUploadAction
  extends BasePayloadAction<{ id: ID; filesToUpload: FileToUpload[] }> {
  type: typeof UPDATE_FILES_TO_UPLOAD
}
export interface DeleteFileToUploadAction extends BasePayloadAction<{ id: ID; fileFormId: string }> {
  type: typeof DELETE_FILE_TO_UPLOAD
}
export interface SetFileValidationAction
  extends BasePayloadAction<{
    validationError: ExhibitValidationError
    index: number
    providerPk: number
  }> {
  type: typeof SET_FILE_VALIDATION
}
export interface FileUploadStartAction extends BasePayloadAction<{ providerPk: number; formId: string }> {
  type: typeof FILE_UPLOAD_START
}
export interface FileUploadErrorAction
  extends BasePayloadAction<{ providerPk: number; formId: string; error: Error }> {
  type: typeof FILE_UPLOAD_ERROR
}
export interface FileUploadSuccessAction
  extends BasePayloadAction<{ providerPk: number; formId: string; exhibit: Exhibit }> {
  type: typeof FILE_UPLOAD_SUCCESS
}
export interface SetExhibitsAction extends BasePayloadAction<{ providerPk: number; exhibits: Exhibit[] }> {
  type: typeof SET_EXHIBITS
}
export interface DeleteExhibitAction extends BasePayloadAction<{ providerPk: number; exhibitPk: number }> {
  type: typeof DELETE_EXHIBIT
}
export interface UpdateExhibitsAction extends BasePayloadAction<{ providerPk: number; exhibits: Exhibit[] }> {
  type: typeof UPDATE_EXHIBITS
}
export interface ResetExhibitAction
  extends BasePayloadAction<{ providerPk: number; exhibitPk: number; exhibit: Exhibit }> {
  type: typeof RESET_EXHIBIT
}
export interface ExhibitSaveStartAction
  extends BasePayloadAction<{ providerPk: number; exhibitPk: number; exhibitType: string }> {
  type: typeof EXHIBIT_SAVE_START
}
export interface ExhibitSaveSuccessAction
  extends BasePayloadAction<{
    providerPk: number
    exhibitPk: number
    exhibit: Exhibit
    exhibitType: string
  }> {
  type: typeof EXHIBIT_SAVE_SUCCESS
}
export interface ExhibitSaveErrorAction
  extends BasePayloadAction<{ providerPk: number; index: number; validationError: ExhibitValidationError }> {
  type: typeof EXHIBIT_SAVE_ERROR
}
export interface SetExhibitEditingAction
  extends BasePayloadAction<{
    providerPk: number
    exhibitPk: number
    editing: boolean
    exhibitType: string
    isProviderAutofillEnabled?: boolean
  }> {
  type: typeof SET_EXHIBIT_EDITING
}
export interface SortProvidersAction {
  type: typeof SORT_PROVIDERS
}
export interface AddBillAction extends BasePayloadAction<{ providerId: ID }> {
  type: typeof ADD_BILL
}
export interface UpdateBillAction
  extends BasePayloadAction<{ providerId: ID; billId: ID; updates: Partial<Bill> }> {
  type: typeof UPDATE_BILL
}
export interface DeleteBillAction extends BasePayloadAction<{ providerId: ID; billId: ID }> {
  type: typeof DELETE_BILL
}
export interface AppendExhibitAction extends BasePayloadAction<{ providerId: ID; exhibit: Exhibit }> {
  type: typeof APPEND_EXHIBIT
}
export interface DeletePartitionAction
  extends BasePayloadAction<{ providerId: ID; providerPk: number; partitionPk: number; exhibitPk: number }> {
  type: typeof DELETE_PARTITION
}

export type Action =
  | SetProvidersAction
  | SetInitialProvidersAction
  | UpdateProviderAction
  | AddProviderAction
  | UpdateProviderNameAction
  | ToggleOpenAction
  | SetEditingAction
  | UpdateIncludeTableAction
  | UpdateFirstContactAction
  | UpdateLastContactAction
  | UpdateVisitCountAction
  | UpdateOngoingAppointmentAction
  | UpdateOneDayAppointmentAction
  | UpdateTemplate
  | UpdateIcdCodesAction
  | UpdateCptCodesAction
  | UpdateInjuryDetailsAction
  | ToggleSavingAction
  | ExhibitChangeAction
  | ToggleDeletingAction
  | SaveInBackgroundAction
  | ProviderUpdateSuccessAction
  | ProviderUpdatePartialSuccessAction
  | SetValidationErrorsAction
  | CloseEdititnProviderAction
  | AddFilesToUploadAction
  | DeleteProviderAction
  | UpdateFilesToUploadAction
  | DeleteFileToUploadAction
  | SetFileValidationAction
  | FileUploadStartAction
  | FileUploadErrorAction
  | FileUploadSuccessAction
  | SetExhibitsAction
  | DeleteExhibitAction
  | UpdateExhibitsAction
  | ResetExhibitAction
  | ExhibitSaveStartAction
  | ExhibitSaveSuccessAction
  | ExhibitSaveErrorAction
  | SetExhibitEditingAction
  | SortProvidersAction
  | AddBillAction
  | UpdateBillAction
  | DeleteBillAction
  | AppendExhibitAction
  | DeletePartitionAction
