import React from "react"
import { Box, Divider, Tab as MuiTab, Tabs as MuiTabs, TabTypeMap } from "@material-ui/core"
import { TabContext, TabPanel as MuiTabPanel } from "@material-ui/lab"
import { styled } from "@material-ui/core/styles"

import { Value } from "../../hooks/useSearchState"

const Tabs = styled(MuiTabs)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  "& .MuiTabs-scroller": {
    borderRadius: theme.shape.borderRadius,
  },
}))

const TabBox = styled(MuiTab)(({ theme }) => ({
  "&:first-child": {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  "&:last-child": {
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  textTransform: "none",
  border: "solid 1px #d9d9d9",
  "&.Mui-selected": {
    color: "white",
    zIndex: 1,
    transition: "color 0.7s",
    borderStyle: "none",
    borderWidth: 0,
  },
  "&.Mui-disabled": {
    border: "solid 1px grey",
    color: "grey",
  },
}))

export const Tab = ({ ...inputProps }: TabTypeMap["props"]): JSX.Element => {
  return <TabBox {...inputProps} />
}

export const TabPanel = styled(MuiTabPanel)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: 0,
}))

interface TabBoxProps {
  currentTab: Value | string
  setCurrentTab: (tabName: string) => void
  tabs: React.ReactElement[]
  children: React.ReactChild
}

export const BlockTabs = ({ currentTab, setCurrentTab, tabs, children }: TabBoxProps): JSX.Element => {
  return (
    <TabContext value={`${currentTab}`}>
      <Box display="flex" mb={2}>
        <Tabs
          TabIndicatorProps={{ style: { height: "100%", backgroundColor: "black", zIndex: 0 } }}
          value={currentTab}
          onChange={(_, newValue) => {
            setCurrentTab(newValue)
          }}
        >
          {tabs}
        </Tabs>
      </Box>
      <Divider />

      {children}
    </TabContext>
  )
}
