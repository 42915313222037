import { Editor, Transforms, Path } from "slate"
import { createList } from "../../create"
import { CustomEditor } from "../../CustomEditor"
import { LIST_ITEM_BLOCK_ELEMENTS } from "../../elements"
import { closestListNode } from "./queries"

export function increaseListItemDepth(editor: CustomEditor, at: Path): void {
  const [node] = Editor.node(editor, at)

  if (!Editor.isBlock(editor, node) || node.type !== LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM) return

  const listEntry = closestListNode(editor, at)
  const previousListItemEntry = Editor.node(editor, Path.previous(at))

  if (!listEntry || !previousListItemEntry) return

  const [list] = listEntry
  const [previousListItem, previousListItemPath] = previousListItemEntry

  if (
    !Editor.isBlock(editor, previousListItem) ||
    previousListItem.type !== LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM
  ) {
    return
  }

  Editor.withoutNormalizing(editor, () => {
    const [, nestedList] = previousListItem.children

    if (!nestedList) {
      Transforms.wrapNodes(editor, createList(undefined, list.type), { at })
      Transforms.moveNodes(editor, { at, to: [...previousListItemPath, 1] })

      return
    }

    Transforms.moveNodes(editor, { at, to: [...previousListItemPath, 1, nestedList.children.length] })
  })
}
