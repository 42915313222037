import React, { useEffect, useState } from "react"
import { Box } from "@material-ui/core"
import { useMutation, useQuery } from "react-query"
import { useForm } from "react-hook-form"
import { useOutletContext } from "react-router-dom"
import { size } from "lodash"
import { deleteExhibit, fetchLossOfIncomeForCase, getCaseFacts, saveLossOfIncome, uploadExhibit } from "api"
import { queryKeys } from "react-query/constants"
import { getChangedFields, getCommonMutateOptions } from "utils"
import useAutosave from "hooks/useAutosave"
import { useDebouncedCallback } from "hooks/useDebouncedCallback"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { formSectionsToRoutes, STEP_STATUSES } from "../constants"
import { useFormContext } from "../context"
import { AdditionalInfoSection } from "./AdditionalInfoSection/AdditionalInfoSection"
import { SalarySection } from "./SalarySection/SalarySection"
import { ImpairmentSection } from "./ImpairmentSection"
import { CommentSection } from "./CommentSection"
import {
  INITIAL_FORM_STATE,
  SALARY_FORMS,
  SALARY_INFO_ALL_SECTIONS_FIELDS,
  SKIP_CLEAN,
  NON_NULL_FIELDS,
  CALCULATION_FIELDS,
} from "./constants"

export function IncomeLoss({ lastVisited }) {
  const [saveRef] = useOutletContext()
  const [errors, setErrors] = useState(null)
  const { caseId, queryClient, setSavedSuccessfully, showErrorMessage, handleUpdateStepStatus, request } =
    useFormContext()
  const { control, handleSubmit, reset, resetField, watch, formState, getValues, setValue } = useForm({
    defaultValues: INITIAL_FORM_STATE,
  })
  const [files, setFiles] = useState(null)
  const [fileToUpload, setFileToUpload] = useState(null)
  const salaryInformationType = watch("salary_information_type")
  const startDate = watch("start_of_impairment_date")
  const endDate = watch("end_of_impairment_date")

  const { showMessage } = useHandleMessages()

  // update state if any
  useQuery([queryKeys.incomeLoss, caseId], async () => {
    const setDefaultStartOfLoss = async () => {
      const data = await getCaseFacts(caseId)
      const incidentDate = data?.date_of_incident

      resetField("start_of_impairment_date", {
        defaultValue: incidentDate,
      })
    }

    const data = await fetchLossOfIncomeForCase(caseId)
    let salary_information_type = SALARY_FORMS.annual.key

    for (const [key, value] of Object.entries(data)) {
      if (SALARY_FORMS.annual.fields.includes(key) && value) {
        salary_information_type = SALARY_FORMS.annual.key
        break
      }
      if (SALARY_FORMS.hourly.fields.includes(key) && value) {
        salary_information_type = SALARY_FORMS.hourly.key
        break
      }
      if (SALARY_FORMS.noSalary.fields.includes(key) && value) {
        salary_information_type = SALARY_FORMS.noSalary.key
        break
      }
    }

    reset({ ...data, salary_information_type: salary_information_type })

    // TODO: Fix this - response recieves id instead of PK
    setFiles(
      data?.exhibits.map(exhibit => {
        return { ...exhibit, pk: exhibit.id }
      })
    )

    if (data?.start_of_impairment_date == null) {
      await setDefaultStartOfLoss()
    }
  })

  const mutation = useMutation(
    saveLossOfIncome,
    getCommonMutateOptions({
      setErrors,
      reset,
      setSavedSuccessfully,
      showErrorMessage,
      onSuccessExtra: () => {
        handleUpdateStepStatus({ status: STEP_STATUSES.completed })
        queryClient.invalidateQueries([queryKeys.incomeLoss, caseId, "results"])
      },
    })
  )
  // succeed/fail silently
  const autosaveMutation = useMutation(saveLossOfIncome)

  const _cleanData = () => {
    // we need to null out the other sections
    // the calculator uses a fallback strategy
    // based on the information present
    const salary_information_type = getValues("salary_information_type")
    const currentTypeFields = SALARY_FORMS[salary_information_type].fields

    for (const field of SALARY_INFO_ALL_SECTIONS_FIELDS) {
      if (!currentTypeFields.includes(field) && !SKIP_CLEAN.includes(field)) {
        // TODO: Fix this on the backend
        let nullValue = NON_NULL_FIELDS.includes(field) ? 0 : null
        setValue(field, nullValue)
      } else {
        if (!getValues(field) && NON_NULL_FIELDS.includes(field)) {
          setValue(field, 0)
        }
      }
    }

    // Main form fields should just check if null and set to 0
    for (const field of SALARY_FORMS.main.fields) {
      if (!getValues(field)) {
        let nullValue = NON_NULL_FIELDS.includes(field) ? 0 : null
        setValue(field, nullValue)
      }
    }

    return getValues()
  }

  const deleteFileMutation = useMutation(deleteExhibit, {
    onSuccess: () => {
      showMessage({ type: "success", message: "Deleted file" })
      queryClient.invalidateQueries(queryKeys.incomeLoss)
    },
    onError: () => {
      showMessage({
        type: "error",
        message:
          "Error occurred deleting file. Please try again shortly or contact a dev if your problem persists.",
      })
    },
  })
  const uploadFileMutation = useMutation(uploadExhibit, {
    onSuccess: () => {
      setFileToUpload(null)
      queryClient.invalidateQueries(queryKeys.incomeLoss)
    },
    onError: () => {
      showMessage({
        type: "error",
        message:
          "Error occurred uploading file. Please try again shortly or contact a dev if your problem persists.",
      })
    },
  })
  const handleFileChange = async (file, remove = false) => {
    if (remove) {
      deleteFileMutation.mutate({ exhibitId: file.id, caseId })
    } else {
      let fileData = new FormData()
      const { file: fileObject, documentType, name } = file
      fileData.append("file", fileObject)
      fileData.append("name", name)
      fileData.append("type", documentType)
      fileData.append("section", "income_loss")

      uploadFileMutation.mutate({ data: fileData, caseId })
    }
  }

  const handleOnChange = handleSubmit(async data => {
    if (fileToUpload) {
      await handleFileChange(fileToUpload)
    }

    const changedFields = getChangedFields(data, formState)

    if (size(changedFields) > 0) {
      const cleanedData = _cleanData()
      mutation.mutateAsync({ caseId, data: cleanedData }).then(() => {
        if (
          CALCULATION_FIELDS.find(key => {
            return Object.prototype.hasOwnProperty.call(changedFields, key)
          })
        ) {
          queryClient.invalidateQueries([queryKeys.incomeLossCalulation, caseId, "results"])
        }
      })
    }
  })

  useAutosave({
    shouldAutosave: formState.isDirty,
    save: () => {
      autosaveMutation.mutate({ caseId, data: { ...getValues(), case_id: caseId } })
    },
  })

  useEffect(() => {
    saveRef.current = handleOnChange
  }, [handleOnChange, saveRef])

  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.income_loss
  })

  const handleChangeDebounced = useDebouncedCallback(handleOnChange)

  return (
    <Box>
      <form noValidate onChange={handleChangeDebounced}>
        <ImpairmentSection
          onClose={handleOnChange}
          errors={errors}
          setValue={setValue}
          startDate={startDate}
          endDate={endDate}
        />
        <SalarySection control={control} watch={watch} salaryInformationType={salaryInformationType} />
        <CommentSection control={control} />
      </form>
      <AdditionalInfoSection
        endDate={endDate}
        startDate={startDate}
        files={files}
        caseId={caseId}
        fileToUpload={fileToUpload}
        queryClient={queryClient}
        setFileToUpload={setFileToUpload}
        handleFileChange={handleFileChange}
        request={request}
        salaryInformationType={salaryInformationType}
      />
    </Box>
  )
}
