import {
  CustomElement,
  CustomText,
  InlineContent,
  ListElement,
  ListItemElement,
  ParagraphElement,
} from "../../CustomEditor"
import { INLINE_ELEMENTS, LEAF_BLOCK_ELEMENTS, LIST_BLOCK_ELEMENTS } from "../../elements"

const INDENT_SIZE = 4
const INDENT_SYMBOL = " "
const BOLD_SYMBOL = "**"
const ITALIC_SYMBOL = "_"
const BOLD_ITALIC_SYMBOL = "_**"

function getIndent(symbol: string): string {
  const indent = new Array(INDENT_SIZE).fill(INDENT_SYMBOL).join("")

  return `${symbol}${indent}`.slice(0, INDENT_SIZE)
}

function serializeText(node: CustomText): string {
  if (node.text.trim() === "") {
    return node.text
  }

  let specialSymbol

  switch (true) {
    case node.bold && node.italic:
      specialSymbol = BOLD_ITALIC_SYMBOL
      break
    case node.bold:
      specialSymbol = BOLD_SYMBOL
      break
    case node.italic:
      specialSymbol = ITALIC_SYMBOL
      break
    default:
      specialSymbol = ""
  }

  const content = node.text.replace(/([*_[\]])/g, "\\$1")
  let spacesBefore = ""
  let spacesAfter = ""

  for (const char of content) {
    if (!char.match(/\s/)) break
    spacesBefore = `${spacesBefore}${char}`
  }

  for (const char of [...content].reverse()) {
    if (!char.match(/\s/)) break
    spacesAfter = `${char}${spacesAfter}`
  }

  return `${spacesBefore}${specialSymbol}${content.trim()}${[...specialSymbol]
    .reverse()
    .join("")}${spacesAfter}`
}

function serializeTextContent(nodes: InlineContent[]): string {
  const serializedTextContent = nodes
    .map(node => {
      if ("type" in node) {
        if (node.type === INLINE_ELEMENTS.SOFT_LINE_BREAK) {
          return "\n"
        }

        if (node.type === INLINE_ELEMENTS.VARIABLE) {
          return `[[${node.name}]]`
        }
      }

      return serializeText(node)
    })
    .join("")

  return serializedTextContent

  // Removed with fix to join styling
  // const createSiblingSymbolsRegExp = (symbol: string): RegExp => {
  //   const pattern = `${[...symbol].reverse().join("")}${symbol}`

  //   // Original: /(?<=\/)([^#]+)(?=#*)/
  //   // New     : /(?:\/)([^#]+)(?=#*)/

  //   return new RegExp(`(?<![^\\\\]\\*|[^\\\\]\\_|\\\\)(\\${[...pattern].join("\\")})(?!\\*|\\_)`, "g")
  // }

  // return serializedTextContent
  //   .replaceAll(createSiblingSymbolsRegExp(BOLD_ITALIC_SYMBOL), "")
  //   .replaceAll(createSiblingSymbolsRegExp(BOLD_SYMBOL), "")
  //   .replaceAll(createSiblingSymbolsRegExp(ITALIC_SYMBOL), "")
}

function serializeParagraph(node: ParagraphElement): string[] {
  const serializedContent = serializeTextContent(node.children)

  return serializedContent.replaceAll("\n", "") ? [serializedContent] : []
}

function serializeListItem(node: ListItemElement, index: number, type: LIST_BLOCK_ELEMENTS): string[] {
  if (!node.children) {
    return []
  }

  const [content, list] = node.children
  const serializedListItemContent = content.children.map(serializeElement).flat()
  const serializedContent = serializedListItemContent.length ? serializedListItemContent : [""]
  const serializedList = list ? serializeList(list) : []
  const result = [...serializedContent, ...serializedList]

  const listSymbol = type === LIST_BLOCK_ELEMENTS.ORDERED_LIST ? `${index + 1}.` : "*"
  const defaultIndent = getIndent("")
  const firstLineIndent = getIndent(listSymbol)

  return [
    ...result.slice(0, 1).map(str => `${firstLineIndent}${str}`),
    ...result.slice(1).map(str => `${defaultIndent}${str}`),
  ]
}

function serializeList(node: ListElement): string[] {
  return node.children.map((childNode, index) => serializeListItem(childNode, index, node.type)).flat()
}

function serializeElement(node: CustomElement): string[] {
  switch (node.type) {
    case LEAF_BLOCK_ELEMENTS.PARAGRAPH:
      return serializeParagraph(node)
    case LIST_BLOCK_ELEMENTS.UNORDERED_LIST:
    case LIST_BLOCK_ELEMENTS.ORDERED_LIST:
      return serializeList(node)
    default:
      return []
  }
}

export function serializeToMarkdown(nodes: CustomElement[]): string {
  return nodes
    .map(node => {
      const content = serializeElement(node)
      return content.length ? ["", ...content] : []
    })
    .flat()
    .join("\n")
    .replace(/^\n/, "")
}
