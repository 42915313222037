import React from "react"
import { styled } from "@material-ui/core/styles"
import { Box } from "@material-ui/core"
import { TagLabelData, TagLabels } from "common/TagLabel"

interface RequestTagLabelsProps {
  tags?: Nullable<TagLabelData[]>
}

const TagsContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}))

export const RequestTagLabels: React.FC<RequestTagLabelsProps> = ({ tags }): JSX.Element | null => {
  if (!tags || !tags?.length) {
    return null
  }

  return (
    <TagsContainer>
      <TagLabels tags={tags} />
    </TagsContainer>
  )
}
