import React from "react"

export const PROVIDER_ERRORS_MESSAGES = {
  UPLOADNIG_FILE_FAIL: "File failed to upload",
  UPLOADNIG_FILE_PROBLEM: (name: string): string => `Problem uploading file: ${name}`,
  DELETING_PROVIDER_PROBLEM: (name: string): string =>
    `An error occurred deleting ${
      name || "this provider"
    }. Please wait a bit and try again. If your problem persists contact a dev.`,
  PROVIDER_SAVING_WITH_ERRORS: (name: string, errors: string[]): JSX.Element => (
    <>
      Provider {name} saved with errors:
      <ul>
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </>
  ),
  INPUT_ERROR: "There are some problems with your input, please double check the fields and try again.",
  PROVIDER_UPDATING_ERROR:
    "Error updating provider. Please try again shortly and if your problem persists contact a dev.",
  PROVIDER_CREATING_ERROR:
    "Error creating provider. Please try again shortly and if your problem persists contact a dev.",
  ICD_SAVING_ERROR: "Could not save icd codes",
  CPT_SAVING_ERROR: "Could not save cpt codes",
}

export const PROVIDER_SUCCESS_MESSAGES = {
  PROVIDER_SAVED: (name: string): string => `Provider ${name} saved`,
  PROVIDER_DELETED: (name: string): string => `Deleted ${name || "provider"}`,
}

export const PROVIDER_CONFIRMATION_MESSAGES = {
  DELETE_PROVIDER: (name: string): string => `Are you sure you want to delete ${name || "this provider"}?`,
}

export const AUTO_IMPORT = "Auto Import"
export const EXHIBIT = "exhibit"
export const PARTITIONED_EXHIBIT = "partitioned_exhibit"

export enum UPDATE_TYPES {
  APPOINTMENTS = "appointments",
  BILLS = "bills",
  ICD_CODES = "icd_codes",
}
