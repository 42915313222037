import React from "react"
import { RenderLeafProps } from "slate-react"

export const Leaf: React.FC<RenderLeafProps> = ({ attributes, leaf, children }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.highlight) {
    children = <span style={{ background: "yellow" }}>{children}</span>
  }

  return <span {...attributes}>{children}</span>
}
