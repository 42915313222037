import React, { useMemo } from "react"
import { Box, InputLabel, MenuItem, Select, SelectProps } from "@material-ui/core"
import StatusLabel from "common/status/StatusLabel"
import useUser from "hooks/useUser"
import { OSF } from "common/models/roles"
import getStatusToProperties from "./requestStatusProperties"
import { INTAKE_STATUSES } from "../constants"
import { getStatusOptions } from "./utils"

interface StatusSelectorProps {
  onChange: (e: React.ChangeEvent<{ value: INTAKE_STATUSES }>) => void
  currentStatus: INTAKE_STATUSES
  demandId: Nullable<PrimaryKey>
  revised: boolean
}

export const StatusSelector = ({
  onChange,
  currentStatus,
  demandId,
  revised,
}: StatusSelectorProps): JSX.Element => {
  const {
    user: { role },
  } = useUser()
  const displayStatusToPropertiesByRole = useMemo(
    () => getStatusOptions(role, currentStatus, revised, demandId),
    [role, currentStatus, demandId, revised]
  )

  const statuses = useMemo(() => {
    return Object.entries(displayStatusToPropertiesByRole).map(([status, properties]) => ({
      ...properties,
      ...getStatusToProperties(status as INTAKE_STATUSES, role),
      id: status,
    }))
  }, [role, displayStatusToPropertiesByRole])

  if (role === OSF && currentStatus === INTAKE_STATUSES.ready_for_tasker) {
    return <></>
  }

  return (
    <Box data-test="status-selector">
      <InputLabel id="request-status-selector">Status</InputLabel>
      <Select
        labelId="request-status-selector"
        value={currentStatus}
        onChange={onChange as SelectProps["onChange"]}
        data-test="request-status-selector"
      >
        {statuses.map(status => {
          return (
            <MenuItem key={status.id} value={status.id} disabled={status.disabled}>
              <StatusLabel
                status={status.id}
                color={status.color}
                text={status.text}
                filled={status.filled}
                disabled={status.disabled}
              />
            </MenuItem>
          )
        })}
      </Select>
    </Box>
  )
}
