import React from "react"
import { useQuery } from "react-query"
import { getInternalUsers, getExternalUsers } from "../api"
import { queryKeys } from "../react-query/constants"

import MultiAutocompleteSelector from "../common/MultiAutocompleteSelector"

const AssigneePicker = ({ onChange, value, isInternal, variant = "standard" }) => {
  const usersKey = isInternal ? queryKeys.internalUsers : queryKeys.externalUsers
  const getUsersMethod = isInternal ? getInternalUsers : getExternalUsers
  const { data: users } = useQuery([usersKey], getUsersMethod)

  if (!users?.data) {
    return <></>
  }

  const getUserFullName = user => {
    return user ? `${user.first_name} ${user.last_name}` : ``
  }

  const optionizedValue = value.map(assignee => {
    return {
      ...assignee,
      label: assignee?.label ?? getUserFullName(assignee),
    }
  })

  // Get a list of options that are not already selected
  const options = users?.data
    .map(user => {
      return {
        ...user,
        label: getUserFullName(user),
      }
    })
    .filter(option => {
      return !optionizedValue.find(value => {
        return value.pk === option.pk
      })
    })

  return (
    <MultiAutocompleteSelector
      dataTest="assignee-selector"
      options={options}
      value={optionizedValue}
      label="Assignee(s)"
      onChange={onChange}
      variant={variant}
    />
  )
}

export { AssigneePicker as default }
