import React, { useCallback } from "react"
import { Button } from "@material-ui/core"
import { isNil } from "lodash"
import { useQuery } from "react-query"
import { queryKeys } from "react-query/constants"
import { useParams } from "react-router-dom"
import { getCase } from "api"

export const GoToRequestButton = (): Nullable<JSX.Element> => {
  const { id: caseId } = useParams()
  const { isLoading, data: caseInfo, error } = useQuery([queryKeys.case, caseId], getCase)
  const hasId = !isNil(caseInfo?.questionnaire_id) && !error

  const handleClick = useCallback(() => {
    if (!hasId) return

    window.open(`/requests/${caseInfo.questionnaire_id}`, "_blank")
  }, [caseInfo?.questionnaire_id, hasId])

  if (!hasId) return null

  return (
    <Button variant="contained" style={{ fontWeight: 600 }} onClick={handleClick} disabled={isLoading}>
      go to request
    </Button>
  )
}
