import React, { useCallback, useMemo, useRef, useState } from "react"
import { Box, Button, Drawer, IconButton, Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useNavigate } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { useMutation, useQuery, useQueryClient } from "react-query"

import { fetchPlaintiffInfoForCase, getFirms, getRequestByCase, updateStepStatus } from "../api"
import { queryKeys } from "../react-query/constants"
import { FormProvider, LeftNavContext } from "./context"
import FormSections from "./FormSections"
import Form from "./Form"
import { DemandPreview } from "./DemandPreview"
import { Close, Menu } from "@material-ui/icons"
import { Helmet } from "react-helmet"
import { CaseVariablesProvider } from "./Variables/CaseVariablesProvider"
import { DemandSteps, useDemandSteps } from "./steps"
import { FORM_SECTION_ROUTES } from "./steps/constants"
import { LeftNav } from "./LeftNav"

const useStyles = makeStyles(theme => ({
  demand: {
    [theme.breakpoints.up("md")]: {
      display: "grid",
      columnGap: theme.spacing(2),
    },
  },
  center: {
    display: "grid",
    margin: "auto",
  },
  desktopNav: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileNavButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}))

const MESSAGE_TYPES = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
}

function DemandContainer() {
  const navigate = useNavigate()
  const { section, items, currentStep, nextStep, caseId } = useDemandSteps()
  const nextStepRef = useRef(nextStep)
  const [leftNavTabIsExpanded, setLeftNavTabIsExpanded] = useState(false)
  nextStepRef.current = nextStep
  const classes = useStyles()
  const [snackMessage, setSnackMessage] = useState("")
  const [severity, setSeverity] = useState(MESSAGE_TYPES.success)
  const [snackOpen, setSnackOpen] = useState(false)
  const [savedSuccessfully, setSavedSuccessfully] = useState(false)
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [plaintiff, setPlaintiff] = useState({})

  const queryClient = useQueryClient()
  const stepMutation = useMutation(updateStepStatus, {
    mutationKey: queryKeys.steps,
    onSuccess: latestStep => {
      queryClient.setQueryData(
        [queryKeys.steps, caseId],
        cachedData => {
          if (!cachedData) return cachedData

          const nextData = [...cachedData]
          const currentStepIndex = nextData.findIndex(step => step.step === latestStep.step)

          if (currentStepIndex === -1) {
            return [...nextData, latestStep]
          }

          nextData[currentStepIndex] = { ...nextData[currentStepIndex], ...latestStep }

          return nextData
        },
        {}
      )
    },
    onError: () => {
      showErrorMessage("Couldn't update status, please try again.")
    },
  })
  useQuery([queryKeys.plaintiff + "-global", caseId], async () => {
    const data = await fetchPlaintiffInfoForCase(caseId)
    if (data?.length) {
      setPlaintiff(data[0])
    }
  })
  const { data: request } = useQuery([queryKeys.request, caseId], getRequestByCase)
  const { data: firms } = useQuery(queryKeys.firms, getFirms)

  const handleUpdateStepStatus = ({ status }) => {
    stepMutation.mutate({ data: { step: section, status }, caseId })
  }

  const showSuccessMessage = useCallback(message => {
    setSnackMessage(message ?? "Save successful!")
    setSeverity(MESSAGE_TYPES.success)
    setSnackOpen(true)
  }, [])

  const handleNextStep = useCallback(async () => {
    showSuccessMessage()
    if (nextStepRef.current) {
      navigate(FORM_SECTION_ROUTES[nextStepRef.current.step])
    } else {
      // form is on the last section
      navigate("../review")
    }
  }, [navigate, showSuccessMessage])

  const currentStepIndex = currentStep?.index ?? -1
  const totalStepCount = items.length

  const closeSnack = () => {
    setSnackOpen(false)
  }

  const showErrorMessage = message => {
    const DEFAULT_ERROR = "Something went wrong."
    message ? setSnackMessage(message) : setSnackMessage(DEFAULT_ERROR)
    setSeverity(MESSAGE_TYPES.error)
    setSnackOpen(true)
  }

  const leftNavTabContextValue = useMemo(
    () => ({ leftNavTabIsExpanded, setLeftNavTabIsExpanded }),
    [leftNavTabIsExpanded, setLeftNavTabIsExpanded]
  )

  return (
    <Box>
      <Helmet>
        <title>
          Demand: {(plaintiff?.first_name ?? "") + (plaintiff?.last_name ? ` ${plaintiff?.last_name} ` : "")}{" "}
          - EvenUp
        </title>
      </Helmet>
      <CaseVariablesProvider caseId={caseId}>
        <FormProvider
          value={{
            caseId,
            section,
            queryClient,
            handleUpdateStepStatus,
            showErrorMessage,
            firms,
            savedSuccessfully,
            setSavedSuccessfully,
            request,
          }}
        >
          <Box className={classes.demand}>
            <Snackbar
              open={snackOpen}
              autoHideDuration={10000}
              onClose={closeSnack}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert onClose={closeSnack} severity={severity}>
                {snackMessage}
              </Alert>
            </Snackbar>
            <LeftNavContext.Provider value={leftNavTabContextValue}>
              <LeftNav />
            </LeftNavContext.Provider>
            <Drawer
              anchor="left"
              open={mobileNavOpen}
              onClose={() => setMobileNavOpen(false)}
              onClick={() => setMobileNavOpen(false)}
            >
              <Box display="flex" justifyContent="end">
                <IconButton>
                  <Close />
                </IconButton>
              </Box>
              <Box px={2}>
                <FormSections />
                <DemandPreview caseId={caseId} extendedExhibits={false} />
              </Box>
            </Drawer>
            <LeftNavContext.Provider value={leftNavTabContextValue}>
              <Form
                currentSection={section}
                handleSave={showSuccessMessage}
                handleNext={handleNextStep}
                mobileNavButton={
                  <Button
                    variant="outlined"
                    startIcon={<Menu />}
                    className={classes.mobileNavButton}
                    onClick={() => setMobileNavOpen(true)}
                  >
                    {currentStepIndex + 1}/{totalStepCount}
                  </Button>
                }
                request={request}
              />
            </LeftNavContext.Provider>
          </Box>
        </FormProvider>
      </CaseVariablesProvider>
    </Box>
  )
}

export function Demand() {
  return (
    <DemandSteps>
      <DemandContainer />
    </DemandSteps>
  )
}
