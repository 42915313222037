import React, { useCallback } from "react"
import { Box, TextField } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  container: {
    minWidth: theme.spacing(25),
    maxWidth: theme.spacing(100),
  },
}))

const MultiAutocompleteSelector = ({ options, value, onChange, label, dataTest, variant = "standard" }) => {
  const classes = useStyles()

  const handleRenderInput = params => {
    return <TextField {...params} variant={variant} label={label} />
  }

  const getOptionLabel = useCallback(option => option.label, [])

  return (
    <Box className={classes.container}>
      <Autocomplete
        multiple
        options={options}
        value={value}
        renderInput={handleRenderInput}
        getOptionLabel={getOptionLabel}
        data-test={dataTest}
        onChange={(event, newValues, reason) => onChange({ event, newValues, reason })}
      />
    </Box>
  )
}

export { MultiAutocompleteSelector as default }
