import React, { useEffect } from "react"
import { Navigate } from "react-router-dom"
import { useHandleMessages } from "./messages/useHandleMessages"

export default function AccessDenied() {
  const { showMessage } = useHandleMessages()

  useEffect(() => {
    showMessage({
      type: "error",
      message: "Requested page does not exists or you are trying to access invalid URL",
    })
  }, [showMessage])

  return <Navigate to="/" replace={true} />
}
