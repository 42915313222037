import { Element, Editor, NodeEntry, Range, Location } from "slate"
import { CustomEditor } from "../CustomEditor"
import { closestListNode, isInList, isList, isListItem } from "./lists"
import { getNormalizedSelection } from "./selection"

export function getBlocks<T extends Element>(
  editor: CustomEditor,
  type: T["type"],
  at?: Location
): NodeEntry<T>[] {
  return Array.from(
    Editor.nodes<T>(editor, {
      match: node => Editor.isBlock(editor, node) && node.type === type,
      mode: "lowest",
      at,
    })
  )
}

export function getActiveBlocks<T extends Element>(editor: CustomEditor): NodeEntry<T>[] {
  if (!editor.selection) {
    return []
  }

  const { anchor, focus } = getNormalizedSelection(editor.selection)
  const isInsideList = isInList(editor, anchor.path) || isInList(editor, focus.path)

  if (!isInsideList) {
    return Array.from(
      Editor.nodes<T>(editor, {
        match: node => Editor.isBlock(editor, node),
        mode: "highest",
      })
    )
  }

  return Array.from(
    Editor.nodes<T>(editor, {
      match: (node, path) => {
        if (!editor.selection) return false
        if (!Editor.isBlock(editor, node)) return false
        if (!isInList(editor, path)) return true
        if (isList(editor, node)) {
          return (
            Range.includes(editor.selection, Editor.start(editor, path)) &&
            Range.includes(editor.selection, Editor.end(editor, path))
          )
        }

        if (!isListItem(editor, node)) return false

        const listEntry = closestListNode(editor, path)

        if (!listEntry) return true

        const [, listPath] = listEntry

        return !(
          Range.includes(editor.selection, Editor.start(editor, listPath)) &&
          Range.includes(editor.selection, Editor.end(editor, listPath))
        )
      },
      mode: "lowest",
    })
  )
}
