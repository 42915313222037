import React from "react"
import {
  StyledToolbar,
  ToolbarContainer,
  ToolbarOuterContainer,
  PdfTitle,
  PdfPagesIndicator,
  StyledToolbarSpace,
  PdfSubTitle,
  PdfTitleWrapper,
} from "./styled"
import { ZoomControl } from "./ZoomControl"

interface PdfToolbarProps {
  title?: string
  subtitle?: string
  pages?: number
  page?: number
  pageNumberAdjustment?: number

  estimatedScale?: number
  onScaleChange: (value: Nullable<number>) => void

  leftControls?: React.ReactElement
  rightControls?: React.ReactElement
}

export function PdfToolbar({
  title,
  subtitle,
  pages = 1,
  page = 1,
  pageNumberAdjustment = 0,

  estimatedScale,
  onScaleChange,

  leftControls,
  rightControls,
}: PdfToolbarProps): React.ReactElement {
  return (
    <ToolbarOuterContainer>
      <ToolbarContainer color="default" elevation={1}>
        <StyledToolbar variant="dense">
          <div data-test="pdf-toolbar-left">{leftControls}</div>
          <StyledToolbarSpace data-test="pdf-toolbar-middle">
            <PdfPagesIndicator>
              {page + pageNumberAdjustment}
              {" / "}
              {pageNumberAdjustment > 0
                ? `[${pageNumberAdjustment + 1}-${pages + pageNumberAdjustment}]`
                : pages}
            </PdfPagesIndicator>
            <PdfTitleWrapper>
              <PdfTitle variant="body2">{title}</PdfTitle>
              {subtitle && <PdfSubTitle variant="body2">{subtitle}</PdfSubTitle>}
            </PdfTitleWrapper>
            <ZoomControl onChange={onScaleChange} estimatedZoomRatio={estimatedScale ?? 1} />
          </StyledToolbarSpace>
          <div data-test="pdf-toolbar-right">{rightControls}</div>
        </StyledToolbar>
      </ToolbarContainer>
    </ToolbarOuterContainer>
  )
}
