import React from "react"
import { Box } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"

const Header = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 600,
  marginBottom: theme.spacing(1),
  display: "flex",
  gap: theme.spacing(0.5),
}))

interface Props {
  header: React.ReactNode
  children: React.ReactNode
  fullWidth?: boolean
}

export const InputWithHeader = ({ children, header, fullWidth }: Props): JSX.Element => {
  return (
    <Box width={fullWidth ? "100%" : "auto"} mr={3}>
      <Header>{header}</Header>
      {children}
    </Box>
  )
}
