import { CASE_STEP, DEMAND_SECTION } from "./enums"
import { CASE_SECTIONS, CASE_ATTRIBUTES_STEP } from "./types"

export const FORM_SECTION_ROUTES: Record<CASE_SECTIONS, string> = {
  [CASE_STEP.PLAINTIFF]: "plaintiff",
  [CASE_STEP.CARRIER]: "carrier",
  [CASE_STEP.FACTS]: "facts",
  [CASE_STEP.PROVIDERS]: "providers",
  [CASE_STEP.FUTURE_EXPENSES]: "future-expenses",
  [CASE_STEP.NON_ECONOMIC]: "non-economic",
  [CASE_STEP.INCOME_LOSS]: "income-loss",
  [CASE_STEP.HOUSEHOLD_LOSS]: "other",
  [CASE_STEP.FIRM]: "firm",
  [CASE_STEP.DAMAGES_SECTIONS]: "damages",
  [DEMAND_SECTION.MISSING_DOCUMENTS]: "missing-docs",
  [DEMAND_SECTION.EXHIBITS]: "exhibit",
  [CASE_ATTRIBUTES_STEP]: "template",
}

export const ROUTES_TO_SECTION = Object.fromEntries(
  (Object.entries(FORM_SECTION_ROUTES) as [CASE_SECTIONS, string][]).map(([section, route]) => [
    route,
    section,
  ])
)
