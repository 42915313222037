import React from "react"
import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import { FirmLogo } from "./FirmLogo"
import { InputField } from "../../common/form-components"
import { FirmData } from "./Firm"
import { Control, FieldErrors, UseFormGetValues } from "react-hook-form"

const useStyles = makeStyles(theme => ({
  headerFieldsWrapper: {
    gridColumn: "1 / 3",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(2, 0),
  },
  headerButtons: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr);",
    gap: theme.spacing(4),
  },
  headerFields: {
    justifyContent: "left",
  },
  firmName: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      top: "100%",
    },
  },
}))

export type FirmHeaderDataFields = Partial<FirmData> & Pick<FirmData, "name" | "logo">

interface FirmHeaderProps {
  firmId: Nullable<number>
  editable: boolean
  control: Control<FirmHeaderDataFields>
  errors?: FieldErrors<FirmHeaderDataFields>
  syncState: Partial<Record<keyof FirmHeaderDataFields, boolean>>
  getValues: UseFormGetValues<FirmData>
  onLogoChange: (file: File) => void
  renderButtons: () => React.ReactNode
}

export const FirmHeader: React.FC<FirmHeaderProps> = ({
  firmId,
  control,
  getValues,
  onLogoChange,
  errors,
  syncState = {},
  editable,
  renderButtons,
}) => {
  const classes = useStyles()

  return (
    <Box className={clsx(classes.header, classes.headerFields)}>
      <div className={classes.headerFieldsWrapper}>
        <FirmLogo
          firmId={firmId}
          onChange={onLogoChange}
          editable={editable}
          isUploading={Boolean(syncState["logo"])}
        />
        <Typography variant="h3" className={classes.firmName}>
          {editable ? (
            <InputField
              control={control}
              name="name"
              type="text"
              label="Firm name"
              variant="outlined"
              rules={{ required: "Firm name can not be empty" }}
              errors={errors}
              autoFocus={!firmId}
              disabled={syncState["name"]}
              data-test="firm-name"
            />
          ) : (
            getValues("name")
          )}
        </Typography>
      </div>
      <div className={classes.headerButtons}>{renderButtons && renderButtons()}</div>
    </Box>
  )
}
