import React, { useState, useMemo, useEffect } from "react"
import { TextField, CircularProgress, Chip, Tooltip, Typography } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import debounce from "lodash/debounce"
import { getIcdCodes } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { uniqWith } from "lodash"
import { getFilteredCodes } from "../../utils"

export function IcdCodesInput({ onChange, icdCodes, fullWidth = true, disabled }) {
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [options, setOptions] = useState([])
  const { showMessage } = useHandleMessages()

  useEffect(() => {
    if (inputValue === "") {
      setOptions([])
      return undefined
    }

    async function fetchOptions() {
      setLoading(true)
      const data = await getIcdCodes({ search: inputValue })
      if (data) {
        const { results: icd_codes } = data
        setOptions(icd_codes)
      }
      setLoading(false)
    }

    fetchOptions()
  }, [inputValue])

  const handleChange = values => {
    const nextValues = getFilteredCodes(values)
    const uniqueCodes = uniqWith(nextValues, (a, b) => {
      const equal = a.code === b.code
      if (equal) {
        showMessage({ type: "error", message: "ICD code already in list." })
      }
      return equal
    })
    onChange(uniqueCodes)
  }

  const changeHandler = (e, newValue) => {
    setInputValue(newValue)
  }

  const selectedIcdCodes = useMemo(() => getFilteredCodes(icdCodes), [icdCodes])

  const availableOptions = useMemo(
    () => getFilteredCodes([...options, ...selectedIcdCodes]),
    [options, selectedIcdCodes]
  )

  const handleInputChange = useMemo(() => debounce(changeHandler, 300), [])
  return (
    <Autocomplete
      id="icd-codes-input"
      value={selectedIcdCodes}
      disabled={disabled}
      multiple
      fullWidth={fullWidth}
      filterSelectedOptions
      autoComplete
      autoHighlight
      // spread selected icdCodes to prevent warnings when options do not include selected icdCodes
      // e.g, search C1 --> select C123 --> search Z1 --> C123 is selected but not in options, MUI complains
      options={availableOptions}
      loading={loading}
      getOptionLabel={option => option.code}
      getOptionSelected={(option, value) => option.code === value.code}
      // filterOptions needs to be overridden to do no filtering
      // since we will do all the filtering ourselves via api call
      filterOptions={option => option}
      renderOption={option => (
        <>
          <Typography variant="caption" display="block" data-test="icd-code-description">
            {option.code} {`(${option.description})`}
          </Typography>
        </>
      )}
      onChange={(event, newValue) => {
        handleChange(newValue)
      }}
      onInputChange={handleInputChange}
      renderInput={params => (
        <TextField
          {...params}
          data-test="add-icd-code"
          label="Add ICD code / Injuries"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip
            title={option.description ?? ""}
            placement="top"
            data-test="icd-code-display"
            key={option.pk ?? index}
          >
            <Chip label={option.code} {...getTagProps({ index })} />
          </Tooltip>
        ))
      }
    />
  )
}
