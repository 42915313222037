import Cookies from "js-cookie"
import { getAPIServerURL } from "utils"

export interface UseQueryFunctionProps {
  queryKey: [string]
}

export enum REQUEST_METHODS {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export interface BaseCallProps<T = never> {
  customUrl?: string
  path: string
  query?: { [key: string]: any }
  method: REQUEST_METHODS
  data?: T extends never ? never : Nullable<T>
  json: false
  blob?: false
  isFormData?: false
  headers?: { [key: string]: string }
}

export interface FormDataCallProps<T extends FormData = FormData>
  extends Omit<BaseCallProps<T>, "isFormData"> {
  isFormData: true
}

export interface JsonCallProps<T = never> extends Omit<BaseCallProps<T>, "json"> {
  json?: true
}

export interface BlobCallProps<T = never> extends Omit<BaseCallProps<T>, "blob"> {
  blob: true
}

export type CallProps<T> = T extends FormData
  ? FormDataCallProps<FormData>
  : JsonCallProps<T> | BlobCallProps<T> | BaseCallProps<T>

export class ApiError extends Error {
  response: Response

  constructor(message: string, response: Response) {
    super(message)
    this.response = response
  }

  get validationErrors(): Record<string, any> | null {
    let errors = null
    try {
      errors = JSON.parse(this.message)
      // eslint-disable-next-line no-empty
    } catch (jsonError) {
      // do nothing
    }
    return errors
  }
}

export type QueryStringValue = string | number | boolean
export type QueryEntry<TValue> = [string, TValue]

function notEmptyEntry<TValue>(entry: QueryEntry<Nullable<TValue> | undefined>): entry is QueryEntry<TValue> {
  const [key, value] = entry
  return Boolean(key) && value !== null && value !== undefined
}

function makeQueryString(query: Record<string, Nullable<QueryStringValue>>): string {
  const queryEntries = Object.entries(query).filter(notEmptyEntry)

  if (queryEntries.length === 0) return ""

  return `?${queryEntries.map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join("&")}`
}

export function getAuthHeaders(): Record<string, string> {
  const deploymentEnv = process.env.REACT_APP_DEPLOYMENT_ENV ?? ""
  const csrftoken = Cookies.get(`csrftoken${deploymentEnv}`)

  return {
    "X-CSRFToken": csrftoken || "",
  }
}

export function getUrl(path: string, query = ""): string {
  return `${getAPIServerURL()}${path}${query}`
}

export class EmptyResponse {}

export class BlobResponse {
  constructor(private readonly blobData: Promise<Blob>, readonly filename: string = "") {}

  blob(): Promise<Blob> {
    return this.blobData
  }
}

export function makeApiCall<TData extends FormData = FormData>(
  props: FormDataCallProps<TData>
): Promise<Response>
export function makeApiCall<TResult, TData = unknown>(
  props: JsonCallProps<TData>
): Promise<TResult | EmptyResponse>
export function makeApiCall<TData = unknown>(
  props: BlobCallProps<TData>
): Promise<BlobResponse | EmptyResponse>
export function makeApiCall<TData = unknown>(
  props: TData extends FormData ? never : BaseCallProps<TData>
): Promise<Response>
export function makeApiCall<TData = never>(props: BaseCallProps<TData>): Promise<Response>
export async function makeApiCall<TData, TProps extends CallProps<TData>>(props: TProps): Promise<any> {
  const query = props.query ? makeQueryString(props.query) : ""
  const url = props.customUrl ? props.customUrl : getUrl(props.path, query)
  const headers = getAuthHeaders()

  if (props.json === undefined || props.json) {
    headers["Content-Type"] = "application/json"
  }

  if (props.headers) {
    Object.entries(props.headers).forEach(([key, value]) => {
      headers[key] = value
    })
  }

  const fetchOptions: RequestInit = {
    method: props.method,
    credentials: "include",
    headers,
  }

  fetchOptions.body

  if (props.data) {
    fetchOptions.body = props.isFormData ? props.data : JSON.stringify(props.data)
  }

  const response = await fetch(url, fetchOptions)

  if (!response.ok) {
    // get the error response as text
    // so we can pass it down to the caller
    const err = await response.text()
    throw new ApiError(err, response)
  }

  if (props.json === undefined || props.json) {
    if (response.status == 204) {
      return new EmptyResponse()
    }
    return response.json()
  } else if (props.blob) {
    if (response.status == 204) {
      return new EmptyResponse()
    }
    const header = response.headers.get("Content-Disposition") ?? ""
    const [, filenamePart = ""] = header.split(";")
    const [, filename = ""] = filenamePart.split("=")
    const cleanedFilename = filename.replace(/['"]/g, "")

    return new BlobResponse(response.blob(), cleanedFilename)
  } else {
    return response
  }
}
