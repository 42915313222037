import React, { useCallback, useContext, useRef } from "react"
import ReactDOM from "react-dom"
import { makeStyles } from "@material-ui/core/styles"
import { Avatar, Box, Divider } from "@material-ui/core"
import { Theme } from "@mui/material"
import clsx from "clsx"

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import FilterNoneOutlinedIcon from "@material-ui/icons/FilterNoneOutlined"
import { APP_ID, HEADER_NAV_HEIGHT_SPACE_MULTIPLIER, HEADER_NAV_Z_INDEX } from "app/constants"
import { Loading } from "common"

import { LeftNavContext, useFormContext as useDemandFormContext } from "./context"
import { useDemandSteps } from "./steps"
import FormSections from "./FormSections"
import { DemandPreview } from "./DemandPreview"
import { DEMAND_FLOW_WRAPPER, LEFT_NAV_PANEL_ORDER } from "./constants"

const useStyles = makeStyles<Theme, LeftNavStyleProps>(theme => ({
  bar: {
    order: LEFT_NAV_PANEL_ORDER,
    minWidth: props => (props.open ? "300px" : "80px"),
    marginLeft: theme.spacing(-6),
    transition: "width 0.25s",
    paddingTop: theme.spacing(5),
    "&:first-child": {},
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 2, 0, -2),
    },
  },
  container: {
    top: 0,
    left: 0,
    position: "fixed",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    marginTop: theme.spacing(HEADER_NAV_HEIGHT_SPACE_MULTIPLIER),
    zIndex: HEADER_NAV_Z_INDEX - 1,
    width: props => (props.open ? "300px" : "80px"),
    backgroundColor: theme.palette.grey[100],
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    margin: props => (props.open ? theme.spacing(2, "-12px", 1, "auto") : theme.spacing(2, "auto")),
    "&:hover": {
      cursor: "pointer",
    },
  },
  openCloseIcon: {
    color: theme.palette.getContrastText(theme.palette.common.black),
    backgroundColor: theme.palette.common.black,
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  preview: {
    display: "flex",
  },
}))

interface LeftNavStyleProps {
  open: boolean
}

export const LeftNav = (): Nullable<JSX.Element> => {
  const barRef = useRef<HTMLDivElement>(null)
  const { leftNavTabIsExpanded, setLeftNavTabIsExpanded } = useContext(LeftNavContext)
  const classes = useStyles({ open: leftNavTabIsExpanded })

  const { caseId } = useDemandSteps()
  const context: { caseId: Nullable<number> } = useDemandFormContext()

  const handleToggleOpenClick = useCallback(() => {
    setLeftNavTabIsExpanded(!leftNavTabIsExpanded)
  }, [leftNavTabIsExpanded, setLeftNavTabIsExpanded])

  if (!context?.caseId && leftNavTabIsExpanded) {
    return <Loading />
  }

  const demandFlowWrapperElement = document.getElementById(DEMAND_FLOW_WRAPPER)
  const appElement = document.getElementById(APP_ID)

  if (!demandFlowWrapperElement || !appElement) return null

  return (
    <>
      {ReactDOM.createPortal(<div ref={barRef} className={classes.bar} />, demandFlowWrapperElement)}
      {ReactDOM.createPortal(
        <div className={classes.container}>
          <Avatar className={clsx(classes.icon, classes.openCloseIcon)} onClick={handleToggleOpenClick}>
            {leftNavTabIsExpanded ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
          </Avatar>
          {leftNavTabIsExpanded ? (
            <FormSections />
          ) : (
            <FilterNoneOutlinedIcon
              className={clsx(classes.icon)}
              color={"action"}
              onClick={handleToggleOpenClick}
            />
          )}
          <Box mt={1} mb={1}>
            <Divider variant="middle" />
          </Box>

          <Box className={classes.preview} ml="auto" mr="auto">
            <DemandPreview caseId={caseId} extendedExhibits={false} iconButton={!leftNavTabIsExpanded} />
          </Box>
        </div>,
        appElement
      )}
    </>
  )
}
