export type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
  : Lowercase<S>

export type RawModel<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K] extends BaseObject ? RawModel<T[K]> : T[K]
}

export function toCamelCase<T extends BaseObject>(data: T): RawModel<T> {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      const [first, ...parts] = key.split("_")
      const camelCasePart: string[] = [
        first.toLowerCase(),
        ...parts.map(part => `${part[0].toUpperCase()}${part.substring(1).toLowerCase()}`),
      ]
      const camelCaseKey = camelCasePart.join("")

      return [camelCaseKey, value]
    })
  ) as RawModel<T>
}

export function toSnakeCase<T extends BaseObject>(data: RawModel<T>): T {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      const snakeCaseKey = key
        .replace(/([A-Z]+)/g, ",$1")
        .replace(/^,/, "")
        .replace(",", "_")
        .toLowerCase()

      return [snakeCaseKey, value]
    })
  ) as T
}
