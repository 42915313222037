import React, { useEffect, useState } from "react"
import { Box, Button } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"

import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"

import { InputField } from "../common/form-components"
import MissingDocumentSection from "./MissingDocumentSection"
import { MissingExhibit } from "../missing-docs/interfaces"

import { useHandleMessages } from "../common/messages/useHandleMessages"
import { formSectionsToRoutes } from "./constants"
import { SECTIONS } from "../missing-docs/constants"
import CommunicationEvents from "../missing-docs/Events/CommunicationEvents"
import { createMissingExhibitEvent, getMissingExhibits } from "../api"
import { queryKeys } from "../react-query/constants"
import { useParams } from "react-router-dom"
import { queryClient } from "../react-query/queryClient"
import { Loading } from "../common"

interface FormValues {
  comment: string
}

const Subtitle = styled(Box)({
  fontWeight: 600,
  fontSize: "1rem",
})

const InfoText = styled(Box)({
  fontWeight: 400,
  fontSize: "0.80rem",
  fontStyle: "italic",
})

interface Props {
  lastVisited: React.MutableRefObject<Nullable<string>>
}

const defaultValues = {
  comment: "",
}

const MissingDocuments = ({ lastVisited }: Props): JSX.Element => {
  const { control, getValues, reset } = useForm<FormValues>({
    defaultValues: defaultValues,
  })
  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.missing_docs
  })

  const { showMessage } = useHandleMessages()
  const [commentsEnabled, setCommentsEnabled] = useState(true)
  const { id: caseId } = useParams()
  const { data: missingExhibits } = useQuery([queryKeys.missingExhibits, caseId], async () => {
    return await getMissingExhibits({ caseId: caseId, onlyUnresolved: false })
  })

  const createEventMutation = useMutation(createMissingExhibitEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.missingExhibitEvents)
      queryClient.invalidateQueries(queryKeys.missingExhibits)
      reset(defaultValues)
    },
    onError: () => {
      showMessage({
        type: "error",
        message: "There was an error notifiying client. Please contact Engineering",
      })
    },
    onSettled: () => {
      setCommentsEnabled(false)
    },
  })

  const handleNotifyClient = () => {
    setCommentsEnabled(false)
    createEventMutation.mutate({
      caseId,
      data: {
        comment: getValues("comment"),
      },
    })
  }

  if (!missingExhibits || !caseId) return <></>

  const factsMissingExhibits: MissingExhibit[] = []
  const providersMissingExhibits: MissingExhibit[] = []
  const lossOfHouseholdServicesMissingExhibits: MissingExhibit[] = []
  const lossOfIncomeMissingExhibits: MissingExhibit[] = []

  missingExhibits.forEach((file: MissingExhibit) => {
    if (file.section === SECTIONS.CASE_FACTS) factsMissingExhibits.push(file)
    if (file.section === SECTIONS.PROVIDERS) providersMissingExhibits.push(file)
    if (file.section === SECTIONS.HOUSEHOLD_LOSS) lossOfHouseholdServicesMissingExhibits.push(file)
    if (file.section === SECTIONS.INCOME_LOSS) lossOfIncomeMissingExhibits.push(file)
  })

  const unresolvedMissingExhibitCount = missingExhibits.filter(
    (me: MissingExhibit) => me.is_resolved != true
  ).length

  return (
    <Box>
      <MissingDocumentSection
        missingDocs={factsMissingExhibits}
        title="Case Facts Section"
        section={SECTIONS.CASE_FACTS}
      />
      <MissingDocumentSection
        missingDocs={providersMissingExhibits}
        title="Providers Section"
        section={SECTIONS.PROVIDERS}
        canSelectProvider={true}
      />
      <MissingDocumentSection
        missingDocs={lossOfIncomeMissingExhibits}
        title="Loss of Income Section"
        section={SECTIONS.INCOME_LOSS}
      />
      <MissingDocumentSection
        missingDocs={lossOfHouseholdServicesMissingExhibits}
        title="Loss of Household Services Section"
        section={SECTIONS.HOUSEHOLD_LOSS}
      />

      <Box mt={2}>
        <Subtitle>Comments</Subtitle>
        {/* TODO: Replace with RTF */}
        {/* <RichTextField control={control} name="comment_json" markdownName="comment" /> */}
        <InputField
          control={control}
          name="comment"
          variant="outlined"
          multiline={true}
          minRows={3}
          fullWidth={true}
          disabled={!commentsEnabled}
        />
      </Box>
      <Box mt={1}>
        <Button
          onClick={handleNotifyClient}
          variant="contained"
          color="primary"
          disabled={!commentsEnabled}
          data-test="notify-client-button"
        >
          Notify Client
        </Button>
      </Box>
      <Box mt={1}>
        <InfoText>
          When ready, click &quot;Notify Client&quot; and an email will be sent containing the information
          above.
        </InfoText>
        <InfoText>You can only notify the client every 24 hours.</InfoText>
      </Box>

      <CommunicationEvents
        title="Client Communications"
        caseId={+caseId}
        setCommentsEnabled={setCommentsEnabled}
        unresolvedMissingExhibitCount={unresolvedMissingExhibitCount}
      />
      <Loading showOnMutation={true} />
    </Box>
  )
}

export { MissingDocuments as default }
