import React from "react"
import { Tooltip as MuiTooltip } from "@material-ui/core"

interface Props {
  tooltipText: string
  disabled?: boolean
  onClick: () => void
  children: React.ReactElement
  placement?: string
  other?: any
}

const Tooltip = ({
  tooltipText,
  onClick,
  children,
  disabled = false,
  placement = "top",
  ...other
}: Props): React.ReactElement => {
  const child = React.cloneElement(children, { disabled, onClick, placement, ...other })

  return (
    <MuiTooltip title={tooltipText} {...other} data-test="tooltip">
      <span>{child}</span>
    </MuiTooltip>
  )
}

export { Tooltip as default }
