import React, { useState, useMemo, forwardRef } from "react"
import { useQuery } from "react-query"
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Button,
  Box,
  Slide,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"
import { Autocomplete } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles"
import { startCase, snakeCase, transform } from "lodash"

import { queryKeys } from "../react-query/constants"
import { getMedicalAssociations } from "../api"

import { useFormContext } from "./FormContext"
import { GenericError } from "../common"
import InjuriesSearch from "./InjuriesSearch"

const DEFAULT_SELECTIONS = {
  category: "",
  problem: "",
  level1: "",
  level2: "",
  level3: "",
  level4: "",
}

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: "flex",
    flexDirection: "column",
  },
  titleAndIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContent: {
    display: "grid",
    gridGap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  ontologyContainer: {
    display: "grid",
    gridGap: theme.spacing(1),
  },
  ontologySelections: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gridGap: theme.spacing(1),
  },
  injuryDetail: {
    display: "flex",
  },
  ontologyTable: {
    "& td": {
      border: "none",
      "&:first-child": {
        fontWeight: "500",
      },
    },
  },
}))

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

function InjuryDetail({ selectedInjury }) {
  const classes = useStyles()
  return (
    <Card variant="outlined" align="left" className={classes.injuryDetail}>
      <CardHeader title={selectedInjury.injury} subheader="Injury descriptors" />
      <CardContent>
        {selectedInjury.ontology && (
          <Table size="small" className={classes.ontologyTable}>
            <TableBody>
              {Object.entries(selectedInjury.ontology).map(([key, value]) => {
                if (value) {
                  return (
                    <TableRow key={key}>
                      <TableCell>{startCase(key)}</TableCell>
                      <TableCell>{value}</TableCell>
                    </TableRow>
                  )
                }
              })}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  )
}

function OntologyExplorer({ onSelect, currentSelections }) {
  const classes = useStyles()
  const [options, setOptions] = useState({ ...DEFAULT_SELECTIONS })
  const { isLoading, error } = useQuery(
    [queryKeys.medicalAssociations, currentSelections],
    getMedicalAssociations,
    {
      onSuccess: data => {
        const { category, problem, level_1, level_2, level_3, level_4 } = data
        const groupedOptions = {
          category,
          problem,
          level1: level_1,
          level2: level_2,
          level3: level_3,
          level4: level_4,
        }

        setOptions(groupedOptions)
      },
    }
  )

  if (error) return <GenericError />

  return (
    <Box className={classes.ontologyContainer}>
      <Box className={classes.ontologySelections}>
        <Autocomplete
          loading={isLoading}
          options={options.category}
          onChange={(_, value) => onSelect(value, "category")}
          value={currentSelections.category}
          renderInput={params => <TextField {...params} label={"Category"} variant="outlined" />}
          disabled={!options.category.length}
        />
        <Autocomplete
          loading={isLoading}
          options={options.problem}
          onChange={(_, value) => onSelect(value, "problem")}
          value={currentSelections.problem}
          renderInput={params => <TextField {...params} label={"Problem"} variant="outlined" />}
          disabled={!options.problem.length}
        />
      </Box>
      <Box className={classes.ontologySelections}>
        <Autocomplete
          loading={isLoading}
          options={options.level1}
          onChange={(_, value) => onSelect(value, "level1")}
          value={currentSelections.level1}
          renderInput={params => <TextField {...params} label={"Level 1"} variant="outlined" />}
          disabled={!options.level1.length}
        />
        <Autocomplete
          loading={isLoading}
          options={options.level2}
          onChange={(_, value) => onSelect(value, "level2")}
          value={currentSelections.level2}
          renderInput={params => <TextField {...params} label={"Level 2"} variant="outlined" />}
          disabled={!options.level2.length}
        />
        <Autocomplete
          loading={isLoading}
          options={options.level3}
          onChange={(_, value) => onSelect(value, "level3")}
          value={currentSelections.level3}
          renderInput={params => <TextField {...params} label={"Level 3"} variant="outlined" />}
          disabled={!options.level3.length}
        />
        <Autocomplete
          loading={isLoading}
          options={options.level4}
          onChange={(_, value) => onSelect(value, "level4")}
          value={currentSelections.level4}
          name="level4"
          renderInput={params => <TextField {...params} label={"Level 4"} variant="outlined" />}
          disabled={!options.level4.length}
        />
      </Box>
    </Box>
  )
}

export default function InjuriesOntology({ open = false, onClose }) {
  const classes = useStyles()
  const { queryActions, handleQueryUpdate } = useFormContext()

  const [selectedOntology, setSelectedOntology] = useState({ ...DEFAULT_SELECTIONS })
  const [selectedInjury, setSelectedInjury] = useState(null)
  useQuery([queryKeys.medicalAssociations, { injury: selectedInjury?.injury }], getMedicalAssociations, {
    onSuccess: data => {
      const { category, problem, level_1, level_2, level_3, level_4 } = data
      setSelectedInjury(prevValue => ({
        ...prevValue,
        ontology: { category, problem, level1: level_1, level2: level_2, level3: level_3, level4: level_4 },
      }))
    },
    enabled: !!selectedInjury?.injury,
  })
  const hasAppliedOntology = useMemo(() => {
    return Object.values(selectedOntology).some(value => value)
  }, [selectedOntology])

  const handleAddInjuryFacet = () => {
    const injuryType = { injury_types: selectedInjury.injury }
    handleQueryUpdate(queryActions.APPLY_FACET, { name: "injury_tags", value: injuryType })
    setSelectedInjury(null)
    onClose()
  }

  const handleAddOntology = ontology => {
    const snakeCasedOntology = transform(
      ontology,
      (result, value, key) => {
        result[snakeCase(key)] = value
        return result
      },
      {}
    )
    handleQueryUpdate(queryActions.APPLY_FACET, { name: "injury_tags", value: snakeCasedOntology })
    setSelectedInjury(null)
    onClose()
  }

  const applyInjuryOntology = () => {
    const { ontology } = selectedInjury
    setSelectedInjury(null)
    setSelectedOntology({ ...ontology })
  }

  const onChange = (event, option) => {
    if (!option) {
      setSelectedInjury(null)
      return
    }
    const { association, value } = option
    if (association === "injury") {
      setSelectedInjury({ injury: value })
      setSelectedOntology({ ...DEFAULT_SELECTIONS })
    } else {
      setSelectedOntology({ ...DEFAULT_SELECTIONS, [association]: value })
    }
  }

  const handleOntologyChange = (option, key) => {
    setSelectedOntology({ ...selectedOntology, [key]: option })
  }

  const resetSelections = () => {
    setSelectedOntology({ ...DEFAULT_SELECTIONS })
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth scroll="paper" TransitionComponent={Transition}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Box className={classes.titleAndIcon}>
          <Typography variant="h6">Add injuries to current search</Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="body2">
          Injuries can be added by name (more specific) or by its descriptors (broader)
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <InjuriesSearch onChange={onChange} />
        {selectedInjury && (
          <InjuryDetail
            selectedInjury={selectedInjury}
            onAddInjury={handleAddInjuryFacet}
            onAddOntology={handleAddOntology}
            onExplore={applyInjuryOntology}
          />
        )}
        {hasAppliedOntology && (
          <OntologyExplorer onSelect={handleOntologyChange} currentSelections={selectedOntology} />
        )}
      </DialogContent>
      <DialogActions>
        {selectedInjury && !hasAppliedOntology && (
          <>
            <Button size="small" color="primary" onClick={applyInjuryOntology}>
              Find similar descriptors
            </Button>
            <Button size="small" color="primary" onClick={() => handleAddOntology(selectedInjury.ontology)}>
              Add Injury Descriptors
            </Button>
            <Button size="small" color="primary" onClick={handleAddInjuryFacet}>
              Add Injury
            </Button>
          </>
        )}
        {hasAppliedOntology && (
          <>
            <Button size="small" color="primary" onClick={resetSelections}>
              Reset
            </Button>
            <Button size="small" color="primary" onClick={() => handleAddOntology(selectedOntology)}>
              Add Injury Descriptors
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
