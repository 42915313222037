import retry from "async-retry"
import { fileService } from "api/services/file"
import { FileUploadActions } from "../file-state"
import { queue } from "../queue"
import { Flow, FileToUpload, Dispatch } from "./types"

export function getChunksUploadFlow(dispatch: Dispatch<FileUploadActions>): Flow<FileToUpload, void> {
  return async (data, { signal }) => {
    const { chunkCount, chunks } = data

    dispatch({ type: "CREATE_UPLOAD", payload: { chunkCount } })

    const createdUpload = await fileService.createFileUpload({ data })
    const uploadId = createdUpload.id

    dispatch({ type: "PROCESS_UPLOAD", payload: { uploadId } })

    const tasks = chunks.map(chunk => {
      const task = () => fileService.uploadChunk({ data: { ...chunk, upload: uploadId }, uploadId })
      const retryableTask = () => retry(task, { retries: 5 })

      return queue.add(retryableTask, { signal })
    })

    for (const [i, task] of tasks.entries()) {
      task
        .then(() =>
          dispatch({
            type: "UPDATE_PROGRESS",
            payload: { chunkIndex: i },
          })
        )
        .catch(e =>
          dispatch({
            type: "FAIL",
            payload: { reason: e instanceof Error ? e.message : "Chunk uploading failed" },
          })
        )
    }

    await Promise.all(tasks)

    dispatch({ type: "COMPLETE_UPLOAD", payload: {} })
  }
}
