import { getServiceWorker } from "./getServiceWorker"
import { Publish } from "./types"

export const publish: Publish = (topic, payload) => {
  const channel = new MessageChannel()
  const port = channel.port1

  return new Promise((resolve, reject) => {
    port.onmessageerror = reject
    port.onmessage = event => {
      if (event.data.success) {
        resolve({ success: event.data.success, payload: event.data.payload })
      } else {
        reject(event.data.error)
      }
    }

    getServiceWorker()
      .then(serviceWorker => {
        serviceWorker.postMessage({ topic, payload }, [channel.port2])
      })
      .catch(reason => reject(reason))
  })
}
