import { LibraryTabState } from "./types"
import { TemplateAction, ActionType } from "./templatesActions"

export const templatesTabReducer = (state: LibraryTabState, action: TemplateAction): LibraryTabState => {
  const { type, payload } = action

  switch (type) {
    case ActionType.EXPAND_TEMPLATE: {
      const updates = { expandedEntityIds: [...state.expandedEntityIds, payload.templateId] }

      return { ...state, ...updates }
    }

    case ActionType.COLLAPSE_TEMPLATE: {
      const updates = { expandedEntityIds: state.expandedEntityIds.filter(id => payload.templateId !== id) }

      return { ...state, ...updates }
    }

    case ActionType.START_EDIT_TEMPLATE: {
      const updates = {
        expandedEntityIds: state.expandedEntityIds.filter(id => payload.templateId !== id),
        editingEntityId: payload.templateId,
      }

      return { ...state, ...updates }
    }

    case ActionType.END_EDIT_TEMPLATE: {
      const updates = { editingEntityId: null }

      return { ...state, ...updates }
    }

    case ActionType.START_CREATING_TEMPLATE: {
      const initialTemplateData = payload || {}
      const updates = { creatingData: initialTemplateData }

      return { ...state, ...updates }
    }

    case ActionType.END_CREATING_TEMPLATE: {
      const updates = { creatingData: undefined }

      return { ...state, ...updates }
    }

    default: {
      return state
    }
  }
}
