import React from "react"
import { CircularProgress, Tooltip } from "@material-ui/core"
import {
  CheckCircleRounded as CheckCircleRoundedIcon,
  Error as ErrorIcon,
  HighlightOffRounded as HighlightOffRoundedIcon,
} from "@material-ui/icons"
import { CircularProgressWithLabel } from "common/CircularProgressWithLabel"
import { FileUploadState } from "../file-state"

export const FileUploadItemIcon: React.FC<{ state: FileUploadState }> = ({ state }) => {
  const { current: status, data } = state

  switch (status) {
    case "PREPARING_FOR_UPLOAD":
    case "CREATING_UPLOAD":
      return <CircularProgress size={25} />
    case "DELETED":
      return <CheckCircleRoundedIcon />
    case "CANCELLED":
      return <HighlightOffRoundedIcon />
    case "FAILURE":
      return (
        <Tooltip title={"An error has occured. Click save to try again"}>
          <ErrorIcon />
        </Tooltip>
      )
    case "UPLOADING_CHUNKS":
      return data.progress ? (
        <CircularProgressWithLabel size={25} value={data.progress} />
      ) : (
        <CircularProgress size={25} />
      )
    default:
      return <CheckCircleRoundedIcon />
  }
}
