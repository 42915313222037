import React, { useState } from "react"
import { Box, Tab as BaseTabsHeader, Tabs as BaseTabs } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import { TabPanel } from "./TabPanel"

const useStyles = makeStyles(theme => ({
  tabsWrapper: {
    boxShadow: theme.shadows[1],
    marginBottom: theme.spacing(2),
  },
  tabsHeader: {
    marginTop: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

interface TabsProps {
  className?: string
  children: React.ReactElement<{ title: string }>[]
}

export const Tabs: React.FC<TabsProps> = ({ className, children }: TabsProps) => {
  const classes = useStyles()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const tabsData = React.Children.map(children, (tabElement, index) => ({
    index,
    label: tabElement.props.title,
  }))

  const handleActiveTabIndexChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    nextIndex: number
  ) => {
    setActiveTabIndex(nextIndex)
  }

  return (
    <Box className={clsx(classes.tabsWrapper, className)}>
      <BaseTabs value={activeTabIndex} onChange={handleActiveTabIndexChange} className={classes.tabsHeader}>
        {tabsData.map(({ label, index }) => (
          <BaseTabsHeader label={label} key={index} />
        ))}
      </BaseTabs>
      {React.Children.map(children, (tabContent, index) => (
        <TabPanel key={index} active={activeTabIndex === index}>
          {tabContent}
        </TabPanel>
      ))}
    </Box>
  )
}
