import React, { useState, useCallback, useEffect } from "react"
import { Box, CircularProgress } from "@material-ui/core"
import clsx from "clsx"
import { isEqual } from "date-fns"

import { useQuery } from "react-query"
import { queryKeys } from "react-query/constants"
import { getAnnotatedProviderAppointments } from "api"

import Cell from "./Cell"
import { Header, useStyles } from "../styled"
import { Appointment } from "./types"
import { Content, ContentHeader, Row } from "./styled"
import { Container } from "../Container"
import ErrorState from "../ErrorState"

import { useFeatures, FEATURES } from "hooks/useFeatures"
import ContainerLabel from "../ContainerLabel"
import { PartitionEntry } from "../types"

interface AppointmentsProps {
  caseId: number
  providerId: number
  currentFirstAppointment: string
  currentLastAppointment: string
  currentNumberOfVisits: number
  onEntryClick: (entry: PartitionEntry) => void
  dateUpdated?: string
}

const Appointments = ({
  providerId,
  caseId,
  currentFirstAppointment,
  currentLastAppointment,
  currentNumberOfVisits,
  onEntryClick,
  dateUpdated,
}: AppointmentsProps): JSX.Element => {
  const { isFeatureEnabled } = useFeatures()
  const isProviderAutofillEnabled = isFeatureEnabled(FEATURES.PROVIDER_AUTOFILL)
  const classes = useStyles()
  const [appointments, setAppointments] = useState<Appointment[]>([])
  const [isDeviated, setIsDeviated] = useState<boolean>(false)

  const checkForDeviation = useCallback(
    (annotatedAppointments: Appointment[]) => {
      if (annotatedAppointments.length !== currentNumberOfVisits) {
        return setIsDeviated(true)
      }

      if (!annotatedAppointments?.length) {
        return setIsDeviated(false)
      }

      const firstDate = annotatedAppointments[0].bill.date || annotatedAppointments[0].record.date || ""
      if (currentNumberOfVisits === 1) {
        if (
          currentLastAppointment ||
          !isEqual(new Date(firstDate), new Date(currentFirstAppointment ?? ""))
        ) {
          return setIsDeviated(true)
        }

        return setIsDeviated(false)
      }

      const lastIndex = annotatedAppointments.length - 1
      const lastDate =
        annotatedAppointments[lastIndex].bill.date || annotatedAppointments[lastIndex].record.date || ""

      if (!isEqual(new Date(lastDate), new Date(currentLastAppointment ?? ""))) {
        return setIsDeviated(true)
      }

      setIsDeviated(false)
    },
    [currentFirstAppointment, currentLastAppointment, currentNumberOfVisits]
  )

  const { isFetching, isError } = useQuery(
    [queryKeys.annotatedAppointments, caseId, providerId],
    async () => {
      return await getAnnotatedProviderAppointments(caseId, providerId)
    },
    {
      meta: {
        disableLoader: true,
      },
      onSuccess: response => {
        setAppointments(response)
      },
      enabled: isProviderAutofillEnabled,
    }
  )

  useEffect(() => {
    checkForDeviation(appointments)
  }, [
    currentFirstAppointment,
    currentLastAppointment,
    currentNumberOfVisits,
    checkForDeviation,
    appointments,
  ])

  if (!isProviderAutofillEnabled) {
    return <></>
  }

  if (isFetching) {
    return (
      <Container includeDeviation={false}>
        <Box display="flex" m={"auto"}>
          <CircularProgress />
        </Box>
      </Container>
    )
  }

  if (isError) {
    return (
      <Container>
        <ErrorState message="There was an error retrieving annotated appointments" />
      </Container>
    )
  }

  if (!isFetching && !appointments.length) {
    return (
      <Container>
        <Box display="flex" m={"auto"}>
          <Box>No Annotated Appointments</Box>
        </Box>
      </Container>
    )
  }

  return (
    <Container>
      <Header>
        <Box>Appointments - No. of Visits {appointments.length}</Box>
        <ContainerLabel
          includeDeviation={isDeviated}
          includeAnnotationUpdated={!!dateUpdated}
          dateUpdated={dateUpdated}
        />
      </Header>
      <Content>
        <ContentHeader>
          <Box>Bills</Box>
          <Box>Records</Box>
        </ContentHeader>

        {appointments.map((appointment: Appointment, index) => {
          const { bill, record } = appointment
          return (
            <Row key={index} className={clsx(index % 2 ? classes.lightRow : classes.darkRow)}>
              <Cell date={bill.date} entries={bill.entries} onEntryClick={onEntryClick}></Cell>
              <Cell date={record.date} entries={record.entries} onEntryClick={onEntryClick}></Cell>
            </Row>
          )
        })}
      </Content>
    </Container>
  )
}

export { Appointments as default }
