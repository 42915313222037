export const MIN_PAGE_NUMBER = 1

export type AnnotationStatus = "pre_annotating" | "annotating" | "auto_complete" | "complete"

export interface AnnotationFileExcerpt {
  provider_name: string
  start_page: number
  end_page: number
  relation_type: string
  first_service_date: string
  last_service_date: string
  number_of_visits: number
}

export interface AnnotationFileType {
  pk: number
  status: AnnotationStatus
  has_annotated_file: boolean
  has_ocr_file: boolean
  has_pre_annotated_file: boolean
}

export interface PartitionProvider {
  pk: number
  name: string
  color: string
}

export type ActionType = "exclude" | "delete"

export interface Action {
  pk: number
  start_page: number
  end_page?: Nullable<number>
  action_type: ActionType
}

export interface DeleteAction extends Action {
  action_type: "delete"
}

export interface ExcludeAction extends Action {
  action_type: "exclude"
}

export type Section = "providers" | null

export interface Partition {
  pk: number
  start_page: number
  end_page: number
  provider?: PartitionProvider
  excerpts: AnnotationFileExcerpt[]
  section: Section
}

export interface WritablePartition {
  partition_provider_id: number
  start_page: number
  end_page: number
  section: Section
}

export type Split = Partition

export const DUPLICATE_PARTITION_TYPE = "duplicate"

export interface Duplicate extends Partition {
  actions?: Action[]
  partition_type: typeof DUPLICATE_PARTITION_TYPE
}

export interface AnnotatedExhibit {
  pk: number
  name: string
  number_of_pages: number
  annotation_file?: AnnotationFileType
  splits: Split[]
  duplicates: Duplicate[]
  actions: Action[]
}

export interface Range {
  start: number
  end: number
}

export interface FormRange extends Range {
  formId: string
}

export type CaseId = string | number

export interface FieldValidationError {
  field: string
  message: string
}

export type FieldValidationErrors = Record<string, Nullable<FieldValidationError[]>>
