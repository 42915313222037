import { handleEmptyResponse } from "api/utils"
import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"
import { isUndefined } from "lodash"
import { apiService } from "../ApiService"
import { SectionTemplateServiceDeserializer } from "../section-template/serializers"
import { ApiServiceType } from "../types"
import { CaseServiceDeserializer, CaseServiceSerializer } from "./serializers"
import {
  CaseAttributeValuesDto,
  CaseFactsSectionUpdateDto,
  CaseFactsUpdateDto,
  ProviderTemplatedSectionDto,
  ProviderTemplatedSectionUpdateDto,
  ProviderTemplateUpdataDto,
} from "./types"

enum CASE_API_PATHS {
  BASE = "case",
  ATTRIBUTES = "attributes",
  MATCHING_TEMPLATES = "matching_templates",
  FACTS = "facts",
  TEMPLATED_SECTIONS = "template_sections",
  TEMPLATED_PROVIDER_SECTIONS = "template-sections",
  TEMPLATED_SECTION_OVERRIDE = "confirm_override",
  TEMPLATED_PROVIDER_OVERRIDE = "confirm-override",
  PROVIDER = "provider",
}

interface CaseServiceOptions {
  caseId: PrimaryKey
}

interface SelectProviderTemplateOptions {
  caseId: PrimaryKey
  providerId: PrimaryKey
  templateSectionId: PrimaryKey
}

class CaseService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(path?: CASE_API_PATHS | (CASE_API_PATHS | PrimaryKey)[]): string {
    const pathParts = Array.from<string | PrimaryKey>(["", CASE_API_PATHS.BASE]).concat(path ?? [])
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getCase = withResponseSerializer(CaseServiceDeserializer.fromJSON, ({ caseId }: CaseServiceOptions) => {
    return handleEmptyResponse(this.apiService.get(null, this.getPath([caseId])))
  })

  updataCaseAttributes = withRequestSerializer(
    CaseServiceSerializer.attributeValuesToJSON,
    withResponseSerializer(
      CaseServiceDeserializer.fromJSON,
      ({ data, caseId }: { data: CaseAttributeValuesDto } & CaseServiceOptions) => {
        return handleEmptyResponse(
          this.apiService.update(data, this.getPath([caseId, CASE_API_PATHS.ATTRIBUTES]))
        )
      }
    )
  )

  getMatchingTemplates = withResponseSerializer(
    SectionTemplateServiceDeserializer.templatesBySectionFromJSON,
    ({ caseId }: CaseServiceOptions) => {
      return handleEmptyResponse(
        this.apiService.get(null, this.getPath([caseId, CASE_API_PATHS.MATCHING_TEMPLATES]))
      )
    }
  )

  getCaseFacts = withResponseSerializer(
    CaseServiceDeserializer.caseFactsFromJSON,
    ({ caseId }: CaseServiceOptions) => {
      return handleEmptyResponse(this.apiService.get(null, this.getPath([caseId, CASE_API_PATHS.FACTS])))
    }
  )

  selectCaseProviderTemplate = withRequestSerializer(
    CaseServiceSerializer.providerTemplatedSectionToJSON,
    ({
      caseId,
      providerId,
      templateSectionId,
      data,
    }: SelectProviderTemplateOptions & { data: ProviderTemplateUpdataDto }) => {
      return handleEmptyResponse<ProviderTemplatedSectionDto>(
        this.apiService.replace(
          data,
          this.getPath([
            caseId,
            CASE_API_PATHS.PROVIDER,
            providerId,
            CASE_API_PATHS.TEMPLATED_PROVIDER_SECTIONS,
            templateSectionId,
          ])
        )
      )
    }
  )

  createCaseProviderTemplate = withRequestSerializer(
    CaseServiceSerializer.providerTemplatedSectionToJSON,
    ({
      caseId,
      providerId,
      data,
    }: Pick<SelectProviderTemplateOptions, "caseId" | "providerId"> & {
      data: ProviderTemplateUpdataDto
    }) => {
      return handleEmptyResponse<ProviderTemplatedSectionDto>(
        this.apiService.create(
          data,
          this.getPath([
            caseId,
            CASE_API_PATHS.PROVIDER,
            providerId,
            CASE_API_PATHS.TEMPLATED_PROVIDER_SECTIONS,
          ])
        )
      )
    }
  )

  overrideProviderTemplate1 = withRequestSerializer(
    CaseServiceSerializer.providerTemplatedSectionToJSON,
    ({
      caseId,
      providerId,
      templateSectionId,
      data,
    }: SelectProviderTemplateOptions & { data: ProviderTemplateUpdataDto }) => {
      return handleEmptyResponse<ProviderTemplatedSectionDto>(
        this.apiService.replace(
          data,
          this.getPath([
            caseId,
            CASE_API_PATHS.PROVIDER,
            providerId,
            CASE_API_PATHS.TEMPLATED_PROVIDER_SECTIONS,
            templateSectionId,
          ])
        )
      )
    }
  )

  overrideProviderTemplate = withRequestSerializer(
    CaseServiceSerializer.resolvedProviderTemplatedSectionToJSON,
    ({
      data,
      caseId,
      providerId,
      templateSectionId,
    }: SelectProviderTemplateOptions & { data: ProviderTemplatedSectionUpdateDto }) => {
      return handleEmptyResponse<ProviderTemplatedSectionDto>(
        this.apiService.create(
          data,
          this.getPath([
            caseId,
            CASE_API_PATHS.PROVIDER,
            providerId,
            CASE_API_PATHS.TEMPLATED_PROVIDER_SECTIONS,
            templateSectionId,
            CASE_API_PATHS.TEMPLATED_PROVIDER_OVERRIDE,
          ])
        )
      )
    }
  )

  saveCaseFacts = withRequestSerializer(
    CaseServiceSerializer.caseFactsToJSON,
    withResponseSerializer(
      CaseServiceDeserializer.caseFactsFromJSON,
      ({ caseId, data }: CaseServiceOptions & { data: CaseFactsUpdateDto }) => {
        return handleEmptyResponse(this.apiService.create(data, this.getPath([caseId, CASE_API_PATHS.FACTS])))
      }
    )
  )

  overrideCaseSection = withRequestSerializer(
    CaseServiceSerializer.resolvedTemplatedSectionToJSON,
    withResponseSerializer(
      CaseServiceDeserializer.templatedSectionFromJSON,
      ({ caseId, data }: CaseServiceOptions & { data: CaseFactsSectionUpdateDto }) => {
        return handleEmptyResponse(
          this.apiService.create(
            data,
            this.getPath([
              caseId,
              CASE_API_PATHS.TEMPLATED_SECTIONS,
              data.pk,
              CASE_API_PATHS.TEMPLATED_SECTION_OVERRIDE,
            ])
          )
        )
      }
    )
  )
}

export const caseService = new CaseService(apiService)
