import React, { useCallback } from "react"
import { Cancel as CancelIcon, InsertDriveFileOutlined as FileIcon } from "@material-ui/icons"
import { FILE_CATEGORY_DISPLAY_OPTIONS, FILE_CATEGORY_OPTIONS } from "common/constants"
import {
  ArrayPath,
  Control,
  FieldErrors,
  FieldValues,
  Path,
  useController,
  useFieldArray,
} from "react-hook-form"
import { FieldPathWithValue } from "common/types/helper"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { SelectInput } from "common/form-components/SelectInput"
import { isNull } from "lodash"
import FileDropzone, { FileDropzoneProps } from "./FileDropzone"
import { PendingFile } from "./types"
import { includesFile, mapFileToPending, mapPendingToFile } from "./utils"
import { FileAttributes, FileNameLabel, FilesContainer, FileWrapper } from "./styled"
import { Box, IconButton } from "@material-ui/core"

interface DragAndDropFileFieldFormProps<TFields extends FieldValues> {
  control: Control<TFields>
  name: FieldPathWithValue<TFields, PendingFile[]>
  errors: FieldErrors<TFields>
  fileTypes?: FILE_CATEGORY_OPTIONS[]
}

type DragAndDropFileFieldProps<TFields extends FieldValues> = DragAndDropFileFieldFormProps<TFields> &
  Omit<FileDropzoneProps, "onDrop">

export function DragAndDropFileField<TFields extends FieldValues>({
  control,
  name,
  disabled,
  fileTypes = Object.values(FILE_CATEGORY_OPTIONS),
  errors,
  ...dropZoneProps
}: DragAndDropFileFieldProps<TFields>): JSX.Element {
  const { fields, append, remove } = useFieldArray({ name: name as unknown as ArrayPath<TFields>, control })
  const { field: pendingFilesField } = useController({ control, name })
  const fileTypeOptions: ValueOptions<FILE_CATEGORY_OPTIONS> = fileTypes.map(key => ({
    key,
    display: FILE_CATEGORY_DISPLAY_OPTIONS[key],
  }))

  const handleDrop = useCallback<FileDropzoneProps["onDrop"]>(
    droppedFiles => {
      const pendingFiles = pendingFilesField.value as PendingFile[]
      const pendingRawFiles = pendingFiles.map(mapPendingToFile)
      const newFiles: File[] = droppedFiles
        .map(file => ("file" in file ? file.file : file))
        .filter(file => !isNull(file)) as File[]
      const filesToAdd = newFiles.filter(file => !includesFile(pendingRawFiles, file))

      if (filesToAdd.length) {
        // Too hard to resolve correct typings for react-hook-form
        append(filesToAdd.map(mapFileToPending) as any[])
      }
    },
    [append, pendingFilesField]
  )

  const handleFileRemove = (index: number) => () => {
    remove(index)
  }

  const pendingFiles = pendingFilesField.value as PendingFile[]

  return (
    <DndProvider backend={HTML5Backend}>
      <FileDropzone onDrop={handleDrop} {...dropZoneProps} disabled={disabled}>
        {dropZoneProps.children}
        {pendingFiles.length ? (
          <FilesContainer>
            {pendingFiles.map((file, i) =>
              fields[i] ? (
                <FileWrapper key={fields[i].id}>
                  <FileAttributes>
                    <FileIcon />
                    <FileNameLabel>{file.name}</FileNameLabel>
                  </FileAttributes>
                  <FileAttributes>
                    <SelectInput
                      size="small"
                      control={control}
                      rules={{ required: true }}
                      label="Document Type"
                      name={
                        `${name}.${i}.type` as FieldPathWithValue<
                          TFields,
                          FILE_CATEGORY_OPTIONS,
                          Path<TFields>
                        >
                      }
                      errors={errors}
                      options={fileTypeOptions}
                      required
                      disabled={disabled}
                    />
                    <Box>
                      <IconButton disabled={disabled} data-file-index={i} onClick={handleFileRemove(i)}>
                        <CancelIcon />
                      </IconButton>
                    </Box>
                  </FileAttributes>
                </FileWrapper>
              ) : null
            )}
          </FilesContainer>
        ) : null}
      </FileDropzone>
    </DndProvider>
  )
}
