import { useCallback, useEffect, useRef } from "react"
import { apm as elasticApm } from "@elastic/apm-rum"
import { MESSAGE_TOPIC } from "./topics"
import { MessageHandler, OnErrorCallback } from "./types"
import { useMessageBroker } from "./useMessageBroker"
import { noop } from "lodash"

interface UseSubscribeOptions {
  enabled?: boolean
  onError?: (reason: any) => void
}

export function useSubscribe(
  topic: MESSAGE_TOPIC,
  handler: MessageHandler,
  { enabled = true, onError = noop }: UseSubscribeOptions
): void {
  const [, subscribe] = useMessageBroker()

  const handerRef = useRef(handler)
  handerRef.current = handler

  const onErrorRef = useRef(onError)
  onErrorRef.current = onError

  const handleError = useCallback<OnErrorCallback>(reason => {
    onErrorRef.current(reason)
    // Log messaging error to be able to discover issues
    elasticApm.captureError(`Message Broker error (subscribe): ${reason}`)
  }, [])

  const handleMessage = useCallback<MessageHandler>(async event => {
    handerRef.current(event)
  }, [])

  useEffect(() => {
    if (enabled) {
      const unsubscribe = subscribe(topic, handleMessage, handleError)
      return () => unsubscribe()
    }
  }, [enabled, subscribe, handleMessage, topic, handleError])
}
