import React, { ReactElement, useCallback } from "react"
import { FieldValues, Control, useController } from "react-hook-form"
import { Descendant } from "slate"
import { RichTextEditor } from "./RichTextEditor"
import { deserializeFromMarkdown } from "./serializer/markdown/deserializer"
import { serializeToMarkdown } from "./serializer/markdown/serializer"
import { RootElement, EditorRoot } from "./CustomEditor"
import { isNil } from "lodash"
import { FieldPathWithValue } from "../../types/helper"
import { EditorFeatureProps } from "./features/types"
import { DEFAULT_VALUE } from "./defaultValue"
import { RichTextWithActions } from "./RichTextWithActions"

export interface RichTextFieldProps<TFields extends FieldValues> {
  control: Control<TFields>
  // Temporary fix as react-hook-form does not support curcular tree types
  // Issue and temporary fix:
  //   - https://github.com/react-hook-form/react-hook-form/issues/4055#issuecomment-774687195
  // TODO: replace Nullable<EditorRoot<false>> with Nullable<EditorRoot> after react-hook-form@v8
  name: FieldPathWithValue<TFields, Nullable<EditorRoot<false>>>
  markdownName?: FieldPathWithValue<TFields, Nullable<string>>
  label?: string
  helperText?: string
  dataTest?: string
  error?: boolean
  actions?: Nullable<React.ReactElement>
}

export function RichTextField<T extends FieldValues>({
  control,
  name,
  markdownName,
  label,
  helperText,
  dataTest,
  actions,
  error,
  ...featureProps
}: RichTextFieldProps<T> & EditorFeatureProps): ReactElement {
  const { field: markdownField } = useController({
    control,
    name: markdownName ?? name,
  })
  const { field: jsonField } = useController({
    control,
    name,
  })
  const hasJsonValue = !isNil(jsonField.value)
  const shouldUseMarkdown = !isNil(markdownName)
  const handleChange = useCallback(
    (nextValue: Descendant[]): void => {
      if (shouldUseMarkdown) {
        const nextMarkdownValue = serializeToMarkdown(nextValue as RootElement[])
        markdownField.onChange({ target: { value: nextMarkdownValue } })
      }
      jsonField.onChange({ target: { value: nextValue } })
    },
    [markdownField, jsonField, shouldUseMarkdown]
  )

  const value = hasJsonValue
    ? jsonField.value
    : shouldUseMarkdown
    ? (deserializeFromMarkdown(markdownField.value) as RootElement[])
    : DEFAULT_VALUE
  const onBlur = hasJsonValue ? jsonField.onBlur : markdownField.onBlur

  return (
    <RichTextWithActions label={label} actions={actions} helperText={helperText}>
      <RichTextEditor
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        keepValue={false}
        dataTest={dataTest}
        error={error}
        {...featureProps}
      />
    </RichTextWithActions>
  )
}
