import { intersectionObserverWithAutoscroll } from "observer"
import React, { useEffect, useRef } from "react"
import { FORM_STATE_STATUS } from "./constants"
import { StyledMessageWrapper, StyledMessage } from "./styled"

interface TemplateFormMessageProps {
  formStatus: FORM_STATE_STATUS
  message: Nullable<JSX.Element>
  clear: () => void
}

const CLEAR_TIMEOUT = 4000

export function TemplateFormMessage({
  formStatus,
  message,
  clear,
}: TemplateFormMessageProps): Nullable<JSX.Element> {
  const messageRef = useRef<Nullable<HTMLDivElement>>(null)
  const showMessage = formStatus !== FORM_STATE_STATUS.IDLE
  const showError = formStatus === FORM_STATE_STATUS.API_ERROR || formStatus === FORM_STATE_STATUS.DATA_ERROR
  const success = formStatus === FORM_STATE_STATUS.SUCCESS

  useEffect(() => {
    const element = messageRef.current
    if (showMessage && element) {
      intersectionObserverWithAutoscroll.observe(element)

      return () => {
        intersectionObserverWithAutoscroll.unobserve(element)
      }
    }
  }, [showMessage])

  useEffect(() => {
    let timer: Nullable<NodeJS.Timeout> = null

    if (showMessage && success) {
      timer = setTimeout(clear, CLEAR_TIMEOUT)
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [showMessage, success, clear])

  return (
    <StyledMessageWrapper ref={messageRef}>
      <StyledMessage error={showError}>{message}</StyledMessage>
    </StyledMessageWrapper>
  )
}
