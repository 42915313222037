import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { InputField } from "common/form-components"

const useStyles = makeStyles(theme => ({
  commentInput: {
    marginTop: theme.spacing(2),
  },
}))

export function CommentSection({ control }) {
  const classes = useStyles()

  return (
    <InputField
      name="income_loss_story"
      control={control}
      className={classes.commentInput}
      variant="outlined"
      fullWidth
      multiline
      rows={4}
      label="Commentary on income loss"
    />
  )
}
