import React from "react"
import { Box, List, ListItem } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Alert from "./Alert"

const useStyles = makeStyles(() => ({
  listItem: {
    display: "list-item",
    listStyle: "disc",
  },
}))

// eslint-disable-next-line @typescript-eslint/ban-types
type BaseData = {}
type ValidationFieldKeys<T extends BaseData> = keyof T
type FieldEntry<T extends BaseData> = [ValidationFieldKeys<T>, unknown]
type ValidationFieldEntry<T extends BaseData> = [ValidationFieldKeys<T>, boolean]

interface Props<T extends BaseData> {
  errorFields: ValidationFieldKeys<T>[]
  data: T
}

function MissingFieldsAlert<T extends BaseData>({ data, errorFields = [] }: Props<T>): JSX.Element {
  const classes = useStyles()
  const warningKeys: ValidationFieldKeys<T>[] = []
  const errorKeys: ValidationFieldKeys<T>[] = []

  const fieldEntries: FieldEntry<T>[] = data ? (Object.entries(data) as FieldEntry<T>[]) : []
  const validatedFieldEntries = fieldEntries.map<ValidationFieldEntry<T>>(([key, value]) => [
    key,
    value === null,
  ])

  validatedFieldEntries.forEach(([key, value]) => {
    if (!value) return

    if (errorFields.includes(key)) {
      errorKeys.push(key)
    } else {
      warningKeys.push(key)
    }
  })

  return (
    <>
      {!!errorKeys.length && (
        <Alert title="Missing Required Fields" severity="error">
          <Box>
            There is missing information that will impact this calculation. Please fill out all required
            fields before using the calculator.
          </Box>
          <List>
            {errorKeys.map(field => (
              <ListItem key={field.toString()} className={classes.listItem} dense={true}>
                {field}
              </ListItem>
            ))}
          </List>
        </Alert>
      )}

      {!!warningKeys.length && (
        <Alert title="Missing Fields" severity="warning">
          <Box>
            There is missing information that can impact this calculation. Please fill out all required fields
            before using the calculator.
          </Box>
          <List>
            {warningKeys.map(field => (
              <ListItem key={field.toString()} className={classes.listItem} dense={true}>
                {field}
              </ListItem>
            ))}
          </List>
        </Alert>
      )}
    </>
  )
}

export { MissingFieldsAlert as default }
