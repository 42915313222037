import React, { useState, useEffect, useMemo } from "react"
import { Box, Button, Divider, Typography } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { queryKeys } from "../react-query/constants"
import { useForm } from "react-hook-form"
import { amountInDollars } from "../utils"

import { useFormContext } from "./context"
import { addDamagesSection, fetchDamagesSectionsByCase, updateDamagesSection } from "../api"
import { formSectionsToRoutes, STEP_STATUSES } from "./constants"
import useAutosave from "../hooks/useAutosave"
import { replaceMatches } from "../common/form-components/rich-text/utils"
import { deserializeFromMarkdown } from "../common/form-components/rich-text/serializer/markdown/deserializer"
import { CurrencyField } from "common/form-components"
import { CaseEditor, CaseEditorField } from "./components/CaseEditor"
import { useCaseVariables } from "./Variables"

const useStyles = makeStyles(theme => ({
  formFields: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  sectionBox: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  innerSectionBox: {
    display: "flex",
    margin: theme.spacing(2),
  },
  actions: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    margin: theme.spacing(2, 0),
    justifyContent: "flex-end",
  },
  notice: {
    margin: theme.spacing(2, 0),
  },
  fieldBox: {
    width: "50%",
    margin: theme.spacing(2),
  },
  renderedSection: {
    width: "50%",
    borderLeftWidth: "1px",
    borderLeftColor: theme.palette.divider,
    borderLeftStyle: "solid",
    margin: theme.spacing(2),
    marginLeft: theme.spacing(0),
    paddingLeft: theme.spacing(2),
  },
}))

const INITIAL_SECTION_DATA = {
  damage_amount: 0,
  details: "",
  details_json: null,
}

export function DamagesSection({ caseId, template, section }) {
  const classes = useStyles()
  const queryClient = useQueryClient()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = useState(null)

  const { control, handleSubmit, getValues, watch, formState, reset } = useForm({
    defaultValues: { ...INITIAL_SECTION_DATA, ...section },
  })

  const damage_amount = watch("damage_amount")
  const damage_dollars = amountInDollars(damage_amount)

  const details = watch("details")
  const detailsJson = watch("details_json")

  const updateMutation = useMutation(updateDamagesSection, {
    onMutate: () => {
      setErrors(null)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.damagesSections)
      reset({}, { keepValues: true })
    },
    onError: error => {
      const errObject = !!error?.message.length && JSON.parse(error.message)
      setErrors(errObject || null)
    },
  })
  // succeed/fail silently
  const autosaveMutation = useMutation(updateDamagesSection, {
    onSuccess: () => reset({}, { keepValues: true }),
  })

  const createMutation = useMutation(addDamagesSection, {
    onMutate: () => {
      setErrors(null)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.damagesSections)
    },
    onError: error => {
      const errObject = !!error?.message.length && JSON.parse(error.message)
      setErrors(errObject || null)
    },
  })

  const dirtyFields = new Set(Object.keys(formState.dirtyFields))
  dirtyFields.delete("details")
  const isDirty = dirtyFields.size > 0

  const handleOnBlur = handleSubmit(async data => {
    if (!isDirty) return

    return section?.pk
      ? await updateMutation.mutateAsync({
          caseId,
          sectionId: section.pk,
          data: { template: template.pk, case: caseId, ...data },
        })
      : await createMutation.mutateAsync({
          caseId,
          data: { template: template.pk, case: caseId, ...data },
        })
  })

  useAutosave({
    shouldAutosave: isDirty && section?.pk,
    save: () => {
      autosaveMutation.mutate({
        caseId,
        sectionId: section.pk,
        data: { template: template.pk, case: caseId, ...getValues() },
      })
    },
  })

  // TODO: should be moved to data store (backend + frontend) later
  const damagesVariables = useMemo(
    () => [
      {
        name: "amount",
        type: "text",
        value: damage_dollars ?? null,
        category: "Damages",
      },
    ],
    [damage_dollars]
  )
  const { variables } = useCaseVariables(damagesVariables)

  const replacements = useMemo(
    () =>
      Object.fromEntries(
        variables.map(variable => {
          const name = `[[${variable.name}]]`
          return [name, variable.value ?? name]
        })
      ),
    [variables]
  )

  const templateTextData = useMemo(() => {
    if (!template.text && !template.text_json) {
      return null
    }

    return replaceMatches(template.text_json || deserializeFromMarkdown(template.text), replacements)
  }, [template, replacements])

  const detailsData = useMemo(() => {
    return replaceMatches(detailsJson || deserializeFromMarkdown(details || ""), replacements)
  }, [detailsJson, details, replacements])

  return (
    <form noValidate onBlur={handleOnBlur}>
      <Typography variant="h5">{template.title}</Typography>
      <Divider />
      <Box className={classes.innerSectionBox}>
        <Box className={classes.fieldBox}>
          <CurrencyField
            name="damage_amount"
            control={control}
            label="Amount"
            className={classes.formFields}
          />
          <CaseEditorField
            control={control}
            name="details_json"
            markdownName="details"
            label="Details"
            variables={variables}
          />
        </Box>
        <Box className={classes.renderedSection}>
          <Typography variant="h5">Preview</Typography>
          <Typography variant="body1" component="div">
            {templateTextData && <CaseEditor value={templateTextData} variables={variables} readonly />}
            {detailsData && <CaseEditor value={detailsData} variables={variables} readonly={true} />}
          </Typography>
        </Box>
      </Box>
    </form>
  )
}

export function DamagesSections({ lastVisited }) {
  const classes = useStyles()
  const [sections, setSections] = useState([])
  const { caseId, handleUpdateStepStatus } = useFormContext()

  useQuery([queryKeys.damagesSections, caseId], fetchDamagesSectionsByCase, {
    onSuccess: async data => {
      if (data?.results?.length) {
        handleUpdateStepStatus({ status: STEP_STATUSES.completed })
      } else {
        handleUpdateStepStatus({ status: STEP_STATUSES.started })
      }
      setSections(data.results)
    },
  })

  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.damages_sections
  })

  return (
    <Box>
      {sections?.length > 0 ? (
        <Box>
          {sections.map(section => (
            <Box key={section.pk} className={classes.sectionBox}>
              <DamagesSection caseId={caseId} section={section?.filled} template={section} />
            </Box>
          ))}
        </Box>
      ) : (
        <Alert
          className={classes.notice}
          severity="warning"
          action={
            <Button component={Link} to="/settings" color="inherit" size="small">
              Settings
            </Button>
          }
        >
          <AlertTitle>
            <strong>Custom Damages</strong> section not defined.
          </AlertTitle>
          Please go to the <strong>settings</strong> for the selected firm in the previous step to enable this
          section if needed.
        </Alert>
      )}
    </Box>
  )
}
