import React from "react"
import { CurrencyField } from "common/form-components"

import { StyledGridRow } from "../styled"

export function AnnualSalaryForm({ control }) {
  return (
    <StyledGridRow>
      <CurrencyField
        name="annual_salary"
        control={control}
        label="Annual salary"
        placeholder="Plaintiff's annual salary"
      />
      <CurrencyField
        name="supplemental_income"
        control={control}
        label="Supplemental income"
        placeholder="Any supplemental income?"
      />
    </StyledGridRow>
  )
}
