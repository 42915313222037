import React, { forwardRef, useReducer, useState } from "react"
import { useTheme } from "@material-ui/core/styles"
import { Box, Divider, IconButton, TextField } from "@material-ui/core"
import { BoldText, EditBoxTitle, EditPaper, RangesWrapper, Text } from "./styled"
import TextButton from "common/buttons/TextButton"
import { AnnotatedExhibit, CaseId, FieldValidationErrors, FormRange, PartitionProvider, Split } from "./types"
import { Cancel } from "@material-ui/icons"
import { v4 } from "uuid"
import { useMutation, useQueryClient } from "react-query"
import { setPartitions } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { queryKeys } from "react-query/constants"
import { Action, splitReducer } from "./reducers/splitReducer"
import { updateAnnotatedExhibits } from "./cacheUtils"
import { validateRanges } from "./validation/splitsValidation"
import { anyErrors } from "./validation/validationUtils"

interface SplitBoxProps {
  onCancel: () => unknown
  onSuccessfulSave: () => unknown
  splits: Split[]
  exhibitId: number
  caseId: CaseId
  numberOfPages: number
}

const splitsToRanges = (splits: Split[]): FormRange[] =>
  splits.map(split => ({ formId: v4(), start: split.start_page, end: split.end_page }))

interface UneditableSplitProps {
  start: number
  end: number
}

const UneditableSplit: React.FC<UneditableSplitProps> = ({ start, end }) => (
  <Box>
    <BoldText>
      From page{" "}
      <Box component="span" ml={1}>
        {start}
      </Box>{" "}
      to <Box component="span">{end}</Box>
    </BoldText>
  </Box>
)

interface EditableSplitProps {
  validationErrors: FieldValidationErrors
  formId: string
  start: number
  end: number
  dispatch: (action: Action) => void
  isLoading: boolean
}

const EditableSplit: React.FC<EditableSplitProps> = ({
  validationErrors,
  formId,
  start,
  dispatch,
  end,
  isLoading,
}) => {
  const theme = useTheme()
  return (
    <Box
      display="flex"
      alignItems="center"
      color={validationErrors[formId] ? theme.palette.error.main : undefined}
    >
      <BoldText>From page</BoldText>
      <Box maxWidth="80px" ml={1} mr={1}>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          value={Number.isNaN(start) ? "" : start}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch({ type: "editRange", formId, start: event.target.valueAsNumber })
          }
          disabled={isLoading}
          error={!!validationErrors[formId]?.some(validationErr => validationErr.field === "start")}
        />
      </Box>
      <BoldText>to {end}</BoldText>
    </Box>
  )
}

export default forwardRef<HTMLElement, SplitBoxProps>(function SplitBox(
  { onCancel, splits, exhibitId, caseId, onSuccessfulSave, numberOfPages },
  ref
): React.ReactElement {
  const [state, dispatch] = useReducer(splitReducer, { ranges: splitsToRanges(splits) })
  const { showMessage } = useHandleMessages()
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: setPartitions,
    onSuccess: (data: Split[]) => {
      queryClient.setQueryData<AnnotatedExhibit[]>([queryKeys.annotated_exhibits, caseId], oldData => {
        return updateAnnotatedExhibits({ oldData, exhibitId, update: { splits: data } })
      })

      queryClient.setQueryData<PartitionProvider[]>([queryKeys.partition_providers, caseId], oldData => {
        if (!oldData) return []
        const newPartitionProviders: PartitionProvider[] = [...oldData]
        data.forEach(split => {
          if (split.provider && !newPartitionProviders.some(provider => provider.pk === split.provider?.pk)) {
            newPartitionProviders.push(split.provider)
          }
        })
        return newPartitionProviders
      })
      onSuccessfulSave()
    },
    onError: () => {
      showMessage({
        type: "error",
        message:
          "Could not create or update splits. Try again shortly and file an issue if the problem persists.",
      })
    },
  })

  const queryClient = useQueryClient()
  const [validationErrors, setValidationErrors] = useState<FieldValidationErrors>({})
  const theme = useTheme()

  const handleSave = () => {
    const errors = validateRanges({ ranges: state.ranges })

    setValidationErrors(errors)

    if (anyErrors(errors)) return

    mutateAsync({
      caseId,
      exhibitId,
      data: {
        partitions: state.ranges.map(({ start, end }) => ({
          start_page: start,
          end_page: end,
        })),
      },
    })
  }
  return (
    <EditPaper ref={ref}>
      <EditBoxTitle>{splits.length > 1 ? "Modify Splits" : "Create Splits"}</EditBoxTitle>
      <RangesWrapper mt={2}>
        {state.ranges.map(({ formId, start, end }, index) => (
          <Box key={formId}>
            <Box display="flex" alignItems="center">
              <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
                {index === 0 ? (
                  <UneditableSplit start={start} end={end} />
                ) : (
                  <>
                    <EditableSplit
                      validationErrors={validationErrors}
                      formId={formId}
                      start={start}
                      end={end}
                      dispatch={dispatch}
                      isLoading={isLoading}
                    />
                    <IconButton
                      onClick={() => dispatch({ type: "deleteRange", formId })}
                      disabled={isLoading}
                    >
                      <Cancel />
                    </IconButton>
                  </>
                )}
              </Box>
            </Box>
            {validationErrors[formId] && (
              <Box color={theme.palette.error.main} mt={1}>
                <Text>
                  {validationErrors[formId]?.map(validationErr => validationErr.message).join(", ")}
                </Text>
              </Box>
            )}
          </Box>
        ))}
      </RangesWrapper>
      <Box mt={2} mb={1}>
        <Divider />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <TextButton onClick={() => dispatch({ type: "addRange", numberOfPages })} disabled={isLoading}>
            + Add Split
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={onCancel} textColor="grey" disabled={isLoading}>
            Cancel
          </TextButton>
          <TextButton onClick={handleSave} disabled={isLoading}>
            Save
          </TextButton>
        </Box>
      </Box>
    </EditPaper>
  )
})
