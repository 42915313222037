import React, { useEffect, useRef, useState, useCallback } from "react"
import {
  Box,
  Button,
  Divider,
  FormLabel,
  Step,
  StepButton,
  Stepper,
  Tooltip,
  Typography,
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles"
import { HelpOutline } from "@material-ui/icons"

import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useMutation, useQueries, useQuery, useQueryClient } from "react-query"
import useUser from "hooks/useUser"
import { useHandleMessages } from "common/messages/useHandleMessages"

import { isEmpty, pickBy, size } from "lodash"
import clsx from "clsx"

import { cleanNulls, getCommonMutateOptions, stringListToArray } from "utils"
import {
  createRequest,
  deleteRequestFile,
  fetchRequest,
  fetchRequestFiles,
  getFirms,
  getIntakeAsset,
  updateRequest,
  uploadRequestFile,
} from "api"
import { queryKeys } from "react-query/constants"
import {
  ASSET_KEYS,
  ASSET_TYPES,
  DEMAND_TYPE_RADIO_OPTIONS,
  FAMILY_STATUS_KEYS,
  INITIAL_STATE_FULL,
  INITIAL_STATE_REQUIRED,
  INJURY_IMPACTS_KEYS,
  INTAKE_STATUSES,
} from "../constants"
import { getEditDisabledMessageByStatus } from "../utils"
import { canUserEditRequest } from "../permissions/requestAction"
import { INT4_MAX } from "app/constants"
import { Helmet } from "react-helmet"
import { StateSelect } from "common/StateSelect"

import { MEDICAL_FILE_OPTIONS } from "demand/constants"

import { RequestContextProvider, useRequestContext } from "../context"

import {
  CheckboxInput,
  CurrencyField,
  DateField,
  InputField,
  RadioButtons,
  SelectInput,
  SliderInput,
} from "common/form-components"
import DragDropFileUploader from "common/DragDropFileUploader"
import { RequestFilesList } from "../RequestFilesList"
import { SavingIndicator } from "../SavingIndicator"
import GenericError from "common/GenericError"
import { RequestView } from "../ViewRequest/Request"
import { PolicyAndDefendants } from "./PolicyAndDefendants"
import { ChunkFileUploader, handleChunkUploadResult } from "common/ChunkFileUploader"
import { ALL_ACCEPTED_FILE_TYPES } from "common/constants"
import useFirm from "hooks/useFirm"
import { PlaintiffsForm } from "./PlaintiffsForm"
import { STEPS_BY_DEMAND_TYPE } from "./constants"

const useStyles = makeStyles(theme => ({
  requestFormFullWidth: {
    margin: theme.spacing(2),
    padding: theme.spacing(2, 8),
    gap: theme.spacing(4),
  },
  requestFormWrapper: {
    marginTop: theme.spacing(2),
  },
  requestForm: {
    margin: theme.spacing(2),
    padding: theme.spacing(2, 8),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(4),
  },
  fullWidth: {
    gridColumn: "1 / 3",
  },
  actions: {
    display: "flex",
    margin: theme.spacing(2, 0),
    justifyContent: "space-between",
    alignItems: "center",
    gridColumn: "1 / 3",
  },
  submit: {
    marginLeft: theme.spacing(2),
  },
  checklistQuestion: {
    fontSize: "1rem",
    color: theme.palette.text.secondary,
  },
  injuryImpacts: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumn: "1 / 3",
    gridGap: theme.spacing(2),
  },
  injuryImpactsGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  injuryImpact: {
    display: "flex",
    flexDirection: "row",
    width: "60%",
    justifyContent: "space-between",
  },
  injuryImpactsLabel: {
    marginBottom: theme.spacing(1),
    display: "block",
    fontWeight: "500",
    fontSize: "0.875rem",
  },
  injurySlider: {
    minWidth: "9rem",
  },
  radio: {
    "& > label span": {
      fontSize: "0.75rem",
    },
  },
  householdImpairment: {
    gridColumn: "1 / 3",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: theme.spacing(4),
  },
  copyContainer: {
    gridColumn: "1 / 3",
  },
  copyMain: {
    marginTop: 0,
  },
  copySub: {
    marginTop: "1rem",
    fontStyle: "italic",
    fontSize: "0.75rem",
  },
}))

const FormNav = () => {
  const { getValues, activeStep, setActiveStep, requestId } = useRequestContext()

  const type = getValues().type

  const steps = STEPS_BY_DEMAND_TYPE[type]

  if (!steps) return null

  return (
    <Stepper nonLinear activeStep={activeStep} alternativeLabel data-test="request-steps">
      {steps.map((label, index) => (
        <Step key={label}>
          <StepButton onClick={() => setActiveStep(index)} disabled={index > 0 && !requestId}>
            {label}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  )
}

const ClientInfo = ({ isInternal, firmsOptions }) => {
  const classes = useStyles()
  const { control, errors, firm } = useRequestContext()

  const showDemandType = (firm && firm?.can_create_basic_plus_requests) || isInternal

  return (
    <>
      {showDemandType && (
        <RadioButtons
          control={control}
          name="type"
          options={DEMAND_TYPE_RADIO_OPTIONS}
          label="Demand Type"
          formControlClassName={classes.fullWidth}
          row
        />
      )}

      <Box className={classes.fullWidth}>
        <PlaintiffsForm />
      </Box>

      <InputField
        control={control}
        name="carrier_name"
        data-test="carrier-name"
        type="text"
        label="Carrier name"
        variant="outlined"
        errors={errors}
        rules={{
          required: "This is required",
          maxLength: { value: 100, message: "Ensure this field has no more than 100 characters." },
        }}
      />
      {isInternal && (
        <SelectInput
          name="firm_id"
          options={firmsOptions}
          label="Firm"
          control={control}
          errors={errors}
          rules={{ required: "Please select a firm" }}
        />
      )}
      <InputField
        control={control}
        name="additional_information"
        type="text"
        multiline
        rows={3}
        label="Additional Information"
        helperText="Share any additional instructions in completing this demand. Additional information may include (1) liability theories, and/or (2) desired claim amount. If you have documents accessible via link, please include here."
        errors={errors}
        variant="outlined"
      />
      <PolicyAndDefendants />
    </>
  )
}

const UploadDocuments = ({ handleDeleteFile, uploader }) => {
  const { getValues } = useRequestContext()

  // Only show files uploaded via the Request Form, not Missing Doc Uploads
  const uploadedFiles = getValues("files")?.filter(file => {
    return file?.missing_exhibit_event === null
  })

  return (
    <>
      {uploader}
      <RequestFilesList
        files={uploadedFiles}
        fileOptions={MEDICAL_FILE_OPTIONS}
        deleteFile={handleDeleteFile}
      />
    </>
  )
}

const CaseInfo = () => {
  const { control, errors, assets, setValue, watch } = useRequestContext()
  const classes = useStyles()

  const dateOfIncident = watch("date_of_incident")
  const handleChangeDateOfIncident = useCallback(
    newDateOfIncident => setValue("date_of_incident", newDateOfIncident, { shouldDirty: true }),
    [setValue]
  )
  return (
    <>
      <Tooltip placement="top" title="The entered name will appear as this demand's signing attorney.">
        <InputField
          control={control}
          name="assigned_attorney"
          type="text"
          label={
            <Box display="flex">
              <Box>Assigned Attorney</Box>
              <Box ml={1} mt={-0.5}>
                <HelpOutline />
              </Box>
            </Box>
          }
          errors={errors}
          variant="outlined"
        />
      </Tooltip>

      <SelectInput
        name="case_type"
        options={assets[ASSET_KEYS.caseTypes]}
        label="Case type"
        control={control}
        errors={errors}
      />
      <InputField
        control={control}
        name="adjuster_first_name"
        type="text"
        label="Recipient's first name"
        errors={errors}
        variant="outlined"
        rules={{ maxLength: { value: 50, message: "Ensure this field has no more than 50 characters." } }}
      />
      <InputField
        control={control}
        name="adjuster_last_name"
        type="text"
        label="Recipient's last name"
        errors={errors}
        variant="outlined"
        rules={{ maxLength: { value: 50, message: "Ensure this field has no more than 50 characters." } }}
      />

      <InputField
        control={control}
        name="adjuster_email"
        type="text"
        label="Recipient E-Mail"
        errors={errors}
        variant="outlined"
      />

      <Box></Box>

      <DateField
        initialValue={dateOfIncident}
        onChange={handleChangeDateOfIncident}
        label="Date of incident"
        fieldProps={{ name: "date_of_incident" }}
        error={errors?.date_of_incident}
        helperText={errors?.date_of_incident}
      />
      <InputField
        control={control}
        name="policy_number"
        type="text"
        label="Policy number"
        errors={errors}
        variant="outlined"
        rules={{ maxLength: { value: 50, message: "Ensure this field has no more than 50 characters." } }}
      />
      <InputField
        control={control}
        name="claim_number"
        type="text"
        label="Claim Number"
        errors={errors}
        variant="outlined"
      />
      <CurrencyField
        control={control}
        name="policy_limit"
        label="Policy limit"
        errors={errors}
        variant="outlined"
        rules={{ max: { value: INT4_MAX, message: `Policy Limit must be below ${INT4_MAX}` } }}
      />
      <Divider className={classes.fullWidth} />

      <InputField
        control={control}
        name="adjuster_address.street"
        type="text"
        label="Recipient Street"
        errors={errors}
        variant="outlined"
      />
      <InputField
        control={control}
        name="adjuster_address.street_2"
        type="text"
        label="Recipient Street Line 2"
        errors={errors}
        variant="outlined"
      />
      <InputField
        control={control}
        name="adjuster_address.city"
        type="text"
        label="Recipient City"
        errors={errors}
        variant="outlined"
      />
      <StateSelect control={control} selectName="adjuster_address.state" label="Recipient State" />
      <InputField
        control={control}
        name="adjuster_address.zip_code"
        type="text"
        label="Recipient Zip Code"
        errors={errors}
        variant="outlined"
      />

      <Divider className={classes.fullWidth} />

      <InputField
        className={classes.fullWidth}
        control={control}
        name="case_facts"
        type="text"
        multiline
        rows={3}
        label="What are the facts?"
        errors={errors}
        variant="outlined"
      />
      <InputField
        className={classes.fullWidth}
        control={control}
        name="ongoing_complaints"
        type="text"
        multiline
        rows={3}
        label="What are the victim’s ongoing complaints?"
        errors={errors}
        variant="outlined"
      />
      <InputField
        className={classes.fullWidth}
        control={control}
        name="future_treatments"
        type="text"
        multiline
        rows={3}
        label="What are the future treatments for the victim that you will be able to prove? "
        errors={errors}
        variant="outlined"
      />
    </>
  )
}

const InjuryImpactsGroup = ({ handleSlider, handleInjurySlider, handleInjuryCheckbox, injuryKey }) => {
  const { control, assets, getValues } = useRequestContext()
  const classes = useStyles()

  const isChecked = (name, key) => {
    const currentValues = getValues(name)
    if (!currentValues?.find) return false

    return !!currentValues.find(value => {
      return key === value?.key
    })
  }

  const getSliderValue = (name, key) => {
    const currentValues = getValues(name)
    if (!currentValues?.find) return 0

    return (
      currentValues.find(value => {
        return key === value?.key
      })?.impact_percentage || 0
    )
  }

  const otherSliderValue = getValues(`${INJURY_IMPACTS_KEYS[injuryKey].key}_other`)?.impact_percentage || 0

  return (
    <Box className={clsx(injuryKey === "other" && classes.fullWidth)}>
      <FormLabel className={classes.injuryImpactsLabel}>{INJURY_IMPACTS_KEYS[injuryKey]?.display}</FormLabel>
      <Box className={classes.injuryImpactGroup}>
        {assets[ASSET_KEYS[injuryKey]]?.map(({ key, display }, index) => {
          const sliderName = `${INJURY_IMPACTS_KEYS[injuryKey].key}.impact_percentage.${key}`
          const isImpactChecked = isChecked(INJURY_IMPACTS_KEYS[injuryKey]?.key, key)
          const sliderValue = getSliderValue(INJURY_IMPACTS_KEYS[injuryKey]?.key, key)

          return (
            <Box className={classes.injuryImpact} key={`${injuryKey}-${key}-${index}`}>
              <CheckboxInput
                control={control}
                name={`${INJURY_IMPACTS_KEYS[injuryKey].key}`}
                value={key}
                label={display}
                onChange={handleInjuryCheckbox}
                checked={isImpactChecked}
              />
              {isImpactChecked && (
                <SliderInput
                  className={classes.injurySlider}
                  label="Impact %"
                  control={control}
                  name={sliderName}
                  min={0}
                  max={100}
                  valueLabelDisplay="auto"
                  step={10}
                  defaultValue={sliderValue}
                  onChange={handleInjurySlider}
                />
              )}
            </Box>
          )
        })}
        <Box className={classes.injuryImpact}>
          <InputField
            control={control}
            name={`${INJURY_IMPACTS_KEYS[injuryKey]?.key}_other.key`}
            type="text"
            label="Other"
            placeholder="Please list out the injury impact"
          />
          {getValues(`${INJURY_IMPACTS_KEYS[injuryKey]?.key}_other.key`) && (
            <SliderInput
              className={classes.injurySlider}
              label="Impact %"
              control={control}
              name={`${INJURY_IMPACTS_KEYS[injuryKey]?.key}_other.impact_percentage`}
              min={0}
              max={100}
              valueLabelDisplay="auto"
              step={10}
              defaultValue={otherSliderValue}
              onChange={(_, newValue) => {
                const otherValue = getValues(`${INJURY_IMPACTS_KEYS[injuryKey]?.key}_other`)
                otherValue.impact_percentage = newValue
                handleSlider(`${INJURY_IMPACTS_KEYS[injuryKey]?.key}_other`, otherValue)
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

const AdditionalInfo = ({ handleSlider, handleCheckbox, handleInjuryCheckbox, handleInjurySlider }) => {
  const { control, assets, getValues, errors, watch, setValue } = useRequestContext()
  const classes = useStyles()

  const isChecked = (name, key) => {
    const currentValues = getValues(name)
    const parsedValues = stringListToArray(currentValues)

    return parsedValues.includes(key)
  }

  const startOfImpairment = watch("household_start_of_impairment")
  const handleChangeStartOfImpairment = useCallback(
    newStartOfImpairment =>
      setValue("household_start_of_impairment", newStartOfImpairment, { shouldDirty: true }),
    [setValue]
  )

  const endOfImpairment = watch("household_end_of_impairment")
  const handleChangeEndOfImpairment = useCallback(
    newEndOfImpairment => setValue("household_end_of_impairment", newEndOfImpairment, { shouldDirty: true }),
    [setValue]
  )

  return (
    <>
      <Typography variant="body2" className={clsx(classes.checklistQuestion, classes.fullWidth)}>
        How did the injury impact the victim&apos;s life? (Select all that apply)
      </Typography>
      <Box className={classes.injuryImpacts}>
        {Object.keys(INJURY_IMPACTS_KEYS).map((injuryKey, index) => {
          return (
            <InjuryImpactsGroup
              handleSlider={handleSlider}
              handleInjurySlider={handleInjurySlider}
              handleInjuryCheckbox={handleInjuryCheckbox}
              key={`${injuryKey}-${index}`}
              injuryKey={injuryKey}
            />
          )
        })}
      </Box>
      <Typography variant="body2" className={clsx(classes.checklistQuestion, classes.fullWidth)}>
        What is the victim&apos;s family status?
      </Typography>
      <Box className={classes.injuryImpacts}>
        {Object.keys(FAMILY_STATUS_KEYS).map(statusKey => (
          <Box key={statusKey} className={clsx(statusKey === "other" && classes.fullWidth)}>
            <FormLabel className={classes.injuryImpactsLabel}>
              {FAMILY_STATUS_KEYS[statusKey]?.display}
            </FormLabel>
            {statusKey === "familyStatus" ? (
              <RadioButtons
                className={classes.radio}
                control={control}
                name={FAMILY_STATUS_KEYS[statusKey]?.key}
                options={assets[ASSET_KEYS[statusKey]]}
              />
            ) : (
              <Box className={classes.injuryImpactsGroup}>
                {assets[ASSET_KEYS[statusKey]]?.map(({ key, display }, index) => (
                  <CheckboxInput
                    control={control}
                    key={`${key}-${index}`}
                    name={FAMILY_STATUS_KEYS[statusKey]?.key}
                    value={key}
                    label={display}
                    onChange={handleCheckbox}
                    checked={isChecked(FAMILY_STATUS_KEYS[statusKey]?.key, key)}
                  />
                ))}
                <InputField
                  name="dependent_status_other"
                  type="text"
                  control={control}
                  label="Other"
                  placeholder="Please specify your dependent status"
                />
              </Box>
            )}
          </Box>
        ))}
      </Box>

      <Typography variant="body2" className={clsx(classes.checklistQuestion, classes.fullWidth)}>
        How was the victim&apos;s contributions to household chores affected by the accident?
      </Typography>
      <Box className={classes.householdImpairment}>
        <DateField
          initialValue={startOfImpairment}
          onChange={handleChangeStartOfImpairment}
          label="Start of impairment date"
          fieldProps={{ name: "household_start_of_impairment" }}
          error={errors?.household_start_of_impairment}
          helperText={errors?.household_start_of_impairment}
        />
        <DateField
          initialValue={endOfImpairment}
          onChange={handleChangeEndOfImpairment}
          label="End of impairment date"
          fieldProps={{ name: "household_end_of_impairment" }}
          error={errors?.household_end_of_impairment}
          helperText={errors?.household_end_of_impairment}
        />
        <SliderInput
          label="Impairment % of household chores"
          control={control}
          name="household_percent_of_chores"
          min={0}
          max={100}
          valueLabelDisplay="auto"
          step={10}
          defaultValue={getValues("household_percent_of_chores") || 0}
          onChange={handleSlider}
        />
      </Box>
      <Box className={classes.fullWidth}>
        <Typography variant="body2" className={clsx(classes.checklistQuestion, classes.fullWidth)}>
          What is the victim&apos;s educational background?
        </Typography>
        <RadioButtons
          options={assets[ASSET_KEYS.educationLevel]}
          className={classes.radio}
          control={control}
          name="education_level"
        />
      </Box>
    </>
  )
}

const ReviewStep = ({ requestId }) => {
  const { data: request } = useQuery([queryKeys.request, requestId], fetchRequest)

  return <RequestView request={request} />
}

export function RequestForm() {
  const { id: requestId } = useParams()
  const { state } = useLocation()
  const [activeStep, setActiveStep] = useState(state?.activeStep || 0)
  const [canEdit, setCanEdit] = useState(true)
  const [error, setError] = useState(null)
  const requestFormWrapperRef = useRef()
  const chunkFileUploaderRef = useRef(null)

  const [filesToUpload, setFilesToUpload] = useState([])
  const [uploadingFiles, setUploadingFiles] = useState(false)
  const [uploadingFilesError, setUploadingFilesError] = useState(false)

  const navigate = useNavigate()
  const classes = useStyles()
  const { user } = useUser()
  const { firm } = useFirm(user?.firmId ?? 0)
  const { showMessage } = useHandleMessages()

  const queryClient = useQueryClient()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
    getValues,
    setValue,
    register,
    watch,
  } = useForm({
    defaultValues: requestId ? INITIAL_STATE_FULL : INITIAL_STATE_REQUIRED,
  })

  useQuery([queryKeys.request, requestId], fetchRequest, {
    enabled: !!requestId,
    onSuccess: data => {
      // backend sends null for values that are provided to select fields
      // this throws a warning in the select component
      // set null values to empty string
      const cleanedData = cleanNulls(data)
      reset(cleanedData)
      if (canEdit && !canUserEditRequest(user, data)) {
        setCanEdit(false)
      }
    },
    onError: responseError => {
      setError(responseError)
    },
  })

  const assetQueries = ASSET_TYPES.map(assetType => {
    return { queryKey: [queryKeys.intakeAsset, assetType], queryFn: getIntakeAsset }
  })

  const assetResults = useQueries(assetQueries)
  const assets = assetResults.reduce((assetMap, asset, index) => {
    if (asset.isSuccess) {
      const assetKey = ASSET_TYPES[index]
      assetMap[assetKey] = asset.data?.results
    }
    return assetMap
  }, {})

  const { data: firms } = useQuery([queryKeys.firms], getFirms, { enabled: user.isInternal })

  const firmsOptions = firms?.map(f => {
    return { key: f.pk, display: f.name }
  })

  const createMutation = useMutation(
    createRequest,
    getCommonMutateOptions({
      reset,
      setValue,
      onSuccessExtra: data => {
        const requestId = data?.pk
        requestId && navigate(`../${requestId}/edit`, { state: { activeStep: 1 }, replace: true })
      },
    })
  )
  const updateMutation = useMutation(
    data => {
      if (!canEdit) return
      updateRequest(data)
    },
    getCommonMutateOptions({
      reset,
      setValue,
      onSuccessExtra: data => {
        // we need this for the saving indicator
        const lastUpdated = data?.updated_at
        setValue("updated_at", lastUpdated)
        // update hidden status field
        const status = data?.intake_status
        setValue("intake_status", status)
      },
    })
  )

  const updateStatus = async () => {
    if (getValues("intake_status") !== INTAKE_STATUSES.notRequested) return

    return await updateMutation.mutateAsync({
      requestId,
      data: {
        intake_status: INTAKE_STATUSES.requested,
      },
    })
  }

  const handleFormValidationErrors = () => {
    showMessage({
      type: "error",
      message: "There were some problems with your input. Please double check your inputs and try again.",
    })
  }

  const handleFormSubmit = handleSubmit(async data => {
    if (filesToUpload.length > 0) {
      await uploadFiles()
    }
    await applyUpdate(data)

    if (activeStep === 4) {
      await updateStatus()
      navigate("../success")
    } else {
      setActiveStep(activeStep + 1)
    }
  }, handleFormValidationErrors)

  const applyUpdate = async data => {
    // TODO: T-4371 - Remove support for plaintiff_first_name & plaintiff_last_name
    data.plaintiff_first_name = data.plaintiffs[0].first_name
    data.plaintiff_last_name = data.plaintiffs[0].last_name
    if (!requestId) {
      data.intake_status = data?.intake_status ? data.intake_status : INTAKE_STATUSES.notRequested

      await createMutation.mutateAsync({ data })
    } else {
      const changedFields = pickBy(data, (_, key) => !!dirtyFields[key])
      return !!size(changedFields) && updateMutation.mutate({ requestId, data: changedFields })
    }
  }

  const handleBlur = handleSubmit(
    async data => {
      return applyUpdate(data)
    },
    errors => {
      // Mute blur errors for the plaintiff form
      if (Object.keys(errors).length === 1 && errors?.plaintiffs) {
        return
      }

      handleFormValidationErrors()
    }
  )

  const saveAndExit = async () => {
    if (filesToUpload.length > 0) {
      await uploadFiles()
    }
    await handleBlur()
    if (isEmpty(errors)) {
      navigate("../")
    }
  }

  const handleCheckbox = e => {
    const currentTarget = e.currentTarget
    const { name, checked, value } = currentTarget
    // note: the backend is sending back values as strings
    // so we need to convert them to arrays
    // eg: "['1', '2']" to ["1", "2"]

    const currentValues = getValues(name)
    const parsedValues = stringListToArray(currentValues)

    const newValues = checked ? [...parsedValues, value] : parsedValues.filter(v => v !== value)
    updateMutation.mutate({ requestId, data: { [name]: newValues } })
    // set the form value so we can validate if it's checked or not
    setValue(name, JSON.stringify(newValues))
  }

  const handleInjuryCheckbox = e => {
    const currentTarget = e.currentTarget
    const { name, checked, value } = currentTarget

    const currentValues = getValues(name)
    const newValues = (
      checked
        ? [...currentValues, { key: value, impact_percentage: 100 }]
        : currentValues.filter(v => v.key !== value)
    ).filter(v => v && v.key)

    updateMutation.mutate({ requestId, data: { [name]: newValues } })
    // set the form value so we can validate if it's checked or not
    setValue(name, newValues)
  }

  const handleSlider = (name, newValue) => {
    // update the value in the form
    setValue(name, newValue)
    const data = {
      [name]: newValue,
    }
    updateMutation.mutate({ requestId, data })
  }

  const handleInjurySlider = (name, newValue) => {
    const injuryImpactGroup = name.split(".")[0]
    const injuryImpactKey = name.split(".")[2]
    const currentValues = getValues(injuryImpactGroup)

    const index = currentValues.findIndex(value => {
      return value.key === injuryImpactKey
    })
    currentValues[index].impact_percentage = newValue

    setValue(injuryImpactGroup, currentValues)

    const data = {
      [injuryImpactGroup]: currentValues,
    }
    updateMutation.mutate({ requestId, data })
  }

  const deleteFileMutation = useMutation(deleteRequestFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.fetchRequestFiles)
    },
  })

  const saveFileMutation = useMutation(uploadRequestFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.fetchRequestFiles)
    },
    onError: () => {
      showMessage({
        type: "error",
        message: `There was an error uploading document(s). Please check the files are not empty or password protected.`,
      })
    },
  })

  const handleDeleteFile = file => {
    const pk = file.pk || file.id
    deleteFileMutation.mutate({ fileId: pk, requestId })
    chunkFileUploaderRef.current.removeRecentlyUploaded(pk)
  }

  useQuery([queryKeys.requestFiles, requestId], fetchRequestFiles, {
    enabled: !!requestId,
    onSuccess: data => {
      setValue("files", data)
    },
  })

  // scroll to the top of the form whenever step is changed
  useEffect(() => {
    if (requestFormWrapperRef.current) {
      requestFormWrapperRef.current.scrollIntoView()
    }
  }, [activeStep])

  const uploadFiles = async () => {
    setUploadingFiles(true)
    setUploadingFilesError(false)

    try {
      const uploadedFiles = await chunkFileUploaderRef.current.upload(filesToUpload.map(item => item.file))
      const results = await Promise.allSettled(
        filesToUpload.map(async f => {
          const { file, fileType } = f
          const formData = new FormData()
          handleChunkUploadResult(uploadedFiles, file, formData)
          formData.append("name", file.name)
          formData.append("type", fileType)

          const response = await saveFileMutation.mutateAsync({
            requestId: requestId,
            data: formData,
            name: file.name,
          })
          const savedFileResult = await response.json()
          const uploadedFile = uploadedFiles.find(item => item.file === f.file)
          if (uploadedFile) {
            chunkFileUploaderRef.current.assignUploaded(uploadedFile.id, savedFileResult.pk)
          }
        })
      )

      let newFilesToUpload = []
      for (let i in results) {
        if (results[i].status != "fulfilled") {
          newFilesToUpload.push(filesToUpload[i])
        }
      }
      setFilesToUpload(newFilesToUpload)
      const failedResults = results.filter(r => r.status == "rejected")
      if (failedResults.length) {
        throw failedResults[0]
      }
    } finally {
      setUploadingFiles(false)
    }
  }

  const getStepContent = step => {
    switch (step) {
      case 0:
        return <ClientInfo isInternal={user.isInternal} firmsOptions={firmsOptions} />
      case 1:
        return (
          <>
            <Alert severity="info" className={classes.copyContainer}>
              <AlertTitle>Please share the following records (if available):</AlertTitle>
              <p className={classes.copyMain}>
                Police reports, incident reports, complete medical records and bills, wage loss information
                (include salary and duration of loss), pictures of property damage or injuries, client
                questionnaires, and expert reports.
              </p>
              <p className={classes.copySub}>
                Additional credits may be required to (1) revise a completed demand due to missing records or
                (2) complete a demand with a large volume of records.
              </p>
            </Alert>
            <UploadDocuments
              handleDeleteFile={handleDeleteFile}
              uploader={
                <DragDropFileUploader
                  fileOptions={MEDICAL_FILE_OPTIONS}
                  acceptedTypes={ALL_ACCEPTED_FILE_TYPES}
                  defaultType="other"
                  files={filesToUpload}
                  onAdd={newFiles => setFilesToUpload([...filesToUpload, ...newFiles])}
                  onChange={newFiles => setFilesToUpload(newFiles)}
                  onSave={uploadFiles}
                  uploadError={uploadingFilesError}
                />
              }
            />
            <ChunkFileUploader ref={chunkFileUploaderRef} />
          </>
        )
      case 2:
        return <CaseInfo />
      case 3:
        return (
          <AdditionalInfo
            handleSlider={handleSlider}
            handleCheckbox={handleCheckbox}
            handleInjuryCheckbox={handleInjuryCheckbox}
            handleInjurySlider={handleInjurySlider}
          />
        )
      case 4:
        return <ReviewStep requestId={requestId} />
      default:
        return <ClientInfo isInternal={user.isInternal} firmsOptions={firmsOptions} />
    }
  }

  if (!canEdit) {
    return (
      <Box className={classes.requestFormWrapper}>
        <Alert severity="error">{getEditDisabledMessageByStatus(watch("intake_status"))}</Alert>
      </Box>
    )
  }

  if (error) {
    return (
      <Box className={classes.requestFormWrapper}>
        <GenericError />
      </Box>
    )
  }

  const plaintiffName =
    getValues("plaintiffs.0.first_name") && getValues("plaintiffs.0.last_name")
      ? `${getValues("plaintiffs.0.first_name")} ${getValues("plaintiffs.0.last_name")}`
      : ""

  return (
    <>
      <Helmet>
        <title>Request: {plaintiffName}</title>
      </Helmet>
      <Box ref={requestFormWrapperRef} className={classes.requestFormWrapper}>
        <RequestContextProvider
          value={{
            activeStep,
            setActiveStep,
            requestId,
            control,
            errors,
            getValues,
            assets,
            watch,
            setValue,
            firm,
          }}
        >
          <FormNav />
          <form
            onSubmit={handleFormSubmit}
            noValidate
            className={activeStep == 4 ? classes.requestFormFullWidth : classes.requestForm}
            onBlur={requestId ? handleBlur : null}
            onKeyDown={e => {
              if (e.code === "Enter") {
                e.preventDefault()
                e.currentTarget?.blur()
              }
            }}
          >
            {getStepContent(activeStep)}
            <Box className={classes.actions}>
              <input {...register("intake_status_last_modified")} style={{ display: "none" }} />
              <input {...register("intake_status")} style={{ display: "none" }} />
              <SavingIndicator />
              <Box>
                <Button disabled={uploadingFiles} onClick={saveAndExit} variant="outlined" color="secondary">
                  Save & Exit
                </Button>
                <Button
                  disabled={uploadingFiles}
                  className={classes.submit}
                  type="submit"
                  variant="contained"
                  color="primary"
                  data-test="submit-button"
                >
                  {activeStep === 4 ? "Finish & Submit" : "Next"}
                </Button>
              </Box>
            </Box>
          </form>
        </RequestContextProvider>
      </Box>
    </>
  )
}
