import React, { useState, useMemo, useEffect, useCallback } from "react"
import { TextField, CircularProgress, Typography } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import debounce from "lodash/debounce"
import { uniqWith } from "lodash"
import { getCptCodes } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"

export function CptCodesInput({ onChange, cptCodes, fullWidth = true, disabled }) {
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [options, setOptions] = useState([])
  const { showMessage } = useHandleMessages()

  useEffect(() => {
    if (inputValue === "") {
      setOptions([])

      return undefined
    }

    async function fetchOptions() {
      setLoading(true)

      const data = await getCptCodes({ prefix: inputValue })

      if (data) {
        const { cpt_codes } = data
        setOptions(cpt_codes)
      }

      setLoading(false)
    }

    fetchOptions()
  }, [inputValue])

  const changeHandler = useCallback((_, newValue) => setInputValue(newValue), [setInputValue])

  const handleInputChange = useMemo(() => debounce(changeHandler, 300), [changeHandler])

  const handleChange = useCallback(
    (_, newValue) => {
      const uniqueCodes = uniqWith(newValue, (a, b) => {
        const equal = a.code === b.code

        if (equal) {
          showMessage({ type: "error", message: "CPT code already in list." })
        }

        return equal
      })

      onChange(uniqueCodes)
    },
    [onChange, showMessage]
  )

  return (
    <Autocomplete
      id="cpt-codes-input"
      value={cptCodes}
      freeSolo
      disabled={disabled}
      fullWidth={fullWidth}
      multiple
      filterSelectedOptions
      autoComplete
      options={options}
      loading={loading}
      getOptionLabel={option => option.code}
      renderOption={option => (
        <>
          <Typography variant="caption" display="block">
            {option.code} {`(${option.description})`}
          </Typography>
        </>
      )}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={params => (
        <TextField
          {...params}
          label="Add CPT codes / Treatments"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
