import React, { useCallback, useEffect, useState, useMemo } from "react"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import { ProviderTemplateFormData as ProviderTemplateFormDataType } from "./types"
import { StyledEditorWrapper } from "./styled"
import { Variable } from "common/types/variables"
import { TextField, Box, Tooltip } from "@material-ui/core"
import { Error as ErrorIcon } from "@material-ui/icons"
import { Autocomplete } from "@material-ui/lab"
import styled from "@emotion/styled"

interface ProviderTemplateFormProps {
  onChange: (data: ProviderTemplateFormDataType) => void
  initialContent?: EditorRoot
  initialTemplateName?: string
  initialGeneratorKey?: string
  variables?: Variable[]
  generatorKeys?: Array<{ key: string; display: string }>
  error?: boolean
  highlightEmptyFields?: boolean
}

const INITIAL_PROVIDER_NAME = ""
const INITIAL_GENERATOR_KEY = ""
const INITIAL_CONTENT: EditorRoot = []
const INITIAL_VARIABLES: Variable[] = []

const StyledBox = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "2fr 1fr",
  gap: theme.spacing(1),
}))

const AutocompleteWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  position: "relative",
}))

const FloatingTooltip = styled(Tooltip)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  color: theme.palette.error.main,
}))

export function ProviderTemplateFormData({
  onChange,
  highlightEmptyFields,
  initialContent,
  initialTemplateName,
  initialGeneratorKey,
  variables,
  generatorKeys = [],
}: ProviderTemplateFormProps): JSX.Element {
  const [templateName, setTemplateName] = useState(initialTemplateName || INITIAL_PROVIDER_NAME)
  const [generatorKey, setGeneratorKey] = useState(initialGeneratorKey || INITIAL_GENERATOR_KEY)
  const [content, setContent] = useState(initialContent || INITIAL_CONTENT)

  const handleChangeTemplateName = useCallback(e => {
    setTemplateName(e.target.value)
  }, [])

  useEffect(() => {
    onChange({ templateName, content, generatorKey })
  }, [onChange, templateName, content, generatorKey])

  const currentValue = useMemo(() => {
    return generatorKeys?.find(option => option.key === generatorKey)
  }, [generatorKeys, generatorKey])

  return (
    <>
      <StyledBox>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          value={templateName}
          error={highlightEmptyFields && !templateName}
          onChange={handleChangeTemplateName}
          label={"Provider Template name"}
        />
        <AutocompleteWrapper>
          <Autocomplete
            fullWidth
            options={generatorKeys}
            getOptionLabel={option => option.display}
            value={currentValue}
            onChange={(_, newValue) => newValue && setGeneratorKey(newValue.key)}
            disabled={!generatorKeys?.length}
            renderInput={params => (
              <TextField {...params} variant="outlined" margin="dense" label="TextGen generator key" />
            )}
          />
          {!generatorKeys?.length && (
            <FloatingTooltip title={"Couldn't get results from API endpoint. Please refresh and try again."}>
              <ErrorIcon />
            </FloatingTooltip>
          )}
        </AutocompleteWrapper>
      </StyledBox>
      <StyledEditorWrapper>
        <RichTextEditor
          value={content}
          onChange={setContent}
          keepValue={false}
          withVariables
          variables={variables || INITIAL_VARIABLES}
        />
      </StyledEditorWrapper>
    </>
  )
}
