import React, { createContext, useContext } from "react"
import { useQueryClient } from "react-query"
import { queryKeys } from "../react-query/constants"
import { loginUser, logoutUser, loginStytchUser } from "../api"
import { LS_LOGGED_IN_KEY } from "common/constants"
import { elasticApm } from "infrastructure/apm"
import { AuthorizedUser } from "common/models/user"

const AuthContext = createContext()

export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

// hook for child components to get the auth object
export const useAuth = () => {
  return useContext(AuthContext)
}

// Provider hook that creates auth object
function useProvideAuth() {
  const queryClient = useQueryClient()

  const login = async data => {
    const userResponse = await loginUser(data)
    queryClient.setQueryData(queryKeys.session, userResponse)
    localStorage.setItem(LS_LOGGED_IN_KEY, true)

    const user = AuthorizedUser.fromJSON(userResponse?.data?.user)

    elasticApm.setUserContext(user)
  }

  const loginWithStytch = async ({ token, oauth }) => {
    const userResponse = await loginStytchUser({ token, oauth })
    queryClient.setQueryData(queryKeys.session, userResponse)
    localStorage.setItem(LS_LOGGED_IN_KEY, true)

    const user = AuthorizedUser.fromJSON(userResponse?.data?.user)

    elasticApm.setUserContext(user)
  }

  const logout = async () => {
    try {
      await logoutUser()

      queryClient.setQueryData(queryKeys.session, null)
      localStorage.removeItem(LS_LOGGED_IN_KEY)

      elasticApm.resetUserContext()
    } catch (err) {
      return false
    }
  }

  return {
    login,
    logout,
    loginWithStytch,
  }
}
