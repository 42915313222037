export enum LIBRARY_TABS {
  TEMPLATES = "templates",
  VARIABLES = "variables",
  PROVIDERS = "providers",
}

export const DEFAULT_LIBRARY_TAB = LIBRARY_TABS.TEMPLATES

export const LIBRARY_TAB_LABELS: Record<LIBRARY_TABS, string> = {
  [LIBRARY_TABS.PROVIDERS]: "Provider Templates",
  [LIBRARY_TABS.TEMPLATES]: "Section Templates",
  [LIBRARY_TABS.VARIABLES]: "Variables",
}
