import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Exhibit } from "./Exhibit"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  itemTitle: {
    padding: theme.spacing(1),
  },
  strong: {
    padding: theme.spacing(1),
    color: theme.palette.secondary.dark,
  },
}))

interface ExhibitsListItemProps {
  item: Exhibit
}

export const ExhibitsListItem: React.FC<ExhibitsListItemProps> = ({ item }) => {
  const classes = useStyles()
  const title = `${item.name} ${
    item?.provider_name ? ` | Provider:${item.provider_name} | First Contact: ${item?.first_contact}` : ""
  }`

  return (
    <>
      <Typography className={classes.itemTitle}>{title}</Typography>
    </>
  )
}
