import React from "react"
import { Box, Typography } from "@material-ui/core"
import { Copyright } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import {
  APP_FOOTER_ID,
  FOOTER_HEIGHT_MULTIPLIER,
  FOOTER_MARGIN_BOTTOM_MULTIPLIER,
  FOOTER_MARGIN_TOP_MULTIPLIER,
} from "./constants"

const useStyles = makeStyles(theme => ({
  footer: {
    height: theme.spacing(FOOTER_HEIGHT_MULTIPLIER),
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(FOOTER_MARGIN_TOP_MULTIPLIER),
    marginBottom: theme.spacing(FOOTER_MARGIN_BOTTOM_MULTIPLIER),
    "& > *": {
      margin: theme.spacing(1),
    },
    "& > p": {
      fontSize: "0.85rem",
    },
    "& > svg": {
      fontSize: "1.25rem",
    },
  },
}))

export default function Footer() {
  const classes = useStyles()
  const date = new Date()
  const year = date.getFullYear()
  return (
    <Box className={classes.footer} data-test="page-footer" id={APP_FOOTER_ID}>
      <Copyright />
      <Typography>EvenUp - {year}</Typography>
    </Box>
  )
}
