import React, { createContext, JSXElementConstructor, ReactElement } from "react"
import {
  Box,
  Collapse,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core"
import { styled } from "@material-ui/core/styles"

export type Alignment = "left" | "right" | "center"

export interface Column {
  id: string
  title: string
  sortable?: boolean
  align?: Alignment
  cellComponent?: (record: any) => ReactElement | string | number
}

const EmptyStateContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  fontWeight: 300,
  fontStyle: "italic",
  fontSize: "14px",
  letterSpacing: "-0.28px",
}))

const FullWidthCollapse = styled(Collapse)({
  width: "100%",
  columnSpan: "all",
})

// note: need to change boolean to number since styled components throws warning
// see: https://maximeblanc.fr/blog/how-to-fix-the-received-true-for-a-non-boolean-attribute-error/
// proper fix: https://styled-components.com/docs/faqs#why-am-i-getting-html-attribute-warnings
const HideWhenEmptyRow = styled(TableRow)(({ hide }: { hide: number }) => ({
  transition: "display 2s",
  padding: 0,
  display: hide ? "hidden" : "table-row",
}))

const ExpandedCell = styled(TableCell)({
  padding: 0,
})

interface Props {
  columns: Column[]
  records: any[]
  sortDirection?: "asc" | "desc"
  sortField?: string
  onSortChange?: () => void
  summary?: Column[] | null
  emptyMessage?: string | React.ReactElement
  expandElement?: Nullable<ReactElement<any, string | JSXElementConstructor<any>>>
  expandedRows?: any[]
}

const TableContext = createContext({})

interface ExpandedElementProps {
  element: ReactElement<any, string | JSXElementConstructor<any>>
  record: any
}
const ExpandedElement = ({ element, record }: ExpandedElementProps) => {
  const ExpandedElementClone = element ? React.cloneElement(element, { record }) : null

  return ExpandedElementClone
}

const Table = ({
  columns,
  records,
  sortDirection,
  sortField,
  onSortChange,
  summary = null,
  emptyMessage = "No Data",
  expandElement = null,
  expandedRows = [],
}: Props): JSX.Element => {
  return (
    <TableContext.Provider value={{ columns, records }}>
      <MuiTable data-test={`table`}>
        <TableHead>
          <TableRow>
            {columns.map((column: Column, index: number) => {
              const align = column?.align || "left"
              return column?.sortable === true ? (
                <TableCell key={index} align={align}>
                  <TableSortLabel
                    active={sortField === column.id}
                    direction={sortDirection}
                    onClick={onSortChange}
                  >
                    {column.title}
                  </TableSortLabel>
                </TableCell>
              ) : (
                <TableCell key={index} align={align}>
                  {column.title}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        {records?.length ? (
          <TableBody>
            {records.map((record: any, rowIndex: number) => {
              return (
                <React.Fragment key={rowIndex}>
                  <TableRow hover>
                    {columns.map((column: Column, cellIndex: number) => {
                      return (
                        <TableCell
                          key={cellIndex}
                          align={column?.align || "left"}
                          data-test={`column-id-${column.id}`}
                        >
                          {column.cellComponent ? column.cellComponent(record) : <Box>{"N/A"}</Box>}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  {/* TOOD: Right now we are using record.pk, probably don't want that long term */}
                  {!!expandElement && (
                    <HideWhenEmptyRow hide={+!expandedRows.includes(record.pk)}>
                      <ExpandedCell colSpan={columns.length}>
                        <FullWidthCollapse in={expandedRows.includes(record.pk)}>
                          <ExpandedElement element={expandElement} record={record} />
                        </FullWidthCollapse>
                      </ExpandedCell>
                    </HideWhenEmptyRow>
                  )}
                </React.Fragment>
              )
            })}
            {summary && (
              <TableRow>
                {summary.map((column: Column, index: number) => {
                  const align = column?.align || "left"
                  return (
                    <TableCell key={index} align={align}>
                      <b>{column.cellComponent ? column.cellComponent(records) : <Box>{"N/A"}</Box>}</b>
                    </TableCell>
                  )
                })}
              </TableRow>
            )}
          </TableBody>
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <EmptyStateContainer>{emptyMessage}</EmptyStateContainer>
            </TableCell>
          </TableRow>
        )}
      </MuiTable>
    </TableContext.Provider>
  )
}

export { Table as default }
