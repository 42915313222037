import { BaseServiceDeserializer } from "api/BaseDeserializer"
import { BaseServiceSerializer } from "api/BaseSerializer"
import { handleEmptyResponse } from "api/utils"
import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"
import { RawModel } from "common/helpers/object"
import { UploadedFileDto } from "common/types/UploadedFile"
import { isUndefined } from "lodash"
import { DEMAND_PACKAGE_FILE_TYPES } from "requests/constants"
import { apiService } from "./ApiService"
import { ApiServiceType } from "./types"

interface RequestServiceOptions {
  requestId: string | PrimaryKey
}

type RequestServiceOptionsArgs<T = null> = T extends null
  ? RequestServiceOptions
  : RequestServiceOptions & {
      data: T
    }

enum REQUEST_API_PATHS {
  BASE = "intake",
  DEMAND_PACKAGE = "demand_package_file",
}

type DemandPackageFileTypeOptions = keyof typeof DEMAND_PACKAGE_FILE_TYPES

type DemandPackageFileDto = UploadedFileDto<DemandPackageFileTypeOptions>

interface DemandPackageFilePayload {
  upload_id: string
  name: string
  type: DemandPackageFileTypeOptions
}

class RequestServiceSerializer {
  static uploadToJSON(data: RawModel<DemandPackageFilePayload>): DemandPackageFilePayload {
    return BaseServiceSerializer.toJSON(data)
  }
}

class RequestServiceDeserializer {
  static uploadFromJSON(data: DemandPackageFileDto): RawModel<DemandPackageFileDto> {
    return BaseServiceDeserializer.fromJSON(data)
  }
}

class RequestService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(options: RequestServiceOptions, path?: REQUEST_API_PATHS): string {
    const pathParts = ["", REQUEST_API_PATHS.BASE, options.requestId, path]
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  uploadDemandPackageFile = withRequestSerializer(
    RequestServiceSerializer.uploadToJSON,
    withResponseSerializer(
      RequestServiceDeserializer.uploadFromJSON,
      ({ data, ...options }: RequestServiceOptionsArgs<DemandPackageFilePayload>) => {
        const path = this.getPath(options, REQUEST_API_PATHS.DEMAND_PACKAGE)
        return handleEmptyResponse(this.apiService.create(data, path))
      }
    )
  )
}

export const requestService = new RequestService(apiService)
