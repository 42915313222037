import React from "react"
import { Box } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"

import ActionButton, { ActionButtonContainer } from "./ActionButton"
import { Status } from "./styled"
import { AttachedFileInterface, StatusProperties } from "./interfaces"
import { buildPropertiesObject } from "./utils"
import { formatDate } from "../utils"

interface Props extends AttachedFileInterface {
  showIcon?: boolean
  allowDownload?: boolean
  onApprovedClick?: Nullable<() => void>
}

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(2, 1, 2),
  "& > :not(:last-child)": {
    margin: theme.spacing("auto", 1, "auto"),
  },
}))

const DetailsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: theme.spacing("auto", 0),
  lineHeight: "1.1rem",
  maxWidth: "60%",
}))

const Name = styled(Box)({
  fontWeight: 700,
  fontSize: "1rem",
})

const DateCreated = styled(Box)({
  fontWeight: 300,
  fontSize: "1rem",
})

const getStatusProperties = (approved: boolean): StatusProperties => {
  return approved
    ? buildPropertiesObject("white", "#3cd5ad", "Approved")
    : buildPropertiesObject("white", "#000000", "Uploaded")
}

const AttachedFile = ({
  name,
  file_url,
  date_created,
  accepted,
  allowDownload = false,
  onApprovedClick = null,
}: Props): JSX.Element => {
  const statusProperties = getStatusProperties(accepted)
  const showApproveAction = onApprovedClick && !accepted

  return (
    <Container data-test="attached-file">
      <Status
        backgroundColor={statusProperties.backgroundColor}
        fontColor={statusProperties.fontColor}
        label={statusProperties.display}
      />
      <DetailsContainer>
        <Name>{name}</Name>
        <DateCreated>{formatDate(date_created)}</DateCreated>
      </DetailsContainer>
      <ActionButtonContainer>
        {showApproveAction ? (
          <ActionButton onClick={onApprovedClick} data-test="approve-document-button">
            Approve Document
          </ActionButton>
        ) : null}
        {allowDownload ? (
          <ActionButton>
            <a download href={file_url} style={{ color: "inherit", textDecoration: "inherit" }}>
              Download
            </a>
          </ActionButton>
        ) : null}
      </ActionButtonContainer>
    </Container>
  )
}

export { AttachedFile as default }
