import React, { useMemo } from "react"
import {
  Box,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  FormHelperText,
} from "@material-ui/core"
import { Cancel } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import { v4 } from "uuid"
import { MEDICAL_FILE_OPTIONS, MAX_FILE_NAME_LENGTH } from "../../../demand/constants"
import splitFileName from "./splitFileName"

const useStyles = makeStyles(theme => ({
  fileNameWrapper: {
    display: "flex",
    alignItems: "baseline",
    width: "100%",
    gap: theme.spacing(2),
  },
  selectFormControl: {
    alignSelf: "flex-start",
  },
}))

const FileToUpload = ({
  originalFileName,
  fileName,
  onFileNameChange,
  fileNameError,
  fileType,
  onFileTypeChange,
  fileTypeError,
  button,
  onCancel,
  disabled,
}) => {
  const classes = useStyles()
  // pass [] since we only need to get a unique id once
  const id = useMemo(() => v4(), [])
  const [name, extension] = splitFileName(fileName ?? "")

  const handleFileNameChange = event => {
    onFileNameChange(event.target.value + extension)
  }

  const handleFileTypeChange = event => {
    onFileTypeChange(event.target.value)
  }

  return (
    <Box display="flex" pr={2.2}>
      <Box className={classes.fileNameWrapper}>
        <TextField
          label="File name"
          value={name}
          variant="outlined"
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{extension}</InputAdornment>,
          }}
          onChange={handleFileNameChange}
          disabled={disabled}
          // slightly less than the max to account for file ext getting added automatically
          inputProps={{ maxLength: MAX_FILE_NAME_LENGTH - extension.length }}
          error={!!fileNameError}
          helperText={fileNameError ? fileNameError : `Original: ${originalFileName}`}
        />
        <FormControl
          className={classes.selectFormControl}
          fullWidth
          variant="outlined"
          disabled={disabled}
          error={!!fileTypeError}
        >
          <InputLabel id={`file-type-label-${id}`}>File type</InputLabel>
          <Select
            data-test="file-type-select"
            value={fileType ?? ""}
            labelId={`file-type-label-${id}`}
            id={`file-type-${id}`}
            label="File type"
            onChange={handleFileTypeChange}
            aria-describedby={fileTypeError ? `file-type-helper-text-${id}` : undefined}
          >
            <MenuItem value="" disabled>
              Please Select
            </MenuItem>
            {Object.entries(MEDICAL_FILE_OPTIONS).map(([key, label]) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </Select>
          {fileTypeError && <FormHelperText>{fileTypeError}</FormHelperText>}
        </FormControl>
        {!!button && <Box>{button}</Box>}
        <Box>
          <IconButton onClick={onCancel} disabled={disabled}>
            <Cancel />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

export default FileToUpload
