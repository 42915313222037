import React from "react"
import { Box, Tabs, Tab } from "@material-ui/core"
import { useLocation, Outlet, Link } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { isNotOSF } from "../common/permission"
import useUser from "../hooks/useUser"
import { useFeatures } from "hooks/useFeatures"
import { DEMAND_FLOW_WRAPPER, LEFT_NAV_PANEL_ORDER } from "./constants"

const useDemandFlowNavStyles = makeStyles(theme => ({
  demandTabs: {
    margin: theme.spacing(0, 3),
  },
}))

function DemandFlowNav() {
  const location = useLocation()
  const { user } = useUser()
  const { isFeatureEnabled } = useFeatures()
  const classes = useDemandFlowNavStyles()

  const tabs = [
    { label: "Case information", toLink: "form" },
    { label: "Case search", toLink: "search", dataTest: "case-search" },
    { label: "Relevant cases", toLink: "relevant", dataTest: "relevant-cases" },
    { label: "Favorite cases", toLink: "favorite", dataTest: "favorite-cases" },
  ]

  if (isFeatureEnabled("annotation")) {
    tabs.unshift({ label: "Intake Files", toLink: "files", dataTest: "files-tab" })
  }

  if (isNotOSF(user.role)) {
    tabs.push({ label: "Review & generate demand", toLink: "review" })
  }

  const currentIndex = tabs.findIndex(tab => location.pathname.includes(tab.toLink))

  return (
    <Tabs
      className={classes.demandTabs}
      variant="fullWidth"
      value={currentIndex === -1 ? 0 : currentIndex}
      aria-label="demand form nav"
    >
      {tabs.map(({ label, toLink, dataTest }) => (
        <Tab component={Link} label={label} to={`${toLink}`} key={toLink} data-test={dataTest} />
      ))}
    </Tabs>
  )
}

const useStyles = makeStyles(theme => ({
  demandFlowWrapper: {
    display: "flex",
  },
  demandFlowContainer: {
    marginTop: theme.spacing(2),
    flexGrow: 1,
    order: LEFT_NAV_PANEL_ORDER + 1,
  },
}))

export function DemandFlow() {
  const classes = useStyles()

  return (
    <Box className={classes.demandFlowWrapper} id={DEMAND_FLOW_WRAPPER}>
      <Box className={classes.demandFlowContainer}>
        <Box mb={4}>
          <DemandFlowNav />
        </Box>
        <Outlet />
      </Box>
    </Box>
  )
}
