import React from "react"
import { useQuery } from "react-query"
import { makeStyles } from "@material-ui/core/styles"
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Tooltip } from "@material-ui/core"

import { getUsStates } from "../api"
import { queryKeys } from "../react-query/constants"
import { Controller } from "react-hook-form"

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export function StateSelect({
  control,
  selectName,
  errors,
  helperText,
  disabled = false,
  tooltip = null,
  label = "State",
}) {
  const classes = useStyles()
  const { isLoading, data: states } = useQuery([queryKeys.usStates], getUsStates)

  return (
    <Controller
      name={selectName || "state"}
      control={control}
      render={({ field: { onChange, value, name } }) => {
        const StateSelector = (
          <FormControl variant="outlined" className={classes.formControl} error={errors}>
            <InputLabel id="state-select-label" variant="outlined">
              {label}
            </InputLabel>
            <Select
              label={label}
              labelId="state-select-label"
              id="state-select"
              variant="outlined"
              value={value ? value : ""}
              onChange={onChange}
              name={name}
              data-test="state-selector"
              disabled={disabled}
            >
              {isLoading && <MenuItem value={value ? value : ""}>Loading...</MenuItem>}
              {!isLoading &&
                states.results.map(item => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.display}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>{errors ? "Please select a state from the list." : helperText}</FormHelperText>
          </FormControl>
        )

        if (tooltip) {
          return <Tooltip title={tooltip}>{StateSelector}</Tooltip>
        }

        return StateSelector
      }}
    />
  )
}
