import { Box } from "@material-ui/core"
import styled from "@emotion/styled"

export const InjuryDetailsContainer = styled(Box)<{ isLeftNavOpen?: boolean }>(({ isLeftNavOpen }) => ({
  display: "flex",
  flexDirection: "column",
  width: isLeftNavOpen !== undefined && isLeftNavOpen ? "100%" : "500px",
}))

export const InjuryDetailContainer = styled(Box)(({ theme }) => ({
  border: `0.5px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(2, 2, 2, 4),
  margin: theme.spacing(2, 0, 0),
}))

export const ContainerContent = styled(Box)(() => ({
  overflowY: "scroll",
  maxHeight: "800px",
}))

export const Header = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  "& > *": {
    alignSelf: "center",
  },
}))

export const DateTitle = styled(Box)(() => ({
  fontWeight: "bold",
  fontSize: "14px",
}))

export const Content = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  lineHeight: 1.5,
  fontSize: "14px",
  marginTop: theme.spacing(1),
  "& > *": {
    margin: theme.spacing(2, 0, 0, 0),
  },
}))

export const ImportantMedicalPassageContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: "fit-content",
}))
