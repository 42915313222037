import React, { useState } from "react"
import { Box, Button, CircularProgress, LinearProgress, Typography } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"
import { Link, useParams } from "react-router-dom"
import { GetApp, OpenInNew } from "@material-ui/icons"
import AnnotationFile from "./AnnotationFile"
import { Alert } from "@material-ui/lab"
import { useQuery } from "react-query"
import { queryKeys } from "react-query/constants"
import {
  downloadCaseAnnotationSummary,
  downloadExhibits,
  getCaseWithAnnotation,
  getExhibits,
  getPartitionProviders,
} from "api"
import { AnnotatedExhibit, PartitionProvider } from "./types"
import { config } from "common/config"
import { CaseWithAnnotation } from "case/types"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { Helmet } from "react-helmet"

const PageHeader = styled(Typography)({
  fontSize: "24px",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1.42,
  letterSpacing: "-0.48px",
})

const HeaderButtons = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}))

const FilesBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "900px",
  margin: "auto",
  gap: theme.spacing(2),
}))

interface FilesContentProps {
  caseId?: string
  isCaseLoading: boolean
  areExhibitsLoading: boolean
  areProvidersLoading: boolean
  caseObj?: CaseWithAnnotation
  exhibits?: AnnotatedExhibit[]
  partitionProviders?: PartitionProvider[]
}

const FilesContent: React.FC<FilesContentProps> = ({
  caseId,
  isCaseLoading,
  areExhibitsLoading,
  areProvidersLoading,
  caseObj,
  exhibits,
  partitionProviders,
}) => {
  const { showMessage } = useHandleMessages()
  const [downloadingExhibits, setDownloadingExhibits] = useState(false)
  const [downloadingSummary, setDownloadingSummary] = useState(false)

  const handleDownloadSummary = () => {
    setDownloadingSummary(true)
    downloadCaseAnnotationSummary({ caseId })
      .catch(() => {
        showMessage({
          type: "error",
          message:
            "Could not download summary. Try again shortly and file an issue if your problem persists.",
        })
      })
      .finally(() => {
        setDownloadingSummary(false)
      })
  }

  if (isCaseLoading || areExhibitsLoading || areProvidersLoading)
    return (
      <Box maxWidth="600px" margin="auto">
        <Typography>Loading intake files...</Typography>
        <Box mt={2}>
          <LinearProgress />
        </Box>
      </Box>
    )

  const handleDownloadExhibits = () => {
    setDownloadingExhibits(true)
    downloadExhibits({ caseId })
      .catch(() => {
        showMessage({
          type: "error",
          message:
            "Could not download exhibits. Try again shortly and file an issue if your problem persists.",
        })
      })
      .finally(() => {
        setDownloadingExhibits(false)
      })
  }

  if (!caseObj?.firm) {
    return (
      <Box pt={4} maxWidth="900px" margin="auto">
        <Alert severity="info">
          Cannot view Intake Files because this case is not currently attached to any firm. Add this case to a
          firm by filling out the Law Firm Information section in the Case Information tab.
        </Alert>
      </Box>
    )
  }

  if (!caseObj.firm.enable_annotations) {
    return (
      <Box pt={4} maxWidth="900px" margin="auto">
        <Alert severity="info">
          Cannot view Intake Files because File Annotations are not enabled for this firm. To view the
          annotated files check the &quot;Enable auto annotations&quot; option in this firms{" "}
          <Link to={`/settings/firms/${caseObj.firm.pk}`}>settings page</Link>.
        </Alert>
      </Box>
    )
  }

  if (!exhibits?.length) {
    return (
      <Box pt={4} maxWidth="900px" margin="auto">
        <Alert severity="info">
          <Box mb={1}>There are no files related to this case being annotated.</Box>
          <Box mb={1}>
            You can proceed to fill in the{" "}
            <Link to={`/demands/${caseId}/form/plaintiff`}>Case Information</Link> as normal.
          </Box>
          This case may have been created before auto annotations. If so, you will have to manually submit an
          annotation request to have your files annotated and they will not appear here.
        </Alert>
      </Box>
    )
  }

  return (
    <Box pt={4}>
      <Box
        display="flex"
        justifyContent="space-between"
        maxWidth="900px"
        marginLeft="auto"
        marginRight="auto"
        marginBottom={7.25}
      >
        <PageHeader variant="h1">Intake Files</PageHeader>
        <HeaderButtons>
          <Button
            size="small"
            variant="contained"
            endIcon={downloadingExhibits ? <CircularProgress size={16} /> : <GetApp />}
            disableElevation
            onClick={handleDownloadExhibits}
            disabled={downloadingExhibits}
          >
            Download All
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={!caseObj.summary_available || downloadingSummary}
            endIcon={downloadingSummary ? <CircularProgress size={16} /> : <GetApp />}
            disableElevation
            onClick={handleDownloadSummary}
          >
            {caseObj.summary_available ? "Summary" : "Summarizing"}
          </Button>
          <Button
            size="small"
            variant="contained"
            endIcon={<OpenInNew />}
            disableElevation
            target="_blank"
            href={`${config.api.host}/case/${caseId}/kili/`}
          >
            Annotation Tool
          </Button>
        </HeaderButtons>
      </Box>

      <FilesBox>
        {exhibits?.map(exhibit => (
          <AnnotationFile
            key={exhibit.pk}
            caseId={caseId ?? ""}
            exhibitId={exhibit.pk}
            fileName={exhibit.name}
            numberOfPages={exhibit.number_of_pages}
            status={exhibit.annotation_file?.status}
            clickable={false}
            splits={exhibit.splits}
            partitionProviders={partitionProviders}
            duplicates={exhibit.duplicates}
            actions={exhibit.actions}
          />
        ))}
      </FilesBox>
    </Box>
  )
}

const Files: React.FC<Record<string, never>> = () => {
  const { id: caseId } = useParams()
  const { data: caseObj, isLoading: isCaseLoading } = useQuery<CaseWithAnnotation>({
    queryKey: [queryKeys.caseWithAnnotation, caseId],
    queryFn: () => getCaseWithAnnotation({ caseId }),
    meta: { disableLoader: true },
  })
  const { data: exhibits, isLoading: areExhibitsLoading } = useQuery<AnnotatedExhibit[]>({
    queryKey: [queryKeys.annotated_exhibits, caseId],
    queryFn: () => getExhibits({ caseId, query: { annotations: true } }),
    meta: { disableLoader: true },
  })
  const { data: partitionProviders, isLoading: areProvidersLoading } = useQuery<PartitionProvider[]>({
    queryKey: [queryKeys.partition_providers, caseId],
    queryFn: () => getPartitionProviders({ caseId }),
    meta: { disableLoader: true },
    // only load after exhibits have loaded to avoid race condition on loading splits for first time
    // where partition provider endpoint returns blank because splits are still being generated
    enabled: !areExhibitsLoading,
  })

  return (
    <>
      <Helmet>
        <title>Demand: {caseObj?.name ?? ""} - EvenUp</title>
      </Helmet>
      <FilesContent
        caseId={caseId}
        isCaseLoading={isCaseLoading}
        areExhibitsLoading={areExhibitsLoading}
        areProvidersLoading={areProvidersLoading}
        caseObj={caseObj}
        exhibits={exhibits}
        partitionProviders={partitionProviders}
      />
    </>
  )
}

export default Files
