import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import DownloadIcon from "@material-ui/icons/GetApp"
import { FLOATING_PANEL_WIDTH } from "../demand/constants"
import { FILE_TYPE } from "../common/constants"

import { useDrag } from "react-dnd"

const useStyles = makeStyles(theme => ({
  hideHyperlinks: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  fileContainer: {
    display: "flex",
    margin: theme.spacing(1, 0.5),
    border: `solid ${theme.palette.primary.main} 0.5px`,
    borderRadius: "0.5rem",
    width: "100%",
    maxWidth: `${FLOATING_PANEL_WIDTH - 100}px`,
    "&:hover": {
      backgroundColor: "#EBEBEB",
    },
  },
  iconContainer: {
    margin: theme.spacing("auto", 0.5, "auto", 1),
    backgroundColor: "black",
    color: "white",
    borderRadius: "50%",
    width: "1.5rem",
    height: "1.5rem",
    display: "flex",
  },
  icon: {
    fontSize: "1rem",
    margin: theme.spacing("auto", 0.5),
  },
  details: {
    margin: theme.spacing("auto", 0.5),
    padding: theme.spacing(1, 1, 1, 0),
  },
  name: {
    fontWeight: 700,
    fontSize: "0.75rem",
    overflowWrap: "anywhere",
  },
}))

interface Props {
  pk: number
  name: string
  url: string
}

interface DropResult {
  name: string
  onDrop: (pk: number, name: string, url: string) => void
}

const FileAttachment = ({ pk, name, url }: Props): JSX.Element => {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type: FILE_TYPE,
      item: { pk, name, url },
      end(item, monitor) {
        const dropResult: DropResult | null = monitor.getDropResult()
        if (item && dropResult) {
          if (dropResult.onDrop) {
            dropResult.onDrop(pk, name, url)
          }
        }
      },
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.3 : 1,
      }),
    }),
    [name, url]
  )
  const classes = useStyles()

  return (
    <div ref={drag} className={classes.fileContainer} style={{ opacity }}>
      <Box className={classes.iconContainer}>
        <DownloadIcon className={classes.icon} />
      </Box>
      <Box className={classes.details}>
        <Box className={classes.name}>{name}</Box>
      </Box>
    </div>
  )
}

export { FileAttachment as default }
